export enum LicenceUserConstants {
  GET_ALL_SUCCESS = 'LicenceUserConstants_GET_ALL_SUCCESS',
  GET_SINGLE_SUCCESS = 'LicenceUserConstants_GET_SINGLE_SUCCESS',
  CREATE_SUCCESS = 'LicenceUserConstants_CREATE_SUCCESS',
  UPDATE_SUCCESS = 'LicenceUserConstants_UPDATE_SUCCESS',
  CLEAR_CURRENT = 'LicenceUserConstants_CLEAR_CURRENT',
  CLEAR_LIST = 'LicenceUserConstants_CLEAR_LIST',
  DELETE = 'LicenceUserConstants_DELETE',
  ERROR = 'LicenceUserConstants_ERROR'
}
