import React, { useEffect, useState } from 'react';
import { Layout } from 'antd';
import { Route, useRouteMatch, Switch, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AdminRoutes } from './admin.routes';
import {
  ContentComponent,
  getAllNotesAction,
  HeaderComponent,
  NotFoundPageComponent,
  SiderComponent,
  StoreStateInterface,
  UserRoleEnum,
} from '../../../common';
import DashboardPageComponent from '../dashboard/dashboardPage.component';
import NewslettersPageComponent from '../newsletters/newsletterDash/newslettersPage.component';
import FaqPageComponent from '../faq/faqPage.component';
import InstitutionalOpinionsPageComponent from '../institutionalOpinions/institutionalOpinionsPage.component';
import ExpertArticlesPageComponent from '../expertArticles/expertArticlesPage.component';
import RegulationsPageComponent from '../regulations/regulationsPage.component';
import WorkflowsPageComponent from '../workflows/workflowsPage.component';
import DKOMDecisionsPageComponent from '../rhPractice/dkom/dkomDecisionsPage.component';
import VUSDeclarationsPageComponent from '../rhPractice/vus/vusDeclarationsPage.component';
import EUCourtDeclarationsPageComponent from '../euPractice/euCourt/euCourtDeclarationsPage.component';
import ProMaterialsPageComponent from '../euPractice/materials/proMaterialsPage.component';
import { AuthRoutes } from '../../auth';
import InstitutionalOpinionsCrudPageComponent from '../institutionalOpinions/crud/institutionalOpinionsCrudPage.component';
import UserProfilePageComponent from '../userProfile/userProfilePage.component';
import AdminProfilePageComponent from '../adminProfile/adminProfile.component';
import ExpertArticlesCrudPageComponent from '../expertArticles/crud/expertArticlesCrudPage.component';
import FaqCrudPageComponent from '../faq/crud/faqCrudPage.component';
import RegulationsCrudPageComponent from '../regulations/crud/regulationsCrudPage.component';
import WorkflowsCrudPageComponent from '../workflows/crud/workflowsCrudPage.component';
import NotesPageComponent from '../notes/notesDash/notesPage.component';
import OrganizationProfilePageComponent from '../organizationProfile/organizationProfilePage.component';
import NoteViewPageComponent from '../notes/noteView/noteView.component';
import NewsletterViewPageComponent from '../newsletters/newsletterView/newsletterView.component';
import NewslettersCrudPageComponent from '../newsletters/crud/newslettersCrudPage.component';
import DKOMMainCrudPageComponent from '../rhPractice/dkom/crud/dkomMainCrudPage.component';
import VUSMainCrudPageComponent from '../rhPractice/vus/crud/vusMainCrudPage.component';
import ProMaterialsCrudPageComponent from '../euPractice/materials/crud/proMaterialsCrudPage.component';
import EuCourtCrudPageComponent from '../euPractice/euCourt/crud/euCourtCrudPage.component';
import NotesCrudPageComponent from '../notes/noteView/crud/notesCrudPage.component';
import InstructionsPageComponent from '../../app/instructions/instructionsPage.component';
import SettingsPageComponent from '../../app/settings/settingsPage.component';
import StatisticsPageComponent from '../../app/statistics/statisticsPage.component';

function AdminRouter() {
  const userAuth = useSelector((state: StoreStateInterface) => state.auth);
  const dispatch = useDispatch();
  const { path } = useRouteMatch();

  useEffect(() => {
    dispatch(getAllNotesAction(userAuth.user?.id));
  }, [userAuth?.user?.id]);

  const [collapsed, setCollapsed] = useState(false);

  let routes = [
    {
      path: AdminRoutes.DASHBOARD.path,
      component: DashboardPageComponent,
      roles: [UserRoleEnum.ADMIN],
      exact: true,
    },
    {
      path: AdminRoutes.NEWSLETTERS.path,
      component: NewslettersPageComponent,
      roles: [UserRoleEnum.ADMIN],
      exact: true,
    },
    {
      path: AdminRoutes.NEWSLETTERS_VIEW.path + '/:id',
      component: NewsletterViewPageComponent,
      roles: [UserRoleEnum.ADMIN],
      exact: true,
    },
    {
      path: AdminRoutes.NEWSLETTERS.path + '/:id',
      component: NewslettersCrudPageComponent,
      roles: [UserRoleEnum.ADMIN],
      exact: true,
    },
    {
      path: AdminRoutes.FAQ.path,
      component: FaqPageComponent,
      roles: [UserRoleEnum.ADMIN],
      exact: true,
    },
    {
      path: AdminRoutes.FAQ.path + '/:id',
      component: FaqCrudPageComponent,
      roles: [UserRoleEnum.ADMIN],
      exact: true,
    },
    {
      path: AdminRoutes.INSTITUTIONAL_OPINIONS.path,
      component: InstitutionalOpinionsPageComponent,
      roles: [UserRoleEnum.ADMIN],
      exact: true,
    },
    {
      path: AdminRoutes.INSTITUTIONAL_OPINIONS.path + '/:id',
      component: InstitutionalOpinionsCrudPageComponent,
      roles: [UserRoleEnum.ADMIN],
      exact: true,
    },
    {
      path: AdminRoutes.EXPERT_ARTICLES.path,
      component: ExpertArticlesPageComponent,
      roles: [UserRoleEnum.ADMIN],
      exact: true,
    },
    {
      path: AdminRoutes.EXPERT_ARTICLES.path + '/:id',
      component: ExpertArticlesCrudPageComponent,
      roles: [UserRoleEnum.ADMIN],
      exact: true,
    },
    {
      path: AdminRoutes.REGULATIONS.path,
      component: RegulationsPageComponent,
      roles: [UserRoleEnum.ADMIN],
      exact: true,
    },
    {
      path: AdminRoutes.REGULATIONS.path + '/:id',
      component: RegulationsCrudPageComponent,
      roles: [UserRoleEnum.ADMIN],
      exact: true,
    },
    {
      path: AdminRoutes.WORKFLOWS.path,
      component: WorkflowsPageComponent,
      roles: [UserRoleEnum.ADMIN],
      exact: true,
    },
    {
      path: AdminRoutes.WORKFLOWS.path + '/:id',
      component: WorkflowsCrudPageComponent,
      roles: [UserRoleEnum.ADMIN],
      exact: true,
    },
    {
      path: AdminRoutes.DKOM_DECISIONS.path,
      component: DKOMDecisionsPageComponent,
      roles: [UserRoleEnum.ADMIN],
      exact: true,
    },
    {
      path: AdminRoutes.DKOM_DECISIONS.path + '/:id',
      component: DKOMMainCrudPageComponent,
      roles: [UserRoleEnum.ADMIN],
      exact: true,
    },
    {
      path: AdminRoutes.VUS_DECLARATIONS.path,
      component: VUSDeclarationsPageComponent,
      roles: [UserRoleEnum.ADMIN],
      exact: true,
    },
    {
      path: AdminRoutes.VUS_DECLARATIONS.path + '/:id',
      component: VUSMainCrudPageComponent,
      roles: [UserRoleEnum.ADMIN],
      exact: true,
    },
    {
      path: AdminRoutes.EU_COURT_DECLARATIONS.path,
      component: EUCourtDeclarationsPageComponent,
      roles: [UserRoleEnum.ADMIN],
      exact: true,
    },
    {
      path: AdminRoutes.EU_COURT_DECLARATIONS.path + '/:id',
      component: EuCourtCrudPageComponent,
      roles: [UserRoleEnum.ADMIN],
      exact: true,
    },
    {
      path: AdminRoutes.PRO_MATERIALS.path,
      component: ProMaterialsPageComponent,
      roles: [UserRoleEnum.ADMIN],
      exact: true,
    },
    {
      path: AdminRoutes.PRO_MATERIALS.path + '/:id',
      component: ProMaterialsCrudPageComponent,
      roles: [UserRoleEnum.ADMIN],
      exact: true,
    },
    {
      path: AdminRoutes.PROFILE.path,
      component: AdminProfilePageComponent,
      roles: [UserRoleEnum.ADMIN],
      exact: true,
    },
    {
      path: AdminRoutes.USER_PROFILE.path + '/:id',
      component: UserProfilePageComponent,
      roles: [UserRoleEnum.ADMIN],
      exact: true,
    },
    {
      path: AdminRoutes.NOTES.path,
      component: NotesPageComponent,
      roles: [UserRoleEnum.ADMIN],
      exact: true,
    },
    {
      path: AdminRoutes.NOTES.path + '/:id',
      component: NoteViewPageComponent,
      roles: [UserRoleEnum.ADMIN],
      exact: true,
    },
    {
      path: AdminRoutes.NOTES.path + '/:id/edit',
      component: NotesCrudPageComponent,
      roles: [UserRoleEnum.ADMIN],
      exact: true,
    },
    {
      path: AdminRoutes.ORGANIZATION_PROFILE.path + '/:id',
      component: OrganizationProfilePageComponent,
      roles: [UserRoleEnum.ADMIN],
      exact: true,
    },
    {
      path: AdminRoutes.INSTRUCTIONS.path,
      component: InstructionsPageComponent,
      roles: [UserRoleEnum.ADMIN],
      exact: true,
    },
    {
      path: AdminRoutes.SETTINGS.path,
      component: SettingsPageComponent,
      roles: [UserRoleEnum.ADMIN],
      exact: true,
    },
    {
      path: AdminRoutes.STATISTICS.path,
      component: StatisticsPageComponent,
      roles: [UserRoleEnum.ADMIN],
      exact: true,
    },
    /**
    {
      path: AdminRoutes.ORGANIZATION_UNIT_PROFILE.path + '/:id',
      component: OrganizationUnitProfilePageComponent,
      roles: [UserRoleEnum.ADMIN],
      exact: true,
    },
    */
  ];

  const allowedRoutes = routes.filter((r) => {
    if (r.roles.length === 0) return true;
    if (!userAuth.isAuthenticated || !userAuth.user) return false;
    if (r.roles.includes(userAuth.user.roles)) return true;

    return false;
  });

  return (
    <Layout className="layout" hasSider>
      <SiderComponent
        isAdmin
        collapsed={collapsed}
        onBreakpointChange={() => {
          setCollapsed(true);
        }}
      ></SiderComponent>
      <Layout className="main">
        <HeaderComponent collapsed={collapsed} setCollapsed={setCollapsed}></HeaderComponent>
        <ContentComponent className="content content-container">
          <Switch>
            {allowedRoutes.map((route, index) => (
              <Route exact={route.exact} path={`${path}/${route.path}`} key={index}>
                {' '}
                <route.component />{' '}
              </Route>
            ))}
            {userAuth.isAuthenticated ? (
              <Route component={NotFoundPageComponent} />
            ) : (
              <Redirect to={AuthRoutes.LOGIN.fullPath} />
            )}
          </Switch>
        </ContentComponent>
      </Layout>
    </Layout>
  );
}

export default AdminRouter;
