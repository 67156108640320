import { DeleteOutlined, EditOutlined, FilePdfOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Popover, Row, Space, Typography } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { AdminRoutes } from '../../../features/admin';
import { AppRoutes } from '../../../features/app';
import { UserRoleEnum } from '../../enums';
import { LicenceEnum } from '../../enums/licence.enum';
import { LicenceInterface, NoteInterface } from '../../interfaces';
import { StoreStateInterface } from '../../redux';
import { i18n } from '../../services';
import printService from '../../services/print.service';
import moment from 'moment';

const { Text } = Typography;

interface Props {
  note: NoteInterface;
  licenceData?: LicenceInterface;
  validLicences?: LicenceInterface[];
  onDelete?: any;
}

export default function NotesComponent({ note, licenceData, validLicences, onDelete }: Props) {
  const userAuth = useSelector((state: StoreStateInterface) => state.auth);
  let licenceDataValid;

  if (validLicences?.length) {
    validLicences.forEach((licence) => {
      if (
        licence.attributes?.licenceUsers?.find((x) => x.user?.id == userAuth.user?.id) &&
        !licence.relationships?.licenceParent?.data && licence.attributes?.notesNum > 0
      ) {
        licenceDataValid = licence;
        const standardLicence = validLicences.find(
          (x) => x.attributes?.licenceUsers?.find((y) => y.user?.id == userAuth.user?.id) && !x.relationships?.licenceParent?.data && x.attributes?.active && x.attributes?.licenceType === LicenceEnum.STANDARD && licence.attributes?.notesNum > 0 && moment().isBefore(moment(licence?.attributes?.to).startOf('day'))
        );
        const premiumLicence = validLicences.find(
          (x) => x.attributes?.licenceUsers?.find((y) => y.user?.id == userAuth.user?.id) && !x.relationships?.licenceParent?.data && x.attributes?.active && x.attributes?.licenceType === LicenceEnum.PREMIUM && licence.attributes?.notesNum > 0 && moment().isBefore(moment(licence?.attributes?.to).startOf('day'))
        );
        if (standardLicence && standardLicence.attributes?.notesNum > 0) licenceDataValid = standardLicence;
        if (premiumLicence && premiumLicence.attributes?.notesNum > 0) licenceDataValid = premiumLicence;
      }
    });
  }

  if (!licenceDataValid) licenceDataValid = licenceData;

  return (
    <div className="note">
      <Row className="row" wrap={false} justify="space-between">
        <Col>
          {' '}
          <Link
            to={
              userAuth?.user?.roles === UserRoleEnum.ADMIN
                ? AdminRoutes.NOTES.path + `/${note?.id}`
                : AppRoutes.NOTES.path + `/${note?.id}`
            }
          >
            <Space split={<Divider type="vertical" />}>
              <Text strong>{note?.attributes?.title}</Text>

              {note?.attributes?.notesObject?.title || note?.attributes?.notesObject?.verdictTitle || note?.attributes?.notesObject?.name || note?.attributes?.notesObject?.titleContent || note?.attributes?.notesObject?.verdictName || note?.attributes?.notesObject?.materialTitle ? 
              <Text type="secondary">
                {note?.attributes?.notesObject?.title ||
                  note?.attributes?.notesObject?.verdictTitle ||
                  note?.attributes?.notesObject?.name ||
                  note?.attributes?.notesObject?.titleContent ||
                  note?.attributes?.notesObject?.verdictName ||
                  note?.attributes?.notesObject?.materialTitle}
              </Text> : null}
            </Space>
          </Link>
        </Col>
        <Col style={{ minWidth: '100px' }}>
          {userAuth?.user?.roles === UserRoleEnum.ADMIN ? (
            <Link to={AdminRoutes.NOTES.path + `/${note?.id}/edit`} key={AdminRoutes.NOTES.path + `/${note?.id}/edit`}>
              <Button type="link" className="cardBtn" icon={<EditOutlined />} />
            </Link>
          ) : (
            <Link to={AppRoutes.NOTES.path + `/${note?.id}/edit`} key={AppRoutes.NOTES.path + `/${note?.id}/edit`}>
              <Button type="link" className="cardBtn" icon={<EditOutlined />} />
            </Link>
          )}
          <Button className="cardBtn" type="link" icon={<DeleteOutlined />} onClick={onDelete} />
          {(!licenceDataValid && userAuth.user?.roles !== UserRoleEnum.ADMIN) ||
          licenceDataValid?.attributes?.licenceType === LicenceEnum.FREE ||
          licenceDataValid?.attributes.licenceType === LicenceEnum.STANDARD ? (
            <Popover content={i18n.translate(`notes.printDisabled`)} trigger="click" placement="topRight">
              <Button disabled className="cardBtn" type="link" icon={<FilePdfOutlined />} />
            </Popover>
          ) : (
            <Button
              onClick={() => printService.printNoteToPdf(note) }
              className="cardBtn"
              type="link"
              icon={<FilePdfOutlined />}
            />
          )}
        </Col>
      </Row>

      <Divider />
    </div>
  );
}
