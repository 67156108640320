import { Col, notification, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  DashboardFAQComponent,
  DashboardNotesComponent,
  DashboardRegulationsComponent,
  ExternalService,
  FaqEsResponseInterface,
  FaqInterface,
  FaqService,
  i18n,
  NewsComponent,
  NewsInterface,
  NoteResponseInterfaceAll,
  NoteService,
  NotificationsComponent,
  RegulationResponseInterfaceAll,
  RegulationService,
  StoreStateInterface,
  VersionComponent,
} from '../../../common';
import EducationsComponent from '../../../common/components/dashboard/educations/educations.component';

function DashboardPageComponent() {
  const userAuth = useSelector((state: StoreStateInterface) => state.auth);

  const [noteData, setNoteData] = useState<NoteResponseInterfaceAll>();
  const [faqData, setFaqData] = useState<FaqEsResponseInterface>();
  const [regData, setRegData] = useState<RegulationResponseInterfaceAll>();

  useEffect(() => {
    NoteService.getAll(userAuth.user?.id, false).subscribe(
      (response: NoteResponseInterfaceAll) => {
        setNoteData(response);
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    FaqService.elasticSearch({ from: 0, size: 4 }, false, '', []).subscribe(
      (response: FaqEsResponseInterface) => {
        setFaqData(response);
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    RegulationService.getAll(undefined, 'filter[id]=12,68,25', 'sort=-id').subscribe(
      (response: RegulationResponseInterfaceAll) => {
        setRegData(response);
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="dashboard w100-h100">
      <div className="dashboard__title">{i18n.translate('dashboard.title')}</div>
      <NotificationsComponent type="admin" />
      <>
        <Row justify="space-between" className="container">
          <Col>
            {noteData?.data?.length ? <DashboardNotesComponent notes={[...noteData?.data]} /> : null}
            {faqData?.hits.length ? (
              <DashboardFAQComponent
                faqs={faqData?.hits.map(
                  (el: { index: 'faq_group'; id: number; score: number; source: FaqInterface['attributes'] }) =>
                    el.source
                )}
              />
            ) : null}
          </Col>
          <Col>
            <NewsComponent />
          </Col>
        </Row>
        <div>{regData?.data?.length ? <DashboardRegulationsComponent regulations={[...regData?.data]} /> : null}</div>
        <div>
          <EducationsComponent />
        </div>
      </>

      <VersionComponent />
    </div>
  );
}

export default DashboardPageComponent;
