import React, { useEffect, useState } from 'react';
import {
  ChildrenTagScreenResponseInterface,
  clearBiltenAction,
  createBiltenAction,
  deleteBiltenAction,
  FileFormatEnum,
  FileService,
  FilterNameEnumHR,
  getScreenTagsByTypeAction,
  getSingleBiltenAction,
  i18n,
  JavaFormat,
  NavigationService,
  BiltenStateInterface,
  SelectTypeEnum,
  StoreStateInterface,
  TagScreenResponseInterface,
  TagScreenStateInterface,
  updateBiltenAction,
  DisplayDateMonthFormat,
  getAllBiltensAction,
} from '../../../../common';
import { InboxOutlined, DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import {
  Button,
  Checkbox,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  notification,
  Radio,
  Select,
  Switch,
  TreeSelect,
  Upload,
  UploadFile,
  UploadProps,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { AdminRoutes } from '../../_router/admin.routes';
import localeHR from 'antd/es/date-picker/locale/hr_HR';
import localeEN from 'antd/es/date-picker/locale/en_GB';
import moment from 'moment';
import { RangePickerProps } from 'antd/lib/date-picker';

const { SHOW_ALL } = TreeSelect;

const { TextArea } = Input;

const { Dragger } = Upload;

const { confirm } = Modal;

function NewslettersCrudPageComponent() {
  const location = useLocation();
  const path = location.pathname.split('/');
  const id = parseInt(path[path.length - 1]);
  const isNew = isNaN(id);

  const dispatch = useDispatch();
  const data: BiltenStateInterface = useSelector((state: StoreStateInterface) => state.bilten);
  const tags: TagScreenStateInterface = useSelector((state: StoreStateInterface) => state.tagScreen);
  const userAuth = useSelector((state: StoreStateInterface) => state.auth);

  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [contentFileList, setContentFileList] = useState<UploadFile[]>([]);
  const [frontFileList, setFrontFileList] = useState<UploadFile[]>([]);
  const [pdfUuid, setPdfUuid] = useState('');
  const [contentPdfUuid, setContentPdfUuid] = useState('');
  const [frontUuid, setFrontUuid] = useState('');

  const [frontPageUrl, setFrontPageUrl] = useState('');
  const [contentUrl, setContentUrl] = useState('');

  useEffect(() => {
    dispatch(clearBiltenAction());
    dispatch(getAllBiltensAction());
    dispatch(getScreenTagsByTypeAction('bilten'));
    if (!isNew) dispatch(getSingleBiltenAction(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isNew) {
      if (data?.currentBilten?.attributes?.pdfUuid) {
        FileService.list(data?.currentBilten?.attributes?.pdfUuid).subscribe(
          (response: any) => {
            setFileList([{ name: response.fileName, uid: response.uuid }]);
            setPdfUuid(response.uuid);
          },
          (error: Error) => {
            console.log(error);
          }
        );
      }
      if (data?.currentBilten?.attributes?.contentPdfUuid) {
        FileService.list(data?.currentBilten?.attributes?.contentPdfUuid).subscribe(
          (response: any) => {
            setContentFileList([{ name: response.fileName, uid: response.uuid }]);
            setContentPdfUuid(response.uuid);
          },
          (error: Error) => {
            console.log(error);
          }
        );
      }
      if (data?.currentBilten?.attributes?.frontpageUuid) {
        FileService.list(data.currentBilten?.attributes?.frontpageUuid).subscribe(
          (response: any) => {
            setFrontFileList([{ name: response.fileName, uid: response.uuid }]);
            setFrontUuid(response.uuid);
          },
          (error: Error) => {
            console.log(error);
          }
        );
      }
      if (data?.currentBilten?.attributes?.frontpageUrl) {
        setFrontPageUrl(data.currentBilten?.attributes?.frontpageUrl)
      }
      if (data?.currentBilten?.attributes?.biltenContentByUrl) {
        setContentUrl(data.currentBilten?.attributes?.biltenContentByUrl)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.currentBilten?.attributes]);

  const onFileDownload = () => {
    FileService.temp(pdfUuid).subscribe(
      (response: any) => {
        if (response.url) {
          notification['success']({ message: i18n.translate('api.downloadSuccessMessage'), duration: 2 });
          var link = document.createElement('a');
          link.href = response.url;
          link.download = 'file.pdf';
          link.dispatchEvent(new MouseEvent('click'));
        } else {
          notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
        }
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
      }
    );
  };

  const onContentFileDownload = () => {
    FileService.temp(contentPdfUuid).subscribe(
      (response: any) => {
        if (response.url) {
          notification['success']({ message: i18n.translate('api.downloadSuccessMessage'), duration: 2 });
          var link = document.createElement('a');
          link.href = response.url;
          link.download = 'file.pdf';
          link.dispatchEvent(new MouseEvent('click'));
        } else {
          notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
        }
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
      }
    );
  };

  const onFrontFileDownload = () => {
    FileService.temp(frontUuid).subscribe(
      (response: any) => {
        if (response.url) {
          notification['success']({ message: i18n.translate('api.downloadSuccessMessage'), duration: 2 });
          var link = document.createElement('a');
          link.href = response.url;
          link.download = 'file.pdf';
          link.dispatchEvent(new MouseEvent('click'));
        } else {
          notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
        }
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
      }
    );
  };

  const beforeUpload = (file: any) => {
    if (!FileFormatEnum.ALLOWED.split(',').includes(file.type)) {
      message.error(i18n.translate('genericFileUpload.formatError', { filename: file.name }));
      return false;
    } else {
      return true;
    }
  };

  const beforeUploadFront = (file: any) => {
    if (!FileFormatEnum.IMAGE_ONLY.split(',').includes(file.type)) {
      message.error(i18n.translate('genericFileUpload.formatErrorImg', { filename: file.name }));
      return false;
    } else {
      return true;
    }
  };

  const onFileListChange = (info: any) => {
    if (!FileFormatEnum.ALLOWED.split(',').includes(info.file.type)) return;

    if (info.file.status === 'done') {
      passUuid(info.file.response[0].uuid);
      message.success(i18n.translate('genericFileUpload.uploadSuccess', { filename: info.file.name }));
    } else if (info.file.status === 'error') {
      message.error(i18n.translate('genericFileUpload.uploadFailed', { filename: info.file.name }));
    }
    setFileList([info.fileList.pop()]);
  };

  const onContentFileListChange = (info: any) => {
    if (!FileFormatEnum.ALLOWED.split(',').includes(info.file.type)) return;

    if (info.file.status === 'done') {
      FileService.tempAdmin(info.file.response[0].uuid).subscribe(
        (response: any) => {
          if (response.url) {
            const url = response.url + '/bjn';
            passContentUrl(url);
          } else {
            notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
          }
        },
        (error: Error) => {
          notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
        }
      );
      passContentUuid(info.file.response[0].uuid);
      message.success(i18n.translate('genericFileUpload.uploadSuccess', { filename: info.file.name }));
    } else if (info.file.status === 'error') {
      message.error(i18n.translate('genericFileUpload.uploadFailed', { filename: info.file.name }));
    }
    setContentFileList([info.fileList.pop()]);
  };

  const onFrontFileListChange = (info: any) => {
    if (!FileFormatEnum.IMAGE_ONLY.split(',').includes(info.file.type)) return;

    if (info.file.status === 'done') {
      FileService.tempAdmin(info.file.response[0].uuid).subscribe(
        (response: any) => {
          if (response.url) {
            const url = response.url + '/bjn';
            passFrontUrl(url);
          } else {
            notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
          }
        },
        (error: Error) => {
          notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
        }
      );
      passFrontUuid(info.file.response[0].uuid);
      message.success(i18n.translate('genericFileUpload.uploadSuccess', { filename: info.file.name }));
    } else if (info.file.status === 'error') {
      message.error(i18n.translate('genericFileUpload.uploadFailed', { filename: info.file.name }));
    }
    setFrontFileList([info.fileList.pop()]);
  };

  const onFileRemove = () => {
    passUuid('');
    setFileList([]);
  };

  const onContentFileRemove = () => {
    passContentUuid('');
    passContentUrl('');
    setContentFileList([]);
  };

  const onFrontFileRemove = () => {
    passFrontUuid('');
    passFrontUrl('');
    setFrontFileList([]);
  };

  const props: UploadProps = {
    name: 'files',
    accept: FileFormatEnum.ALLOWED,
    beforeUpload: (file: any) => beforeUpload(file),
    multiple: false,
    action: process.env.REACT_APP_FILE_UPLOAD,
    onChange: (info: any) => onFileListChange(info),
    fileList: fileList,
    showUploadList: {
      showRemoveIcon: false,
    },
  };

  const propsContent: UploadProps = {
    name: 'files',
    accept: FileFormatEnum.ALLOWED,
    beforeUpload: (file: any) => beforeUpload(file),
    multiple: false,
    action: process.env.REACT_APP_FILE_UPLOAD,
    onChange: (info: any) => onContentFileListChange(info),
    fileList: contentFileList,
    showUploadList: {
      showRemoveIcon: false,
    },
  };

  const propsFront: UploadProps = {
    name: 'files',
    //accept: FileFormatEnum.IMAGE_ONLY,
    beforeUpload: (file: any) => beforeUploadFront(file),
    multiple: false,
    action: process.env.REACT_APP_FILE_UPLOAD,
    onChange: (info: any) => onFrontFileListChange(info),
    fileList: frontFileList,
    showUploadList: {
      showRemoveIcon: false,
    },
  };

  const checkForNested = (tag: TagScreenResponseInterface) => {
    let nested = tag.children?.find((child) => child.nestedChildren?.length && child.nestedChildren?.length > 0);
    if (nested) return true;
    else return false;
  };

  // eslint-disable-next-line arrow-body-style
  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    // Can not select year after current year or before 2016
    return (current && current > moment().endOf('year')) || current < moment('2015').endOf('year');
  };

  const onFinishBilten = (values: any) => {
    const getTags = () => {
      let array: Array<{ id: Number; type: string }> = [];
      tags.tagScreens?.forEach((tag) => {
        const value = values[tag.filter.name as keyof any];
        if (value) {
          if (tag?.selectType === SelectTypeEnum.YEAR) {
            const childTag = tag.children?.find((child) => child.tag.name == moment(value).format('YYYY'));
            if (childTag) array.push({ id: childTag.tag.id as number, type: 'tag' });
          }
          if (tag?.selectType === SelectTypeEnum.SELECT || tag?.selectType === SelectTypeEnum.BOOLEAN) {
            const childTag = tag.children?.find((child) => child.tag.id == value);
            if (childTag) array.push({ id: childTag.tag.id as number, type: 'tag' });
          }
          if (tag?.selectType === SelectTypeEnum.MULTISELECT) {
            const value = values[tag.filter.name as keyof any];
            if (value && value.length && value.length > 0) {
              value.forEach((id: any) => {
                if (id) array.push({ id: id, type: 'tag' });
              });
            }
          }
        }
      });

      return array;
    };

    let biltenData: any = {
      data: {
        type: 'bilten',
        id: data.currentBilten?.id,
        attributes: {
          specialEdition: values.specialEdition,
          issueNum: values.issueNum,
          issuedAt: values.issuedAt ? moment(values.issuedAt).set({date:1,hour:0,minute:0,second:0,millisecond:0}).format(JavaFormat) : null,
          name: values.name,
          actual: values.actual,
          descriptionFive: values.descriptionFive,
          descriptionEight: values.descriptionEight,
          goalText: values.goalText,
          resolvesText: values.resolvesText,
          printEditionStock: values.printEditionStock,
          availability: values.availability ? (values.printEditionStock <= 0 ? false : values.availability) : values.availability,
          price: values.price,
          hiddenContent: values.hiddenContent,
          pdfUuid: pdfUuid,
          contentPdfUuid: contentPdfUuid,
          frontpageUuid: frontUuid,
          frontpageUrl: frontPageUrl,
          biltenContentByUrl: contentUrl
        },
        relationships: {
          tags: {
            data: getTags(),
          },
        },
      },
    };
    isNew ? dispatch(createBiltenAction(biltenData)) : dispatch(updateBiltenAction(biltenData));
    window.scrollTo({ top: 0, behavior: 'smooth' });

    setTimeout(() => {
      NavigationService.navigate(AdminRoutes.NEWSLETTERS.fullPath);
    }, 1000);
  };

  const onFinishBiltenFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const setTreeData = (children: Array<ChildrenTagScreenResponseInterface>) => {
    let values = children.map((child) => ({
      title: child.tag.name,
      value: child.tag.id,
      key: child.tag.id,
      children: child.nestedChildren?.map((nestedChild) => ({
        title: nestedChild.tag.name,
        value: nestedChild.tag.id,
        key: nestedChild.tag.id,
        children: nestedChild.nestedChildren?.map((nestedLvl2Child) => ({
          title: nestedLvl2Child.tag.name,
          value: nestedLvl2Child.tag.id,
          key: nestedLvl2Child.tag.id,
        })),
      })),
    }));
    return values;
  };

  const getDisplayConditions = () => {
    if (isNew) {
      return tags.tagScreens && tags.tagScreens.length > 0;
    } else {
      return tags.tagScreens && tags.tagScreens.length > 0 && data.currentBilten;
    }
  };

  const getFilter = (field: string) => {
    let filter;
    let tag = tags.tagScreens?.find((tag) => tag.filter.name == field);
    if (tag) {
      let array: number[] = [];
      data.currentBilten?.attributes?.tagIds?.forEach((id) => {
        tag?.children?.forEach((child) => {
          if (id === child.tag.id) {
            if (tag?.selectType === SelectTypeEnum.YEAR) {
              filter = moment(child.tag.name);
            }
            if (tag?.selectType === SelectTypeEnum.SELECT || tag?.selectType === SelectTypeEnum.BOOLEAN) {
              filter = child.tag.id;
            }
            if (tag?.selectType === SelectTypeEnum.MULTISELECT) {
              array.push(child.tag.id);
            }
          }
        });
      });

      if (tag?.selectType === SelectTypeEnum.MULTISELECT) filter = array;
    }
    return filter;
  };

  const loadInitialValues = () => {
    let values: any = {
      specialEdition: data.currentBilten?.attributes?.specialEdition,
      issueNum: data.currentBilten?.attributes?.issueNum,
      issuedAt: data.currentBilten?.attributes?.issuedAt ? moment(data.currentBilten?.attributes?.issuedAt) : null,
      name: data.currentBilten?.attributes?.name,
      actual: data.currentBilten?.attributes?.actual,
      descriptionFive: data.currentBilten?.attributes?.descriptionFive,
      descriptionEight: data.currentBilten?.attributes?.descriptionEight,
      goalText: data.currentBilten?.attributes?.goalText,
      resolvesText: data.currentBilten?.attributes?.resolvesText,
      printEditionStock: data.currentBilten?.attributes?.printEditionStock,
      availability: data.currentBilten?.attributes?.availability,
      price: data?.currentBilten?.attributes?.price,
      hiddenContent: data?.currentBilten?.attributes?.hiddenContent
    };

    tags.tagScreens?.forEach((tag) => {
      values[tag.filter.name as keyof any] = getFilter(tag.filter.name);
    });

    return values;
  };

  const loadDefaultValues = () => {
    let values: any = {};

    tags.tagScreens?.forEach((tag) => {
      if (tag.filter.name == FilterNameEnumHR.ZJN) {
        values[tag.filter.name as keyof any] = [tag.children?.length ? tag.children[0].tag.id : []];
      }
    });

    values.specialEdition = false;
    values.issueNum = data.biltens?.meta?.totalResourceCount ? data.biltens?.meta?.totalResourceCount + 1 : 1;
    values.actual = false;
    values.printEditionStock = 1;
    values.availability = true;
    values.price = 0;

    return values;
  };

  const clearCurrentBilten = () => {
    dispatch(clearBiltenAction());
  };

  const passUuid = (uuid: string) => {
    setPdfUuid(uuid);
  };

  const passContentUuid = (uuid: string) => {
    setContentPdfUuid(uuid);
  };

  const passFrontUuid = (uuid: string) => {
    setFrontUuid(uuid);
  };

  const passFrontUrl = (url: string) => {
    setFrontPageUrl(url);
  };

  const passContentUrl = (url: string) => {
    setContentUrl(url);
  };

  const showConfirm = () => {
    confirm({
      title: i18n.t('newsletters.confirmDelete'),
      icon: <ExclamationCircleOutlined />,
      content: i18n.t('common.cantUndo'),
      okText: i18n.t('genericButtons.yes'),
      cancelText: i18n.t('genericButtons.cancel'),
      onOk() {
        dispatch(deleteBiltenAction(id));
        window.scrollTo({ top: 0, behavior: 'smooth' });

        setTimeout(() => {
          NavigationService.navigate(AdminRoutes.NEWSLETTERS.fullPath);
        }, 1000);
      },
    });
  };

  const returnComponent = (tag: TagScreenResponseInterface) => {
    let component = null;
    if (tag.selectType == SelectTypeEnum.YEAR) {
      component = (
        <DatePicker
          picker="year"
          disabledDate={disabledDate}
          locale={userAuth.lang == 'hr' ? localeHR : localeEN}
          format={'YYYY.'}
        />
      );
    }

    if (tag.selectType == SelectTypeEnum.BOOLEAN) {
      component = (
        <Radio.Group>
          <Radio.Button value={tag.children?.length ? tag.children[0].tag.id : 0}>
            {tag.children?.length ? tag.children[0].tag.name : ''}
          </Radio.Button>
          <Radio.Button value={tag.children?.length ? tag.children[1].tag.id : 1}>
            {tag.children?.length ? tag.children[1].tag.name : ''}
          </Radio.Button>
        </Radio.Group>
      );
    }

    if (tag.selectType == SelectTypeEnum.SELECT || tag.selectType == SelectTypeEnum.MULTISELECT) {
      if (checkForNested(tag)) {
        component = (
          <TreeSelect
            treeData={setTreeData(tag.children as Array<ChildrenTagScreenResponseInterface>)}
            treeCheckable
            showCheckedStrategy={SHOW_ALL}
            maxTagCount="responsive"
            placeholder={i18n.t('genericFormMsg.placeholders.select')}
          />
        );
      } else {
        component = (
          <Select
            mode={tag.selectType == SelectTypeEnum.MULTISELECT ? 'multiple' : undefined}
            maxTagCount={tag.selectType == SelectTypeEnum.MULTISELECT ? 'responsive' : undefined}
            allowClear
          >
            {tag.children?.length &&
              tag.children.map((child) => (
                <Select.Option key={child.tag.name} value={child.tag.id}>
                  {child.tag.name}
                </Select.Option>
              ))}
          </Select>
        );
      }
    }

    return component;
  };

  return (
    <div className="newslettersCrud w100-h100">
      {getDisplayConditions() && (
        <Form
          layout="vertical"
          name="bilten"
          size="large"
          scrollToFirstError
          initialValues={data.currentBilten && !isNew ? loadInitialValues() : loadDefaultValues()}
          onFinish={onFinishBilten}
          onFinishFailed={onFinishBiltenFailed}
          autoComplete="off"
          requiredMark={false}
        >
          <div className="newslettersCrud__admin">
            <div className="title">
              {i18n.translate(isNew ? 'newsletters.crudTitleAdd' : 'newsletters.crudTitleUpdate')}
            </div>
            <div className="buttons">
              <Link to={AdminRoutes.NEWSLETTERS.fullPath} key={AdminRoutes.NEWSLETTERS.path}>
                <Button
                  onClick={clearCurrentBilten}
                  className="button"
                  size="large"
                  style={{ fontSize: 16, fontWeight: 700, borderRadius: 0, minWidth: 193, marginRight: 16 }}
                >
                  {i18n.translate('genericButtons.cancel')}
                </Button>
              </Link>
              <Button
                className="button"
                htmlType="submit"
                type="primary"
                size="large"
                style={{ fontSize: 16, fontWeight: 700, borderRadius: 0, minWidth: 193 }}
              >
                {i18n.translate('genericButtons.save')}
              </Button>
            </div>
          </div>
          <Divider />
          <div className="newslettersCrud__form">
            <div className="subtitle">
              <span className="text">{i18n.translate('newsletters.form.sections.info')}</span>
            </div>

            <Form.Item
              label={<span className="text-bold">{i18n.translate(`newsletters.form.fields.specialEdition`)}</span>}
              name="specialEdition"
              labelCol={{ span: 12 }}
              wrapperCol={{ span: 12 }}
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>

            <Form.Item
              label={<span className="text-bold">{i18n.translate(`newsletters.form.fields.issueNum`)}</span>}
              name="issueNum"
              rules={[{ required: true, message: i18n.translate(`genericFormMsg.required`) }]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 12 }}
            >
              <InputNumber min={1} />
            </Form.Item>

            <Form.Item
              label={<span className="text-bold">{i18n.translate(`newsletters.form.fields.issuedAt`)}</span>}
              name="issuedAt"
              rules={[{ required: true, message: i18n.translate(`genericFormMsg.required`) }]}
            >
              {/** DISABLED DATE ISPOD AKO TREBA */}
              <DatePicker
                style={{ borderRadius: 0 }}
                picker="month"
                locale={userAuth.lang == 'hr' ? localeHR : localeEN}
                format={DisplayDateMonthFormat}
              />
            </Form.Item>

            <Form.Item
              label={<span className="text-bold">{i18n.translate(`newsletters.form.fields.name`)}</span>}
              name="name"
              rules={[{ required: true, message: i18n.translate(`genericFormMsg.required`) }]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 12 }}
            >
              <Input style={{ borderRadius: 0 }} />
            </Form.Item>

            <Form.Item name="actual" valuePropName="checked" wrapperCol={{ span: 12 }}>
              <Checkbox>
                <span style={{ paddingLeft: 4 }} className="text-bold">
                  {i18n.translate(`newsletters.form.fields.actual`)}
                </span>
              </Checkbox>
            </Form.Item>

            <Form.Item
              label={<span className="text-bold">{i18n.translate(`newsletters.form.fields.descriptionFive`)}</span>}
              name="descriptionFive"
              rules={[{ required: true, message: i18n.translate(`genericFormMsg.required`) }]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 12 }}
            >
              <TextArea style={{ borderRadius: 0 }} autoSize={{ minRows: 4 }} />
            </Form.Item>

            <Form.Item
              label={<span className="text-bold">{i18n.translate(`newsletters.form.fields.descriptionEight`)}</span>}
              name="descriptionEight"
              rules={[{ required: true, message: i18n.translate(`genericFormMsg.required`) }]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 12 }}
            >
              <TextArea style={{ borderRadius: 0 }} autoSize={{ minRows: 4 }} />
            </Form.Item>

            <Form.Item
              label={<span className="text-bold">{i18n.translate(`newsletters.form.fields.goalText`)}</span>}
              name="goalText"
              rules={[{ required: true, message: i18n.translate(`genericFormMsg.required`) }]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 12 }}
            >
              <TextArea style={{ borderRadius: 0 }} autoSize={{ minRows: 4 }} />
            </Form.Item>

            <Form.Item
              label={<span className="text-bold">{i18n.translate(`newsletters.form.fields.resolvesText`)}</span>}
              name="resolvesText"
              rules={[{ required: true, message: i18n.translate(`genericFormMsg.required`) }]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 12 }}
            >
              <TextArea style={{ borderRadius: 0 }} autoSize={{ minRows: 4 }} />
            </Form.Item>

            <Form.Item
              label={<span className="text-bold">{i18n.translate(`newsletters.form.fields.hiddenContent`)}</span>}
              name="hiddenContent"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 12 }}
            >
              <TextArea style={{ borderRadius: 0 }} autoSize={{ minRows: 4 }} />
            </Form.Item>

            <div className="subtitle">
              <span className="text">{i18n.translate('newsletters.form.sections.printedEdition')}</span>
            </div>

            <Form.Item
              label={<span className="text-bold">{i18n.translate(`newsletters.form.fields.printEditionStock`)}</span>}
              name="printEditionStock"
              rules={[{ required: true, message: i18n.translate(`genericFormMsg.required`) }]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 12 }}
            >
              <InputNumber min={0} />
            </Form.Item>

            <Form.Item
              label={<span className="text-bold">{i18n.translate(`newsletters.form.fields.availability`)}</span>}
              name="availability"
              rules={[{ required: true, message: i18n.translate(`genericFormMsg.required`) }]}
            >
              <Radio.Group>
                <Radio.Button value={true}>{i18n.translate(`newsletters.form.fields.options.available`)}</Radio.Button>
                <Radio.Button value={false}>
                  {i18n.translate(`newsletters.form.fields.options.unavailable`)}
                </Radio.Button>
              </Radio.Group>
            </Form.Item>

            <Form.Item
              label={<span className="text-bold">{i18n.translate(`newsletters.form.fields.price`)}</span>}
              name="price"
              rules={[{ required: true, message: i18n.translate(`genericFormMsg.required`) }]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 12 }}
            >
              <InputNumber min={0} />
            </Form.Item>

            <div className="subtitle">
              <span className="text">{i18n.translate('newsletters.form.sections.filters')}</span>
            </div>

            {/** OVDJE ISPOD SVI FILTERI TREBAJU BITI GENERIČKI */}

            {tags.tagScreens?.map((tag) => (
              <Form.Item
                label={<span className="text-bold">{`${tag.filter.name}`}</span>}
                name={tag.filter.name}
                key={tag.filter.name}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 8 }}
              >
                {returnComponent(tag)}
              </Form.Item>
            ))}

            {/** GENERIC FILTERI END */}

            <div className="subtitle">
              <span className="text">{i18n.translate('newsletters.form.sections.upload')}</span>
            </div>

            <Form.Item
              label={<span className="text-bold">{i18n.translate(`newsletters.form.fields.frontPdf`)}</span>}
              name="frontPdf"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 10 }}
            >
              {frontPageUrl ? <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 8}}><img style={{maxWidth: 200, maxHeight: 200}} src={frontPageUrl} alt="BJN" /></div> : null}
              <Dragger {...propsFront}>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">{i18n.translate(`newsletters.form.upload.text`)}</p>
              </Dragger>
            </Form.Item>

            {frontFileList && frontFileList.length > 0 && (
              <div>
                <Button type="link" style={{ color: 'red', marginBottom: 8 }} onClick={onFrontFileRemove}>
                  {i18n.translate(`genericButtons.delete`)}
                </Button>
                <Button type="link" style={{ marginLeft: 8, marginBottom: 8 }} onClick={onFrontFileDownload}>
                  {i18n.translate(`genericButtons.download`)}
                </Button>
              </div>
            )}

            <Form.Item
              label={<span className="text-bold">{i18n.translate(`newsletters.form.fields.pdf`)}</span>}
              name="pdf"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 10 }}
            >
              <Dragger {...props}>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">{i18n.translate(`newsletters.form.upload.text`)}</p>
              </Dragger>
            </Form.Item>

            {fileList && fileList.length > 0 && (
              <div>
                <Button type="link" style={{ color: 'red', marginBottom: 8 }} onClick={onFileRemove}>
                  {i18n.translate(`genericButtons.delete`)}
                </Button>
                <Button type="link" style={{ marginLeft: 8, marginBottom: 8 }} onClick={onFileDownload}>
                  {i18n.translate(`genericButtons.download`)}
                </Button>
              </div>
            )}

            <Form.Item
              label={<span className="text-bold">{i18n.translate(`newsletters.form.fields.contentPdf`)}</span>}
              name="contentPdf"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 10 }}
            >
              <Dragger {...propsContent}>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">{i18n.translate(`newsletters.form.upload.text`)}</p>
              </Dragger>
            </Form.Item>

            {contentFileList && contentFileList.length > 0 && (
              <div>
                <Button type="link" style={{ color: 'red', marginBottom: 8 }} onClick={onContentFileRemove}>
                  {i18n.translate(`genericButtons.delete`)}
                </Button>
                <Button type="link" style={{ marginLeft: 8, marginBottom: 8 }} onClick={onContentFileDownload}>
                  {i18n.translate(`genericButtons.download`)}
                </Button>
              </div>
            )}

          </div>
          <div className="newslettersCrud__bottomButtons">
            <div>
              {!isNew && (
                <Button
                  danger
                  onClick={showConfirm}
                  className="button"
                  size="large"
                  icon={<DeleteOutlined />}
                  style={{ fontSize: 16, fontWeight: 700, borderRadius: 0, minWidth: 193 }}
                >
                  {i18n.translate('genericButtons.delete')}
                </Button>
              )}
            </div>
            <div>
              <Link to={AdminRoutes.NEWSLETTERS.fullPath} key={AdminRoutes.NEWSLETTERS.path}>
                <Button
                  onClick={clearCurrentBilten}
                  className="button"
                  size="large"
                  style={{ fontSize: 16, fontWeight: 700, borderRadius: 0, minWidth: 193, marginRight: 16 }}
                >
                  {i18n.translate('genericButtons.cancel')}
                </Button>
              </Link>
              <Button
                className="button"
                htmlType="submit"
                type="primary"
                size="large"
                style={{ fontSize: 16, fontWeight: 700, borderRadius: 0, minWidth: 193 }}
              >
                {i18n.translate('genericButtons.save')}
              </Button>
            </div>
          </div>
        </Form>
      )}
    </div>
  );
}

export default NewslettersCrudPageComponent;
