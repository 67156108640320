import { Button, Col, Divider, Row, Typography, notification, Popover } from 'antd';
import React from 'react';
import { FilePdfOutlined, DownloadOutlined, EditOutlined, ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';
import { LicenceInterface, RoadmapInterface } from '../../../interfaces';
import { Link } from 'react-router-dom';
import { AdminRoutes } from '../../../../features/admin';
import { FileService, UserActivityLogService } from '../../../api';
import { i18n, ViewerService } from '../../../services';
import { useSelector } from 'react-redux';
import { StoreStateInterface } from '../../../redux';
import { UserRoleEnum } from '../../../enums';
import WorkflowCardImage from './workflowCardImage.component';
import { LicenceEnum } from '../../../enums/licence.enum';
import { UserActionTypeEnum } from '../../../enums/userActionType.enum';

const { Paragraph, Title } = Typography;

interface Props {
  workflow?: RoadmapInterface;
  maxLength?: number;
  onPositionChange?: (workflow: RoadmapInterface, up: boolean) => void;
  licenceData?: LicenceInterface;
  validLicences?: LicenceInterface[];
}

export default function WorkflowCardComponent({ workflow, maxLength, onPositionChange, licenceData, validLicences }: Props) {
  const userAuth = useSelector((state: StoreStateInterface) => state.auth);

  const onPositionsChange = (workflow: RoadmapInterface, up: boolean) => {
    if (up) {
      if (workflow?.attributes?.position > 1) {
        let newWorkflow = workflow;
        newWorkflow.attributes.position = workflow.attributes?.position - 1;
        onPositionChange && onPositionChange(newWorkflow, up);
      }
    } else {
      if (workflow?.attributes?.position < (maxLength as number)) {
        let newWorkflow = workflow;
        newWorkflow.attributes.position = workflow.attributes?.position + 1;
        onPositionChange && onPositionChange(newWorkflow, up);
      }
    }
  };

  const onFileDownload = (download: boolean) => {
    if (!download) {
      if (!workflow) return;
      if (userAuth?.user?.roles !== UserRoleEnum.ADMIN) {
        let userActivityLogData: any = {
          data: {
            type: 'user_activity_log',
            attributes: {
              action: UserActionTypeEnum.FILE_OPENED,
              extra: JSON.stringify({type: 'roadmap', title: workflow.attributes?.name})
            },
            relationships: {
              user: {
                data: {
                  id: userAuth?.user?.id,
                  type: 'user'
                },
              },
            },
          },
        };
  
        UserActivityLogService.create(userActivityLogData);
      }
      ViewerService.goToPdf(workflow?.attributes?.pdfUuid);
      return;
    }

    FileService.temp(workflow?.attributes?.pdfUuid as string).subscribe(
      (response: any) => {
        if (response.url) {
          notification['success']({ message: i18n.translate('api.downloadSuccessMessage'), duration: 2 });
          let url = response.url + '/bjn';
          if (download) {
            if (workflow && userAuth?.user?.roles !== UserRoleEnum.ADMIN) {
              let userActivityLogData: any = {
                data: {
                  type: 'user_activity_log',
                  attributes: {
                    action: UserActionTypeEnum.FILE_DOWNLOADED,
                    extra: JSON.stringify({type: 'roadmap', title: workflow.attributes?.name})
                  },
                  relationships: {
                    user: {
                      data: {
                        id: userAuth?.user?.id,
                        type: 'user'
                      },
                    },
                  },
                },
              };
        
              UserActivityLogService.create(userActivityLogData);
            }
            var link = document.createElement('a');
            link.href = response.url;
            link.download = 'file.pdf';
            link.dispatchEvent(new MouseEvent('click'));
          } else {
            window.open(url, '_blank');
          }
        } else {
          notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
        }
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
      }
    );
  };

  const checkLicenceYear = (download: boolean) => {
    if (userAuth.user?.roles === UserRoleEnum.ADMIN) return true;

    if (validLicences?.length && download && validLicences?.find(x => x.attributes?.licenceType === LicenceEnum.PREMIUM)) {
      return true;
    } else if (validLicences?.length && !download) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div className="workflowCard">
      <Row>
        {onPositionChange && (
          <Col className="arrowButtons" span={24} md={1}>
            <ArrowUpOutlined
              onClick={() => onPositionsChange(workflow as RoadmapInterface, true)}
              className="link"
              style={{ fontSize: 16 }}
            />
            <ArrowDownOutlined
              onClick={() => onPositionsChange(workflow as RoadmapInterface, false)}
              className="link"
              style={{ fontSize: 16 }}
            />
          </Col>
        )}
        <Col span={24} md={4}>
          <WorkflowCardImage licenceData={licenceData} workflow={workflow} missingAccess={!checkLicenceYear(false)} />
        </Col>
        <Col span={24} md={15}>
          <Title level={4}>{workflow?.attributes?.name}</Title>
          <Paragraph>{workflow?.attributes?.description}</Paragraph>
        </Col>
        <Col span={24} md={4}>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            {workflow?.attributes?.pdfUuid ? (
              !checkLicenceYear(false) ? (
                <Popover
                  placement="topRight"
                  content={i18n.translate(`common.licence.content`)}
                  title={i18n.translate(`common.licence.title`)}
                >
                  <Button disabled type="link" className="cardBtn" icon={<FilePdfOutlined />} />
                </Popover>
              ) : (
                <Button
                  onClick={() => onFileDownload(false)}
                  type="link"
                  className="cardBtn"
                  icon={<FilePdfOutlined />}
                />
              )
            ) : (
              <Popover
                placement="topRight"
                content={i18n.translate(`common.licence.content`)}
                title={i18n.translate(`common.licence.title`)}
              >
                <Button disabled type="link" className="cardBtn" icon={<FilePdfOutlined />} />
              </Popover>
            )}
            {workflow?.attributes?.pdfUuid ? (
              checkLicenceYear(true) ? (
                <Button
                  onClick={() => onFileDownload(true)}
                  type="link"
                  className="cardBtn"
                  icon={<DownloadOutlined />}
                />
              ) : (
                <Popover
                  placement="topRight"
                  content={i18n.translate(`common.licence.content`)}
                  title={i18n.translate(`common.licence.title`)}
                >
                  <Button disabled type="link" className="cardBtn" icon={<DownloadOutlined />} />
                </Popover>
              )
            ) : (
              <Popover
                placement="topRight"
                content={i18n.translate(`common.licence.content`)}
                title={i18n.translate(`common.licence.title`)}
              >
                <Button disabled type="link" className="cardBtn" icon={<DownloadOutlined />} />
              </Popover>
            )}
            {userAuth.user?.roles === UserRoleEnum.ADMIN && (
              <Link
                to={AdminRoutes.WORKFLOWS.path + `/${workflow?.id}`}
                key={AdminRoutes.WORKFLOWS.path + `/${workflow?.id}`}
              >
                <Button type="link" className="cardBtn" icon={<EditOutlined />} />
              </Link>
            )}
          </div>
        </Col>
      </Row>
      <Divider />
    </div>
  );
}
