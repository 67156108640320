import { Dispatch } from 'redux';
import { notification } from 'antd';
import { ProfessionalArticleConstants } from './professionalArticle.constants';

import { ProfessionalArticleService } from '../../api/api.service';
import { ProfessionalArticleResponseInterfaceAll, ProfessionalArticleResponseInterfaceSingle } from '../../interfaces';
import { i18n } from '../../services';

export function getAllProfessionalArticlesAction(params?: any, filter?: any, sort?: any, pagination?: any) {
  return (dispatch: Dispatch) => {
    return ProfessionalArticleService.getAll(params, filter, sort, pagination).subscribe(
      (response: ProfessionalArticleResponseInterfaceAll) => {
        dispatch({
          type: ProfessionalArticleConstants.GET_ALL_SUCCESS,
          payload: response,
        });
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });

        dispatch({
          type: ProfessionalArticleConstants.ERROR,
          payload: error,
        });
      }
    );
  };
}

export function getSingleProfessionalArticleAction(id: number) {
  return (dispatch: Dispatch) => {
    return ProfessionalArticleService.getSingle(id).subscribe(
      (response: ProfessionalArticleResponseInterfaceSingle) => {
        dispatch({
          type: ProfessionalArticleConstants.GET_SINGLE_SUCCESS,
          payload: response,
        });
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });

        dispatch({
          type: ProfessionalArticleConstants.ERROR,
          payload: error,
        });
      }
    );
  };
}

export function createProfessionalArticleAction(body: ProfessionalArticleResponseInterfaceSingle) {
  return (dispatch: Dispatch) => {
    return ProfessionalArticleService.create(body).subscribe(
      (response: ProfessionalArticleResponseInterfaceSingle) => {
        notification['success']({ message: i18n.translate('api.successMessage'), duration: 2 });

        dispatch({
          type: ProfessionalArticleConstants.CREATE_SUCCESS,
          payload: response,
        });
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });

        dispatch({
          type: ProfessionalArticleConstants.ERROR,
          payload: error,
        });
      }
    );
  };
}

export function updateProfessionalArticleAction(body: ProfessionalArticleResponseInterfaceSingle) {
  return (dispatch: Dispatch) => {
    return ProfessionalArticleService.update(body).subscribe(
      (response: ProfessionalArticleResponseInterfaceSingle) => {
        notification['success']({ message: i18n.translate('api.successMessage'), duration: 2 });

        dispatch({
          type: ProfessionalArticleConstants.UPDATE_SUCCESS,
          payload: response,
        });
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });

        dispatch({
          type: ProfessionalArticleConstants.ERROR,
          payload: error,
        });
      }
    );
  };
}

export function deleteProfessionalArticleAction(id: number) {
  return (dispatch: Dispatch) => {
    return ProfessionalArticleService.delete(id).subscribe(
      (response: any) => {
        dispatch({
          type: ProfessionalArticleConstants.DELETE
        });
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });

        dispatch({
          type: ProfessionalArticleConstants.ERROR,
          payload: error,
        });
      }
    );
  };
}

export function clearProfessionalArticleAction() {
  return (dispatch: Dispatch) => {
    dispatch({ type: ProfessionalArticleConstants.CLEAR_CURRENT });
  };
}

export function clearProfessionalArticleListAction() {
  return (dispatch: Dispatch) => {
    dispatch({ type: ProfessionalArticleConstants.CLEAR_LIST });
  };
}