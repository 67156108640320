import { RegulationConstants } from './regulation.constants';
import { RegulationInterface, RegulationResponseInterfaceAll, RegulationResponseInterfaceSingle, ReducerActionInterface } from '../../interfaces';

// ============ INTERFACE ============

export interface RegulationStateInterface {
  currentRegulation?: RegulationInterface;
  regulations?: RegulationResponseInterfaceAll;
  error?: string;
}

// ============ INIT STATE ============

const initialState: RegulationStateInterface = {
  currentRegulation: undefined,
  regulations: {
    data: [],
    meta: {
      totalResourceCount: 0
    }
  }
}

// ============ REDUCERS ============

const getSingleSuccess = (state: RegulationStateInterface, payload: RegulationResponseInterfaceSingle): RegulationStateInterface => {
  return {
    ...state,
    currentRegulation: payload.data,
  };
};

const getAllSuccess = (state: RegulationStateInterface, payload: RegulationResponseInterfaceAll): RegulationStateInterface => {
  return {
    ...state,
    regulations: {
      data: payload.data,
      meta: payload.meta
    }
  };
};

const createSuccess = (state: RegulationStateInterface, payload: RegulationResponseInterfaceSingle): RegulationStateInterface => {
  return {
    ...state,
    currentRegulation: payload.data,
  };
};

const updateSuccess = (state: RegulationStateInterface, payload: RegulationResponseInterfaceSingle): RegulationStateInterface => {
  return {
    ...state,
    currentRegulation: payload.data,
  };
};

const clearCurrent = (state: RegulationStateInterface): RegulationStateInterface => {
  return {
    ...state,
    currentRegulation: undefined,
  };
};

const clearList = (state: RegulationStateInterface): RegulationStateInterface => {
  return {
    ...state,
    regulations: {
      data: [],
      meta: {
        totalResourceCount: 0
      }
    }
  };
};

const deleteSuccess = (state: RegulationStateInterface): RegulationStateInterface => {
  return {
    ...state,
    currentRegulation: undefined,
  };
};

const error = (state: RegulationStateInterface, payload: string) => {
  return {
    ...state,
    error: payload,
  };
};

// ============ EXPORTS ============

export const RegulationReducer = (state = initialState, action: ReducerActionInterface) => {
  switch (action.type) {
    case RegulationConstants.GET_SINGLE_SUCCESS:
      return getSingleSuccess(state, action.payload);
    case RegulationConstants.GET_ALL_SUCCESS:
      return getAllSuccess(state, action.payload);
    case RegulationConstants.CREATE_SUCCESS:
      return createSuccess(state, action.payload);
    case RegulationConstants.UPDATE_SUCCESS:
      return updateSuccess(state, action.payload);
    case RegulationConstants.CLEAR_CURRENT:
      return clearCurrent(state);
    case RegulationConstants.CLEAR_LIST:
      return clearList(state);
    case RegulationConstants.DELETE:
      return deleteSuccess(state);
    case RegulationConstants.ERROR:
      return error(state, action.payload);
    default:
      return state;
  }
};
