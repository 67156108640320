import { Dispatch } from 'redux';
import { notification } from 'antd';
import { AllegationConstants } from './allegation.constants';

import { AllegationService } from '../../api/api.service';
import { AllegationResponseInterfaceAll, AllegationResponseInterfaceSingle } from '../../interfaces';
import { i18n } from '../../services';

export function getAllAllegationsAction(params?: any, filter?: any, sort?: any, pagination?: any) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: AllegationConstants.SET_LOADER,
      payload: true
    });

    dispatch({
      type: AllegationConstants.GET_ALL_SUCCESS,
      payload: {
        data: [],
        meta: {}
      },
    });

    return AllegationService.getAll(params, filter, sort, pagination).subscribe(
      (response: AllegationResponseInterfaceAll) => {
        dispatch({
          type: AllegationConstants.GET_ALL_SUCCESS,
          payload: response,
        });
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });

        dispatch({
          type: AllegationConstants.ERROR,
          payload: error,
        });
      }
    );
  };
}

export function getSingleAllegationAction(id: number) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: AllegationConstants.SET_LOADER,
      payload: true
    });

    return AllegationService.getSingle(id).subscribe(
      (response: AllegationResponseInterfaceSingle) => {
        dispatch({
          type: AllegationConstants.GET_SINGLE_SUCCESS,
          payload: response,
        });
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });

        dispatch({
          type: AllegationConstants.ERROR,
          payload: error,
        });
      }
    );
  };
}

export function createAllegationAction(body: AllegationResponseInterfaceSingle, showNotif: boolean = true) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: AllegationConstants.SET_LOADER,
      payload: true
    });

    return AllegationService.create(body).subscribe(
      (response: AllegationResponseInterfaceSingle) => {
        if (showNotif) notification['success']({ message: i18n.translate('api.successMessage'), duration: 2 });

        dispatch({
          type: AllegationConstants.CREATE_SUCCESS,
          payload: response,
        });
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });

        dispatch({
          type: AllegationConstants.ERROR,
          payload: error,
        });
      }
    );
  };
}

export function updateAllegationAction(body: AllegationResponseInterfaceSingle, showNotif: boolean = true) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: AllegationConstants.SET_LOADER,
      payload: true
    });
    return AllegationService.update(body).subscribe(
      (response: AllegationResponseInterfaceSingle) => {
        if (showNotif) notification['success']({ message: i18n.translate('api.successMessage'), duration: 2 });

        dispatch({
          type: AllegationConstants.UPDATE_SUCCESS,
          payload: response,
        });
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });

        dispatch({
          type: AllegationConstants.ERROR,
          payload: error,
        });
      }
    );
  };
}

export function deleteAllegationAction(id: number) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: AllegationConstants.SET_LOADER,
      payload: true
    });

    return AllegationService.delete(id).subscribe(
      (response: any) => {
        dispatch({
          type: AllegationConstants.DELETE
        });
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });

        dispatch({
          type: AllegationConstants.ERROR,
          payload: error,
        });
      }
    );
  };
}

export function clearAllegationAction() {
  return (dispatch: Dispatch) => {
    dispatch({ type: AllegationConstants.CLEAR_CURRENT });
  };
}

export function clearAllegationListAction() {
  return (dispatch: Dispatch) => {
    dispatch({ type: AllegationConstants.CLEAR_LIST });
  };
}
