import React, { useEffect, useState } from 'react';
import { Layout } from 'antd';
import { Route, useRouteMatch, Switch, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppRoutes } from './app.routes';
import {
  ContentComponent,
  getAllNotesAction,
  HeaderComponent,
  NotFoundPageComponent,
  SiderComponent,
  StoreStateInterface,
  UserRoleEnum,
} from '../../../common';
import DashboardPageComponent from '../dashboard/dashboardPage.component';
import NewslettersPageComponent from '../newsletters/newslettersPage.component';
import FaqPageComponent from '../faq/faqPage.component';
import InstitutionalOpinionsPageComponent from '../institutionalOpinions/institutionalOpinionsPage.component';
import ExpertArticlesPageComponent from '../expertArticles/expertArticlesPage.component';
import RegulationsPageComponent from '../regulations/regulationsPage.component';
import WorkflowsPageComponent from '../workflows/workflowsPage.component';
import { AuthRoutes } from '../../auth';
import NotesPageComponent from '../notes/notesDash/notesPage.component';
import NoteViewPageComponent from '../notes/noteView/noteView.component';
import NewsletterViewPageComponent from '../newsletters/newsletterView/newsletterView.component';
import DKOMDecisionsPageComponent from '../rhPractice/dkom/dkomDecisionsPage.component';
import VUSDeclarationsPageComponent from '../rhPractice/vus/vusDeclarationsPage.component';
import EUCourtDeclarationsPageComponent from '../euPractice/euCourt/euCourtDeclarationsPage.component';
import ProMaterialsPageComponent from '../euPractice/materials/proMaterialsPage.component';
import UserProfilePageComponent from '../userProfile/userProfilePage.component';
import NotesCrudPageComponent from '../notes/noteView/crud/notesCrudPage.component';
import InstructionsPageComponent from '../instructions/instructionsPage.component';

function AppRouter() {
  const userAuth = useSelector((state: StoreStateInterface) => state.auth);
  const dispatch = useDispatch();
  const { path } = useRouteMatch();

  useEffect(() => {
    dispatch(getAllNotesAction(userAuth.user?.id));
  }, [userAuth?.user?.id]);

  const [collapsed, setCollapsed] = useState(false);

  let routes = [
    {
      path: AppRoutes.DASHBOARD.path,
      component: DashboardPageComponent,
      roles: [UserRoleEnum.USER, UserRoleEnum.MANAGER],
      exact: true,
    },
    {
      path: AppRoutes.NEWSLETTERS.path,
      component: NewslettersPageComponent,
      roles: [UserRoleEnum.USER, UserRoleEnum.MANAGER],
      exact: true,
    },
    {
      path: AppRoutes.NEWSLETTERS_VIEW.path + '/:id',
      component: NewsletterViewPageComponent,
      roles: [UserRoleEnum.USER, UserRoleEnum.MANAGER],
      exact: true,
    },
    {
      path: AppRoutes.FAQ.path,
      component: FaqPageComponent,
      roles: [UserRoleEnum.USER, UserRoleEnum.MANAGER],
      exact: true,
    },
    {
      path: AppRoutes.INSTITUTIONAL_OPINIONS.path,
      component: InstitutionalOpinionsPageComponent,
      roles: [UserRoleEnum.USER, UserRoleEnum.MANAGER],
      exact: true,
    },
    {
      path: AppRoutes.EXPERT_ARTICLES.path,
      component: ExpertArticlesPageComponent,
      roles: [UserRoleEnum.USER, UserRoleEnum.MANAGER],
      exact: true,
    },
    {
      path: AppRoutes.REGULATIONS.path,
      component: RegulationsPageComponent,
      roles: [UserRoleEnum.USER, UserRoleEnum.MANAGER],
      exact: true,
    },
    {
      path: AppRoutes.WORKFLOWS.path,
      component: WorkflowsPageComponent,
      roles: [UserRoleEnum.USER, UserRoleEnum.MANAGER],
      exact: true,
    },
    {
      path: AppRoutes.DKOM_DECISIONS.path,
      component: DKOMDecisionsPageComponent,
      roles: [UserRoleEnum.USER, UserRoleEnum.MANAGER],
      exact: true,
    },
    {
      path: AppRoutes.VUS_DECLARATIONS.path,
      component: VUSDeclarationsPageComponent,
      roles: [UserRoleEnum.USER, UserRoleEnum.MANAGER],
      exact: true,
    },
    {
      path: AppRoutes.EU_COURT_DECLARATIONS.path,
      component: EUCourtDeclarationsPageComponent,
      roles: [UserRoleEnum.USER, UserRoleEnum.MANAGER],
      exact: true,
    },
    {
      path: AppRoutes.PRO_MATERIALS.path,
      component: ProMaterialsPageComponent,
      roles: [UserRoleEnum.USER, UserRoleEnum.MANAGER],
      exact: true,
    },
    {
      path: AppRoutes.NOTES.path,
      component: NotesPageComponent,
      roles: [UserRoleEnum.USER, UserRoleEnum.MANAGER],
      exact: true,
    },
    {
      path: AppRoutes.NOTES.path + '/:id',
      component: NoteViewPageComponent,
      roles: [UserRoleEnum.USER, UserRoleEnum.MANAGER],
      exact: true,
    },
    {
      path: AppRoutes.NOTES.path + '/:id/edit',
      component: NotesCrudPageComponent,
      roles: [UserRoleEnum.USER, UserRoleEnum.MANAGER],
      exact: true,
    },
    {
      path: AppRoutes.USER_PROFILE.path,
      component: UserProfilePageComponent,
      roles: [UserRoleEnum.USER, UserRoleEnum.MANAGER],
      exact: true,
    },
    {
      path: AppRoutes.INSTRUCTIONS.path,
      component: InstructionsPageComponent,
      roles: [UserRoleEnum.USER, UserRoleEnum.MANAGER],
      exact: true,
    },
  ];

  const allowedRoutes = routes.filter((r) => {
    if (r.roles.length === 0) return true;
    if (!userAuth.isAuthenticated || !userAuth.user) return false;
    if (r.roles.includes(userAuth.user.roles)) return true;

    return false;
  });

  return (
    <Layout className="layout" hasSider>
      <SiderComponent isAdmin={false} collapsed={collapsed} onBreakpointChange={() => { setCollapsed(true) }}></SiderComponent>
      <Layout className="main">
        <HeaderComponent collapsed={collapsed} setCollapsed={setCollapsed}></HeaderComponent>
        <ContentComponent className="content content-container">
          <Switch>
            {allowedRoutes.map((route, index) => (
              <Route exact={route.exact} path={`${path}/${route.path}`} key={index}>
                {' '}
                <route.component />{' '}
              </Route>
            ))}
            {userAuth.isAuthenticated ? <Route component={NotFoundPageComponent} /> : <Redirect to={AuthRoutes.LOGIN.fullPath} />}
          </Switch>
        </ContentComponent>
      </Layout>
    </Layout>
  );
}

export default AppRouter;
