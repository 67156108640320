import { Dispatch } from 'redux';
import { notification } from 'antd';
import { LicenceConstants } from './licence.constants';

import { LicenceService } from '../../api/api.service';
import { LicenceResponseInterfaceAll, LicenceResponseInterfaceSingle } from '../../interfaces';
import { i18n } from '../../services';

export function getAllLicencesAction(params?: any, filter?: any, sort?: any, pagination?: any) {
  return (dispatch: Dispatch) => {
    return LicenceService.getAll(params, filter, sort, pagination).subscribe(
      (response: LicenceResponseInterfaceAll) => {
        dispatch({
          type: LicenceConstants.GET_ALL_SUCCESS,
          payload: response,
        });
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });

        dispatch({
          type: LicenceConstants.ERROR,
          payload: error,
        });
      }
    );
  };
}

export function getSingleLicenceAction(id: number) {
  return (dispatch: Dispatch) => {
    return LicenceService.getSingle(id).subscribe(
      (response: LicenceResponseInterfaceSingle) => {
        dispatch({
          type: LicenceConstants.GET_SINGLE_SUCCESS,
          payload: response,
        });
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });

        dispatch({
          type: LicenceConstants.ERROR,
          payload: error,
        });
      }
    );
  };
}

export function createLicenceAction(body: any, hideSuccess?: boolean) {
  return (dispatch: Dispatch) => {
    return LicenceService.create(body).subscribe(
      (response: LicenceResponseInterfaceSingle) => {
        if (!hideSuccess) notification['success']({ message: i18n.translate('api.successMessage'), duration: 2 });

        dispatch({
          type: LicenceConstants.CREATE_SUCCESS,
          payload: response,
        });
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });

        dispatch({
          type: LicenceConstants.ERROR,
          payload: error,
        });
      }
    );
  };
}

export function updateLicenceAction(body: LicenceResponseInterfaceSingle) {
  return (dispatch: Dispatch) => {
    return LicenceService.update(body).subscribe(
      (response: LicenceResponseInterfaceSingle) => {
        notification['success']({ message: i18n.translate('api.successMessage'), duration: 2 });

        dispatch({
          type: LicenceConstants.UPDATE_SUCCESS,
          payload: response,
        });
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });

        dispatch({
          type: LicenceConstants.ERROR,
          payload: error,
        });
      }
    );
  };
}

export function deleteLicenceAction(id: number) {
  return (dispatch: Dispatch) => {
    return LicenceService.delete(id).subscribe(
      (response: any) => {
        notification['success']({ message: i18n.translate('api.successDelete'), duration: 2 });
        
        dispatch({
          type: LicenceConstants.DELETE
        });
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });

        dispatch({
          type: LicenceConstants.ERROR,
          payload: error,
        });
      }
    );
  };
}

export function clearLicenceAction() {
  return (dispatch: Dispatch) => {
    dispatch({ type: LicenceConstants.CLEAR_CURRENT });
  };
}

export function clearLicenceListAction() {
  return (dispatch: Dispatch) => {
    dispatch({ type: LicenceConstants.CLEAR_LIST });
  };
}
