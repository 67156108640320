import { CommentOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Breadcrumb, Button, Col, Form, Modal, notification, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteNoteLinkAction,
  getAllNoteLinksAction,
  i18n,
  NoteInterface,
  NoteLinkStateInterface,
  NoteModal,
  NoteResponseInterfaceSingle,
  NotesComponent,
  StoreStateInterface,
} from '../../../../common';
import {
  clearNoteAction,
  createNoteAction,
  deleteNoteAction,
  getAllNotesAction,
  NoteStateInterface,
} from '../../../../common/redux/notes';

function NotesPageComponent() {
  const { confirm } = Modal;

  const dispatch = useDispatch();

  const userAuth = useSelector((state: StoreStateInterface) => state.auth);

  const notes: NoteStateInterface = useSelector((state: StoreStateInterface) => state.notes);

  const noteLinkData: NoteLinkStateInterface = useSelector((state: StoreStateInterface) => state.notesLinks);

  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    localStorage.removeItem('transferFilters');
    clearNoteAction();
    dispatch(getAllNotesAction(userAuth.user?.id));
  }, []);

  const refresh = () => {
    clearNoteAction();
    dispatch(getAllNotesAction(userAuth.user?.id));
  };

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
    refresh();
  };

  const handleCancel = () => {
    setTimeout(() => {
      setVisible(false);
      refresh();
    }, 1000);
  };

  const showConfirm = (id: number) => {
    confirm({
      title: i18n.t('notes.confirmDelete'),
      icon: <ExclamationCircleOutlined />,
      content: i18n.t('common.cantUndo'),
      okText: i18n.t('genericButtons.yes'),
      cancelText: i18n.t('genericButtons.cancel'),
      onOk() {
        dispatch(getAllNoteLinksAction(id));
        for (let i = 0; i <= (noteLinkData?.notesLinks?.data?.length as number); i++) {
          noteLinkData?.notesLinks?.data?.forEach((link) => {
            dispatch(deleteNoteLinkAction(link?.id as number));
          });
        }
        
        notification['success']({ message: i18n.translate('api.successDelete'), duration: 2 });
        dispatch(deleteNoteAction(id));

        setTimeout(() => {
          dispatch(getAllNotesAction(userAuth.user?.id));
        }, 1000);
        
      },
    });
  };

  const onFinish = (values: any) => {
    let newNote: NoteInterface = {
      type: 'notes',
      attributes: {
        title: values.title,
        text: values.text,
        type: 'note',
        breadcrumb: '--',
        isEditable: true,
        active: true,
      },
      relationships: {
        user: {
          data: {
            id: userAuth.user?.id,
            type: 'user',
          },
        },
      },
    };

    let newNoteResponse: NoteResponseInterfaceSingle = {
      data: newNote,
    };

    dispatch(createNoteAction(newNoteResponse, userAuth.user?.id));
    setVisible(false);
    // refresh();
  };

  return (
    <div className="notes w100-h100">
      <div className="notes___breadcrumbs">
        <Breadcrumb>
          <Breadcrumb.Item>
            <CommentOutlined />
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span>{i18n.translate('breadcrumbs.notes')}</span>
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <Row justify="space-between">
        <Col>
          <div className="notes__title">{i18n.translate('notes.title')}</div>
        </Col>
        <Col>
          <Button
            className="button"
            type="primary"
            size="large"
            onClick={showModal}
            style={{ fontSize: 16, fontWeight: 700, borderRadius: 0 }}
          >
            {i18n.translate('notes.create')}
          </Button>
        </Col>
      </Row>
      <div className="notes___content">
        {notes?.notes?.data
          .sort((a, b) => {
            return (
              new Date(a?.attributes?.modifiedDate as string).getTime() -
              new Date(b.attributes?.modifiedDate as string).getTime()
            );
          })
          .reverse()
          .map((note, i) => (
            <NotesComponent
              key={'note' + i}
              note={note}
              onDelete={() => {
                showConfirm(note?.id as number);
              }}
            />
          ))}
      </div>

      <NoteModal
        isNew={true}
        visible={visible}
        handleOk={handleOk}
        handleCancel={handleCancel}
        breadcrumb={'--'}
        type="note"
        onFinish={onFinish}
        form={form}
      />
    </div>
  );
}

export default NotesPageComponent;
