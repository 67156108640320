export enum SummaryConstants {
  SET_LOADER = 'SummaryConstants_SET_LOADER',
  GET_ALL_SUCCESS = 'SummaryConstants_GET_ALL_SUCCESS',
  GET_SINGLE_SUCCESS = 'SummaryConstants_GET_SINGLE_SUCCESS',
  CREATE_SUCCESS = 'SummaryConstants_CREATE_SUCCESS',
  UPDATE_SUCCESS = 'SummaryConstants_UPDATE_SUCCESS',
  CLEAR_CURRENT = 'SummaryConstants_CLEAR_CURRENT',
  CLEAR_LIST = 'SummaryConstants_CLEAR_LIST',
  DELETE = 'SummaryConstants_DELETE',
  ERROR = 'SummaryConstants_ERROR'
}
