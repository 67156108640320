import { Button, Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { clearVerdictAction, getSingleVerdictAction, i18n, NavigationService } from '../../../../../common';
import { AdminRoutes } from '../../../_router/admin.routes';
import VUSOrdersCrudPageComponent from './pages/orders/vusOrdersCrudPage.component';
import VUSResponsesCrudPageComponent from './pages/responses/vusResponsesCrudPage.component';
import VUSVerdictsCrudPageComponent from './pages/verdicts/vusVerdictsCrudPage.component';
import VUSSynopsisCrudPageComponent from './pages/synopsiss/vusSynopsisCrudPage.component';

function VUSMainCrudPageComponent() {
  const location = useLocation();

  const [selectedTab, setSelectedTab] = useState('verdict');
  const [enableTabs, setEnableTabs] = useState(false);
  const [id, setId] = useState<number | undefined>();

  const dispatch = useDispatch();

  useEffect(() => {
    const path = location.pathname.split('/');
    const id = parseInt(path[path.length - 1]);
    const isNew = isNaN(id);

    if (isNew) return;

    setId(id);
    dispatch(getSingleVerdictAction(id));
    setEnableTabs(true);
  }, []);

  const clearCurrentVerdict = () => {
    dispatch(clearVerdictAction());
  };

  const verdictCreated = (verdictId: number) => {
    setId(id);
    dispatch(getSingleVerdictAction(verdictId));
    setEnableTabs(true);
    setSelectedTab('order');
  };
  
  return (
    <div className="vusMainCrud w100-h100">
      <div className="vusVerdictsCrud__admin">
        <div className="title">
          {i18n.translate(!id ? 'verdicts.crudTitleAdd' : 'verdicts.crudTitleUpdate')}
        </div>
        <div className="buttons">
          <Link to={AdminRoutes.VUS_DECLARATIONS.fullPath} key={AdminRoutes.VUS_DECLARATIONS.path}>
            <Button
              onClick={clearCurrentVerdict}
              className="button"
              size="large"
              style={{ fontSize: 16, fontWeight: 700, borderRadius: 0, minWidth: 193, marginRight: 16 }}
            >
              {i18n.translate('genericButtons.cancel')}
            </Button>
          </Link>
        </div>
      </div>
      <Tabs activeKey={selectedTab} onChange={(activeKey) => setSelectedTab(activeKey)} destroyInactiveTabPane>
        <Tabs.TabPane tab={i18n.translate('verdicts.tabs.verdict')} key="verdict">
          <VUSVerdictsCrudPageComponent setTabOrder={verdictCreated} />
        </Tabs.TabPane>
        <Tabs.TabPane disabled={!enableTabs} tab={i18n.translate('verdicts.tabs.order')} key="order">
          <VUSOrdersCrudPageComponent setTabResponse={() => setSelectedTab('response')} />
        </Tabs.TabPane>
        <Tabs.TabPane disabled={!enableTabs} tab={i18n.translate('verdicts.tabs.response')} key="response">
          <VUSResponsesCrudPageComponent setTabSynopsis={() => setSelectedTab('synopsis')} />
        </Tabs.TabPane>
        <Tabs.TabPane disabled={!enableTabs} tab={i18n.translate('verdicts.tabs.synopsis')} key="synopsis">
          <VUSSynopsisCrudPageComponent />
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
}

export default VUSMainCrudPageComponent;
