import { Button, notification, Row, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { FAQCardComponent } from '../../index';
import { FaqInterface, LicenceInterface, NoteResponseInterfaceAll, TagScreenResponseInterface } from '../../../interfaces';
import { i18n } from '../../../services';
import { Link } from 'react-router-dom';
import { AdminRoutes } from '../../../../features/admin';
import { useSelector } from 'react-redux';
import { AppRoutes } from '../../../../features/app';
import { UserRoleEnum } from '../../../enums';
import { clearNoteAction, StoreStateInterface } from '../../../redux';
import { NoteService } from '../../../api';

const { Title } = Typography;

interface Props {
  faqs?: Array<FaqInterface['attributes']>;
  validLicences?: LicenceInterface[];
  tags?: TagScreenResponseInterface[];
}

export default function DashboardFAQComponent({ faqs, validLicences, tags }: Props) {
  const userAuth = useSelector((state: StoreStateInterface) => state.auth);

  const [noteData, setNoteData] = useState<NoteResponseInterfaceAll>();

  useEffect(() => {
    clearNoteAction();
    NoteService.getAll(userAuth.user?.id, false).subscribe(
      (response: NoteResponseInterfaceAll) => {
        setNoteData(response);
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
      }
    );
  }, [userAuth?.user?.id]);

  return (
    <div className="dashFaq">
      <Title className="title" level={3}>
        {i18n.translate('menu.faq')}
      </Title>

      {faqs && faqs?.length && faqs.length > 2 && (
        <div className="faqSection">
          <FAQCardComponent faqs={faqs.slice(0, 4)} isDashboard validLicences={validLicences} tags={tags} />
        </div>
      )}

      <Row justify="start">
        <Link to={userAuth.user?.roles === UserRoleEnum.ADMIN ? AdminRoutes.FAQ.fullPath : AppRoutes.FAQ.fullPath}>
          <Button type="primary" className="cardBtn">
            {i18n.translate(`dashboard.seeAll`)}
          </Button>
        </Link>
      </Row>
    </div>
  );
}
