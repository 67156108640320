export enum RoadmapConstants {
  GET_ALL_SUCCESS = 'RoadmapConstants_GET_ALL_SUCCESS',
  GET_SINGLE_SUCCESS = 'RoadmapConstants_GET_SINGLE_SUCCESS',
  CREATE_SUCCESS = 'RoadmapConstants_CREATE_SUCCESS',
  UPDATE_SUCCESS = 'RoadmapConstants_UPDATE_SUCCESS',
  CLEAR_CURRENT = 'RoadmapConstants_CLEAR_CURRENT',
  CLEAR_LIST = 'RoadmapConstants_CLEAR_LIST',
  DELETE = 'RoadmapConstants_DELETE',
  ERROR = 'RoadmapConstants_ERROR'
}
