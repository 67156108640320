export enum AppealConstants {
  SET_LOADER = 'AppealConstants_SET_LOADER',
  GET_ALL_SUCCESS = 'AppealConstants_GET_ALL_SUCCESS',
  GET_SINGLE_SUCCESS = 'AppealConstants_GET_SINGLE_SUCCESS',
  CREATE_SUCCESS = 'AppealConstants_CREATE_SUCCESS',
  UPDATE_SUCCESS = 'AppealConstants_UPDATE_SUCCESS',
  CLEAR_CURRENT = 'AppealConstants_CLEAR_CURRENT',
  CLEAR_LIST = 'AppealConstants_CLEAR_LIST',
  DELETE = 'AppealConstants_DELETE',
  ERROR = 'AppealConstants_ERROR'
}
