import { AuthConstants } from './auth.constants';
import { LoginResponseInterface, ReducerActionInterface, UserInterface } from '../../interfaces';

// ============ INTERFACE ============

export interface AuthStateInterface {
  lang: 'hr' | 'en';
  isAuthenticated: boolean;
  accessToken?: string;
  accessTokenValidTo?: string;
  refreshToken?: string;
  refreshTokenValidTo?: string;
  organizationId?: number;
  user?: UserInterface;
  error?: string;
}

export interface RegisterStateInterface {
  data: any;
  error?: string; 
  registrationStatus?: {
    timestamp: number;
    isSuccess: boolean
  }
}

// ============ INIT STATE ============

const initialState: AuthStateInterface = {
  lang: 'hr',
  isAuthenticated: false,
};

const initialStateRegister: RegisterStateInterface = {
  data: null
};

// ============ REDUCERS ============

const loginSuccess = (state: AuthStateInterface, payload: LoginResponseInterface): AuthStateInterface => {
  return {
    ...state,
    isAuthenticated: true,
    accessToken: payload.access_token,
    accessTokenValidTo: payload.accessTokenValidTo,
    refreshToken: payload.refresh_token,
    refreshTokenValidTo: payload.refreshTokenValidTo,
    organizationId: payload.organizationId,
    user: payload.user,
  };
};

const logoutSuccess = (state: AuthStateInterface, payload: LoginResponseInterface): AuthStateInterface => {
  return {
    ...state,
    isAuthenticated: false,
    accessToken: undefined,
    accessTokenValidTo: undefined,
    refreshToken: undefined,
    refreshTokenValidTo: undefined,
    organizationId: undefined,
    user: undefined,
  };
};

const registerStart = (state: RegisterStateInterface): RegisterStateInterface => {
  return {
    ...state,
    registrationStatus: undefined
  };
};

const registerSuccess = (state: RegisterStateInterface, payload: any): RegisterStateInterface => {
  return {
    ...state,
    data: payload,
    registrationStatus: {
      timestamp: new Date().valueOf(),
      isSuccess: true
    }
  };
};

const registerError = (state: RegisterStateInterface, payload: any): RegisterStateInterface => {
  return {
    ...state,
    data: payload,
    registrationStatus: {
      timestamp: new Date().valueOf(),
      isSuccess: false
    }
  };
};

const error = (state: AuthStateInterface, payload: string) => {
  return {
    ...state,
    error: payload,
  };
};

const setLang = (state: AuthStateInterface, payload: 'hr' | 'en'): AuthStateInterface => {
  return {
    ...state,
    lang: payload,
  };
};

const renewToken = (state: AuthStateInterface, payload: LoginResponseInterface): AuthStateInterface => {
  return {
    ...state,
    accessToken: payload.access_token,
    accessTokenValidTo: payload.accessTokenValidTo,
    refreshToken: payload.refresh_token,
    refreshTokenValidTo: payload.refreshTokenValidTo,
    organizationId: payload.organizationId,
    user: payload.user
  };
};

const set2fa = (state: AuthStateInterface, payload: LoginResponseInterface['user']): AuthStateInterface => {
  return {
    ...state,
    user: payload,
  };
};

// ============ EXPORTS ============

export const AuthReducer = (state = initialState, action: ReducerActionInterface) => {
  switch (action.type) {
    case AuthConstants.LOGIN_SUCCESS:
      return loginSuccess(state, action.payload);
    case AuthConstants.LOGOUT_SUCCESS:
      return logoutSuccess(state, action.payload);
    case AuthConstants.ERROR:
      return error(state, action.payload);
    case AuthConstants.SET_LANG:
      return setLang(state, action.payload);
    case AuthConstants.RENEW_USER_TOKEN:
      return renewToken(state, action.payload);
    case AuthConstants.SET_2FA:
      return set2fa(state, action.payload);
    default:
      return state;
  }
};

export const RegisterReducer = (state = initialStateRegister, action: ReducerActionInterface) => {
  switch (action.type) {
    case AuthConstants.REGISTER_START:
      return registerStart(state);
    case AuthConstants.REGISTER_SUCCESS:
      return registerSuccess(state, action.payload);
    case AuthConstants.REGISTER_ERROR:
      return registerError(state, action.payload);
    default:
      return state;
  }
};
