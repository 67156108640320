import { Col, notification, Row } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  DashboardFAQComponent,
  DashboardNotesComponent,
  DashboardRegulationsComponent,
  EducationsComponent,
  ExternalService,
  FaqEsResponseInterface,
  FaqInterface,
  FaqService,
  FullOrganizationUnitResponseInterfaceSingle,
  getScreenTagsByTypeAction,
  i18n,
  LicenceInterface,
  LicenceResponseInterfaceAll,
  LicenceService,
  NavigationService,
  NewsComponent,
  NewsInterface,
  NoteResponseInterfaceAll,
  NoteService,
  NotificationsComponent,
  OrganizationUnitService,
  RegulationResponseInterfaceAll,
  RegulationService,
  StoreStateInterface,
  TagScreenStateInterface,
  VersionComponent,
} from '../../../common';
import { LicenceEnum } from '../../../common/enums/licence.enum';
import Force2faModal from '../../../common/components/modals/force2fa/force2faModal.component';
import { AppRoutes } from '../_router/app.routes';
import { useLocation } from 'react-router-dom';

function DashboardPageComponent() {
  const dispatch = useDispatch();

  const location = useLocation();
  const search = location.search.split('=');
  const loggedIn = search[search.length - 1];
  
  const userAuth = useSelector((state: StoreStateInterface) => state.auth);

  const [force2faVisible, setForce2faVisible] = useState<boolean>(false);

  const [noteData, setNoteData] = useState<NoteResponseInterfaceAll>();
  const [faqData, setFaqData] = useState<FaqEsResponseInterface>();
  const [regData, setRegData] = useState<RegulationResponseInterfaceAll>();

  const [orgUnitInfo, setOrgUnitInfo] = useState<FullOrganizationUnitResponseInterfaceSingle>();
  const [licenceData, setLicenceData] = useState<LicenceInterface>();
  const [allValidLicences, setAllValidLicences] = useState<LicenceInterface[]>([]);

  const tags: TagScreenStateInterface = useSelector((state: StoreStateInterface) => state.tagScreen);

  useEffect(() => {
    if (userAuth?.user?.force2faAfter && loggedIn === 'true') {
      setForce2faVisible(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedIn]);

  useEffect(() => {
    NoteService.getAll(userAuth.user?.id).subscribe(
      (response: NoteResponseInterfaceAll) => {
        setNoteData(response);
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    FaqService.elasticSearch({ from: 0, size: 4 }, false, '', []).subscribe(
      (response: FaqEsResponseInterface) => {
        setFaqData(response);
      },
      (error: any) => {
        if (error.errorId == 23) {
          notification['warning']({ message: i18n.translate('common.licence.noFilter'), duration: 2 });
        }
        else {
          notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
        }
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    RegulationService.getAll(undefined, 'filter[id]=12,68,25', 'sort=-id').subscribe(
      (response: RegulationResponseInterfaceAll) => {
        setRegData(response);
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(getScreenTagsByTypeAction('frequent_questions'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userAuth.user?.organizationUnit?.id) {
      OrganizationUnitService.getSingle(userAuth.user?.organizationUnit?.id).subscribe(
        (response: FullOrganizationUnitResponseInterfaceSingle) => {
          setOrgUnitInfo(response);
        },
        (error: Error) => {
          notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
        }
      );
    }
  }, [userAuth.user]);

  useEffect(() => {
    if (orgUnitInfo?.data?.id) {
      LicenceService.getAll(undefined, `filter[organizationUnit.id]=${orgUnitInfo?.data?.id}`, 'sort=id', `page[offset]=0&page[limit]=999999`).subscribe(
        (response: LicenceResponseInterfaceAll) => {
          if (response?.data?.length) {
            let validLicences: LicenceInterface[] = [];

            response?.data?.forEach(licence => {
              if (licence.attributes?.licenceUsers?.find(x => x.user?.id == userAuth.user?.id) && !licence.relationships?.licenceParent?.data) {
                setLicenceData(licence);
              }
              if (licence.attributes?.licenceUsers?.find(x => x.user?.id == userAuth.user?.id) && !licence.relationships?.licenceParent?.data && licence.attributes?.active && licence?.attributes?.licenceType !== LicenceEnum.FREE && moment().isBefore(moment(licence?.attributes?.to).startOf('day'))) {
                validLicences.push(licence);
              }
            });

            setAllValidLicences([...validLicences]);
          }
        },
        (error: Error) => {
          notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
        }
      );
    }
  }, [orgUnitInfo?.data]);

  return (
    <div className="dashboard w100-h100">
      <div className="dashboard__title">{i18n.translate('dashboard.title')}</div>
      <NotificationsComponent type="user" />
      <>
        <Row justify="space-between" className="container">
          <Col>
            {noteData?.data?.length ? <DashboardNotesComponent notes={[...(noteData?.data as any)]} /> : null}
            {faqData?.hits.length ? (
              <DashboardFAQComponent
                faqs={faqData?.hits.map(
                  (el: { index: 'faq_group'; id: number; score: number; source: FaqInterface['attributes'] }) =>
                    el.source
                )}
                validLicences={allValidLicences}
                tags={tags.tagScreens}
              />
            ) : null}
          </Col>
          <Col>
            <NewsComponent />
          </Col>
        </Row>
        <div>{regData?.data?.length ? <DashboardRegulationsComponent regulations={[...regData?.data]} /> : null}</div>
        <div>
          <EducationsComponent />
        </div>
      </>

      <VersionComponent />
      <Force2faModal
        visible={force2faVisible}
        handleOk={() => { setForce2faVisible(false); NavigationService.navigate(AppRoutes.USER_PROFILE.fullPath, '?tabIndex=4') }}
        date={userAuth.user?.force2faAfter}
      />
    </div>
  );
}

export default DashboardPageComponent;
