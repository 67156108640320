import { ResponseConstants } from './response.constants';
import { ResponseInterface, ResponseResponseInterfaceAll, ResponseResponseInterfaceSingle, ReducerActionInterface } from '../../interfaces';

// ============ INTERFACE ============

export interface ResponseStateInterface {
  revision: number;
  currentResponse?: ResponseInterface;
  responses?: ResponseResponseInterfaceAll;
  error?: string;
  isLoading?: boolean;
}

// ============ INIT STATE ============

const initialState: ResponseStateInterface = {
  revision: 0,
  currentResponse: undefined,
  responses: {
    data: [],
    meta: {
      totalResourceCount: 0
    }
  }
}

// ============ REDUCERS ============

const setLoader = (state: ResponseStateInterface, payload: boolean): ResponseStateInterface => {
  return {
    ...state,
    isLoading: payload,
  };
};

const getSingleSuccess = (state: ResponseStateInterface, payload: ResponseResponseInterfaceSingle): ResponseStateInterface => {
  return {
    ...state,
    isLoading: false,
    currentResponse: payload.data,
  };
};

const getAllSuccess = (state: ResponseStateInterface, payload: ResponseResponseInterfaceAll): ResponseStateInterface => {
  return {
    ...state,
    isLoading: false,
    responses: {
      data: payload.data,
      meta: payload.meta
    }
  };
};

const createSuccess = (state: ResponseStateInterface, payload: ResponseResponseInterfaceSingle): ResponseStateInterface => {
  return {
    ...state,
    revision: state.revision + 1,
    isLoading: false,
    currentResponse: payload.data,
  };
};

const updateSuccess = (state: ResponseStateInterface, payload: ResponseResponseInterfaceSingle): ResponseStateInterface => {
  return {
    ...state,
    revision: state.revision + 1,
    isLoading: false,
    currentResponse: payload.data,
  };
};

const clearCurrent = (state: ResponseStateInterface): ResponseStateInterface => {
  return {
    ...state,
    revision: state.revision + 1,
    isLoading: false,
    currentResponse: undefined,
  };
};

const clearList = (state: ResponseStateInterface): ResponseStateInterface => {
  return {
    ...state,
    revision: state.revision + 1,
    isLoading: false,
    responses: {
      data: [],
      meta: {
        totalResourceCount: 0
      }
    }
  };
};

const deleteSuccess = (state: ResponseStateInterface): ResponseStateInterface => {
  return {
    ...state,
    revision: state.revision + 1,
    isLoading: false,
    currentResponse: undefined,
  };
};

const error = (state: ResponseStateInterface, payload: string) => {
  return {
    ...state,
    revision: state.revision + 1,
    isLoading: false,
    error: payload,
  };
};

// ============ EXPORTS ============

export const ResponseReducer = (state = initialState, action: ReducerActionInterface) => {
  switch (action.type) {
    case ResponseConstants.SET_LOADER:
      return setLoader(state, action.payload);
    case ResponseConstants.GET_SINGLE_SUCCESS:
      return getSingleSuccess(state, action.payload);
    case ResponseConstants.GET_ALL_SUCCESS:
      return getAllSuccess(state, action.payload);
    case ResponseConstants.CREATE_SUCCESS:
      return createSuccess(state, action.payload);
    case ResponseConstants.UPDATE_SUCCESS:
      return updateSuccess(state, action.payload);
    case ResponseConstants.CLEAR_CURRENT:
      return clearCurrent(state);
    case ResponseConstants.CLEAR_LIST:
      return clearList(state);
    case ResponseConstants.DELETE:
      return deleteSuccess(state);
    case ResponseConstants.ERROR:
      return error(state, action.payload);
    default:
      return state;
  }
};
