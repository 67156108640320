export enum OpinionConstants {
  GET_ALL_SUCCESS = 'OpinionConstants_GET_ALL_SUCCESS',
  GET_SINGLE_SUCCESS = 'OpinionConstants_GET_SINGLE_SUCCESS',
  CREATE_SUCCESS = 'OpinionConstants_CREATE_SUCCESS',
  UPDATE_SUCCESS = 'OpinionConstants_UPDATE_SUCCESS',
  CLEAR_CURRENT = 'OpinionConstants_CLEAR_CURRENT',
  CLEAR_LIST = 'OpinionConstants_CLEAR_LIST',
  DELETE = 'OpinionConstants_DELETE',
  ERROR = 'OpinionConstants_ERROR'
}
