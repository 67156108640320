import { SynopsisConstants } from './synopsis.constants';
import { SynopsisInterface, SynopsisResponseInterfaceAll, SynopsisResponseInterfaceSingle, ReducerActionInterface } from '../../interfaces';

// ============ INTERFACE ============

export interface SynopsisStateInterface {
  revision: number;
  currentSynopsis?: SynopsisInterface;
  synopsiss?: SynopsisResponseInterfaceAll;
  error?: string;
  isLoading?: boolean;
}

// ============ INIT STATE ============

const initialState: SynopsisStateInterface = {
  revision: 0,
  currentSynopsis: undefined,
  synopsiss: {
    data: [],
    meta: {
      totalResourceCount: 0
    }
  }
}

// ============ REDUCERS ============

const setLoader = (state: SynopsisStateInterface, payload: boolean): SynopsisStateInterface => {
  return {
    ...state,
    isLoading: payload,
  };
};

const getSingleSuccess = (state: SynopsisStateInterface, payload: SynopsisResponseInterfaceSingle): SynopsisStateInterface => {
  return {
    ...state,
    isLoading: false,
    currentSynopsis: payload.data,
  };
};

const getAllSuccess = (state: SynopsisStateInterface, payload: SynopsisResponseInterfaceAll): SynopsisStateInterface => {
  return {
    ...state,
    isLoading: false,
    synopsiss: {
      data: payload.data,
      meta: payload.meta
    }
  };
};

const createSuccess = (state: SynopsisStateInterface, payload: SynopsisResponseInterfaceSingle): SynopsisStateInterface => {
  return {
    ...state,
    revision: state.revision + 1,
    isLoading: false,
    currentSynopsis: payload.data,
  };
};

const updateSuccess = (state: SynopsisStateInterface, payload: SynopsisResponseInterfaceSingle): SynopsisStateInterface => {
  return {
    ...state,
    revision: state.revision + 1,
    isLoading: false,
    currentSynopsis: payload.data,
  };
};

const clearCurrent = (state: SynopsisStateInterface): SynopsisStateInterface => {
  return {
    ...state,
    revision: state.revision + 1,
    isLoading: false,
    currentSynopsis: undefined,
  };
};

const clearList = (state: SynopsisStateInterface): SynopsisStateInterface => {
  return {
    ...state,
    revision: state.revision + 1,
    isLoading: false,
    synopsiss: {
      data: [],
      meta: {
        totalResourceCount: 0
      }
    }
  };
};

const deleteSuccess = (state: SynopsisStateInterface): SynopsisStateInterface => {
  return {
    ...state,
    revision: state.revision + 1,
    isLoading: false,
    currentSynopsis: undefined,
  };
};

const error = (state: SynopsisStateInterface, payload: string) => {
  return {
    ...state,
    revision: state.revision + 1,
    isLoading: false,
    error: payload,
  };
};

// ============ EXPORTS ============

export const SynopsisReducer = (state = initialState, action: ReducerActionInterface) => {
  switch (action.type) {
    case SynopsisConstants.SET_LOADER:
      return setLoader(state, action.payload);
    case SynopsisConstants.GET_SINGLE_SUCCESS:
      return getSingleSuccess(state, action.payload);
    case SynopsisConstants.GET_ALL_SUCCESS:
      return getAllSuccess(state, action.payload);
    case SynopsisConstants.CREATE_SUCCESS:
      return createSuccess(state, action.payload);
    case SynopsisConstants.UPDATE_SUCCESS:
      return updateSuccess(state, action.payload);
    case SynopsisConstants.CLEAR_CURRENT:
      return clearCurrent(state);
    case SynopsisConstants.CLEAR_LIST:
      return clearList(state);
    case SynopsisConstants.DELETE:
      return deleteSuccess(state);
    case SynopsisConstants.ERROR:
      return error(state, action.payload);
    default:
      return state;
  }
};
