import { Dispatch } from 'redux';
import { notification } from 'antd';
import { AuthConstants } from './auth.constants';
import { store } from '../';

import { UserActivityLogService, UserService } from '../../api/api.service';
import { LoginResponseInterface, RegisterInterface } from '../../interfaces';
import { i18n, NavigationService } from '../../services';
import { AuthRoutes } from '../../../features/auth';
import { UserRoleEnum } from '../../enums';
import { UserActionTypeEnum } from '../../enums/userActionType.enum';

export function logoutAction() {
  return (dispatch: Dispatch) => {
    return UserService.logout().subscribe(
      () => {
        const userAuth = store.getState().auth;
        const isAdmin = userAuth.user?.roles === UserRoleEnum.ADMIN;

        if (!isAdmin) {
          let userActivityLogData: any = {
            data: {
              type: 'user_activity_log',
              attributes: {
                action: UserActionTypeEnum.LOGOUT,
                extra: JSON.stringify({organizationId: userAuth.user?.organizationUnit?.organization?.id, organizationName: userAuth.user?.organizationUnit?.organization?.name, userId: userAuth.user?.id, userName: userAuth.user?.name})
              },
              relationships: {
                user: {
                  data: {
                    id: userAuth.user?.id,
                    type: 'user'
                  },
                },
              },
            },
          };
    
          UserActivityLogService.create(userActivityLogData);
        }
    
        localStorage.clear();
        dispatch({ type: AuthConstants.LOGOUT_SUCCESS });
        notification['success']({ message: i18n.translate('login.msgs.logout'), duration: 2 });
        NavigationService.navigate(AuthRoutes.LOGIN.fullPath);
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('login.msgs.loginError'), duration: 2 });

        dispatch({
          type: AuthConstants.ERROR,
          payload: error,
        });
      }
    );
  };
}

export function registerAction(data: RegisterInterface) {
  return (dispatch: Dispatch) => {
    dispatch({ type: AuthConstants.REGISTER_START });

    return UserService.register(data).subscribe(
      (response: any) => {
        dispatch({
          type: AuthConstants.REGISTER_SUCCESS,
          payload: response,
        });
      },
      (error: Error) => {
        dispatch({
          type: AuthConstants.REGISTER_ERROR,
          payload: error,
        });
      }
    );
  };
}

export function refreshTokenAction() {
  return (dispatch: Dispatch) => {
    return UserService.refreshToken().subscribe(
      (response: LoginResponseInterface) => {
        dispatch({
          type: AuthConstants.RENEW_USER_TOKEN,
          payload: response,
        });
      },
      (error: Error) => {
        console.log(error);
      }
    );
  };
}

export function setLangAction(lang: 'hr' | 'en') {
  return (dispatch: Dispatch) => {
    dispatch({ type: AuthConstants.SET_LANG, payload: lang });
  };
}

export function set2faAction() {
  const user2faActivated = store.getState().auth.user;
  if (!user2faActivated) return;
  user2faActivated.active2fa = true;
  return (dispatch: Dispatch) => {
    dispatch({ type: AuthConstants.SET_2FA, payload: user2faActivated });
  };
}
