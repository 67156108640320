import { Typography, Image, Button, Col, Row, notification, FormInstance } from 'antd';
import React, { useEffect, useState } from 'react';
import { NewMemberCardComponent, NoDataComponent, TeamTableComponent, UpgradeModal, UsersModal, YearDividerComponent } from '../../../index';
import { i18n, LicenceUpgradeService } from '../../../../services';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { OrganizationUnitService, LicenceService, PriceService, FullUserService, LicenceUserService } from '../../../../api';
import { FullOrganizationUnitResponseInterfaceSingle, LicenceInterface, PriceInterface, LicenceResponseInterfaceAll, PriceResponseInterfaceAll, FullOrganizationUnitResponseInterfaceAll, FullUserResponseInterfaceAll, LicenceResponseInterfaceSingle, LicenceUserResponseInterfaceAll } from '../../../../interfaces';
import { StoreStateInterface, clearFullUserAction, getSingleFullUserAction, createLicenceAction, updateFullUserAction, createFullUserAction } from '../../../../redux';
import moment from 'moment';
import { JavaFormat } from '../../../../constants';
import { LicenceEnum } from '../../../../enums/licence.enum';

const { Title, Text } = Typography;

interface Props {
  setTab?: (tab: string, userId: string) => void;
}

export default function AdminTeamComponent({ setTab }: Props) {
  const location = useLocation();
  const path = location.pathname.split('/');
  const id = parseInt(path[path.length - 1]);

  const dispatch = useDispatch();
  const userAuth = useSelector((state: StoreStateInterface) => state.auth);
  const user = useSelector((state: StoreStateInterface) => state.fullUser);
  const [unitUsers, setUnitUsers] = useState<FullUserResponseInterfaceAll>();
  const [userLicences, setUserLicences] = useState<LicenceInterface[]>([]);
  const [oldUserLicences, setOldUserLicences] = useState<LicenceInterface[]>([]);
  const [orgUnitInfo, setOrgUnitInfo] = useState<FullOrganizationUnitResponseInterfaceSingle>();
  const [licenceInfo, setLicenceInfo] = useState<LicenceInterface>();
  const [oldLicencesInfo, setOldLicencesInfo] = useState<LicenceInterface[]>([]);
  const [unusedLicencesInfo, setUnusedLicencesInfo] = useState<LicenceInterface[]>([]);
  const [priceInfo, setPriceInfo] = useState<PriceInterface>();

  //const [loadedLatestLicence, setLoadedLatestLicence] = useState(false);
  
  const [visible, setVisible] = useState(false);
  const [visibleUser, setVisibleUser] = useState(false);

  useEffect(() => {
    dispatch(clearFullUserAction());
    dispatch(getSingleFullUserAction(id));
  }, [id]);

  useEffect(() => {
    if (user.currentFullUser?.relationships?.organizationUnit?.data?.id) {
      OrganizationUnitService.getSingle(user.currentFullUser?.relationships?.organizationUnit?.data?.id).subscribe(
        (response: FullOrganizationUnitResponseInterfaceSingle) => {
          setOrgUnitInfo(response);
        },
        (error: Error) => {
          notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
        }
      );
    }
  }, [user.currentFullUser]);

  useEffect(() => {
    if (orgUnitInfo?.data?.id) {
      LicenceService.getAll(undefined, `filter[organizationUnit.id]=${orgUnitInfo?.data?.id}`, 'sort=id', `page[offset]=0&page[limit]=999999`).subscribe(
        (response: LicenceResponseInterfaceAll) => {
          if (response?.data?.length) {
            let oldLicences: LicenceInterface[] = [];

            response?.data?.forEach(licence => {
              if (licence.attributes?.licenceUsers?.find(x => x.user?.id == id) && !licence.relationships?.licenceParent?.data) {
                setLicenceInfo(licence);
                oldLicences.push(licence);
                //setLoadedLatestLicence(true);
              }
            });

            setOldLicencesInfo([...oldLicences]);
          }
        },
        (error: Error) => {
          //setLoadedLatestLicence(true);
          notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
        }
      );
    }
  }, [orgUnitInfo?.data]);

  useEffect(() => {
    if (orgUnitInfo?.data?.id) {
      PriceService.getAll(orgUnitInfo?.data?.id).subscribe(
        (response: PriceResponseInterfaceAll) => {
          if (response?.data?.length && response.data[0]) {
            setPriceInfo(response?.data[0]);
          }
        },
        (error: Error) => {
          notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
        }
      );
    }
  }, [orgUnitInfo?.data]);

  useEffect(() => {
    if (orgUnitInfo?.data?.id) {
      FullUserService.getAll(undefined, `filter[organizationUnit.id]=${orgUnitInfo?.data?.id}`).subscribe(
        (response: FullUserResponseInterfaceAll) => {
          setUnitUsers(response);
        },
        (error: Error) => {
          notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
        }
      );
    }
  }, [orgUnitInfo?.data]);

  useEffect(() => {
    if (unitUsers?.data) {
      let currentLicences: LicenceInterface[] = [];
      let oldLicences: LicenceInterface[] = [];
      let unusedLicences: LicenceInterface[] = [];

      let userIds: (number | undefined)[] = unitUsers?.data?.map(user => user.id);

      LicenceService.getAll(undefined, `filter[organizationUnit.id]=${orgUnitInfo?.data?.id}`, 'sort=id', `page[offset]=0&page[limit]=999999`).subscribe(
        (response: LicenceResponseInterfaceAll) => {
          if (response?.data?.length) {
            response?.data?.forEach(licence => {
              userIds?.forEach(userId => {
                if (licence.attributes?.licenceUsers?.find(x => x.user?.id == userId) && !licence.relationships?.licenceParent?.data && licence.attributes?.active) {
                  currentLicences.push(licence);
                }
                if (licence.attributes?.licenceUsers?.find(x => x.user?.id == userId) && !licence.relationships?.licenceParent?.data && !licence.attributes?.active) {
                  oldLicences.push(licence);
                }
                if (!licence.attributes?.licenceUsers?.length && !licence.relationships?.licenceParent?.data) {
                  unusedLicences.push(licence);
                }
              });
            });

            setUserLicences([...currentLicences]);
            setOldUserLicences([...oldLicences]);
            setUnusedLicencesInfo([...unusedLicences]);
          }
        },
        (error: Error) => {
          notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
        }
      );
    }
  }, [unitUsers?.data]);

  const filterUnusedPremium = () => {
    return unusedLicencesInfo?.filter(x => x.attributes?.licenceType === LicenceEnum.PREMIUM)?.filter((v,i,a)=>a.findIndex(v2=>(v2.id===v.id))===i);
  };

  const filterUnusedBasic = () => {
    return unusedLicencesInfo?.filter(x => x.attributes?.licenceType === LicenceEnum.STANDARD)?.filter((v,i,a)=>a.findIndex(v2=>(v2.id===v.id))===i);
  };

  const filterUsedPremium = () => {
    return userLicences?.filter(x => x.attributes?.licenceType === LicenceEnum.PREMIUM)?.filter((v,i,a)=>a.findIndex(v2=>(v2.id===v.id))===i);
  };

  const filterUsedBasic = () => {
    return userLicences?.filter(x => x.attributes?.licenceType === LicenceEnum.STANDARD)?.filter((v,i,a)=>a.findIndex(v2=>(v2.id===v.id))===i);
  };

  const showModal = () => {
    setVisible(true);
  };

  const showUserModal = () => {
    setVisibleUser(true);
  };

  const handleUpgradeOk = (form: FormInstance<any>, price: number, isUpgrade: boolean | undefined, isNew: boolean | undefined, isTrial: boolean | undefined) => {
    let values = form.getFieldsValue(true);

    let numberArrayYears: number[] = values?.years?.map((stringYear: string) => parseInt(stringYear));

    let latestYear = Math.max.apply(Math, numberArrayYears);
    var index = values?.years?.indexOf(latestYear.toString());
    if (index !== -1) {
      values?.years?.splice(index, 1);
    }

    let subs = '0';
    if (Array.isArray(values.subs)) subs = values.subs?.length?.toString();
    if (!Array.isArray(values.subs)) subs = values.subs;

    let licenceDetails: any = {
      from: values?.actDate ? moment(values?.actDate).format(JavaFormat) : moment(latestYear.toString()).startOf('year').format(JavaFormat),
      to: values?.expDate ? moment(values?.expDate).format(JavaFormat) : moment(latestYear.toString()).add(1, 'y').set({'date': 20, 'month': 1}).format(JavaFormat),
      licenceType: values?.model,
      notesNum: values?.notes == 'noLimit' ? 1000000 : values?.notes,
      questionsNum: values?.questions == 'noLimit' ? 1000000 : values?.questions,
      educationsNum: values?.educations == 'noLimit' ? 1000000 : values?.educations,
      printedEditionsNum: parseInt(subs) > 1 ? 3 : parseInt(subs) * 3,
      biltenSubscription: parseInt(subs) > 0 ? true : false,
      price: price
    };

    if (isUpgrade) {
      LicenceUpgradeService.upgradeUserLicence(id, orgUnitInfo?.data?.id!, licenceDetails, latestYear.toString(), values.years, parseInt(subs) > 1, [values.subs].flat(), true).then(() => {
        notification['success']({ message: i18n.translate('api.successMessage'), duration: 2 });
        setVisible(false);
        dispatch(clearFullUserAction());
        dispatch(getSingleFullUserAction(id));
        form.resetFields();
      });
    }

    if (isNew) {
      LicenceUpgradeService.createUserLicence(orgUnitInfo?.data?.id!, licenceDetails, latestYear.toString(), values.years, parseInt(subs) > 1, [values.subs].flat(), true).then(() => {
        notification['success']({ message: i18n.translate('api.successMessage'), duration: 2 });
        setVisible(false);
        dispatch(clearFullUserAction());
        dispatch(getSingleFullUserAction(id));
        form.resetFields();
      });
    }

  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleCancelUser = () => {
    setVisibleUser(false);
  };

  const handleApprove = () => {
    dispatch(clearFullUserAction());
    dispatch(getSingleFullUserAction(id as number));
  };

  const handleCreateUser = (form: FormInstance<any>) => {
    let userData: any = {
      data: {
        type: 'user',
        attributes: {
          active: form.getFieldValue('active'),
          name: form.getFieldValue('name'),
          email: form.getFieldValue('email'),
          password: form.getFieldValue('password'),
          vat: form.getFieldValue('companyOib'),
          phoneNumber: form.getFieldValue('contactNo'),
          competition: form.getFieldValue('competition'),
          address: form.getFieldValue('addressUser'),
          footnote: form.getFieldValue('userRemark'),
          adminFootnote: form.getFieldValue('temporisRemark'),
        },
        relationships: {
          organizationUnit: {
            data: {
              id: orgUnitInfo?.data?.id,
              type: 'organization_unit'
            }
          }
        }
      },
    };

    dispatch(createFullUserAction(userData));

    form.resetFields();

    setVisibleUser(false);
    dispatch(clearFullUserAction());
    dispatch(getSingleFullUserAction(id as number));
  };

  const changeTab = (tab: string, userId: string) => {
    setTab && setTab(tab, userId);
  };

  return (
    <div className="team">
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <Title className="team__title" level={5}>
          {i18n.translate('profile.myTeam.users')}
        </Title>
        <Button type="primary" onClick={showUserModal}>{i18n.translate('profile.myTeam.newUsers')}</Button>
      </div>
      {user.currentFullUser && orgUnitInfo?.data /**&& licenceInfo && priceInfo && unitUsers?.data*/ ? (
      <>
      <div className="team___content">
        <TeamTableComponent changeTab={changeTab} orgUnitInfo={orgUnitInfo} unitUsers={unitUsers?.data} userLicences={userLicences} oldUserLicences={oldUserLicences} handleApprove={handleApprove} />
      </div>

      {/**<Title className="team__title" level={5}>
        {i18n.translate('profile.myTeam.newUsers')}
      </Title>*/}
      <div className="team___content">
        {/**<Col className="team___notice">
          <Row justify="center">
            <Image
              preview={false}
              width={100}
              alt="EMPTY"
              src={require('../../../../../assets/images/empty-img-gray.png')}
            />
          </Row>
          <Row justify="center">
            <Text>{i18n.translate('profile.myTeam.notice', { number: '2' })}</Text>
          </Row>
          <Row justify="center">
            <Button>{i18n.translate('profile.unlock.users')}</Button>
          </Row>
        </Col>*/}
        {<>
          <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <Title className="team__title" style={{color: 'black'}} level={5}>
              {i18n.translate('profile.myTeam.modelStandard')}
            </Title>
            <Button type="primary" onClick={showModal}>{i18n.translate('profile.myTeam.addSubscription')}</Button>
          </div>
          <YearDividerComponent title={''} />
          {filterUsedBasic()?.map((licence, index) => (
            <NewMemberCardComponent key={'premium' + index} licence={licence} unitUsers={unitUsers} orgUnitId={orgUnitInfo?.data?.id} />
          ))}
          {filterUnusedBasic()?.map((licence, index) => (
            <NewMemberCardComponent key={'premium' + index} licence={licence} unitUsers={unitUsers} orgUnitId={orgUnitInfo?.data?.id} />
          ))}
          {!filterUnusedBasic()?.length && !filterUsedBasic()?.length ? <NoDataComponent text={i18n.translate('profile.myTeam.standardEmpty')} /> : null}

          <Title className="team__title" style={{color: 'black'}} level={5}>
            {i18n.translate('profile.myTeam.modelPremium')}
          </Title>
          <YearDividerComponent title={''} />
          {filterUsedPremium()?.map((licence, index) => (
            <NewMemberCardComponent key={'premium' + index} licence={licence} unitUsers={unitUsers} orgUnitId={orgUnitInfo?.data?.id} />
          ))}
          {filterUnusedPremium()?.map((licence, index) => (
            <NewMemberCardComponent key={'premium' + index} licence={licence} unitUsers={unitUsers} orgUnitId={orgUnitInfo?.data?.id} />
          ))}
          {!filterUnusedPremium()?.length && !filterUsedPremium()?.length  ? <NoDataComponent text={i18n.translate('profile.myTeam.premiumEmpty')} /> : null}
          </>}
      </div>
      </>
      ) : null}
      <UpgradeModal isNew licenceInfo={licenceInfo} visible={visible} handleOk={handleUpgradeOk} handleCancel={handleCancel} teamScreen />
      <UsersModal visible={visibleUser} handleOk={handleCreateUser} handleCancel={handleCancelUser} />
    </div>
  );
}
