export enum ProfessionalArticleConstants {
  GET_ALL_SUCCESS = 'ProfessionalArticleConstants_GET_ALL_SUCCESS',
  GET_SINGLE_SUCCESS = 'ProfessionalArticleConstants_GET_SINGLE_SUCCESS',
  CREATE_SUCCESS = 'ProfessionalArticleConstants_CREATE_SUCCESS',
  UPDATE_SUCCESS = 'ProfessionalArticleConstants_UPDATE_SUCCESS',
  CLEAR_CURRENT = 'ProfessionalArticleConstants_CLEAR_CURRENT',
  DELETE = 'ProfessionalArticleConstants_DELETE',
  CLEAR_LIST = 'ProfessionalArticleConstants_CLEAR_LIST',
  ERROR = 'ProfessionalArticleConstants_ERROR'
}
