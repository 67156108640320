import { Dispatch } from 'redux';
import { notification } from 'antd';
import { PrintEditionConstants } from './printEditions.constants';
import { PrintEditionService } from '../../api/api.service';
import { PrintEditionResponseInterfaceSingle, PrintEditionsBiltenIncludedResponse } from '../../interfaces';
import { i18n } from '../../services';

export function getAllPrintEditionsAction(userId: number) {
  return (dispatch: Dispatch) => {
    return PrintEditionService.getAllWithBilten(
      undefined,
      `filter[user.id]=${userId}&include=bilten`,
      'sort=-id', 
      `page[offset]=0&page[limit]=999999`
    ).subscribe(
      (response: PrintEditionsBiltenIncludedResponse) => {
        dispatch({
          type: PrintEditionConstants.GET_ALL_SUCCESS,
          payload: response,
        });
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });

        dispatch({
          type: PrintEditionConstants.ERROR,
          payload: error,
        });
      }
    );
  };
}

export function getAllPrintEditionsWithBiltenAction(userId: number) {
  return (dispatch: Dispatch) => {
    return PrintEditionService.getAllWithBilten(userId).subscribe(
      (response: PrintEditionsBiltenIncludedResponse) => {
        dispatch({
          type: PrintEditionConstants.GET_ALL_SUCCESS,
          payload: response,
        });
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });

        dispatch({
          type: PrintEditionConstants.ERROR,
          payload: error,
        });
      }
    );
  };
}

export function getSinglePrintEditionAction(id: number) {
  return (dispatch: Dispatch) => {
    return PrintEditionService.getSingle(id).subscribe(
      (response: PrintEditionResponseInterfaceSingle) => {
        dispatch({
          type: PrintEditionConstants.GET_SINGLE_SUCCESS,
          payload: response,
        });
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });

        dispatch({
          type: PrintEditionConstants.ERROR,
          payload: error,
        });
      }
    );
  };
}

export function getSinglePrintEditionOnlyAction(id: number) {
  return (dispatch: Dispatch) => {
    return PrintEditionService.getSingle(id).subscribe(
      (response: PrintEditionResponseInterfaceSingle) => {
        dispatch({
          type: PrintEditionConstants.GET_SINGLE_ONLY,
          payload: response,
        });
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });

        dispatch({
          type: PrintEditionConstants.ERROR,
          payload: error,
        });
      }
    );
  };
}

export function createPrintEditionAction(body: PrintEditionResponseInterfaceSingle, userId: number) {
  return (dispatch: Dispatch) => {
    return PrintEditionService.create(body).subscribe(
      (response: PrintEditionResponseInterfaceSingle) => {
        notification['success']({ message: i18n.translate('api.successMessage'), duration: 2 });

        dispatch(getAllPrintEditionsAction(userId) as any);

        dispatch({
          type: PrintEditionConstants.CREATE_SUCCESS,
          payload: response,
        });
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });

        dispatch({
          type: PrintEditionConstants.ERROR,
          payload: error,
        });
      }
    );
  };
}

export function updatePrintEditionAction(body: PrintEditionResponseInterfaceSingle, id: number, userId: number) {
  return (dispatch: Dispatch) => {
    return PrintEditionService.update(body, id).subscribe(
      (response: PrintEditionResponseInterfaceSingle) => {
        notification['success']({ message: i18n.translate('api.successMessage'), duration: 2 });

        dispatch(getAllPrintEditionsAction(userId) as any);

        dispatch({
          type: PrintEditionConstants.UPDATE_SUCCESS,
          payload: response,
        });
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });

        dispatch({
          type: PrintEditionConstants.ERROR,
          payload: error,
        });
      }
    );
  };
}

export function deletePrintEditionAction(id: number, userId: number) {
  return (dispatch: Dispatch) => {
    return PrintEditionService.delete(id).subscribe(
      (response: any) => {
        dispatch(getAllPrintEditionsAction(userId) as any);
        dispatch({
          type: PrintEditionConstants.DELETE
        });
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });

        dispatch({
          type: PrintEditionConstants.ERROR,
          payload: error,
        });
      }
    );
  };
}

export function clearPrintEditionAction() {
  return (dispatch: Dispatch) => {
    dispatch({ type: PrintEditionConstants.CLEAR_CURRENT });
  };
}

export function clearPrintEditionListAction() {
  return (dispatch: Dispatch) => {
    dispatch({ type: PrintEditionConstants.CLEAR_LIST });
  };
}
