import React, { useEffect, useState } from 'react';
import { Menu, MenuProps } from 'antd';
import { i18n } from '../../services';
import Sider from 'antd/lib/layout/Sider';
import { AppRoutes } from '../../../features/app';
import { Link, useLocation } from 'react-router-dom';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { AdminRoutes } from '../../../features/admin';
import logo from '../../../assets/images/bjn.png';
import { useSelector } from 'react-redux';
import { UserActivityLogService } from '../../api';
import { UserActionTypeEnum } from '../../enums/userActionType.enum';
import { StoreStateInterface } from '../../redux';

interface SiderProps {
  isAdmin: boolean;
  collapsed: boolean;
  onBreakpointChange?: () => void;
}

export default function SiderComponent({ isAdmin, collapsed, onBreakpointChange }: SiderProps) {
  const userAuth = useSelector((state: StoreStateInterface) => state.auth);
  
  const location = useLocation();
  const path = location.pathname.split('/');
  const route = path[2];

  const [lastKey, setLastKey] = useState('');

  useEffect(() => {
    let parts = location.pathname.split('/');
    if (parts.length === 0) return;
    setLastKey(parts[parts.length - 1]);
  }, [])

  type MenuItem = Required<MenuProps>['items'][number];

  function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: 'group'
  ): MenuItem {
    return {
      key,
      icon,
      children,
      label,
      type,
    } as MenuItem;
  }

  function createHomeLinks(): ItemType {
    let links: MenuItem[] = [];

    Object.values(isAdmin ? AdminRoutes : AppRoutes).forEach((item) => {
      if (item.group === 'home') {
        let link: MenuItem = {
          key: item.path,
          icon: item.icon,
          label: (
            <Link to={item.fullPath} key={item.path}>
              {i18n.translate(`menu.${item.path}`)}
            </Link>
          ),
        };

        if (!item.hide) links.push(link);
      }
    });

    return getItem(null, 'home', null, links, 'group');
  }

  function createWebOfKnowledgeLinks(): ItemType {
    let links: MenuItem[] = [];

    Object.values(isAdmin ? AdminRoutes : AppRoutes).forEach((item) => {
      if (item.group === 'web-of-knowledge') {
        let link: MenuItem = {
          key: item.path,
          icon: item.icon,
          label: (
            <Link to={item.fullPath} key={item.path}>
              {i18n.translate(`menu.${item.path}`)}
            </Link>
          ),
        };

        if (!item.hide) links.push(link);
      }
    });

    return getItem(i18n.translate(`breadcrumbs.web-of-knowledge`), 'web-of-knowledge', null, links, 'group');
  }

  function createLegalProtectionLinks(): ItemType {
    let links: MenuItem[] = [];
    let childrenEU: MenuItem[] = [];
    let childrenRH: MenuItem[] = [];

    Object.values(isAdmin ? AdminRoutes : AppRoutes).forEach((item) => {
      if (item.group === 'legal-protection-sub-cro') {
        let link: MenuItem = {
          key: item.path,
          icon: item.icon,
          label: (
            <Link to={item.fullPath} key={item.path}>
              {i18n.translate(`menu.${item.path}`)}
            </Link>
          ),
        };

        if (!item.hide) childrenRH.push(link);
      }
    });

    Object.values(isAdmin ? AdminRoutes : AppRoutes).forEach((item) => {
      if (item.group === 'legal-protection-sub-eu') {
        let link: MenuItem = {
          key: item.path,
          icon: item.icon,
          label: (
            <Link to={item.fullPath} key={item.path}>
              {i18n.translate(`menu.${item.path}`)}
            </Link>
          ),
        };

        if (!item.hide) childrenEU.push(link);
      }
    });

    Object.values(isAdmin ? AdminRoutes : AppRoutes).forEach((item) => {
      if (item.group === 'legal-protection-cro') {
        let link: MenuItem = {
          key: item.path,
          icon: item.icon,
          label: <span>{i18n.translate(`menu.${item.path}`)}</span>,
          children: childrenRH,
        };

        if (!item.hide) links.push(link);
      }
    });

    Object.values(isAdmin ? AdminRoutes : AppRoutes).forEach((item) => {
      if (item.group === 'legal-protection-eu') {
        let link: MenuItem = {
          key: item.path,
          icon: item.icon,
          label: <span> {i18n.translate(`menu.${item.path}`)}</span>,
          children: childrenEU,
        };

        if (!item.hide) links.push(link);
      }
    });

    return getItem(i18n.translate(`breadcrumbs.legal-protection`), 'legal-protection', null, links, 'group');
  }

  function combineLinks(): MenuProps['items'] {
    return [createHomeLinks(), createWebOfKnowledgeLinks(), createLegalProtectionLinks()];
  }

  function categoryClicked(key: string) {
    if (!isAdmin) {
      let userActivityLogData: any = {
        data: {
          type: 'user_activity_log',
          attributes: {
            action: UserActionTypeEnum.CATEGORY_ACCESSED,
            extra: key
          },
          relationships: {
            user: {
              data: {
                id: userAuth?.user?.id,
                type: 'user'
              },
            },
          },
        },
      };

      UserActivityLogService.create(userActivityLogData);
    }
  }

  return (
    <Sider
      className="sider"
      trigger={null}
      collapsible
      collapsed={collapsed}
      theme="light"
      width="256"
      breakpoint="sm"
      collapsedWidth="0"
      onBreakpoint={(broken) => {
        if (broken) {
          onBreakpointChange && onBreakpointChange();
        }
      }}
    >
      <Link
        to={isAdmin ? AdminRoutes.DASHBOARD.fullPath : AppRoutes.DASHBOARD.fullPath}
        key={isAdmin ? AdminRoutes.DASHBOARD.fullPath : AppRoutes.DASHBOARD.fullPath}
      >
        <div className="sider__logo">
          <div className="icon">
            <img width={26} height={24} src={logo} alt="none" />
          </div>
          <div className="title">{i18n.translate(`sider.title`)}</div>
        </div>
      </Link>
      <Menu
        mode="inline"
        defaultSelectedKeys={['dashboard']}
        selectedKeys={[route]}
        defaultOpenKeys={['eu-practice', 'rh-practice']}
        items={combineLinks()}
        onClick={(data) => {
          categoryClicked(data.key);
          if (data.key === lastKey) return window.location.reload();
          setLastKey(data.key);
        }}
      />
    </Sider>
  );
}
