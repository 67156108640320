import { OpinionConstants } from './opinion.constants';
import { OpinionInterface, OpinionResponseInterfaceAll, OpinionResponseInterfaceSingle, ReducerActionInterface } from '../../interfaces';

// ============ INTERFACE ============

export interface OpinionStateInterface {
  currentOpinion?: OpinionInterface;
  opinions?: OpinionResponseInterfaceAll;
  error?: string;
}

// ============ INIT STATE ============

const initialState: OpinionStateInterface = {
  currentOpinion: undefined,
  opinions: {
    data: [],
    meta: {
      totalResourceCount: 0
    }
  }
}

// ============ REDUCERS ============

const getSingleSuccess = (state: OpinionStateInterface, payload: OpinionResponseInterfaceSingle): OpinionStateInterface => {
  return {
    ...state,
    currentOpinion: payload.data,
  };
};

const getAllSuccess = (state: OpinionStateInterface, payload: OpinionResponseInterfaceAll): OpinionStateInterface => {
  return {
    ...state,
    opinions: {
      data: payload.data,
      meta: payload.meta
    }
  };
};

const createSuccess = (state: OpinionStateInterface, payload: OpinionResponseInterfaceSingle): OpinionStateInterface => {
  return {
    ...state,
    currentOpinion: payload.data,
  };
};

const updateSuccess = (state: OpinionStateInterface, payload: OpinionResponseInterfaceSingle): OpinionStateInterface => {
  return {
    ...state,
    currentOpinion: payload.data,
  };
};

const clearCurrent = (state: OpinionStateInterface): OpinionStateInterface => {
  return {
    ...state,
    currentOpinion: undefined,
  };
};

const clearList = (state: OpinionStateInterface): OpinionStateInterface => {
  return {
    ...state,
    opinions: {
      data: [],
      meta: {
        totalResourceCount: 0
      }
    }
  };
};

const deleteSuccess = (state: OpinionStateInterface): OpinionStateInterface => {
  return {
    ...state,
    currentOpinion: undefined,
  };
};

const error = (state: OpinionStateInterface, payload: string) => {
  return {
    ...state,
    error: payload,
  };
};

// ============ EXPORTS ============

export const OpinionReducer = (state = initialState, action: ReducerActionInterface) => {
  switch (action.type) {
    case OpinionConstants.GET_SINGLE_SUCCESS:
      return getSingleSuccess(state, action.payload);
    case OpinionConstants.GET_ALL_SUCCESS:
      return getAllSuccess(state, action.payload);
    case OpinionConstants.CREATE_SUCCESS:
      return createSuccess(state, action.payload);
    case OpinionConstants.UPDATE_SUCCESS:
      return updateSuccess(state, action.payload);
    case OpinionConstants.CLEAR_CURRENT:
      return clearCurrent(state);
    case OpinionConstants.CLEAR_LIST:
      return clearList(state);
    case OpinionConstants.DELETE:
      return deleteSuccess(state);
    case OpinionConstants.ERROR:
      return error(state, action.payload);
    default:
      return state;
  }
};
