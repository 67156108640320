import { VerdictConstants } from './verdict.constants';
import { VerdictInterface, VerdictResponseInterfaceAll, VerdictResponseInterfaceSingle, ReducerActionInterface } from '../../interfaces';

// ============ INTERFACE ============

export interface VerdictStateInterface {
  currentVerdict?: VerdictInterface;
  verdicts?: VerdictResponseInterfaceAll;
  error?: string;
}

// ============ INIT STATE ============

const initialState: VerdictStateInterface = {
  currentVerdict: undefined,
  verdicts: {
    data: [],
    included: [],
    meta: {
      totalResourceCount: 0
    }
  }
}

// ============ REDUCERS ============

const getSingleSuccess = (state: VerdictStateInterface, payload: VerdictResponseInterfaceSingle): VerdictStateInterface => {
  return {
    ...state,
    currentVerdict: payload.data,
    verdicts: {
      data: [payload.data],
      included: payload.included,
      meta: {
        totalResourceCount: 1
      }
    }
  };
};

const getAllSuccess = (state: VerdictStateInterface, payload: VerdictResponseInterfaceAll): VerdictStateInterface => {
  return {
    ...state,
    verdicts: {
      data: payload.data,
      included: payload.included,
      meta: payload.meta
    }
  };
};

const createSuccess = (state: VerdictStateInterface, payload: VerdictResponseInterfaceSingle): VerdictStateInterface => {
  return {
    ...state,
    currentVerdict: payload.data,
  };
};

const updateSuccess = (state: VerdictStateInterface, payload: VerdictResponseInterfaceSingle): VerdictStateInterface => {
  return {
    ...state,
    currentVerdict: payload.data,
  };
};

const clearCurrent = (state: VerdictStateInterface): VerdictStateInterface => {
  return {
    ...state,
    currentVerdict: undefined,
  };
};

const clearList = (state: VerdictStateInterface): VerdictStateInterface => {
  return {
    ...state,
    verdicts: {
      data: [],
      included: [],
      meta: {
        totalResourceCount: 0
      }
    }
  };
};

const deleteSuccess = (state: VerdictStateInterface): VerdictStateInterface => {
  return {
    ...state,
    currentVerdict: undefined,
  };
};

const error = (state: VerdictStateInterface, payload: string) => {
  return {
    ...state,
    error: payload,
  };
};

// ============ EXPORTS ============

export const VerdictReducer = (state = initialState, action: ReducerActionInterface) => {
  switch (action.type) {
    case VerdictConstants.GET_SINGLE_SUCCESS:
      return getSingleSuccess(state, action.payload);
    case VerdictConstants.GET_ALL_SUCCESS:
      return getAllSuccess(state, action.payload);
    case VerdictConstants.CREATE_SUCCESS:
      return createSuccess(state, action.payload);
    case VerdictConstants.UPDATE_SUCCESS:
      return updateSuccess(state, action.payload);
    case VerdictConstants.CLEAR_CURRENT:
      return clearCurrent(state);
    case VerdictConstants.CLEAR_LIST:
      return clearList(state);
    case VerdictConstants.DELETE:
      return deleteSuccess(state);
    case VerdictConstants.ERROR:
      return error(state, action.payload);
    default:
      return state;
  }
};
