export enum AuthConstants {
  LOGIN_SUCCESS = 'AuthConstants_LOGIN_SUCCESS',
  LOGOUT_SUCCESS = 'AuthConstants_LOGOUT_SUCCESS',
  REGISTER_START = 'AuthConstants_REGISTER_START',
  REGISTER_SUCCESS = 'AuthConstants_REGISTER_SUCCESS',
  REGISTER_ERROR = 'AuthConstants_REGISTER_ERROR',
  ERROR = 'AuthConstants_ERROR',
  SET_LANG = 'AuthConstants_SET_LANG',
  RENEW_USER_TOKEN = 'AuthConstants_RENEW_USER_TOKEN',
  SET_2FA = 'AuthConstants_SET_2FA'
}
