import { DeleteOutlined, DeleteTwoTone, ExclamationCircleOutlined, SaveOutlined } from '@ant-design/icons';
import {
  Button,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  notification,
  Radio,
  RadioChangeEvent,
  Select,
  Tooltip,
  TreeSelect
} from 'antd';
import localeEN from 'antd/es/date-picker/locale/en_GB';
import localeHR from 'antd/es/date-picker/locale/hr_HR';
import DatePicker, { RangePickerProps } from 'antd/lib/date-picker';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import {
  BiltenStateInterface, ChildrenTagScreenResponseInterface, clearFaqAction,
  createFaqAction, deleteFaqAction,
  FaqService, FaqStateInterface, FilterNameEnumHR, getAllBiltensAction,
  getAllProfessionalArticlesAction, getScreenTagsByTypeAction,
  getSingleFaqAction,
  i18n,
  NavigationService, ProfessionalArticleStateInterface, SelectTypeEnum, StoreStateInterface, TagScreenResponseInterface, TagScreenStateInterface,
  updateFaqAction
} from '../../../../common';
import { AdminRoutes } from '../../_router/admin.routes';

const { SHOW_ALL } = TreeSelect;

const { confirm } = Modal;

function FaqCrudPageComponent() {
  const location = useLocation();
  const path = location.pathname.split('/');
  const id = parseInt(path[path.length - 1]);
  const isNew = isNaN(id);

  const dispatch = useDispatch();
  const data: FaqStateInterface = useSelector((state: StoreStateInterface) => state.faq);
  const biltens: BiltenStateInterface = useSelector((state: StoreStateInterface) => state.bilten);
  const articles: ProfessionalArticleStateInterface = useSelector(
    (state: StoreStateInterface) => state.professionalArticle
  );
  const tags: TagScreenStateInterface = useSelector((state: StoreStateInterface) => state.tagScreen);
  const userAuth = useSelector((state: StoreStateInterface) => state.auth);

  const [isExpertArticle, setIsExpertArticle] = useState(false);
  const [faqChanged, setFaqChanged] = useState<boolean[] | undefined>([]);
  const [questionArray, setQuestionArray] = useState<{ title: string, pageNum: number, position: number, id?: number }[] | undefined>([{ title: '', pageNum: 1, position: 1 }]);

  useEffect(() => {
    dispatch(clearFaqAction());
    dispatch(getScreenTagsByTypeAction('frequent_questions'));
    if (!isNew) dispatch(getSingleFaqAction(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isExpertArticle) {
      dispatch(getAllProfessionalArticlesAction(undefined, undefined, 'sort=-id', `page[offset]=0&page[limit]=9999`));
    } else {
      dispatch(getAllBiltensAction());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isExpertArticle]);

  useEffect(() => {
    if (!isNew) {
      if (data?.currentFaq?.relationships?.bilten?.data) setIsExpertArticle(false);
      if (data?.currentFaq?.relationships?.professionalArticle?.data) setIsExpertArticle(true);
      if (data?.currentFaq?.attributes) {
        const questions = data?.included?.sort(function(a, b){return a?.attributes?.position - b?.attributes?.position}).map((question) => {
          return {
            id: parseInt(question?.id),
            title: question?.attributes?.title ? question?.attributes?.title : '',
            pageNum: question?.attributes?.pageNum ? question?.attributes?.pageNum : 1,
            position: question?.attributes?.position ? question?.attributes?.position : 1,
          };
        });
        setQuestionArray(questions);
        const changedArray = questions?.map((question) => {
          return false;
        });
        setFaqChanged(changedArray);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.currentFaq?.attributes]);

  const checkForNested = (tag: TagScreenResponseInterface) => {
    let nested = tag?.children?.find((child) => child?.nestedChildren?.length && child?.nestedChildren?.length > 0);
    if (nested) return true;
    else return false;
  };

  // eslint-disable-next-line arrow-body-style
  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    // Can not select year after current year or before 2016
    return (current && current > moment().endOf('year')) || current < moment('2015').endOf('year');
  };

  const onFinishFaq = (values: any) => {
    let returnBoolean = false;

    questionArray?.forEach((question) => {
      if (!question?.title || !question?.pageNum) returnBoolean = true;
    });

    if (returnBoolean) {
      window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
      notification['error']({ message: i18n.translate('faq.form.questionError'), duration: 2 });
      return;
    }

    const getTags = () => {
      let array: Array<{ id: Number; type: string }> = [];
      tags.tagScreens?.forEach((tag) => {
        const value = values[tag?.filter?.name as keyof any];
        if (value) {
          if (tag?.selectType === SelectTypeEnum.YEAR) {
            const childTag = tag?.children?.find((child) => child?.tag?.name == moment(value).format('YYYY'));
            if (childTag) array.push({ id: childTag?.tag?.id as number, type: 'tag' });
          }
          if (tag?.selectType === SelectTypeEnum.SELECT || tag?.selectType === SelectTypeEnum.BOOLEAN) {
            const childTag = tag?.children?.find((child) => child?.tag?.id == value);
            if (childTag) array.push({ id: childTag?.tag?.id as number, type: 'tag' });
          }
          if (tag?.selectType === SelectTypeEnum.MULTISELECT) {
            const value = values[tag?.filter?.name as keyof any];
            if (value && value.length && value.length > 0) {
              value.forEach((id: any) => {
                if (id) array.push({ id: id, type: 'tag' });
              });
            }
          }
        }
      });

      return array;
    };

    let faqData: any = {
      data: {
        type: 'faq_group',
        id: data?.currentFaq?.id,
        attributes: {
          name: values?.title,
          faqs: questionArray,
        },
        relationships: {
          tags: {
            data: getTags(),
          },
          bilten: {
            data:
              !isExpertArticle && values?.newsletterRelationship
                ? { id: values?.newsletterRelationship, type: 'bilten' }
                : null,
          },
          professionalArticle: {
            data:
              isExpertArticle && values?.articleRelationship
                ? { id: values?.articleRelationship, type: 'professional_article' }
                : null,
          },
        },
      },
    };
    if (isNew) {
      dispatch(createFaqAction(faqData));
    } else {
      dispatch(updateFaqAction(faqData));
    }
    window.scrollTo({ top: 0, behavior: 'smooth' });

    setTimeout(() => {
      NavigationService.navigate(AdminRoutes.FAQ.fullPath);
    }, 1000);
  };

  const onFinishFaqFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const setTreeData = (children: Array<ChildrenTagScreenResponseInterface>) => {
    let values = children?.map((child) => ({
      title: child?.tag?.name,
      value: child?.tag?.id,
      key: child?.tag?.id,
      children: child?.nestedChildren?.map((nestedChild) => ({
        title: nestedChild?.tag?.name,
        value: nestedChild?.tag?.id,
        key: nestedChild?.tag?.id,
        children: nestedChild?.nestedChildren?.map((nestedLvl2Child) => ({
          title: nestedLvl2Child?.tag?.name,
          value: nestedLvl2Child?.tag?.id,
          key: nestedLvl2Child?.tag?.id,
        })),
      })),
    }));
    return values;
  };

  const getDisplayConditions = () => {
    if (isNew) {
      return tags?.tagScreens && tags?.tagScreens.length > 0;
    } else {
      return (
        tags?.tagScreens &&
        tags?.tagScreens.length > 0 &&
        data?.currentFaq &&
        biltens?.biltens?.data &&
        articles?.professionalArticles?.data
      );
    }
  };

  const getFilter = (field: string) => {
    let filter;
    let tag = tags?.tagScreens?.find((tag) => tag?.filter?.name == field);
    if (tag) {
      let array: number[] = [];
      data?.currentFaq?.attributes?.tagIds?.forEach((id) => {
        tag?.children?.forEach((child) => {
          if (id === child?.tag?.id) {
            if (tag?.selectType === SelectTypeEnum.YEAR) {
              filter = moment(child?.tag?.name);
            }
            if (tag?.selectType === SelectTypeEnum.SELECT || tag?.selectType === SelectTypeEnum.BOOLEAN) {
              filter = child?.tag?.id;
            }
            if (tag?.selectType === SelectTypeEnum.MULTISELECT) {
              array.push(child?.tag?.id);
            }
          }
        });
      });

      if (tag?.selectType === SelectTypeEnum.MULTISELECT) filter = array;
    }
    return filter;
  };

  const loadInitialValues = () => {
    let values: any = {
      title: data?.currentFaq?.attributes?.name,
      articleRelationship: data?.currentFaq?.relationships?.professionalArticle?.data?.id,
      newsletterRelationship: data?.currentFaq?.relationships?.bilten?.data?.id,
    };

    tags?.tagScreens?.forEach((tag) => {
      values[tag?.filter?.name as keyof any] = getFilter(tag?.filter?.name);
    });

    return values;
  };

  const loadDefaultValues = () => {
    let values: any = {};

    tags?.tagScreens?.forEach((tag) => {
      if (tag?.filter?.name == FilterNameEnumHR.ZJN) {
        values[tag?.filter?.name as keyof any] = [tag?.children?.length ? tag?.children[0]?.tag?.id : []];
      }
    });

    return values;
  };

  const clearCurrentFaq = () => {
    dispatch(clearFaqAction());
  };

  const showConfirm = () => {
    confirm({
      title: i18n.t('faq.confirmDelete'),
      icon: <ExclamationCircleOutlined />,
      content: i18n.t('common.cantUndo'),
      okText: i18n.t('genericButtons.yes'),
      cancelText: i18n.t('genericButtons.cancel'),
      onOk() {
        dispatch(deleteFaqAction(id));
        window.scrollTo({ top: 0, behavior: 'smooth' });

        setTimeout(() => {
          NavigationService.navigate(AdminRoutes.FAQ.fullPath);
        }, 1000);
      }
    });
  };

  const onChange = (e: RadioChangeEvent) => {
    if (e.target.value == 'article') {
      setIsExpertArticle(true);
    } else {
      setIsExpertArticle(false);
    }
  };

  const updateSingleFaq = (index: number) => {
    if (!data.included || !questionArray) return;
    const faq = data?.included[index];
    const question = questionArray[index];
    if (faq && question) {
      const updatedFaq = {
        data: {
          id: faq?.id,
          type: 'frequent_questions',
          attributes: {
            title: question?.title,
            pageNum: question?.pageNum,
            position: question?.position,
          },
          relationships: {
            faqGroup: {
              data: { id: data?.currentFaq?.id, type: 'faq_group' },
            },
          },
        },
      };
      FaqService.updateSingle(updatedFaq).subscribe(
        (response: any) => {
          dispatch(getSingleFaqAction(id));
          notification['success']({ message: i18n.translate('api.successMessage'), duration: 2 });
        },
        (error: Error) => {
          notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
        }
      );
    }
  };

  const updateFieldChanged = (index: number, field: string) => (e: any) => {
    if (!faqChanged || !questionArray) return;
    if (!isNew) {
      let newFaqChanged = [...faqChanged];
      newFaqChanged[index] = true;
      setFaqChanged(newFaqChanged);
    }
    let newArr = [...questionArray];
    if (field == 'title') newArr[index].title = e.target.value;
    if (field == 'pageNum') newArr[index].pageNum = e;

    setQuestionArray(newArr);
  };

  const addField = () => {
    if (!questionArray) return;
    let newArr = [...questionArray];
    newArr.push({ title: '', pageNum: 1, position: newArr.length + 1 });

    setQuestionArray(newArr);
  };

  const deleteField = (index: number) => {
    if (!questionArray) return;
    if (questionArray[index].id) {
      FaqService.deleteSingle(questionArray[index].id as number).subscribe(
        (response: any) => {
          dispatch(getSingleFaqAction(id));
          notification['success']({ message: i18n.translate('api.successMessage'), duration: 2 });
        },
        (error: Error) => {
          notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
        }
      );
    }
    let newArr = [...questionArray];
    newArr.splice(index, 1);

    setQuestionArray(newArr);
  };

  const returnComponent = (tag: TagScreenResponseInterface) => {
    let component = null;
    if (tag?.selectType == SelectTypeEnum.YEAR) {
      component = (
        <DatePicker
          size='large'
          picker="year"
          disabledDate={disabledDate}
          locale={userAuth.lang == 'hr' ? localeHR : localeEN}
          format={'YYYY.'}
        />
      );
    }

    if (tag?.selectType == SelectTypeEnum.BOOLEAN) {
      component = (
        <Radio.Group>
          <Radio.Button value={tag?.children?.length ? tag?.children[0]?.tag?.id : 0}>
            {tag?.children?.length ? tag?.children[0]?.tag?.name : ''}
          </Radio.Button>
          <Radio.Button value={tag?.children?.length ? tag?.children[1]?.tag.id : 1}>
            {tag?.children?.length ? tag?.children[1]?.tag?.name : ''}
          </Radio.Button>
        </Radio.Group>
      );
    }

    if (tag?.selectType == SelectTypeEnum.SELECT || tag?.selectType == SelectTypeEnum.MULTISELECT) {
      if (checkForNested(tag)) {
        component = (
          <TreeSelect
            treeData={setTreeData(tag?.children as Array<ChildrenTagScreenResponseInterface>)}
            treeCheckable
            showCheckedStrategy={SHOW_ALL}
            maxTagCount="responsive"
            placeholder={i18n.t('genericFormMsg.placeholders.select')}
          />
        );
      } else {
        component = (
          <Select
            mode={tag?.selectType == SelectTypeEnum.MULTISELECT ? 'multiple' : undefined}
            maxTagCount={tag?.selectType == SelectTypeEnum.MULTISELECT ? 'responsive' : undefined}
            allowClear
          >
            {tag?.children?.length &&
              tag?.children.map((child) => (
                <Select.Option key={child?.tag?.name} value={child?.tag?.id}>
                  {child?.tag?.name}
                </Select.Option>
              ))}
          </Select>
        );
      }
    }

    return component;
  };

  return (
    <div className="faqCrud w100-h100">
      {getDisplayConditions() && (
        <Form
          layout="vertical"
          name="faq"
          size="large"
          scrollToFirstError
          initialValues={data?.currentFaq && !isNew ? loadInitialValues() : loadDefaultValues()}
          onFinish={onFinishFaq}
          onFinishFailed={onFinishFaqFailed}
          autoComplete="off"
          requiredMark={false}
        >
          <div className="faqCrud__admin">
            <div className="title">{i18n.translate(isNew ? 'faq.crudTitleAdd' : 'faq.crudTitleUpdate')}</div>
            <div className="buttons">
              <Link to={AdminRoutes.FAQ.fullPath} key={AdminRoutes.FAQ.path}>
                <Button
                  onClick={clearCurrentFaq}
                  className="button"
                  size="large"
                  style={{ fontSize: 16, fontWeight: 700, borderRadius: 0, minWidth: 193, marginRight: 16 }}
                >
                  {i18n.translate('genericButtons.cancel')}
                </Button>
              </Link>
              <Button
                className="button"
                htmlType="submit"
                type="primary"
                size="large"
                style={{ fontSize: 16, fontWeight: 700, borderRadius: 0, minWidth: 193 }}
              >
                {i18n.translate('genericButtons.save')}
              </Button>
            </div>
          </div>
          <Divider />
          <div className="faqCrud__form">
            <div className="subtitle">
              <span className="text">{i18n.translate('faq.form.sections.info')}</span>
            </div>

            <Form.Item
              label={<span className="text-bold">{i18n.translate(`faq.form.fields.group`)}</span>}
              name="title"
              rules={[{ required: true, message: i18n.translate(`genericFormMsg.required`) }]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 12 }}
            >
              <Input style={{ borderRadius: 0 }} />
            </Form.Item>

            <Radio.Group
              style={{ marginTop: 8, marginBottom: 16 }}
              onChange={onChange}
              value={isExpertArticle ? 'article' : 'newsletter'}
            >
              <Radio value="newsletter">{i18n.t('faq.form.fields.newsletter')}</Radio>
              <Radio value="article">{i18n.t('faq.form.fields.article')}</Radio>
            </Radio.Group>

            {isExpertArticle ? (
              <Form.Item
                label={<span className="text-bold">{i18n.translate(`faq.form.fields.article`)}</span>}
                name="articleRelationship"
                rules={[{ required: true, message: i18n.translate(`genericFormMsg.required`) }]}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 5 }}
              >
                <Select allowClear>
                  {articles?.professionalArticles?.data?.map((article) => (
                    <Select.Option key={'article' + article?.id} value={article?.id}>
                      {article?.attributes?.title}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            ) : (
              <Form.Item
                label={<span className="text-bold">{i18n.translate(`faq.form.fields.newsletter`)}</span>}
                name="newsletterRelationship"
                rules={[{ required: true, message: i18n.translate(`genericFormMsg.required`) }]}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 5 }}
              >
                <Select allowClear>
                  {biltens?.biltens?.data?.map((bilten) => (
                    <Select.Option key={'bilten' + bilten?.id} value={bilten?.id}>
                      {bilten?.attributes?.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )}

            <div className="subtitle">
              <span className="text">{i18n.translate('faq.form.sections.filters')}</span>
            </div>

            {/** OVDJE ISPOD SVI FILTERI TREBAJU BITI GENERIČKI */}

            {tags?.tagScreens?.map((tag) => (
              <Form.Item
                label={<span className="text-bold">{`${tag?.filter?.name}`}</span>}
                name={tag?.filter?.name}
                key={tag?.filter?.name}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 8 }}
              >
                {returnComponent(tag)}
              </Form.Item>
            ))}

            {/** GENERIC FILTERI END */}

            <div className="subtitle">
              <span className="text">{i18n.translate('faq.form.sections.addQuestions')}</span>
            </div>

            {questionArray?.map((question, index) => (
              <div className="questionContainer" key={index}>
                <div className="column">
                  <div className="text" style={{ fontWeight: 'bold', marginBottom: 16 }}>
                    {i18n.t('faq.form.fields.title')}
                  </div>
                  <div className="input">
                    <Input value={question?.title} onChange={updateFieldChanged(index, 'title')} />
                  </div>
                </div>
                <div className="column">
                  <div className="text" style={{ fontWeight: 'bold', marginBottom: 16 }}>
                    {i18n.t('faq.form.fields.pageNum')}
                  </div>
                  <div className="input">
                    <InputNumber min={1} value={question?.pageNum} onChange={updateFieldChanged(index, 'pageNum')} />
                  </div>
                </div>
                {!isNew && (
                  <div className="column trashIcon">
                    <Button type="text" onClick={() => deleteField(index)} icon={<DeleteTwoTone />} />
                  </div>
                )}
                {isNew && index !== 0 && (
                  <div className="column trashIcon">
                    <Button type="text" onClick={() => deleteField(index)} icon={<DeleteTwoTone />} />
                  </div>
                )}
              </div>
            ))}

            <Button onClick={addField} type="link">
              {i18n.t('faq.form.addQuestion')}
            </Button>
          </div>
          <div className="faqCrud__bottomButtons">
            <div>
              {!isNew && (
                <Button
                  danger
                  onClick={showConfirm}
                  className="button"
                  size="large"
                  icon={<DeleteOutlined />}
                  style={{ fontSize: 16, fontWeight: 700, borderRadius: 0, minWidth: 193 }}
                >
                  {i18n.translate('genericButtons.delete')}
                </Button>
              )}
            </div>
            <div>
              <Link to={AdminRoutes.FAQ.fullPath} key={AdminRoutes.FAQ.path}>
                <Button
                  onClick={clearCurrentFaq}
                  className="button"
                  size="large"
                  style={{ fontSize: 16, fontWeight: 700, borderRadius: 0, minWidth: 193, marginRight: 16 }}
                >
                  {i18n.translate('genericButtons.cancel')}
                </Button>
              </Link>
              <Button
                className="button"
                htmlType="submit"
                type="primary"
                size="large"
                style={{ fontSize: 16, fontWeight: 700, borderRadius: 0, minWidth: 193 }}
              >
                {i18n.translate('genericButtons.save')}
              </Button>
            </div>
          </div>
        </Form>
      )}
    </div>
  );
}

export default FaqCrudPageComponent;