import BookmarkOutline from '../images/bookmarkOutline.svg';
import BookmarkFill from '../images/bookmarkFill.svg';
import React from 'react';

export const BookmarkOutlined = () => (
  <svg width="16" height="16" viewBox="0 0 20 22" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.35 21.7745L8.80797 18.195L8.75 18.1707L8.69203 18.195L0.15 21.7745V2.44444C0.15 1.186 1.20465 0.15 2.5 0.15H9.85V2.29444H2.5H2.35V2.44444V18.2967V18.522L2.55791 18.435L8.75 15.8437L14.9421 18.435L15.15 18.522V18.2967V9.92778H17.35V21.7745ZM15 4.73889H12.65V2.59444H15H15.15V2.44444V0.15H17.35V2.44444V2.59444H17.5H19.85V4.73889H17.5H17.35V4.88889V7.18333H15.15V4.88889V4.73889H15Z"
      fill="currentColor"
      stroke="white"
      strokeWidth="0.3"
    />
  </svg>
);
export const BookmarkFilled = () => <img alt="" width={16} height={16} src={BookmarkFill} />;
