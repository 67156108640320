import { Dispatch } from 'redux';
import { notification } from 'antd';
import { ProMaterialConstants } from './proMaterials.constants';
import { ProMaterialService } from '../../api/api.service';
import { ProMaterialResponseInterfaceAll, ProMaterialResponseInterfaceSingle } from '../../interfaces';
import { i18n } from '../../services';

export function getAllProMaterialsAction(params?: any, filter?: any, sort?: any, pagination?: any) {
  return (dispatch: Dispatch) => {
    return ProMaterialService.getAll(params, filter, sort, pagination).subscribe(
      (response: ProMaterialResponseInterfaceAll) => {
        dispatch({
          type: ProMaterialConstants.GET_ALL_SUCCESS,
          payload: response,
        });
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });

        dispatch({
          type: ProMaterialConstants.ERROR,
          payload: error,
        });
      }
    );
  };
}

export function getSingleProMaterialAction(id: number) {
  return (dispatch: Dispatch) => {
    return ProMaterialService.getSingle(id).subscribe(
      (response: ProMaterialResponseInterfaceSingle) => {
        dispatch({
          type: ProMaterialConstants.GET_SINGLE_SUCCESS,
          payload: response,
        });
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });

        dispatch({
          type: ProMaterialConstants.ERROR,
          payload: error,
        });
      }
    );
  };
}

export function getSingleProMaterialOnlyAction(id: number) {
  return (dispatch: Dispatch) => {
    return ProMaterialService.getSingle(id).subscribe(
      (response: ProMaterialResponseInterfaceSingle) => {
        dispatch({
          type: ProMaterialConstants.GET_SINGLE_ONLY,
          payload: response,
        });
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });

        dispatch({
          type: ProMaterialConstants.ERROR,
          payload: error,
        });
      }
    );
  };
}

export function createProMaterialAction(body: ProMaterialResponseInterfaceSingle) {
  return (dispatch: Dispatch) => {
    return ProMaterialService.create(body).subscribe(
      (response: ProMaterialResponseInterfaceSingle) => {
        notification['success']({ message: i18n.translate('api.successMessage'), duration: 2 });

        dispatch({
          type: ProMaterialConstants.CREATE_SUCCESS,
          payload: response,
        });
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });

        dispatch({
          type: ProMaterialConstants.ERROR,
          payload: error,
        });
      }
    );
  };
}

export function updateProMaterialAction(body: ProMaterialResponseInterfaceSingle) {
  return (dispatch: Dispatch) => {
    return ProMaterialService.update(body).subscribe(
      (response: ProMaterialResponseInterfaceSingle) => {
        notification['success']({ message: i18n.translate('api.successMessage'), duration: 2 });

        dispatch({
          type: ProMaterialConstants.UPDATE_SUCCESS,
          payload: response,
        });
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });

        dispatch({
          type: ProMaterialConstants.ERROR,
          payload: error,
        });
      }
    );
  };
}

export function deleteProMaterialAction(id: number) {
  return (dispatch: Dispatch) => {
    return ProMaterialService.delete(id).subscribe(
      (response: any) => {
        dispatch({
          type: ProMaterialConstants.DELETE
        });
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });

        dispatch({
          type: ProMaterialConstants.ERROR,
          payload: error,
        });
      }
    );
  };
}

export function clearProMaterialAction() {
  return (dispatch: Dispatch) => {
    dispatch({ type: ProMaterialConstants.CLEAR_CURRENT });
  };
}

export function clearProMaterialListAction() {
  return (dispatch: Dispatch) => {
    dispatch({ type: ProMaterialConstants.CLEAR_LIST });
  };
}
