import { Dispatch } from 'redux';
import { notification } from 'antd';
import { FaqConstants } from './faq.constants';

import { FaqService } from '../../api/api.service';
import { FaqResponseInterfaceAll, FaqResponseInterfaceSingle, FaqSingleInterface, FaqSingleInterfaceResponse } from '../../interfaces';
import { i18n } from '../../services';

export function getAllFaqsAction(params?: any, filter?: any, sort?: any, pagination?: any) {
  return (dispatch: Dispatch) => {
    return FaqService.getAll(params, filter, sort, pagination).subscribe(
      (response: FaqResponseInterfaceAll) => {
        dispatch({
          type: FaqConstants.GET_ALL_SUCCESS,
          payload: response,
        });
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });

        dispatch({
          type: FaqConstants.ERROR,
          payload: error,
        });
      }
    );
  };
}

export function getSingleFaqAction(id: number) {
  return (dispatch: Dispatch) => {
    return FaqService.getSingle(id).subscribe(
      (response: FaqResponseInterfaceSingle) => {
        dispatch({
          type: FaqConstants.GET_SINGLE_SUCCESS,
          payload: response,
        });
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });

        dispatch({
          type: FaqConstants.ERROR,
          payload: error,
        });
      }
    );
  };
}

export function getOneFaqAction(id: number) {
  return (dispatch: Dispatch) => {
    return FaqService.getOne(id).subscribe(
      (response: FaqSingleInterfaceResponse) => {
        dispatch({
          type: FaqConstants.GET_SINGLE_SUCCESS,
          payload: response,
        });
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });

        dispatch({
          type: FaqConstants.ERROR,
          payload: error,
        });
      }
    );
  };
}

export function createFaqAction(body: FaqResponseInterfaceSingle) {
  return (dispatch: Dispatch) => {
    return FaqService.create(body).subscribe(
      (response: FaqResponseInterfaceSingle) => {
        notification['success']({ message: i18n.translate('api.successMessage'), duration: 2 });

        dispatch({
          type: FaqConstants.CREATE_SUCCESS,
          payload: response,
        });
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });

        dispatch({
          type: FaqConstants.ERROR,
          payload: error,
        });
      }
    );
  };
}

export function updateFaqAction(body: FaqResponseInterfaceSingle) {
  return (dispatch: Dispatch) => {
    return FaqService.update(body).subscribe(
      (response: FaqResponseInterfaceSingle) => {
        notification['success']({ message: i18n.translate('api.successMessage'), duration: 2 });

        dispatch({
          type: FaqConstants.UPDATE_SUCCESS,
          payload: response,
        });
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });

        dispatch({
          type: FaqConstants.ERROR,
          payload: error,
        });
      }
    );
  };
}

export function deleteFaqAction(id: number) {
  return (dispatch: Dispatch) => {
    return FaqService.delete(id).subscribe(
      (response: any) => {
        dispatch({
          type: FaqConstants.DELETE,
        });
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });

        dispatch({
          type: FaqConstants.ERROR,
          payload: error,
        });
      }
    );
  };
}

export function clearFaqAction() {
  return (dispatch: Dispatch) => {
    dispatch({ type: FaqConstants.CLEAR_CURRENT });
  };
}

export function clearFaqListAction() {
  return (dispatch: Dispatch) => {
    dispatch({ type: FaqConstants.CLEAR_LIST });
  };
}
