import moment from 'moment';
import { from, Observable } from 'rxjs';
import { logoutAction, refreshTokenAction, store } from '../redux';
export default {
  GET,
  POST,
  PUT,
  DELETE,
  PATCH,
  getUrlQueryParams,
};

/**
 * GET request factory
 *
 * @param {string}    path          Resource path
 * @param {object}    params        Request query params
 */
function GET(
  path: string,
  params?: HttpParamsTypes,
  showLoader?: boolean,
  external?: boolean,
  refresh?: boolean
): Observable<any> {
  if (!refresh) checkForRefresh();

  let requestOptions;
  let url;

  if (external) {
    requestOptions = {
      method: 'GET',
    };
    url = getUrlWithParams(path);
  } else {
    requestOptions = {
      method: 'GET',
      headers: {
        ...httpHeader(refresh),
        Accept: 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
      },
    };
    url = [process.env.REACT_APP_API_URL, path].join('/');
    url = getUrlWithParams(url, params);
  }

  showLoader = showLoader === undefined ? true : showLoader;

  showLoader && startLoader();

  try {
    return from(
      fetch(url, requestOptions)
        .then(parseResponse)
        .then(showLoader ? endLoader : (r) => r)
        .catch((error) => {
          endLoader(showLoader);
          throw error;
        })
    );
  } catch (error) {
    endLoader(showLoader);
    throw error;
  }
}

/**
 * POST request factory
 *
 * @param {string}    path          Resource path
 * @param {object}    body          Request payload
 * @param {object}    params        Request query params
 */
function POST(path: string, body: any, params?: HttpParamsTypes, external?: boolean): Observable<any> {
  checkForRefresh();

  let requestOptions;
  let url;

  if (external) {
    requestOptions = {
      method: 'POST',
      body: JSON.stringify(body),
    };
    url = getUrlWithParams(path);
  } else {
    requestOptions = {
      method: 'POST',
      headers: {
        ...httpHeader(),
        Accept: 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
      },
      body: JSON.stringify(body),
    };
    url = [process.env.REACT_APP_API_URL, path].join('/');
    url = getUrlWithParams(url, params);
  }

  startLoader();

  return from(
    fetch(url, requestOptions)
      .then(parseResponse)
      .then(endLoader)
      .catch((error) => {
        endLoader();
        throw error;
      })
  );
}

/**
 * PUT request factory
 *
 * @param {string}    path          Resource path
 * @param {object}    body          Request payload
 * @param {object}    params        Request query params
 */
function PUT(path: string, body: any, params?: HttpParamsTypes, reindex?: boolean): Observable<any> {
  checkForRefresh();

  const requestOptions = {
    method: 'PUT',
    headers: {
      ...httpHeader(),
      Accept: reindex ? 'application/json' : 'application/vnd.api+json',
      'Content-Type': reindex ? 'application/json' : 'application/vnd.api+json',
    },
    body: JSON.stringify(body),
  };

  let url = [process.env.REACT_APP_API_URL, path].join('/');
  url = getUrlWithParams(url, params);

  startLoader();

  return from(
    fetch(url, requestOptions)
      .then(parseResponse)
      .then(endLoader)
      .catch((error) => {
        endLoader();
        throw error;
      })
  );
}

function PATCH(path: string, body: any, params?: HttpParamsTypes): Observable<any> {
  checkForRefresh();

  const requestOptions = {
    method: 'PATCH',
    headers: {
      ...httpHeader(),
      Accept: 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
    body: JSON.stringify(body),
  };

  let url = [process.env.REACT_APP_API_URL, path].join('/');
  url = getUrlWithParams(url, params);

  startLoader();

  return from(
    fetch(url, requestOptions)
      .then(parseResponse)
      .then(endLoader)
      .catch((error) => {
        endLoader();
        throw error;
      })
  );
}

/**
 * DELETE request factory
 *
 * @param {string}    path          Resource path
 * @param {object}    params        Request query params
 */
function DELETE(path: string, params?: HttpParamsTypes): Observable<any> {
  checkForRefresh();

  const requestOptions = {
    method: 'DELETE',
    headers: {
      ...httpHeader(),
      Accept: 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  };

  let url = [process.env.REACT_APP_API_URL, path].join('/');
  url = getUrlWithParams(url, params);

  startLoader();

  return from(
    fetch(url, requestOptions)
      .then(parseResponse)
      .then(endLoader)
      .catch((error) => {
        endLoader();
        throw error;
      })
  );
}

// ====== PRIVATE UTILS ======

function getUrlWithParams(url: string, params?: HttpParamsTypes) {
  if (params === null) return url;

  let p: string = '';
  if (params) {
    p = '?';
    let keys = Object.keys(params);
    for (let i in keys) {
      if (p !== '?') p += '&';
      p += encodeURIComponent(keys[i]) + '=' + encodeURIComponent(params[keys[i]]);
    }
  }

  return [url, p].join('');
}

/**
 * Requests header generator
 */
export function httpHeader(refresh?: boolean) {
  let header: any = {};
  var APP_TOKEN;
  var REFRESH_TOKEN;
  var ORGANIZATION_ID;

  let currentState: any = store.getState();

  if (currentState?.auth?.isAuthenticated || refresh) {
    APP_TOKEN = currentState.auth.accessToken;
    REFRESH_TOKEN = currentState.auth.refreshToken;
    ORGANIZATION_ID = currentState.auth.organizationId;

    //console.log('NEW ACCESS TOKEN', currentState?.auth?.accessToken);
    //console.log('NEW REFRESH TOKEN', currentState?.auth?.refreshToken);
  }

  if (APP_TOKEN && !refresh) header['Authorization'] = 'Bearer ' + APP_TOKEN;
  if (REFRESH_TOKEN && refresh) header['Authorization'] = 'Bearer ' + REFRESH_TOKEN;
  if (ORGANIZATION_ID) header['x-organization-id'] = ORGANIZATION_ID;

  return header;
}

function parseResponse(response: any) {
  return response.text().then((text: any) => {
    let data;

    try {
      data = text && JSON.parse(text);
    } catch (e) {
      data = null;
    }

    if (!response.ok) {
      if (!response.url.includes('/auth/login') && response.status === 401) {
        if (store.getState()?.auth?.isAuthenticated) store.dispatch(logoutAction());
        setTimeout(() => {
          window.location.href = '/auth/login';
        }, 100);
        return;
        //throw response;
      }

      throw data;
    }

    return data;
  });
}

function checkForRefresh() {
  let currentState: any = store.getState();

  if (currentState?.auth?.isAuthenticated) {
  const now = moment();
  const accessTokenValidTo = moment(currentState?.auth?.accessTokenValidTo);

    if (now.valueOf() >= accessTokenValidTo.subtract('15', 'minute').valueOf()) store.dispatch(refreshTokenAction());
  }

  // let currentState: any = store.getState();
  // if (currentState?.auth?.isAuthenticated) store.dispatch(refreshTokenAction());

  /**
  let currentState: any = store.getState();

  const accessTokenValidTo = moment(currentState?.auth?.accessTokenValidTo);
  const refreshTokenValidTo = moment(currentState?.auth?.refreshTokenValidTo);

  if (moment().isAfter(accessTokenValidTo) && moment().isBefore(refreshTokenValidTo)) {
    //console.log('OLD ACCESS TOKEN', currentState?.auth?.accessToken);
    //console.log('OLD REFRESH TOKEN', currentState?.auth?.refreshToken);
    store.dispatch(refreshTokenAction());
  }
  */
}

var LOADER__COUNT = 0;

function startLoader(response?: any) {
  LOADER__COUNT++;
  let el: any = document.getElementById('global-loader');
  let el2: any = document.getElementById('global-loader-wrap');
  el.style.display = 'block';
  el2.style.display = 'block';
  return response;
}

function endLoader(response?: any) {
  LOADER__COUNT--;
  if (LOADER__COUNT === 0) {
    let el: any = document.getElementById('global-loader');
    let el2: any = document.getElementById('global-loader-wrap');
    el.style.display = 'none';
    el2.style.display = 'none';
  }
  return response;
}

// ====== UTILS ======

export function getUrlQueryParams(
  pagination?: Pagination,
  sorter?: Sorter,
  filters?: Array<QueryFilter>,
  other?: any
): HttpParamsTypes {
  let params: HttpParamsTypes = {};

  if (pagination) {
    params.pagination = 'true';
    params.pageNumber = pagination.current.toString();
    params.perPage = pagination.pageSize.toString();
  } else {
    params.pagination = 'false';
  }

  if (sorter && sorter.columnKey) {
    params.sortAttr = sorter.columnKey;
    params.sortDir = sorter.order || 'ascend';
  }

  if (filters) {
    params.filter = filters.map((x) => [x.name, x.value].join('!')).join(';');
  }

  if (other) {
    params = { ...params, ...other };
  }

  return params;
}

export const DefaultPagination: Pagination = {
  current: 1,
  pageSize: 15,
  total: 0, //not important on call
};

export const createPaginationFromRequest = (request: any): Pagination => ({
  from: request.from,
  to: request.to,
  current: request.current_page,
  pageSize: request.per_page,
  total: request.total,
});

// ====== INTERFACES ======

export type HttpParamsTypes = { [index: string]: string };

export type QueryFilter = { name: string; value: string | number[]; exactMatch?: boolean };

export interface Pagination {
  current: number;
  pageSize: number;
  total: number;
  from?: number;
  to?: number;
}

export interface Sorter {
  column?: {
    title: string;
    dataIndex: string;
    sorter: boolean;
  };
  order?: string;
  field?: string;
  columnKey?: string;
}
