export enum NotesConstants {
  SET_IS_LOADING = 'NotesConstants_SET_IS_LOADING',
  GET_ALL_SUCCESS = 'NotesConstants_GET_ALL_SUCCESS',
  GET_SINGLE_SUCCESS = 'NotesConstants_GET_SINGLE_SUCCESS',
  CREATE_SUCCESS = 'NotesConstants_CREATE_SUCCESS',
  UPDATE_SUCCESS = 'NotesConstants_UPDATE_SUCCESS',
  CLEAR_CURRENT = 'NotesConstants_CLEAR_CURRENT',
  DELETE = 'NotesConstants_DELETE',
  ERROR = 'NotesConstants_ERROR',
}
