import { Breadcrumb, Collapse, notification, Pagination, PaginationProps, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import {
  ArticleCardComponent,
  clearNoteAction,
  clearProfessionalArticleAction,
  clearProfessionalArticleListAction,
  FilterComponent,
  FullOrganizationUnitResponseInterfaceSingle,
  getAllProfessionalArticlesAction,
  getScreenTagsByTypeAction,
  i18n,
  LicenceInterface,
  LicenceResponseInterfaceAll,
  LicenceResponseInterfaceSingle,
  LicenceService,
  LicenceUserResponseInterfaceAll,
  LicenceUserService,
  NoDataComponent,
  NoteResponseInterfaceAll,
  NoteService,
  OrganizationUnitService,
  ProfessionalArticleEsResponseInterface,
  ProfessionalArticleInterface,
  ProfessionalArticleService,
  ProfessionalArticleStateInterface,
  StoreStateInterface,
  TagScreenStateInterface,
  UserRoleEnum,
} from '../../../common';
import { HomeOutlined, CaretRightOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { LicenceEnum } from '../../../common/enums/licence.enum';

const { Text } = Typography;
const { Panel } = Collapse;

function ExpertArticlesPageComponent() {
  const userAuth = useSelector((state: StoreStateInterface) => state.auth);

  const dispatch = useDispatch();
  const data: ProfessionalArticleStateInterface = useSelector(
    (state: StoreStateInterface) => state.professionalArticle
  );
  const tags: TagScreenStateInterface = useSelector((state: StoreStateInterface) => state.tagScreen);

  const [filterValues, setFilterValues] = useState();
  const [esData, setEsData] = useState<ProfessionalArticleEsResponseInterface>();

  const [keyword, setKeyword] = useState('');
  //const [allOpinions, setAllOpinions] = useState(true);
  const [exactMatch, setExactMatch] = useState<boolean>(true);

  const [esSearch, setEsSearch] = useState(true);

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const [orgUnitInfo, setOrgUnitInfo] = useState<FullOrganizationUnitResponseInterfaceSingle>();
  const [licenceData, setLicenceData] = useState<LicenceInterface>();
  const [allValidLicences, setAllValidLicences] = useState<LicenceInterface[]>([]);
  const [noteData, setNoteData] = useState<NoteResponseInterfaceAll>();

  useEffect(() => {
    clearNoteAction();
    NoteService.getAll(userAuth.user?.id, false).subscribe(
      (response: NoteResponseInterfaceAll) => {
        setNoteData(response); 
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
      }
    );
  }, [userAuth?.user?.id]);

  useEffect(() => {
    if (userAuth.user?.organizationUnit?.id) {
      OrganizationUnitService.getSingle(userAuth.user?.organizationUnit?.id).subscribe(
        (response: FullOrganizationUnitResponseInterfaceSingle) => {
          setOrgUnitInfo(response);
        },
        (error: Error) => {
          notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
        }
      );
    }
  }, [userAuth.user]);

  useEffect(() => {
    if (orgUnitInfo?.data?.id) {
      LicenceService.getAll(undefined, `filter[organizationUnit.id]=${orgUnitInfo?.data?.id}`, 'sort=id', `page[offset]=0&page[limit]=999999`).subscribe(
        (response: LicenceResponseInterfaceAll) => {
          if (response?.data?.length) {
            let validLicences: LicenceInterface[] = [];

            response?.data?.forEach(licence => {
              if (licence.attributes?.licenceUsers?.find((x) => x.user?.id == userAuth.user?.id) && !licence.relationships?.licenceParent?.data && licence.attributes?.notesNum > 0) {
                setLicenceData(licence);
                const standardLicence = response.data.find(
                  (x) => x.attributes?.licenceUsers?.find((y) => y.user?.id == userAuth.user?.id) && !x.relationships?.licenceParent?.data && x.attributes?.active && x.attributes?.licenceType === LicenceEnum.STANDARD && licence.attributes?.notesNum > 0 && moment().isBefore(moment(licence?.attributes?.to).startOf('day'))
                );
                const premiumLicence = response.data.find(
                  (x) => x.attributes?.licenceUsers?.find((y) => y.user?.id == userAuth.user?.id) && !x.relationships?.licenceParent?.data && x.attributes?.active && x.attributes?.licenceType === LicenceEnum.PREMIUM && licence.attributes?.notesNum > 0 && moment().isBefore(moment(licence?.attributes?.to).startOf('day'))
                );
                if (standardLicence && standardLicence.attributes?.notesNum > 0) setLicenceData(standardLicence);
                if (premiumLicence && premiumLicence.attributes?.notesNum > 0) setLicenceData(premiumLicence);
              }
              if (licence.attributes?.licenceUsers?.find(x => x.user?.id == userAuth.user?.id) && !licence.relationships?.licenceParent?.data && licence.attributes?.active && licence?.attributes?.licenceType !== LicenceEnum.FREE && moment().isBefore(moment(licence?.attributes?.to).startOf('day'))) {
                validLicences.push(licence);
              }
            });

            setAllValidLicences([...validLicences]);
          }
        },
        (error: Error) => {
          notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
        }
      );
    }
  }, [orgUnitInfo?.data]);

  useEffect(() => {
    localStorage.removeItem('transferFilters');
    dispatch(clearProfessionalArticleAction());
    dispatch(clearProfessionalArticleListAction());
    dispatch(getScreenTagsByTypeAction('professional_article'));
    /**
    dispatch(
      getAllProfessionalArticlesAction(
        undefined,
        //undefined,
        `filter[visibility]=${((!licenceData && userAuth.user?.roles !== UserRoleEnum.ADMIN) || licenceData?.attributes?.licenceType === LicenceEnum.FREE) ? `all` : `all,subscribers`}`,
        'sort=-publishedAt',
        `page[offset]=${pageSize * (page - 1)}&page[limit]=${pageSize}`
      )
    );
    */
    esSearchFunction(false, { from: pageSize * (page - 1), size: pageSize });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const esSearchFunction = (allOpinions: boolean, pageSorted: {from: number, size: number}, keyword?: string, tagIds?: any) => {
    ProfessionalArticleService.elasticSearch(allOpinions, pageSorted, exactMatch, keyword, tagIds).subscribe(
      (response: ProfessionalArticleEsResponseInterface) => {
        setEsData(response);
      },
      (error: any) => {
        if (error.errorId == 23) {
          notification['warning']({ message: i18n.translate('common.licence.noFilter'), duration: 2 });
        }
        else {
          notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
        }
      }
    );
  };

  const refetch = (allValues: any) => {
    if (!allValidLicences.some(x => x.attributes?.licenceType === LicenceEnum.STANDARD) && !allValidLicences.some(x => x.attributes?.licenceType === LicenceEnum.PREMIUM)) {
      notification['warning']({ message: i18n.translate('common.licence.noFilter'), duration: 4 });
      return;
    }
    //setEsSearch(true);
    setFilterValues(allValues);
    setPage(1);
    esSearchFunction(false, {from: 0, size: pageSize}, keyword, allValues);
  };

  const resetSearch = () => {
    setEsData(undefined);
    setKeyword('');
    //setAllOpinions(true);
    //setEsSearch(false);
    setFilterValues(undefined);
    setPage(1);
    //dispatch(getAllProfessionalArticlesAction(undefined, `filter[visibility]=${((!licenceData && userAuth.user?.roles !== UserRoleEnum.ADMIN) || licenceData?.attributes?.licenceType === LicenceEnum.FREE) ? `all` : `all,subscribers`}`, 'sort=-publishedAt', `page[offset]=0&page[limit]=${pageSize}`));
    esSearchFunction(false, { from: 0, size: pageSize });
  };

  const onPaginationChangeEs: PaginationProps['onChange'] = (page, pageSize) => {
    setPage(page);
    setPageSize(pageSize);
    esSearchFunction(false, { from: pageSize * (page - 1), size: pageSize }, keyword, filterValues);
  };

  const onPaginationChange: PaginationProps['onChange'] = (page, pageSize) => {
    setPage(page);
    setPageSize(pageSize);
    dispatch(
      getAllProfessionalArticlesAction(
        filterValues,
        `filter[visibility]=${((!licenceData && userAuth.user?.roles !== UserRoleEnum.ADMIN) || licenceData?.attributes?.licenceType === LicenceEnum.FREE) ? `all` : `all,subscribers`}`,
        'sort=-publishedAt',
        `page[offset]=${pageSize * (page - 1)}&page[limit]=${pageSize}`
      )
    );
  };

  const sortedArticles = (articles: ProfessionalArticleInterface[]) => {
    let articleArray = articles;

    function onlyUnique(value: any, index: any, self: string | any[]) {
      return self.indexOf(value) === index;
    }

    let yearArray: string[] = [];
    let articleGroups: (ProfessionalArticleInterface[] | undefined)[] = [];

    articleArray?.forEach(article => {
      yearArray.push(article.attributes?.publishedAt ? moment(article.attributes?.publishedAt).format('YYYY.') : '');
    });

    let unique = yearArray?.filter(onlyUnique);

    unique?.forEach(entry => {
      articleGroups.push(articleArray?.filter(x => moment(x.attributes?.publishedAt).format('YYYY.') == entry));
    });

    if (articleArray.find(x => !x.attributes?.publishedAt)) {
      articleGroups.push(articleArray?.filter(x => !x.attributes?.publishedAt));
    }

    return articleGroups.filter(x => x?.length as number > 0);
  };

  const genHeader = (article: ProfessionalArticleInterface | undefined) => {
    return (
      <span className="header">
        <Text className="extraText">
          {article?.attributes?.publishedAt ? moment(article?.attributes?.publishedAt).format('YYYY.') : i18n.translate('common.unsorted')}
        </Text>
      </span>
    );
  };

  const getDefaultActiveKeys = (articles: ProfessionalArticleInterface[]) => {
    let articleArray = articles;

    function onlyUnique(value: any, index: any, self: string | any[]) {
      return self.indexOf(value) === index;
    }

    let yearArray: string[] = [];
    let defaultOpenArray: string[] = [];

    articleArray?.forEach(article => {
      yearArray.push(article.attributes?.publishedAt ? moment(article.attributes?.publishedAt).format('YYYY.') : '');
    });

    let unique = yearArray?.filter(onlyUnique);

    unique?.forEach((entry, index) => {
      defaultOpenArray.push('article_sorted' + index);
    });

    if (defaultOpenArray.length === 0) defaultOpenArray.push('article_sorted' + 0);
    if (defaultOpenArray.length > 0) defaultOpenArray.push('article_sorted' + defaultOpenArray.length);

    return defaultOpenArray;
  };

  const keywordChanged = (value: string) => {
    setKeyword(value);
  };

  const exactMatchChanged = (exactMatch: boolean) => {
    setExactMatch(exactMatch);
  };

  const convertDataType = (articles: ProfessionalArticleEsResponseInterface["hits"]): ProfessionalArticleInterface[] => {
    return articles.map((article) => {
      return {
        id: article.id,
        type: article.index,
        attributes: article.source
      };
    })
  };

  return (
    <div className="expertArticles w100-h100">
      <div className="expertArticles__breadcrumbs">
        <Breadcrumb>
          <Breadcrumb.Item>
            <HomeOutlined />
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span>{i18n.translate('breadcrumbs.web-of-knowledge')}</span>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span>{i18n.translate('menu.expert-articles')}</span>
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className='expertArticles__title'>{i18n.translate('expertArticles.title')}</div>
      {tags.tagScreens && tags.tagScreens.length ? <FilterComponent aggregation={esData?.aggregation?.buckets} filterAccess onExactMatchChange={exactMatchChanged} keyword={keyword} filters={tags.tagScreens} onFilterChange={refetch} onFilterRemove={resetSearch} onKeywordChange={keywordChanged} /> : null}
      {/**<YearDividerComponent title={''}/>*/}
      {esSearch &&
        <>
          {esData?.hits?.length ? (
            <Collapse className='noPointer' collapsible={undefined} activeKey={getDefaultActiveKeys(convertDataType(esData?.hits as ProfessionalArticleEsResponseInterface["hits"]) as ProfessionalArticleInterface[])} ghost expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 270 : 90} />}>
            {sortedArticles(convertDataType(esData?.hits as ProfessionalArticleEsResponseInterface["hits"]) as ProfessionalArticleInterface[])?.length ? 
            [...sortedArticles(convertDataType(esData?.hits as ProfessionalArticleEsResponseInterface["hits"]) as ProfessionalArticleInterface[])]
              .map((group, index: number) => (
                <Panel className='noPointer' showArrow={false} forceRender key={'article_sorted' + index} header={genHeader(group?.length && group[0] ? group[0] : undefined)}>
                  {group?.length ? (
                    [...(group as ProfessionalArticleInterface[])]
                      ?.map((article, i) => (
                        <ArticleCardComponent key={'article' + i} article={article} licenceData={licenceData} validLicences={allValidLicences} notes={noteData}/>
                      ))
                  ) : (
                    <div className="center">
                      <NoDataComponent />
                    </div>
                  )}
                </Panel>
              )) : null}
            </Collapse>
          ) : (
            <NoDataComponent />
          )}
        </>
      }
      {!esSearch &&
        <>
          {data.professionalArticles?.data?.length ? (
            <Collapse className='noPointer' collapsible={undefined} activeKey={getDefaultActiveKeys(data.professionalArticles?.data)} ghost expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 270 : 90} />}>
            {sortedArticles(data.professionalArticles?.data)?.length ? 
            [...sortedArticles(data.professionalArticles?.data)]
              .map((group, index: number) => (
                <Panel className='noPointer' showArrow={false} forceRender key={'article_sorted' + index} header={genHeader(group?.length && group[0] ? group[0] : undefined)}>
                  {group?.length ? (
                    [...(group as ProfessionalArticleInterface[])]
                      ?.map((article, i) => (
                        <ArticleCardComponent key={'article' + i} article={article} licenceData={licenceData} validLicences={allValidLicences} notes={noteData}/>
                      ))
                  ) : (
                    <div className="center">
                      <NoDataComponent />
                    </div>
                  )}
                </Panel>
              )) : null}
            </Collapse>
          ) : (
            <NoDataComponent />
          )}
        </>
      }
      {esSearch &&
        <>
          {esData?.hits?.length ? (
            <Pagination
              style={{ float: 'right', paddingTop: 16, paddingBottom: 16 }}
              onChange={onPaginationChangeEs}
              current={page}
              pageSize={pageSize}
              pageSizeOptions={['10', '20', '50', '100']}
              locale={{ items_per_page: `${i18n.t('pagination.perPage')}` }}
              total={esData?.totalHits}
              hideOnSinglePage
              responsive
              showTotal={(total) => `${i18n.t('pagination.total')}: ${total}`}
            />
          ) : null}
        </>
      }
      {!esSearch &&
        <>
          {data.professionalArticles?.data?.length ? (
            <Pagination
              style={{ float: 'right', paddingTop: 16, paddingBottom: 16 }}
              onChange={onPaginationChange}
              current={page}
              pageSize={pageSize}
              pageSizeOptions={['10', '20', '50', '100']}
              locale={{ items_per_page: `${i18n.t('pagination.perPage')}` }}
              total={data?.professionalArticles?.meta?.totalResourceCount}
              hideOnSinglePage
              responsive
              showTotal={(total) => `${i18n.t('pagination.total')}: ${total}`}
            />
          ) : null}
        </>
      }
    </div>
  );
}

export default ExpertArticlesPageComponent;
