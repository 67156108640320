import React, { useEffect, useRef, useState } from 'react';
import {
  AdminTableComponent,
  clearFullUserAction,
  clearFullUserListAction,
  getAllFullUsersAction,
  i18n,
  StoreStateInterface,
} from '../../../common';
import { Button, Dropdown, Menu, Space, Tabs } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { MoreOutlined, DownloadOutlined } from '@ant-design/icons';
import AdminUpgradeRequestComponent from '../../../common/components/tables/adminUpgradeRequest/adminUpgradeRequest.component';
import ExpiredLicencesComponent from '../../../common/components/tables/expiredLicences/expiredLicences.component';

function AdminProfilePageComponent() {
  const dispatch = useDispatch();
  const user = useSelector((state: StoreStateInterface) => state.fullUser);
  const allRef = useRef<typeof AdminTableComponent>();
  const premiumRef = useRef<typeof AdminTableComponent>();
  const standardRef = useRef<typeof AdminTableComponent>();
  const freeRef = useRef<typeof AdminTableComponent>();

  const [selectedTab, setSelectedTab] = useState('1');

  useEffect(() => {
    localStorage.removeItem('transferFilters');
    dispatch(clearFullUserListAction());
    dispatch(clearFullUserAction());
    dispatch(getAllFullUsersAction(undefined, undefined, 'sort=-id', `page[offset]=0&page[limit]=999999`));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const groupActionsMenu = (): React.ReactElement => (
    <Menu
      items={[
        {
          label: (
            <Button
              type="link"
              style={{ color: 'black' }}
              onClick={() => {
                if (!!allRef.current && selectedTab === "1") {
                  //@ts-ignore
                 return allRef.current.approveLicences();
                }
                if (!!premiumRef.current && selectedTab === "2") {
                  //@ts-ignore
                 return premiumRef.current.approveLicences();
                }
                if (!!standardRef.current && selectedTab === "3") {
                  //@ts-ignore
                 return standardRef.current.approveLicences();
                }
                if (!!freeRef.current && selectedTab === "4") {
                  //@ts-ignore
                 return freeRef.current.approveLicences();
                }
                }
              }
            >
              {i18n.translate(`profile.approveLicences`)}
            </Button>
          ),
          key: '0',
        },
        {
          label: (
            <Button
              type="link"
              style={{ color: 'black' }}
              onClick={() => {
                if (!!allRef.current && selectedTab === "1") {
                  //@ts-ignore
                 return allRef.current.unapproveLicences();
                }
                if (!!premiumRef.current && selectedTab === "2") {
                  //@ts-ignore
                 return premiumRef.current.unapproveLicences();
                }
                if (!!standardRef.current && selectedTab === "3") {
                  //@ts-ignore
                 return standardRef.current.unapproveLicences();
                }
                if (!!freeRef.current && selectedTab === "4") {
                  //@ts-ignore
                 return freeRef.current.unapproveLicences();
                }
                }
              }
            >
              {i18n.translate(`profile.unapproveLicences`)}
            </Button>
          ),
          key: '1',
        }
      ]}
    />
  );

  function downloadCsv() {
    let attrs = ["name", "address", "vat", "email", "phoneNumber", "competition", "adminFootnote", "footnote", "roles", "active", "lastActive", "createdDate"];
    let csvContent = "data:text/csv;charset=utf-8,";

    // Header
    let row = ["id", ...attrs].join(";");
    csvContent += row + "\r\n";

    // User data
    user.fullUsers?.data.forEach((userRow) => {
      let userAttrs: any = userRow.attributes;
      let row = [userRow.id, ...attrs.map(key => userAttrs[key])].join(";");
      csvContent += row + "\r\n";
    });
    
    // Open download
    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `bjn_korisnici_${new Date().toISOString().split('T')[0]}.csv`);
    document.body.appendChild(link);
    
    link.click();
  }

  return (
    <div className="adminProfile w100-h100">
      <div className="adminProfile__title" style={{ display: 'flex', justifyContent: 'space-between' }}>
        {i18n.translate('adminProfile.title')}
        <div>
          <Dropdown
            overlay={groupActionsMenu(
            )}
            trigger={['click']}
          >
            <a onClick={(e) => e.preventDefault()}>
              <Space>
                <Button type="link" icon={<MoreOutlined />}>{i18n.t("profile.groupActions")}</Button>
              </Space>
            </a>
          </Dropdown>
          <Button onClick={() => downloadCsv()} type="link" icon={<DownloadOutlined />} style={{ marginLeft: '8px' }}></Button>
        </div>
      </div>
      <Tabs activeKey={selectedTab} onChange={(activeKey) => setSelectedTab(activeKey)} destroyInactiveTabPane>
        <Tabs.TabPane tab={i18n.translate('adminProfile.tabs.all')} key="1">
          <AdminTableComponent forwardRef={allRef} users={user.fullUsers?.data} />
        </Tabs.TabPane>
        <Tabs.TabPane tab={i18n.translate('adminProfile.tabs.premium')} key="2">
            <AdminTableComponent forwardRef={premiumRef} users={user.fullUsers?.data} filter={'PREMIUM'} />
          {/**<BasicInfoComponent />*/}
        </Tabs.TabPane>
        <Tabs.TabPane tab={i18n.translate('adminProfile.tabs.basic')} key="3">
            <AdminTableComponent forwardRef={standardRef} users={user.fullUsers?.data} filter={'STANDARD'} />
          {/**<SubscriptionModelsComponent />*/}
        </Tabs.TabPane>
        <Tabs.TabPane tab={i18n.translate('adminProfile.tabs.starter')} key="4">
            <AdminTableComponent forwardRef={freeRef} users={user.fullUsers?.data} filter={'FREE'} />
          {/**<TeamComponent />*/}
        </Tabs.TabPane>
        <Tabs.TabPane tab={i18n.translate('adminProfile.tabs.upgradeRequest')} key="5">
          <AdminUpgradeRequestComponent />
        </Tabs.TabPane>
        <Tabs.TabPane tab={i18n.translate('adminProfile.tabs.expiredLicences')} key="6">
          <ExpiredLicencesComponent />
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
}

export default AdminProfilePageComponent;
