import { Breadcrumb, Collapse, notification, Pagination, PaginationProps, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import {
  ProMaterialCardComponent,
  i18n,
  StoreStateInterface,
  ProMaterialInterface,
  NoDataComponent,
  getScreenTagsByTypeAction,
  TagScreenStateInterface,
  FilterComponent,
  ProMaterialEsResponseInterface,
  ProMaterialService,
  YearDividerComponent,
  SelectTypeEnum,
  LicenceInterface,
  LicenceResponseInterfaceSingle,
  LicenceService,
  LicenceUserResponseInterfaceAll,
  LicenceUserService,
  clearNoteAction,
  NoteResponseInterfaceAll,
  NoteService,
  ESFilterInterface,
  FullOrganizationUnitResponseInterfaceSingle,
  LicenceResponseInterfaceAll,
  OrganizationUnitService,
} from '../../../../common';
import { HomeOutlined, CaretRightOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearProMaterialAction,
  clearProMaterialListAction,
  getAllProMaterialsAction,
  ProMaterialStateInterface,
} from '../../../../common/redux/proMaterials';
import moment from 'moment';
import { LicenceEnum } from '../../../../common/enums/licence.enum';

const { Panel } = Collapse;
const { Text } = Typography;

function ProMaterialsPageComponent() {
  const userAuth = useSelector((state: StoreStateInterface) => state.auth);

  const dispatch = useDispatch();
  const proMaterials: ProMaterialStateInterface = useSelector((state: StoreStateInterface) => state.proMaterial);

  const tags: TagScreenStateInterface = useSelector((state: StoreStateInterface) => state.tagScreen);

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [filterValues, setFilterValues] = useState<ESFilterInterface | undefined>();

  const [esData, setEsData] = useState<ProMaterialEsResponseInterface>();

  const [keyword, setKeyword] = useState('');
  //const [allOpinions, setAllOpinions] = useState(true);
  const [exactMatch, setExactMatch] = useState<boolean>(true);

  const [esSearch, setEsSearch] = useState(true);

  const [orgUnitInfo, setOrgUnitInfo] = useState<FullOrganizationUnitResponseInterfaceSingle>();
  const [licenceData, setLicenceData] = useState<LicenceInterface>();
  const [allValidLicences, setAllValidLicences] = useState<LicenceInterface[]>([]);
  const [noteData, setNoteData] = useState<NoteResponseInterfaceAll>();

  useEffect(() => {
    clearNoteAction();
    NoteService.getAll(userAuth.user?.id, false).subscribe(
      (response: NoteResponseInterfaceAll) => {
        setNoteData(response); 
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
      }
    );
  }, [userAuth?.user?.id]);

  useEffect(() => {
    if (userAuth.user?.organizationUnit?.id) {
      OrganizationUnitService.getSingle(userAuth.user?.organizationUnit?.id).subscribe(
        (response: FullOrganizationUnitResponseInterfaceSingle) => {
          setOrgUnitInfo(response);
        },
        (error: Error) => {
          notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
        }
      );
    }
  }, [userAuth.user]);

  useEffect(() => {
    if (orgUnitInfo?.data?.id) {
      LicenceService.getAll(undefined, `filter[organizationUnit.id]=${orgUnitInfo?.data?.id}`, 'sort=id', `page[offset]=0&page[limit]=999999`).subscribe(
        (response: LicenceResponseInterfaceAll) => {
          if (response?.data?.length) {
            let validLicences: LicenceInterface[] = [];

            response?.data?.forEach(licence => {
              if (licence.attributes?.licenceUsers?.find((x) => x.user?.id == userAuth.user?.id) && !licence.relationships?.licenceParent?.data && licence.attributes?.notesNum > 0) {
                setLicenceData(licence);
                const standardLicence = response.data.find(
                  (x) => x.attributes?.licenceUsers?.find((y) => y.user?.id == userAuth.user?.id) && !x.relationships?.licenceParent?.data && x.attributes?.active && x.attributes?.licenceType === LicenceEnum.STANDARD && licence.attributes?.notesNum > 0 && moment().isBefore(moment(licence?.attributes?.to).startOf('day'))
                );
                const premiumLicence = response.data.find(
                  (x) => x.attributes?.licenceUsers?.find((y) => y.user?.id == userAuth.user?.id) && !x.relationships?.licenceParent?.data && x.attributes?.active && x.attributes?.licenceType === LicenceEnum.PREMIUM && licence.attributes?.notesNum > 0 && moment().isBefore(moment(licence?.attributes?.to).startOf('day'))
                );
                if (standardLicence && standardLicence.attributes?.notesNum > 0) setLicenceData(standardLicence);
                if (premiumLicence && premiumLicence.attributes?.notesNum > 0) setLicenceData(premiumLicence);
              }
              if (licence.attributes?.licenceUsers?.find(x => x.user?.id == userAuth.user?.id) && !licence.relationships?.licenceParent?.data && licence.attributes?.active && licence?.attributes?.licenceType === LicenceEnum.PREMIUM && moment().isBefore(moment(licence?.attributes?.to).startOf('day'))) {
                validLicences.push(licence);
              }
            });

            setAllValidLicences([...validLicences]);
          }
        },
        (error: Error) => {
          notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
        }
      );
    }
  }, [orgUnitInfo?.data]);

  useEffect(() => {
    localStorage.removeItem('transferFilters');
    dispatch(clearProMaterialAction());
    dispatch(clearProMaterialListAction());
    dispatch(getScreenTagsByTypeAction('pro_material'));
    esSearchFunction(false, { from: 0, size: pageSize }, keyword, undefined)
    /*dispatch(
      getAllProMaterialsAction(
        undefined,
        undefined,
        'sort=-year',
        `page[offset]=${pageSize * (page - 1)}&page[limit]=${pageSize}`
      )
    ); */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const esSearchFunction = (
    allOpinions: boolean,
    pageSorted: { from: number; size: number },
    keyword?: string,
    tagIds?: any
  ) => {
    ProMaterialService.elasticSearch(allOpinions, pageSorted, exactMatch, keyword, tagIds).subscribe(
      (response: ProMaterialEsResponseInterface) => {
        setEsData(response);
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
      }
    );
  };

  const refetch = (allValues: ESFilterInterface) => {
    if (!allValidLicences.some(x => x.attributes?.licenceType === LicenceEnum.PREMIUM)) {
      notification['warning']({ message: i18n.translate('common.licence.noFilter'), duration: 4 });
      return;
    }
    //setEsSearch(true);
    setFilterValues(allValues);
    setPage(1);
    esSearchFunction(false, { from: 0, size: pageSize }, keyword, allValues);
  };

  const resetSearch = () => {
    setEsData(undefined);
    setKeyword('');
    //setAllOpinions(true);
    //setEsSearch(false);
    setFilterValues(undefined);
    setPage(1);
    esSearchFunction(false, { from: 0, size: pageSize })
    //dispatch(getAllProMaterialsAction(undefined, undefined, 'sort=-year', `page[offset]=0&page[limit]=${pageSize}`));
  };

  const sortedProMaterials = (proMaterials: ProMaterialInterface[]) => {
    const tagYearsObject = tags?.tagScreens?.find(x => x.selectType === SelectTypeEnum.YEAR);
    const tagYearsArray = tagYearsObject?.children;
    
    let proMaterialArray = proMaterials;
    let unsorted: ProMaterialInterface[] = [];

    function onlyUnique(value: any, index: any, self: string | any[]) {
      return self.indexOf(value) === index;
    }

    let yearArray: string[] = [];
    let proMaterialGroups: (ProMaterialInterface[] | undefined)[] = [];

    proMaterialArray?.forEach(proMaterial => {
      proMaterial.attributes?.tagIds?.forEach(tagId => {
        const found = tagYearsArray?.find(x => x.tag?.id == tagId);
        if (found) {
          yearArray.push(moment(found.tag?.name).format('YYYY.'));
        } else {
          yearArray.push('');
        }
      });
    });

    let unique = yearArray?.filter(onlyUnique);

    unique?.forEach(entry => {
      let sameYearProMaterials: ProMaterialInterface[] = [];
      proMaterialArray?.forEach(proMaterial => {
        proMaterial.attributes?.tagIds?.forEach(tagId => {
          const found = tagYearsArray?.find(x => x.tag?.id == tagId);
          if (found && entry == moment(found.tag?.name).format('YYYY.')) {
            sameYearProMaterials.push(proMaterial);
          }
        });
      });
      proMaterialGroups.push(sameYearProMaterials);
    });

    proMaterialArray?.forEach(proMaterial => {
      let foundOne = false;
      proMaterial.attributes?.tagIds?.forEach(tagId => {
        const found = tagYearsArray?.find(x => x.tag?.id == tagId);
        if (found) {
          foundOne = true;
        }
      });
      if (!foundOne) unsorted.push(proMaterial);
    });

    proMaterialGroups.push(unsorted);

    return proMaterialGroups.filter(x => x?.length as number > 0);
  };

  const genHeader = (proMaterial: ProMaterialInterface | undefined) => {
    const tagYearsObject = tags?.tagScreens?.find(x => x.selectType === SelectTypeEnum.YEAR);
    const tagYearsArray = tagYearsObject?.children;

    let headerString = i18n.translate('common.unsorted');

    proMaterial?.attributes?.tagIds?.forEach(tagId => {
      const found = tagYearsArray?.find(x => x.tag?.id == tagId);
      if (found) {
        headerString = moment(found.tag?.name).format('YYYY.');
      }
    });

    return (
      <span className="header">
        <Text className="extraText">
          {headerString}
        </Text>
      </span>
    );
  };

  const getDefaultActiveKeys = (proMaterials: ProMaterialInterface[]) => {
    const tagYearsObject = tags?.tagScreens?.find(x => x.selectType === SelectTypeEnum.YEAR);
    const tagYearsArray = tagYearsObject?.children;
    
    let proMaterialArray = proMaterials;

    function onlyUnique(value: any, index: any, self: string | any[]) {
      return self.indexOf(value) === index;
    }

    let yearArray: string[] = [];
    let defaultOpenArray: string[] = [];

    proMaterialArray?.forEach(proMaterial => {
      proMaterial.attributes?.tagIds?.forEach(tagId => {
        const found = tagYearsArray?.find(x => x.tag?.id == tagId);
        if (found) {
          yearArray.push(moment(found.tag?.name).format('YYYY.'));
        } else {
          yearArray.push('');
        }
      });
    });

    let unique = yearArray?.filter(onlyUnique);

    unique?.forEach((entry, index) => {
      defaultOpenArray.push('proMaterial_sorted' + index);
    });

    if (defaultOpenArray.length === 0) defaultOpenArray.push('proMaterial_sorted' + 0);
    if (defaultOpenArray.length > 0) defaultOpenArray.push('proMaterial_sorted' + defaultOpenArray.length);

    return defaultOpenArray;
  };
  
  const onPaginationChange: PaginationProps['onChange'] = (page, pageSize) => {
    if (!allValidLicences.some(x => x.attributes?.licenceType === LicenceEnum.PREMIUM)) {
      notification['warning']({ message: i18n.translate('common.licence.noFilter'), duration: 4 });
      return;
    }
    setPage(page);
    setPageSize(pageSize);
    if (esSearch) {
      esSearchFunction(false, { from: pageSize * (page - 1), size: pageSize }, keyword, filterValues);
    } else {
      dispatch(
        getAllProMaterialsAction(
          filterValues,
          undefined,
          'sort=-year',
          `page[offset]=${pageSize * (page - 1)}&page[limit]=${pageSize}`
        )
      );
    }
  };

  const keywordChanged = (value: string) => {
    setKeyword(value);
  };

  const exactMatchChanged = (exactMatch: boolean) => {
    setExactMatch(exactMatch);
  };

  const convertDataType = (proMaterials: ProMaterialEsResponseInterface['hits']): ProMaterialInterface[] => {
    return proMaterials.map((proMaterial) => {
      return {
        id: proMaterial.id,
        type: proMaterial.index,
        attributes: proMaterial.source,
      };
    });
  };

  return (
    <div className="proMaterials w100-h100">
      <div className="proMaterials__breadcrumbs">
        <Breadcrumb>
          <Breadcrumb.Item>
            <HomeOutlined />
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span>{i18n.translate('breadcrumbs.eu-practice')}</span>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span>{i18n.translate('menu.professional-materials')}</span>
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="proMaterials__title">{i18n.translate('proMaterials.title')}</div>
      {tags.tagScreens && tags.tagScreens.length ? (
        <FilterComponent
          aggregation={esData?.aggregation?.buckets}
          filterAccess
          keyword={keyword}
          filters={tags.tagScreens}
          onFilterChange={refetch}
          onFilterRemove={resetSearch}
          onKeywordChange={keywordChanged}
          onExactMatchChange={exactMatchChanged}
        />
      ) : null}
      <YearDividerComponent title={''} />
      {esSearch && (
        <>
          {esData?.hits?.length ? (
            <Collapse className='noPointer' collapsible={undefined} activeKey={getDefaultActiveKeys(convertDataType(esData?.hits as ProMaterialEsResponseInterface["hits"]) as ProMaterialInterface[])} ghost expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 270 : 90} />}>
            {sortedProMaterials(convertDataType(esData?.hits as ProMaterialEsResponseInterface["hits"]) as ProMaterialInterface[])?.length ? 
            [...sortedProMaterials(convertDataType(esData?.hits as ProMaterialEsResponseInterface["hits"]) as ProMaterialInterface[])]
              .map((group, index: number) => (
                <Panel className='noPointer' showArrow={false} forceRender key={'proMaterial_sorted' + index} header={genHeader(group?.length && group[0] ? group[0] : undefined)}>
                  {group?.length ? (
                    [...(group as ProMaterialInterface[])]
                      ?.map((proMaterial, i) => (
                        <ProMaterialCardComponent licenceData={licenceData} validLicences={allValidLicences} key={'euProMaterial' + i} proMaterial={proMaterial} notes={noteData} tags={tags.tagScreens} />
                      ))
                  ) : (
                    <div className="center">
                      <NoDataComponent />
                    </div>
                  )}
                </Panel>
              )) : null}
            </Collapse>
          ) : (
            <NoDataComponent />
          )}
        </>
      )}
      {!esSearch && (
        <>
          {proMaterials.promaterials?.data?.length ? (
            <Collapse className='noPointer' collapsible={undefined} activeKey={getDefaultActiveKeys(proMaterials.promaterials?.data)} ghost expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 270 : 90} />}>
            {sortedProMaterials(proMaterials.promaterials?.data)?.length ? 
            [...sortedProMaterials(proMaterials.promaterials?.data)]
              .map((group, index: number) => (
                <Panel className='noPointer' showArrow={false} forceRender key={'proMaterial_sorted' + index} header={genHeader(group?.length && group[0] ? group[0] : undefined)}>
                  {group?.length ? (
                    [...(group as ProMaterialInterface[])]
                      ?.map((proMaterial, i) => (
                        <ProMaterialCardComponent licenceData={licenceData} validLicences={allValidLicences} key={'euProMaterial' + i} proMaterial={proMaterial} notes={noteData} tags={tags.tagScreens} />
                      ))
                  ) : (
                    <div className="center">
                      <NoDataComponent />
                    </div>
                  )}
                </Panel>
              )) : null}
            </Collapse>
          ) : (
            <NoDataComponent />
          )}
        </>
      )}
      {esSearch && (
        <>
          {esData?.hits?.length ? (
            <Pagination
              style={{ float: 'right', paddingTop: 16, paddingBottom: 16 }}
              onChange={onPaginationChange}
              current={page}
              pageSize={pageSize}
              pageSizeOptions={['10', '20', '50', '100']}
              locale={{ items_per_page: `${i18n.t('pagination.perPage')}` }}
              total={esData?.totalHits}
              hideOnSinglePage
              responsive
              showTotal={(total) => `${i18n.t('pagination.total')}: ${total}`}
            />
          ) : null}
        </>
      )}
      {!esSearch && (
        <>
          {proMaterials.promaterials?.data?.length ? (
            <Pagination
              style={{ float: 'right', paddingTop: 16, paddingBottom: 16 }}
              onChange={onPaginationChange}
              current={page}
              pageSize={pageSize}
              pageSizeOptions={['10', '20', '50', '100']}
              locale={{ items_per_page: `${i18n.t('pagination.perPage')}` }}
              total={proMaterials?.promaterials?.meta?.totalResourceCount}
              hideOnSinglePage
              responsive
              showTotal={(total) => `${i18n.t('pagination.total')}: ${total}`}
            />
          ) : null}
        </>
      )}
    </div>
  );
}

export default ProMaterialsPageComponent;
