import { Observable } from 'rxjs';
import {
  AllegationEsResponseInterface, AllegationResponseInterfaceAll,
  AllegationResponseInterfaceSingle, AppealEsResponseInterface, AppealResponseInterfaceAll,
  AppealResponseInterfaceSingle, BiltenEsResponseInterface, BiltenResponseInterfaceAll,
  BiltenResponseInterfaceSingle, CourtEsResponseInterface, CourtResponseInterfaceAll,
  CourtResponseInterfaceSingle, DecisionEsResponseInterface, DecisionResponseInterfaceAll,
  DecisionResponseInterfaceSingle,
  EducationInterface, ESFilterInterface,
  FaqEsResponseInterface, FaqResponseInterfaceAll,
  FaqResponseInterfaceSingle, FaqSingleInterfaceResponse, FullOrganizationResponseInterfaceAll,
  FullOrganizationResponseInterfaceSingle,
  FullOrganizationUnitResponseInterfaceAll,
  FullOrganizationUnitResponseInterfaceSingle,
  FullUserResponseInterfaceAll,
  FullUserResponseInterfaceSingle,
  LicenceResponseInterfaceAll,
  LicenceResponseInterfaceSingle,
  LicenceUserResponseInterfaceAll,
  LicenceUserResponseInterfaceSingle, LoginInterface,
  LoginResponseInterface,
  NewsInterface, NoteLinkResponseInterfaceAll,
  NoteLinkResponseInterfaceSingle, NoteResponseInterfaceAll,
  NoteResponseInterfaceSingle, NotificationInterfaceAll,
  NotificationInterfaceSingle, OpinionEsResponseInterface, OpinionResponseInterfaceAll,
  OpinionResponseInterfaceSingle, OrderEsResponseInterface, OrderResponseInterfaceAll,
  OrderResponseInterfaceSingle, PriceResponseInterfaceAll,
  PriceResponseInterfaceSingle,
  PrintEditionResponseInterfaceAll,
  PrintEditionResponseInterfaceSingle,
  PrintEditionsBiltenIncludedResponse, ProfessionalArticleEsResponseInterface, ProfessionalArticleResponseInterfaceAll,
  ProfessionalArticleResponseInterfaceSingle, ProMaterialEsResponseInterface, ProMaterialResponseInterfaceAll, ProMaterialResponseInterfaceSingle, RegisterInterface, RegulationEsResponseInterface, RegulationResponseInterfaceAll,
  RegulationResponseInterfaceSingle, ResponseEsResponseInterface, ResponseResponseInterfaceAll,
  ResponseResponseInterfaceSingle, RoadmapEsResponseInterface, RoadmapResponseInterfaceAll,
  RoadmapResponseInterfaceSingle, SorterInterface, SummaryEsResponseInterface, SummaryResponseInterfaceAll,
  SummaryResponseInterfaceSingle, SynopsisEsResponseInterface, SynopsisResponseInterfaceAll,
  SynopsisResponseInterfaceSingle, TagScreenResponseInterface, UpgradeRequestResponseInterfaceAll,
  UpgradeRequestResponseInterfaceSingle, UserActivityLogResponseInterfaceAll, UserActivityLogResponseInterfaceSingle, UserLogStatisticsResponseInterfaceAll, VerdictEsResponseInterface, VerdictResponseInterfaceAll,
  VerdictResponseInterfaceSingle
} from '../interfaces';
import HttpFactory, { QueryFilter } from './abstract.api.service';
import { apiRoutes } from './routes';
import { ExpiredLicenceInterface } from '../interfaces/expiredLicence/expiredLicence.interface';

const EsAttributesHelper = (attributes: Array<{ field: string, value: string } | { field: string, tags: Array<number> }>, tagIds?: ESFilterInterface): Array<{ field: string, value: string } | { field: string, tags: Array<number> }> => {
  let andTags = { field: "tagIds", tags: new Array<number>() };
  let orTags = { field: "orTagIds", tags: new Array<number>() };
  if (tagIds) {
    for (let e in tagIds) {
      if (!!tagIds[e].tags) {
        if (tagIds[e].tags.length === 1) {
          andTags.tags = andTags.tags.concat(tagIds[e].tags)
        } else if (tagIds[e].tags.length > 1) {
          orTags.tags = orTags.tags.concat(tagIds[e].tags)
        }
      }
    }
  }

  if (andTags.tags.length > 0) {
    andTags.tags = andTags.tags
    attributes.push(andTags)
  }
  if (orTags.tags.length > 0) {
    orTags.tags = orTags.tags
    attributes.push(orTags)
  }

  return attributes;
}

const EsAttributesHelperAndOr = (tagIds?: ESFilterInterface): Array<number[]> => {
  let andOrTags = new Array<number[]>()
  if (tagIds) {
    for (let e in tagIds) {
      if (!!tagIds[e].tags) {
        if (tagIds[e].tags.length) andOrTags.push(tagIds[e].tags)
      }
    }
  }

  return andOrTags;
}

export class UserService {
  static login(data: LoginInterface): Observable<LoginResponseInterface> {
    return HttpFactory.POST(apiRoutes.login, data);
  }

  static logout(): Observable<LoginResponseInterface> {
    return HttpFactory.POST(apiRoutes.logout, {});
  }

  static register(data: RegisterInterface): Observable<any> {
    return HttpFactory.POST(apiRoutes.register, data);
  }

  static refreshToken(): Observable<LoginResponseInterface> {
    return HttpFactory.GET(apiRoutes.refreshToken, undefined, false, false, true);
  }

  static checkEmail(email: string): Observable<boolean> {
    return HttpFactory.GET(apiRoutes.registerCheckEmail, { email });
  }

  static resetPassword(email: string): Observable<boolean> {
    return HttpFactory.POST(apiRoutes.resetPassword, {}, { email });
  }
}

export class UserActivityLogService {
  static getAll(userId?: number | string, filter?: any, sort?: any, pagination?: any): Observable<UserActivityLogResponseInterfaceAll> {
    let paramString = '';
    let filterString = '';
    let sortString = '';
    let paginationString = '';
    if (userId) {
      paramString = `&filter[user.id]=${userId}`;
    }
    if (filter) {
      filterString = `&${filter}`;
    }
    if (sort) {
      sortString = `&${sort}`;
    }
    if (pagination) {
      paginationString = `&${pagination}`;
    }

    return HttpFactory.GET(apiRoutes.userActivityLog + '?include=user' + paramString + filterString + sortString + paginationString);
  }

  static getSingle(id: number): Observable<UserActivityLogResponseInterfaceSingle> {
    return HttpFactory.GET(apiRoutes.userActivityLog + `/${id}`);
  }

  static create(body: UserActivityLogResponseInterfaceSingle): Observable<UserActivityLogResponseInterfaceSingle> {
    return HttpFactory.POST(apiRoutes.userActivityLog, body);
  }

  static update(body: UserActivityLogResponseInterfaceSingle): Observable<UserActivityLogResponseInterfaceSingle> {
    return HttpFactory.PATCH(apiRoutes.userActivityLog + `/${body.data.id}`, body);
  }

  static delete(id: number): Observable<any> {
    return HttpFactory.DELETE(apiRoutes.userActivityLog + `/${id}`);
  }
}

export class UserLogStatisticsService {
  static getAll(userId?: number | string, params?: any, filter?: any, sort?: any, pagination?: any): Observable<UserLogStatisticsResponseInterfaceAll> {
    let userString = '';
    let paramString = '';
    let filterString = '';
    let sortString = '';
    let paginationString = '';
    if (userId) {
      userString = `/user/${userId}`;
    }
    if (params) {
      paramString = `?${params}`;
    }
    if (filter) {
      filterString = paramString ? `&${filter}` : `?${filter}`;
    }
    if (sort) {
      sortString = !paramString && !filterString ? `?${sort}` : `&${sort}`;
    }
    if (pagination) {
      paginationString = !paramString && !filterString && !sortString ? `?${pagination}` : `&${pagination}`;
    }

    return HttpFactory.GET(apiRoutes.userLogStatistics + userString + paramString + filterString + sortString + paginationString);
  }

  static getStatisticDetails(params?: any, filter?: any, sort?: any, pagination?: any): Observable<any> {
    let paramString = '';
    let filterString = '';
    let sortString = '';
    let paginationString = '';
    if (params) {
      paramString = `?${params}`;
    }
    if (filter) {
      filterString = paramString ? `&${filter}` : `?${filter}`;
    }
    if (sort) {
      sortString = !paramString && !filterString ? `?${sort}` : `&${sort}`;
    }
    if (pagination) {
      paginationString = !paramString && !filterString && !sortString ? `?${pagination}` : `&${pagination}`;
    }

    return HttpFactory.GET(`${apiRoutes.userLogStatistics}/detailed_view` + paramString + filterString + sortString + paginationString);
  }
}

export class BiltenService {
  static getAll(params?: any): Observable<BiltenResponseInterfaceAll> {
    let paramArray: any[] = [];
    let paramString = '';
    if (params) {
      paramArray = processTags(params);
      if (paramArray?.length) paramString = `?filter={${paramArray?.join(',')}}`;
    }

    return HttpFactory.GET(apiRoutes.bilten + `?page[offset]=0&page[limit]=9999` + paramString + `&sort=-issuedAt`);
  }

  static getSingle(id: number): Observable<BiltenResponseInterfaceSingle> {
    return HttpFactory.GET(apiRoutes.bilten + `/${id}`);
  }

  static create(body: BiltenResponseInterfaceSingle): Observable<BiltenResponseInterfaceSingle> {
    return HttpFactory.POST(apiRoutes.bilten, body);
  }

  static update(body: BiltenResponseInterfaceSingle): Observable<BiltenResponseInterfaceSingle> {
    return HttpFactory.PATCH(apiRoutes.bilten + `/${body.data.id}`, body);
  }

  static delete(id: number): Observable<any> {
    return HttpFactory.DELETE(apiRoutes.bilten + `/${id}`);
  }

  static elasticSearch(allOpinions: boolean, pageSorted: { from: number, size: number }, match: boolean, keyword?: string, tagIds?: any): Observable<BiltenEsResponseInterface> {
    let attributes: Array<{ field: string, value: string } | { field: string, tags: Array<number> }> = [];
    let keywords: Array<{ field: string, value: string, exactMatch: boolean }> = [];
    //attributes.push({field: "allOpinions", value: allOpinions});
    if (keyword) {
      keywords.push({field: "name", value: keyword, exactMatch: match});
      keywords.push({field: "goalText", value: keyword, exactMatch: match});
      keywords.push({field: "resolvesText", value: keyword, exactMatch: match});
      keywords.push({field: "descriptionFive", value: keyword, exactMatch: match});
      keywords.push({field: "descriptionEight", value: keyword, exactMatch: match});
      keywords.push({field: "hiddenContent", value: keyword, exactMatch: match});
    }
    if (tagIds) {
      attributes = EsAttributesHelper(attributes, tagIds);
    }
    const body = {
      index: "bilten",
      pagedSorted: {
        from: pageSorted.from,
        size: pageSorted.size,
      },
      attributes: attributes,
      globalSearchAttribute: keyword ? { 
        value: keyword,
        exactMatch: match
      } : {}
    }
    return HttpFactory.POST(apiRoutes.elasticSearch, body);
  }
}

export class ProfessionalArticleService {
  static getAll(
    params?: any,
    filter?: any,
    sort?: any,
    pagination?: any
  ): Observable<ProfessionalArticleResponseInterfaceAll> {
    let filterString = '';
    let sortString = '';
    let paginationString = '';
    let paramArray: any[] = [];
    let paramString = '';
    if (params) {
      paramArray = processTags(params);
      if (paramArray?.length) paramString = `?filter={${paramArray?.join(',')}}`;
    }
    if (filter) {
      filterString = paramString ? `&${filter}` : `?${filter}`;
    }
    if (sort) {
      sortString = !paramString && !filterString ? `?${sort}` : `&${sort}`;
    }
    if (pagination) {
      paginationString = !paramString && !filterString && !sortString ? `?${pagination}` : `&${pagination}`;
    }
    return HttpFactory.GET(apiRoutes.professionalArticle + paramString + filterString + sortString + paginationString);
  }

  static getSingle(id: number): Observable<ProfessionalArticleResponseInterfaceSingle> {
    return HttpFactory.GET(apiRoutes.professionalArticle + `/${id}`);
  }

  static create(
    body: ProfessionalArticleResponseInterfaceSingle
  ): Observable<ProfessionalArticleResponseInterfaceSingle> {
    return HttpFactory.POST(apiRoutes.professionalArticle, body);
  }

  static update(
    body: ProfessionalArticleResponseInterfaceSingle
  ): Observable<ProfessionalArticleResponseInterfaceSingle> {
    return HttpFactory.PATCH(apiRoutes.professionalArticle + `/${body.data.id}`, body);
  }

  static delete(id: number): Observable<any> {
    return HttpFactory.DELETE(apiRoutes.professionalArticle + `/${id}`);
  }

  static elasticSearch(allOpinions: boolean, pageSorted: { from: number, size: number }, match: boolean, keyword?: string, tagIds?: any, author?: string): Observable<ProfessionalArticleEsResponseInterface> {
    let attributes: Array<{ field: string, value: string } | { field: string, tags: Array<number> }> = [];
    let keywords: Array<{ field: string, value: string, exactMatch: boolean }> = [];
    //attributes.push({field: "allOpinions", value: allOpinions});
    if (keyword) {
      keywords.push({field: "title", value: keyword, exactMatch: match});
      keywords.push({field: "content", value: keyword, exactMatch: match});
    }
    if (author) {
      attributes.push({ field: "author", value: author });
    }
    if (tagIds) {
      attributes = EsAttributesHelper(attributes, tagIds);
    }
    const body = {
      index: "professional_article",
      pagedSorted: {
        from: pageSorted.from,
        size: pageSorted.size,
      },
      attributes: attributes,
      globalSearchAttribute: keyword ? { 
        value: keyword,
        exactMatch: match
      } : {}
    }
    return HttpFactory.POST(apiRoutes.elasticSearch, body);
  }
}

export class FaqService {
  static getAll(params?: any, filter?: any, sort?: any, pagination?: any): Observable<FaqResponseInterfaceAll> {
    let filterString = '';
    let sortString = '';
    let paginationString = '';
    let paramArray: any[] = [];
    let paramString = '';
    if (params) {
      paramArray = processTags(params);
      if (paramArray?.length) paramString = `?filter={${paramArray?.join(',')}}`;
    }
    if (filter) {
      filterString = paramString ? `&${filter}` : `?${filter}`;
    }
    if (sort) {
      sortString = !paramString && !filterString ? `?${sort}` : `&${sort}`;
    }
    if (pagination) {
      paginationString = !paramString && !filterString && !sortString ? `?${pagination}` : `&${pagination}`;
    }
    return HttpFactory.GET(apiRoutes.faq + paramString + filterString + sortString + paginationString);
  }

  static getSingle(id: number): Observable<FaqResponseInterfaceSingle> {
    return HttpFactory.GET(apiRoutes.faq + `/${id}?include=frequentQuestions`);
  }


  static create(body: FaqResponseInterfaceSingle): Observable<FaqResponseInterfaceSingle> {
    return HttpFactory.POST(apiRoutes.faq, body);
  }

  static update(body: FaqResponseInterfaceSingle): Observable<FaqResponseInterfaceSingle> {
    return HttpFactory.PATCH(apiRoutes.faq + `/${body.data.id}`, body);
  }

  static delete(id: number): Observable<any> {
    return HttpFactory.DELETE(apiRoutes.faq + `/${id}`);
  }

  static getOne(id: number): Observable<FaqSingleInterfaceResponse> {
    return HttpFactory.GET(apiRoutes.faqSingle + `/${id}`);
  }

  static deleteSingle(id: number): Observable<any> {
    return HttpFactory.DELETE(apiRoutes.faqSingle + `/${id}`);
  }

  static createSingle(body: any): Observable<any> {
    return HttpFactory.POST(apiRoutes.faqSingle, body);
  }

  static updateSingle(body: any): Observable<any> {
    return HttpFactory.PATCH(apiRoutes.faqSingle + `/${body.data.id}`, body);
  }

  static elasticSearch(pageSorted: {from: number, size: number}, match: boolean, keyword?: string, tagIds?: any): Observable<FaqEsResponseInterface> {
    let attributes: Array<{ field: string, value: string } | { field: string, tags: Array<number> }> = [];
    let keywords: Array<{ field: string, value: string, exactMatch: boolean }> = [];
    if (keyword) {
      keywords.push({field: "name", value: keyword, exactMatch: match });
      keywords.push({field: "frequentQuestions.title", value: keyword, exactMatch: match });
    }
    if (tagIds) {
      attributes = EsAttributesHelper(attributes, tagIds);
    }
    const body = {
      index: "faq_group",
      pagedSorted: {
        from: pageSorted.from,
        size: pageSorted.size,
      },
      attributes: attributes,
      globalSearchAttribute: keyword ? { 
        value: keyword,
        exactMatch: match
      } : {}
    }
    return HttpFactory.POST(apiRoutes.elasticSearch, body);
  }
}

export class OpinionService {
  static getAll(params?: any, filter?: any, sort?: any, pagination?: any): Observable<OpinionResponseInterfaceAll> {
    let filterString = '';
    let sortString = '';
    let paginationString = '';
    let paramArray: any[] = [];
    let paramString = '';
    if (params) {
      paramArray = processTags(params);
      if (paramArray?.length) paramString = `?filter={${paramArray?.join(',')}}`;
    }
    if (filter) {
      filterString = paramString ? `&${filter}` : `?${filter}`;
    }
    if (sort) {
      sortString = !paramString && !filterString ? `?${sort}` : `&${sort}`;
    }
    if (pagination) {
      paginationString = !paramString && !filterString && !sortString ? `?${pagination}` : `&${pagination}`;
    }
    return HttpFactory.GET(apiRoutes.opinion + paramString + filterString + sortString + paginationString);
  }

  static getSingle(id: number): Observable<OpinionResponseInterfaceSingle> {
    return HttpFactory.GET(apiRoutes.opinion + `/${id}`);
  }

  static create(body: OpinionResponseInterfaceSingle): Observable<OpinionResponseInterfaceSingle> {
    return HttpFactory.POST(apiRoutes.opinion, body);
  }

  static update(body: OpinionResponseInterfaceSingle): Observable<OpinionResponseInterfaceSingle> {
    return HttpFactory.PATCH(apiRoutes.opinion + `/${body.data.id}`, body);
  }

  static delete(id: number): Observable<any> {
    return HttpFactory.DELETE(apiRoutes.opinion + `/${id}`);
  }

  static elasticSearch(allOpinions: boolean, pageSorted: { from: number, size: number }, match: boolean, keyword?: string, tagIds?: any, sort?: SorterInterface): Observable<OpinionEsResponseInterface> {
    let attributes: Array<{ field: string, value: any } | { field: string, tags: Array<number> }> = [];
    let keywords: Array<{ field: string, value: string, exactMatch: boolean }> = [];
    attributes.push({ field: "allOpinions", value: allOpinions });
    if (keyword) {
      keywords.push({ field: "title", value: keyword, exactMatch: match });
      keywords.push({ field: "content", value: keyword, exactMatch: match });
      keywords.push({ field: "summary", value: keyword, exactMatch: match });
    }
    if (tagIds) {
      attributes = EsAttributesHelper(attributes, tagIds);
    }
    const body = {
      index: "opinion",
      pagedSorted: {
        from: pageSorted.from,
        size: pageSorted.size,
      },
      sortBy: sort ? sort : undefined,
      attributes: attributes,
      globalSearchAttribute: keyword ? { 
        value: keyword,
        exactMatch: match
      } : {}
    }
    return HttpFactory.POST(apiRoutes.elasticSearch, body);
  }
}

export class RoadmapService {
  static getAll(params?: any): Observable<RoadmapResponseInterfaceAll> {
    let paramArray: any[] = [];
    let paramString = '';
    if (params) {
      paramArray = processTags(params);
      if (paramArray?.length) paramString = `?filter={${paramArray?.join(',')}}`;
    }
    return HttpFactory.GET(apiRoutes.roadmap + '?sort=position' + `&page[offset]=0&page[limit]=9999` + paramString);
  }

  static getSingle(id: number): Observable<RoadmapResponseInterfaceSingle> {
    return HttpFactory.GET(apiRoutes.roadmap + `/${id}`);
  }

  static create(body: RoadmapResponseInterfaceSingle): Observable<RoadmapResponseInterfaceSingle> {
    return HttpFactory.POST(apiRoutes.roadmap, body);
  }

  static update(body: RoadmapResponseInterfaceSingle): Observable<RoadmapResponseInterfaceSingle> {
    return HttpFactory.PATCH(apiRoutes.roadmap + `/${body.data.id}`, body);
  }

  static delete(id: number): Observable<any> {
    return HttpFactory.DELETE(apiRoutes.roadmap + `/${id}`);
  }

  static elasticSearch(allOpinions: boolean, pageSorted: { from: number, size: number }, match: boolean, keyword?: string, tagIds?: any): Observable<RoadmapEsResponseInterface> {
    let attributes: Array<{ field: string, value: string } | { field: string, tags: Array<number> }> = [];
    let keywords: Array<{ field: string, value: string, exactMatch: boolean }> = [];
    //attributes.push({field: "allOpinions", value: allOpinions});
    if (keyword) {
      keywords.push({ field: "name", value: keyword, exactMatch: match });
      keywords.push({ field: "description", value: keyword, exactMatch: match });
    }
    if (tagIds) {
      attributes = EsAttributesHelper(attributes, tagIds);
    }
    const body = {
      index: "roadmap",
      pagedSorted: {
        from: pageSorted.from,
        size: pageSorted.size,
      },
      attributes: attributes,
      globalSearchAttribute: keyword ? { 
        value: keyword,
        exactMatch: match
      } : {}
    }
    return HttpFactory.POST(apiRoutes.elasticSearch, body);
  }
}

export class RegulationService {
  static getAll(params?: any, filter?: any, sort?: any, pagination?: any): Observable<RegulationResponseInterfaceAll> {
    let filterString = '';
    let sortString = '';
    let paginationString = '';
    let paramArray: any[] = [];
    let paramString = '';
    if (params) {
      paramArray = processTags(params);
      if (paramArray?.length) paramString = `?filter={${paramArray?.join(',')}}`;
    }
    if (filter) {
      filterString = paramString ? `&${filter}` : `?${filter}`;
    }
    if (sort) {
      sortString = !paramString && !filterString ? `?${sort}` : `&${sort}`;
    }
    if (pagination) {
      paginationString = !paramString && !filterString && !sortString ? `?${pagination}` : `&${pagination}`;
    }
    return HttpFactory.GET(apiRoutes.regulation + paramString + filterString + sortString + paginationString);
  }

  static getSingle(id: number): Observable<RegulationResponseInterfaceSingle> {
    return HttpFactory.GET(apiRoutes.regulation + `/${id}`);
  }

  static create(body: RegulationResponseInterfaceSingle): Observable<RegulationResponseInterfaceSingle> {
    return HttpFactory.POST(apiRoutes.regulation, body);
  }

  static update(body: RegulationResponseInterfaceSingle): Observable<RegulationResponseInterfaceSingle> {
    return HttpFactory.PATCH(apiRoutes.regulation + `/${body.data.id}`, body);
  }

  static delete(id: number): Observable<any> {
    return HttpFactory.DELETE(apiRoutes.regulation + `/${id}`);
  }

  static elasticSearch(allOpinions: boolean, pageSorted: { from: number, size: number }, match: boolean, keyword?: string, tagIds?: any): Observable<RegulationEsResponseInterface> {
    let attributes: Array<{ field: string, value: string } | { field: string, tags: Array<number> }> = [];
    let keywords: Array<{ field: string, value: string, exactMatch: boolean }> = [];
    //attributes.push({field: "allOpinions", value: allOpinions});
    if (keyword) {
      keywords.push({ field: "title", value: keyword, exactMatch: match });
    }
    if (tagIds) {
      attributes = EsAttributesHelper(attributes, tagIds);
    }
    const body = {
      index: "regulation",
      pagedSorted: {
        from: pageSorted.from,
        size: pageSorted.size,
      },
      attributes: attributes,
      globalSearchAttribute: keyword ? { 
        value: keyword,
        exactMatch: match
      } : {}
    }
    return HttpFactory.POST(apiRoutes.elasticSearch, body);
  }
}

export class NoteService {
  static getAll(userId?: number, showLoader?: boolean): Observable<NoteResponseInterfaceAll> {
    let userParam = '';
    if (userId) userParam = `&filter[user.id]=${userId}`;
    return HttpFactory.GET(apiRoutes.notes + `?page[offset]=0&page[limit]=9999&sort=-id` + userParam, undefined, showLoader);
  }

  static getSingle(id: number, showLoader?: boolean): Observable<NoteResponseInterfaceSingle> {
    return HttpFactory.GET(apiRoutes.notes + `/${id}`, undefined, showLoader);
  }

  static create(body: NoteResponseInterfaceSingle): Observable<NoteResponseInterfaceSingle> {
    return HttpFactory.POST(apiRoutes.notes, body);
  }

  static update(body: NoteResponseInterfaceSingle, id?: number): Observable<NoteResponseInterfaceSingle> {
    return HttpFactory.PATCH(apiRoutes.notes + `/${body.data.id ? body.data.id : id}`, body);
  }

  static delete(id: number): Observable<any> {
    return HttpFactory.DELETE(apiRoutes.notes + `/${id}`);
  }
}

export class NoteLinkService {
  static getAll(noteId?: number): Observable<NoteLinkResponseInterfaceAll> {
    let userParam = '';
    if (noteId) userParam = `&filter[notes.id]=${noteId}`;
    return HttpFactory.GET(apiRoutes.notesLinks + `?page[offset]=0&page[limit]=9999&sort=-id` + userParam);
  }

  static getSingle(id: number): Observable<NoteLinkResponseInterfaceSingle> {
    return HttpFactory.GET(apiRoutes.notesLinks + `/${id}`);
  }

  static create(body: NoteLinkResponseInterfaceSingle): Observable<NoteLinkResponseInterfaceSingle> {
    return HttpFactory.POST(apiRoutes.notesLinks, body);
  }

  static update(body: NoteLinkResponseInterfaceSingle, id?: number): Observable<NoteLinkResponseInterfaceSingle> {
    return HttpFactory.PATCH(apiRoutes.notesLinks + `/${body.data.id ? body.data.id : id}`, body);
  }

  static delete(id: number): Observable<any> {
    return HttpFactory.DELETE(apiRoutes.notesLinks + `/${id}`);
  }
}

export class DecisionService {
  static getAll(params?: any, filter?: any, sort?: any, pagination?: any): Observable<DecisionResponseInterfaceAll> {
    let filterString = '';
    let sortString = '';
    let paginationString = '';
    let paramArray: any[] = [];
    let paramString = '';
    if (params) {
      paramArray = processTags(params);
      if (paramArray?.length) paramString = `?filter={${paramArray?.join(',')}}`;
    }
    if (filter) {
      filterString = paramString ? `&${filter}` : `?${filter}`;
    }
    if (sort) {
      sortString = !paramString && !filterString ? `?${sort}` : `&${sort}`;
    }
    if (pagination) {
      paginationString = !paramString && !filterString && !sortString ? `?${pagination}` : `&${pagination}`;
    }
    return HttpFactory.GET(apiRoutes.decision + paramString + filterString + sortString + paginationString);
  }

  static getSingle(id: number): Observable<DecisionResponseInterfaceSingle> {
    return HttpFactory.GET(apiRoutes.decision + `/${id}`);
  }

  static create(body: DecisionResponseInterfaceSingle): Observable<DecisionResponseInterfaceSingle> {
    return HttpFactory.POST(apiRoutes.decision, body);
  }

  static update(body: DecisionResponseInterfaceSingle): Observable<DecisionResponseInterfaceSingle> {
    return HttpFactory.PATCH(apiRoutes.decision + `/${body.data.id}`, body);
  }

  static activation(body: any): Observable<DecisionResponseInterfaceSingle> {
    return HttpFactory.PATCH(apiRoutes.decision + `/${body.data.id}`, body);
  }

  static delete(id: number): Observable<any> {
    return HttpFactory.DELETE(apiRoutes.decision + `/${id}`);
  }

  static elasticSearch(allOpinions: boolean, pageSorted: { from: number, size: number }, match: boolean, keyword?: string, search?: Array<QueryFilter>, tagIds?: any, sort?: SorterInterface, supportedYears?: Array<{id: number, name: string}>): Observable<DecisionEsResponseInterface> {
    let attributes: Array<{ field: string, value: string, exactMatch?: boolean } | { field: string, tags: Array<number> }> = [];
    let keywords: Array<{ field: string, value: string, exactMatch: boolean }> = [];
    let andOrFilters: Array<number[]> = [];
    //attributes.push({field: "allOpinions", value: allOpinions});

    if (keyword) {
      keywords.push({ field: "subject", value: keyword, exactMatch: match });
      keywords.push({ field: "appellant", value: keyword, exactMatch: match });
      keywords.push({ field: "clazz", value: keyword, exactMatch: match });
      keywords.push({ field: "client", value: keyword, exactMatch: match });
    }

    if (tagIds) {
      andOrFilters = EsAttributesHelperAndOr(tagIds);
    }

    if (search) {
      for (let q of search) {
        if (q.name == 'class') q.name = 'clazz';
        //if (q.name.toLowerCase().includes('date')) q.value = moment(q.value).format(JavaFormat);
        if (q.name === 'type') {
          attributes.push({ field: q.value.length > 1 ? 'orTagIds' : 'tagIds', tags: q.value as number[] });
        } else {
          attributes.push({ field: q.name, value: q.value as string, exactMatch: q.exactMatch === undefined ? true : q.exactMatch });
        }
      }
    }

    const body = {
      index: "decision",
      pagedSorted: {
        from: pageSorted.from,
        size: pageSorted.size,
      },
      sortBy: sort ? sort : undefined,
      attributes: attributes,
      globalSearchAttribute: keyword ? { 
        value: keyword,
        exactMatch: match
      } : {},
      supportedYears: andOrFilters.length || keyword ? supportedYears : undefined,
      //AND_OR_FILTERI_TABLICE
      andOrFilters: andOrFilters
    }
    return HttpFactory.POST(apiRoutes.elasticSearch, body);
  }
}

export class AllegationService {
  static getAll(params?: any, filter?: any, sort?: any, pagination?: any): Observable<AllegationResponseInterfaceAll> {
    let filterString = '';
    let sortString = '';
    let paginationString = '';
    let paramArray: any[] = [];
    let paramString = '';
    if (params) {
      paramArray = processTags(params);
      if (paramArray?.length) paramString = `?filter={${paramArray?.join(',')}}`;
    }
    if (filter) {
      filterString = paramString ? `&${filter}` : `?${filter}`;
    }
    if (sort) {
      sortString = !paramString && !filterString ? `?${sort}` : `&${sort}`;
    }
    if (pagination) {
      paginationString = !paramString && !filterString && !sortString ? `?${pagination}` : `&${pagination}`;
    }
    return HttpFactory.GET(apiRoutes.allegation + paramString + filterString + sortString + paginationString);
  }

  static getSingle(id: number): Observable<AllegationResponseInterfaceSingle> {
    return HttpFactory.GET(apiRoutes.allegation + `/${id}`);
  }

  static create(body: AllegationResponseInterfaceSingle): Observable<AllegationResponseInterfaceSingle> {
    return HttpFactory.POST(apiRoutes.allegation, body);
  }

  static update(body: AllegationResponseInterfaceSingle): Observable<AllegationResponseInterfaceSingle> {
    return HttpFactory.PATCH(apiRoutes.allegation + `/${body.data.id}`, body);
  }

  static delete(id: number): Observable<any> {
    return HttpFactory.DELETE(apiRoutes.allegation + `/${id}`);
  }

  static createSingle(body: any): Observable<any> {
    return HttpFactory.POST(apiRoutes.allegation, body);
  }

  static updateSingle(body: any): Observable<any> {
    return HttpFactory.PATCH(apiRoutes.allegation + `/${body.data.id}`, body);
  }

  static elasticSearch(allOpinions: boolean, pageSorted: { from: number, size: number }, match: boolean, keyword?: string, tagIds?: any, supportedYears?: Array<{id: number, name: string}>): Observable<AllegationEsResponseInterface> {
    let attributes: Array<{ field: string, value: string } | { field: string, tags: Array<number> }> = [];
    let keywords: Array<{ field: string, value: string, exactMatch: boolean }> = [];
    let andOrFilters: Array<number[]> = [];
    //attributes.push({field: "allOpinions", value: allOpinions});
    if (keyword) {
      keywords.push({field: "textContent", value: keyword, exactMatch: match});
      keywords.push({field: "titleContent", value: keyword, exactMatch: match});
      keywords.push({field: "dkomText", value: keyword, exactMatch: match});
      keywords.push({field: "dkomAssessment", value: keyword, exactMatch: match});
      keywords.push({field: "clientResponse", value: keyword, exactMatch: match});
      keywords.push({field: "comment", value: keyword, exactMatch: match});
      keywords.push({field: "lawArticle", value: keyword, exactMatch: match});

      //keywords.push({ field: "decision.subject", value: keyword });
      //keywords.push({ field: "decision.appellant", value: keyword });
      //keywords.push({ field: "decision.clazz", value: keyword });
      //keywords.push({ field: "decision.client", value: keyword });
    }
    if (tagIds) {
      //attributes = EsAttributesHelper(attributes, tagIds);
      andOrFilters = EsAttributesHelperAndOr(tagIds);
    }
    const body = {
      index: "decision_appeal_allegation",
      pagedSorted: {
        from: pageSorted.from,
        size: pageSorted.size,
      },
      attributes: attributes,
      supportedYears: supportedYears,
      globalSearchAttribute: keyword ? { 
        value: keyword,
        exactMatch: match
      } : {},
      //AND_OR_FILTERI
      andOrFilters: andOrFilters
    }
    return HttpFactory.POST(apiRoutes.elasticSearch, body);
  }
}

export class AppealService {
  static getAll(params?: any, filter?: any, sort?: any, pagination?: any): Observable<AppealResponseInterfaceAll> {
    let filterString = '';
    let sortString = '';
    let paginationString = '';
    let paramArray: any[] = [];
    let paramString = '';
    if (params) {
      paramArray = processTags(params);
      if (paramArray?.length) paramString = `?filter={${paramArray?.join(',')}}`;
    }
    if (filter) {
      filterString = paramString ? `&${filter}` : `?${filter}`;
    }
    if (sort) {
      sortString = !paramString && !filterString ? `?${sort}` : `&${sort}`;
    }
    if (pagination) {
      paginationString = !paramString && !filterString && !sortString ? `?${pagination}` : `&${pagination}`;
    }
    return HttpFactory.GET(apiRoutes.appeal + paramString + filterString + sortString + paginationString);
  }

  static getSingle(id: number): Observable<AppealResponseInterfaceSingle> {
    return HttpFactory.GET(apiRoutes.appeal + `/${id}`);
  }

  static create(body: AppealResponseInterfaceSingle): Observable<AppealResponseInterfaceSingle> {
    return HttpFactory.POST(apiRoutes.appeal, body);
  }

  static update(body: AppealResponseInterfaceSingle): Observable<AppealResponseInterfaceSingle> {
    return HttpFactory.PATCH(apiRoutes.appeal + `/${body.data.id}`, body);
  }

  static delete(id: number): Observable<any> {
    return HttpFactory.DELETE(apiRoutes.appeal + `/${id}`);
  }

  static createSingle(body: any): Observable<any> {
    return HttpFactory.POST(apiRoutes.appeal, body);
  }

  static updateSingle(body: any): Observable<any> {
    return HttpFactory.PATCH(apiRoutes.appeal + `/${body.data.id}`, body);
  }

  static elasticSearch(allOpinions: boolean, pageSorted: { from: number, size: number }, match: boolean, keyword?: string, tagIds?: any, supportedYears?: Array<{id: number, name: string}>): Observable<AppealEsResponseInterface> {
    let attributes: Array<{ field: string, value: string } | { field: string, tags: Array<number> }> = [];
    let keywords: Array<{ field: string, value: string, exactMatch: boolean }> = [];
    let andOrFilters: Array<number[]> = [];
    //attributes.push({field: "allOpinions", value: allOpinions});
    if (keyword) {
      keywords.push({field: "textContent", value: keyword, exactMatch: match});
      keywords.push({field: "titleContent", value: keyword, exactMatch: match});
      keywords.push({field: "dkomText", value: keyword, exactMatch: match});
      keywords.push({field: "dkomAssessment", value: keyword, exactMatch: match});
      keywords.push({field: "appealAllegation", value: keyword, exactMatch: match});
      keywords.push({field: "lawArticle", value: keyword, exactMatch: match});
    }
    if (tagIds) {
      //attributes = EsAttributesHelper(attributes, tagIds);
      andOrFilters = EsAttributesHelperAndOr(tagIds);
    }
    const body = {
      index: "decision_appeal_response",
      pagedSorted: {
        from: pageSorted.from,
        size: pageSorted.size,
      },
      attributes: attributes,
      supportedYears: supportedYears,
      globalSearchAttribute: keyword ? { 
        value: keyword,
        exactMatch: match
      } : {},
      //AND_OR_FILTERI
      andOrFilters: andOrFilters
    }
    return HttpFactory.POST(apiRoutes.elasticSearch, body);
  }
}

export class SynopsisService {
  static getAll(params?: any, filter?: any, sort?: any, pagination?: any): Observable<SynopsisResponseInterfaceAll> {
    let filterString = '';
    let sortString = '';
    let paginationString = '';
    let paramArray: any[] = [];
    let paramString = '';
    if (params) {
      paramArray = processTags(params);
      if (paramArray?.length) paramString = `?filter={${paramArray?.join(',')}}`;
    }
    if (filter) {
      filterString = paramString ? `&${filter}` : `?${filter}`;
    }
    if (sort) {
      sortString = !paramString && !filterString ? `?${sort}` : `&${sort}`;
    }
    if (pagination) {
      paginationString = !paramString && !filterString && !sortString ? `?${pagination}` : `&${pagination}`;
    }
    return HttpFactory.GET(apiRoutes.synopsis + paramString + filterString + sortString + paginationString);
  }

  static getSingle(id: number): Observable<SynopsisResponseInterfaceSingle> {
    return HttpFactory.GET(apiRoutes.synopsis + `/${id}`);
  }

  static create(body: SynopsisResponseInterfaceSingle): Observable<SynopsisResponseInterfaceSingle> {
    return HttpFactory.POST(apiRoutes.synopsis, body);
  }

  static update(body: SynopsisResponseInterfaceSingle): Observable<SynopsisResponseInterfaceSingle> {
    return HttpFactory.PATCH(apiRoutes.synopsis + `/${body.data.id}`, body);
  }

  static delete(id: number): Observable<any> {
    return HttpFactory.DELETE(apiRoutes.synopsis + `/${id}`);
  }

  static createSingle(body: any): Observable<any> {
    return HttpFactory.POST(apiRoutes.synopsis, body);
  }

  static updateSingle(body: any): Observable<any> {
    return HttpFactory.PATCH(apiRoutes.synopsis + `/${body.data.id}`, body);
  }

  static elasticSearch(allOpinions: boolean, pageSorted: { from: number, size: number }, match: boolean, keyword?: string, tagIds?: any, supportedYears?: Array<{id: number, name: string}>): Observable<SynopsisEsResponseInterface> {
    let attributes: Array<{ field: string, value: string } | { field: string, tags: Array<number> }> = [];
    let keywords: Array<{ field: string, value: string, exactMatch: boolean }> = [];
    let andOrFilters: Array<number[]> = [];
    //attributes.push({field: "allOpinions", value: allOpinions});
    if (keyword) {
      keywords.push({field: "textContent", value: keyword, exactMatch: match});
      keywords.push({field: "titleContent", value: keyword, exactMatch: match});
    }
    if (tagIds) {
      //attributes = EsAttributesHelper(attributes, tagIds);
      andOrFilters = EsAttributesHelperAndOr(tagIds);
    }
    const body = {
      index: "verdict_summary",
      pagedSorted: {
        from: pageSorted.from,
        size: pageSorted.size,
      },
      attributes: attributes,
      supportedYears: supportedYears,
      globalSearchAttribute: keyword ? { 
        value: keyword,
        exactMatch: match
      } : {},
      //AND_OR_FILTERI
      andOrFilters: andOrFilters
    }
    return HttpFactory.POST(apiRoutes.elasticSearch, body);
  }
}

export class VerdictService {
  static getAll(params?: any, filter?: any, sort?: any, pagination?: any): Observable<VerdictResponseInterfaceAll> {
    let filterString = '';
    let sortString = '';
    let paginationString = '';
    let paramArray: any[] = [];
    let paramString = '';
    let includeString = '';
    if (params) {
      paramArray = processTags(params);
      if (paramArray?.length) paramString = `?filter={${paramArray?.join(',')}}`;
    }
    if (filter) {
      filterString = paramString ? `&${filter}` : `?${filter}`;
    }
    if (sort) {
      sortString = !paramString && !filterString ? `?${sort}` : `&${sort}`;
    }
    if (pagination) {
      paginationString = !paramString && !filterString && !sortString ? `?${pagination}` : `&${pagination}`;
    }
    includeString = !paramString && !filterString && !sortString ? `?include=decision` : `&include=decision`;
    return HttpFactory.GET(
      apiRoutes.verdict + paramString + filterString + sortString + paginationString + includeString
    );
  }

  static getSingle(id: number): Observable<VerdictResponseInterfaceSingle> {
    return HttpFactory.GET(apiRoutes.verdict + `/${id}?include=decision`);
  }

  static create(body: VerdictResponseInterfaceSingle): Observable<VerdictResponseInterfaceSingle> {
    return HttpFactory.POST(apiRoutes.verdict, body);
  }

  static update(body: VerdictResponseInterfaceSingle): Observable<VerdictResponseInterfaceSingle> {
    return HttpFactory.PATCH(apiRoutes.verdict + `/${body.data.id}`, body);
  }

  static activation(body: any): Observable<DecisionResponseInterfaceSingle> {
    return HttpFactory.PATCH(apiRoutes.verdict + `/${body.data.id}`, body);
  }

  static delete(id: number): Observable<any> {
    return HttpFactory.DELETE(apiRoutes.verdict + `/${id}`);
  }

  static elasticSearch(allOpinions: boolean, pageSorted: { from: number, size: number }, match: boolean, keyword?: string, search?: Array<QueryFilter>, tagIds?: any, sort?: SorterInterface, supportedYears?: Array<{id: number, name: string}>): Observable<VerdictEsResponseInterface> {
    let attributes: Array<{ field: string, value: string, exactMatch?: boolean, nested?: boolean } | { field: string, tags: Array<number> }> = [];
    let keywords: Array<{ field: string, value: string, exactMatch: boolean, nested?: boolean }> = [];
    let andOrFilters: Array<number[]> = [];

    if (keyword) {
      //VUS
      keywords.push({field: "verdictNum", value: keyword, exactMatch: match});
      keywords.push({field: "plaintiff", value: keyword, exactMatch: match});
      //DKOM
      keywords.push({ field: "decision.subject", value: keyword, exactMatch: match, nested: true });
      keywords.push({ field: "decision.appellant", value: keyword, exactMatch: match, nested: true });
      keywords.push({ field: "decision.clazz", value: keyword, exactMatch: match, nested: true });
      keywords.push({ field: "decision.client", value: keyword, exactMatch: match, nested: true });
    }

    if (tagIds) {
      andOrFilters = EsAttributesHelperAndOr(tagIds);
    }

    if (search) {
      for (let q of search) {
        if (q.name == 'decision') q.name = 'clazz';
        //if (q.name.toLowerCase().includes('date')) q.value = moment(q.value).format(JavaFormat);
        if (q.name === 'type') {
          attributes.push({ field: q.value.length > 1 ? 'orTagIds' : 'tagIds', tags: q.value as number[] });
        } else {
          attributes.push({ field: q.name, value: q.value as string, exactMatch: q.exactMatch === undefined ? true : q.exactMatch });
        }
      }
    }

    const body = {
      index: "verdict",
      pagedSorted: {
        from: pageSorted.from,
        size: pageSorted.size,
      },
      sortBy: sort ? sort : undefined,
      attributes: attributes,
      globalSearchAttribute: keyword ? { 
        value: keyword,
        exactMatch: match,
        nested: true
      } : {},
      supportedYears: andOrFilters.length || keyword ? supportedYears : undefined,
      //AND_OR_FILTERI_TABLICE
      andOrFilters: andOrFilters
    }
    return HttpFactory.POST(apiRoutes.elasticSearch, body);
  }
}

export class OrderService {
  static getAll(params?: any, filter?: any, sort?: any, pagination?: any): Observable<OrderResponseInterfaceAll> {
    let filterString = '';
    let sortString = '';
    let paginationString = '';
    let paramArray: any[] = [];
    let paramString = '';
    if (params) {
      paramArray = processTags(params);
      if (paramArray?.length) paramString = `?filter={${paramArray?.join(',')}}`;
    }
    if (filter) {
      filterString = paramString ? `&${filter}` : `?${filter}`;
    }
    if (sort) {
      sortString = !paramString && !filterString ? `?${sort}` : `&${sort}`;
    }
    if (pagination) {
      paginationString = !paramString && !filterString && !sortString ? `?${pagination}` : `&${pagination}`;
    }
    return HttpFactory.GET(apiRoutes.order + paramString + filterString + sortString + paginationString);
  }

  static getSingle(id: number): Observable<OrderResponseInterfaceSingle> {
    return HttpFactory.GET(apiRoutes.order + `/${id}`);
  }

  static create(body: OrderResponseInterfaceSingle): Observable<OrderResponseInterfaceSingle> {
    return HttpFactory.POST(apiRoutes.order, body);
  }

  static update(body: OrderResponseInterfaceSingle): Observable<OrderResponseInterfaceSingle> {
    return HttpFactory.PATCH(apiRoutes.order + `/${body.data.id}`, body);
  }

  static delete(id: number): Observable<any> {
    return HttpFactory.DELETE(apiRoutes.order + `/${id}`);
  }

  static createSingle(body: any): Observable<any> {
    return HttpFactory.POST(apiRoutes.order, body);
  }

  static updateSingle(body: any): Observable<any> {
    return HttpFactory.PATCH(apiRoutes.order + `/${body.data.id}`, body);
  }

  static elasticSearch(allOpinions: boolean, pageSorted: { from: number, size: number }, match: boolean, keyword?: string, tagIds?: any, supportedYears?: Array<{id: number, name: string}>): Observable<OrderEsResponseInterface> {
    let attributes: Array<{ field: string, value: string } | { field: string, tags: Array<number> }> = [];
    let keywords: Array<{ field: string, value: string, exactMatch: boolean }> = [];
    let andOrFilters: Array<number[]> = [];
    //attributes.push({field: "allOpinions", value: allOpinions});
    if (keyword) {
      keywords.push({field: "textContent", value: keyword, exactMatch: match});
      keywords.push({field: "titleContent", value: keyword, exactMatch: match});
      keywords.push({field: "vusText", value: keyword, exactMatch: match});
      keywords.push({field: "vusAssessment", value: keyword, exactMatch: match});
      keywords.push({field: "comment", value: keyword, exactMatch: match});
      keywords.push({field: "zjnArticleEtc", value: keyword, exactMatch: match});
      keywords.push({field: "thirdPartyResponse", value: keyword, exactMatch: match});
    }
    if (tagIds) {
      //attributes = EsAttributesHelper(attributes, tagIds);
      andOrFilters = EsAttributesHelperAndOr(tagIds);
    }
    const body = {
      index: "verdict_prosecution_order",
      pagedSorted: {
        from: pageSorted.from,
        size: pageSorted.size,
      },
      attributes: attributes,
      supportedYears: supportedYears,
      globalSearchAttribute: keyword ? { 
        value: keyword,
        exactMatch: match
      } : {},
      //AND_OR_FILTERI
      andOrFilters: andOrFilters
    }
    return HttpFactory.POST(apiRoutes.elasticSearch, body);
  }
}

export class ResponseService {
  static getAll(params?: any, filter?: any, sort?: any, pagination?: any): Observable<ResponseResponseInterfaceAll> {
    let filterString = '';
    let sortString = '';
    let paginationString = '';
    let paramArray: any[] = [];
    let paramString = '';
    if (params) {
      paramArray = processTags(params);
      if (paramArray?.length) paramString = `?filter={${paramArray?.join(',')}}`;
    }
    if (filter) {
      filterString = paramString ? `&${filter}` : `?${filter}`;
    }
    if (sort) {
      sortString = !paramString && !filterString ? `?${sort}` : `&${sort}`;
    }
    if (pagination) {
      paginationString = !paramString && !filterString && !sortString ? `?${pagination}` : `&${pagination}`;
    }
    return HttpFactory.GET(apiRoutes.response + paramString + filterString + sortString + paginationString);
  }

  static getSingle(id: number): Observable<ResponseResponseInterfaceSingle> {
    return HttpFactory.GET(apiRoutes.response + `/${id}`);
  }

  static create(body: ResponseResponseInterfaceSingle): Observable<ResponseResponseInterfaceSingle> {
    return HttpFactory.POST(apiRoutes.response, body);
  }

  static update(body: ResponseResponseInterfaceSingle): Observable<ResponseResponseInterfaceSingle> {
    return HttpFactory.PATCH(apiRoutes.response + `/${body.data.id}`, body);
  }

  static delete(id: number): Observable<any> {
    return HttpFactory.DELETE(apiRoutes.response + `/${id}`);
  }

  static createSingle(body: any): Observable<any> {
    return HttpFactory.POST(apiRoutes.response, body);
  }

  static updateSingle(body: any): Observable<any> {
    return HttpFactory.PATCH(apiRoutes.response + `/${body.data.id}`, body);
  }

  static elasticSearch(allOpinions: boolean, pageSorted: { from: number, size: number }, match: boolean, keyword?: string, tagIds?: any, supportedYears?: Array<{id: number, name: string}>): Observable<ResponseEsResponseInterface> {
    let attributes: Array<{ field: string, value: string } | { field: string, tags: Array<number> }> = [];
    let keywords: Array<{ field: string, value: string, exactMatch: boolean }> = [];
    let andOrFilters: Array<number[]> = [];
    //attributes.push({field: "allOpinions", value: allOpinions});
    if (keyword) {
      keywords.push({field: "textContent", value: keyword, exactMatch: match});
      keywords.push({field: "titleContent", value: keyword, exactMatch: match});
      //keywords.push({field: "verdictAppealResponse", value: keyword});
      keywords.push({field: "vusText", value: keyword, exactMatch: match});
      keywords.push({field: "vusAssessment", value: keyword, exactMatch: match});
      keywords.push({field: "comment", value: keyword, exactMatch: match});
      keywords.push({field: "zjnArticleEtc", value: keyword, exactMatch: match});
      keywords.push({field: "thirdPartyResponse", value: keyword, exactMatch: match});
    }
    if (tagIds) {
      //attributes = EsAttributesHelper(attributes, tagIds);
      andOrFilters = EsAttributesHelperAndOr(tagIds);
    }
    const body = {
      index: "verdict_response",
      pagedSorted: {
        from: pageSorted.from,
        size: pageSorted.size,
      },
      attributes: attributes,
      supportedYears: supportedYears,
      globalSearchAttribute: keyword ? { 
        value: keyword,
        exactMatch: match
      } : {},
      //AND_OR_FILTERI
      andOrFilters: andOrFilters
    }
    return HttpFactory.POST(apiRoutes.elasticSearch, body);
  }
}

export class SummaryService {
  static getAll(params?: any, filter?: any, sort?: any, pagination?: any): Observable<SummaryResponseInterfaceAll> {
    let filterString = '';
    let sortString = '';
    let paginationString = '';
    let paramArray: any[] = [];
    let paramString = '';
    if (params) {
      paramArray = processTags(params);
      if (paramArray?.length) paramString = `?filter={${paramArray?.join(',')}}`;
    }
    if (filter) {
      filterString = paramString ? `&${filter}` : `?${filter}`;
    }
    if (sort) {
      sortString = !paramString && !filterString ? `?${sort}` : `&${sort}`;
    }
    if (pagination) {
      paginationString = !paramString && !filterString && !sortString ? `?${pagination}` : `&${pagination}`;
    }
    return HttpFactory.GET(apiRoutes.summary + paramString + filterString + sortString + paginationString);
  }

  static getSingle(id: number): Observable<SummaryResponseInterfaceSingle> {
    return HttpFactory.GET(apiRoutes.summary + `/${id}`);
  }

  static create(body: SummaryResponseInterfaceSingle): Observable<SummaryResponseInterfaceSingle> {
    return HttpFactory.POST(apiRoutes.summary, body);
  }

  static update(body: SummaryResponseInterfaceSingle): Observable<SummaryResponseInterfaceSingle> {
    return HttpFactory.PATCH(apiRoutes.summary + `/${body.data.id}`, body);
  }

  static delete(id: number): Observable<any> {
    return HttpFactory.DELETE(apiRoutes.summary + `/${id}`);
  }

  static createSingle(body: any): Observable<any> {
    return HttpFactory.POST(apiRoutes.summary, body);
  }

  static updateSingle(body: any): Observable<any> {
    return HttpFactory.PATCH(apiRoutes.summary + `/${body.data.id}`, body);
  }

  static elasticSearch(allOpinions: boolean, pageSorted: { from: number, size: number }, match: boolean, keyword?: string, tagIds?: any, supportedYears?: Array<{id: number, name: string}>): Observable<SummaryEsResponseInterface> {
    let attributes: Array<{ field: string, value: string } | { field: string, tags: Array<number> }> = [];
    let keywords: Array<{ field: string, value: string, exactMatch: boolean }> = [];
    //attributes.push({field: "allOpinions", value: allOpinions});
    let andOrFilters: Array<number[]> = [];
    if (keyword) {
      keywords.push({field: "textContent", value: keyword, exactMatch: match});
      keywords.push({field: "titleContent", value: keyword, exactMatch: match});
    }
    if (tagIds) {
      //attributes = EsAttributesHelper(attributes, tagIds);
      andOrFilters = EsAttributesHelperAndOr(tagIds);
    }
    const body = {
      index: "decision_summary",
      pagedSorted: {
        from: pageSorted.from,
        size: pageSorted.size,
      },
      attributes: attributes,
      supportedYears: supportedYears,
      globalSearchAttribute: keyword ? { 
        value: keyword,
        exactMatch: match
      } : {},
      //AND_OR_FILTERI
      andOrFilters: andOrFilters
    }
    return HttpFactory.POST(apiRoutes.elasticSearch, body);
  }
}

export class TagScreenService {
  static getScreenTagsByType(type: string): Observable<Array<TagScreenResponseInterface>> {
    return HttpFactory.GET(apiRoutes.tagScreen + `?type=${type}`);
  }
}

export class FileService {
  static temp(uuid: string): Observable<any> {
    return HttpFactory.GET(apiRoutes.dms + `/${uuid}/temp-url`);
  }

  static tempAdmin(uuid: string): Observable<any> {
    return HttpFactory.GET(apiRoutes.dmsAdmin + `/${uuid}/temp-url`);
  }

  static action(url: string): Observable<any> {
    return HttpFactory.GET(`${url}`, undefined, true, true);
  }

  static list(uuid: string): Observable<any> {
    return HttpFactory.GET(apiRoutes.dms + `/${uuid}/list`);
  }
}

export class ExternalService {
  static getNews(): Observable<NewsInterface[]> {
    return HttpFactory.GET(apiRoutes.news, undefined, false, true);
  }

  static getEducations(): Observable<EducationInterface[]> {
    return HttpFactory.GET(apiRoutes.educations, undefined, false, true);
  }
}

export class BjnService {
  static sendUserInfo(body: any): Observable<any> {
    return HttpFactory.POST(apiRoutes.bjn, body, undefined, true);
  }
}

export class OrganizationService {
  static getAll(
    params?: any,
    filter?: any,
    sort?: any,
    pagination?: any
  ): Observable<FullOrganizationResponseInterfaceAll> {
    let filterString = '';
    let sortString = '';
    let paginationString = '';
    let paramArray: any[] = [];
    let paramString = '';
    if (params) {
      paramArray = processTags(params);
      if (paramArray?.length) paramString = `?filter={${paramArray?.join(',')}}`;
    }
    if (filter) {
      filterString = paramString ? `&${filter}` : `?${filter}`;
    }
    if (sort) {
      sortString = !paramString && !filterString ? `?${sort}` : `&${sort}`;
    }
    if (pagination) {
      paginationString = !paramString && !filterString && !sortString ? `?${pagination}` : `&${pagination}`;
    }
    return HttpFactory.GET(apiRoutes.organization + paramString + filterString + sortString + paginationString);
  }

  static getSingle(id: number): Observable<FullOrganizationResponseInterfaceSingle> {
    return HttpFactory.GET(apiRoutes.organization + `/${id}`);
  }

  static create(body: FullOrganizationResponseInterfaceSingle): Observable<FullOrganizationResponseInterfaceSingle> {
    return HttpFactory.POST(apiRoutes.organization, body);
  }

  static update(body: FullOrganizationResponseInterfaceSingle): Observable<FullOrganizationResponseInterfaceSingle> {
    return HttpFactory.PATCH(apiRoutes.organization + `/${body.data.id}`, body);
  }

  static delete(id: number): Observable<any> {
    return HttpFactory.DELETE(apiRoutes.organization + `/${id}`);
  }
}

export class OrganizationUnitService {
  static getAll(
    params?: any,
    filter?: any,
    sort?: any,
    pagination?: any,
    includeOrgs?: boolean
  ): Observable<FullOrganizationUnitResponseInterfaceAll> {
    let filterString = '';
    let sortString = '';
    let paginationString = '';
    let paramArray: any[] = [];
    let paramString = '';
    let includeString = '';
    if (params) {
      paramArray = processTags(params);
      if (paramArray?.length) paramString = `?filter={${paramArray?.join(',')}}`;
    }
    if (filter) {
      filterString = paramString ? `&${filter}` : `?${filter}`;
    }
    if (sort) {
      sortString = !paramString && !filterString ? `?${sort}` : `&${sort}`;
    }
    if (pagination) {
      paginationString = !paramString && !filterString && !sortString ? `?${pagination}` : `&${pagination}`;
    }
    if (includeOrgs) {
      includeString = !paramString && !filterString && !sortString && !paginationString ? `?include=organization` : `&include=organization`;
    }
    return HttpFactory.GET(apiRoutes.organizationUnit + paramString + filterString + sortString + paginationString + includeString);
  }

  static getSingle(id: number): Observable<FullOrganizationUnitResponseInterfaceSingle> {
    return HttpFactory.GET(apiRoutes.organizationUnit + `/${id}`);
  }

  static create(
    body: FullOrganizationUnitResponseInterfaceSingle
  ): Observable<FullOrganizationUnitResponseInterfaceSingle> {
    return HttpFactory.POST(apiRoutes.organizationUnit, body);
  }

  static update(
    body: FullOrganizationUnitResponseInterfaceSingle
  ): Observable<FullOrganizationUnitResponseInterfaceSingle> {
    return HttpFactory.PATCH(apiRoutes.organizationUnit + `/${body.data.id}`, body);
  }

  static delete(id: number): Observable<any> {
    return HttpFactory.DELETE(apiRoutes.organizationUnit + `/${id}`);
  }
}

export class FullUserService {
  static getAll(params?: any, filter?: any, sort?: any, pagination?: any): Observable<FullUserResponseInterfaceAll> {
    let filterString = '';
    let sortString = '';
    let paginationString = '';
    let paramArray: any[] = [];
    let paramString = '';
    if (params) {
      paramArray = processTags(params);
      if (paramArray?.length) paramString = `?filter={${paramArray?.join(',')}}`;
    }
    if (filter) {
      filterString = paramString ? `&${filter}` : `?${filter}`;
    }
    if (sort) {
      sortString = !paramString && !filterString ? `?${sort}` : `&${sort}`;
    }
    if (pagination) {
      paginationString = !paramString && !filterString && !sortString ? `?${pagination}` : `&${pagination}`;
    }
    return HttpFactory.GET(apiRoutes.fullUser + paramString + filterString + sortString + paginationString);
  }

  static getSingle(id: number): Observable<FullUserResponseInterfaceSingle> {
    return HttpFactory.GET(apiRoutes.fullUser + `/${id}`);
  }

  static create(body: FullUserResponseInterfaceSingle): Observable<FullUserResponseInterfaceSingle> {
    return HttpFactory.POST(apiRoutes.fullUser, body);
  }

  static update(body: FullUserResponseInterfaceSingle): Observable<FullUserResponseInterfaceSingle> {
    return HttpFactory.PATCH(apiRoutes.fullUser + `/${body.data.id}`, body);
  }

  static updateAny(body: any): Observable<FullUserResponseInterfaceSingle> {
    return HttpFactory.PATCH(apiRoutes.fullUser + `/${body.data.id}`, body);
  }

  static delete(id: number): Observable<any> {
    return HttpFactory.DELETE(apiRoutes.fullUser + `/${id}`);
  }
}

export class LicenceService {
  static getAll(params?: any, filter?: any, sort?: any, pagination?: any): Observable<LicenceResponseInterfaceAll> {
    let filterString = '';
    let sortString = '';
    let paginationString = '';
    let paramArray: any[] = [];
    let paramString = '';
    if (params) {
      paramArray = processTags(params);
      if (paramArray?.length) paramString = `?filter={${paramArray?.join(',')}}`;
    }
    if (filter) {
      filterString = paramString ? `&${filter}` : `?${filter}`;
    }
    if (sort) {
      sortString = !paramString && !filterString ? `?${sort}` : `&${sort}`;
    }
    if (pagination) {
      paginationString = !paramString && !filterString && !sortString ? `?${pagination}` : `&${pagination}`;
    }
    return HttpFactory.GET(apiRoutes.licence + paramString + filterString + sortString + paginationString);
  }

  static getSingle(id: number): Observable<LicenceResponseInterfaceSingle> {
    return HttpFactory.GET(apiRoutes.licence + `/${id}`);
  }

  static create(body: LicenceResponseInterfaceSingle): Observable<LicenceResponseInterfaceSingle> {
    return HttpFactory.POST(apiRoutes.licence, body);
  }

  static createAny(body: any): Observable<LicenceResponseInterfaceSingle> {
    return HttpFactory.POST(apiRoutes.licence, body);
  }

  static update(body: LicenceResponseInterfaceSingle): Observable<LicenceResponseInterfaceSingle> {
    return HttpFactory.PATCH(apiRoutes.licence + `/${body.data.id}`, body);
  }

  static updateAny(body: any): Observable<LicenceResponseInterfaceSingle> {
    return HttpFactory.PATCH(apiRoutes.licence + `/${body.data.id}`, body);
  }

  static delete(id: number): Observable<any> {
    return HttpFactory.DELETE(apiRoutes.licence + `/${id}`);
  }

  static approveSpecificLicence(organizationUnitId: number, licenceId: number): Observable<any> {
    return HttpFactory.PUT(apiRoutes.approveLicence + `/${organizationUnitId}/${licenceId}`, null);
  }

  static disapproveSpecificLicence(organizationUnitId: number, licenceId: number): Observable<any> {
    return HttpFactory.PUT(apiRoutes.disapproveLicence + `/${organizationUnitId}/${licenceId}`, null);
  }

  static releaseLicence(licenceId: number): Observable<any> {
    return HttpFactory.PUT(apiRoutes.releaseLicence + `/${licenceId}`, null);
  }

  static extendLicence(id: number, until: string): Observable<any> {
    return HttpFactory.PUT(apiRoutes.extendLicence + `/${id}?until=${until}`, null);
  }

  static trialLicence(id: number, until: string): Observable<any> {
    return HttpFactory.PUT(apiRoutes.trialLicence + `/${id}?until=${until}`, null);
  }
}

export class LicenceUserService {
  static getAll(params?: any, filter?: any, sort?: any, pagination?: any): Observable<LicenceUserResponseInterfaceAll> {
    let filterString = '';
    let sortString = '';
    let paginationString = '';
    let paramArray: any[] = [];
    let paramString = '';
    if (params) {
      paramArray = processTags(params);
      if (paramArray?.length) paramString = `?filter={${paramArray?.join(',')}}`;
    }
    if (filter) {
      filterString = paramString ? `&${filter}` : `?${filter}`;
    }
    if (sort) {
      sortString = !paramString && !filterString ? `?${sort}` : `&${sort}`;
    }
    if (pagination) {
      paginationString = !paramString && !filterString && !sortString ? `?${pagination}` : `&${pagination}`;
    }
    return HttpFactory.GET(apiRoutes.licenceUser + paramString + filterString + sortString + paginationString);
  }

  static getSingle(id: number): Observable<LicenceUserResponseInterfaceSingle> {
    return HttpFactory.GET(apiRoutes.licenceUser + `/${id}`);
  }

  static create(body: any): Observable<LicenceUserResponseInterfaceSingle> {
    return HttpFactory.POST(apiRoutes.licenceUser, body);
  }

  static update(body: any): Observable<LicenceUserResponseInterfaceSingle> {
    return HttpFactory.PATCH(apiRoutes.licenceUser + `/${body.data.id}`, body);
  }

  static delete(id: number): Observable<any> {
    return HttpFactory.DELETE(apiRoutes.licenceUser + `/${id}`);
  }
}

function processTags(params: any) {
  let paramArray: any[] = [];
  Object.values(params)?.forEach((id: any) => {
    if (id && id?.length) {
      if (id?.length > 1) {
        id.forEach((x: any) => {
          if (x?.length) {
            x?.forEach((y: any) => {
              paramArray.push(`"tags": {"id": ${y}}`);
            });
          } else {
            paramArray.push(`"tags": {"id": ${x}}`);
          }
        });
      } else {
        if (id[0]?.length) {
          id[0]?.forEach((x: any) => {
            paramArray.push(`"tags": {"id": ${x}}`);
          });
        } else {
          paramArray.push(`"tags": {"id": ${id[0]}}`);
        }
      }
    }
  });

  return paramArray.length ? paramArray : [];
}

function processTagsEs(params: any) {
  let paramArray: number[] = [];
  if (!!params) return [];
  Object.values(params)?.forEach((id: any) => {
    if (id && id?.length) {
      if (id?.length > 1) {
        id.forEach((x: any) => {
          if (x?.length) {
            x?.forEach((y: any) => {
              paramArray.push(y);
            });
          } else {
            paramArray.push(x);
          }
        });
      } else {
        if (id[0]?.length) {
          id[0]?.forEach((x: any) => {
            paramArray.push(x);
          });
        } else {
          paramArray.push(id[0]);
        }
      }
    }
  });

  return paramArray.length ? paramArray : [];
}

export class CourtService {
  static getAll(params?: any, filter?: any, sort?: any, pagination?: any): Observable<CourtResponseInterfaceAll> {
    let filterString = '';
    let sortString = '';
    let paginationString = '';
    let paramArray: any[] = [];
    let paramString = '';
    if (params) {
      paramArray = processTags(params);
      if (paramArray?.length) paramString = `?filter={${paramArray?.join(',')}}`;
    }
    if (filter) {
      filterString = paramString ? `&${filter}` : `?${filter}`;
    }
    if (sort) {
      sortString = !paramString && !filterString ? `?${sort}` : `&${sort}`;
    }
    if (pagination) {
      paginationString = !paramString && !filterString && !sortString ? `?${pagination}` : `&${pagination}`;
    }
    return HttpFactory.GET(apiRoutes.courtEU + paramString + filterString + sortString + paginationString);
  }

  static getSingle(id: number): Observable<CourtResponseInterfaceSingle> {
    return HttpFactory.GET(apiRoutes.courtEU + `/${id}`);
  }

  static create(body: CourtResponseInterfaceSingle): Observable<CourtResponseInterfaceSingle> {
    return HttpFactory.POST(apiRoutes.courtEU, body);
  }

  static update(body: CourtResponseInterfaceSingle): Observable<CourtResponseInterfaceSingle> {
    return HttpFactory.PATCH(apiRoutes.courtEU + `/${body.data.id}`, body);
  }

  static delete(id: number): Observable<any> {
    return HttpFactory.DELETE(apiRoutes.courtEU + `/${id}`);
  }

  static elasticSearch(allOpinions: boolean, pageSorted: { from: number, size: number }, match: boolean, keyword?: string, tagIds?: any): Observable<CourtEsResponseInterface> {
    let attributes: Array<{ field: string, value: string } | { field: string, tags: Array<number> }> = [];
    let keywords: Array<{ field: string, value: string, exactMatch: boolean }> = [];
    //attributes.push({field: "allOpinions", value: allOpinions});
    if (keyword) {
      keywords.push({field: "verdictNumber", value: keyword, exactMatch: match});
      keywords.push({field: "verdictName", value: keyword, exactMatch: match});
      keywords.push({field: "verdictTitle", value: keyword, exactMatch: match});
      keywords.push({field: "textContent", value: keyword, exactMatch: match});
    }
    if (tagIds) {
      attributes = EsAttributesHelper(attributes, tagIds);
    }
    const body = {
      index: "court_eu",
      pagedSorted: {
        from: pageSorted.from,
        size: pageSorted.size,
      },
      attributes: attributes,
      globalSearchAttribute: keyword ? { 
        value: keyword,
        exactMatch: match
      } : {}
    }
    return HttpFactory.POST(apiRoutes.elasticSearch, body);
  }
}

export class ProMaterialService {
  static getAll(params?: any, filter?: any, sort?: any, pagination?: any): Observable<ProMaterialResponseInterfaceAll> {
    let filterString = '';
    let sortString = '';
    let paginationString = '';
    let paramArray: any[] = [];
    let paramString = '';
    if (params) {
      paramArray = processTags(params);
      if (paramArray?.length) paramString = `?filter={${paramArray?.join(',')}}`;
    }
    if (filter) {
      filterString = paramString ? `&${filter}` : `?${filter}`;
    }
    if (sort) {
      sortString = !paramString && !filterString ? `?${sort}` : `&${sort}`;
    }
    if (pagination) {
      paginationString = !paramString && !filterString && !sortString ? `?${pagination}` : `&${pagination}`;
    }
    return HttpFactory.GET(apiRoutes.proMaterials + paramString + filterString + sortString + paginationString);
  }

  static getSingle(id: number): Observable<ProMaterialResponseInterfaceSingle> {
    return HttpFactory.GET(apiRoutes.proMaterials + `/${id}` + '?include=proMaterialAssets');
  }

  static deleteSingleAsset(id: number): Observable<any> {
    return HttpFactory.DELETE(apiRoutes.proMaterialAssets + `/${id}`);
  }

  static createSingleAsset(body: any): Observable<any> {
    return HttpFactory.POST(apiRoutes.proMaterialAssets, body);
  }

  static updateSingleAsset(body: any): Observable<any> {
    return HttpFactory.PATCH(apiRoutes.proMaterialAssets + `/${body.data.id}`, body);
  }

  static create(body: ProMaterialResponseInterfaceSingle): Observable<ProMaterialResponseInterfaceSingle> {
    return HttpFactory.POST(apiRoutes.proMaterials, body);
  }

  static update(body: ProMaterialResponseInterfaceSingle): Observable<ProMaterialResponseInterfaceSingle> {
    return HttpFactory.PATCH(apiRoutes.proMaterials + `/${body.data.id}`, body);
  }

  static delete(id: number): Observable<any> {
    return HttpFactory.DELETE(apiRoutes.proMaterials + `/${id}`);
  }

  static elasticSearch(allOpinions: boolean, pageSorted: { from: number, size: number }, match: boolean, keyword?: string, tagIds?: ESFilterInterface): Observable<ProMaterialEsResponseInterface> {
    let attributes: Array<{ field: string, value: string } | { field: string, tags: Array<number> }> = [];
    let keywords: Array<{ field: string, value: string, exactMatch: boolean }> = [];
    //attributes.push({field: "allOpinions", value: allOpinions});
    if (keyword) {
      keywords.push({field: "publisher", value: keyword, exactMatch: match});
      keywords.push({field: "materialTitle", value: keyword, exactMatch: match});
      keywords.push({field: "summary", value: keyword, exactMatch: match});
    }

    if (tagIds) {
      attributes = EsAttributesHelper(attributes, tagIds);
    }

    const body = {
      index: "pro_material",
      pagedSorted: {
        from: pageSorted.from,
        size: pageSorted.size,
      },
      attributes: attributes,
      globalSearchAttribute: keyword ? { 
        value: keyword,
        exactMatch: match
      } : {}
    }
    return HttpFactory.POST(apiRoutes.elasticSearch, body);
  }
}

export class NotificationService {
  static getAll(params?: any, filter?: any, sort?: any, pagination?: any): Observable<NotificationInterfaceAll> {
    let filterString = '';
    let sortString = '';
    let paginationString = '';
    let paramArray: any[] = [];
    let paramString = '';
    if (params) {
      paramArray = processTags(params);
      if (paramArray?.length) paramString = `?filter={${paramArray?.join(',')}}`;
    }
    if (filter) {
      filterString = paramString ? `&${filter}` : `?${filter}`;
    }
    if (sort) {
      sortString = !paramString && !filterString ? `?${sort}` : `&${sort}`;
    }
    if (pagination) {
      paginationString = !paramString && !filterString && !sortString ? `?${pagination}` : `&${pagination}`;
    }
    return HttpFactory.GET(apiRoutes.notification + paramString + filterString + sortString + paginationString);
  }

  static create(body: NotificationInterfaceSingle): Observable<NotificationInterfaceSingle> {
    return HttpFactory.POST(apiRoutes.notification, body);
  }

  static update(body: NotificationInterfaceSingle): Observable<NotificationInterfaceSingle> {
    return HttpFactory.PATCH(apiRoutes.notification + `/${body.data.id}`, body);
  }
}

export class PriceService {
  static getAll(orgUnitId: number): Observable<PriceResponseInterfaceAll> {
    return HttpFactory.GET(apiRoutes.prices + `?filter[organizationUnit.id]=${orgUnitId}`);
  }

  static getSingle(id: number): Observable<PriceResponseInterfaceSingle> {
    return HttpFactory.GET(apiRoutes.prices + `/${id}`);
  }

  static create(body: PriceResponseInterfaceSingle): Observable<PriceResponseInterfaceSingle> {
    return HttpFactory.POST(apiRoutes.prices, body);
  }

  static update(body: PriceResponseInterfaceSingle): Observable<PriceResponseInterfaceSingle> {
    return HttpFactory.PATCH(apiRoutes.prices + `/${body.data.id}`, body);
  }

  static delete(id: number): Observable<any> {
    return HttpFactory.DELETE(apiRoutes.prices + `/${id}`);
  }
}

export class QuestionEducationService {
  static putQuestions(userId: number, body: { value: number }): Observable<any> {
    return HttpFactory.PUT(apiRoutes.questionsPut + `/${userId}`, body);
  }

  static putEducations(userId: number, body: { value: number }): Observable<any> {
    return HttpFactory.PUT(apiRoutes.educationsPut + `/${userId}`, body);
  }
}

export class PasswordChangeService {
  static changePassword(body: { email: string, newPassword: string, oldPassword: string }): Observable<any> {
    return HttpFactory.POST(apiRoutes.changePassword, body);
  }
}

export class PrintEditionService {
  static getAll(params?: any, filter?: any, sort?: any, pagination?: any): Observable<PrintEditionResponseInterfaceAll> {
    let filterString = '';
    let sortString = '';
    let paginationString = '';
    let paramArray: any[] = [];
    let paramString = '';
    if (params) {
      paramArray = processTags(params);
      if (paramArray?.length) paramString = `?filter={${paramArray?.join(',')}}`;
    }
    if (filter) {
      filterString = paramString ? `&${filter}` : `?${filter}`;
    }
    if (sort) {
      sortString = !paramString && !filterString ? `?${sort}` : `&${sort}`;
    }
    if (pagination) {
      paginationString = !paramString && !filterString && !sortString ? `?${pagination}` : `&${pagination}`;
    }
    return HttpFactory.GET(apiRoutes.printEditions + paramString + filterString + sortString + paginationString);
  }

  static getAllWithBilten(params?: any, filter?: any, sort?: any, pagination?: any): Observable<PrintEditionsBiltenIncludedResponse> {
    let filterString = '';
    let sortString = '';
    let paginationString = '';
    let paramArray: any[] = [];
    let paramString = '';
    if (params) {
      paramArray = processTags(params);
      if (paramArray?.length) paramString = `?filter={${paramArray?.join(',')}}`;
    }
    if (filter) {
      filterString = paramString ? `&${filter}` : `?${filter}`;
    }
    if (sort) {
      sortString = !paramString && !filterString ? `?${sort}` : `&${sort}`;
    }
    if (pagination) {
      paginationString = !paramString && !filterString && !sortString ? `?${pagination}` : `&${pagination}`;
    }
    return HttpFactory.GET(apiRoutes.printEditions + paramString + filterString + sortString + paginationString);
  }

  static getSingle(id: number): Observable<PrintEditionResponseInterfaceSingle> {
    return HttpFactory.GET(apiRoutes.printEditions + `/${id}`);
  }

  static create(body: PrintEditionResponseInterfaceSingle): Observable<PrintEditionResponseInterfaceSingle> {
    return HttpFactory.POST(apiRoutes.printEditions, body);
  }

  static update(body: PrintEditionResponseInterfaceSingle, id?: number): Observable<PrintEditionResponseInterfaceSingle> {
    return HttpFactory.PATCH(apiRoutes.printEditions + `/${body.data.id ? body.data.id : id}`, body);
  }

  static delete(id: number): Observable<any> {
    return HttpFactory.DELETE(apiRoutes.printEditions + `/${id}`);
  }
}
export class InviteService {
  static create(body: any): Observable<any> {
    return HttpFactory.POST(apiRoutes.userInvitation, body);
  }

  static accept(body: {password: string, invitationId: number}): Observable<any> {
    return HttpFactory.POST(apiRoutes.userInvitation, body);
  }

  static check(id: number): Observable<any> {
    return HttpFactory.GET(apiRoutes.userInvitation + `/${id}`);
  }
}

export class UpgradeRequestService {
  static getAll(): Observable<UpgradeRequestResponseInterfaceAll> {
    return HttpFactory.GET(apiRoutes.upgradeRequest + `?page[offset]=0&page[limit]=9999&include=user,user.organizationUnit&sort=-id`);
  }

  static getAllFromUser(userId: number): Observable<UpgradeRequestResponseInterfaceAll> {
    return HttpFactory.GET(apiRoutes.upgradeRequest + `?filter[user.id]=${userId}&page[offset]=0&page[limit]=9999&include=user,user.organizationUnit&sort=-id`);
  }

  static create(body: UpgradeRequestResponseInterfaceSingle): Observable<UpgradeRequestResponseInterfaceSingle> {
    return HttpFactory.POST(apiRoutes.upgradeRequest, body);
  }

  static update(body: UpgradeRequestResponseInterfaceSingle): Observable<UpgradeRequestResponseInterfaceSingle> {
    return HttpFactory.PATCH(apiRoutes.upgradeRequest + `/${body.data.id}`, body);
  }

  static delete(id: number): Observable<any> {
    return HttpFactory.DELETE(apiRoutes.upgradeRequest + `/${id}`);
  }
}

export class ExpiredLicenceService {
  static getAllByExpirationDate(date: string): Observable<ExpiredLicenceInterface[]> {
    return HttpFactory.GET(apiRoutes.expiredLicences + `?expiresOn=${date}`);
  }
}

export class TwoFactorAuthenticationService {
  static activate(): Observable<any> {
    return HttpFactory.POST(apiRoutes.twoFactorActivation, {});
  }

  static confirm(code: string): Observable<any> {
    return HttpFactory.POST(apiRoutes.twoFactorConfirmation + `?code=${code}`, {});
  }

  static deactivate(userId: number): Observable<any> {
    return HttpFactory.POST(apiRoutes.twoFactorDeactivation + `?userId=${userId}`, {});
  }

  static getLatest2faCode(userId: number): Observable<UpgradeRequestResponseInterfaceAll> {
    return HttpFactory.GET(apiRoutes.latest2faCode + `?userId=${userId}`);
  }
}
