import { Dispatch } from 'redux';
import { notification } from 'antd';
import { DecisionConstants } from './decision.constants';

import { DecisionService } from '../../api/api.service';
import { DecisionResponseInterfaceAll, DecisionResponseInterfaceSingle } from '../../interfaces';
import { i18n } from '../../services';

export function getAllDecisionsAction(params?: any, filter?: any, sort?: any, pagination?: any) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: DecisionConstants.GET_ALL_SUCCESS,
      payload: {
        data: [],
        meta: {}
      },
    });

    return DecisionService.getAll(params, filter, sort, pagination).subscribe(
      (response: DecisionResponseInterfaceAll) => {
        dispatch({
          type: DecisionConstants.GET_ALL_SUCCESS,
          payload: response,
        });
        
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });

        dispatch({
          type: DecisionConstants.ERROR,
          payload: error,
        });
      }
    );
  };
}

export function getSingleDecisionAction(id: number) {
  return (dispatch: Dispatch) => {
    return DecisionService.getSingle(id).subscribe(
      (response: DecisionResponseInterfaceSingle) => {
        dispatch({
          type: DecisionConstants.GET_SINGLE_SUCCESS,
          payload: response,
        });
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });

        dispatch({
          type: DecisionConstants.ERROR,
          payload: error,
        });
      }
    );
  };
}

export function createDecisionAction(body: DecisionResponseInterfaceSingle) {
  return (dispatch: Dispatch) => {
    return DecisionService.create(body).subscribe(
      (response: DecisionResponseInterfaceSingle) => {
        notification['success']({ message: i18n.translate('api.successMessage'), duration: 2 });

        dispatch({
          type: DecisionConstants.CREATE_SUCCESS,
          payload: response,
        });
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });

        dispatch({
          type: DecisionConstants.ERROR,
          payload: error,
        });
      }
    );
  };
}

export function updateDecisionAction(body: DecisionResponseInterfaceSingle) {
  return (dispatch: Dispatch) => {
    return DecisionService.update(body).subscribe(
      (response: DecisionResponseInterfaceSingle) => {
        notification['success']({ message: i18n.translate('api.successMessage'), duration: 2 });

        dispatch({
          type: DecisionConstants.UPDATE_SUCCESS,
          payload: response,
        });
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });

        dispatch({
          type: DecisionConstants.ERROR,
          payload: error,
        });
      }
    );
  };
}

export function deleteDecisionAction(id: number) {
  return (dispatch: Dispatch) => {
    return DecisionService.delete(id).subscribe(
      (response: any) => {
        notification['success']({ message: i18n.translate('api.successDelete'), duration: 2 });

        dispatch({
          type: DecisionConstants.DELETE
        });
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });

        dispatch({
          type: DecisionConstants.ERROR,
          payload: error,
        });
      }
    );
  };
}

export function clearDecisionAction() {
  return (dispatch: Dispatch) => {
    dispatch({ type: DecisionConstants.CLEAR_CURRENT });
  };
}

export function clearDecisionListAction() {
  return (dispatch: Dispatch) => {
    dispatch({ type: DecisionConstants.CLEAR_LIST });
  };
}
