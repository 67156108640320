import React from 'react';
import { Form, Input, Button, notification } from 'antd';
import { useDispatch } from 'react-redux';
import { i18n, NavigationService, UserService } from '../../../common';
import { Link } from 'react-router-dom';
import { AuthRoutes } from '../_router/auth.routes';
import logo from '../../../assets/images/bjn.png';

function ForgotPasswordPageComponent() {
  const dispatch = useDispatch();

  const tailLayout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };

  const onFinish = (values: any) => {
    UserService.resetPassword(values.email).subscribe(
      (responseUser: any) => {
        notification['success']({ message: i18n.translate('forgotPassword.msgs.success'), duration: 5 });
        setTimeout(() => {
          NavigationService.navigate(AuthRoutes.LOGIN.fullPath);
        }, 500);
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('forgotPassword.msgs.error'), duration: 23});
      }
    );
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div className='login'>
      <div className='login__icon'>
        <img width={38} height={32} src={logo} alt="none" />
        <div className='text'>BJN</div>
      </div>
      <div className='login__title'>
        <div className="primary">
          {i18n.translate(`forgotPassword.title.primary`)}
        </div>
        <div className='subtitle'>
        {i18n.translate(`forgotPassword.title.subtitle`)}
        </div>
      </div>
      <div className='login__form'>
        <Form
          name="basic"
          layout='vertical'
          initialValues={{ remember: true, email: '', password: '' }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          requiredMark={false}
        >
          <Form.Item
            label={i18n.translate(`forgotPassword.form.username`)}
            name="email"
            rules={[
              { required: true, message: i18n.translate(`forgotPassword.msgs.required`) },
              { type: 'email', message: i18n.translate(`register.msgs.email`) },
            ]}
          >
            <Input size="large" style={{borderRadius: 0}} />
          </Form.Item>

          <Form.Item {...tailLayout}>
            <Button type="primary" htmlType="submit" block size="large" style={{fontSize: 16, fontWeight: 700, borderRadius: 0}}>
              {i18n.translate(`forgotPassword.btns.login`)}
            </Button>
          </Form.Item>
          {i18n.translate(`forgotPassword.btns.noAccount`)}
          <Link to={AuthRoutes.LOGIN.fullPath} key={AuthRoutes.LOGIN.path}>
            <Button type="link" style={{paddingLeft: 2}}>
              <span style={{textDecoration: 'underline'}}>{i18n.translate(`forgotPassword.btns.register`)}</span>
            </Button>
          </Link>
        </Form>
      </div>
    </div>
  );
}

export default ForgotPasswordPageComponent;
