import { Dispatch } from 'redux';
import { notification } from 'antd';
import { ResponseConstants } from './response.constants';

import { ResponseService } from '../../api/api.service';
import { ResponseResponseInterfaceAll, ResponseResponseInterfaceSingle } from '../../interfaces';
import { i18n } from '../../services';

export function getAllResponsesAction(params?: any, filter?: any, sort?: any, pagination?: any) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: ResponseConstants.SET_LOADER,
      payload: true
    });

    dispatch({
      type: ResponseConstants.GET_ALL_SUCCESS,
      payload: {
        data: [],
        meta: {}
      },
    });

    return ResponseService.getAll(params, filter, sort, pagination).subscribe(
      (response: ResponseResponseInterfaceAll) => {
        dispatch({
          type: ResponseConstants.GET_ALL_SUCCESS,
          payload: response,
        });
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });

        dispatch({
          type: ResponseConstants.ERROR,
          payload: error,
        });
      }
    );
  };
}

export function getSingleResponseAction(id: number) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: ResponseConstants.SET_LOADER,
      payload: true
    });

    return ResponseService.getSingle(id).subscribe(
      (response: ResponseResponseInterfaceSingle) => {
        dispatch({
          type: ResponseConstants.GET_SINGLE_SUCCESS,
          payload: response,
        });
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });

        dispatch({
          type: ResponseConstants.ERROR,
          payload: error,
        });
      }
    );
  };
}

export function createResponseAction(body: ResponseResponseInterfaceSingle, showNotif: boolean = true) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: ResponseConstants.SET_LOADER,
      payload: true
    });

    return ResponseService.create(body).subscribe(
      (response: ResponseResponseInterfaceSingle) => {
        if (showNotif) notification['success']({ message: i18n.translate('api.successMessage'), duration: 2 });

        dispatch({
          type: ResponseConstants.CREATE_SUCCESS,
          payload: response,
        });
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });

        dispatch({
          type: ResponseConstants.ERROR,
          payload: error,
        });
      }
    );
  };
}

export function updateResponseAction(body: ResponseResponseInterfaceSingle, showNotif: boolean = true) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: ResponseConstants.SET_LOADER,
      payload: true
    });

    return ResponseService.update(body).subscribe(
      (response: ResponseResponseInterfaceSingle) => {
        if (showNotif) notification['success']({ message: i18n.translate('api.successMessage'), duration: 2 });

        dispatch({
          type: ResponseConstants.UPDATE_SUCCESS,
          payload: response,
        });
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });

        dispatch({
          type: ResponseConstants.ERROR,
          payload: error,
        });
      }
    );
  };
}

export function deleteResponseAction(id: number) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: ResponseConstants.SET_LOADER,
      payload: true
    });
    
    return ResponseService.delete(id).subscribe(
      (response: any) => {
        dispatch({
          type: ResponseConstants.DELETE
        });
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });

        dispatch({
          type: ResponseConstants.ERROR,
          payload: error,
        });
      }
    );
  };
}

export function clearResponseAction() {
  return (dispatch: Dispatch) => {
    dispatch({ type: ResponseConstants.CLEAR_CURRENT });
  };
}

export function clearResponseListAction() {
  return (dispatch: Dispatch) => {
    dispatch({ type: ResponseConstants.CLEAR_LIST });
  };
}
