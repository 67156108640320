export enum RegulationConstants {
  GET_ALL_SUCCESS = 'RegulationConstants_GET_ALL_SUCCESS',
  GET_SINGLE_SUCCESS = 'RegulationConstants_GET_SINGLE_SUCCESS',
  CREATE_SUCCESS = 'RegulationConstants_CREATE_SUCCESS',
  UPDATE_SUCCESS = 'RegulationConstants_UPDATE_SUCCESS',
  CLEAR_CURRENT = 'RegulationConstants_CLEAR_CURRENT',
  CLEAR_LIST = 'RegulationConstants_CLEAR_LIST',
  DELETE = 'RegulationConstants_DELETE',
  ERROR = 'RegulationConstants_ERROR'
}
