let _navigator: any;

function setTopLevelNavigator(navigatorRef: any) {
  _navigator = navigatorRef;
}

function navigate(route: string, queryParams?: string) {
  if (queryParams) _navigator.history.push({pathname: route, search: queryParams});
  else _navigator.history.push(route);
}

export default {
  navigate,
  setTopLevelNavigator,
};
