import { Button, Col, DatePicker, Form, Input, InputNumber, Modal, notification, Row, Switch, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, Link } from 'react-router-dom';
import { AppRoutes } from '../../../../../features/app';
import { OrganizationService, OrganizationUnitService } from '../../../../api';
import { UserRoleEnum } from '../../../../enums';
import { FullOrganizationResponseInterfaceSingle, FullOrganizationUnitResponseInterfaceSingle } from '../../../../interfaces';
import { clearFullUserAction, getSingleFullUserAction, StoreStateInterface, updateFullUserAction, updateOrganizationAction, updateOrganizationUnitAction } from '../../../../redux';
import { i18n, NavigationService } from '../../../../services';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import localeEN from 'antd/es/date-picker/locale/en_GB';
import localeHR from 'antd/es/date-picker/locale/hr_HR';
import { DisplayDateFormat } from '../../../../constants';
import moment from 'moment';

const { Title } = Typography;
const { TextArea } = Input;

const { confirm } = Modal;

export default function BasicInfoComponent(/**props ? */) {
  const dispatch = useDispatch();
  const userAuth = useSelector((state: StoreStateInterface) => state.auth);
  const id = userAuth.user?.id;
  const user = useSelector((state: StoreStateInterface) => state.fullUser);
  const [orgUnitInfo, setOrgUnitInfo] = useState<FullOrganizationUnitResponseInterfaceSingle>();
  const [orgInfo, setOrgInfo] = useState<FullOrganizationResponseInterfaceSingle>();

  useEffect(() => {
    dispatch(clearFullUserAction());
    dispatch(getSingleFullUserAction(id as number));
  }, [id]);

  useEffect(() => {
    if (user.currentFullUser?.relationships?.organizationUnit?.data?.id) {
      OrganizationUnitService.getSingle(user.currentFullUser?.relationships?.organizationUnit?.data?.id).subscribe(
        (response: FullOrganizationUnitResponseInterfaceSingle) => {
          setOrgUnitInfo(response);
        },
        (error: Error) => {
          notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
        }
      );
    }
  }, [user.currentFullUser]);

  useEffect(() => {
    if (orgUnitInfo?.data?.relationships?.organization?.data?.id) {
      OrganizationService.getSingle(orgUnitInfo?.data?.relationships?.organization?.data?.id).subscribe(
        (response: FullOrganizationResponseInterfaceSingle) => {
          setOrgInfo(response);
        },
        (error: Error) => {
          notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
        }
      );
    }
  }, [orgUnitInfo?.data]);

  const onFinish = (values: any) => {

    let userData: any = {
      data: {
        type: 'user',
        id: user.currentFullUser?.id,
        attributes: {
          name: values?.name,
          phoneNumber: values?.contactNo,
          email: user.currentFullUser?.attributes?.email,
          footnote: user.currentFullUser?.attributes?.footnote,
          adminFootnote: values?.temporisRemark,
          address: values?.addressUser,
          newEmailFootnote: {
            email: values?.email,
            footnote: values?.userRemark,
          }
        }
      },
    };

    let orgUnitData: any = {
      data: {
        type: 'organization_unit',
        id: orgUnitInfo?.data?.id,
        attributes: {
          name: values?.groupName
        }
      },
    };

    let orgData: any = {
      data: {
        type: 'organization',
        id: orgInfo?.data?.id,
        attributes: {
          type: values?.type,
          contact: values?.contactNo,
          streetAddress: values?.address,
          city: values?.city,
          zipCode: values?.postNo,
          country: values?.companyCountry,
          name: values?.companyName,
          vat: values?.companyOib,
          changesMadeByUserId: user.currentFullUser?.id
        }
      },
    };

    dispatch(updateFullUserAction(userData));
    if (userAuth.user?.roles !== UserRoleEnum.USER) dispatch(updateOrganizationAction(orgData, true));

    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const showConfirm = () => {
    confirm({
      title: i18n.t(user.currentFullUser?.attributes?.active ? 'profile.basicInfo.userDeactivate' : 'profile.basicInfo.userActivate'),
      icon: <ExclamationCircleOutlined />,
      //content: i18n.t('common.cantUndo'),
      okText: i18n.t('genericButtons.yes'),
      cancelText: i18n.t('genericButtons.cancel'),
      onOk() {
        let userData: any = {
          data: {
            type: 'user',
            id: user.currentFullUser?.id,
            attributes: {
              active: user.currentFullUser?.attributes?.active ? false : true,
              oldUser: {
                name: user.currentFullUser?.attributes?.name,
                phoneNumber: user.currentFullUser?.attributes?.phoneNumber,
                email: user.currentFullUser?.attributes?.email,
                footnote: user.currentFullUser?.attributes?.footnote,
                adminFootnote: user.currentFullUser?.attributes?.adminFootnote,
              }
            }
          },
        };

        dispatch(updateFullUserAction(userData));
        window.scrollTo({ top: 0, behavior: 'smooth' });

        setTimeout(() => {
          NavigationService.navigate(AppRoutes.USER_PROFILE.fullPath);
        }, 1000);
      },
    });
  };

  return (
    <div className="basicInfo">
      {user.currentFullUser && orgUnitInfo?.data && orgInfo?.data ? (
        <Form
          layout="vertical"
          initialValues={{
            name: user?.currentFullUser?.attributes?.name,
            email: user?.currentFullUser?.attributes?.email,
            userRemark: user?.currentFullUser?.attributes?.footnote,
            addressUser: user?.currentFullUser?.attributes?.address,
            registeredDate: user?.currentFullUser?.attributes?.createdDate ? moment(user?.currentFullUser?.attributes?.createdDate) : null,

            groupName: orgUnitInfo?.data?.attributes?.name,

            type: orgInfo?.data?.attributes?.type,
            contactNo: orgInfo?.data?.attributes?.contact,
            address: orgInfo?.data?.attributes?.streetAddress,
            city: orgInfo?.data?.attributes?.city,
            country: orgInfo?.data?.attributes?.country,
            companyName: orgInfo?.data?.attributes?.name,
            companyOib: orgInfo?.data?.attributes?.vat,
            postNo: orgInfo?.data?.attributes?.zipCode,
            companyCountry: orgInfo?.data?.attributes?.country,
          }}
          onFinish={onFinish}
        >
          {orgInfo?.data?.attributes?.type == 1 ?
          <>
            <div className="section">
              <Title className="basicInfo__title" level={5}></Title>
              <Form.Item label={i18n.translate(`profile.basicInfo.name`)} name="name">
                <Input style={{ borderRadius: 0 }} />
              </Form.Item>
              <Form.Item name="email" label="E-mail" rules={[{ type: 'email', message: i18n.translate(`register.msgs.email`) },]}>
                <Input />
              </Form.Item>
              <Form.Item name="registeredDate" label={i18n.translate(`organizationInfo.registerDate`)}>
                <DatePicker disabled locale={userAuth.lang == 'hr' ? localeHR : localeEN} format={DisplayDateFormat} />
              </Form.Item>
            </div>

            <div className="section">
              <Title className="basicInfo__title" level={5}>
                {i18n.translate('profile.basicInfo.companyInfo')}
              </Title>
              <Form.Item label={i18n.translate(`profile.basicInfo.companyName`)} name="companyName">
                <TextArea disabled={userAuth.user?.roles === UserRoleEnum.USER} autoSize style={{ borderRadius: 0, width: 540 }} />
              </Form.Item>
              {/**userAuth.user?.roles === UserRoleEnum.MANAGER ? <Form.Item label={i18n.translate(`profile.basicInfo.groupName`)} name="groupName">
                <TextArea disabled autoSize style={{ borderRadius: 0, width: 540 }} />
              </Form.Item> : null*/}
              <Form.Item
                label={i18n.translate(`profile.basicInfo.companyOib`)}
                name="companyOib"
              >
                <Input disabled={userAuth.user?.roles === UserRoleEnum.USER} style={{ borderRadius: 0, width: 200 }} />
              </Form.Item>
              <Form.Item
                label={i18n.translate(`profile.basicInfo.contactNo`)}
                name="contactNo"
              >
                <Input disabled={userAuth.user?.roles === UserRoleEnum.USER} style={{ borderRadius: 0, width: 200 }} />
              </Form.Item>
              <Form.Item label={i18n.translate(`profile.basicInfo.address`)} name="address">
                <TextArea disabled={userAuth.user?.roles === UserRoleEnum.USER} style={{ borderRadius: 0, width: 200 }} />
              </Form.Item>
              <Input.Group>
                <Row justify="space-between" style={{ width: 350 }}>
                  <Col span={15}>
                    <Form.Item name="city" label={i18n.translate(`profile.basicInfo.city`)}>
                      <Input disabled={userAuth.user?.roles === UserRoleEnum.USER} />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="postNo"
                      label={i18n.translate(`profile.basicInfo.postNo`)}
                    >
                      <Input disabled={userAuth.user?.roles === UserRoleEnum.USER} />
                    </Form.Item>
                  </Col>
                </Row>
              </Input.Group>
              <Form.Item name="companyCountry" label={i18n.translate(`profile.basicInfo.country`)}>
                <Input disabled={userAuth.user?.roles === UserRoleEnum.USER} />
              </Form.Item>
            </div>

            <div className="section">
              <Title className="basicInfo__title" level={5}>
                {i18n.translate('profile.basicInfo.remark')}
              </Title>
              <Form.Item name="addressUser" label={i18n.translate(`profile.subModels.deliveryAddress`)}>
                <TextArea autoSize={{ minRows: 3 }} />
              </Form.Item>
              <Form.Item name="userRemark" label={i18n.translate(`profile.basicInfo.userRemark`)}>
                <TextArea autoSize={{ minRows: 3 }} />
              </Form.Item>
            </div>
          </>
          :
          <>
            <div className="section">
              <Title className="basicInfo__title" level={5}></Title>
              <Form.Item label={i18n.translate(`profile.basicInfo.name`)} name="name">
                <Input style={{ borderRadius: 0 }} />
              </Form.Item>
              <Form.Item
                label={i18n.translate(`register.form.vat`)}
                name="companyOib"
              >
                <Input disabled={userAuth.user?.roles === UserRoleEnum.USER} style={{ borderRadius: 0, width: 200 }} />
              </Form.Item>
              <Form.Item name="email" label="E-mail" rules={[{ type: 'email', message: i18n.translate(`register.msgs.email`) },]}>
                <Input />
              </Form.Item>
              <Form.Item
                label={i18n.translate(`profile.basicInfo.contactNo`)}
                name="contactNo"
              >
                <Input disabled={userAuth.user?.roles === UserRoleEnum.USER} style={{ borderRadius: 0, width: 200 }} />
              </Form.Item>
              <Form.Item label={i18n.translate(`profile.basicInfo.address`)} name="address">
                <TextArea disabled={userAuth.user?.roles === UserRoleEnum.USER} style={{ borderRadius: 0, width: 200 }} />
              </Form.Item>
              <Form.Item name="registeredDate" label={i18n.translate(`organizationInfo.registerDate`)}>
                <DatePicker disabled locale={userAuth.lang == 'hr' ? localeHR : localeEN} format={DisplayDateFormat} />
              </Form.Item>
              <Input.Group>
                <Row justify="space-between" style={{ width: 350 }}>
                  <Col span={15}>
                    <Form.Item name="city" label={i18n.translate(`profile.basicInfo.city`)}>
                      <Input disabled={userAuth.user?.roles === UserRoleEnum.USER} />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="postNo"
                      label={i18n.translate(`profile.basicInfo.postNo`)}
                    >
                      <Input disabled={userAuth.user?.roles === UserRoleEnum.USER} />
                    </Form.Item>
                  </Col>
                </Row>
              </Input.Group>
              <Form.Item name="companyCountry" label={i18n.translate(`profile.basicInfo.country`)}>
                <Input disabled={userAuth.user?.roles === UserRoleEnum.USER} />
              </Form.Item>
            </div>

            <div className="section">
              <Title className="basicInfo__title" level={5}>
                {i18n.translate('profile.basicInfo.remark')}
              </Title>
              <Form.Item name="addressUser" label={i18n.translate(`profile.subModels.deliveryAddress`)}>
                <TextArea autoSize={{ minRows: 3 }} />
              </Form.Item>
              <Form.Item name="userRemark" label={i18n.translate(`profile.basicInfo.userRemark`)}>
                <TextArea autoSize={{ minRows: 3 }} />
              </Form.Item>
            </div>
          </>}

          <Input.Group>
            <Row gutter={10} style={{ width: '100%', margin: 0 }}>
              <Col>
              <Link to={AppRoutes.USER_PROFILE.fullPath} key={AppRoutes.USER_PROFILE.path}>
                <Button>{i18n.translate(`genericButtons.cancel`)}</Button>
              </Link>
              </Col>
              <Col>
                <Button type="primary" htmlType="submit">{i18n.translate(`genericButtons.save`)}</Button>
              </Col>
            </Row>
          </Input.Group>
        </Form>
      ) : null}
    </div>
  );
}
