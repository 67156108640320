export enum DecisionConstants {
  GET_ALL_SUCCESS = 'DecisionConstants_GET_ALL_SUCCESS',
  GET_SINGLE_SUCCESS = 'DecisionConstants_GET_SINGLE_SUCCESS',
  CREATE_SUCCESS = 'DecisionConstants_CREATE_SUCCESS',
  UPDATE_SUCCESS = 'DecisionConstants_UPDATE_SUCCESS',
  CLEAR_CURRENT = 'DecisionConstants_CLEAR_CURRENT',
  CLEAR_LIST = 'DecisionConstants_CLEAR_LIST',
  DELETE = 'DecisionConstants_DELETE',
  ERROR = 'DecisionConstants_ERROR'
}
