import { Button, Col, DatePicker, Form, Input, Modal, notification, Radio, RadioChangeEvent, Row, Space, Switch, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { AdminRoutes } from '../../../../../features/admin';
import { OrganizationService, OrganizationUnitService } from '../../../../api';
import { FullOrganizationResponseInterfaceSingle, FullOrganizationUnitResponseInterfaceSingle } from '../../../../interfaces';
import { clearFullUserAction, getSingleFullUserAction, StoreStateInterface, updateFullUserAction, updateOrganizationAction, updateOrganizationUnitAction } from '../../../../redux';
import { i18n, NavigationService } from '../../../../services';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { UserRoleEnum } from '../../../../enums';
import localeEN from 'antd/es/date-picker/locale/en_GB';
import localeHR from 'antd/es/date-picker/locale/hr_HR';
import { DisplayDateFormat } from '../../../../constants';
import moment from 'moment';

const { Title } = Typography;
const { TextArea } = Input;

const { confirm } = Modal;

export default function AdminBasicInfoComponent(/**props ? */) {
  const location = useLocation();
  const path = location.pathname.split('/');
  const id = parseInt(path[path.length - 1]);
  
  const dispatch = useDispatch();
  const userAuth = useSelector((state: StoreStateInterface) => state.auth);
  const user = useSelector((state: StoreStateInterface) => state.fullUser);
  const [orgUnitInfo, setOrgUnitInfo] = useState<FullOrganizationUnitResponseInterfaceSingle>();
  const [orgInfo, setOrgInfo] = useState<FullOrganizationResponseInterfaceSingle>();

  const [form] = Form.useForm();
  const formType = Form.useWatch('type', form);

  useEffect(() => {
    dispatch(clearFullUserAction());
    dispatch(getSingleFullUserAction(id));
  }, [id]);

  useEffect(() => {
    if (user.currentFullUser?.relationships?.organizationUnit?.data?.id) {
      OrganizationUnitService.getSingle(user.currentFullUser?.relationships?.organizationUnit?.data?.id).subscribe(
        (response: FullOrganizationUnitResponseInterfaceSingle) => {
          setOrgUnitInfo(response);
        },
        (error: Error) => {
          notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
        }
      );
    }
  }, [user.currentFullUser]);

  useEffect(() => {
    if (orgUnitInfo?.data?.relationships?.organization?.data?.id) {
      OrganizationService.getSingle(orgUnitInfo?.data?.relationships?.organization?.data?.id).subscribe(
        (response: FullOrganizationResponseInterfaceSingle) => {
          setOrgInfo(response);
        },
        (error: Error) => {
          notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
        }
      );
    }
  }, [orgUnitInfo?.data]);

  const onFinish = (values: any) => {

    let userData: any = {
      data: {
        type: 'user',
        id: user.currentFullUser?.id,
        attributes: {
          name: values?.name,
          phoneNumber: values?.contactNo,
          email: user.currentFullUser?.attributes?.email,
          footnote: user.currentFullUser?.attributes?.footnote,
          adminFootnote: values?.temporisRemark,
          address: values?.addressUser,
          competition: values?.competition,
          newEmailFootnote: {
            email: values?.email,
            footnote: values?.userRemark,
          }
        }
      },
    };

    let orgUnitData: any = {
      data: {
        type: 'organization_unit',
        id: orgUnitInfo?.data?.id,
        attributes: {
          name: values?.groupName
        }
      },
    };

    let orgData: any = {
      data: {
        type: 'organization',
        id: orgInfo?.data?.id,
        attributes: {
          type: values?.type,
          contact: values?.contactNo,
          streetAddress: values?.address,
          city: values?.city,
          zipCode: values?.postNo,
          country: values?.companyCountry,
          name: values?.companyName,
          vat: values?.companyOib,
          changesMadeByUserId: user.currentFullUser?.id
        }
      },
    };

    dispatch(updateFullUserAction(userData));
    //dispatch(updateOrganizationUnitAction(orgUnitData, true));
    dispatch(updateOrganizationAction(orgData, true));

    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const showConfirm = () => {
    confirm({
      title: i18n.t(user.currentFullUser?.attributes?.removed ? 'profile.basicInfo.userActivate' : 'profile.basicInfo.userDeactivate'),
      icon: <ExclamationCircleOutlined />,
      //content: i18n.t('common.cantUndo'),
      okText: i18n.t('genericButtons.yes'),
      cancelText: i18n.t('genericButtons.cancel'),
      onOk() {
        let userData: any = {
          data: {
            type: 'user',
            id: user.currentFullUser?.id,
            attributes: {
              removed: user.currentFullUser?.attributes?.removed ? false : true,
              newEmailFootnote: {
                email: user.currentFullUser?.attributes?.email,
                footnote: user.currentFullUser?.attributes?.footnote,
              }
            }
          },
        };

        dispatch(updateFullUserAction(userData));
        window.scrollTo({ top: 0, behavior: 'smooth' });

        setTimeout(() => {
          NavigationService.navigate(AdminRoutes.PROFILE.fullPath);
        }, 1000);
      },
    });
  };

  return (
    <div className="basicInfo">
      {user.currentFullUser && orgUnitInfo?.data && orgInfo?.data ? (
        <Form
          form={form}
          layout="vertical"
          initialValues={{
            name: user?.currentFullUser?.attributes?.name,
            email: user?.currentFullUser?.attributes?.email,
            userRemark: user?.currentFullUser?.attributes?.footnote,
            temporisRemark: user?.currentFullUser?.attributes?.adminFootnote,
            addressUser: user?.currentFullUser?.attributes?.address,
            competition: user?.currentFullUser?.attributes?.competition,
            registeredDate: user?.currentFullUser?.attributes?.createdDate ? moment(user?.currentFullUser?.attributes?.createdDate) : null,

            groupName: orgUnitInfo?.data?.attributes?.name,

            type: orgInfo?.data?.attributes?.type,
            contactNo: orgInfo?.data?.attributes?.contact,
            address: orgInfo?.data?.attributes?.streetAddress,
            city: orgInfo?.data?.attributes?.city,
            country: orgInfo?.data?.attributes?.country,
            companyName: orgInfo?.data?.attributes?.name,
            companyOib: orgInfo?.data?.attributes?.vat,
            postNo: orgInfo?.data?.attributes?.zipCode,
            companyCountry: orgInfo?.data?.attributes?.country,
          }}
          onFinish={onFinish}
        >
          {formType == 1 ?
          <>
            <Form.Item name="type">
              <Radio.Group>
                <Radio value={1}>{i18n.translate('register.form.legal')}</Radio>
                <Radio value={2}>{i18n.translate('register.form.physical')}</Radio>
              </Radio.Group>
            </Form.Item>

            <div className="section">
              <Title className="basicInfo__title" level={5}></Title>
              <Form.Item label={i18n.translate(`profile.basicInfo.name`)} name="name">
                <Input style={{ borderRadius: 0 }} />
              </Form.Item>
              <Form.Item name="email" label="E-mail" rules={[{ type: 'email', message: i18n.translate(`register.msgs.email`) },]}>
                <Input />
              </Form.Item>
              <Form.Item name="registeredDate" label={i18n.translate(`organizationInfo.registerDate`)}>
                <DatePicker disabled locale={userAuth.lang == 'hr' ? localeHR : localeEN} format={DisplayDateFormat} />
              </Form.Item>
            </div>

            <div className="section">
              <Title className="basicInfo__title" level={5}>
                {i18n.translate('profile.basicInfo.companyInfo')}
              </Title>
              <Form.Item label={i18n.translate(`profile.basicInfo.companyName`)} name="companyName">
                <TextArea autoSize style={{ borderRadius: 0, width: 540 }} />
              </Form.Item>
              {/**<Form.Item label={i18n.translate(`profile.basicInfo.groupName`)} name="groupName">
                <TextArea autoSize style={{ borderRadius: 0, width: 540 }} />
              </Form.Item>*/}
              <Form.Item
                label={i18n.translate(`profile.basicInfo.companyOib`)}
                name="companyOib"
              >
                <Input style={{ borderRadius: 0, width: 200 }} />
              </Form.Item>
              <Form.Item
                label={i18n.translate(`profile.basicInfo.contactNo`)}
                name="contactNo"
              >
                <Input style={{ borderRadius: 0, width: 200 }} />
              </Form.Item>
              <Form.Item label={i18n.translate(`profile.basicInfo.address`)} name="address">
                <TextArea style={{ borderRadius: 0, width: 200 }} />
              </Form.Item>
              <Input.Group>
                <Row justify="space-between" style={{ width: 350 }}>
                  <Col span={15}>
                    <Form.Item name="city" label={i18n.translate(`profile.basicInfo.city`)}>
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="postNo"
                      label={i18n.translate(`profile.basicInfo.postNo`)}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
              </Input.Group>
              <Form.Item name="companyCountry" label={i18n.translate(`profile.basicInfo.country`)}>
                <Input />
              </Form.Item>
            </div>

            <div className="section">
              <Title className="basicInfo__title" level={5}>
                {i18n.translate('profile.basicInfo.remark')}
              </Title>
              <Form.Item name="competition" label={i18n.translate(`profile.basicInfo.competitionShort`)}>
                <Radio.Group optionType="button">
                  <Radio value={false}>{i18n.translate('common.options.no')}</Radio>
                  <Radio value={true}>{i18n.translate('common.options.yes')}</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item name="addressUser" label={i18n.translate(`profile.subModels.deliveryAddress`)}>
                <TextArea autoSize={{ minRows: 3 }} />
              </Form.Item>
              <Form.Item name="userRemark" label={i18n.translate(`profile.basicInfo.userRemark`)}>
                <TextArea autoSize={{ minRows: 3 }} />
              </Form.Item>
              <Form.Item name="temporisRemark" label={i18n.translate(`profile.basicInfo.temporisRemark`)}>
                <TextArea autoSize={{ minRows: 3 }} />
              </Form.Item>
            </div>
          </>
          :
          <>
            <Form.Item name="type">
              <Radio.Group>
                <Radio value={1}>{i18n.translate('register.form.legal')}</Radio>
                <Radio value={2}>{i18n.translate('register.form.physical')}</Radio>
              </Radio.Group>
            </Form.Item>

            <div className="section">
              <Title className="basicInfo__title" level={5}></Title>
              <Form.Item label={i18n.translate(`profile.basicInfo.name`)} name="name">
                <Input style={{ borderRadius: 0 }} />
              </Form.Item>
              <Form.Item
                label={i18n.translate(`register.form.vat`)}
                name="companyOib"
              >
                <Input style={{ borderRadius: 0, width: 200 }} />
              </Form.Item>
              <Form.Item name="email" label="E-mail" rules={[{ type: 'email', message: i18n.translate(`register.msgs.email`) },]}>
                <Input />
              </Form.Item>
              <Form.Item
                label={i18n.translate(`profile.basicInfo.contactNo`)}
                name="contactNo"
              >
                <Input style={{ borderRadius: 0, width: 200 }} />
              </Form.Item>
              <Form.Item label={i18n.translate(`profile.basicInfo.address`)} name="address">
                <TextArea style={{ borderRadius: 0, width: 200 }} />
              </Form.Item>
              <Form.Item name="registeredDate" label={i18n.translate(`organizationInfo.registerDate`)}>
                <DatePicker disabled locale={userAuth.lang == 'hr' ? localeHR : localeEN} format={DisplayDateFormat} />
              </Form.Item>
              <Input.Group>
                <Row justify="space-between" style={{ width: 350 }}>
                  <Col span={15}>
                    <Form.Item name="city" label={i18n.translate(`profile.basicInfo.city`)}>
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="postNo"
                      label={i18n.translate(`profile.basicInfo.postNo`)}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
              </Input.Group>
              <Form.Item name="companyCountry" label={i18n.translate(`profile.basicInfo.country`)}>
                <Input />
              </Form.Item>
            </div>

            <div className="section">
              <Title className="basicInfo__title" level={5}>
                {i18n.translate('profile.basicInfo.remark')}
              </Title>
              <Form.Item name="competition" label={i18n.translate(`profile.basicInfo.competitionShort`)}>
                <Radio.Group optionType="button">
                  <Radio value={false}>{i18n.translate('common.options.no')}</Radio>
                  <Radio value={true}>{i18n.translate('common.options.yes')}</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item name="addressUser" label={i18n.translate(`profile.subModels.deliveryAddress`)}>
                <TextArea autoSize={{ minRows: 3 }} />
              </Form.Item>
              <Form.Item name="userRemark" label={i18n.translate(`profile.basicInfo.userRemark`)}>
                <TextArea autoSize={{ minRows: 3 }} />
              </Form.Item>
              <Form.Item name="temporisRemark" label={i18n.translate(`profile.basicInfo.temporisRemark`)}>
                <TextArea autoSize={{ minRows: 3 }} />
              </Form.Item>
            </div>
          </>}

          <Input.Group>
            <Row gutter={10} style={{ width: '100%', margin: 0 }}>
              <Col>
                {user.currentFullUser?.attributes?.roles !== UserRoleEnum.ADMIN && <Button danger={!user.currentFullUser?.attributes?.removed} onClick={showConfirm} type="default">
                  {i18n.translate(user.currentFullUser?.attributes?.removed ? `genericButtons.activate` : `genericButtons.deactivate`)}
                </Button>}
              </Col>
              <Col>
              <Link to={AdminRoutes.PROFILE.fullPath} key={AdminRoutes.PROFILE.path}>
                <Button>{i18n.translate(`genericButtons.cancel`)}</Button>
              </Link>
              </Col>
              <Col>
                <Button type="primary" htmlType="submit">{i18n.translate(`genericButtons.save`)}</Button>
              </Col>
            </Row>
          </Input.Group>
        </Form>
      ) : null}
    </div>
  );
}
