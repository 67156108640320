export enum ResponseConstants {
  SET_LOADER = 'ResponseConstants_SET_LOADER',
  GET_ALL_SUCCESS = 'ResponseConstants_GET_ALL_SUCCESS',
  GET_SINGLE_SUCCESS = 'ResponseConstants_GET_SINGLE_SUCCESS',
  CREATE_SUCCESS = 'ResponseConstants_CREATE_SUCCESS',
  UPDATE_SUCCESS = 'ResponseConstants_UPDATE_SUCCESS',
  CLEAR_CURRENT = 'ResponseConstants_CLEAR_CURRENT',
  CLEAR_LIST = 'ResponseConstants_CLEAR_LIST',
  DELETE = 'ResponseConstants_DELETE',
  ERROR = 'ResponseConstants_ERROR'
}
