import React, { useEffect, useState } from 'react';
import {
  ChildrenTagScreenResponseInterface,
  FileFormatEnum,
  FileService,
  getScreenTagsByTypeAction,
  i18n,
  NavigationService,
  SelectTypeEnum,
  StoreStateInterface,
  TagScreenResponseInterface,
  TagScreenStateInterface,
  clearProMaterialAction,
  createProMaterialAction,
  deleteProMaterialAction,
  ProMaterialStateInterface,
  updateProMaterialAction,
  getSingleProMaterialAction,
  FilterNameEnumHR,
  ProMaterialService,
} from '../../../../../common';
import { InboxOutlined, DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import {
  Button,
  DatePicker,
  Divider,
  Form,
  Input,
  message,
  Modal,
  notification,
  Radio,
  Select,
  TreeSelect,
  Upload,
  UploadFile,
  UploadProps,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { AdminRoutes } from '../../../_router/admin.routes';
import localeHR from 'antd/es/date-picker/locale/hr_HR';
import localeEN from 'antd/es/date-picker/locale/en_GB';
import moment from 'moment';
import { RangePickerProps } from 'antd/lib/date-picker';

const { SHOW_ALL } = TreeSelect;

const { TextArea } = Input;

const { Dragger } = Upload;

const { confirm } = Modal;

function ProMaterialsCrudPageComponent() {
  const location = useLocation();
  const path = location.pathname.split('/');
  const id = parseInt(path[path.length - 1]);
  const isNew = isNaN(id);

  const [form] = Form.useForm();

  const dispatch = useDispatch();
  const data: ProMaterialStateInterface = useSelector((state: StoreStateInterface) => state.proMaterial);
  const tags: TagScreenStateInterface = useSelector((state: StoreStateInterface) => state.tagScreen);
  const userAuth = useSelector((state: StoreStateInterface) => state.auth);

  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [fileListEng, setFileListEng] = useState<UploadFile[]>([]);
  const [pdfLinkList, setPdfLinkList] = useState<string[]>([]);
  const [pdfLinkListEng, setPdfLinkListEng] = useState<string[]>([]);

  const arrayHr: UploadFile[] = [];
  const arrayEng: UploadFile[] = [];

  useEffect(() => {
    dispatch(clearProMaterialAction());
    dispatch(getScreenTagsByTypeAction('pro_material'));
    if (!isNew) dispatch(getSingleProMaterialAction(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isNew) {
      if (data?.promaterials?.included?.length) {
        let materialsLinkArrayEng: string[] = [];
        let materialsLinkArrayHr: string[] = [];
        data?.promaterials?.included?.forEach((material, index) => {
          if (material?.attributes?.pdfUuidEng) {
            FileService.list(material?.attributes?.pdfUuidEng).subscribe(
              (response: any) => {
                passFileListEng({ name: response.fileName, uid: response.uuid, response: {position: index} });
              },
              (error: Error) => {
                console.log(error);
              }
            );
          }
          if (material.attributes?.pdfUuidHr) {
            FileService.list(material.attributes?.pdfUuidHr).subscribe(
              (response: any) => {
                passFileList({ name: response.fileName, uid: response.uuid, response: {position: index} });
              },
              (error: Error) => {
                console.log(error);
              }
            );
          }
          if (material.attributes?.engLink) {
            materialsLinkArrayEng.push(material.attributes?.engLink);
          }
          if (material.attributes?.hrLink) {
            materialsLinkArrayHr.push(material.attributes?.hrLink);
          }
        });
        setPdfLinkList(materialsLinkArrayHr);
        setPdfLinkListEng(materialsLinkArrayEng);
        materialsLinkArrayHr?.forEach((hrLink, index) => {
          form.setFieldValue(`hrLink${index}`, hrLink);
        });
        materialsLinkArrayEng?.forEach((enLink, index) => {
          form.setFieldValue(`engLink${index}`, enLink);
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.currentProMaterial?.attributes]);

  const onFileDownload = (pdfUuid: string) => {
    FileService.temp(pdfUuid).subscribe(
      (response: any) => {
        if (response.url) {
          notification['success']({ message: i18n.translate('api.downloadSuccessMessage'), duration: 2 });
          var link = document.createElement('a');
          link.href = response.url;
          link.download = 'file.pdf';
          link.dispatchEvent(new MouseEvent('click'));
        } else {
          notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
        }
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
      }
    );
  };

  const onFileDownloadEng = (pdfUuidEng: string) => {
    FileService.temp(pdfUuidEng).subscribe(
      (response: any) => {
        if (response.url) {
          notification['success']({ message: i18n.translate('api.downloadSuccessMessage'), duration: 2 });
          var link = document.createElement('a');
          link.href = response.url;
          link.download = 'file.pdf';
          link.dispatchEvent(new MouseEvent('click'));
        } else {
          notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
        }
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
      }
    );
  };

  const beforeUpload = (file: any) => {
    if (!FileFormatEnum.ALLOWED.split(',').includes(file.type)) {
      message.error(i18n.translate('genericFileUpload.formatError', { filename: file.name }));
      return false;
    } else {
      return true;
    }
  };

  const onFileListChange = (info: any) => {
    if (info.file?.status && info.file?.status == 'removed') {
      if (!isNew) {
        let foundId = data?.promaterials?.included?.find(x => x.attributes?.pdfUuidHr == info.file.uid)?.id;

        if (!foundId) return;

        let assetObject = data?.promaterials?.included?.find(x => x.id == foundId);

        if (assetObject?.attributes?.pdfUuidEng) {
          let langAttributes = {
            pdfUuidHr: '',
            hrLink: ''
          };
          let proMaterialAsset = {
            data: {
              type: 'pro_material_assets',
              id: foundId,
              attributes: langAttributes
            },
          };

          ProMaterialService.updateSingleAsset(proMaterialAsset).subscribe(
            (response: any) => {
              dispatch(getSingleProMaterialAction(id));
            },
            (error: Error) => {
              dispatch(getSingleProMaterialAction(id));
            }
          );
        }
        else {
          ProMaterialService.deleteSingleAsset(foundId).subscribe(
            (response: any) => {
              dispatch(getSingleProMaterialAction(id));
            },
            (error: Error) => {
              dispatch(getSingleProMaterialAction(id));
            }
          );
        }
      } else {
        setFileList(info.fileList);
        return;
      }
      setFileList(info.fileList);
    }
    if (!FileFormatEnum.ALLOWED.split(',').includes(info.file.type)) return;

    if (info.file.status === 'done') {
      if (!isNew) {
        const createNew = fileList?.length > fileListEng?.length;

        if (createNew) {
          let proMaterialAsset = {
            data: {
              type: 'pro_material_assets',
              attributes: {
                pdfUuidHr: info?.file?.response[0]?.uuid,
                hrLink: ''
              },
              relationships: {
                proMaterial: {
                  data: {
                    id: id,
                    type: 'professional_material'
                  }
                },
              },
            },
          };
  
          ProMaterialService.createSingleAsset(proMaterialAsset).subscribe(
            (response: any) => {
              dispatch(getSingleProMaterialAction(id));
            },
            (error: Error) => {
              dispatch(getSingleProMaterialAction(id));
            }
          );
        } else {
          const index = fileList?.length ? fileList?.length - 1 : 0;
          const foundId = data?.currentProMaterial?.relationships?.proMaterialAssets?.data[index]?.id;

          let proMaterialAsset = {
            data: {
              type: 'pro_material_assets',
              id: foundId,
              attributes: {
                pdfUuidHr: info?.file?.response[0]?.uuid,
                hrLink: ''
              },
            },
          };
  
          ProMaterialService.updateSingleAsset(proMaterialAsset).subscribe(
            (response: any) => {
              dispatch(getSingleProMaterialAction(id));
            },
            (error: Error) => {
              dispatch(getSingleProMaterialAction(id));
            }
          );
        }
      }
      message.success(i18n.translate('genericFileUpload.uploadSuccess', { filename: info.file.name }));
    } else if (info.file.status === 'error') {
      message.error(i18n.translate('genericFileUpload.uploadFailed', { filename: info.file.name }));
    }
    setFileList(info.fileList);
  };

  const onFileListChangeEng = (info: any) => {
    if (info.file?.status && info.file?.status == 'removed') {
      if (!isNew) {
        let foundId = data?.promaterials?.included?.find(x => x.attributes?.pdfUuidEng == info.file.uid)?.id;

        if (!foundId) return;

        let assetObject = data?.promaterials?.included?.find(x => x.id == foundId);

        if (assetObject?.attributes?.pdfUuidHr) {
          let langAttributes = {
            pdfUuidEng: '',
            engLink: ''
          };
          let proMaterialAsset = {
            data: {
              type: 'pro_material_assets',
              id: foundId,
              attributes: langAttributes
            },
          };

          ProMaterialService.updateSingleAsset(proMaterialAsset).subscribe(
            (response: any) => {
              dispatch(getSingleProMaterialAction(id));
            },
            (error: Error) => {
              dispatch(getSingleProMaterialAction(id));
            }
          );
        }
        else {
          ProMaterialService.deleteSingleAsset(foundId).subscribe(
            (response: any) => {
              dispatch(getSingleProMaterialAction(id));
            },
            (error: Error) => {
              dispatch(getSingleProMaterialAction(id));
            }
          );
        }
      } else {
        setFileListEng(info.fileList);
        return;
      }
      setFileListEng(info.fileList);
    }
    if (!FileFormatEnum.ALLOWED.split(',').includes(info.file.type)) return;

    if (info.file.status === 'done') {
      if (!isNew) {
        const createNew = fileListEng?.length > fileList?.length;

        if (createNew) {
          let proMaterialAsset = {
            data: {
              type: 'pro_material_assets',
              attributes: {
                pdfUuidEng: info?.file?.response[0]?.uuid,
                engLink: ''
              },
              relationships: {
                proMaterial: {
                  data: {
                    id: id,
                    type: 'professional_material'
                  }
                },
              },
            },
          };

          ProMaterialService.createSingleAsset(proMaterialAsset).subscribe(
            (response: any) => {
              dispatch(getSingleProMaterialAction(id));
            },
            (error: Error) => {
              dispatch(getSingleProMaterialAction(id));
            }
          );
        } else {
          const index = fileListEng?.length ? fileListEng?.length - 1 : 0; 
          const foundId = data?.currentProMaterial?.relationships?.proMaterialAssets?.data[index]?.id;

          let proMaterialAsset = {
            data: {
              type: 'pro_material_assets',
              id: foundId,
              attributes: {
                pdfUuidEng: info?.file?.response[0]?.uuid,
                engLink: ''
              },
            },
          };
  
          ProMaterialService.updateSingleAsset(proMaterialAsset).subscribe(
            (response: any) => {
              dispatch(getSingleProMaterialAction(id));
            },
            (error: Error) => {
              dispatch(getSingleProMaterialAction(id));
            }
          );
        }
      }
      message.success(i18n.translate('genericFileUpload.uploadSuccess', { filename: info.file.name }));
    } else if (info.file.status === 'error') {
      message.error(i18n.translate('genericFileUpload.uploadFailed', { filename: info.file.name }));
    }
    setFileListEng(info.fileList);
  };

  const props: UploadProps = {
    name: 'files',
    accept: FileFormatEnum.ALLOWED,
    beforeUpload: (file: any) => beforeUpload(file),
    multiple: true,
    action: process.env.REACT_APP_FILE_UPLOAD,
    onChange: (info: any) => onFileListChange(info),
    fileList: fileList,
    defaultFileList: fileList,
  };

  const propsEng: UploadProps = {
    name: 'files',
    accept: FileFormatEnum.ALLOWED,
    beforeUpload: (file: any) => beforeUpload(file),
    multiple: true,
    action: process.env.REACT_APP_FILE_UPLOAD,
    onChange: (info: any) => onFileListChangeEng(info),
    fileList: fileListEng,
    defaultFileList: fileListEng,
  };

  const checkForNested = (tag: TagScreenResponseInterface) => {
    let nested = tag.children?.find((child) => child.nestedChildren?.length && child.nestedChildren?.length > 0);
    if (nested) return true;
    else return false;
  };

  // eslint-disable-next-line arrow-body-style
  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    // Can not select year after current year or before 2000
    return (current && current > moment().endOf('year')) || current < moment('1999').endOf('year');
  };

  const onFinishProMaterial = (values: any) => {
    const getTags = () => {
      let array: Array<{ id: Number; type: string }> = [];
      tags.tagScreens?.forEach((tag) => {
        const value = values[tag.filter.name as keyof any];
        if (value) {
          if (tag?.selectType === SelectTypeEnum.YEAR) {
            const childTag = tag.children?.find((child) => child.tag.name == moment(value).format('YYYY'));
            if (childTag) array.push({ id: childTag.tag.id as number, type: 'tag' });
          }
          if (tag?.selectType === SelectTypeEnum.SELECT || tag?.selectType === SelectTypeEnum.BOOLEAN) {
            const childTag = tag.children?.find((child) => child.tag.id == value);
            if (childTag) array.push({ id: childTag.tag.id as number, type: 'tag' });
          }
          if (tag?.selectType === SelectTypeEnum.MULTISELECT) {
            const value = values[tag.filter.name as keyof any];
            if (value && value.length && value.length > 0) {
              value.forEach((id: any) => {
                if (id) array.push({ id: id, type: 'tag' });
              });
            }
          }
        }
      });

      return array;
    };

    const getYear = () => {
      try {
        return values['Godina'].format('YYYY');
      } catch (error) {
        
      }
      return '';
    }

    let proMaterialData: any = {};

    if (isNew) {
      let proMaterialAssetArrayHr: {pdfUuid: string, link: string}[] = [];
      let proMaterialAssetArrayEng: {pdfUuid: string, link: string}[] = [];
      let proMaterialFinalArray: {pdfUuidEng: string, pdfUuidHr: string, engLink: string, hrLink: string}[] = [];

      fileList?.forEach((file, index) => {
        proMaterialAssetArrayHr.push({
          pdfUuid: file.response[0]?.uuid,
          link: values[`hrLink${index}`],
        });
      });

      fileListEng?.forEach((file, index) => {
        proMaterialAssetArrayEng.push({
          pdfUuid: file.response[0]?.uuid,
          link: values[`engLink${index}`],
        });
      });

      for(var i = 0; i < Math.max(fileList?.length, fileListEng?.length); i++){
        proMaterialFinalArray.push({
          pdfUuidEng: proMaterialAssetArrayEng[i]?.pdfUuid,
          pdfUuidHr: proMaterialAssetArrayHr[i]?.pdfUuid,
          engLink: proMaterialAssetArrayEng[i]?.link,
          hrLink: proMaterialAssetArrayHr[i]?.link,
        });
      }

      proMaterialData = {
        data: {
          type: 'professional_material',
          id: data.currentProMaterial?.id,
          attributes: {
            publisher: values.publisher,
            materialTitle: values.materialTitle,
            summary: values.summary,
            comment: values.comment,
            proMaterialAsset: proMaterialFinalArray,
            year: getYear()
          },
          relationships: {
            tags: {
              data: getTags(),
            },
          },
        },
      };
    } else {
      let proMaterialAssetArray: { id: number; proMaterial: { id: number; }; hrLink: string; engLink: string; }[] = [];
      let materialsLinkArrayEng: string[] = [];
      let materialsLinkArrayHr: string[] = [];
      Object.entries(values).forEach((element: any) => {
        if (element[0].includes('Link')) {
          if (element[0].includes('hrLink')) {
            materialsLinkArrayHr.push(element[1]);
          }
          if (element[0].includes('engLink')) {
            materialsLinkArrayEng.push(element[1]);
          }
        }
      });
      const maxLength = Math.max(materialsLinkArrayEng?.length, materialsLinkArrayHr?.length);
      for (let i = 0; i < maxLength; i++) {
        let foundId = data?.promaterials?.included && data?.promaterials?.included[i] ? data?.promaterials?.included[i]?.id : 0;
        let foundPdfUuidHr = data?.promaterials?.included && data?.promaterials?.included[i] ? data?.promaterials?.included[i]?.attributes?.pdfUuidHr : '';
        let foundPdfUuidEng = data?.promaterials?.included && data?.promaterials?.included[i] ? data?.promaterials?.included[i]?.attributes?.pdfUuidEng : '';
        let proMaterialAsset = {
          id: foundId ? foundId : 0,
          proMaterial: {
            id: id
          },
          hrLink: materialsLinkArrayHr[i],
          engLink: materialsLinkArrayEng[i],
          pdfUuidHr: foundPdfUuidHr,
          pdfUuidEng: foundPdfUuidEng,
        }
        if (proMaterialAsset.id > 0) proMaterialAssetArray.push(proMaterialAsset);
      }
      proMaterialData = {
        data: {
          type: 'professional_material',
          id: data.currentProMaterial?.id,
          attributes: {
            publisher: values.publisher,
            materialTitle: values.materialTitle,
            summary: values.summary,
            comment: values.comment,
            proMaterialAsset: proMaterialAssetArray,
            year: getYear()
          },
          relationships: {
            tags: {
              data: getTags(),
            },
          },
        },
      };
    }
    isNew ? dispatch(createProMaterialAction(proMaterialData)) : dispatch(updateProMaterialAction(proMaterialData));
    window.scrollTo({ top: 0, behavior: 'smooth' });

    setTimeout(() => {
      NavigationService.navigate(AdminRoutes.PRO_MATERIALS.fullPath);
    }, 1000);
  };

  const onFinishProMaterialFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const setTreeData = (children: Array<ChildrenTagScreenResponseInterface>) => {
    let values = children.map((child) => ({
      title: child.tag.name,
      value: child.tag.id,
      key: child.tag.id,
      children: child.nestedChildren?.map((nestedChild) => ({
        title: nestedChild.tag.name,
        value: nestedChild.tag.id,
        key: nestedChild.tag.id,
        children: nestedChild.nestedChildren?.map((nestedLvl2Child) => ({
          title: nestedLvl2Child.tag.name,
          value: nestedLvl2Child.tag.id,
          key: nestedLvl2Child.tag.id,
        })),
      })),
    }));
    return values;
  };

  const getDisplayConditions = () => {
    if (isNew) {
      return tags.tagScreens && tags.tagScreens.length > 0;
    } else {
      return tags.tagScreens && tags.tagScreens.length > 0 && data.currentProMaterial;
    }
  };

  const getFilter = (field: string) => {
    let filter;
    let tag = tags.tagScreens?.find((tag) => tag.filter.name == field);
    if (tag) {
      let array: number[] = [];
      data.currentProMaterial?.attributes?.tagIds?.forEach((id) => {
        tag?.children?.forEach((child) => {
          if (id === child.tag.id) {
            if (tag?.selectType === SelectTypeEnum.YEAR) {
              filter = moment(child.tag.name);
            }
            if (tag?.selectType === SelectTypeEnum.SELECT || tag?.selectType === SelectTypeEnum.BOOLEAN) {
              filter = child.tag.id;
            }
            if (tag?.selectType === SelectTypeEnum.MULTISELECT) {
              array.push(child.tag.id);
            }
          }
        });
      });

      if (tag?.selectType === SelectTypeEnum.MULTISELECT) filter = array;
    }
    return filter;
  };

  const loadInitialValues = () => {
    let values: any = {
      publisher: data.currentProMaterial?.attributes?.publisher,
      materialTitle: data.currentProMaterial?.attributes?.materialTitle,
      summary: data.currentProMaterial?.attributes?.summary,
      comment: data.currentProMaterial?.attributes?.comment,
    };

    tags.tagScreens?.forEach((tag) => {
      values[tag.filter.name as keyof any] = getFilter(tag.filter.name);
    });

    return values;
  };

  const loadDefaultValues = () => {
    let values: any = {};

    tags.tagScreens?.forEach((tag) => {
      if (tag.filter.name == FilterNameEnumHR.ZJN) {
        values[tag.filter.name as keyof any] = [tag.children?.length ? tag.children[0].tag.id : []];
      }
    });

    return values;
  };

  const clearCurrentProMaterial = () => {
    dispatch(clearProMaterialAction());
  };

  const passFileList = (file: UploadFile) => {
    arrayHr.push(file);
    const array = [...arrayHr].sort((a, b) => a.response?.position - b.response?.position);
    setFileList([...array]);
    form.setFieldValue('pdf', [...array]);
  };

  const passFileListEng = (file: UploadFile) => {
    arrayEng.push(file);
    const array = [...arrayEng].sort((a, b) => a.response?.position - b.response?.position);
    setFileListEng([...array]);
    form.setFieldValue('pdfEng', [...array]);
  };

  const showConfirm = () => {
    confirm({
      title: i18n.t('proMaterials.confirmDelete'),
      icon: <ExclamationCircleOutlined />,
      content: i18n.t('common.cantUndo'),
      okText: i18n.t('genericButtons.yes'),
      cancelText: i18n.t('genericButtons.cancel'),
      onOk() {
        dispatch(deleteProMaterialAction(id));
        window.scrollTo({ top: 0, behavior: 'smooth' });

        setTimeout(() => {
          NavigationService.navigate(AdminRoutes.PRO_MATERIALS.fullPath);
        }, 1000);
      },
    });
  };

  const returnComponent = (tag: TagScreenResponseInterface) => {
    let component = null;
    if (tag.selectType == SelectTypeEnum.YEAR) {
      component = (
        <DatePicker
          picker="year"
          disabledDate={disabledDate}
          locale={userAuth.lang == 'hr' ? localeHR : localeEN}
          format={'YYYY.'}
        />
      );
    }

    if (tag.selectType == SelectTypeEnum.BOOLEAN) {
      component = (
        <Radio.Group>
          <Radio.Button value={tag.children?.length ? tag.children[0].tag.id : 0}>
            {tag.children?.length ? tag.children[0].tag.name : ''}
          </Radio.Button>
          <Radio.Button value={tag.children?.length ? tag.children[1].tag.id : 1}>
            {tag.children?.length ? tag.children[1].tag.name : ''}
          </Radio.Button>
        </Radio.Group>
      );
    }

    if (tag.selectType == SelectTypeEnum.SELECT || tag.selectType == SelectTypeEnum.MULTISELECT) {
      if (checkForNested(tag)) {
        component = (
          <TreeSelect
            treeData={setTreeData(tag.children as Array<ChildrenTagScreenResponseInterface>)}
            treeCheckable
            showCheckedStrategy={SHOW_ALL}
            maxTagCount="responsive"
            placeholder={i18n.t('genericFormMsg.placeholders.select')}
          />
        );
      } else {
        component = (
          <Select
            mode={tag.selectType == SelectTypeEnum.MULTISELECT ? 'multiple' : undefined}
            maxTagCount={tag.selectType == SelectTypeEnum.MULTISELECT ? 'responsive' : undefined}
            allowClear
          >
            {tag.children?.length &&
              tag.children.map((child) => (
                <Select.Option key={child.tag.name} value={child.tag.id}>
                  {child.tag.name}
                </Select.Option>
              ))}
          </Select>
        );
      }
    }

    return component;
  };

  return (
    <div className="proMaterialsCrud w100-h100">
      {getDisplayConditions() && (
        <Form
          form={form}
          layout="vertical"
          name="proMaterial"
          size="large"
          scrollToFirstError
          initialValues={data.currentProMaterial && !isNew ? loadInitialValues() : loadDefaultValues()}
          onFinish={onFinishProMaterial}
          onFinishFailed={onFinishProMaterialFailed}
          autoComplete="off"
          requiredMark={false}
        >
          <div className="proMaterialsCrud__admin">
            <div className="title">
              {i18n.translate(isNew ? 'proMaterials.crudTitleAdd' : 'proMaterials.crudTitleUpdate')}
            </div>
            <div className="buttons">
              <Link to={AdminRoutes.PRO_MATERIALS.fullPath} key={AdminRoutes.PRO_MATERIALS.path}>
                <Button
                  onClick={clearCurrentProMaterial}
                  className="button"
                  size="large"
                  style={{ fontSize: 16, fontWeight: 700, borderRadius: 0, minWidth: 193, marginRight: 16 }}
                >
                  {i18n.translate('genericButtons.cancel')}
                </Button>
              </Link>
              <Button
                className="button"
                htmlType="submit"
                type="primary"
                size="large"
                style={{ fontSize: 16, fontWeight: 700, borderRadius: 0, minWidth: 193 }}
              >
                {i18n.translate('genericButtons.save')}
              </Button>
            </div>
          </div>
          <Divider />
          <div className="proMaterialsCrud__form">
            <div className="subtitle">
              <span className="text">{i18n.translate('proMaterials.form.sections.info')}</span>
            </div>

            <Form.Item
              label={<span className="text-bold">{i18n.translate(`proMaterials.form.fields.materialTitle`)}</span>}
              name="materialTitle"
              rules={[{ required: true, message: i18n.translate(`genericFormMsg.required`) }]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 12 }}
            >
              <Input style={{ borderRadius: 0 }} />
            </Form.Item>

            <Form.Item
              label={<span className="text-bold">{i18n.translate(`proMaterials.form.fields.publisher`)}</span>}
              name="publisher"
              rules={[{ required: true, message: i18n.translate(`genericFormMsg.required`) }]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 12 }}
            >
              <Input style={{ borderRadius: 0 }} />
            </Form.Item>

            <Form.Item
              label={<span className="text-bold">{i18n.translate(`proMaterials.form.fields.summary`)}</span>}
              name="summary"
              rules={[{ required: true, message: i18n.translate(`genericFormMsg.required`) }]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 12 }}
            >
              <TextArea style={{ borderRadius: 0 }} autoSize={{ minRows: 8 }} />
            </Form.Item>

            <Form.Item
              label={<span className="text-bold">{i18n.translate(`proMaterials.form.fields.comment`)}</span>}
              name="comment"
              //rules={[{ required: true, message: i18n.translate(`genericFormMsg.required`) }]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 12 }}
            >
              <TextArea style={{ borderRadius: 0 }} autoSize={{ minRows: 8 }} />
            </Form.Item>

            <div className="subtitle">
              <span className="text">{i18n.translate('proMaterials.form.sections.filters')}</span>
            </div>

            {/** OVDJE ISPOD SVI FILTERI TREBAJU BITI GENERIČKI */}

            {tags.tagScreens?.map((tag) => (
              <Form.Item
                label={<span className="text-bold">{`${tag.filter.name}`}</span>}
                name={tag.filter.name}
                key={tag.filter.name}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 8 }}
              >
                {returnComponent(tag)}
              </Form.Item>
            ))}

            {/** GENERIC FILTERI END */}

            <div className="subtitle">
              <span className="text">{i18n.translate('proMaterials.form.sections.upload')}</span>
            </div>

            <Form.Item
              label={<span className="text-bold">{i18n.translate(`proMaterials.form.fields.pdf`)}</span>}
              name="pdf"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 10 }}
            >
              <Dragger {...props}>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">{i18n.translate(`proMaterials.form.upload.text`)}</p>
              </Dragger>
            </Form.Item>

            {/**fileList && fileList.length > 0 && (
              <div>
                <Button type="link" style={{ color: 'red', marginBottom: 8 }} onClick={onFileRemove}>
                  {i18n.translate(`genericButtons.delete`)}
                </Button>
                <Button type="link" style={{ marginLeft: 8, marginBottom: 8 }} onClick={onFileDownload}>
                  {i18n.translate(`genericButtons.download`)}
                </Button>
              </div>
            )*/}

            <Form.Item
              label={<span className="text-bold">{i18n.translate(`proMaterials.form.fields.pdfEng`)}</span>}
              name="pdfEng"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 10 }}
            >
              <Dragger {...propsEng}>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">{i18n.translate(`proMaterials.form.upload.text`)}</p>
              </Dragger>
            </Form.Item>

            {/**fileListEng && fileListEng.length > 0 && (
              <div>
                <Button type="link" style={{ color: 'red', marginBottom: 8 }} onClick={onFileRemoveEng}>
                  {i18n.translate(`genericButtons.delete`)}
                </Button>
                <Button type="link" style={{ marginLeft: 8, marginBottom: 8 }} onClick={onFileDownloadEng}>
                  {i18n.translate(`genericButtons.download`)}
                </Button>
              </div>
            )*/}

            <div className="subtitle">
              <span className="text">{i18n.translate('proMaterials.form.fields.hrLinks')}</span>
            </div>

            {fileList && fileList.length > 0 ? (
            fileList.map((fileHr, index) => (
            <Form.Item
              key={'hrLinkKey' + index}
              label={<span className="text-bold">{`${fileHr?.name} - ${i18n.translate('proMaterials.form.fields.hrLink')}`}</span>}
              name={"hrLink" + index}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 12 }}
              rules={[{ required: true, message: i18n.translate(`genericFormMsg.required`) }]}
            >
              <Input style={{ borderRadius: 0 }} />
            </Form.Item>
            ))) : <div style={{opacity: 0.5, marginBottom: 8}}>{i18n.translate('proMaterials.form.fields.pleaseUpload')}</div>}

            <div className="subtitle">
              <span className="text">{i18n.translate('proMaterials.form.fields.engLinks')}</span>
            </div>

            {fileListEng && fileListEng.length > 0 ? (
            fileListEng.map((fileEng, index) => (
            <Form.Item
              key={'engLinkKey' + index}
              label={<span className="text-bold">{`${fileEng?.name} - ${i18n.translate('proMaterials.form.fields.engLink')}`}</span>}
              name={"engLink" + index}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 12 }}
              rules={[{ required: true, message: i18n.translate(`genericFormMsg.required`) }]}
            >
              <Input style={{ borderRadius: 0 }} />
            </Form.Item>
            ))) : <div style={{opacity: 0.5, marginBottom: 8}}>{i18n.translate('proMaterials.form.fields.pleaseUpload')}</div>}
          </div>
          <div className="proMaterialsCrud__bottomButtons">
            <div>
              {!isNew && (
                <Button
                danger
                  onClick={showConfirm}
                  className="button"
                  size="large"
                  icon={<DeleteOutlined />}
                  style={{ fontSize: 16, fontWeight: 700, borderRadius: 0, minWidth: 193 }}
                >
                  {i18n.translate('genericButtons.delete')}
                </Button>
              )}
            </div>
            <div>
              <Link to={AdminRoutes.PRO_MATERIALS.fullPath} key={AdminRoutes.PRO_MATERIALS.path}>
                <Button
                  onClick={clearCurrentProMaterial}
                  className="button"
                  size="large"
                  style={{ fontSize: 16, fontWeight: 700, borderRadius: 0, minWidth: 193, marginRight: 16 }}
                >
                  {i18n.translate('genericButtons.cancel')}
                </Button>
              </Link>
              <Button
                className="button"
                htmlType="submit"
                type="primary"
                size="large"
                style={{ fontSize: 16, fontWeight: 700, borderRadius: 0, minWidth: 193 }}
              >
                {i18n.translate('genericButtons.save')}
              </Button>
            </div>
          </div>
        </Form>
      )}
    </div>
  );
}

export default ProMaterialsCrudPageComponent;
