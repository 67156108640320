import { Dispatch } from 'redux';
import { notification } from 'antd';
import { CourtConstants } from './courtEU.constants';
import { CourtService } from '../../api/api.service';
import { CourtResponseInterfaceAll, CourtResponseInterfaceSingle } from '../../interfaces';
import { i18n } from '../../services';

export function getAllCourtsAction(params?: any, filter?: any, sort?: any, pagination?: any) {
  return (dispatch: Dispatch) => {
    return CourtService.getAll(params, filter, sort, pagination).subscribe(
      (response: CourtResponseInterfaceAll) => {
        dispatch({
          type: CourtConstants.GET_ALL_SUCCESS,
          payload: response,
        });
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });

        dispatch({
          type: CourtConstants.ERROR,
          payload: error,
        });
      }
    );
  };
}

export function getSingleCourtAction(id: number) {
  return (dispatch: Dispatch) => {
    return CourtService.getSingle(id).subscribe(
      (response: CourtResponseInterfaceSingle) => {
        dispatch({
          type: CourtConstants.GET_SINGLE_SUCCESS,
          payload: response,
        });
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });

        dispatch({
          type: CourtConstants.ERROR,
          payload: error,
        });
      }
    );
  };
}

export function createCourtAction(body: CourtResponseInterfaceSingle) {
  return (dispatch: Dispatch) => {
    return CourtService.create(body).subscribe(
      (response: CourtResponseInterfaceSingle) => {
        notification['success']({ message: i18n.translate('api.successMessage'), duration: 2 });

        dispatch({
          type: CourtConstants.CREATE_SUCCESS,
          payload: response,
        });
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });

        dispatch({
          type: CourtConstants.ERROR,
          payload: error,
        });
      }
    );
  };
}

export function updateCourtAction(body: CourtResponseInterfaceSingle) {
  return (dispatch: Dispatch) => {
    return CourtService.update(body).subscribe(
      (response: CourtResponseInterfaceSingle) => {
        notification['success']({ message: i18n.translate('api.successMessage'), duration: 2 });

        dispatch({
          type: CourtConstants.UPDATE_SUCCESS,
          payload: response,
        });
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });

        dispatch({
          type: CourtConstants.ERROR,
          payload: error,
        });
      }
    );
  };
}

export function deleteCourtAction(id: number) {
  return (dispatch: Dispatch) => {
    return CourtService.delete(id).subscribe(
      (response: any) => {
        dispatch({
          type: CourtConstants.DELETE
        });
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });

        dispatch({
          type: CourtConstants.ERROR,
          payload: error,
        });
      }
    );
  };
}

export function clearCourtAction() {
  return (dispatch: Dispatch) => {
    dispatch({ type: CourtConstants.CLEAR_CURRENT });
  };
}

export function clearCourtListAction() {
  return (dispatch: Dispatch) => {
    dispatch({ type: CourtConstants.CLEAR_LIST });
  };
}
