import { Dispatch } from 'redux';
import { notification } from 'antd';
import { TagScreenConstants } from './tagScreen.constants';

import { TagScreenService } from '../../api/api.service';
import { TagScreenResponseInterface } from '../../interfaces';
import { i18n } from '../../services';

export function getScreenTagsByTypeAction(type: string) {
  return (dispatch: Dispatch) => {
    return TagScreenService.getScreenTagsByType(type).subscribe(
      (response: Array<TagScreenResponseInterface>) => {
        dispatch({
          type: TagScreenConstants.GET_SCREEN_TAGS_BY_TYPE_SUCCESS,
          payload: response,
        });
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });

        dispatch({
          type: TagScreenConstants.ERROR,
          payload: error,
        });
      }
    );
  };
}

export function clearFiltersAction() {
  return (dispatch: Dispatch) => {
    dispatch({ type: TagScreenConstants.CLEAR });
  };
}
