import React, { useEffect, useState } from 'react';
import {
  clearNoteAction,
  clearOpinionAction,
  clearOpinionListAction,
  FilterComponent,
  getAllOpinionsAction,
  getScreenTagsByTypeAction,
  i18n,
  InstitutionalOpinionCardComponent,
  LicenceInterface,
  LicenceResponseInterfaceSingle,
  LicenceService,
  LicenceUserResponseInterfaceAll,
  LicenceUserService,
  NoDataComponent,
  NoteResponseInterfaceAll,
  NoteService,
  OpinionEsResponseInterface,
  OpinionInterface,
  OpinionService,
  OpinionStateInterface,
  SorterInterface,
  StoreStateInterface,
  TagScreenStateInterface,
} from '../../../common';
import { CaretRightOutlined, HomeOutlined } from '@ant-design/icons';
import { Breadcrumb, Button, Collapse, notification, Pagination, PaginationProps, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { AdminRoutes } from '../_router/admin.routes';

const { Text } = Typography;
const { Panel } = Collapse;

function InstitutionalOpinionsPageComponent() {
  const dispatch = useDispatch();
  const userAuth = useSelector((state: StoreStateInterface) => state.auth);
  const data: OpinionStateInterface = useSelector((state: StoreStateInterface) => state.opinion);
  const tags: TagScreenStateInterface = useSelector((state: StoreStateInterface) => state.tagScreen);

  const [filterValues, setFilterValues] = useState();
  const [esData, setEsData] = useState<OpinionEsResponseInterface>();

  const [keyword, setKeyword] = useState('');
  const [allOpinions, setAllOpinions] = useState(true);
  const [exactMatch, setExactMatch] = useState<boolean>(true);

  const [esSearch, setEsSearch] = useState(true);

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  
  const [licenceData, setLicenceData] = useState<LicenceInterface>();
  const [noteData, setNoteData] = useState<NoteResponseInterfaceAll>();

  useEffect(() => {
    clearNoteAction();
    NoteService.getAll(userAuth.user?.id, false).subscribe(
      (response: NoteResponseInterfaceAll) => {
        setNoteData(response); 
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
      }
    );
  }, [userAuth?.user?.id]);

  useEffect(() => {
    LicenceUserService.getAll(undefined, `filter[user.id]=${userAuth.user?.id}`, undefined, `page[offset]=0&page[limit]=999999`).subscribe(
      (response: LicenceUserResponseInterfaceAll) => {
        if (response.data?.length && response.data[0]) {
          const licenceId = response?.data[0]?.relationships?.licence?.data?.id;
          if (licenceId) {
            LicenceService.getSingle(licenceId).subscribe(
              (response: LicenceResponseInterfaceSingle) => {
                setLicenceData(response.data);
              }
            );
          }
        }
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    localStorage.removeItem('transferFilters');
    dispatch(clearOpinionAction());
    dispatch(clearOpinionListAction());
    dispatch(getScreenTagsByTypeAction('opinion'));
    /**
    dispatch(
      getAllOpinionsAction(
        undefined,
        undefined,
        'sort=-year',
        `page[offset]=${pageSize * (page - 1)}&page[limit]=${pageSize}`
      )
    );
    */
    esSearchFunction(allOpinions, { from: pageSize * (page - 1), size: pageSize }, undefined, undefined, {field: 'year', order: 'Desc'});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const esSearchFunction = (allOpinions: boolean, pageSorted: {from: number, size: number}, keyword?: string, tagIds?: any, sort?: SorterInterface) => {
    OpinionService.elasticSearch(allOpinions, pageSorted, exactMatch, keyword, tagIds/**, sort*/).subscribe(
      (response: OpinionEsResponseInterface) => {
        setEsData(response);
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
      }
    );
  };

  const refetch = (allValues: any) => {
    //setEsSearch(true);
    setFilterValues(allValues);
    setPage(1);
    esSearchFunction(allOpinions, {from: 0, size: pageSize}, keyword, allValues, {field: 'year', order: 'Desc'});
  };

  const resetSearch = () => {
    setEsData(undefined);
    setKeyword('');
    setAllOpinions(true);
    //setEsSearch(false);
    setFilterValues(undefined);
    setPage(1);
    //dispatch(getAllOpinionsAction(undefined, undefined, 'sort=-year', `page[offset]=0&page[limit]=${pageSize}`));
    esSearchFunction(allOpinions, { from: 0, size: pageSize }, undefined, undefined, {field: 'year', order: 'Desc'});
  };

  const onPaginationChange: PaginationProps['onChange'] = (page, pageSize) => {
    setPage(page);
    setPageSize(pageSize);
    if (esSearch) {
      esSearchFunction(allOpinions, {from: pageSize * (page - 1), size: pageSize}, keyword, filterValues, {field: 'year', order: 'Desc'});
    } else {
      dispatch(getAllOpinionsAction(filterValues, undefined, 'sort=-year',`page[offset]=${pageSize * (page - 1)}&page[limit]=${pageSize}`));
    }
  };

  const allOpinionsChanged = (value: boolean) => {
    setAllOpinions(value);
  };

  const keywordChanged = (value: string) => {
    setKeyword(value);
  };

  const exactMatchChanged = (exactMatch: boolean) => {
    setExactMatch(exactMatch);
  };

  const convertDataType = (opinions: OpinionEsResponseInterface["hits"]): OpinionInterface[] => {
    return opinions.map((opinion) => {
      return {
        id: opinion.id,
        type: opinion.index,
        attributes: opinion.source
      };
    })
  };

  const sortedOpinions = (opinions: OpinionInterface[]): Array<Array<OpinionInterface>> => {
    let years: any = {};

    opinions?.forEach(opinion => {
      let key = opinion.attributes?.yearTag;
      if(!key) key = 0;
      if (!years[key]) years[key] = [];
      years[key].push(opinion);
    });

    let yearAndData: any = Object.entries(years).filter((x: any) => x[1].length > 0) as any;
    yearAndData = yearAndData.sort((a: any, b: any) => (parseInt(a[0]) > parseInt(b[0]) ? -1 : 1));
    let data: Array<Array<OpinionInterface>> = yearAndData.map((x: any) => x[1]) as any;
    return data;
  };

  const genHeader = (opinion: OpinionInterface | undefined) => {
    return (
      <span className="header">
        <Text className="extraText">
          {opinion?.attributes?.yearTag ? opinion?.attributes?.yearTag + '.' : i18n.translate('common.unsorted')}
        </Text>
      </span>
    );
  };

  const getDefaultActiveKeys = (opinions: OpinionInterface[]) => {
    let years: any = {};

    opinions?.forEach(opinion => {
      let key = opinion.attributes?.yearTag;
      if(!key) key = 0;
      if (!years[key]) years[key] = [];
    });

    return Object.keys(years).map((entry, index) => 'opinion_sorted' + index);
  };

  return (
    <div className="institutionalOpinions w100-h100">
      <div className="institutionalOpinions__breadcrumbs">
        <Breadcrumb>
          <Breadcrumb.Item>
            <HomeOutlined />
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span>{i18n.translate('breadcrumbs.web-of-knowledge')}</span>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span>{i18n.translate('menu.institutional-opinions')}</span>
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="institutionalOpinions__admin">
        <div className="title">{i18n.translate('institutionalOpinions.title')}</div>
        <Link
          to={AdminRoutes.INSTITUTIONAL_OPINIONS.fullPath + `/new`}
          key={AdminRoutes.INSTITUTIONAL_OPINIONS.path + `/new`}
        >
          <Button
            className="button"
            type="primary"
            size="large"
            style={{ fontSize: 16, fontWeight: 700, borderRadius: 0 }}
          >
            {i18n.translate('institutionalOpinions.addButton')}
          </Button>
        </Link>
      </div>
      {tags.tagScreens && tags.tagScreens.length ? (
        <FilterComponent aggregation={esData?.aggregation?.buckets} filterAccess onExactMatchChange={exactMatchChanged} allOpinions={allOpinions} keyword={keyword} filters={tags.tagScreens} onFilterChange={refetch} onFilterRemove={resetSearch} onAllOpinionsChange={allOpinionsChanged} onKeywordChange={keywordChanged} />
      ) : null}
      {/**<YearDividerComponent title={''} />*/}
      {esSearch &&
        <>
          {esData?.hits?.length ? (
            <Collapse className='noPointer' collapsible={undefined} activeKey={getDefaultActiveKeys(convertDataType(esData?.hits as OpinionEsResponseInterface["hits"]) as OpinionInterface[])} ghost expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 270 : 90} />}>
            {sortedOpinions(convertDataType(esData?.hits as OpinionEsResponseInterface["hits"]) as OpinionInterface[])?.length ? 
            [...sortedOpinions(convertDataType(esData?.hits as OpinionEsResponseInterface["hits"]) as OpinionInterface[])]
              .map((group, index: number) => (
                <Panel className='noPointer' showArrow={false} forceRender key={'opinion_sorted' + index} header={genHeader(group?.length && group[0] ? group[0] : undefined)}>
                  {group?.length ? (
                    [...(group as OpinionInterface[])]
                      ?.map((opinion, i) => (
                        <InstitutionalOpinionCardComponent key={'opinion' + i} opinion={opinion} tags={tags.tagScreens} notes={noteData} />
                      ))
                  ) : (
                    <div className="center">
                      <NoDataComponent />
                    </div>
                  )}
                </Panel>
              )) : null}
            </Collapse>
          ) : (
            <NoDataComponent />
          )}
        </>
      }
      {!esSearch &&
        <>
          {data.opinions?.data?.length ? (
            <Collapse className='noPointer' collapsible={undefined} activeKey={getDefaultActiveKeys(data.opinions?.data)} ghost expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 270 : 90} />}>
            {sortedOpinions(data.opinions?.data)?.length ? 
            [...sortedOpinions(data.opinions?.data)]
              .map((group, index: number) => (
                <Panel className='noPointer' showArrow={false} forceRender key={'opinion_sorted' + index} header={genHeader(group?.length && group[0] ? group[0] : undefined)}>
                  {group?.length ? (
                    [...(group as OpinionInterface[])]
                      ?.map((opinion, i) => (
                        <InstitutionalOpinionCardComponent key={'opinion' + i} opinion={opinion} tags={tags.tagScreens} notes={noteData} />
                      ))
                  ) : (
                    <div className="center">
                      <NoDataComponent />
                    </div>
                  )}
                </Panel>
              )) : null}
            </Collapse>
          ) : (
            <NoDataComponent />
          )}
        </>
      }
      {esSearch &&
        <>
          {esData?.hits?.length ? (
            <Pagination
              style={{ float: 'right', paddingTop: 16, paddingBottom: 16 }}
              onChange={onPaginationChange}
              current={page}
              pageSize={pageSize}
              pageSizeOptions={['10', '20', '50', '100']}
              locale={{ items_per_page: `${i18n.t('pagination.perPage')}` }}
              total={esData?.totalHits}
              hideOnSinglePage
              responsive
              showTotal={(total) => `${i18n.t('pagination.total')}: ${total}`}
            />
          ) : null}
        </>
      }
      {!esSearch &&
        <>
          {data.opinions?.data?.length ? (
            <Pagination
              style={{ float: 'right', paddingTop: 16, paddingBottom: 16 }}
              onChange={onPaginationChange}
              current={page}
              pageSize={pageSize}
              pageSizeOptions={['10', '20', '50', '100']}
              locale={{ items_per_page: `${i18n.t('pagination.perPage')}` }}
              total={data?.opinions?.meta?.totalResourceCount}
              hideOnSinglePage
              responsive
              showTotal={(total) => `${i18n.t('pagination.total')}: ${total}`}
            />
          ) : null}
        </>
      }
    </div>
  );
}

export default InstitutionalOpinionsPageComponent;
