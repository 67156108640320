import { LicenceUserConstants } from './licenceUser.constants';
import { LicenceUserInterface, LicenceUserResponseInterfaceAll, LicenceUserResponseInterfaceSingle, ReducerActionInterface } from '../../interfaces';

// ============ INTERFACE ============

export interface LicenceUserStateInterface {
  currentLicenceUser?: LicenceUserInterface;
  licenceUsers?: LicenceUserResponseInterfaceAll;
  error?: string;
}

// ============ INIT STATE ============

const initialState: LicenceUserStateInterface = {
  currentLicenceUser: undefined,
  licenceUsers: {
    data: [],
    meta: {
      totalResourceCount: 0
    }
  }
}

// ============ REDUCERS ============

const getSingleSuccess = (state: LicenceUserStateInterface, payload: LicenceUserResponseInterfaceSingle): LicenceUserStateInterface => {
  return {
    ...state,
    currentLicenceUser: payload.data,
  };
};

const getAllSuccess = (state: LicenceUserStateInterface, payload: LicenceUserResponseInterfaceAll): LicenceUserStateInterface => {
  return {
    ...state,
    licenceUsers: {
      data: payload.data,
      meta: payload.meta
    }
  };
};

const createSuccess = (state: LicenceUserStateInterface, payload: LicenceUserResponseInterfaceSingle): LicenceUserStateInterface => {
  return {
    ...state,
    currentLicenceUser: payload.data,
  };
};

const updateSuccess = (state: LicenceUserStateInterface, payload: LicenceUserResponseInterfaceSingle): LicenceUserStateInterface => {
  return {
    ...state,
    currentLicenceUser: payload.data,
  };
};

const clearCurrent = (state: LicenceUserStateInterface): LicenceUserStateInterface => {
  return {
    ...state,
    currentLicenceUser: undefined,
  };
};

const clearList = (state: LicenceUserStateInterface): LicenceUserStateInterface => {
  return {
    ...state,
    licenceUsers: {
      data: [],
      meta: {
        totalResourceCount: 0
      }
    }
  };
};

const deleteSuccess = (state: LicenceUserStateInterface): LicenceUserStateInterface => {
  return {
    ...state,
    currentLicenceUser: undefined,
  };
};

const error = (state: LicenceUserStateInterface, payload: string) => {
  return {
    ...state,
    error: payload,
  };
};

// ============ EXPORTS ============

export const LicenceUserReducer = (state = initialState, action: ReducerActionInterface) => {
  switch (action.type) {
    case LicenceUserConstants.GET_SINGLE_SUCCESS:
      return getSingleSuccess(state, action.payload);
    case LicenceUserConstants.GET_ALL_SUCCESS:
      return getAllSuccess(state, action.payload);
    case LicenceUserConstants.CREATE_SUCCESS:
      return createSuccess(state, action.payload);
    case LicenceUserConstants.UPDATE_SUCCESS:
      return updateSuccess(state, action.payload);
    case LicenceUserConstants.CLEAR_CURRENT:
      return clearCurrent(state);
    case LicenceUserConstants.CLEAR_LIST:
      return clearList(state);
    case LicenceUserConstants.DELETE:
      return deleteSuccess(state);
    case LicenceUserConstants.ERROR:
      return error(state, action.payload);
    default:
      return state;
  }
};
