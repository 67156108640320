import { Dispatch } from 'redux';
import { notification } from 'antd';
import { FullUserConstants } from './fullUser.constants';

import { FullUserService } from '../../api/api.service';
import { FullUserResponseInterfaceAll, FullUserResponseInterfaceSingle } from '../../interfaces';
import { i18n } from '../../services';

export function getAllFullUsersAction(params?: any, filter?: any, sort?: any, pagination?: any) {
  return (dispatch: Dispatch) => {
    return FullUserService.getAll(params, filter, sort, pagination).subscribe(
      (response: FullUserResponseInterfaceAll) => {
        dispatch({
          type: FullUserConstants.GET_ALL_SUCCESS,
          payload: response,
        });
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });

        dispatch({
          type: FullUserConstants.ERROR,
          payload: error,
        });
      }
    );
  };
}

export function getSingleFullUserAction(id: number) {
  return (dispatch: Dispatch) => {
    return FullUserService.getSingle(id).subscribe(
      (response: FullUserResponseInterfaceSingle) => {
        dispatch({
          type: FullUserConstants.GET_SINGLE_SUCCESS,
          payload: response,
        });
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });

        dispatch({
          type: FullUserConstants.ERROR,
          payload: error,
        });
      }
    );
  };
}

export function createFullUserAction(body: FullUserResponseInterfaceSingle) {
  return (dispatch: Dispatch) => {
    return FullUserService.create(body).subscribe(
      (response: FullUserResponseInterfaceSingle) => {
        notification['success']({ message: i18n.translate('api.successMessage'), duration: 2 });

        dispatch({
          type: FullUserConstants.CREATE_SUCCESS,
          payload: response,
        });
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });

        dispatch({
          type: FullUserConstants.ERROR,
          payload: error,
        });
      }
    );
  };
}

export function updateFullUserAction(body: FullUserResponseInterfaceSingle, hideSuccess?: boolean) {
  return (dispatch: Dispatch) => {
    return FullUserService.update(body).subscribe(
      (response: FullUserResponseInterfaceSingle) => {
        if (!hideSuccess) notification['success']({ message: i18n.translate('api.successMessage'), duration: 2 });

        dispatch({
          type: FullUserConstants.UPDATE_SUCCESS,
          payload: response,
        });
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });

        dispatch({
          type: FullUserConstants.ERROR,
          payload: error,
        });
      }
    );
  };
}

export function deleteFullUserAction(id: number) {
  return (dispatch: Dispatch) => {
    return FullUserService.delete(id).subscribe(
      (response: any) => {
        dispatch({
          type: FullUserConstants.DELETE
        });
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });

        dispatch({
          type: FullUserConstants.ERROR,
          payload: error,
        });
      }
    );
  };
}

export function clearFullUserAction() {
  return (dispatch: Dispatch) => {
    dispatch({ type: FullUserConstants.CLEAR_CURRENT });
  };
}

export function clearFullUserListAction() {
  return (dispatch: Dispatch) => {
    dispatch({ type: FullUserConstants.CLEAR_LIST });
  };
}
