import { Dispatch } from 'redux';
import { notification } from 'antd';
import { VerdictConstants } from './verdict.constants';

import { VerdictService } from '../../api/api.service';
import { VerdictResponseInterfaceAll, VerdictResponseInterfaceSingle } from '../../interfaces';
import { i18n } from '../../services';

export function getAllVerdictsAction(params?: any, filter?: any, sort?: any, pagination?: any) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: VerdictConstants.GET_ALL_SUCCESS,
      payload: {
        data: [],
        meta: {}
      },
    });

    return VerdictService.getAll(params, filter, sort, pagination).subscribe(
      (response: VerdictResponseInterfaceAll) => {
        dispatch({
          type: VerdictConstants.GET_ALL_SUCCESS,
          payload: response,
        });
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });

        dispatch({
          type: VerdictConstants.ERROR,
          payload: error,
        });
      }
    );
  };
}

export function getSingleVerdictAction(id: number) {
  return (dispatch: Dispatch) => {
    return VerdictService.getSingle(id).subscribe(
      (response: VerdictResponseInterfaceSingle) => {
        dispatch({
          type: VerdictConstants.GET_SINGLE_SUCCESS,
          payload: response,
        });
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });

        dispatch({
          type: VerdictConstants.ERROR,
          payload: error,
        });
      }
    );
  };
}

export function createVerdictAction(body: VerdictResponseInterfaceSingle) {
  return (dispatch: Dispatch) => {
    return VerdictService.create(body).subscribe(
      (response: VerdictResponseInterfaceSingle) => {
        notification['success']({ message: i18n.translate('api.successMessage'), duration: 2 });

        dispatch({
          type: VerdictConstants.CREATE_SUCCESS,
          payload: response,
        });
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });

        dispatch({
          type: VerdictConstants.ERROR,
          payload: error,
        });
      }
    );
  };
}

export function updateVerdictAction(body: VerdictResponseInterfaceSingle) {
  return (dispatch: Dispatch) => {
    return VerdictService.update(body).subscribe(
      (response: VerdictResponseInterfaceSingle) => {
        notification['success']({ message: i18n.translate('api.successMessage'), duration: 2 });

        dispatch({
          type: VerdictConstants.UPDATE_SUCCESS,
          payload: response,
        });
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });

        dispatch({
          type: VerdictConstants.ERROR,
          payload: error,
        });
      }
    );
  };
}

export function deleteVerdictAction(id: number) {
  return (dispatch: Dispatch) => {
    return VerdictService.delete(id).subscribe(
      (response: any) => {
        notification['success']({ message: i18n.translate('api.successDelete'), duration: 2 });
        
        dispatch({
          type: VerdictConstants.DELETE
        });
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });

        dispatch({
          type: VerdictConstants.ERROR,
          payload: error,
        });
      }
    );
  };
}

export function clearVerdictAction() {
  return (dispatch: Dispatch) => {
    dispatch({ type: VerdictConstants.CLEAR_CURRENT });
  };
}

export function clearVerdictListAction() {
  return (dispatch: Dispatch) => {
    dispatch({ type: VerdictConstants.CLEAR_LIST });
  };
}
