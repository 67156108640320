import { Button, Cascader, Divider, Form, Input, Radio, Select, Space, TreeSelect, Typography, notification } from 'antd';
import { SearchOutlined, CloseOutlined } from '@ant-design/icons';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { SelectTypeEnum, UserRoleEnum } from '../../enums';
import { ChildrenTagScreenResponseInterface, ESFilterInterface, TagScreenResponseInterface } from '../../interfaces';
import { i18n } from '../../services';
import { useSelector } from 'react-redux';
import { UserActivityLogService } from '../../api';
import { UserActionTypeEnum } from '../../enums/userActionType.enum';
import { StoreStateInterface } from '../../redux';

const { SHOW_CHILD } = Cascader;

const { Text } = Typography;

interface Props {
  filters?: Array<TagScreenResponseInterface>;
  aggregation?: Array<{ key: string, docCount: number }>;
  tab?: string;
  allOpinions?: boolean;
  keyword?: string;
  author?: string;
  noYearLimit?: boolean;
  transferEnabled?: boolean;
  filterAccess?: boolean;
  summaryFilterAccess?: boolean;
  dkomFilters?: Array<TagScreenResponseInterface>;
  vusFilters?: Array<TagScreenResponseInterface>;
  alternativeLayout?: boolean;
  hideFilter?: boolean;
  onTagChange?: (changedTags: any, allTags: any) => void;
  onFilterChange?: (filters: ESFilterInterface, tab?: string) => void;
  onTabChange?: (tab: string) => void;
  onFilterRemove?: (tab?: string) => void;
  onAllOpinionsChange?: (value: boolean) => void;
  onKeywordChange?: (value: string) => void;
  onAuthorChange?: (value: string) => void;
  onExactMatchChange?: (exactMatch: boolean) => void;
}

export default function FilterComponent({ filters, aggregation, tab, allOpinions, summaryFilterAccess, keyword, author, noYearLimit, transferEnabled, filterAccess, dkomFilters, vusFilters, alternativeLayout, hideFilter, onTagChange, onFilterChange, onTabChange, onFilterRemove, onAllOpinionsChange, onKeywordChange, onAuthorChange, onExactMatchChange }: Props) {
  const userAuth = useSelector((state: StoreStateInterface) => state.auth);
  
  const location = useLocation();
  const path = location.pathname.split('/');
  const pathString = path[path.length - 1];
  const isVus = pathString.includes('vus');

  const [form] = Form.useForm();
  const [advancedFilters, setAdvancedFilters] = useState(false);
  const [advancedFiltersOpen, setAdvancedFiltersOpen] = useState(false);

  useEffect(() => {
    if (transferEnabled) {
      form.resetFields();
      form.setFieldsValue(handleStorageFilters());
      onTagsChange(form.getFieldsValue(true), form.getFieldsValue());
      if (handleStorageFilters() && Object.keys(handleStorageFilters()).length) form.submit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onTagsChange = (changedValues: any, values: any) => {
    onTagChange && onTagChange(changedValues, values);
  };

  const onFiltersChange = (allValues: { [key: string]: Array<number> }) => {
    function removeDuplicates(arr: any[]) {
      return arr?.filter((item: any, index: any) => arr?.indexOf(item) === index);
    }

    let value: ESFilterInterface = {};
    let activityLogValue: any = [];
    for (let e in allValues) {
      let type = "";
      if (!!allValues[e])
        for (let v of allValues[e]) {
          if (!!type && type.length > 0) break;
          if (!!filters) {
            for (let f of filters) {
              if (!!f.children)
                for (let c of f.children) {
                  if (c.tag.id === v) {
                    type = f.selectType;
                  }
                }
            }
          }
        }

      value[e] = {
        tags: removeDuplicates(allValues[e]?.flat())
      }
    }
    Object.entries(allValues).forEach(element => {
      if (element[1]) {
        activityLogValue.push({ parentFilterUsed: element[0], childFiltersNum: element[1].length, domain: pathString });
      }
    });
    if (userAuth?.user?.roles !== UserRoleEnum.ADMIN && activityLogValue.length) {
      let userActivityLogData: any = {
        data: {
          type: 'user_activity_log',
          attributes: {
            action: UserActionTypeEnum.FILTERS_USED,
            extra: JSON.stringify(activityLogValue)
          },
          relationships: {
            user: {
              data: {
                id: userAuth?.user?.id,
                type: 'user'
              },
            },
          },
        },
      };

      UserActivityLogService.create(userActivityLogData);
    }
    onFilterChange && onFilterChange(value, tab);
  };

  const onTabsChange = (e: any) => {
    if (!filterAccess) return;
    onTabChange && onTabChange(e.target.value);
  };

  const onFiltersRemove = () => {
    form.resetFields();
    onFilterRemove && onFilterRemove(tab);
  };

  const setTreeData = (children: Array<ChildrenTagScreenResponseInterface>) => {
    let values = children.map(child => ({
      title: child.nestedChildren?.length ? child.tag.name : <Text style={{ display: 'flex', justifyContent: 'space-between'}}><span style={{overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', marginRight: 16}}>{`${child.tag.name}`}</span><span>{`${findAggregate(child.tag.id as number)}`}</span></Text>,
      value: child.tag.id,
      key: child.tag.id,
      children: child.nestedChildren?.map(nestedChild => ({
        title: <Text style={{ display: 'flex', justifyContent: 'space-between'}}><span style={{overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', marginRight: 16}}>{`${nestedChild.tag.name}`}</span><span>{`${findAggregate(nestedChild.tag.id as number)}`}</span></Text>,
        value: nestedChild.tag.id,
        key: nestedChild.tag.id,
        children: nestedChild.nestedChildren?.map(nestedLvl2Child => ({
          title: <Text style={{ display: 'flex', justifyContent: 'space-between'}}><span style={{overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', marginRight: 16}}>{`${nestedLvl2Child.tag.name}`}</span><span>{`${findAggregate(nestedLvl2Child.tag.id as number)}`}</span></Text>,
          value: nestedLvl2Child.tag.id,
          key: nestedLvl2Child.tag.id,
        }))
      }))
    }));
    return values;
  };

  const setCascaderData = (children: Array<ChildrenTagScreenResponseInterface>) => {
    let values = children.map(child => ({
      //label: child.nestedChildren?.length ? child.tag.name : <Text style={{ display: 'flex', justifyContent: 'space-between'}}><span style={{overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', marginRight: 16}}>{`${child.tag.name}`}</span><span>{`${findAggregate(child.tag.id as number)}`}</span></Text>,
      label: child.tag.name,
      value: child.tag.id,
      key: child.tag.id,
      children: child.nestedChildren?.map(nestedChild => ({
        //label: nestedChild.nestedChildren?.length ? nestedChild.tag.name : <Text style={{ display: 'flex', justifyContent: 'space-between'}}><span style={{overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', marginRight: 16}}>{`${nestedChild.tag.name}`}</span><span>{`${findAggregate(nestedChild.tag.id as number)}`}</span></Text>,
        label: nestedChild.tag.name,
        value: nestedChild.tag.id,
        key: nestedChild.tag.id,
        children: nestedChild.nestedChildren?.map(nestedLvl2Child => ({
          //label: <Text style={{ display: 'flex', justifyContent: 'space-between'}}><span style={{overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', marginRight: 16}}>{`${nestedLvl2Child.tag.name}`}</span><span>{`${findAggregate(nestedLvl2Child.tag.id as number)}`}</span></Text>,
          label: nestedLvl2Child.tag.name,
          value: nestedLvl2Child.tag.id,
          key: nestedLvl2Child.tag.id,
        }))
      }))
    }));
    return values;
  };

  const checkForNested = (tag: TagScreenResponseInterface) => {
    let nested = tag.children?.find(child => child.nestedChildren?.length && child.nestedChildren?.length > 0);
    if (nested)
      return true;
    else
      return false;
  };

  const filterFromCurrentYear = (children: ChildrenTagScreenResponseInterface[]): ChildrenTagScreenResponseInterface[] => {
    if (noYearLimit) {
      const currentYear = parseInt(moment().format('YYYY').toString());
      return [...children].filter(a => parseInt(a.tag?.name) <= currentYear).sort((a, b) => parseInt(a.tag?.name) - parseInt(b.tag?.name)).reverse();
    } else {
      const currentYear = moment().format('YYYY');
      const currentYearChild = children.find(child => child.tag.name == currentYear);
      const index = children.indexOf(currentYearChild as ChildrenTagScreenResponseInterface);
      if (index && index > 0) children.length = index + 1;
      return [...children].reverse();
    }
  };

  const filterAdvanced = () => {
    let filteredTags;
    if (filters) {
      filteredTags = [...filters];
      if (tab && !advancedFilters) {
        filteredTags = [...filters.filter(filterTag => filterTag.selectType != SelectTypeEnum.ADVANCED)];
      }
      filteredTags.forEach(tag => {
        tag.children?.sort((a, b) => a.tag?.position - b.tag?.position);
      });
    }
    //if (hideFilter) return filteredTags?.sort((a, b) => a.filter.position - b.filter.position).filter(f => f.filter.id !== 402);
    return filteredTags?.sort((a, b) => a.filter.position - b.filter.position);
  };

  const TabFilters = () => {
    if (!onTabChange) return null;
    return (
      <div className='tabs'>
        <Radio.Group value={tab} onChange={onTabsChange} buttonStyle="solid">
          <Radio.Button value="decision">{i18n.t(isVus ? 'filterTabs.verdict' : 'filterTabs.decision')}</Radio.Button>
          <Radio.Button onClick={showLicenceMessage} value="allegation">{i18n.t(isVus ? 'filterTabs.order' : 'filterTabs.allegation')}</Radio.Button>
          <Radio.Button onClick={showLicenceMessage} value="appeal">{i18n.t(isVus ? 'filterTabs.response' : 'filterTabs.appeal')}</Radio.Button>
          <Radio.Button onClick={showLicenceMessage} value="summary">{i18n.t(isVus ? 'filterTabs.synopsis' : 'filterTabs.summary')}</Radio.Button>
        </Radio.Group>
      </div>
    );
  }

  const showLicenceMessage = () => {
    if (!filterAccess) {
      notification['warning']({ message: i18n.translate('common.licence.noFilter'), duration: 4 });
    }
  }

  const handleStorageFilters = () => {
    function isObjEmpty (obj: any) {
      return Object.keys(obj).length === 0;
    }

    let filters = undefined;
    const storageString = localStorage.getItem('transferFilters');

    if (storageString && storageString !== 'undefined') {
      filters = JSON.parse(storageString);
    }

    let processedFilters: any = {};

    if (filters) {
      Object.entries(filters).forEach(el => {
        let key: any = el[0];
        let values: number[] = el[1] as number[];
        let filterArray = isVus ? dkomFilters : vusFilters;
        let oppositeFilterArray = isVus ? vusFilters : dkomFilters;

        let foundFilterByName = filterArray?.find(x => x.filter?.name == key);
        let foundFilterByNameOpposite = oppositeFilterArray?.find(x => x.filter?.name == key);

        if (foundFilterByName && foundFilterByNameOpposite) {
          let valueArray: (number | undefined)[] = [];

          // LVL1
          foundFilterByName?.children?.forEach((childFilter) => {
            if (values.includes(childFilter?.tag?.id as number)) {
              let foundOpposite = foundFilterByNameOpposite?.children?.find(x => x.tag?.name == childFilter?.tag?.name);
              if (foundOpposite) {
                valueArray.push(foundOpposite.tag?.id);
              }
            }

            // LVL2
            childFilter?.nestedChildren?.forEach((lvl2ChildFilter) => {
              if (values.includes(lvl2ChildFilter?.tag?.id as number)) {
                let foundOpposite = foundFilterByNameOpposite?.children?.find(x => x.tag?.name == childFilter.tag?.name)?.nestedChildren?.find(y => y.tag?.name == lvl2ChildFilter?.tag?.name);
                if (foundOpposite) {
                  valueArray.push(foundOpposite.tag?.id);
                }
              }
            });
          });
          
          processedFilters[`${key}`] = valueArray;
        }
      });
    }

    return !isObjEmpty(processedFilters) ? processedFilters : filters;
  };

  const findAggregate = (filterId: number) => {
    if (!filterId || !aggregation || !aggregation?.length) return 0;

    const foundNumber = aggregation?.find(x => x.key.toString() == filterId.toString())?.docCount;

    return foundNumber ? foundNumber : 0;
  };

  const advancedDropdownRender = (menus: React.ReactNode) => (
    <div>
      <div style={{ position: 'fixed', top: 4, left: 12 }}>
        <Space style={{ cursor: 'pointer' }} onClick={() => setAdvancedFiltersOpen(false)}>
          <b><CloseOutlined /></b>
          <b>{i18n.t('common.filter.closeAdvanced')}</b>
        </Space>
      </div>
      <Divider style={{ visibility: 'hidden', marginBottom: 8 }}/>
      <div>
        {menus}
      </div>
    </div>
  );

  return (
    <div className="filter">
      {filters && filters.length > 0 &&
        <div>
          <TabFilters />
          <div className='top'>
            {onAllOpinionsChange || onKeywordChange || onAuthorChange ? <div className='top-filter'>
              {onKeywordChange &&
                <div className="top-input">
                  <Input addonAfter={<SearchOutlined onClick={() => form.submit()} style={{ borderRadius: 0 }} />} placeholder={i18n.translate(`institutionalOpinions.form.other.textSearchLabel`)} value={keyword} onChange={(e: any) => onKeywordChange(e.target.value)} onPressEnter={() => onFiltersChange(form.getFieldsValue(true))} />
                </div>
              }
              {onAuthorChange &&
                <div className="top-input">
                  <Input addonAfter={<SearchOutlined onClick={() => form.submit()} style={{ borderRadius: 0 }} />} placeholder={i18n.translate(`proMaterials.form.other.author`)} value={author} onChange={(e: any) => onAuthorChange(e.target.value)} onPressEnter={() => onFiltersChange(form.getFieldsValue(true))} />
                </div>
              }
              {/**onAllOpinionsChange &&
              <>
                <div style={{ marginRight: 16 }}>
                <Radio.Group value={allOpinions} onChange={(e: any) => onAllOpinionsChange(e.target.value)} buttonStyle="solid">
                  <Radio.Button value={true}>{i18n.translate(`institutionalOpinions.form.other.allOpinions`)}</Radio.Button>
                  <Radio.Button value={false}>{i18n.translate(`institutionalOpinions.form.other.availableOpinions`)}</Radio.Button>
                </Radio.Group>
                </div>
                <Tooltip title={i18n.translate(`institutionalOpinions.form.other.topFilterTooltip`)} placement="right">
                  <QuestionCircleOutlined style={{ fontSize: '18px', color: '#08c' }} />
                </Tooltip>
              </>
            */}
            {
              <Radio.Group defaultValue={true}>
                <Radio onClick={() => onExactMatchChange && onExactMatchChange(true)} value={true}>{i18n.translate('common.filter.exactMatch')}</Radio>
                <Radio onClick={() => onExactMatchChange && onExactMatchChange(false)} value={false}>{i18n.translate('common.filter.noExactMatch')}</Radio>
              </Radio.Group>
            }
            </div> : null}
            {!alternativeLayout ? <Button className='cancelSearch' type='link' onClick={onFiltersRemove}>{i18n.translate(`genericButtons.clearSearch`)}</Button> : null}
            {!alternativeLayout ? <Button className="top-button" type='primary' onClick={() => form.submit()}>{i18n.translate(`genericButtons.search`)}</Button> : null}
          </div>
          {filterAccess ? <Divider /> : null}
          <Form
            form={form}
            layout='vertical'
            name="filters"
            //initialValues={transferEnabled ? handleStorageFilters() : undefined}
            onFinish={onFiltersChange}
            onValuesChange={onTagsChange}
            autoComplete="off"
            requiredMark={false}
          >

            {filterAccess && filterAdvanced()?.map(tag => (
              <Form.Item
                label={<span className='text-bold'>{tag.filter.name}</span>}
                name={tag.filter.name}
                key={tag.filter.name}
              >
                {checkForNested(tag) ?
                  tag.selectType == SelectTypeEnum.ADVANCED ? <Cascader open={advancedFiltersOpen} onDropdownVisibleChange={(open) => { if (open) setAdvancedFiltersOpen(true) } } dropdownRender={advancedDropdownRender} dropdownMatchSelectWidth={false} options={setCascaderData(tag.children as Array<ChildrenTagScreenResponseInterface>)} multiple showCheckedStrategy={SHOW_CHILD} maxTagCount='responsive' placeholder={i18n.t('genericFormMsg.placeholders.select')} /> :
                    <TreeSelect dropdownMatchSelectWidth={false} treeData={setTreeData(tag.children as Array<ChildrenTagScreenResponseInterface>)} treeCheckable maxTagCount='responsive' placeholder={i18n.t('genericFormMsg.placeholders.select')} /> :
                  tag.selectType == SelectTypeEnum.YEAR ?
                    <Select dropdownMatchSelectWidth={false} mode="multiple" maxTagCount='responsive' allowClear placeholder={i18n.t('genericFormMsg.placeholders.select')}>
                      {tag.children?.length && filterFromCurrentYear(tag.children) && filterFromCurrentYear(tag.children)?.length && filterFromCurrentYear(tag.children).map(child => (
                        <Select.Option key={child.tag.name} value={child.tag.id}><Text style={{ display: 'flex', justifyContent: 'space-between'}}><span style={{overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', marginRight: 16}}>{`${child.tag.name}`}</span><span>{`${findAggregate(child.tag.id as number)}`}</span></Text></Select.Option>
                      ))}
                    </Select> :
                    <Select dropdownMatchSelectWidth={false} mode="multiple" maxTagCount='responsive' allowClear placeholder={i18n.t('genericFormMsg.placeholders.select')}>
                      {tag.children?.length && tag.children.map(child => (
                        <Select.Option key={child.tag.name} value={child.tag.id}><Text style={{ display: 'flex', justifyContent: 'space-between'}}><span style={{overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', marginRight: 16}}>{`${child.tag.name}`}</span><span>{`${findAggregate(child.tag.id as number)}`}</span></Text></Select.Option>
                      ))}
                    </Select>
                }
              </Form.Item>
            ))}

            <div className='bottomSearch'>
              {filterAccess && tab ? <div className='advancedButtonContainer'>
                <Button onClick={() => setAdvancedFilters(!advancedFilters)} className='advancedButton'>{!advancedFilters ? i18n.translate('genericButtons.advancedFilters') : i18n.translate('genericButtons.advancedFiltersHide')}</Button>
              </div> : null}
              <div className='bottomSearchButtons'>
                {alternativeLayout ? <Button className='cancelSearch' type='link' onClick={onFiltersRemove}>{i18n.translate(`genericButtons.clearSearch`)}</Button> : null}
                {alternativeLayout ? <Button className="top-button" type='primary' onClick={() => form.submit()}>{i18n.translate(`genericButtons.search`)}</Button> : null}
              </div>
            </div>

          </Form>
        </div>
      }
    </div>
  );
}
