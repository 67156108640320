import { Button, Input, InputRef, Space, Table } from 'antd';
import type { ColumnsType, ColumnType } from 'antd/es/table';
import { FilterConfirmProps } from 'antd/lib/table/interface';
import React, { useRef, useState } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { i18n } from '../../../../services';

interface DataType {
  key: string;
  year: string;
  name: string;
  licenseNo: string;
  printSubs: string;
  price: string;
  payDate: string;
  activationDate: string;
  expiryDate: string;
  notes: string;
  users: string;
  years: string;
}

const data: DataType[] = [
  {
    key: '1',
    year: '2021',
    name: 'hep1',
    licenseNo: '4',
    printSubs: '5',
    price: '123,45 EUR',
    payDate: '24.10.2022.',
    activationDate: '06.10.2022.',
    expiryDate: '25.10.2022',
    notes: 'blabla',
    users: 'Jaaa lol',
    years: '2022.',
  },
  {
    key: '2',
    year: '2023',
    name: 'hep2',
    licenseNo: '4',
    printSubs: '5',
    price: '123,45 EUR',
    payDate: '24.10.2022.',
    activationDate: '06.10.2022.',
    expiryDate: '25.10.2022',
    notes: 'lalalalalal',
    users: 'Ivan Ivanic',
    years: '2021., 2022.',
  },
];

type DataIndex = keyof DataType;

export default function OrganizationSubscriptionTableComponent(/**props ? */) {
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef<InputRef>(null);

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<DataType> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => clearFilters && handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText((selectedKeys as string[])[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex] !== undefined && record[dataIndex] !== null &&
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
  });

  const columns: ColumnsType<DataType> = [
    {
      title: i18n.translate(`organizationInfo.year`),
      dataIndex: 'year',
      key: 'year',
      width: '166px',
      fixed: 'left',
      ...getColumnSearchProps('year'),
    },
    {
      title: i18n.translate(`organizationInfo.name`),
      dataIndex: 'name',
      key: 'name',
      width: '170px',
      fixed: 'left',
      ...getColumnSearchProps('name'),
    },
    {
      title: i18n.translate(`organizationInfo.licenceNo`),
      dataIndex: 'licenseNo',
      key: 'licenseNo',
      width: '150px',
      ...getColumnSearchProps('licenseNo'),
    },
    {
      title: i18n.translate(`organizationInfo.printNo`),
      dataIndex: 'printSubs',
      key: 'printSubs',
      width: '150px',
      ...getColumnSearchProps('printSubs'),
    },
    {
      title: i18n.translate(`organizationInfo.price`),
      dataIndex: 'price',
      key: 'price',
      width: '170px',
      ...getColumnSearchProps('price'),
    },
    {
      title: i18n.translate(`organizationInfo.payDate`),
      dataIndex: 'payDate',
      key: 'payDate',
      width: '185px',
      ...getColumnSearchProps('payDate'),
    },
    {
      title: i18n.translate(`profile.subModels.activationDate`),
      dataIndex: 'activationDate',
      key: 'activationDate',
      width: '185px',
      ...getColumnSearchProps('activationDate'),
    },
    {
      title: i18n.translate(`profile.subModels.expirationDate`),
      dataIndex: 'expiryDate',
      key: 'expiryDate',
      width: '185px',
      ...getColumnSearchProps('expiryDate'),
    },
    {
      title: i18n.translate(`organizationInfo.notes`),
      dataIndex: 'notes',
      key: 'notes',
      width: '183px',
      ...getColumnSearchProps('notes'),
    },
    {
      title: i18n.translate(`organizationInfo.users`),
      dataIndex: 'users',
      key: 'users',
      width: '183px',
      ...getColumnSearchProps('users'),
    },
    {
      title: i18n.translate(`organizationInfo.years`),
      dataIndex: 'years',
      key: 'years',
      width: '185px',
      ...getColumnSearchProps('years'),
    },
  ];
  return (
    <Table
      scroll={{ x: 1500, y: 300 }}
      className="organizationTable"
      columns={columns}
      dataSource={data}
      pagination={false}
    />
  );
}
