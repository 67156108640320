import { Button, Col, Form, Input, InputNumber, notification, Row, Tabs, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  FullOrganizationInterface,
  FullOrganizationResponseInterfaceSingle,
  FullOrganizationUnitResponseInterfaceAll,
  OrganizationService,
  OrganizationSubscriptionsModelComponent,
  OrganizationTableComponent,
  OrganizationUnitService,
  PrintEditionInterface,
} from '../../../common';
import { i18n } from '../../../common/services';

const { Title } = Typography;

export default function OrganizationProfilePageComponent() {
  const location = useLocation();
  const path = location.pathname.split('/');
  const id = parseInt(path[path.length - 1]);

  const [orgInfo, setOrgInfo] = useState<FullOrganizationResponseInterfaceSingle>();
  const [orgUnits, setOrgUnits] = useState<FullOrganizationUnitResponseInterfaceAll>();
  const [selectedTab, setSelectedTab] = useState('1');

  const [form] = Form.useForm();

  useEffect(() => {
    if (id) {
      OrganizationService.getSingle(id as number).subscribe(
        (response: FullOrganizationResponseInterfaceSingle) => {
          if (response) {
            setOrgInfo(response);
            form.setFieldsValue(loadInitialValues(response.data));
          }
        },
        (error: Error) => {
          notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
        }
      );
    }
  }, [localStorage.removeItem('transferFilters')]);

  useEffect(() => {
    if (orgInfo?.data.id) {
      OrganizationUnitService.getAll(
        undefined,
        `filter[organization.id]=${orgInfo?.data.id}`,
        'sort=id',
        `page[offset]=0&page[limit]=999999`
      ).subscribe(
        (response: FullOrganizationUnitResponseInterfaceAll) => {
          if (response) {
            setOrgUnits(response);
          }
        },
        (error: Error) => {
          notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
        }
      );
    }
  }, [orgInfo?.data.id]);

  const loadInitialValues = (data: FullOrganizationInterface) => {
    let values: any = {
      name: data.attributes.name,
      companyOib: data.attributes.vat,
      address: data.attributes.streetAddress,
      city: data.attributes.city,
      postNo: data.attributes.zipCode,
      country: data.attributes.country,
    };

    return values;
  };

  return (
    <div className="organizationProfile w100-h100">
      {orgInfo && orgUnits && (
        <>
          <div className="organizationProfile__title" style={{ display: 'flex', justifyContent: 'space-between' }}>
            {orgInfo?.data.attributes.name}
            <Button type="primary">{i18n.translate(`profile.organization.edit`)}</Button>
          </div>
          <div className="organizationProfile___content">
            <Tabs activeKey={selectedTab} onChange={(activeKey) => setSelectedTab(activeKey)} destroyInactiveTabPane>
              <Tabs.TabPane tab={i18n.translate('profile.tabs.basicInfo')} key="1">
                <Title level={4} className="subTitle">
                  {i18n.translate(`profile.tabs.basicInfo`)}
                </Title>
                <Form id="orgForm" form={form} layout="vertical">
                  <Form.Item label={i18n.translate(`profile.basicInfo.companyName`)} name="name">
                    <Input placeholder={i18n.translate(`profile.basicInfo.companyName`)} style={{ borderRadius: 0 }} />
                  </Form.Item>
                  <Form.Item label={i18n.translate(`profile.basicInfo.companyOib`)} name="companyOib">
                    <Input placeholder={i18n.translate(`profile.basicInfo.companyOib`)} style={{ borderRadius: 0 }} />
                  </Form.Item>
                  <Form.Item label={i18n.translate(`profile.basicInfo.address`)} name="address">
                    <Input placeholder={i18n.translate(`profile.basicInfo.address`)} style={{ borderRadius: 0 }} />
                  </Form.Item>
                  <Input.Group>
                    <Row justify="space-between" style={{ width: 350 }}>
                      <Col span={15}>
                        <Form.Item name="city" label={i18n.translate(`profile.basicInfo.city`)}>
                          <Input placeholder={i18n.translate(`profile.basicInfo.city`)} />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          name="postNo"
                          label={i18n.translate(`profile.basicInfo.postNo`)}
                          rules={[{ type: 'number' }]}
                        >
                          <InputNumber placeholder="10000" />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Input.Group>
                  <Form.Item name="country" label={i18n.translate(`profile.basicInfo.country`)}>
                    <Input placeholder={i18n.translate(`profile.basicInfo.country`)} />
                  </Form.Item>
                </Form>

                <Title level={4} className="subTitle">
                  {i18n.translate(`profile.organization.orgUnit`)}
                </Title>
                <OrganizationTableComponent units={orgUnits} />
              </Tabs.TabPane>
              <Tabs.TabPane tab={i18n.translate('profile.tabs.subModels')} key="2">
                <OrganizationSubscriptionsModelComponent />
              </Tabs.TabPane>
            </Tabs>
          </div>
        </>
      )}
    </div>
  );
}
