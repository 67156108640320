import { Button, Form, Modal, notification, Popover, Space, Typography } from 'antd';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NoteModal } from '../../..';
import { LicenceInterface, NoteInterface, NoteResponseInterfaceSingle } from '../../../../interfaces';
import { createNoteAction, getAllNotesAction, NoteStateInterface, StoreStateInterface } from '../../../../redux';
import { i18n } from '../../../../services';
import { UserActivityLogService } from '../../../../api';
import { UserRoleEnum } from '../../../../enums';
import { UserActionTypeEnum } from '../../../../enums/userActionType.enum';
import { LicenceEnum } from '../../../../enums/licence.enum';
import moment from 'moment';

const { Text } = Typography;

interface Props {
  isExternal: boolean;
  visible: boolean;
  handleOk: any;
  handleCancel: any;
  external?: any;
  type: string;
  typeId?: number;
  breadcrumb?: string;
  number?: number;
  isEditable?: boolean;
  active?: boolean;
  licenceData: LicenceInterface;
  validLicences?: LicenceInterface[];
}

export default function NotePickModal({
  visible,
  handleOk,
  handleCancel,
  external,
  type,
  typeId,
  breadcrumb,
  number,
  licenceData,
  validLicences
}: Props) {
  const dispatch = useDispatch();
  const userAuth = useSelector((state: StoreStateInterface) => state.auth);
  const [newVisible, setNewVisible] = useState(false);
  const [isNew, setIsNew] = useState(false);

  const notes: NoteStateInterface = useSelector((state: StoreStateInterface) => state.notes);
  const [form] = Form.useForm();

  const filteredNotes = notes.notes?.data.filter((n) => n.attributes.type == 'note');
  let availableNotes;

  if (validLicences?.length) {
    validLicences.forEach((licence) => {
      if (
        licence.attributes?.licenceUsers?.find((x) => x.user?.id == userAuth.user?.id) &&
        !licence.relationships?.licenceParent?.data && licence.attributes?.notesNum > 0
      ) {
        availableNotes = licence.attributes?.notesNum as number;
        const standardLicence = validLicences.find(
          (x) => x.attributes?.licenceUsers?.find((y) => y.user?.id == userAuth.user?.id) && !x.relationships?.licenceParent?.data && x.attributes?.active && x.attributes?.licenceType === LicenceEnum.STANDARD && licence.attributes?.notesNum > 0 && moment().isBefore(moment(licence?.attributes?.to).startOf('day'))
        );
        const premiumLicence = validLicences.find(
          (x) => x.attributes?.licenceUsers?.find((y) => y.user?.id == userAuth.user?.id) && !x.relationships?.licenceParent?.data && x.attributes?.active && x.attributes?.licenceType === LicenceEnum.PREMIUM && licence.attributes?.notesNum > 0 && moment().isBefore(moment(licence?.attributes?.to).startOf('day'))
        );
        if (standardLicence && standardLicence.attributes?.notesNum > 0) availableNotes = standardLicence.attributes?.notesNum as number;
        if (premiumLicence && premiumLicence.attributes?.notesNum > 0) availableNotes = premiumLicence.attributes?.notesNum as number;
      }
    });
  }

  if (!availableNotes) availableNotes = licenceData?.attributes?.notesNum as number;

  const exceeded = (notes?.notes?.data?.length as number) >= availableNotes;

  const toggleVisible = () => {
    setNewVisible(!newVisible);
  };

  const showNewModal = () => {
    dispatch(getAllNotesAction(userAuth?.user?.id));
    setNewVisible(true);
  };

  const handleNewOk = () => {
    if (exceeded && isNew) {
      notification['warning']({ message: i18n.translate('notes.modal.maxNotes'), duration: 0 });
    } else {
    }
  };

  const handleNewCancel = () => {
    setNewVisible(false);
  };

  const onFinish = (values: any) => {
    let newNote: NoteInterface = {
      type: 'notes',
      attributes: {
        title: values.title,
        text: values.text,
        typeId: typeId,
        type: type,
        breadcrumb: breadcrumb,
        number: number,
        isEditable: true,
        active: true,
      },
      relationships: {
        user: {
          data: {
            id: userAuth.user?.id,
            type: 'user',
          },
        },
      },
    };

    let newNoteResponse: NoteResponseInterfaceSingle = {
      data: newNote,
    };

    if (userAuth?.user?.roles !== UserRoleEnum.ADMIN) {
      let userActivityLogData: any = {
        data: {
          type: 'user_activity_log',
          attributes: {
            action: UserActionTypeEnum.NOTE_CREATED,
            extra: newNoteResponse.data?.attributes?.type == 'note' ? undefined : newNoteResponse.data?.attributes?.type
          },
          relationships: {
            user: {
              data: {
                id: userAuth?.user?.id,
                type: 'user'
              },
            },
          },
        },
      };

      UserActivityLogService.create(userActivityLogData);
    }

    dispatch(createNoteAction(newNoteResponse));
    setNewVisible(false);
  };

  return (
    <div>
      <Modal
        className="notePickModal"
        title={
          <Text strong className="noteModal___title">
            {i18n.translate('notes.modal.title')}
          </Text>
        }
        visible={visible}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            {i18n.translate(`genericButtons.cancel`)}
          </Button>,

          exceeded ? (
            <Popover
              placement="topRight"
              content={i18n.translate(`notes.modal.exceeded.content`)}
              title={i18n.translate(`notes.modal.exceeded.title`)}
            >
              <Button disabled key="new" type="primary">
                {i18n.translate(`notes.modal.createNew`)}
              </Button>
            </Popover>
          ) : (
            <Button
              key="new"
              type="primary"
              onClick={() => {
                setIsNew(true);
                showNewModal();
                handleOk();
              }}
            >
              {i18n.translate(`notes.modal.createNew`)}
            </Button>
          ),

          notes.notes?.data?.length == 0 || filteredNotes?.length == 0 ? (
            <Popover
              placement="topRight"
              content={i18n.translate(`notes.modal.none.content`)}
              title={i18n.translate(`notes.modal.none.title`)}
            >
              <Button disabled type="primary">
                {i18n.translate(`notes.modal.addExisting`)}
              </Button>
            </Popover>
          ) : (
            <Button
              type="primary"
              onClick={() => {
                setIsNew(false);
                showNewModal();
                handleOk();
              }}
            >
              {i18n.translate(`notes.modal.addExisting`)}
            </Button>
          ),
        ]}
        width={1000}
      >
        <Space direction="vertical">
          <Text type="secondary">{i18n.translate(`notes.modal.linked`)}</Text>
          <Text>
            {external?.attributes?.title ||
              external?.attributes?.name ||
              external?.attributes?.titleContent ||
              external?.attributes?.verdictName ||
              external?.title ||
              external?.attributes?.materialTitle}
          </Text>
        </Space>
      </Modal>

      {newVisible && (
        <NoteModal
          isNew={isNew}
          visible={newVisible}
          handleOk={handleNewOk}
          handleCancel={handleNewCancel}
          external={external}
          type={type}
          typeId={typeId}
          breadcrumb={breadcrumb}
          number={number}
          isEditable={true}
          active={true}
          onFinish={onFinish}
          toggleVisible={toggleVisible}
          form={form}
        />
      )}
    </div>
  );
}
