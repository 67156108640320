export enum FaqConstants {
  GET_ALL_SUCCESS = 'FaqConstants_GET_ALL_SUCCESS',
  GET_SINGLE_SUCCESS = 'FaqConstants_GET_SINGLE_SUCCESS',
  GET_ONE_SUCCESS = 'FaqConstants_GET_ONE_SUCCESS',
  CREATE_SUCCESS = 'FaqConstants_CREATE_SUCCESS',
  UPDATE_SUCCESS = 'FaqConstants_UPDATE_SUCCESS',
  CLEAR_CURRENT = 'FaqConstants_CLEAR_CURRENT',
  CLEAR_LIST = 'FaqConstants_CLEAR_LIST',
  DELETE = 'FaqConstants_DELETE',
  ERROR = 'FaqConstants_ERROR',
}
