import { CommentOutlined, DownOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Breadcrumb, Button, Col, Dropdown, Menu, Modal, notification, Popover, Row, Space, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import {
  deleteNoteLinkAction,
  FullOrganizationUnitResponseInterfaceSingle,
  getAllNoteLinksAction,
  i18n,
  LicenceInterface,
  LicenceResponseInterfaceAll,
  LicenceService,
  NavigationService,
  NoteLinkInterface,
  OrganizationUnitService,
  StoreStateInterface
} from '../../../../common';
import { LicenceEnum } from '../../../../common/enums/licence.enum';
import {
  clearNoteAction,
  deleteNoteAction,
  getSingleNoteAction,
  NoteStateInterface
} from '../../../../common/redux/notes';
import printService from '../../../../common/services/print.service';
import { AppRoutes } from '../../_router/app.routes';
import moment from 'moment';

function NoteViewPageComponent() {
  const dispatch = useDispatch();
  const userAuth = useSelector((state: StoreStateInterface) => state.auth);

  const { Text } = Typography;
  const { confirm } = Modal;

  const { id } = useParams<{ id: string }>();

  const note: NoteStateInterface = useSelector((state: StoreStateInterface) => state.notes);

  const [orgUnitInfo, setOrgUnitInfo] = useState<FullOrganizationUnitResponseInterfaceSingle>();
  const [licenceData, setLicenceData] = useState<LicenceInterface>();

  const noteLinkData = useSelector((state: StoreStateInterface) => state.notesLinks);

  useEffect(() => {
    localStorage.removeItem('transferFilters');
    clearNoteAction();
    dispatch(getAllNoteLinksAction(parseInt(id)));
    dispatch(getSingleNoteAction(parseInt(id)));
  }, []);

  useEffect(() => {
    if (userAuth.user?.organizationUnit?.id) {
      OrganizationUnitService.getSingle(userAuth.user?.organizationUnit?.id).subscribe(
        (response: FullOrganizationUnitResponseInterfaceSingle) => {
          setOrgUnitInfo(response);
        },
        (error: Error) => {
          notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
        }
      );
    }
  }, [userAuth.user]);

  useEffect(() => {
    if (orgUnitInfo?.data?.id) {
      LicenceService.getAll(
        undefined,
        `filter[organizationUnit.id]=${orgUnitInfo?.data?.id}`,
        'sort=id',
        `page[offset]=0&page[limit]=999999`
      ).subscribe(
        (response: LicenceResponseInterfaceAll) => {
          if (response?.data?.length) {
            response?.data?.forEach((licence) => {
              if (
                licence.attributes?.licenceUsers?.find((x) => x.user?.id == userAuth.user?.id) &&
                !licence.relationships?.licenceParent?.data && licence.attributes?.notesNum > 0
              ) {
                setLicenceData(licence);
                const standardLicence = response.data.find(
                  (x) => x.attributes?.licenceUsers?.find((y) => y.user?.id == userAuth.user?.id) && !x.relationships?.licenceParent?.data && x.attributes?.active && x.attributes?.licenceType === LicenceEnum.STANDARD && licence.attributes?.notesNum > 0 && moment().isBefore(moment(licence?.attributes?.to).startOf('day'))
                );
                const premiumLicence = response.data.find(
                  (x) => x.attributes?.licenceUsers?.find((y) => y.user?.id == userAuth.user?.id) && !x.relationships?.licenceParent?.data && x.attributes?.active && x.attributes?.licenceType === LicenceEnum.PREMIUM && licence.attributes?.notesNum > 0 && moment().isBefore(moment(licence?.attributes?.to).startOf('day'))
                );
                if (standardLicence && standardLicence.attributes?.notesNum > 0) setLicenceData(standardLicence);
                if (premiumLicence && premiumLicence.attributes?.notesNum > 0) setLicenceData(premiumLicence);
              }
            });
          }
        },
        (error: Error) => {
          notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
        }
      );
    }
  }, [orgUnitInfo?.data]);

  const showConfirm = () => {
    confirm({
      title: i18n.t('notes.confirmDelete'),
      icon: <ExclamationCircleOutlined />,
      content: i18n.t('common.cantUndo'),
      okText: i18n.t('genericButtons.yes'),
      cancelText: i18n.t('genericButtons.cancel'),
      onOk() {
        for (let i = 0; i <= (noteLinkData?.notesLinks?.data?.length as number); i++) {
          noteLinkData.notesLinks?.data.forEach((link) => {
            dispatch(deleteNoteLinkAction(link?.id as number));
          });
        }

        notification['success']({ message: i18n.translate('api.successDelete'), duration: 2 });
        dispatch(deleteNoteAction(note?.currentNote?.id as number));
        
        setTimeout(() => {
          NavigationService.navigate(AppRoutes.NOTES.fullPath);
        }, 1000);
      },
    });
  };

  const menu = (
    <Menu
      items={[
        {
          label:
            licenceData?.attributes?.licenceType == LicenceEnum.STANDARD ||
            licenceData?.attributes?.licenceType == LicenceEnum.FREE ? (
              <Popover
                placement="topRight"
                content={i18n.translate(`common.licence.content`)}
                title={i18n.translate(`common.licence.title`)}
              >
                <Button disabled className="cardBtn" type="link">
                  {i18n.translate(`notes.actions.download`)}
                </Button>
              </Popover>
            ) : (
              <Button
                onClick={() => printService.printNoteToPdf(note?.currentNote) }
                className="cardBtn"
                type="link"
              >
                {i18n.translate(`notes.actions.download`)}
              </Button>
            ),
          key: '0',
        },
        {
          label: (
            <Button
              danger
              type="link"
              onClick={() => {
                showConfirm();
              }}
            >
              {i18n.translate(`notes.actions.delete`)}
            </Button>
          ),
          key: '1',
        },
      ]}
    />
  );

  return (
    <div className="note w100-h100">
      {note.currentNote?.attributes ? (
        <>
          <div className="note___breadcrumbs">
            <Breadcrumb>
              <Breadcrumb.Item>
                <CommentOutlined />
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to={AppRoutes.NOTES.fullPath} key={AppRoutes.NOTES.fullPath}>
                  {i18n.translate('breadcrumbs.notes')}
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <span>{note.currentNote?.attributes?.title}</span>
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <Row justify="space-between" align="middle">
            <Col>
              <div className="note__title">{note.currentNote?.attributes?.title}</div>
            </Col>
            <Col>
              <Space>
                <Dropdown overlay={menu} trigger={['click']}>
                  <Button className="button" size="large" style={{ fontSize: 16, fontWeight: 700, borderRadius: 0 }}>
                    <Space>
                      {i18n.translate(`notes.noteView.actions`)}
                      <DownOutlined />
                    </Space>
                  </Button>
                </Dropdown>
                <Button
                  className="button"
                  type="primary"
                  size="large"
                  style={{ fontSize: 16, fontWeight: 700, borderRadius: 0 }}
                  onClick={() => {
                    NavigationService.navigate(AppRoutes.NOTES.fullPath + `/${id}/edit`);
                  }}
                >
                  {i18n.translate(`notes.noteView.edit`)}
                </Button>
              </Space>
            </Col>
          </Row>

          <Row gutter={[16, 24]}>
            {note.currentNote.attributes.notesObject !== null && (
              <Col span={24}>
                <Space direction="vertical">
                  <Text strong>{i18n.translate(`notes.noteView.linkedContent`)}</Text>
                  <Text type="secondary">{note.currentNote?.attributes?.breadcrumb?.toUpperCase()}</Text>
                  {note.currentNote?.attributes?.notesObject?.title ||
                    note.currentNote?.attributes?.notesObject?.verdictTitle ||
                    note.currentNote?.attributes?.notesObject?.name ||
                    note.currentNote?.attributes?.notesObject?.titleContent ||
                    note.currentNote?.attributes?.notesObject?.verdictName ||
                    note.currentNote?.attributes?.notesObject?.materialTitle}
                </Space>
              </Col>
            )}

            {(noteLinkData?.notesLinks?.data?.length as number) > 0 ? (
              <>
                <Col span={24}>
                  <Space direction="vertical">
                    <Text strong>{i18n.translate(`notes.noteView.externalContent`)}</Text>
                    {noteLinkData?.notesLinks?.data?.map((noteLink: NoteLinkInterface) => (
                      <Button
                        style={{ whiteSpace: 'pre-wrap', height: 'auto', textAlign: 'left', overflowWrap: 'anywhere' }}
                        key={noteLink.id}
                        onClick={() => {
                          window.open(noteLink?.attributes?.link, '_blank');
                        }}
                      >
                        {noteLink?.attributes?.link}
                      </Button>
                    ))}
                  </Space>
                </Col>
              </>
            ) : (
              <></>
            )}

            <Col span={24}>
              <Space direction="vertical">
                <Text strong>{i18n.translate(`notes.noteView.note`)}</Text>
                <Text style={{ whiteSpace: 'pre-wrap' }}>{note.currentNote?.attributes?.text}</Text>
              </Space>
            </Col>
          </Row>
        </>
      ) : null}
    </div>
  );
}

export default NoteViewPageComponent;
