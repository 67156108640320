import { NoteLinkConstants } from './notesLinks.constants';
import {
  NoteLinkResponseInterfaceSingle,
  NoteLinkResponseInterfaceAll,
  ReducerActionInterface,
  NoteLinkInterface,
} from '../../interfaces';

// ============ INTERFACE ============

export interface NoteLinkStateInterface {
  currentNoteLink?: NoteLinkInterface;
  notesLinks?: NoteLinkResponseInterfaceAll;
  error?: string;
}

// ============ INIT STATE ============

const initialState: NoteLinkStateInterface = {
  currentNoteLink: undefined,
  notesLinks: {
    data: [],
    meta: {
      totalResourceCount: 0,
    },
  },
};

// ============ REDUCERS ============

const getSingleSuccess = (
  state: NoteLinkStateInterface,
  payload: NoteLinkResponseInterfaceSingle
): NoteLinkStateInterface => {
  return {
    ...state,
    currentNoteLink: payload.data,
  };
};

const getAllSuccess = (
  state: NoteLinkStateInterface,
  payload: NoteLinkResponseInterfaceAll
): NoteLinkStateInterface => {
  return {
    ...state,
    notesLinks: {
      data: payload.data,
      meta: payload.meta,
    },
  };
};

const createSuccess = (
  state: NoteLinkStateInterface,
  payload: NoteLinkResponseInterfaceSingle
): NoteLinkStateInterface => {
  return {
    ...state,
    currentNoteLink: payload.data,
  };
};

const updateSuccess = (
  state: NoteLinkStateInterface,
  payload: NoteLinkResponseInterfaceSingle
): NoteLinkStateInterface => {
  return {
    ...state,
    currentNoteLink: payload.data,
  };
};

const clearCurrent = (state: NoteLinkStateInterface): NoteLinkStateInterface => {
  return {
    ...state,
    currentNoteLink: undefined,
  };
};

const error = (state: NoteLinkStateInterface, payload: string) => {
  return {
    ...state,
    error: payload,
  };
};

// ============ EXPORTS ============

export const NotesLinksReducer = (state = initialState, action: ReducerActionInterface) => {
  switch (action.type) {
    case NoteLinkConstants.GET_SINGLE_SUCCESS:
      return getSingleSuccess(state, action.payload);
    case NoteLinkConstants.GET_ALL_SUCCESS:
      return getAllSuccess(state, action.payload);
    case NoteLinkConstants.CREATE_SUCCESS:
      return createSuccess(state, action.payload);
    case NoteLinkConstants.UPDATE_SUCCESS:
      return updateSuccess(state, action.payload);
    case NoteLinkConstants.CLEAR_CURRENT:
      return clearCurrent(state);
    case NoteLinkConstants.ERROR:
      return error(state, action.payload);
    default:
      return state;
  }
};
