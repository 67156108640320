import { Button, Col, Row, Typography, Image, FormInstance, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import { NewMemberCardComponent, NoDataComponent, TeamTableComponent, UpgradeModal, UsersModal, YearDividerComponent } from '../../../index';
import { i18n } from '../../../../services';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { OrganizationUnitService, LicenceService, PriceService, FullUserService, UpgradeRequestService } from '../../../../api';
import { JavaFormat } from '../../../../constants';
import { LicenceEnum } from '../../../../enums/licence.enum';
import { FullUserResponseInterfaceAll, LicenceInterface, FullOrganizationUnitResponseInterfaceSingle, PriceInterface, LicenceResponseInterfaceAll, PriceResponseInterfaceAll } from '../../../../interfaces';
import { StoreStateInterface, clearFullUserAction, getSingleFullUserAction, createLicenceAction } from '../../../../redux';

const { Text, Title } = Typography;

export default function TeamComponent(/**props ? */) {
  const location = useLocation();
  const path = location.pathname.split('/');

  const dispatch = useDispatch();
  const userAuth = useSelector((state: StoreStateInterface) => state.auth);
  const id = userAuth?.user?.id;
  const user = useSelector((state: StoreStateInterface) => state.fullUser);
  const [unitUsers, setUnitUsers] = useState<FullUserResponseInterfaceAll>();
  const [userLicences, setUserLicences] = useState<LicenceInterface[]>([]);
  const [oldUserLicences, setOldUserLicences] = useState<LicenceInterface[]>([]);
  const [orgUnitInfo, setOrgUnitInfo] = useState<FullOrganizationUnitResponseInterfaceSingle>();
  const [licenceInfo, setLicenceInfo] = useState<LicenceInterface>();
  const [oldLicencesInfo, setOldLicencesInfo] = useState<LicenceInterface[]>([]);
  const [unusedLicencesInfo, setUnusedLicencesInfo] = useState<LicenceInterface[]>([]);
  const [priceInfo, setPriceInfo] = useState<PriceInterface>();
  
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    dispatch(clearFullUserAction());
    dispatch(getSingleFullUserAction(id as number));
  }, [id]);

  useEffect(() => {
    if (user.currentFullUser?.relationships?.organizationUnit?.data?.id) {
      OrganizationUnitService.getSingle(user.currentFullUser?.relationships?.organizationUnit?.data?.id).subscribe(
        (response: FullOrganizationUnitResponseInterfaceSingle) => {
          setOrgUnitInfo(response);
        },
        (error: Error) => {
          notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
        }
      );
    }
  }, [user.currentFullUser]);

  useEffect(() => {
    if (orgUnitInfo?.data?.id) {
      LicenceService.getAll(undefined, `filter[organizationUnit.id]=${orgUnitInfo?.data?.id}`, 'sort=id', `page[offset]=0&page[limit]=999999`).subscribe(
        (response: LicenceResponseInterfaceAll) => {
          if (response?.data?.length) {
            let oldLicences: LicenceInterface[] = [];

            response?.data?.forEach(licence => {
              if (licence.attributes?.licenceUsers?.find(x => x.user?.id == id) && !licence.relationships?.licenceParent?.data) {
                setLicenceInfo(licence);
                oldLicences.push(licence);
              }
            });

            setOldLicencesInfo([...oldLicences]);
          }
        },
        (error: Error) => {
          notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
        }
      );
    }
  }, [orgUnitInfo?.data]);

  useEffect(() => {
    if (orgUnitInfo?.data?.id) {
      PriceService.getAll(orgUnitInfo?.data?.id).subscribe(
        (response: PriceResponseInterfaceAll) => {
          if (response?.data?.length && response.data[0]) {
            setPriceInfo(response?.data[0]);
          }
        },
        (error: Error) => {
          notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
        }
      );
    }
  }, [orgUnitInfo?.data]);

  useEffect(() => {
    if (orgUnitInfo?.data?.id) {
      FullUserService.getAll(undefined, `filter[organizationUnit.id]=${orgUnitInfo?.data?.id}`).subscribe(
        (response: FullUserResponseInterfaceAll) => {
          setUnitUsers(response);
        },
        (error: Error) => {
          notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
        }
      );
    }
  }, [orgUnitInfo?.data]);

  useEffect(() => {
    if (unitUsers?.data) {
      let currentLicences: LicenceInterface[] = [];
      let oldLicences: LicenceInterface[] = [];
      let unusedLicences: LicenceInterface[] = [];

      let userIds: (number | undefined)[] = unitUsers?.data?.map(user => user.id);

      LicenceService.getAll(undefined, `filter[organizationUnit.id]=${orgUnitInfo?.data?.id}`, 'sort=id', `page[offset]=0&page[limit]=999999`).subscribe(
        (response: LicenceResponseInterfaceAll) => {
          if (response?.data?.length) {
            response?.data?.forEach(licence => {
              userIds?.forEach(userId => {
                if (licence.attributes?.licenceUsers?.find(x => x.user?.id == userId) && !licence.relationships?.licenceParent?.data && licence.attributes?.active) {
                  currentLicences.push(licence);
                }
                if (licence.attributes?.licenceUsers?.find(x => x.user?.id == userId) && !licence.relationships?.licenceParent?.data && !licence.attributes?.active) {
                  oldLicences.push(licence);
                }
                if (!licence.attributes?.licenceUsers?.length && !licence.relationships?.licenceParent?.data) {
                  unusedLicences.push(licence);
                }
              });
            });

            setUserLicences([...currentLicences]);
            setOldUserLicences([...oldLicences]);
            setUnusedLicencesInfo([...unusedLicences]);
          }
        },
        (error: Error) => {
          notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
        }
      );
    }
  }, [unitUsers?.data]);

  const filterUnusedPremium = () => {
    return unusedLicencesInfo.filter(x => x.attributes?.licenceType === LicenceEnum.PREMIUM);
  };

  const filterUnusedBasic = () => {
    return unusedLicencesInfo.filter(x => x.attributes?.licenceType === LicenceEnum.STANDARD);
  };

  const showModal = () => {
    setVisible(true);
  };

  const handleUpgradeOk = (form: FormInstance<any>, price: number, isUpgrade: boolean | undefined, isNew: boolean | undefined, isTrial: boolean | undefined) => {
    let values = form.getFieldsValue(true);

    let numberArrayYears: number[] = values?.years?.map((stringYear: string) => parseInt(stringYear));

    let latestYear = Math.max.apply(Math, numberArrayYears);
    //var index = values?.years?.indexOf(latestYear.toString());
    //if (index !== -1) {
      //values?.years?.splice(index, 1);
    //}

    let subs = '0';
    if (Array.isArray(values.subs)) subs = values.subs?.length?.toString();
    if (!Array.isArray(values.subs)) subs = values.subs;

    let extra = {
      isUpgrade: isUpgrade ? true : false,
      isNew: isNew ? true : false,
      isTrial: isTrial ? true : false,
      oldModel: licenceInfo?.attributes?.licenceType,
      oldYear: latestYear.toString(),
      oldFrom: moment(latestYear.toString()).startOf('year').format(JavaFormat),
      oldTo: moment(latestYear.toString()).add(1, 'y').set({'date': 20, 'month': 1}).format(JavaFormat),
      bothYearsSubscription: parseInt(subs) > 1,
      biltenSubscriptionYears: [values.subs].flat(),
      price: price
    }

    const handleSingleYears = () => {
      if (values?.model === LicenceEnum.PREMIUM) {
        if (values?.years?.includes(latestYear.toString()) && values?.years?.includes((latestYear - 1)?.toString())) {
          if (moment().isSame(moment().set('month', 10), 'month')) {
            if ([values.subs].flat().includes('2')) return latestYear.toString();
            if ([values.subs].flat().includes('1')) return (latestYear - 1).toString();
          }
          else return latestYear.toString();
        }
        return latestYear.toString();
      } else {
        if (values?.years?.includes(latestYear.toString()) && values?.years?.includes((latestYear - 1)?.toString())) {
          // if (parseInt(subs) == 0) return `${latestYear},${latestYear - 1}`;
          if (parseInt(subs) == 0) return latestYear.toString();
          if (moment().isSame(moment().set('month', 10), 'month')) {
            if ([values.subs].flat().includes('2')) return latestYear.toString();
            if ([values.subs].flat().includes('1')) return (latestYear - 1).toString();
          }
          else return latestYear.toString();
        }
        return latestYear.toString();
      }
    };

    let upgradeBody = {
      data: {
        type: 'upgrade_request' as 'upgrade_request',
        attributes: {
          licenceType: values?.model,
          printedCopyNum: parseInt(subs) > 1 ? 3 : parseInt(subs) * 3,
          printedCopyYears: parseInt(subs) > 1 ? `${latestYear},${latestYear - 1}` : handleSingleYears(),
          additionalYears: values?.years?.length ? values?.years?.join(',') : null,
          status: 'ZAPRIMLJENO',
          createdDate: moment().format(JavaFormat),
          extra: JSON.stringify(extra),
        },
        relationships: {
          user: {
            data: {
              id: id as number,
              type: "user" as 'user'
            }
          },
        }
      }
    }

    UpgradeRequestService.create(upgradeBody).subscribe(
      (response: any) => {
        dispatch(clearFullUserAction());
        dispatch(getSingleFullUserAction(id as number));
        notification['success']({ message: i18n.translate('api.successMessage'), duration: 2 });
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
      }
    );

    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleApprove = () => {
    dispatch(clearFullUserAction());
    dispatch(getSingleFullUserAction(id as number));
  };

  return (
    <div className="team">
      <Title className="team__title" level={5}>
        {i18n.translate('profile.myTeam.users')}
      </Title>
      {user.currentFullUser && orgUnitInfo?.data /**&& licenceInfo && priceInfo && unitUsers?.data*/ ? (
      <>
      <div className="team___content">
        <TeamTableComponent orgUnitInfo={orgUnitInfo} unitUsers={unitUsers?.data} userLicences={userLicences} oldUserLicences={oldUserLicences} handleApprove={handleApprove} />
      </div>

      {/**<Title className="team__title" level={5}>
        {i18n.translate('profile.myTeam.newUsers')}
      </Title>*/}
      <div className="team___content">
        {/**<Col className="team___notice">
          <Row justify="center">
            <Image
              preview={false}
              width={100}
              alt="EMPTY"
              src={require('../../../../../assets/images/empty-img-gray.png')}
            />
          </Row>
          <Row justify="center">
            <Text>{i18n.translate('profile.myTeam.notice', { number: '2' })}</Text>
          </Row>
          <Row justify="center">
            <Button>{i18n.translate('profile.unlock.users')}</Button>
          </Row>
        </Col>*/}
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
          <Title className="team__title" style={{color: 'black'}} level={5}>
            {i18n.translate('profile.myTeam.modelStandard')}
          </Title>
          {/** <Button type="primary" onClick={showModal}>{i18n.translate('profile.myTeam.addSubscription')}</Button> */}
        </div>
        <YearDividerComponent title={''} />
        {filterUnusedBasic()?.map((licence, index) => (
          <NewMemberCardComponent key={'premium' + index} licence={licence} unitUsers={unitUsers} orgUnitId={orgUnitInfo?.data?.id} />
        ))}
        {!filterUnusedBasic()?.length ? <NoDataComponent text={i18n.translate('profile.myTeam.standardEmpty')} /> : null}

        <Title className="team__title" style={{color: 'black'}} level={5}>
          {i18n.translate('profile.myTeam.modelPremium')}
        </Title>
        <YearDividerComponent title={''} />
        {filterUnusedPremium()?.map((licence, index) => (
          <NewMemberCardComponent key={'premium' + index} licence={licence} unitUsers={unitUsers} orgUnitId={orgUnitInfo?.data?.id} />
        ))}
        {!filterUnusedPremium()?.length ? <NoDataComponent text={i18n.translate('profile.myTeam.premiumEmpty')} /> : null}
      </div>
      </>
      ) : null}
      <UpgradeModal isNew licenceInfo={licenceInfo} visible={visible} handleOk={handleUpgradeOk} handleCancel={handleCancel} teamScreen />
    </div>
  );
}
