import { Card, Select, Space, Typography } from 'antd';
import { CheckCircleFilled } from '@ant-design/icons';
import React from 'react';
import { i18n } from '../../../services';

const { Title, Text } = Typography;

interface Props {
  type: string;
  users: any;
  priceHRK: number;
  priceEUR: number;
  handleChange?: any;
  handleChangePremium?: any;
  selected: boolean;
  userNo?: number;
  isPremium?: boolean;
}

export default function LicenceCardComponent({
  type,
  users,
  priceHRK,
  priceEUR,
  handleChange,
  handleChangePremium,
  selected,
  userNo,
  isPremium,
}: Props) {
  const getTitle = (type: string) => {
    switch (type) {
      case 'starter':
        return i18n.t('register.licence.starter');
      case 'standard':
        return i18n.t('register.licence.standard');
      case 'premium':
        return i18n.t('register.licence.premium');
      default:
        return i18n.t('register.licence.starter');
    }
  };

  const getSubtitle = (type: string) => {
    switch (type) {
      case 'starter':
        return i18n.t('register.licence.subs.starter');
      case 'standard':
        return i18n.t('register.licence.subs.standard');
      case 'premium':
        return i18n.t('register.licence.subs.premium');
      default:
        return i18n.t('register.licence.subs.starter');
    }
  };

  const getModelText = (type: string) => {
    switch (type) {
      case 'starter':
        return i18n.t('adminProfile.tabs.starter');
      case 'standard':
        return i18n.t('adminProfile.tabs.basic');
      case 'premium':
        return i18n.t('adminProfile.tabs.premium');
      default:
        return i18n.t('adminProfile.tabs.starter');
    }
  };

  const getList = (type: string) => {
    switch (type) {
      case 'starter':
        return 'Pregled sadržaja Biltena, Novosti putem newslettera, Pristup kategoriji Stručni članci, Pristup kategoriji Propisi, Pregled Mreže znanja ';
      case 'standard':
        return '3 internet izdanja BJN-a, Otključana kategorija Mreža znanja, Do 7 savjeta godišnje (telefonom), Povoljnija kupnja tiskanog izdanja, 3 predavanja* ';
      case 'premium':
        return 'Sve iz Osnovnog modela, 3 Internet i 3 tiskana izdanja BJN-a, Otključana kategorija Pravna zaštita, Do 8 savjeta godišnje (telefonom), 6 predavanja*';
      default:
        return '';
    }
  };

  const data = getList(type).split(',');

  const currencyFormat = (price: any) => parseFloat(price).toLocaleString('hr-HR', {minimumFractionDigits:2, maximumFractionDigits:2});

  return (
    <div className="licenceCard">
      <Card bordered hoverable style={{ height: 650, borderRadius: 0 }} className={selected ? 'active' : 'inactive'}>
        <Space direction="vertical" size={'large'}>
          <Space direction="vertical" size={'small'}>
            <Space>
              <Title level={3} className={'licenceCard___title'}>
                {getTitle(type)}
              </Title>
              {selected ? <CheckCircleFilled className="icon" /> : <></>}
            </Space>
            <Text strong>{getSubtitle(type)}</Text>
          </Space>
          <Space direction="vertical" className="content">
            {priceEUR === 0 ? (
              <>
                <span>{i18n.translate(`register.licence.yearlyTitle`)}</span>
                <Title level={3}>0 EUR</Title>
                <Title level={5}>{i18n.translate(`register.licence.noFee`)}</Title>
              </>
            ) : (
              <>
                <span>{i18n.translate(`register.licence.yearlyTitle`)}</span>
                <Title level={3}>{currencyFormat(priceEUR)} EUR <span style={{ fontSize: '.75rem', opacity: '.8' }}>+PDV</span></Title>
              </>
            )}
          </Space>

          {type == 'standard' || type == 'premium' ? (
            <Select
              disabled={!selected}
              value={userNo}
              style={{ width: 191 }}
              onClick={(e) => {
                e.stopPropagation();
              }}
              onChange={isPremium ? handleChangePremium : handleChange}
              options={users}
            />
          ) : (
            <Text>1 korisnik</Text>
          )}
          <Space direction="vertical" >
            <Text style={{ marginBottom: '12px', display: 'inherit' }} strong>{getModelText(type)} {i18n.translate(`register.licence.included`)}:</Text>
            <ul>
              {data?.map((item, index) => (
                <li key={'preview' + index}>{item}</li>
              ))}
            </ul>
          </Space>
        </Space>
      </Card>
    </div>
  );
}
