import React, { useEffect, useState } from 'react';
import { Button, DatePicker, Pagination, PaginationProps, Select, Space, Table, TablePaginationConfig, notification } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { clearFullUserAction, clearFullUserListAction, DisplayDateFormat, FullUserInterface, getAllFullUsersAction, getAllUserLogStatisticsAction, getPeriodEndDate, getPeriodLabel, getPeriodStartDate, i18n, JavaFormat, PeriodEnum, Periods, QueryFilter, SorterInterface, StoreStateInterface, UserLogStatisticsResponseInterfaceAll, UserLogStatisticsService, UserLogStatisticsStateInterface } from '../../../../common';
import { useDispatch, useSelector } from 'react-redux';
import { UserActionTypeEnum, getUserActionLabel, translateDomainString } from '../../../../common/enums/userActionType.enum';
import moment from 'moment';
import localeEN from 'antd/es/date-picker/locale/en_GB';
import localeHR from 'antd/es/date-picker/locale/hr_HR';
import { FilterValue, SorterResult, TableCurrentDataSource } from 'antd/lib/table/interface';
import StatisticDetailsDrawer from '../../../../common/components/statisticDetailsDrawer/statisticDetailsDrawer.component';

const { RangePicker } = DatePicker;

function UserStatisticsComponent() {
  const dispatch = useDispatch();

  const userAuth = useSelector((state: StoreStateInterface) => state.auth);

  const fullUser = useSelector((state: StoreStateInterface) => state.fullUser);
  const userLogStatistic: UserLogStatisticsStateInterface = useSelector((state: StoreStateInterface) => state.userLogStatistic);

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const [userId, setUserId] = useState('');
  const [actionType, setActionType] = useState('LOGIN');
  const [period, setPeriod] = useState('1');
  const [dateRange, setDateRange] = useState([moment().startOf('day').format(JavaFormat), moment().endOf('day').format(JavaFormat)]);

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerTableRecord, setDrawerTableRecord] = useState(undefined);

  useEffect(() => {
    localStorage.removeItem('transferFilters');
    dispatch(clearFullUserListAction());
    dispatch(clearFullUserAction());
    dispatch(getAllFullUsersAction(undefined, undefined, 'sort=-id', `page[offset]=0&page[limit]=999999`));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (fullUser) {
      dispatch(getAllUserLogStatisticsAction(userId, `${actionType ? `action=${actionType}` : ''}&startDateTime=${dateRange[0]}&endDateTime=${dateRange[1]}`, undefined, `page=${page - 1}&size=${pageSize}`));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fullUser]);

  const onPaginationChange: PaginationProps['onChange'] = (page, pageSize) => {
    setPage(page);
    setPageSize(pageSize);
    dispatch(
      getAllUserLogStatisticsAction(
        userId,
        `${actionType ? `action=${actionType}` : ''}&startDateTime=${dateRange[0]}&endDateTime=${dateRange[1]}`,
        undefined,
        `page=${page - 1}&size=${pageSize}`
      )
    );
  };

  const onChangeTable = (pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>, sorter: SorterResult<any> | SorterResult<any>[], extra: TableCurrentDataSource<any>): void => {
    let sort: SorterInterface | undefined;
    //@ts-ignore
    if (Object.keys(sorter).length > 0 && sorter.column !== undefined) {
      sort = {
        //@ts-ignore
        field: sorter.field.toString() === "verdict" ? "hasVerdict" : sorter.field.toString(),
        //@ts-ignore
        order: sorter.order === "descend" ? "Desc" : "Asc"
      }
    }
    let qf: Array<QueryFilter> = new Array<QueryFilter>();
    for (let key in filters) {
      if (filters[key] !== null && !!filters[key] && filters[key]!.length > 0) {
        if (key === 'type') {
          qf.push({ name: key, value: filters[key]! as number[]});
        } else {
          qf.push({ name: key, value: filters[key]![0].toString(), exactMatch: filters[key]![1] as boolean });
        }
      }
    }
    //setQueryFilter(qf)
    //setSortBy(sort)
    //refetch(filterValues, 'decision', qf, sort, true)
  }

  const onUserChange = (value: string) => {
    setUserId(value);
    setPage(1);
    dispatch(
      getAllUserLogStatisticsAction(
        value,
        `${actionType ? `action=${actionType}` : ''}&startDateTime=${dateRange[0]}&endDateTime=${dateRange[1]}`,
        undefined,
        `page=${0}&size=${pageSize}`
      )
    );
  };

  const onActionChange = (value: string) => {
    setActionType(value);
    setPage(1);
    dispatch(
      getAllUserLogStatisticsAction(
        userId,
        `${value ? `action=${value}` : ''}&startDateTime=${dateRange[0]}&endDateTime=${dateRange[1]}`,
        undefined,
        `page=${0}&size=${pageSize}`
      )
    );
  };

  const onPeriodChange = (value: string) => {
    setDateRange([getPeriodStartDate(parseInt(value)), getPeriodEndDate(parseInt(value))]);
    setPeriod(value);
    setPage(1);
    dispatch(
      getAllUserLogStatisticsAction(
        userId,
        `${actionType ? `action=${actionType}` : ''}&startDateTime=${getPeriodStartDate(parseInt(value))}&endDateTime=${getPeriodEndDate(parseInt(value))}`,
        undefined,
        `page=${0}&size=${pageSize}`
      )
    );
  };

  const onDateRangeChange = (values: any, stringValues: Array<string>) => {
    setDateRange([moment(stringValues[0], DisplayDateFormat).startOf('day').format(JavaFormat), moment(stringValues[1], DisplayDateFormat).endOf('day').format(JavaFormat)]);
    setPage(1);
    dispatch(
      getAllUserLogStatisticsAction(
        userId,
        `${actionType ? `action=${actionType}` : ''}&startDateTime=${moment(stringValues[0], DisplayDateFormat).startOf('day').format(JavaFormat)}&endDateTime=${moment(stringValues[1], DisplayDateFormat).endOf('day').format(JavaFormat)}`,
        undefined,
        `page=${0}&size=${pageSize}`
      )
    );
  };
  
  const onUserSearch = (value: string) => {
    //console.log('search:', value);
  };

  const UserLogStatistics = (props: any) => {
    const dataSource = userLogStatistic?.userLogStatistics?.data?.map((userLogStatistic, index) => {
      return {
        key: `${userLogStatistic?.action}${index}`,
        ...userLogStatistic,
      };
    });
    
    const columns = [
      {
        title: 'Akcija',
        dataIndex: 'action',
        key: 'action',
        show: true,
        render: (text: any, record: any, index: any) => {
          return (<span>{getUserActionLabel(text)}</span>);
        }
      },
      {
        title: 'Broj puta',
        dataIndex: 'count',
        key: 'count',
        show: true,
      },
      {
        title: 'IP Adresa',
        dataIndex: 'ipAddress',
        key: 'ipAddress',
        show: actionType == UserActionTypeEnum.LOGIN || actionType == UserActionTypeEnum.LOGOUT,
        render: (text: any, record:any, index: any) => {
          return (<span>{text ? text : '—'}</span>);
        }
      },
      {
        title: 'Detalji',
        dataIndex: 'extra',
        key: 'extra',
        show: true,
        render: (text: any, record:any, index: any) => {
          return (<span>{getExtraLabel(record)}</span>);
        }
      },
      {
        title: '',
        dataIndex: 'users',
        key: 'users',
        show: actionType != UserActionTypeEnum.LOGIN && actionType != UserActionTypeEnum.LOGOUT,
        width: '100px',
        render: (text: any, record:any, index: any) => {
          return (<Button type="link" onClick={() => {setDrawerTableRecord(record); setDrawerOpen(true)}}>Korisnici</Button>);
        }
      }
    ].filter(x => x.show);

    const columnsUser = [
      {
        title: 'Akcija',
        dataIndex: 'action',
        key: 'action',
        show: true,
        render: (text: any, record:any, index: any) => {
          return (<span>{getUserActionLabel(text)}</span>);
        }
      },
      {
        title: 'Organizacija',
        dataIndex: 'organizationName',
        key: 'organizationName',
        show: true,
        render: (text: any, record:any, index: any) => {
          return (<span>{getOrganization() ? getOrganization() : '—'}</span>);
        }
      },
      {
        title: 'Korisnik',
        dataIndex: 'createdBy',
        key: 'createdBy',
        show: true,
        render: (text: any, record:any, index: any) => {
          return (<span>{getUser() ? getUser() : '—'}</span>);
        }
      },
      {
        title: 'Broj puta',
        dataIndex: 'count',
        key: 'count',
        show: true,
      },
      {
        title: 'IP Adresa',
        dataIndex: 'ipAddress',
        key: 'ipAddress',
        show: actionType == UserActionTypeEnum.LOGIN || actionType == UserActionTypeEnum.LOGOUT,
        render: (text: any, record:any, index: any) => {
          return (<span>{text ? text : '—'}</span>);
        }
      },
      {
        title: 'Detalji',
        dataIndex: 'extra',
        key: 'extra',
        show: true,
        render: (text: any, record:any, index: any) => {
          return (<span>{getExtraLabel(record)}</span>);
        }
      }
    ].filter(x => x.show);

    const getUser = () => {
      let userString = '';

      if (userId && fullUser?.fullUsers?.data?.find(x => x.id == parseInt(userId))?.attributes?.name) {
        userString = fullUser?.fullUsers?.data?.find(x => x.id == parseInt(userId))?.attributes?.name!;
      }

      return userString;
    };

    const getOrganization = () => {
      let orgString = '';

      if (userId && fullUser?.fullUsers?.data?.find(x => x.id == parseInt(userId))?.attributes?.organizationName) {
        orgString = fullUser?.fullUsers?.data?.find(x => x.id == parseInt(userId))?.attributes?.organizationName!;
      }

      return orgString;
    };

    const getExtraLabel = (record: any) => {
      switch (record.action) {
        case UserActionTypeEnum.LOGIN:
          return 'Korisnik se prijavio u sustav';
        case UserActionTypeEnum.LOGOUT:
          return 'Korisnik se odjavio iz sustava';
        case UserActionTypeEnum.CATEGORY_ACCESSED:
          return `Korisnik pristupio kategoriji ${translateDomainString(record.extra)}`;
        case UserActionTypeEnum.FILTERS_USED:
          return processFiltersUsed(JSON.parse(record.extra));
        case UserActionTypeEnum.FILE_OPENED:
          return `Korisnik otvorio datoteku ${JSON.parse(record.extra).title ? JSON.parse(record.extra).title : JSON.parse(record.extra).name} (domena ${translateDomainString(JSON.parse(record.extra).type)}) ` + (JSON.parse(record.extra).documentType ? `vrste ${JSON.parse(record.extra).documentType}` : ``);
        case UserActionTypeEnum.FILE_DOWNLOADED:
          return `Korisnik preuzeo datoteku ${JSON.parse(record.extra).title ? JSON.parse(record.extra).title : JSON.parse(record.extra).name} (domena ${translateDomainString(JSON.parse(record.extra).type)}) ` + (JSON.parse(record.extra).documentType ? `vrste ${JSON.parse(record.extra).documentType}` : ``);
        case UserActionTypeEnum.NOTE_CREATED:
          return `Korisnik kreirao bilješku${record.extra && record.extra !== 'notes' ? (' za ' + translateDomainString(record.extra)) : ' bez povezanog sadržaja'}`;
        case UserActionTypeEnum.INSTITUTION_VISITED:
          return `Naziv institucije - ${JSON.parse(record.extra).organizationName}`;
        default:
          return '—';
      }
    };

    const processFiltersUsed = (filters: any) => {
      const domainString = filters[0]?.domain;

      let finalString = domainString ? `Iskorišteni filteri u domeni ${translateDomainString(domainString)}:` : 'Iskorišteni filteri:';

      filters.forEach((filter: any) => {
        finalString += `\n${filter.parentFilterUsed} - iskorišteno iz kategorije: ${filter.childFiltersNum}`;
      });

      return finalString;
    };
    
    return (
      <Table 
        scroll={{ x: 1500, y: 680 }}
        dataSource={dataSource} 
        columns={userId ? columnsUser : columns}
        pagination={false}
        locale={{ 
          emptyText: i18n.t('common.noData'),
          triggerDesc: i18n.t('common.sortDesc'),
          triggerAsc: i18n.t('common.sortAsc'),
          cancelSort: i18n.t('common.cancelSort'),
          filterReset: i18n.t('common.reset'),
        }}
        onChange={onChangeTable}
      />
    );
  };

  const downloadStatisticsCSV = () => {
    const filter = `${actionType ? `action=${actionType}` : ''}&startDateTime=${dateRange[0]}&endDateTime=${dateRange[1]}`;
    const pagination = `page=0&size=999999`;
    UserLogStatisticsService.getAll(userId, filter, undefined, undefined, pagination).subscribe(
      (response: UserLogStatisticsResponseInterfaceAll) => {
        let attrs: Array<string> = [];
        if (userId) {
          attrs = actionType == UserActionTypeEnum.LOGIN || actionType == UserActionTypeEnum.LOGOUT ? ["action", "organizationName", "createdBy", "count", "ipAddress", "extra"] : ["action", "organizationName", "createdBy", "count", "extra"]
        } else {
          attrs = actionType == UserActionTypeEnum.LOGIN || actionType == UserActionTypeEnum.LOGOUT ? ["action", "count", "ipAddress", "extra"] : ["action", "count", "extra"]
        }
        let csvContent = "data:text/csv;charset=utf-8,";

        // Header processing

        const translateHeader = (text: string) => {
          switch (text) {
            case 'id':
              return 'ID';
            case 'action':
              return 'Akcija';
            case 'organizationName':
              return 'Organizacija';
            case 'createdBy':
              return 'Korisnik';
            case 'ipAddress':
              return 'IP adresa';
            case 'createdDate':
              return 'Datum';
            case 'extra':
              return 'Detalji';
            case 'count':
              return 'Broj puta';
            default:
              return '—';
          }
        }

        // Header
        let row = [...attrs].map(x => translateHeader(x)).join(";");
        csvContent += row + "\r\n";

        // Data processing

        const getUser = () => {
          let userString = '';
    
          if (userId && fullUser?.fullUsers?.data?.find(x => x.id == parseInt(userId))?.attributes?.name) {
            userString = fullUser?.fullUsers?.data?.find(x => x.id == parseInt(userId))?.attributes?.name!;
          }
    
          return userString;
        };
    
        const getOrganization = () => {
          let orgString = '';
    
          if (userId && fullUser?.fullUsers?.data?.find(x => x.id == parseInt(userId))?.attributes?.organizationName) {
            orgString = fullUser?.fullUsers?.data?.find(x => x.id == parseInt(userId))?.attributes?.organizationName!;
          }
    
          return orgString;
        };

        const getExtraLabel = (record: any) => {
          switch (record.action) {
            case UserActionTypeEnum.LOGIN:
              return 'Korisnik se prijavio u sustav';
            case UserActionTypeEnum.LOGOUT:
              return 'Korisnik se odjavio iz sustava';
            case UserActionTypeEnum.CATEGORY_ACCESSED:
              return `Korisnik pristupio kategoriji ${translateDomainString(record.extra)}`;
            case UserActionTypeEnum.FILTERS_USED:
              return processFiltersUsed(JSON.parse(record.extra));
            case UserActionTypeEnum.FILE_OPENED:
              return `Korisnik otvorio datoteku ${JSON.parse(record.extra).title ? JSON.parse(record.extra).title : JSON.parse(record.extra).name} (domena ${translateDomainString(JSON.parse(record.extra).type)}) ` + (JSON.parse(record.extra).documentType ? `vrste ${JSON.parse(record.extra).documentType}` : ``);
            case UserActionTypeEnum.FILE_DOWNLOADED:
              return `Korisnik preuzeo datoteku ${JSON.parse(record.extra).title ? JSON.parse(record.extra).title : JSON.parse(record.extra).name} (domena ${translateDomainString(JSON.parse(record.extra).type)}) ` + (JSON.parse(record.extra).documentType ? `vrste ${JSON.parse(record.extra).documentType}` : ``);
            case UserActionTypeEnum.NOTE_CREATED:
              return `Korisnik kreirao bilješku${record.extra && record.extra !== 'notes' ? (' za ' + translateDomainString(record.extra)) : ' bez povezanog sadržaja'}`;
            case UserActionTypeEnum.INSTITUTION_VISITED:
              return `Naziv institucije - ${JSON.parse(record.extra).organizationName}`;
            default:
              return '—';
          }
        };
    
        const processFiltersUsed = (filters: any) => {
          const domainString = filters[0]?.domain;
    
          let finalString = domainString ? `Iskorišteni filteri u domeni ${translateDomainString(domainString)}:` : 'Iskorišteni filteri:';
    
          filters.forEach((filter: any) => {
            finalString += ` ${filter.parentFilterUsed} - iskorišteno iz kategorije: ${filter.childFiltersNum}`;
          });
    
          return finalString;
        };

        const mapData = (key: string, data: any, record: any) => {
          switch (key) {
            case 'action':
              return getUserActionLabel(data);
            case 'organizationName':
              return getOrganization();
            case 'createdBy':
              return getUser();
            case 'extra':
              return getExtraLabel(record);
            default:
              return data;
          }
        }
        
        // Data
        response.content?.forEach((dataRow) => {
          let userAttrs: any = dataRow;
          let row = [...attrs.map(key => mapData(key, userAttrs[key], userAttrs))].join(";");
          csvContent += row + "\r\n";
        });
        
        // Open download
        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", `bjn_statistics_${new Date(dateRange[0]).toISOString().split('T')[0]}-${new Date(dateRange[0]).toISOString().split('T')[0]}.csv`);
        document.body.appendChild(link);
        
        link.click();
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
      }
    );
  }

  return (
    <div className="logs-page w100-h100">
      <div className='logs'>
        <Space style={{ marginBottom: 16 }} wrap={true}>
          <Select
            value={actionType}
            style={{ minWidth: 250 }}
            onChange={onActionChange}
            options={Object.keys(UserActionTypeEnum).map((x: string) => ({ value: x, label: getUserActionLabel(x) }))}
          />
          <Select
            style={{ minWidth: 250 }}
            allowClear
            showSearch
            placeholder="Izaberite korisnika"
            optionFilterProp="children"
            onChange={onUserChange}
            onSearch={onUserSearch}
            filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            options={fullUser.fullUsers?.data?.map((x: FullUserInterface) => ({ value: x.id?.toString(), label: x.attributes?.name }))}
          />
          <Select
            value={period}
            style={{ minWidth: 250 }}
            onChange={onPeriodChange}
            options={Periods.map((p) => ({ value: p.id?.toString(), label: getPeriodLabel(p.id) }))}
          />
          {period == PeriodEnum.CUSTOM.toString() && <RangePicker
            locale={userAuth.lang == 'hr' ? localeHR : localeEN}
            allowClear={false}
            format={DisplayDateFormat}
            onChange={onDateRangeChange}
          />}
        </Space>

        <UserLogStatistics />

        <Button onClick={downloadStatisticsCSV} type="default" style={{ marginTop: 16 }} icon={<DownloadOutlined />}>Preuzmi CSV</Button>

        <Pagination
          style={{ float: 'right', paddingTop: 16, paddingBottom: 16 }}
          onChange={onPaginationChange}
          current={page}
          pageSize={pageSize}
          pageSizeOptions={['10', '20', '50', '100']}
          locale={{ items_per_page: `${i18n.t('pagination.perPage')}` }}
          total={userLogStatistic.userLogStatistics?.meta?.totalResourceCount}
          responsive
          showTotal={(total) => `${i18n.t('pagination.total')}: ${total}`}
          showSizeChanger
        />
      </div>

      { drawerTableRecord ? <StatisticDetailsDrawer tableRecord={drawerTableRecord} open={drawerOpen} startDate={dateRange[0]} endDate={dateRange[1]} onClose={() => { setDrawerOpen(false); setDrawerTableRecord(undefined) }} title='Detalji statistike' /> : null}
    </div>
  );
}

export default UserStatisticsComponent;
