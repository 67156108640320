import React, { useEffect, useState } from 'react';
import {
  AdminBasicInfoComponent,
  AdminSubscriptionModelsComponent,
  AdminPasswordComponent,
  AdminPrintEditionsComponent,
  AdminTeamComponent,
  i18n,
  NavigationService,
  StoreStateInterface,
  getSingleFullUserAction,
  UserRoleEnum,
} from '../../../common';
import { Tabs } from 'antd';
import { useLocation } from 'react-router-dom';
import { AdminRoutes } from '../_router/admin.routes';
import { useDispatch, useSelector } from 'react-redux';

function UserProfilePageComponent() {
  const [selectedTab, setSelectedTab] = useState('1');
  const location = useLocation();
  const path = location.pathname.split('/');
  const id = parseInt(path[path.length - 1]);

  const dispatch = useDispatch();
  
  const userAuth = useSelector((state: StoreStateInterface) => state.auth);
  const user = useSelector((state: StoreStateInterface) => state.fullUser.currentFullUser);

  useEffect(() => {
    localStorage.removeItem('transferFilters');
    dispatch(getSingleFullUserAction(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setTabAfterNav = (tab: string, userId: string) => {
    setSelectedTab(tab);
    NavigationService.navigate(AdminRoutes.USER_PROFILE.fullPath + `/${userId}`);
  };

  const showTab = (): boolean => {
    return userAuth?.user?.id != user?.id && user?.attributes?.roles !== UserRoleEnum.ADMIN;
  };
  
  return (
    <div className="profile w100-h100">
      <div className="profile__title" style={{ display: 'flex', justifyContent: 'space-between' }}>
        {i18n.translate('profile.title')}
      </div>
      <div className="profile___content">
        <Tabs activeKey={selectedTab} onChange={(activeKey) => setSelectedTab(activeKey)} destroyInactiveTabPane>
          <Tabs.TabPane tab={i18n.translate('profile.tabs.basicInfo')} key="1">
            <AdminBasicInfoComponent />
          </Tabs.TabPane>
          {showTab() && <Tabs.TabPane tab={i18n.translate('profile.tabs.subModels')} key="2">
            <AdminSubscriptionModelsComponent />
          </Tabs.TabPane>}
          {showTab() && <Tabs.TabPane tab={i18n.translate('profile.tabs.team')} key="3">
            <AdminTeamComponent setTab={setTabAfterNav} />
          </Tabs.TabPane>}
          <Tabs.TabPane tab={i18n.translate('profile.tabs.password')} key="4">
            <AdminPasswordComponent />
          </Tabs.TabPane>
          {showTab() && <Tabs.TabPane tab={i18n.translate('profile.tabs.printEditions')} key="5">
            <AdminPrintEditionsComponent />
          </Tabs.TabPane>}
        </Tabs>
      </div>
    </div>
  );
}

export default UserProfilePageComponent;
