import React, { useEffect, useState } from 'react';
import {
  BiltenEsResponseInterface,
  BiltenInterface,
  BiltenService,
  BiltenStateInterface,
  clearBiltenAction,
  clearBiltenListAction,
  FilterComponent,
  getAllBiltensAction,
  getScreenTagsByTypeAction,
  i18n,
  LicenceInterface,
  NewsletterCardComponent,
  NoDataComponent,
  PreviewComponent,
  StoreStateInterface,
  TagScreenStateInterface,
  YearDividerComponent,
} from '../../../../common';
import { HomeOutlined } from '@ant-design/icons';
import { Breadcrumb, Button, Collapse, notification, Row, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { AdminRoutes } from '../../_router/admin.routes';
import { CaretRightOutlined } from '@ant-design/icons';
import moment from 'moment';

const { Panel } = Collapse;
const { Text } = Typography;

function NewslettersPageComponent() {
  const dispatch = useDispatch();
  const data: BiltenStateInterface = useSelector((state: StoreStateInterface) => state.bilten);
  const tags: TagScreenStateInterface = useSelector((state: StoreStateInterface) => state.tagScreen);

  const [esData, setEsData] = useState<BiltenEsResponseInterface>();

  const [keyword, setKeyword] = useState('');
  //const [allOpinions, setAllOpinions] = useState(true);
  const [exactMatch, setExactMatch] = useState<boolean>(true);
  
  const [licenceData, setLicenceData] = useState<LicenceInterface>();

  const [esSearch, setEsSearch] = useState(true);

  const esSearchFunction = (allOpinions: boolean, pageSorted: {from: number, size: number}, keyword?: string, tagIds?: any) => {
    BiltenService.elasticSearch(allOpinions, pageSorted, exactMatch, keyword, tagIds).subscribe(
      (response: BiltenEsResponseInterface) => {
        setEsData(response);
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
      }
    );
  };

  useEffect(() => {
    localStorage.removeItem('transferFilters');
    dispatch(clearBiltenAction());
    dispatch(clearBiltenListAction());
    dispatch(getScreenTagsByTypeAction('bilten'));
    dispatch(getAllBiltensAction());
    esSearchFunction(false, {from: 0, size: 9999});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getActual = (biltens: BiltenInterface[]) => {
    let biltenArray = biltens;
    return biltenArray?.find((bilten) => bilten.attributes?.actual);
  };

  const refetch = (allValues: any) => {
    //setEsSearch(true);
    esSearchFunction(false, {from: 0, size: 9999}, keyword, allValues);
  };

  const resetSearch = () => {
    setEsData(undefined);
    setKeyword('');
    //setAllOpinions(true);
    //setEsSearch(false);
    //dispatch(getAllBiltensAction());
    esSearchFunction(false, {from: 0, size: 9999});
  };

  const keywordChanged = (value: string) => {
    setKeyword(value);
  };

  const exactMatchChanged = (exactMatch: boolean) => {
    setExactMatch(exactMatch);
  };

  const convertDataType = (biltens: BiltenEsResponseInterface["hits"]): BiltenInterface[] => {
    return biltens?.map((bilten) => {
      return {
        id: bilten.id,
        type: bilten.index,
        attributes: bilten.source
      };
    })
  };

  const genHeader = (bilten: BiltenInterface | undefined) => {
    return (
      <span className="header">
        <Text className="extraText">
          {bilten?.attributes?.issuedAt ? moment(bilten?.attributes?.issuedAt).format('YYYY.') : ''}
        </Text>
      </span>
    );
  };

  const sortedBiltens = (biltens: BiltenInterface[]) => {
    let years: any = {};

    biltens?.forEach(bilten => {
      let key = bilten.attributes?.issuedAt ? moment(bilten.attributes?.issuedAt).format('YYYY.') : '';
      if (!years[key]) years[key] = [];
      years[key].push(bilten);
    });

    let yearAndData: any = Object.entries(years).filter((x: any) => x[1].length > 0) as any;
    yearAndData = yearAndData.sort((a: any, b: any) => (parseInt(a[0]) > parseInt(b[0]) ? -1 : 1));
    yearAndData.forEach((element: any[][]) => {
      if (element[1] && element[1].length) element[1].sort((a: any, b: any) => (parseInt(a.attributes?.issueNum) > parseInt(b.attributes?.issueNum) ? -1 : 1));
    });
    let data: Array<Array<BiltenInterface>> = yearAndData.map((x: any) => x[1]) as any;
    return data;
  };

  const getDefaultActiveKeys = (biltens: BiltenInterface[]) => {
    let biltenArray = biltens;

    function onlyUnique(value: any, index: any, self: string | any[]) {
      return self.indexOf(value) === index;
    }

    let yearArray: string[] = [];
    let defaultOpenArray: string[] = [];

    biltenArray?.forEach(bilten => {
      yearArray.push(bilten.attributes?.issuedAt ? moment(bilten.attributes?.issuedAt).format('YYYY.') : '');
    });

    let unique = yearArray?.filter(onlyUnique);

    unique?.forEach((entry, index) => {
      defaultOpenArray.push('bilten_sorted' + index);
    });

    if (defaultOpenArray.length === 0) defaultOpenArray.push('bilten_sorted' + 0);
    if (defaultOpenArray.length > 0) defaultOpenArray.push('bilten_sorted' + defaultOpenArray.length);

    return defaultOpenArray;
  };

  return (
    <div className="newsletters w100-h100">
      <div className="newsletters__breadcrumbs">
        <Breadcrumb>
          <Breadcrumb.Item>
            <HomeOutlined />
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span>{i18n.translate('menu.newsletters')}</span>
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="newsletters__admin">
        <div className="title">{i18n.translate('newsletters.title')}</div>
        <Link to={AdminRoutes.NEWSLETTERS.fullPath + `/new`} key={AdminRoutes.NEWSLETTERS.path + `/new`}>
          <Button
            className="button"
            type="primary"
            size="large"
            style={{ fontSize: 16, fontWeight: 700, borderRadius: 0 }}
          >
            {i18n.translate('newsletters.addButton')}
          </Button>
        </Link>
      </div>
      {tags.tagScreens && tags.tagScreens.length ? (
        <FilterComponent aggregation={esData?.aggregation?.buckets} filterAccess onExactMatchChange={exactMatchChanged} keyword={keyword} filters={tags.tagScreens} onFilterChange={refetch} onFilterRemove={resetSearch} onKeywordChange={keywordChanged} />
      ) : null}
      <div className="newsletters__content">

        {esSearch && <>
          <YearDividerComponent title={i18n.translate('newsletters.currentIss')} />
          {getActual(esSearch ? convertDataType(esData?.hits as BiltenEsResponseInterface["hits"]) : data.biltens?.data as BiltenInterface[]) && getActual(esSearch ? convertDataType(esData?.hits as BiltenEsResponseInterface["hits"]) : data.biltens?.data as BiltenInterface[])?.attributes ? (
            <PreviewComponent
              id={getActual(esSearch ? convertDataType(esData?.hits as BiltenEsResponseInterface["hits"]) : data.biltens?.data as BiltenInterface[])?.id}
              title={getActual(esSearch ? convertDataType(esData?.hits as BiltenEsResponseInterface["hits"]) : data.biltens?.data as BiltenInterface[])?.attributes?.name}
              price={getActual(esSearch ? convertDataType(esData?.hits as BiltenEsResponseInterface["hits"]) : data.biltens?.data as BiltenInterface[])?.attributes?.price}
              issue={getActual(esSearch ? convertDataType(esData?.hits as BiltenEsResponseInterface["hits"]) : data.biltens?.data as BiltenInterface[])?.attributes?.issueNum}
              description={getActual(esSearch ? convertDataType(esData?.hits as BiltenEsResponseInterface["hits"]) : data.biltens?.data as BiltenInterface[])?.attributes?.descriptionFive}
              dateIssued={getActual(esSearch ? convertDataType(esData?.hits as BiltenEsResponseInterface["hits"]) : data.biltens?.data as BiltenInterface[])?.attributes?.issuedAt}
              pdf={getActual(esSearch ? convertDataType(esData?.hits as BiltenEsResponseInterface["hits"]) : data.biltens?.data as BiltenInterface[])?.attributes?.pdfUuid}
              contentPdf={getActual(esSearch ? convertDataType(esData?.hits as BiltenEsResponseInterface["hits"]) : data.biltens?.data as BiltenInterface[])?.attributes?.contentPdfUuid}
              biltenContentByUrl={getActual(esSearch ? convertDataType(esData?.hits as BiltenEsResponseInterface["hits"]) : data.biltens?.data as BiltenInterface[])?.attributes?.biltenContentByUrl}
              frontPageUuid={getActual(esSearch ? convertDataType(esData?.hits as BiltenEsResponseInterface["hits"]) : data.biltens?.data as BiltenInterface[])?.attributes?.frontpageUuid}
              type={getActual(esSearch ? convertDataType(esData?.hits as BiltenEsResponseInterface["hits"]) : data.biltens?.data as BiltenInterface[])?.type}
              frontPageUrl={getActual(esSearch ? convertDataType(esData?.hits as BiltenEsResponseInterface["hits"]) : data.biltens?.data as BiltenInterface[])?.attributes?.frontpageUrl}
            />
          ) : (
            <NoDataComponent />
          )}
        </>}

      {esSearch &&
        <>
          {esData?.hits?.length ? <Collapse defaultActiveKey={getDefaultActiveKeys(convertDataType(esData?.hits as BiltenEsResponseInterface["hits"]) as BiltenInterface[])} ghost expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 270 : 90} />}>
          {sortedBiltens(convertDataType(esData?.hits as BiltenEsResponseInterface["hits"]) as BiltenInterface[])?.length
            ? [...sortedBiltens(convertDataType(esData?.hits as BiltenEsResponseInterface["hits"]) as BiltenInterface[])]
                .map((group, index: number) => (
                  <Panel forceRender key={'bilten_sorted' + index} header={genHeader(group?.length && group[0] ? group[0] : undefined)}>
                    <Row wrap gutter={40}>
                      {group?.length ? (
                        [...(group as BiltenInterface[])]
                          ?.map((bilten) => (
                            <NewsletterCardComponent
                              key={'review' + bilten?.id}
                              id={bilten?.id}
                              title={bilten?.attributes?.name}
                              price={bilten?.attributes?.price}
                              dateIssued={bilten?.attributes?.issuedAt}
                              pdf={bilten?.attributes?.pdfUuid}
                              contentPdf={bilten?.attributes?.contentPdfUuid}
                              frontPageUuid={bilten?.attributes?.frontpageUuid}
                              type={bilten?.type}
                              frontPageUrl={bilten?.attributes?.frontpageUrl}
                            />
                          ))
                      ) : (
                        <div className="center">
                          <NoDataComponent />
                        </div>
                      )}
                    </Row>
                  </Panel>
                ))
            : null}
        </Collapse> : <div className="center"><NoDataComponent /></div>}
        </>
      }
      
      {!esSearch &&
        <>
          {data.biltens?.data?.length ? <Collapse defaultActiveKey={getDefaultActiveKeys(data.biltens?.data)} ghost expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 270 : 90} />}>
          {sortedBiltens(data.biltens?.data)?.length
            ? [...sortedBiltens(data.biltens?.data)]
                .map((group, index: number) => (
                  <Panel forceRender key={'bilten_sorted' + index} header={genHeader(group?.length && group[0] ? group[0] : undefined)}>
                    <Row wrap gutter={40}>
                      {group?.length ? (
                        [...(group as BiltenInterface[])]
                          ?.map((bilten) => (
                            <NewsletterCardComponent
                              key={'review' + bilten?.id}
                              id={bilten?.id}
                              title={bilten?.attributes?.name}
                              price={bilten?.attributes?.price}
                              dateIssued={bilten?.attributes?.issuedAt}
                              pdf={bilten?.attributes?.pdfUuid}
                              contentPdf={bilten?.attributes?.contentPdfUuid}
                              frontPageUuid={bilten?.attributes?.frontpageUuid}
                              type={bilten?.type}
                              frontPageUrl={bilten?.attributes?.frontpageUrl}
                            />
                          ))
                      ) : (
                        <div className="center">
                          <NoDataComponent />
                        </div>
                      )}
                    </Row>
                  </Panel>
                ))
            : null}
        </Collapse> : <div className="center"><NoDataComponent /></div>}
        </>
      }
      </div>
    </div>
  );
}

export default NewslettersPageComponent;
