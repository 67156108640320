import React, { useEffect, useState } from 'react';
import { Button, notification, Pagination, PaginationProps, Select, Space, Table, TablePaginationConfig } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { clearFullUserAction, clearFullUserListAction, DisplayDateTimeFormat, FullUserInterface, getAllFullUsersAction, getAllUserActivityLogsAction, i18n, JavaFormat, QueryFilter, SorterInterface, StoreStateInterface, UserActivityLogResponseInterfaceAll, UserActivityLogService, UserActivityLogStateInterface } from '../../../../common';
import { useDispatch, useSelector } from 'react-redux';
import { UserActionTypeEnum, getUserActionLabel, translateDomainString } from '../../../../common/enums/userActionType.enum';
import moment from 'moment';
import { FilterValue, SorterResult, TableCurrentDataSource } from 'antd/lib/table/interface';

function LogsComponent() {
  const dispatch = useDispatch();

  const fullUser = useSelector((state: StoreStateInterface) => state.fullUser);
  const userActivityLog: UserActivityLogStateInterface = useSelector((state: StoreStateInterface) => state.userActivityLog);

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const [userId, setUserId] = useState('');
  const [actionType, setActionType] = useState('');

  useEffect(() => {
    localStorage.removeItem('transferFilters');
    dispatch(clearFullUserListAction());
    dispatch(clearFullUserAction());
    dispatch(getAllFullUsersAction(undefined, undefined, 'sort=-id', `page[offset]=0&page[limit]=999999`));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (fullUser) {
      dispatch(getAllUserActivityLogsAction(userId, actionType ? `filter[action]=${actionType}` : '', 'sort=-id', `page[offset]=${pageSize * (page - 1)}&page[limit]=${pageSize}`));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fullUser]);

  const onPaginationChange: PaginationProps['onChange'] = (page, pageSize) => {
    setPage(page);
    setPageSize(pageSize);
    dispatch(
      getAllUserActivityLogsAction(
        userId,
        actionType ? `filter[action]=${actionType}` : '',
        'sort=-id',
        `page[offset]=${pageSize * (page - 1)}&page[limit]=${pageSize}`
      )
    );
  };

  const onChangeTable = (pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>, sorter: SorterResult<any> | SorterResult<any>[], extra: TableCurrentDataSource<any>): void => {
    let sort: SorterInterface | undefined;
    //@ts-ignore
    if (Object.keys(sorter).length > 0 && sorter.column !== undefined) {
      sort = {
        //@ts-ignore
        field: sorter.field.toString() === "verdict" ? "hasVerdict" : sorter.field.toString(),
        //@ts-ignore
        order: sorter.order === "descend" ? "Desc" : "Asc"
      }
    }
    let qf: Array<QueryFilter> = new Array<QueryFilter>();
    for (let key in filters) {
      if (filters[key] !== null && !!filters[key] && filters[key]!.length > 0) {
        if (key === 'type') {
          qf.push({ name: key, value: filters[key]! as number[]});
        } else {
          qf.push({ name: key, value: filters[key]![0].toString(), exactMatch: filters[key]![1] as boolean });
        }
      }
    }
    //setQueryFilter(qf)
    //setSortBy(sort)
    //refetch(filterValues, 'decision', qf, sort, true)
  }

  const onUserChange = (value: string) => {
    setUserId(value);
    setPage(1);
    dispatch(
      getAllUserActivityLogsAction(
        value,
        actionType ? `filter[action]=${actionType}` : '',
        'sort=-id',
        `page[offset]=${0}&page[limit]=${pageSize}`
      )
    );
  };

  const onActionChange = (value: string) => {
    setActionType(value);
    setPage(1);
    dispatch(
      getAllUserActivityLogsAction(
        userId,
        value ? `filter[action]=${value}` : '',
        'sort=-id',
        `page[offset]=${0}&page[limit]=${pageSize}`
      )
    );
  };
  
  const onUserSearch = (value: string) => {
    //console.log('search:', value);
  };

  const UserLogs = (props: any) => {
    const dataSource = userActivityLog?.userActivityLogs?.data?.map((userActivityLog) => {
      return {
        key: userActivityLog?.id,
        ...userActivityLog?.attributes,
      };
    });
    
    const columns = [
      {
        title: 'Akcija',
        dataIndex: 'action',
        key: 'action',
        render: (text: any, record:any, index: any) => {
          return (<span>{getUserActionLabel(text)}</span>);
        }
      },
      {
        title: 'Organizacija',
        dataIndex: 'organizationName',
        key: 'organizationName',
        render: (text: any, record:any, index: any) => {
          return (<span>{getOrganization(record.createdBy) ? getOrganization(record.createdBy) : '—'}</span>);
        }
      },
      {
        title: 'Korisnik',
        dataIndex: 'createdBy',
        key: 'createdBy',
        render: (text: any, record:any, index: any) => {
          return (<span>{getUser(text) ? getUser(text) : '—'}</span>);
        }
      },
      {
        title: 'IP adresa',
        dataIndex: 'ipAddress',
        key: 'ipAddress',
      },
      {
        title: 'Datum',
        dataIndex: 'createdDate',
        key: 'createdDate',
        render: (text: any, record:any, index: any) => {
          return (<span>{text ? moment(text, JavaFormat).format(DisplayDateTimeFormat) : '—'}</span>);
        }
      },
      {
        title: 'Detalji',
        dataIndex: 'extra',
        key: 'extra',
        render: (text: any, record:any, index: any) => {
          return (<span>{getExtraLabel(record)}</span>);
        }
      }
    ];

    const getUser = (userId: any) => {
      let userString = '';

      const user = userActivityLog.userActivityLogs?.included.find(x => x.id == userId);
      if (user?.attributes?.name) {
        userString = user.attributes?.name;
      }

      return userString;
    };

    const getOrganization = (userId: any) => {
      let orgString = '';

      const user = userActivityLog.userActivityLogs?.included.find(x => x.id == userId);
      if (user?.attributes?.organizationName) {
        orgString = user.attributes?.organizationName;
      }

      return orgString;
    };

    const getExtraLabel = (record: any) => {
      switch (record.action) {
        case UserActionTypeEnum.LOGIN:
          return 'Korisnik se prijavio u sustav';
        case UserActionTypeEnum.LOGOUT:
          return 'Korisnik se odjavio iz sustava';
        case UserActionTypeEnum.CATEGORY_ACCESSED:
          return `Korisnik pristupio kategoriji ${translateDomainString(record.extra)}`;
        case UserActionTypeEnum.FILTERS_USED:
          return processFiltersUsed(JSON.parse(record.extra));
        case UserActionTypeEnum.FILE_OPENED:
          return `Korisnik otvorio datoteku ${JSON.parse(record.extra).title ? JSON.parse(record.extra).title : JSON.parse(record.extra).name} (domena ${translateDomainString(JSON.parse(record.extra).type)}) ` + (JSON.parse(record.extra).documentType ? `vrste ${JSON.parse(record.extra).documentType}` : ``);
        case UserActionTypeEnum.FILE_DOWNLOADED:
          return `Korisnik preuzeo datoteku ${JSON.parse(record.extra).title ? JSON.parse(record.extra).title : JSON.parse(record.extra).name} (domena ${translateDomainString(JSON.parse(record.extra).type)}) ` + (JSON.parse(record.extra).documentType ? `vrste ${JSON.parse(record.extra).documentType}` : ``);
        case UserActionTypeEnum.NOTE_CREATED:
          return `Korisnik kreirao bilješku${record.extra && record.extra !== 'notes' ? (' za ' + translateDomainString(record.extra)) : ' bez povezanog sadržaja'}`;
        case UserActionTypeEnum.INSTITUTION_VISITED:
          return `Naziv institucije - ${JSON.parse(record.extra).organizationName}`;
        default:
          return '—';
      }
    };

    const processFiltersUsed = (filters: any) => {
      const domainString = filters[0]?.domain;

      let finalString = domainString ? `Iskorišteni filteri u domeni ${translateDomainString(domainString)}:` : 'Iskorišteni filteri:';

      filters.forEach((filter: any) => {
        finalString += `\n${filter.parentFilterUsed} - iskorišteno iz kategorije: ${filter.childFiltersNum}`;
      });

      return finalString;
    };
    
    return (
      <Table 
        scroll={{ x: 1500, y: 680 }}
        dataSource={dataSource} 
        columns={columns} 
        pagination={false}
        locale={{ 
          emptyText: i18n.t('common.noData'),
          triggerDesc: i18n.t('common.sortDesc'),
          triggerAsc: i18n.t('common.sortAsc'),
          cancelSort: i18n.t('common.cancelSort'),
          filterReset: i18n.t('common.reset'),
        }}
        onChange={onChangeTable}
      />
    );
  };

  const downloadLogsCSV = () => {
    const filter = actionType ? `filter[action]=${actionType}` : '';
    const sort = 'sort=-id';
    const pagination = `page[offset]=0&page[limit]=999999`;
    UserActivityLogService.getAll(userId, filter, sort, pagination).subscribe(
      (response: UserActivityLogResponseInterfaceAll) => {
        let attrs = ["action", "organizationName", "createdBy", "ipAddress", "createdDate", "extra"];
        let csvContent = "data:text/csv;charset=utf-8,";

        // Header processing

        const translateHeader = (text: string) => {
          switch (text) {
            case 'id':
              return 'ID';
            case 'action':
              return 'Akcija';
            case 'organizationName':
              return 'Organizacija';
            case 'createdBy':
              return 'Korisnik';
            case 'ipAddress':
              return 'IP adresa';
            case 'createdDate':
              return 'Datum';
            case 'extra':
              return 'Detalji';
            default:
              return '—';
          }
        }

        // Header
        let row = ["id", ...attrs].map(x => translateHeader(x)).join(";");
        csvContent += row + "\r\n";

        // Data processing

        const getUser = (userId: any) => {
          let userString = '';
    
          const user = response.included?.find(x => x.id == userId);
          if (user?.attributes?.name) {
            userString = user.attributes?.name;
          }
    
          return userString;
        };

        const getOrganization = (userId: any) => {
          let orgString = '';
    
          const user = userActivityLog.userActivityLogs?.included.find(x => x.id == userId);
          if (user?.attributes?.organizationName) {
            orgString = user.attributes?.organizationName;
          }
    
          return orgString;
        };

        const getExtraLabel = (record: any) => {
          switch (record.action) {
            case UserActionTypeEnum.LOGIN:
              return 'Korisnik se prijavio u sustav';
            case UserActionTypeEnum.LOGOUT:
              return 'Korisnik se odjavio iz sustava';
            case UserActionTypeEnum.CATEGORY_ACCESSED:
              return `Korisnik pristupio kategoriji ${translateDomainString(record.extra)}`;
            case UserActionTypeEnum.FILTERS_USED:
              return processFiltersUsed(JSON.parse(record.extra));
            case UserActionTypeEnum.FILE_OPENED:
              return `Korisnik otvorio datoteku ${JSON.parse(record.extra).title ? JSON.parse(record.extra).title : JSON.parse(record.extra).name} (domena ${translateDomainString(JSON.parse(record.extra).type)}) ` + (JSON.parse(record.extra).documentType ? `vrste ${JSON.parse(record.extra).documentType}` : ``);
            case UserActionTypeEnum.FILE_DOWNLOADED:
              return `Korisnik preuzeo datoteku ${JSON.parse(record.extra).title ? JSON.parse(record.extra).title : JSON.parse(record.extra).name} (domena ${translateDomainString(JSON.parse(record.extra).type)}) ` + (JSON.parse(record.extra).documentType ? `vrste ${JSON.parse(record.extra).documentType}` : ``);
            case UserActionTypeEnum.NOTE_CREATED:
              return `Korisnik kreirao bilješku${record.extra && record.extra !== 'notes' ? (' za ' + translateDomainString(record.extra)) : ' bez povezanog sadržaja'}`;
            case UserActionTypeEnum.INSTITUTION_VISITED:
              return `Naziv institucije - ${JSON.parse(record.extra).organizationName}`;
            default:
              return '—';
          }
        };
    
        const processFiltersUsed = (filters: any) => {
          const domainString = filters[0]?.domain;
    
          let finalString = domainString ? `Iskorišteni filteri u domeni ${translateDomainString(domainString)}:` : 'Iskorišteni filteri:';
    
          filters.forEach((filter: any) => {
            finalString += ` ${filter.parentFilterUsed} - iskorišteno iz kategorije: ${filter.childFiltersNum}`;
          });
    
          return finalString;
        };

        const mapData = (key: string, data: any, record: any) => {
          switch (key) {
            case 'action':
              return getUserActionLabel(data);
            case 'organizationName':
              return getOrganization(record['createdBy']);
            case 'createdBy':
              return getUser(data);
            case 'extra':
              return getExtraLabel(record);
            default:
              return data;
          }
        }
        
        // Data
        response.data?.forEach((dataRow) => {
          let userAttrs: any = dataRow.attributes;
          let row = [dataRow.id, ...attrs.map(key => mapData(key, userAttrs[key], userAttrs))].join(";");
          csvContent += row + "\r\n";
        });
        
        // Open download
        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", `bjn_log_${new Date().toISOString().split('T')[0]}.csv`);
        document.body.appendChild(link);
        
        link.click();
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
      }
    );
  }

  return (
    <div className="logs-page w100-h100">
      <div className='logs'>
        <Space style={{ marginBottom: 16 }} wrap={true}>
          <Select
            style={{ minWidth: 250 }}
            allowClear
            placeholder="Izaberite vrstu akcije"
            onChange={onActionChange}
            options={Object.keys(UserActionTypeEnum).map((x: string) => ({ value: x, label: getUserActionLabel(x) }))}
          />
          <Select
            style={{ minWidth: 250 }}
            allowClear
            showSearch
            placeholder="Izaberite korisnika"
            optionFilterProp="children"
            onChange={onUserChange}
            onSearch={onUserSearch}
            filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            options={fullUser.fullUsers?.data?.map((x: FullUserInterface) => ({ value: x.id?.toString(), label: x.attributes?.name }))}
          />
        </Space>

        <UserLogs />

        <Button onClick={downloadLogsCSV} type="default" style={{ marginTop: 16 }} icon={<DownloadOutlined />}>Preuzmi CSV</Button>

        <Pagination
          style={{ float: 'right', paddingTop: 16, paddingBottom: 16 }}
          onChange={onPaginationChange}
          current={page}
          pageSize={pageSize}
          pageSizeOptions={['10', '20', '50', '100']}
          locale={{ items_per_page: `${i18n.t('pagination.perPage')}` }}
          total={userActivityLog.userActivityLogs?.meta?.totalResourceCount}
          responsive
          showTotal={(total) => `${i18n.t('pagination.total')}: ${total}`}
          showSizeChanger
        />
      </div>
    </div>
  );
}

export default LogsComponent;
