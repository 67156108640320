import { Button, Divider, Row, Typography } from 'antd';
import React from 'react';
import { RegulationInterface } from '../../../interfaces';
import { i18n } from '../../../services';
import { Link } from 'react-router-dom';
import { AdminRoutes } from '../../../../features/admin';
import { useSelector } from 'react-redux';
import { StoreStateInterface } from '../../../redux';
import { AppRoutes } from '../../../../features/app';
import { UserRoleEnum } from '../../../enums';

const { Title, Text } = Typography;

interface Props {
  regulations?: Array<RegulationInterface>;
}

export default function DashboardRegulationsComponent({ regulations }: Props) {
  const userAuth = useSelector((state: StoreStateInterface) => state.auth);

  return (
    <div className="dashRegs">
      <Title className="title" level={3}>
        {i18n.translate('menu.regulations')}
      </Title>

      {regulations &&
        [...regulations].map((reg, i) => (
          <div className="regSection" key={reg.id}>
            <a href={reg.attributes?.link} className='hover-efect' target="_blank" rel="noopener noreferrer">
              <Text strong>{reg.attributes?.title}</Text>
            </a>
            <Divider />
          </div>
        ))}

      <Row justify="center">
        <Link
          to={
            userAuth.user?.roles === UserRoleEnum.ADMIN
              ? AdminRoutes.REGULATIONS.fullPath
              : AppRoutes.REGULATIONS.fullPath
          }
        >
          <Button type="primary" className="cardBtn">
            {i18n.translate(`dashboard.seeAll`)}
          </Button>
        </Link>
      </Row>
    </div>
  );
}
