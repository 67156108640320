import { Button, DatePicker, Form, FormInstance, Modal, Row, Select, SelectProps, Typography } from 'antd';
import localeEN from 'antd/es/date-picker/locale/en_GB';
import localeHR from 'antd/es/date-picker/locale/hr_HR';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  DisplayDateFormat,
  EUR_CONVERSION,
  StoreStateInterface,
  UserRoleEnum
} from '../../../../common';
import { LicenceEnum } from '../../../enums/licence.enum';
import { LicenceInterface } from '../../../interfaces';
import { i18n } from '../../../services';

const { Text } = Typography;

const { Option } = Select;

interface Props {
  visible: boolean;
  licenceInfo?: LicenceInterface;
  isUpgrade?: boolean;
  isNew?: boolean;
  isTrial?: boolean;
  selectedModel?: string;
  teamScreen?: boolean;
  handleOk?: (form: FormInstance<any>, price: number, isUpgrade: boolean | undefined, isNew: boolean | undefined, isTrial: boolean | undefined) => void;
  handleCancel: () => void;
}

export default function UpgradeModal({ visible, licenceInfo, isUpgrade, isNew, isTrial, selectedModel, teamScreen, handleOk, handleCancel }: Props) {
  const BASE_BILTEN_PRICE = 527.42; // Pretplata za BILTEN

  const STANDARD_SUB_PRICE = 1958.97; // Pretplata za OSNOVNI
  const PREMIUM_SUB_PRICE = 4822.08; // Pretplata za PREMIUM
  
  const BASE_YEAR_PRICE = 1958.97; // Dodatne godine za STANDARD_SUB_PRICE
  const PREMIUM_YEAR_PRICE = 4822.08; // Dodatne godine za PREMIUM_SUB_PRICE

  const [form] = Form.useForm();

  const [model, setModel] = useState<string>('PREMIUM');
  const [users, setUsers] = useState<string>('1');

  const userAuth = useSelector((state: StoreStateInterface) => state.auth);

  const isAdmin = userAuth.user?.roles === UserRoleEnum.ADMIN;

  useEffect(() => {
    if (selectedModel) {
      setModel(selectedModel);
      let subs = selectedCurrentAndNextYear ? [] : '0';
      if (selectedModel === 'PREMIUM') subs = selectedCurrentAndNextYear ? [] : '1';
      form.setFieldsValue({model: selectedModel ? selectedModel : 'PREMIUM', users: '1', subs: subs, years: [], educations: '1', notes: '10', questions: '1'});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const getPriceBaseKN = (value: any) => {
    const getMultiplierNested = () => {
      switch (value) {
        case 1:
          return 1.0;
        case 2:
          return 1.9;
        case 3:
          return 2.75;
        case 4:
          return 3.6;
        case 5:
          return 4.45;
        default:
          return 1.0;
      }
    };

    const basePrice = STANDARD_SUB_PRICE;
    const multiplier = getMultiplierNested();
    const total = basePrice * multiplier;

    return total;
  };

  const getPricePremiumKN = (value: any) => {
    const getMultiplierNested = () => {
      switch (value) {
        case 1:
          return 1.0;
        case 2:
          return 1.9;
        case 3:
          return 2.75;
        case 4:
          return 3.6;
        case 5:
          return 4.45;
        default:
          return 1.0;
      }
    };

    const basePrice = PREMIUM_SUB_PRICE;
    const multiplier = getMultiplierNested();
    const total = basePrice * multiplier;

    return total;
  };

  const getPriceBaseEUR = (value: any) => {
    const getMultiplierNested = () => {
      switch (value) {
        case 1:
          return 1.0;
        case 2:
          return 1.9;
        case 3:
          return 2.75;
        case 4:
          return 3.6;
        case 5:
          return 4.45;
        default:
          return 1.0;
      }
    };

    const basePrice = STANDARD_SUB_PRICE;
    const multiplier = getMultiplierNested();
    const total = basePrice * multiplier;

    return total / EUR_CONVERSION;
  };

  const getPricePremiumEUR = (value: any) => {
    const getMultiplierNested = () => {
      switch (value) {
        case 1:
          return 1.0;
        case 2:
          return 1.9;
        case 3:
          return 2.75;
        case 4:
          return 3.6;
        case 5:
          return 4.45;
        default:
          return 1.0;
      }
    };

    const basePrice = PREMIUM_SUB_PRICE;
    const multiplier = getMultiplierNested();
    const total = basePrice * multiplier;

    return total / EUR_CONVERSION;
  };

  const currencyFormat = (price: any) => parseFloat(price).toLocaleString('hr-HR', {minimumFractionDigits:2, maximumFractionDigits:2});

  const onChangeModel = (value: any) => {
    setModel(value);
    form.setFieldValue('users', '1');
    form.setFieldValue('subs', selectedCurrentAndNextYear ? [] : '1');
    if (value === 'STANDARD') form.validateFields();
  };

  const onChangeUsers = (value: any) => {
    setUsers(value);
    form.setFieldValue('subs', selectedCurrentAndNextYear ? [] : '1');
  };

  const formYears = Form.useWatch('years', form);

  const currentYear = parseInt(moment().format('YYYY'));
  const nextYear = currentYear + 1;

  const endOfYearPeriodDate = moment().set('month', 10).set('date', 1).format('YYYY-MM-DD');
  const endPeriodOfCurrentYear = moment().isSameOrAfter(endOfYearPeriodDate);

  const selectedCurrentYear = !!formYears?.includes(currentYear?.toString());
  const selectedNextYear = !!formYears?.includes(nextYear?.toString());
  const selectedCurrentAndNextYear = selectedCurrentYear && selectedNextYear;

  useEffect(() => {
    if (selectedCurrentAndNextYear) {
      form.setFieldValue('subs', []);
    } else {
      let subs = '0';
      if (model === 'PREMIUM') subs = '1';
      form.setFieldValue('subs', subs);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCurrentAndNextYear]);

  const yearOptions: SelectProps['options'] = [];
  const hrkPriceYear = currencyFormat(BASE_YEAR_PRICE);
  const eurPriceYear = currencyFormat(BASE_YEAR_PRICE / EUR_CONVERSION);
  const hrkPriceYearPremium = currencyFormat(PREMIUM_YEAR_PRICE);
  const eurPriceYearPremium = currencyFormat(PREMIUM_YEAR_PRICE / EUR_CONVERSION);
  for (let i = (endPeriodOfCurrentYear ? nextYear : currentYear); i >= 2016; i--) {
    yearOptions.push({
      label: i + `. + ${model === LicenceEnum.PREMIUM ? eurPriceYearPremium : eurPriceYear} EUR (+PDV)`,
      value: i.toString(),
    });
  }

  const newsletterOptions: SelectProps['options'] = [];
  for (let i = 0; i <= parseInt(users); i++) {
    let basePrice = BASE_BILTEN_PRICE;
    let label =
      i === 0
        ? i18n.translate('register.form.extra.noPrintEditions')
        : `${i} kom ${selectedNextYear ? nextYear : currentYear}. + ${currencyFormat((basePrice / EUR_CONVERSION) * (i - 0))} EUR (+PDV)`
    newsletterOptions.push({
      label: label,
      value: i.toString(),
    });
  }

  const newsletterOptionsPremium: SelectProps['options'] = [
    {
      label: `${i18n.translate('register.form.extra.premiumIncluded', { year: selectedNextYear ? nextYear : currentYear, userNoPremium: users})}`,
      value: '1',
    }
  ];

  /** BOTH YEARS OPTIONS */

  let newsletterBothYearsOptions: SelectProps['options'] = [
    {
      label: `${1} kom ${nextYear}. + ${currencyFormat((BASE_BILTEN_PRICE / EUR_CONVERSION) * (1))} EUR (+PDV)`,
      value: '2',
    },
    {
      label: `${1} kom ${currentYear}. + ${currencyFormat((BASE_BILTEN_PRICE / EUR_CONVERSION) * (1))} EUR (+PDV)`,
      value: '1',
    },
  ];

  let newsletterBothYearsOptionsPremium: SelectProps['options'] = [
    {
      label: `${i18n.translate('register.form.extra.premiumIncluded', { year: nextYear, userNoPremium: users})}`,
      value: '2',
    },
    {
      label: `${i18n.translate('register.form.extra.premiumIncluded', { year: currentYear, userNoPremium: users})}`,
      value: '1',
    },
  ];

  const getText = () => {
    let text = '';

    if (isNew || isTrial) {
      text = i18n.translate(`profile.subModels.addSubscription`);
    }
    if (isUpgrade) {
      text = i18n.translate(`profile.subModels.upgradeButton`);
    }

    return text;
  };

  const getPrice = () => {
    let price = 0;

    price += parseFloat(currencyFormat(model === 'PREMIUM' ? getPricePremiumEUR(form.getFieldValue('users')) : getPriceBaseEUR(form.getFieldValue('users'))));
    price += parseInt(selectedCurrentAndNextYear ? form.getFieldValue('subs').length : form.getFieldValue('subs')) * parseFloat(currencyFormat(BASE_BILTEN_PRICE / EUR_CONVERSION));
    if (form.getFieldValue('years').length) price += parseInt(form.getFieldValue('years').length) * parseFloat(currencyFormat(BASE_YEAR_PRICE / EUR_CONVERSION));

    return price;
  };

  const onFinishForm = () => {
    form.validateFields()
    .then(async (values) => {
      handleOk && handleOk(form, getPrice(), isUpgrade, isNew, isTrial);
    })
    .catch((error) => {
      console.log(error);
    });
  };

  return (
    <div className="upgradeModal">
      <Modal
        title={getText()}
        visible={visible}
        onOk={() => onFinishForm()}
        onCancel={() => {
          form.resetFields();
          handleCancel();
        }}
        footer={[
          <Button key="back" onClick={() => {
            form.resetFields();
            handleCancel();
          }}>
            {i18n.translate(`genericButtons.cancel`)}
          </Button>,
          <Button key="submit" type="primary" onClick={() => onFinishForm()}>
            {!isAdmin ? i18n.translate(`genericButtons.submit`) : isNew ? i18n.translate(`genericButtons.createLicence`) : i18n.translate(`profile.subModels.upgradeButton`)}
          </Button>,
        ]}
      >
        <Form form={form} layout="vertical">
          <Row style={{marginBottom: 16}}>
            {isUpgrade && !isAdmin ? <Text>{i18n.translate(`profile.subModels.wantUpgrade`)}</Text> : null}
          </Row>
          <Form.Item label="Model pretplate" name="model">
            <Select onChange={(value) => onChangeModel(value)}>
              {!isTrial ? <Option value="PREMIUM">{i18n.translate('common.licenceType.PREMIUM')}</Option> : null}
              {!isTrial ? <Option value="STANDARD">{i18n.translate('common.licenceType.STANDARD')}</Option> : null}
              {/**isNew ? <Option value="FREE">{i18n.translate('common.licenceType.FREE')}</Option> : null*/}
              {/**isTrial || isNew ? <Option value="TRIAL">TRIAL</Option> : null*/}
            </Select>
          </Form.Item>
          {model !== 'FREE' ? <Form.Item rules={[{ required: true, message: i18n.translate(`genericFormMsg.required`) }]} label={i18n.translate(`profile.subModels.yearsView`)} name="years">
            <Select mode="multiple" options={yearOptions} allowClear />
          </Form.Item> : null}
          {isNew || isTrial ? <Form.Item name="actDate" label={i18n.translate(`profile.subModels.activationDate`)}>
            <DatePicker locale={userAuth.lang == 'hr' ? localeHR : localeEN} format={DisplayDateFormat} style={{ borderRadius: 0, width: 200 }} />
          </Form.Item> : null}
          {isNew || isTrial ? <Form.Item name="expDate" label={i18n.translate(`profile.subModels.expirationDate`)}>
            <DatePicker locale={userAuth.lang == 'hr' ? localeHR : localeEN} format={DisplayDateFormat} style={{ borderRadius: 0, width: 200 }} />
          </Form.Item> : null}
          {/**(isNew || isUpgrade) && userAuth.user?.roles === UserRoleEnum.ADMIN ? <Form.Item label="Broj korisnika" name="users">
            <Select onChange={(value) => onChangeUsers(value)}>
              {model === 'FREE' ? <Option value="1">{`1 + 0 EUR (+PDV) / 0 HRK (+PDV)`}</Option> : null}
              {model !== 'FREE' ? <Option value="1">{`1 + ${currencyFormat(model === 'PREMIUM' ? getPricePremiumEUR(1) : getPriceBaseEUR(1))} EUR (+PDV) / ${currencyFormat(model === 'PREMIUM' ? getPricePremiumKN(1) : getPriceBaseKN(1))} HRK (+PDV)`}</Option> : null}
              {model !== 'FREE' ? <Option value="2">{`2 + ${currencyFormat(model === 'PREMIUM' ? getPricePremiumEUR(2) : getPriceBaseEUR(2))} EUR (+PDV) / ${currencyFormat(model === 'PREMIUM' ? getPricePremiumKN(2) : getPriceBaseKN(2))} HRK (+PDV)`}</Option> : null}
              {model !== 'FREE' ? <Option value="3">{`3 + ${currencyFormat(model === 'PREMIUM' ? getPricePremiumEUR(3) : getPriceBaseEUR(3))} EUR (+PDV) / ${currencyFormat(model === 'PREMIUM' ? getPricePremiumKN(3) : getPriceBaseKN(3))} HRK (+PDV)`}</Option> : null}
              {model !== 'FREE' ? <Option value="4">{`4 + ${currencyFormat(model === 'PREMIUM' ? getPricePremiumEUR(4) : getPriceBaseEUR(4))} EUR (+PDV) / ${currencyFormat(model === 'PREMIUM' ? getPricePremiumKN(4) : getPriceBaseKN(4))} HRK (+PDV)`}</Option> : null}
              {model !== 'FREE' ? <Option value="5">{`5 + ${currencyFormat(model === 'PREMIUM' ? getPricePremiumEUR(5) : getPriceBaseEUR(5))} EUR (+PDV) / ${currencyFormat(model === 'PREMIUM' ? getPricePremiumKN(5) : getPriceBaseKN(5))} HRK (+PDV)`}</Option> : null}
            </Select>
          </Form.Item> : null*/}
          {model !== 'FREE' && (isNew || isUpgrade) && ((selectedCurrentYear || selectedNextYear) && !selectedCurrentAndNextYear) ? <Form.Item label={i18n.translate(`profile.subModels.availableNewsletters`)} name="subs">
            <Select options={model === 'PREMIUM' ? newsletterOptionsPremium : newsletterOptions} />
          </Form.Item> : null}
          {model !== 'FREE' && (isNew || isUpgrade) && selectedCurrentAndNextYear ? <Form.Item rules={model === 'PREMIUM' ? [{ required: true, message: i18n.translate(`genericFormMsg.required`) }] : []} label={i18n.translate(`profile.subModels.availableNewsletters`)} name="subs">
            <Select mode="multiple" options={model === 'PREMIUM' ? newsletterBothYearsOptionsPremium : newsletterBothYearsOptions} allowClear />
          </Form.Item> : null}
          {model !== 'FREE' && (isNew || isUpgrade) && userAuth.user?.roles === UserRoleEnum.ADMIN ? <Form.Item label={i18n.translate(`profile.subModels.availableEducations`)} name="educations">
            <Select>
              <Option value="0">0</Option>
              <Option value="1">1</Option>
              <Option value="2">2</Option>
              <Option value="3">3</Option>
              <Option value="4">4</Option>
              <Option value="5">5</Option>
              <Option value="6">6</Option>
              <Option value="7">7</Option>
              <Option value="8">8</Option>
              <Option value="9">9</Option>
              <Option value="10">10</Option>
              <Option value="noLimit">{i18n.translate(`profile.subModels.unlimited`)}</Option>
            </Select>
          </Form.Item> : null}
          {model !== 'FREE' && (isNew || isUpgrade) && userAuth.user?.roles === UserRoleEnum.ADMIN ? <Form.Item label={i18n.translate(`profile.subModels.availableNotes`)} name="notes">
            <Select>
              <Option value="0">0</Option>
              <Option value="10">10</Option>
              <Option value="100">100</Option>
              <Option value="noLimit">{i18n.translate(`profile.subModels.unlimited`)}</Option>
            </Select>
          </Form.Item> : null}
          {model !== 'FREE' && (isNew || isUpgrade) && userAuth.user?.roles === UserRoleEnum.ADMIN ? <Form.Item label={i18n.translate(`profile.subModels.availableQuestions`)} name="questions">
            <Select>
              <Option value="0">0</Option>
              <Option value="1">1</Option>
              <Option value="2">2</Option>
              <Option value="3">3</Option>
              <Option value="4">4</Option>
              <Option value="5">5</Option>
              <Option value="6">6</Option>
              <Option value="7">7</Option>
              <Option value="8">8</Option>
              <Option value="9">9</Option>
              <Option value="10">10</Option>
              <Option value="noLimit">{i18n.translate(`profile.subModels.unlimited`)}</Option>
            </Select>
          </Form.Item> : null}
        </Form>
      </Modal>
    </div>
  );
}
