import Icon, { CaretDownOutlined, CaretUpOutlined, EditOutlined, FilePdfOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Popover, Row, Typography } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  CourtInterface,
  DisplayDateFormat,
  LicenceInterface,
  NotePickModal,
  NoteResponseInterfaceAll,
  StoreStateInterface,
  UserActivityLogService,
  UserRoleEnum
} from '../../..';
import { BookmarkOutlined } from '../../../../assets/icons/icons';
import { AdminRoutes } from '../../../../features/admin';
import { LicenceEnum } from '../../../enums/licence.enum';
import useWindowSize from '../../../hooks/useWindowSize';
import { DownloadService, i18n, ViewerService } from '../../../services';
import { UserActionTypeEnum } from '../../../enums/userActionType.enum';

const { Paragraph, Text, Title } = Typography;

const oneLetterSize = 6.5;
const sidebarWidth = 256;
const sidebarBreakPoint = 575;

interface Props {
  court: CourtInterface;
  licenceData?: LicenceInterface;
  notes?: NoteResponseInterfaceAll;
  validLicences?: LicenceInterface[];
}

export default function EUCourtCardComponent({ court, licenceData, notes, validLicences }: Props) {
  const userAuth = useSelector((state: StoreStateInterface) => state.auth);

  const [ellipsis, setEllipsis] = useState(true);
  const [visible, setVisible] = useState(false);

  const windowSize = useWindowSize();

  const availableNotes = licenceData?.attributes?.notesNum as number;

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    if (!((notes?.data.length as number) == availableNotes)) {
      setVisible(true);
    }
    setVisible(false)
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const onCourtPdfView = () => {
    if (!court) return;
    if (userAuth?.user?.roles !== UserRoleEnum.ADMIN) {
      let userActivityLogData: any = {
        data: {
          type: 'user_activity_log',
          attributes: {
            action: licenceData?.attributes?.licenceType === LicenceEnum.PREMIUM ? UserActionTypeEnum.FILE_DOWNLOADED : UserActionTypeEnum.FILE_OPENED,
            extra: JSON.stringify({type: 'eu_court', title: court.attributes?.verdictTitle, name: court.attributes?.verdictName, number: court.attributes?.verdictNumber})
          },
          relationships: {
            user: {
              data: {
                id: userAuth?.user?.id,
                type: 'user'
              },
            },
          },
        },
      };

      UserActivityLogService.create(userActivityLogData);
    }
    if (licenceData?.attributes?.licenceType === LicenceEnum.PREMIUM || userAuth.user?.roles === UserRoleEnum.ADMIN) {
      DownloadService.download(court?.attributes?.pdfUuid);
    } else {
      ViewerService.goToPdf(court?.attributes?.pdfUuid);
    }
  };

  const displayShowMoreBtn = (text?: string) => {
    if (!windowSize.width || !text) return false;
    // if (text.indexOf('\n') !== -1) return true;
    const sidebarSpace = windowSize.width > sidebarBreakPoint ? sidebarWidth : 0;
    const contentWidth = text.length * oneLetterSize;
    const paddingWidth = 25;
    const boxWidth = (windowSize.width - sidebarSpace - paddingWidth - paddingWidth) * 0.8 * 2; // x2 becouse for preview we have 2 lines
    return boxWidth < contentWidth;
  };

  const checkLicenceYear = () => {
    const courtYear = getYear();

    if (userAuth.user?.roles === UserRoleEnum.ADMIN) return true;

    if (validLicences?.length) {
      // MIČEMO ŠTIT ZA GODINE
      /**
      let access = false;

      validLicences.forEach((licenceData) => {
        if (courtYear == licenceData.attributes?.year) {
          access = true;
        }
        if (licenceData.attributes?.childrenLicences?.length) {
          if (licenceData.attributes?.childrenLicences?.find((x) => x.active && x.year == courtYear)) {
            access = true;
          }
        }
      });

      return access;
      */

      return true;
    } else {
      return false;
    }
  };

  const getYear = () => {
    let yearString = i18n.translate('common.unsorted');

    if (court.attributes?.verdictDate) yearString = moment(court.attributes?.verdictDate).format('YYYY');

    return yearString;
  };

  return (
    <div className="euCourtCard">
      <Row className="row">
        <Col flex={4}>
          <Text className="dateTime" type="secondary">
            {i18n.translate(`euCourt.numberAlt`) + court.attributes?.verdictNumber} <Divider type="vertical" />
            {moment(court.attributes?.verdictDate).format(DisplayDateFormat)} <Divider type="vertical" />{' '}
            {court.attributes?.procedureType}
          </Text>
        </Col>
        <Col flex={0}>
          <>
            {checkLicenceYear() ? (
              <Button
                type="link"
                className="cardBtn"
                onClick={() => showModal()}
                icon={<Icon component={BookmarkOutlined} />}
              />
            ) : (
              <Popover
                placement="topRight"
                content={i18n.translate(`common.licence.content`)}
                title={i18n.translate(`common.licence.title`)}
              >
                <Button disabled type="link" className="cardBtn" icon={<Icon component={BookmarkOutlined} />} />
              </Popover>
            )}
            {court?.attributes?.pdfUuid ? (
              !checkLicenceYear() ? (
                <Popover
                  placement="topRight"
                  content={i18n.translate(`common.licence.content`)}
                  title={i18n.translate(`common.licence.title`)}
                >
                  <Button disabled type="link" className="cardBtn" icon={<FilePdfOutlined />} />
                </Popover>
              ) : (
                <Button type="link" className="cardBtn" onClick={onCourtPdfView} icon={<FilePdfOutlined />} />
              )
            ) : (
              <Popover
                placement="topRight"
                content={i18n.translate(`common.licence.content`)}
                title={i18n.translate(`common.licence.title`)}
              >
                <Button disabled type="link" className="cardBtn" icon={<FilePdfOutlined />} />
              </Popover>
            )}
            {court?.attributes?.hrLink ? (
              !checkLicenceYear() ? (
                <Popover
                  placement="topRight"
                  content={i18n.translate(`common.licence.content`)}
                  title={i18n.translate(`common.licence.title`)}
                >
                  <Button disabled type="link" className="cardBtn" style={{ marginLeft: 2, marginRight: 2 }}>
                    <span>HR</span>
                  </Button>
                </Popover>
              ) : (
                <a href={court?.attributes?.hrLink} target="_blank" rel="noopener noreferrer">
                  <Button style={{ marginLeft: 2, marginRight: 2 }} type="link" className="cardBtn">
                    HR
                  </Button>
                </a>
              )
            ) : (
              <Popover
                placement="topRight"
                content={i18n.translate(`common.licence.content`)}
                title={i18n.translate(`common.licence.title`)}
              >
                <Button disabled type="link" className="cardBtn" style={{ marginLeft: 2, marginRight: 2 }}>
                  <span>HR</span>
                </Button>
              </Popover>
            )}
            {court?.attributes?.engLink ? (
              !checkLicenceYear() ? (
                <Popover
                  placement="topRight"
                  content={i18n.translate(`common.licence.content`)}
                  title={i18n.translate(`common.licence.title`)}
                >
                  <Button disabled type="link" className="cardBtn" style={{ marginLeft: 2, marginRight: 2 }}>
                    <span>EN</span>
                  </Button>
                </Popover>
              ) : (
                <a href={court?.attributes?.engLink} target="_blank" rel="noopener noreferrer">
                  <Button style={{ marginLeft: 2, marginRight: 2 }} type="link" className="cardBtn">
                    EN
                  </Button>
                </a>
              )
            ) : (
              <Popover
                placement="topRight"
                content={i18n.translate(`common.licence.content`)}
                title={i18n.translate(`common.licence.title`)}
              >
                <Button disabled type="link" className="cardBtn" style={{ marginLeft: 2, marginRight: 2 }}>
                  <span>EN</span>
                </Button>
              </Popover>
            )}
            {userAuth.user?.roles === UserRoleEnum.ADMIN && (
              <Link
                to={AdminRoutes.EU_COURT_DECLARATIONS.path + `/${court?.id}`}
                key={AdminRoutes.EU_COURT_DECLARATIONS.path + `/${court?.id}`}
              >
                <Button type="link" className="cardBtn" icon={<EditOutlined />} />
              </Link>
            )}
          </>
        </Col>
      </Row>
      <Title level={4}>
        {court?.attributes?.verdictName}
        {userAuth.user?.roles === UserRoleEnum.ADMIN ? ` | ${court?.attributes?.verdictTitle}` : ''}
      </Title>
      <Paragraph
        className='para'
        ellipsis={ellipsis ? { rows: 2 } : false}
        style={{ whiteSpace: ellipsis ? 'normal' : 'pre-wrap' }}
      >
        {court?.attributes?.textContent}
      </Paragraph>
      <>
        {ellipsis && displayShowMoreBtn(court?.attributes?.textContent) && (
          <Button
            className="cardBtn"
            type="link"
            icon={<CaretDownOutlined />}
            onClick={() => {
              setEllipsis(!ellipsis);
            }}
          >
            {i18n.translate('common.readMore')}
          </Button>
        )}
        {!ellipsis && (
          <Button
            className="cardBtn"
            type="link"
            icon={<CaretUpOutlined />}
            onClick={() => {
              setEllipsis(!ellipsis);
            }}
          >
            {i18n.translate('common.hide')}
          </Button>
        )}
      </>
      <Divider />
      <NotePickModal
        isExternal={true}
        visible={visible}
        handleOk={handleOk}
        handleCancel={handleCancel}
        external={court}
        type="court_eu"
        typeId={court?.id}
        breadcrumb={'Pravna zaštita/Praksa u EU/Presude Suda EU/' + court?.attributes?.verdictTitle}
        number={court?.id}
        isEditable={true}
        active={true}
        licenceData={licenceData as LicenceInterface}
        validLicences={validLicences}
      />
    </div>
  );
}
