import { RoadmapConstants } from './roadmap.constants';
import { RoadmapInterface, RoadmapResponseInterfaceAll, RoadmapResponseInterfaceSingle, ReducerActionInterface } from '../../interfaces';

// ============ INTERFACE ============

export interface RoadmapStateInterface {
  currentRoadmap?: RoadmapInterface;
  roadmaps?: RoadmapResponseInterfaceAll;
  error?: string;
}

// ============ INIT STATE ============

const initialState: RoadmapStateInterface = {
  currentRoadmap: undefined,
  roadmaps: {
    data: [],
    meta: {
      totalResourceCount: 0
    }
  }
}

// ============ REDUCERS ============

const getSingleSuccess = (state: RoadmapStateInterface, payload: RoadmapResponseInterfaceSingle): RoadmapStateInterface => {
  return {
    ...state,
    currentRoadmap: payload.data,
  };
};

const getAllSuccess = (state: RoadmapStateInterface, payload: RoadmapResponseInterfaceAll): RoadmapStateInterface => {
  return {
    ...state,
    roadmaps: {
      data: payload.data,
      meta: payload.meta
    }
  };
};

const createSuccess = (state: RoadmapStateInterface, payload: RoadmapResponseInterfaceSingle): RoadmapStateInterface => {
  return {
    ...state,
    currentRoadmap: payload.data,
  };
};

const updateSuccess = (state: RoadmapStateInterface, payload: RoadmapResponseInterfaceSingle): RoadmapStateInterface => {
  return {
    ...state,
    currentRoadmap: payload.data,
  };
};

const clearCurrent = (state: RoadmapStateInterface): RoadmapStateInterface => {
  return {
    ...state,
    currentRoadmap: undefined,
  };
};

const clearList = (state: RoadmapStateInterface): RoadmapStateInterface => {
  return {
    ...state,
    roadmaps: {
      data: [],
      meta: {
        totalResourceCount: 0
      }
    }
  };
};

const deleteSuccess = (state: RoadmapStateInterface): RoadmapStateInterface => {
  return {
    ...state,
    currentRoadmap: undefined,
  };
};

const error = (state: RoadmapStateInterface, payload: string) => {
  return {
    ...state,
    error: payload,
  };
};

// ============ EXPORTS ============

export const RoadmapReducer = (state = initialState, action: ReducerActionInterface) => {
  switch (action.type) {
    case RoadmapConstants.GET_SINGLE_SUCCESS:
      return getSingleSuccess(state, action.payload);
    case RoadmapConstants.GET_ALL_SUCCESS:
      return getAllSuccess(state, action.payload);
    case RoadmapConstants.CREATE_SUCCESS:
      return createSuccess(state, action.payload);
    case RoadmapConstants.UPDATE_SUCCESS:
      return updateSuccess(state, action.payload);
    case RoadmapConstants.CLEAR_CURRENT:
      return clearCurrent(state);
    case RoadmapConstants.CLEAR_LIST:
      return clearList(state);
    case RoadmapConstants.DELETE:
      return deleteSuccess(state);
    case RoadmapConstants.ERROR:
      return error(state, action.payload);
    default:
      return state;
  }
};
