import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Avatar, Button, Dropdown, Menu } from 'antd';
import {
  UserOutlined,
  CommentOutlined,
  InfoCircleOutlined,
  LogoutOutlined,
  SettingOutlined,
  FundViewOutlined,
} from '@ant-design/icons';
import { i18n } from '../../services';
import { setLang } from '../../services/translate.service';
import { AuthConstants, logoutAction } from '../../redux/auth';
import { StoreStateInterface } from '../../redux/store';
import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';
import Meta from 'antd/lib/card/Meta';
import { Link } from 'react-router-dom';
import { AdminRoutes } from '../../../features/admin';
import { UserRoleEnum } from '../../enums';
import { AppRoutes } from '../../../features/app';
import useWindowSize from '../../hooks/useWindowSize';

interface HeaderProps {
  collapsed: boolean;
  setCollapsed: (collapsed: boolean) => void;
}

export default function HeaderComponent({ collapsed, setCollapsed }: HeaderProps) {
  const dispatch = useDispatch();
  const userAuth = useSelector((state: StoreStateInterface) => state.auth);

  const windowSize = useWindowSize();

  function onLogOutBtnClick() {
    dispatch(logoutAction());
  }

  function changeLang(nextLang: string) {
    setLang(nextLang);
    dispatch({ type: AuthConstants.SET_LANG, payload: nextLang });
  }

  const checkIfMobile = () => {
    if ((/Android/i.test(navigator.userAgent))) {
      return true;
    }
    if ((/iPhone/i.test(navigator.userAgent))) {
      return true;
    }
    else {
      return false;
    }
  };

  var menuItems = [
    {
      key: '1',
      label: (
        <Link to={userAuth.user?.roles === UserRoleEnum.ADMIN ? AdminRoutes.USER_PROFILE.fullPath + `/${userAuth.user?.id}` : AppRoutes.USER_PROFILE.fullPath} key={userAuth.user?.roles === UserRoleEnum.ADMIN ? AdminRoutes.USER_PROFILE.fullPath + `/${userAuth.user?.id}` : AppRoutes.USER_PROFILE.fullPath}>
          <Meta
            avatar={
              <Avatar style={{ backgroundColor: "#1210A9", verticalAlign: 'middle' }} size="large" gap={4}>
                { userAuth.user?.name.split(' ').map(x => x[0]).join('') }
              </Avatar>
            }
            title={userAuth.user?.name}
            description={i18n.translate(`common.viewProfile`)}
          />
        </Link>
      ),
    },
    {
      type: 'divider',
    },
    //{
      //key: '2',
      //label: <a>{i18n.translate(`dropdown.notifications`)}</a>,
      //icon: <BellOutlined />,
    //},
    {
      key: '4',
      label: (
        <Link to={userAuth.user?.roles === UserRoleEnum.ADMIN ? AdminRoutes.NOTES.fullPath : AppRoutes.NOTES.fullPath} key={userAuth.user?.roles === UserRoleEnum.ADMIN ? AdminRoutes.NOTES.fullPath : AppRoutes.NOTES.fullPath}>
          {i18n.translate(`dropdown.notes`)}
        </Link>
      ),
      icon: <CommentOutlined />,
    },
    {
      key: '5',
      label: (
        <Link to={userAuth.user?.roles === UserRoleEnum.ADMIN ? AdminRoutes.INSTRUCTIONS.fullPath : AppRoutes.INSTRUCTIONS.fullPath} key={userAuth.user?.roles === UserRoleEnum.ADMIN ? AdminRoutes.INSTRUCTIONS.fullPath : AppRoutes.INSTRUCTIONS.fullPath}>
          {i18n.translate(`dropdown.instructions`)}
        </Link>
      ),
      icon: <InfoCircleOutlined />,
    }
  ];

  if (userAuth.user?.roles === UserRoleEnum.ADMIN) {
    menuItems.push({
      key: '66',
      label: (
        <Link to={AdminRoutes.SETTINGS.fullPath} key={AdminRoutes.SETTINGS.fullPath}>
          {i18n.translate(`dropdown.settings`)}
        </Link>
      ),
      icon: <SettingOutlined />,
    });
    menuItems.push({
      key: '67',
      label: (
        <Link to={AdminRoutes.STATISTICS.fullPath} key={AdminRoutes.STATISTICS.fullPath}>
          {i18n.translate(`dropdown.statistics`)}
        </Link>
      ),
      icon: <FundViewOutlined />,
    });
  }

  const menu = (
    <Menu
      items={[
        ...menuItems,
        {
          type: 'divider',
        },
        {
          key: '6',
          danger: true,
          label: <a onClick={onLogOutBtnClick}>{i18n.translate(`dropdown.logout`)}</a>,
          icon: <LogoutOutlined />,
        },
      ] as any}
    />
  );

  const languageMenu = (
    <Menu
      items={[
        {
          key: 'hr',
          label: (<a onClick={() => changeLang('hr')}> HR </a>),
        },
        {
          key: 'en',
          label: (<a onClick={() => changeLang('en')}> EN </a>),
        },
      ]}
    />
  );

  return (
    <header className={userAuth.user?.roles === UserRoleEnum.ADMIN ? "header-admin" : "header"}>
      <div className="header__content">
        <div className="menu" style={{color: userAuth.user?.roles === UserRoleEnum.ADMIN ? 'white' : '#1210A9'}}>
          {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
            className: 'trigger',
            onClick: () => setCollapsed(!collapsed),
          })}
        </div>
        {(collapsed && checkIfMobile()) || windowSize.width as number > 425 ? <div className="buttons">
          <Dropdown overlay={languageMenu}>
            <a style={{ marginRight: 16, color: userAuth.user?.roles === UserRoleEnum.ADMIN ? 'white' : '#1210A9' }}  onClick={e => e.preventDefault()}>
              { userAuth.lang.toUpperCase() }
            </a>
          </Dropdown>

          <Dropdown overlay={menu} trigger={['click']} placement="bottomRight">
            <Button style={{color: userAuth.user?.roles === UserRoleEnum.ADMIN ? '#1210A9' : 'white', backgroundColor: userAuth.user?.roles === UserRoleEnum.ADMIN ? 'white' : '#1210A9'}} onClick={(e) => e.preventDefault()} type="primary" shape="circle" icon={<UserOutlined />} />
          </Dropdown>
        </div> : null}
      </div>
    </header>
  );
}
