import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { NoteLinkService } from '../api/api.service';
import { NoteInterface } from '../interfaces';

pdfMake.vfs = pdfFonts.pdfMake.vfs;


async function printNoteToPdf(note?: NoteInterface) {
  if (!note) return;

  const filename = `${note?.attributes?.title}.pdf`;
  const title = note?.attributes?.title;
  const conetnt = note?.attributes?.text;

  const links = await NoteLinkService.getAll(note.id!).toPromise();

  let docDefinition: any = {

    pageMargins: [40, 80, 40, 60],

    header: {
      margin: [250, 12, 0, 0],
      columns: [
        {
          image: 'headerLogo',
          width: 80,
        }
      ]
    },

    footer: function (currentPage: any, pageCount: any) {
      return { text: currentPage.toString() + ' / ' + pageCount, alignment: 'center', margin: [0, 8, 0, 0] };
    },

    images: {
      headerLogo: 'static/images/pdf-header.png',
    },

    content: [],
    styles: {
      header: {
        fontSize: 18,
        bold: true,
        marginTop: 0,
      },
      contentTitle: {
        bold: true
      }
    },
  };

  docDefinition.images.headerLogo = `${window.location.origin}/${docDefinition.images.headerLogo}`;

  docDefinition.content.push({
    style: 'header',
    text: title,
  });

  if (note.attributes.notesObject) {
    docDefinition.content.push({
      style: 'contentTitle',
      margin: [0, 32, 0, 0],
      text: "Vezani sadržaj: ",
    });
    docDefinition.content.push({
      text: note.attributes.breadcrumb,
    });
    docDefinition.content.push({
      text: note.attributes.notesObject.title,
    });
  }

  if (links && links.data && links.data.length > 0) {
    docDefinition.content.push({
      style: 'contentTitle',
      margin: [0, 32, 0, 0],
      text: "Link na dodatni sadržaj: ",
    });
    docDefinition.content.push( {
      // to treat a paragraph as a bulleted list, set an array of items under the ul key
      ul: links.data.map(x => x.attributes.link)
    },);
  }

  docDefinition.content.push({
    margin: [0, 32, 0, 0],
    text: conetnt,
  });

  pdfMake.createPdf(docDefinition).download(filename);
}

function printToPdf(filename: string, title: string, conetnt: string) {
  let docDefinition: any = {

    pageMargins: [40, 80, 40, 60],

    header: {
      margin: [250, 12, 0, 0],
      columns: [
        {
          image: 'headerLogo',
          width: 80,
        }
      ]
    },

    footer: function (currentPage: any, pageCount: any) {
      return { text: currentPage.toString() + ' / ' + pageCount, alignment: 'center', margin: [0, 8, 0, 0] };
    },

    images: {
      headerLogo: 'static/images/pdf-header.png',
    },

    content: [],
    styles: {
      header: {
        fontSize: 18,
        bold: true,
        marginTop: 0,
      },
    },
  };

  docDefinition.images.headerLogo = `${window.location.origin}/${docDefinition.images.headerLogo}`;

  docDefinition.content.push({
    style: 'header',
    text: title,
  });

  docDefinition.content.push({
    margin: [0, 32, 0, 0],
    text: conetnt,
  });

  pdfMake.createPdf(docDefinition).download(filename);
}

export default {
  printToPdf,
  printNoteToPdf,
};
