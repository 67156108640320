export enum BiltenConstants {
  GET_ALL_SUCCESS = 'BiltenConstants_GET_ALL_SUCCESS',
  GET_SINGLE_SUCCESS = 'BiltenConstants_GET_SINGLE_SUCCESS',
  CREATE_SUCCESS = 'BiltenConstants_CREATE_SUCCESS',
  UPDATE_SUCCESS = 'BiltenConstants_UPDATE_SUCCESS',
  CLEAR_CURRENT = 'BiltenConstants_CLEAR_CURRENT',
  CLEAR_LIST = 'BiltenConstants_CLEAR_LIST',
  DELETE = 'BiltenConstants_DELETE',
  ERROR = 'BiltenConstants_ERROR'
}
