import { Button, Col, Row, Typography, Image, Space, notification, Spin, Popover } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { DisplayDateMonthFormat, EUR_CONVERSION, FileService, LicenceInterface, StoreStateInterface, UserActivityLogService, UserRoleEnum } from '../..';
import { AdminRoutes } from '../../../features/admin';
import { AppRoutes } from '../../../features/app';
import { LicenceEnum } from '../../enums/licence.enum';
import { i18n, ViewerService } from '../../services';
import { UserActionTypeEnum } from '../../enums/userActionType.enum';

const { Title, Text } = Typography;

interface Props {
  id?: number;
  title?: string;
  price?: number;
  issue?: number;
  description?: string;
  dateIssued?: string;
  pdf?: string;
  frontPageUuid?: string;
  type?: string;
  contentPdf?: string;
  biltenContentByUrl?: string;
  frontPageUrl?: string;
  licenceData?: LicenceInterface;
  validLicences?: LicenceInterface[];
}

export default function PreviewComponent({
  id,
  title,
  price,
  issue,
  description,
  dateIssued,
  pdf,
  contentPdf,
  biltenContentByUrl,
  frontPageUuid,
  type,
  frontPageUrl,
  licenceData,
  validLicences
}: Props) {
  const [biltenImage, setBiltenImage] = useState('');
  const [imageGet, setImageGet] = useState(false);
  const data = description;
  const hasSplit = description?.includes('•');
  const dataSplit = description?.split('•');
  const userAuth = useSelector((state: StoreStateInterface) => state.auth);

  const getBiltenImage = () => {
    FileService.temp(frontPageUuid as string).subscribe(
      (response: any) => {
        if (response.url) {
          let url = response.url + '/bjn';
          setBiltenImage(url);
        }
        setImageGet(true);
      },
      (error: Error) => {
        setImageGet(true);
      }
    );
  };

  const onBiltenPdfView = () => {
    if (!pdf) return;
    if (userAuth?.user?.roles !== UserRoleEnum.ADMIN) {
      let userActivityLogData: any = {
        data: {
          type: 'user_activity_log',
          attributes: {
            action: UserActionTypeEnum.FILE_OPENED,
            extra: JSON.stringify({type: 'newsletter', documentType: 'Bilten', title: title})
          },
          relationships: {
            user: {
              data: {
                id: userAuth?.user?.id,
                type: 'user'
              },
            },
          },
        },
      };

      UserActivityLogService.create(userActivityLogData);
    }
    ViewerService.goToPdf(pdf);
  };

  const onBiltenContentPdfView = () => {
    if (!biltenContentByUrl) return;
    if (userAuth?.user?.roles !== UserRoleEnum.ADMIN) {
      let userActivityLogData: any = {
        data: {
          type: 'user_activity_log',
          attributes: {
            action: UserActionTypeEnum.FILE_OPENED,
            extra: JSON.stringify({type: 'newsletter', documentType: 'Sadržaj biltena', title: title})
          },
          relationships: {
            user: {
              data: {
                id: userAuth?.user?.id,
                type: 'user'
              },
            },
          },
        },
      };

      UserActivityLogService.create(userActivityLogData);
    }
    window.open(biltenContentByUrl, '_blank');
  };

  const currencyFormat = (price: any) => parseFloat(price).toLocaleString('hr-HR', {minimumFractionDigits:2, maximumFractionDigits:2});

  const priceEuro = () => {
    let p = price;
    if (!p) p = 0;
    return `${currencyFormat(p)} EUR (+PDV)`;
  }

  const priceHrk = () => {
    let p: any = price;
    if (!p) p = 0;
    p = p * EUR_CONVERSION;
    p = currencyFormat(p);
    if (p === '319,99') p = '320,00';
    if (p === '220,01') p = '220,00';
    return `${p} HRK (+PDV)`;
  }

  const checkLicenceYear = () => {
    const newsletterYear = getYear();

    if (userAuth.user?.roles === UserRoleEnum.ADMIN) return true;

    if (validLicences?.length) {
      let access = false;

      validLicences.forEach(licenceData => {
        if (newsletterYear == licenceData.attributes?.year) {
          access = true;
        }
        if (licenceData.attributes?.childrenLicences?.length) {
          if (licenceData.attributes?.childrenLicences?.find((x) => x.active && x.year == newsletterYear)) {
            access = true;
          }
        }
      });

      return access;
    } else {
      return false;
    }
  };

  const getYear = () => {
    let yearString = i18n.translate('common.unsorted');

    if (dateIssued) yearString = moment(dateIssued).format('YYYY').toString();

    return yearString;
  };

  const isDisabled = () => {
    return !checkLicenceYear();
  };

  return (
    <div className="previewContent">
      <Row className="row" justify="center" gutter={190}>
        <Col className="row___imgCol">
          <Image
            style={{ maxWidth: 237, maxHeight: 344 }}
            preview={false}
            alt="BJN Naslovnica"
            fallback={require('../../../assets/images/naslovnicaBJN1.png')}
            src={frontPageUrl}
            placeholder={<Spin style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}} />}
          />
        </Col>
        <Col className="row___listCol" offset={2}>
          <Text strong>
            {i18n.translate(`newsletters.issue`, { number: issue })} /{' '}
            {moment(dateIssued)
              .locale(userAuth.lang == 'hr' ? 'hr' : 'en')
              .format(DisplayDateMonthFormat)}
          </Text>
          <Title className="row___subtitle" level={5}>
            {title}
          </Title>

          <Text strong>{i18n.translate('dashboard.review.inThis')}</Text>
          {hasSplit ? <ul className="list">
            {dataSplit?.slice(1, data?.length).map((item, index) => (
              <li key={'review' + index}>{item}</li>
            ))}
          </ul> : data}
          <div className="row___price">
            <Text underline>{i18n.translate('dashboard.review.price')}</Text>
            <Text strong>{priceEuro()}</Text>
          </div>
          <Space align="center" direction="horizontal" className="row__space">
            {isDisabled() ?
            <Popover
              placement="topRight"
              content={i18n.translate(`common.licence.content`)}
              title={i18n.translate(`common.licence.title`)}
            >
            <Button disabled type="primary">
              {i18n.translate(`newsletters.read`)}
            </Button>
            </Popover>
            : null}
            {!isDisabled() ?
            <Button onClick={onBiltenPdfView} type="primary">
              {i18n.translate(`newsletters.read`)}
            </Button> : null}
            <Button disabled={!biltenContentByUrl} onClick={onBiltenContentPdfView}>{i18n.translate('dashboard.review.reviewContent')}</Button>
            <Link
              to={
                userAuth.user?.roles === UserRoleEnum.ADMIN
                  ? AdminRoutes.NEWSLETTERS_VIEW.fullPath + `/${id}`
                  : AppRoutes.NEWSLETTERS_VIEW.fullPath + `/${id}`
              }
            >
              <Button type="link">{i18n.translate('dashboard.learnMore')}</Button>
            </Link>
          </Space>
        </Col>
      </Row>
    </div>
  );
}
