import {
  Form,
  Input,
  Button,
  Steps,
  Radio,
  Col,
  Row,
  Select,
  SelectProps,
  Typography,
  Divider,
  Space,
  RadioChangeEvent,
  notification,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  EUR_CONVERSION,
  i18n,
  LicenceCardComponent,
  LicenceTypeEnum,
  registerAction,
  RegisterInterface,
  StoreStateInterface,
  UserService,
  YearDividerComponent,
} from '../../../common';
import { AuthRoutes } from '../_router/auth.routes';
import logo from '../../../assets/images/bjn.png';
import moment from 'moment';

const { Text } = Typography;
const { TextArea } = Input;

const BASE_BILTEN_PRICE = 527.42; // Pretplata za BILTEN

const STANDARD_SUB_PRICE = 1958.97; // Pretplata za OSNOVNI
const PREMIUM_SUB_PRICE = 4822.08; // Pretplata za PREMIUM

const BASE_YEAR_PRICE = 1958.97; // Dodatne gotine za STANDARD_SUB_PRICE
const PREMIUM_YEAR_PRICE = 4822.08; // Dodatne gotine za PREMIUM_SUB_PRICE

const PDV_AMOUNT = 0.13;

function RegisterPageComponent() {
  const dispatch = useDispatch();

  //REMEMBER TO CHANGE PREREG TO TRUE
  const [preregistration, setPreregistration] = useState(true);
  const [postregistration, setPostregistration] = useState(false);
  const [subType, setSubType] = useState(LicenceTypeEnum.STARTER);
  const [userNo, setUserNo] = useState(1);
  const [userNoPremium, setUserNoPremium] = useState(1);
  const [price, setPrice] = useState(0);
  const [priceEUR, setPriceEUR] = useState(0);
  const [priceYears, setPriceYears] = useState(0);
  const [priceYearsEUR, setPriceYearsEUR] = useState(0);
  const [priceNewsletters, setPriceNewsletters] = useState(0);
  const [priceNewslettersEUR, setPriceNewslettersEUR] = useState(0);
  //REMEMBER TO CHANGE ACTIVE STEP TO 0
  const [activeStep, setActiveStep] = useState(0);

  const [activeModel, setActiveModel] = useState<boolean[]>([true, false, false]);

  const [digitalEditions, setDigitalEditions] = useState(0);
  const [printEditions, setPrintEditions] = useState(0);
  const [consultations, setConsultations] = useState(0);
  const [lectures, setLectures] = useState(0);
  const [notes, setNotes] = useState(0);

  const [legal, setLegal] = useState(1);

  const [extraValueYears, setExtraValueYears] = useState<string[]>([]);
  const [extraValueNewsletters, setExtraValueNewsletters] = useState<number>(0);

  const [isLoading, setIsLoading] = useState(false);

  const [registerForm] = Form.useForm();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const model = urlParams.get('model');
    const korisnik = urlParams.get('korisnik');
    const korisnikNum = korisnik ? parseInt(korisnik) : 1;
    switch (model) {
      case "free":    onSubscriptionSelect(LicenceTypeEnum.STARTER, korisnikNum); break;
      case "osnovni": onSubscriptionSelect(LicenceTypeEnum.STANDARD, korisnikNum); break;
      case "premium": onSubscriptionSelect(LicenceTypeEnum.PREMIUM, korisnikNum); break;
      default: onSubscriptionSelect(LicenceTypeEnum.STANDARD, korisnikNum); break;
    }
  }, []);

  const registrationStatus = useSelector((state: StoreStateInterface) => state.register.registrationStatus);
  useEffect(() => {
    if (!registrationStatus) return;
    if (!registrationStatus.isSuccess) {
      notification['error']({ message: i18n.translate('register.msgs.registerError'), duration: 10 });
      return;
    }

    notification['success']({ message: i18n.translate('register.msgs.registerSuccess'), duration: 10 });
    setPostregistration(true);
  }, [registrationStatus]);

  function next() {
    if (activeStep === 0) {
      registerForm
        .validateFields()
        .then(async (values) => {
          let isEmailOk = await checkIfEmailIsFree(values.email);
          if (!isEmailOk) {
            notification['error']({ message: i18n.translate(`register.msgs.emailTaken`), duration: 4 });
            return;
          }
          const nextStep = activeStep + 1;
          setActiveStep(nextStep);
        })
        .catch((error) => {
          console.log(error);
        });
    } else if (activeStep === 1) {
      const jump = activeModel[0] ? 2 : 1;
      const nextStep = activeStep + jump;
      setActiveStep(nextStep);
    } else {
      const nextStep = activeStep + 1;
      setActiveStep(nextStep);
    }
  }

  function prev() {
    if (activeStep === 3) {
      const jump = activeModel[0] ? 2 : 1;
      const prevStep = activeStep - jump;
      setActiveStep(prevStep);
    } else {
      const prevStep = activeStep - 1;
      setActiveStep(prevStep);
    }
  }

  function checkIfEmailIsFree(email: string) {
    return new Promise((resolve, reject) => {
      setIsLoading(true);
      try {
        UserService.checkEmail(email).subscribe((resp) => {
          setIsLoading(false);
          resolve(resp);
        });
      } catch (error) {
        setIsLoading(false);
        resolve(false);
      }
    });
  }

  const currencyFormat = (price: any) => parseFloat(price).toLocaleString('hr-HR', {minimumFractionDigits:2, maximumFractionDigits:2});

  function capitalize(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  const handleChange = (value: any) => {
    const getMultiplierNested = () => {
      switch (value) {
        case 1:
          return 1.0;
        case 2:
          return 1.9;
        case 3:
          return 2.75;
        case 4:
          return 3.6;
        case 5:
          return 4.45;
        default:
          return 1.0;
      }
    };

    const basePrice = STANDARD_SUB_PRICE;
    const multiplier = getMultiplierNested();
    const total = basePrice * multiplier;

    setPrice(total);
    setPriceEUR(total / EUR_CONVERSION);
    setExtraValueNewsletters(0);
    setUserNo(value);
  };

  const handleChangePremium = (value: any) => {
    const getMultiplierNested = () => {
      switch (value) {
        case 1:
          return 1.0;
        case 2:
          return 1.9;
        case 3:
          return 2.75;
        case 4:
          return 3.6;
        case 5:
          return 4.45;
        default:
          return 1.0;
      }
    };

    const basePrice = PREMIUM_SUB_PRICE;
    const multiplier = getMultiplierNested();
    const total = basePrice * multiplier;

    setPrice(total);
    setPriceEUR(total / EUR_CONVERSION);
    setExtraValueNewsletters(1);
    setUserNoPremium(value);
  };

  const onChange = (value: number) => {
    setActiveStep(value);
  };

  const onChangeLegal = (e: RadioChangeEvent) => {
    setLegal(e.target.value);
  };

  const onChangePrintEditions = (value: number) => {
    let basePrice = BASE_BILTEN_PRICE;
    if (activeModel[2]) {
      setPriceNewsletters(basePrice * (value - 1));
      setPriceNewslettersEUR((basePrice / EUR_CONVERSION) * (value - 1));
    } else {
      setPriceNewsletters(basePrice * value);
      setPriceNewslettersEUR((basePrice / EUR_CONVERSION) * value);
    }
    setExtraValueNewsletters(value);
  };

  const onChangeAdditionalYears = (value: string[]) => {
    let basePrice = activeModel[2] ? PREMIUM_YEAR_PRICE : BASE_YEAR_PRICE;
    setPriceYears(basePrice * value.length);
    setPriceYearsEUR((basePrice / EUR_CONVERSION) * value.length);
    setExtraValueYears(value);
  };

  const extraValueYearsAndCurrent = () => {
    let array = [...extraValueYears];
    array.unshift(moment().format('YYYY').toString());
    return array;
  };

  const getSubType = () => {
    switch (subType) {
      case LicenceTypeEnum.STARTER:
        return 'FREE';
      case LicenceTypeEnum.STANDARD:
        return 'STANDARD';
      case LicenceTypeEnum.PREMIUM:
        return 'PREMIUM';
      default:
        return 'FREE';
    }
  };

  const getNotesFinal = () => {
    switch (subType) {
      case LicenceTypeEnum.STARTER:
        return 0;
      case LicenceTypeEnum.STANDARD:
        return 10;
      case LicenceTypeEnum.PREMIUM:
        return 100;
      default:
        return 0;
    }
  };

  const getConsultationsFinal = () => {
    switch (subType) {
      case LicenceTypeEnum.STARTER:
        return 0;
      case LicenceTypeEnum.STANDARD:
        return 5;
      case LicenceTypeEnum.PREMIUM:
        return 8;
      default:
        return 0;
    }
  };

  const getLecturesFinal = () => {
    switch (subType) {
      case LicenceTypeEnum.STARTER:
        return 0;
      case LicenceTypeEnum.STANDARD:
        return 3;
      case LicenceTypeEnum.PREMIUM:
        return 6;
      default:
        return 0;
    }
  };

  const getSubTypeTranslation = () => {
    switch (subType) {
      case LicenceTypeEnum.STARTER:
        return i18n.t('adminProfile.tabs.starter');
      case LicenceTypeEnum.STANDARD:
        return i18n.t('adminProfile.tabs.basic');
      case LicenceTypeEnum.PREMIUM:
        return i18n.t('adminProfile.tabs.premium');
      default:
        return i18n.t('register.licence.subs.starter');
    }
  };

  const getMultiplier = (isPremium: boolean) => {
    switch (isPremium ? userNoPremium : userNo) {
      case 1:
        return 1.0;
      case 2:
        return 1.9;
      case 3:
        return 2.75;
      case 4:
        return 3.6;
      case 5:
        return 4.45;
      default:
        return 1.0;
    }
  };

  const onFinish = () => {
    let registerData: RegisterInterface = {
      freeUser: activeModel[0],
      organization: {
        type: legal,
        name: legal == 1 ? registerForm.getFieldValue('name') : registerForm.getFieldValue('fullName'),
        streetAddress: registerForm.getFieldValue('address'),
        zipCode: parseInt(registerForm.getFieldValue('postNo')),
        city: registerForm.getFieldValue('city'),
        country: registerForm.getFieldValue('country'),
        contact: registerForm.getFieldValue('contact'),
        vat: registerForm.getFieldValue('vat')
      },
      user: {
        email: registerForm.getFieldValue('email'),
        password: registerForm.getFieldValue('password'),
        phoneNumber: registerForm.getFieldValue('contact'),
        name: registerForm.getFieldValue('fullName'),
        footnote: registerForm.getFieldValue('note') ? registerForm.getFieldValue('note') : '',
      },
      licenceInfo: {
        year: moment().format('YYYY').toString(),
        additionalYears: extraValueYears.toString(),
        licenceType: getSubType(),
        subscriptionType: 'SUBSCRIPTION',
        notesNum: getNotesFinal() as number,
        printedEditionsNum: extraValueNewsletters * 3,
        questionsNum: getConsultationsFinal() as number,
        educationsNum: getLecturesFinal() as number,
        usersNum: activeModel[2] ? userNoPremium : userNo,
        biltenSubscription: extraValueNewsletters > 0,
        price: Math.round((priceEUR + priceYearsEUR + priceNewslettersEUR) * 100) / 100,
      },
    };
    dispatch(registerAction(registerData));
  };

  const getTitle = (num: number) => {
    switch (num) {
      case 0:
        return i18n.t('register.steps.personalData');
      case 1:
        return i18n.t('register.steps.subscription');
      case 2:
        return i18n.t('register.steps.additionalOptions');
      case 3:
        return i18n.t('register.steps.review');
      default:
        return i18n.t('register.steps.personalData');
    }
  };

  const currentYear = parseInt(moment().format('YYYY'));

  const yearOptions: SelectProps['options'] = [];
  const hrkPriceYear = currencyFormat(BASE_YEAR_PRICE);
  const eurPriceYear = currencyFormat(BASE_YEAR_PRICE / EUR_CONVERSION);
  const hrkPriceYearPremium = currencyFormat(PREMIUM_YEAR_PRICE);
  const eurPriceYearPremium = currencyFormat(PREMIUM_YEAR_PRICE / EUR_CONVERSION);
  for (let i = (currentYear - 1); i >= 2016; i--) {
    yearOptions.push({
      label: i + `. + ${activeModel[2] ? eurPriceYearPremium : eurPriceYear} EUR (+PDV)`,
      value: i,
    });
  }

  const userCount: SelectProps['options'] = [];
  for (let i = 1; i <= 5; i++) {
    userCount.push({
      label: i + ' ' + (i === 1 ? i18n.translate('register.form.extra.user') : i18n.translate('register.form.extra.users')),
      value: i,
    });
  }

  const newsletterOptions: SelectProps['options'] = [];
  for (let i = 0; i <= userNo; i++) {
    let basePrice = BASE_BILTEN_PRICE;
    let label =
      i === 0
        ? i18n.translate('register.form.extra.noPrintEditions')
        : `${i} kom ${currentYear}. + ${currencyFormat((basePrice / EUR_CONVERSION) * (i - 0))} EUR (+PDV)`
    newsletterOptions.push({
      label: label,
      value: i,
    });
  }

  const newsletterOptionsPremium: SelectProps['options'] = [
    {
      label: `${i18n.translate('register.form.extra.premiumIncluded', { year: currentYear, userNoPremium: userNoPremium})}`,
      value: 1,
    }
  ];

  const getPriceHRK = (type: string) => {
    let total;
    let basePrice;
    let multiplier;
    if (type === LicenceTypeEnum.PREMIUM) {
      basePrice = PREMIUM_SUB_PRICE;
      multiplier = getMultiplier(true);
    } else {
      basePrice = STANDARD_SUB_PRICE;
      multiplier = getMultiplier(false);
    }
    total = (basePrice * multiplier).toFixed(2);

    switch (type) {
      case LicenceTypeEnum.STARTER:
        return 0;
      case LicenceTypeEnum.STANDARD:
        //setPrice(parseInt(total));
        return total;
      case LicenceTypeEnum.PREMIUM:
        //setPrice(parseInt(total));
        return total;
      default:
        //setPrice(parseInt(total));
        return total;
    }
  };

  const getPriceEUR = (type: string) => {
    let total;
    let basePrice;
    let multiplier;
    if (type === LicenceTypeEnum.PREMIUM) {
      basePrice = PREMIUM_SUB_PRICE / EUR_CONVERSION;
      multiplier = getMultiplier(true);
    } else {
      basePrice = STANDARD_SUB_PRICE / EUR_CONVERSION;
      multiplier = getMultiplier(false);
    }
    total = (basePrice * multiplier).toFixed(2);

    switch (type) {
      case LicenceTypeEnum.STARTER:
        return 0;
      case LicenceTypeEnum.STANDARD:
        //(parseInt(total));
        return total;
      case LicenceTypeEnum.PREMIUM:
        //(parseInt(total));
        return total;
      default:
        //setPriceEUR(parseInt(total));
        return total;
    }
  };

  const getDetails = (type: string) => {
    switch (type) {
      case LicenceTypeEnum.STARTER:
        setDigitalEditions(0);
        setPrintEditions(0);
        setConsultations(0);
        setNotes(0);
        setLectures(0);
      case LicenceTypeEnum.STANDARD:
        setDigitalEditions(3);
        setPrintEditions(0);
        setConsultations(7);
        setNotes(5);
        setLectures(3);
      case LicenceTypeEnum.PREMIUM:
        setDigitalEditions(3);
        setPrintEditions(3);
        setConsultations(8);
        setNotes(10);
        setLectures(6);
      default:
    }
  };

  const UserOrgForm = () => {
    return (
      <>
        <Radio.Group onChange={onChangeLegal} value={legal} style={{ marginTop: 16, marginBottom: 32 }}>
          <Radio value={1}>{i18n.translate('register.form.legal')}</Radio>
          <Radio value={2}>{i18n.translate('register.form.physical')}</Radio>
        </Radio.Group>

        <Form
          form={registerForm}
          layout="vertical"
          name="registration"
          size="large"
          autoComplete="off"
          requiredMark
          scrollToFirstError
        >
          <>
            {legal == 1 ? (
              <>
                <div className="subtitle">
                  <span className="text">{i18n.translate('register.form.titles.organization')}</span>
                  <div className="prompt">{i18n.translate('register.form.prompts.legalOnly')}</div>
                </div>

                <Form.Item
                  label={<span className="text-bold">{i18n.translate(`register.form.name`)}</span>}
                  name="name"
                  rules={[{ required: true, message: i18n.translate(`register.msgs.required`) }]}
                >
                  <Input style={{ borderRadius: 0 }} />
                </Form.Item>

                <Form.Item
                  label={<span className="text-bold">{i18n.translate(`register.form.vat`)}</span>}
                  name="vat"
                  rules={[{ required: true, message: i18n.translate(`register.msgs.required`) }]}
                  tooltip={i18n.translate(`register.form.vatTooltip`)}
                >
                  <Input style={{ borderRadius: 0 }} />
                </Form.Item>

                <Form.Item
                  label={<span className="text-bold">{i18n.translate(`register.form.contact`)}</span>}
                  name="contact"
                  rules={[{ required: true, message: i18n.translate(`register.msgs.required`) }]}
                >
                  <Input style={{ borderRadius: 0 }} />
                </Form.Item>

                <Form.Item
                  label={<span className="text-bold">{i18n.translate(`register.form.address`)}</span>}
                  name="address"
                  rules={[{ required: true, message: i18n.translate(`register.msgs.required`) }]}
                >
                  <Input style={{ borderRadius: 0 }} />
                </Form.Item>

                <Form.Item
                  label={<span className="text-bold">{i18n.translate(`register.form.postNo`)}</span>}
                  name="postNo"
                  rules={[{ required: true, message: i18n.translate(`register.msgs.required`) }]}
                >
                  <Input style={{ borderRadius: 0 }} />
                </Form.Item>

                <Form.Item
                  label={<span className="text-bold">{i18n.translate(`register.form.city`)}</span>}
                  name="city"
                  rules={[{ required: true, message: i18n.translate(`register.msgs.required`) }]}
                >
                  <Input style={{ borderRadius: 0 }} />
                </Form.Item>

                <Form.Item
                  label={<span className="text-bold">{i18n.translate(`register.form.country`)}</span>}
                  name="country"
                  rules={[{ required: true, message: i18n.translate(`register.msgs.required`) }]}
                >
                  <Input style={{ borderRadius: 0 }} />
                </Form.Item>

                <div className="subtitle">
                  <span className="text">{i18n.translate('register.form.titles.user')}</span>
                </div>

                <Form.Item
                  label={<span className="text-bold">{i18n.translate(`register.form.fullName`)}</span>}
                  name="fullName"
                  rules={[{ required: true, message: i18n.translate(`register.msgs.required`) }]}
                >
                  <Input style={{ borderRadius: 0 }} />
                </Form.Item>

                <Form.Item
                  label={<span className="text-bold">{i18n.translate(`register.form.email`)}</span>}
                  name="email"
                  rules={[
                    { required: true, message: i18n.translate(`register.msgs.required`) },
                    { type: 'email', message: i18n.translate(`register.msgs.email`) },
                  ]}
                >
                  <Input style={{ borderRadius: 0 }} />
                </Form.Item>

                <Form.Item
                  label={<span className="text-bold">{i18n.translate(`register.form.password`)}</span>}
                  name="password"
                  rules={[{ required: true, message: i18n.translate(`register.msgs.required`) }, {
                    validator: (_, value) =>
                      !value.includes(" ")
                        ? Promise.resolve()
                        : Promise.reject(new Error(i18n.t('common.noSpace')))
                  }]}
                  tooltip={i18n.translate(`register.form.passwordTooltip`)}
                >
                  <Input.Password style={{ borderRadius: 0 }} />
                </Form.Item>

                <Form.Item label={<span className="text-bold">{i18n.translate(`register.form.note`)}</span>} name="note">
                  <TextArea
                    showCount
                    rows={3}
                    style={{ borderRadius: 0 }}
                  />
                </Form.Item>
              </>
            ) : (
              <>
                <div className="subtitle">
                  <span className="text">{i18n.translate('register.form.titles.user')}</span>
                </div>

                <Form.Item
                  label={<span className="text-bold">{i18n.translate(`register.form.fullName`)}</span>}
                  name="fullName"
                  rules={[{ required: true, message: i18n.translate(`register.msgs.required`) }]}
                >
                  <Input style={{ borderRadius: 0 }} />
                </Form.Item>

                <Form.Item
                  label={<span className="text-bold">{i18n.translate(`register.form.vat`)}</span>}
                  name="vat"
                  rules={[{ required: true, message: i18n.translate(`register.msgs.required`) }]}
                >
                  <Input style={{ borderRadius: 0 }} />
                </Form.Item>

                <Form.Item
                  label={<span className="text-bold">{i18n.translate(`register.form.email`)}</span>}
                  name="email"
                  rules={[
                    { required: true, message: i18n.translate(`register.msgs.required`) },
                    { type: 'email', message: i18n.translate(`register.msgs.email`) },
                  ]}
                >
                  <Input style={{ borderRadius: 0 }} />
                </Form.Item>

                <Form.Item
                  label={<span className="text-bold">{i18n.translate(`register.form.password`)}</span>}
                  name="password"
                  rules={[{ required: true, message: i18n.translate(`register.msgs.required`) }, {
                    validator: (_, value) =>
                      !value.includes(" ")
                        ? Promise.resolve()
                        : Promise.reject(new Error(i18n.t('common.noSpace')))
                  }]}
                  tooltip={i18n.translate(`register.form.passwordTooltip`)}
                >
                  <Input.Password style={{ borderRadius: 0 }} />
                </Form.Item>

                <Form.Item
                  label={<span className="text-bold">{i18n.translate(`register.form.contact`)}</span>}
                  name="contact"
                  rules={[{ required: true, message: i18n.translate(`register.msgs.required`) }]}
                >
                  <Input style={{ borderRadius: 0 }} />
                </Form.Item>

                <Form.Item
                  label={<span className="text-bold">{i18n.translate(`register.form.address`)}</span>}
                  name="address"
                  rules={[{ required: true, message: i18n.translate(`register.msgs.required`) }]}
                >
                  <Input style={{ borderRadius: 0 }} />
                </Form.Item>

                <Form.Item
                  label={<span className="text-bold">{i18n.translate(`register.form.postNo`)}</span>}
                  name="postNo"
                  rules={[{ required: true, message: i18n.translate(`register.msgs.required`) }]}
                >
                  <Input style={{ borderRadius: 0 }} />
                </Form.Item>

                <Form.Item
                  label={<span className="text-bold">{i18n.translate(`register.form.city`)}</span>}
                  name="city"
                  rules={[{ required: true, message: i18n.translate(`register.msgs.required`) }]}
                >
                  <Input style={{ borderRadius: 0 }} />
                </Form.Item>

                <Form.Item
                  label={<span className="text-bold">{i18n.translate(`register.form.country`)}</span>}
                  name="country"
                  rules={[{ required: true, message: i18n.translate(`register.msgs.required`) }]}
                >
                  <Input style={{ borderRadius: 0 }} />
                </Form.Item>

                <Form.Item label={<span className="text-bold">{i18n.translate(`register.form.note`)}</span>} name="note">
                  <TextArea
                    showCount
                    rows={3}
                    style={{ borderRadius: 0 }}
                  />
                </Form.Item>
              </>
            )}
          </>
        </Form>
      </>
    );
  };

  const onSubscriptionSelect = (subscriptionType: LicenceTypeEnum, userNum?: number) => {
    switch (subscriptionType) {
      case LicenceTypeEnum.STARTER:
        setExtraValueNewsletters(0);
        setUserNo(userNum || 1);
        setUserNoPremium(userNum || 1);
        setActiveModel([true, false, false]);
        setPrice(0);
        setPriceEUR(0);
        setSubType(LicenceTypeEnum.STARTER);
        getDetails(LicenceTypeEnum.STARTER);
        onChangePrintEditions(0);
        return;

      case LicenceTypeEnum.STANDARD:
        setExtraValueNewsletters(0);
        setUserNo(userNum || 1);
        setUserNoPremium(userNum || 1);
        setActiveModel([false, true, false]);
        setPrice(STANDARD_SUB_PRICE);
        setPriceEUR(STANDARD_SUB_PRICE / EUR_CONVERSION);
        setSubType(LicenceTypeEnum.STANDARD);
        getDetails(LicenceTypeEnum.STANDARD);
        return;

      case LicenceTypeEnum.PREMIUM:
        setExtraValueNewsletters(1);
        setUserNo(userNum || 1);
        setUserNoPremium(userNum || 1);
        setActiveModel([false, false, true]);
        setPrice(PREMIUM_SUB_PRICE);
        setPriceEUR(PREMIUM_SUB_PRICE / EUR_CONVERSION);
        setSubType(LicenceTypeEnum.PREMIUM);
        getDetails(LicenceTypeEnum.PREMIUM);
        return;
    }
  };

  const SubModelForm = () => {
    return (
      <>
        <Row wrap={true} justify={'center'} gutter={22}>
          <Col onClick={() => onSubscriptionSelect(LicenceTypeEnum.STARTER)}>
            <LicenceCardComponent
              type={LicenceTypeEnum.STARTER}
              users={userCount}
              priceHRK={getPriceHRK(LicenceTypeEnum.STARTER) as number}
              priceEUR={getPriceEUR(LicenceTypeEnum.STARTER) as number}
              selected={activeModel[0]}
            />
          </Col>
          <Col onClick={() => onSubscriptionSelect(LicenceTypeEnum.STANDARD)}>
            <LicenceCardComponent
              type={LicenceTypeEnum.STANDARD}
              users={userCount}
              userNo={userNo}
              priceHRK={getPriceHRK(LicenceTypeEnum.STANDARD) as number}
              priceEUR={getPriceEUR(LicenceTypeEnum.STANDARD) as number}
              handleChange={handleChange}
              selected={activeModel[1]}
            />
          </Col>
          <Col onClick={() => onSubscriptionSelect(LicenceTypeEnum.PREMIUM)}>
            <LicenceCardComponent
              type={LicenceTypeEnum.PREMIUM}
              users={userCount}
              userNo={userNoPremium}
              priceHRK={getPriceHRK(LicenceTypeEnum.PREMIUM) as number}
              priceEUR={getPriceEUR(LicenceTypeEnum.PREMIUM) as number}
              handleChangePremium={handleChangePremium}
              selected={activeModel[2]}
              isPremium
            />
          </Col>
        </Row>
        <div style={{textAlign: 'right'}}>
          <div>{i18n.translate('register.licence.extra.line1')}</div>
        </div>
      </>
    );
  };

  const ExtraForm = () => {
    return (
      <div style={{ maxWidth: 500 }}>
        <div className="subtitle">
          <span className="text">{i18n.translate('register.form.titles.options')}</span>
          <div className="prompt">{i18n.translate('register.form.prompts.options', { year: currentYear })}</div>
        </div>

        <div style={{ marginBottom: 32 }}>
          <div style={{ fontWeight: 'bold', marginBottom: 8 }}>{i18n.translate(`register.form.pickYear`)}</div>
          <Select
            style={{ width: '100%' }}
            mode="multiple"
            value={extraValueYears}
            allowClear
            options={yearOptions}
            onChange={onChangeAdditionalYears}
          />
        </div>

        <div className="subtitle">
          <span className="text">{i18n.translate('register.form.titles.printEditions')}</span>
          {!activeModel[2] && <div className="prompt">{i18n.translate('register.form.prompts.printEditions', { year: currentYear })}</div>}
          {activeModel[2] && <div className="prompt">{i18n.translate('register.form.prompts.printEditionsPremium', { year: currentYear })}</div>}
        </div>

        <div style={{ marginBottom: 32 }}>
          <div style={{ fontWeight: 'bold', marginBottom: 8 }}>{i18n.translate(`register.form.prints`)}</div>
          <Select
            style={{ width: '100%' }}
            value={extraValueNewsletters}
            options={activeModel[2] ? newsletterOptionsPremium : newsletterOptions}
            onChange={onChangePrintEditions}
          />
        </div>
      </div>
    );
  };

  const TotalForm = () => {
    return (
      <>
        <div>
          <Row justify="space-between">
            <Col className="titleCol" span={10}>
              <Text type="secondary">{i18n.translate('register.form.overview.model')}</Text>
            </Col>
            <Col span={10}>
              <Text strong>{getSubTypeTranslation()}</Text>
            </Col>
          </Row>
          <Divider />
          <Row justify="space-between">
            <Col className="titleCol" span={10}>
              <Text type="secondary">{i18n.translate('register.form.overview.userNo')}</Text>
            </Col>
            {activeModel[2] ? (
              <Col span={10}>
                {userNoPremium ? (
                  <Text strong>
                    {userNoPremium} {userNoPremium > 1 ? 'korisnika' : 'korisnik'}
                  </Text>
                ) : (
                  <Text strong>0,00 EUR</Text>
                )}
              </Col>
            ) : (
              <Col span={10}>
                {userNo ? (
                  <Text strong>
                    {userNo} {userNo > 1 ? 'korisnika' : 'korisnik'}
                  </Text>
                ) : (
                  <Text strong>0,00 EUR</Text>
                )}
              </Col>
            )}
          </Row>
          <Divider />
          <Row justify="space-between">
            <Col className="titleCol" span={10}>
              <Text type="secondary">{i18n.translate('register.form.overview.printSubs')} {currentYear}.</Text>
            </Col>
            <Col span={10}>
              <Space direction="vertical">
                {extraValueNewsletters > 0 ? <Text strong>Da</Text> : <Text strong>Ne</Text>}
              </Space>
            </Col>
          </Row>
          <Divider />
          <Row justify="space-between">
            <Col className="titleCol" span={10}>
              <Text type="secondary">{i18n.translate('register.form.overview.years')}</Text>
            </Col>
            <Col span={10}>
              <Space direction="vertical">
                {extraValueYears.length ? (
                  extraValueYearsAndCurrent().map((year: string) => <Text strong>{year}.</Text>)
                ) : (
                  <Text strong>{moment().format('YYYY.').toString()}</Text>
                )}
              </Space>
            </Col>
          </Row>
        </div>
        <YearDividerComponent title={''} />
        <div className="total">
          <Row style={{ marginTop: 24, marginBottom: 24 }} justify="space-between">
            <Col className="titleCol" span={10}>
              <Text strong>{i18n.translate('register.form.overview.yearTotal')}</Text>
            </Col>
            <Col span={10}>
              {price && priceEUR ? (
                <Text>
                  {currencyFormat(priceEUR + priceYearsEUR)} EUR
                </Text>
              ) : (
                <Text strong>0,00 EUR</Text>
              )}
            </Col>
          </Row>
          <Row style={{ marginTop: 24, marginBottom: 24 }} justify="space-between">
            <Col className="titleCol" span={10}>
              <Text strong>{i18n.translate('register.form.overview.printSub')}</Text>
            </Col>
            <Col span={10}>
              {priceNewsletters && priceNewslettersEUR ? (
                <Text>
                  {currencyFormat(priceNewslettersEUR)} EUR
                </Text>
              ) : (
                <Text strong>0,00 EUR</Text>
              )}
            </Col>
          </Row>
          <Row style={{ marginTop: 24, marginBottom: 24 }} justify="space-between">
            <Col className="titleCol" span={10}>
              <Text strong>{i18n.translate('register.form.overview.pdvValue')}</Text>
            </Col>
            <Col span={10}>
              <Text>
                {currencyFormat((priceEUR + priceYearsEUR + priceNewslettersEUR) * PDV_AMOUNT)} EUR
              </Text>
            </Col>
          </Row>
          <Row style={{ marginTop: 24, marginBottom: 24 }} justify="space-between">
            <Col className="titleCol" span={10}>
              <Text strong>{i18n.translate('register.form.overview.total')}</Text>
            </Col>
            <Col span={10}>
              {price && priceEUR ? (
                <Text strong style={{ color: 'blue' }}>
                  {currencyFormat(priceEUR + priceYearsEUR + priceNewslettersEUR + (priceEUR + priceYearsEUR + priceNewslettersEUR) * PDV_AMOUNT)} EUR
                </Text>
              ) : (
                <Text strong>0,00 EUR</Text>
              )}
            </Col>
          </Row>
        </div>
        
        <div className='terms'>
          <Button type="link" style={{paddingLeft: 2}} onClick={() => window.open(`${process.env.REACT_APP_URL_WP}/uvjeti-koristenja`, '_blank')}>
            <span style={{textDecoration: 'underline'}}>{i18n.translate(`register.msgs.terms`)}</span>
          </Button>
          <Button type="link" style={{paddingLeft: 2}} onClick={() => window.open(`${process.env.REACT_APP_URL_WP}/smjernice-i-pravila-o-privatnosti`, '_blank')}>
            <span style={{textDecoration: 'underline'}}>{i18n.translate(`register.msgs.privacy`)}</span>
          </Button>
        </div>
        
        <div className='terms'>{i18n.translate(`register.msgs.termsAndPrivacy`)}</div>
      </>
    );
  };

  const StepPanel = (props: any) => {
    return (
      <>
        <Steps labelPlacement="vertical" current={activeStep} responsive onChange={onChange}>
          {props.steps.map((item: any) => (
            <Steps.Step disabled key={item.title} title={item.title} />
          ))}
        </Steps>
        {!postregistration && (
          <>
            <div className="register___steps">{props.steps[activeStep].content}</div>
            <div className="buttons">
              {activeStep > 0 && (
                <Button size="large" onClick={() => prev()}>
                  {i18n.translate(`register.btns.previous`)}
                </Button>
              )}
              {activeStep < props.steps.length - 1 && (
                <Button size="large" type="primary" onClick={() => next()} loading={isLoading}>
                  {i18n.translate(`register.btns.next`)}
                </Button>
              )}
              {activeStep === props.steps.length - 1 && (
                <Button type="primary" size="large" onClick={() => onFinish()}>
                  {i18n.translate(`register.btns.confirm`)}
                </Button>
              )}
              <Button size="large">
                <Link to={AuthRoutes.LOGIN.path} key={AuthRoutes.LOGIN.path}>
                  {i18n.translate(`register.btns.cancel`)}
                </Link>
              </Button>
            </div>
          </>
        )}
      </>
    );
  };

  const steps = [
    {
      title: getTitle(0),
      content: <UserOrgForm />,
    },
    {
      title: getTitle(1),
      content: <SubModelForm />,
    },
    {
      title: getTitle(2),
      content: <ExtraForm />,
    },
    {
      title: getTitle(3),
      content: <TotalForm />,
    },
  ];

  const getYearText = () => {
    return i18n.translate(`register.title.year`, {year: moment().format('YYYY')});
  }

  return (
    <div className="register">
      <div className={`${!preregistration ? 'register__icon2' : 'register__icon'}`}>
        <img width={38} height={32} src={logo} alt="none" />
        <div className="text">BJN</div>
      </div>
      {preregistration && (
        <>
          <div className="register__title">
            <div className="primary">{i18n.translate(`register.title.primary`)}</div>
            <div className="subtitle">{i18n.translate(`register.title.subtitle`)}</div>
          </div>
          <div className="register__button">
            <Button
              onClick={() => setPreregistration(false)}
              type="primary"
              block
              size="large"
              style={{ fontSize: 16, fontWeight: 700, borderRadius: 0 }}
            >
              {i18n.translate(`register.btns.register`)}
            </Button>
            <div style={{ marginTop: 12 }}>
              {i18n.translate(`register.btns.haveAccount`)}
              <Link to={AuthRoutes.LOGIN.path} key={AuthRoutes.LOGIN.path}>
                <Button type="link" style={{ paddingLeft: 2 }}>
                  <span style={{ textDecoration: 'underline' }}>{i18n.translate(`register.btns.login`)}</span>
                </Button>
              </Link>
            </div>
          </div>
        </>
      )}
      {!preregistration && (
        <>
          <div className='year-text'>{getYearText()}</div>
          <div className="register__form">
            <div className="form">
              {!postregistration && <div className="title">{getTitle(activeStep)}</div>}
              <StepPanel steps={steps} />
              {postregistration && (
                <div style={{ maxWidth: 600 }}>
                  <div className="register__title">
                    <div className="primary" style={{ marginTop: '50px' }}>{i18n.translate(`dashboard.title`)}</div>
                    <div style={{ marginTop: '16px' }}>{i18n.translate(`register.msgs.registrationComplete.text`)}</div>
                    <div style={{ margin: '16px 0' }}>Tel: {i18n.translate(`register.msgs.registrationComplete.tel`)}</div>
                    <div>Email: <a style={{color: "#1210A9"}} href={"mailto:"+i18n.translate(`register.msgs.registrationComplete.email`)}>{i18n.translate(`register.msgs.registrationComplete.email`)}</a></div>
                  </div>

                  <div className="register__button">
                    <Link to={AuthRoutes.LOGIN.path} key={AuthRoutes.LOGIN.path}>
                      <Button
                        type="primary"
                        block
                        size="large"
                        style={{ fontSize: 16, fontWeight: 700, borderRadius: 0 }}
                      >
                        {i18n.translate(`genericButtons.ok`)}
                      </Button>
                    </Link>
                  </div>

                  <div className='terms'>
                    <Button type="link" style={{paddingLeft: 2}} onClick={() => window.open(`${process.env.REACT_APP_URL_WP}/uvjeti-koristenja`, '_blank')}>
                      <span style={{textDecoration: 'underline'}}>{i18n.translate(`register.msgs.terms`)}</span>
                    </Button>
                    <Button type="link" style={{paddingLeft: 2}} onClick={() => window.open(`${process.env.REACT_APP_URL_WP}/smjernice-i-pravila-o-privatnosti`, '_blank')}>
                      <span style={{textDecoration: 'underline'}}>{i18n.translate(`register.msgs.privacy`)}</span>
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default RegisterPageComponent;
