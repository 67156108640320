import { Breadcrumb, Button, Collapse, notification, Pagination, PaginationProps, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import {
  ArticleCardComponent,
  clearNoteAction,
  clearProfessionalArticleAction,
  clearProfessionalArticleListAction,
  FilterComponent,
  getAllProfessionalArticlesAction,
  getScreenTagsByTypeAction,
  i18n,
  LicenceInterface,
  LicenceResponseInterfaceSingle,
  LicenceService,
  LicenceUserResponseInterfaceAll,
  LicenceUserService,
  NoDataComponent,
  NoteResponseInterfaceAll,
  NoteService,
  ProfessionalArticleEsResponseInterface,
  ProfessionalArticleInterface,
  ProfessionalArticleService,
  ProfessionalArticleStateInterface,
  StoreStateInterface,
  TagScreenStateInterface,
} from '../../../common';
import { HomeOutlined, CaretRightOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { AdminRoutes } from '../_router/admin.routes';
import { Link } from 'react-router-dom';
import moment from 'moment';

const { Text } = Typography;
const { Panel } = Collapse;

function ExpertArticlesPageComponent() {
  
  const userAuth = useSelector((state: StoreStateInterface) => state.auth);

  const dispatch = useDispatch();

  const data: ProfessionalArticleStateInterface = useSelector(
    (state: StoreStateInterface) => state.professionalArticle
  );
  const tags: TagScreenStateInterface = useSelector((state: StoreStateInterface) => state.tagScreen);

  const [filterValues, setFilterValues] = useState();
  const [esData, setEsData] = useState<ProfessionalArticleEsResponseInterface>();

  const [keyword, setKeyword] = useState('');
  const [author, setAuthor] = useState('');
  //const [allOpinions, setAllOpinions] = useState(true);
  const [exactMatch, setExactMatch] = useState<boolean>(true);

  const [esSearch, setEsSearch] = useState(true);

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const [licenceData, setLicenceData] = useState<LicenceInterface>();
  const [noteData, setNoteData] = useState<NoteResponseInterfaceAll>();

  useEffect(() => {
    clearNoteAction();
    NoteService.getAll(userAuth.user?.id, false).subscribe(
      (response: NoteResponseInterfaceAll) => {
        setNoteData(response); 
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
      }
    );
  }, [userAuth?.user?.id]);

  useEffect(() => {
    LicenceUserService.getAll(undefined, `filter[user.id]=${userAuth.user?.id}`, undefined, `page[offset]=0&page[limit]=999999`).subscribe(
      (response: LicenceUserResponseInterfaceAll) => {
        if (response.data?.length && response.data[0]) {
          const licenceId = response?.data[0]?.relationships?.licence?.data?.id;
          if (licenceId) {
            LicenceService.getSingle(licenceId).subscribe(
              (response: LicenceResponseInterfaceSingle) => {
                setLicenceData(response.data);
              }
            );
          }
        }
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    localStorage.removeItem('transferFilters');
    dispatch(clearProfessionalArticleAction());
    dispatch(clearProfessionalArticleListAction());
    dispatch(getScreenTagsByTypeAction('professional_article'));
    /**
    dispatch(
      getAllProfessionalArticlesAction(
        undefined,
        undefined,
        'sort=-publishedAt',
        `page[offset]=${pageSize * (page - 1)}&page[limit]=${pageSize}`
      )
    );
    */
    esSearchFunction(false, { from: pageSize * (page - 1), size: pageSize });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const esSearchFunction = (allOpinions: boolean, pageSorted: {from: number, size: number}, keyword?: string, tagIds?: any, author?: string) => {
    ProfessionalArticleService.elasticSearch(allOpinions, pageSorted, exactMatch, keyword, tagIds, author).subscribe(
      (response: ProfessionalArticleEsResponseInterface) => {
        setEsData(response);
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
      }
    );
  };

  const refetch = (allValues: any) => {
    //setEsSearch(true);
    setFilterValues(allValues);
    setPage(1);
    esSearchFunction(false, {from: 0, size: pageSize}, keyword, allValues, author);
  };

  const resetSearch = () => {
    setEsData(undefined);
    setKeyword('');
    setAuthor('');
    //setAllOpinions(true);
    //setEsSearch(false);
    setFilterValues(undefined);
    setPage(1);
    //dispatch(getAllProfessionalArticlesAction(undefined, undefined, 'sort=-publishedAt', `page[offset]=0&page[limit]=${pageSize}`));
    esSearchFunction(false, { from: 0, size: pageSize });
  };

  const onPaginationChange: PaginationProps['onChange'] = (page, pageSize) => {
    setPage(page);
    setPageSize(pageSize);
    if (esSearch) {
      esSearchFunction(false, {from: pageSize * (page - 1), size: pageSize}, keyword, filterValues, author);
    } else {
      dispatch(getAllProfessionalArticlesAction(filterValues, undefined, 'sort=-publishedAt',`page[offset]=${pageSize * (page - 1)}&page[limit]=${pageSize}`));
    }
  };

  const keywordChanged = (value: string) => {
    setKeyword(value);
  };

  const authorChanged = (value: string) => {
    setAuthor(value);
  };

  const exactMatchChanged = (exactMatch: boolean) => {
    setExactMatch(exactMatch);
  };

  const convertDataType = (articles: ProfessionalArticleEsResponseInterface["hits"]): ProfessionalArticleInterface[] => {
    return articles.map((article) => {
      return {
        id: article.id,
        type: article.index,
        attributes: article.source
      };
    })
  };

  const sortedArticles = (articles: ProfessionalArticleInterface[]) => {
    let articleArray = articles;

    function onlyUnique(value: any, index: any, self: string | any[]) {
      return self.indexOf(value) === index;
    }

    let yearArray: string[] = [];
    let articleGroups: (ProfessionalArticleInterface[] | undefined)[] = [];

    articleArray?.forEach(article => {
      yearArray.push(article.attributes?.publishedAt ? moment(article.attributes?.publishedAt).format('YYYY.') : '');
    });

    let unique = yearArray?.filter(onlyUnique);

    unique?.forEach(entry => {
      articleGroups.push(articleArray?.filter(x => moment(x.attributes?.publishedAt).format('YYYY.') == entry));
    });

    if (articleArray.find(x => !x.attributes?.publishedAt)) {
      articleGroups.push(articleArray?.filter(x => !x.attributes?.publishedAt));
    }

    return articleGroups.filter(x => x?.length as number > 0);
  };

  const genHeader = (article: ProfessionalArticleInterface | undefined) => {
    return (
      <span className="header">
        <Text className="extraText">
          {article?.attributes?.publishedAt ? moment(article?.attributes?.publishedAt).format('YYYY.') : i18n.translate('common.unsorted')}
        </Text>
      </span>
    );
  };

  const getDefaultActiveKeys = (articles: ProfessionalArticleInterface[]) => {
    let articleArray = articles;

    function onlyUnique(value: any, index: any, self: string | any[]) {
      return self.indexOf(value) === index;
    }

    let yearArray: string[] = [];
    let defaultOpenArray: string[] = [];

    articleArray?.forEach(article => {
      yearArray.push(article.attributes?.publishedAt ? moment(article.attributes?.publishedAt).format('YYYY.') : '');
    });

    let unique = yearArray?.filter(onlyUnique);

    unique?.forEach((entry, index) => {
      defaultOpenArray.push('article_sorted' + index);
    });

    if (defaultOpenArray.length === 0) defaultOpenArray.push('article_sorted' + 0);
    if (defaultOpenArray.length > 0) defaultOpenArray.push('article_sorted' + defaultOpenArray.length);

    return defaultOpenArray;
  };

  return (
    <div className="expertArticles w100-h100">
      <div className="expertArticles__breadcrumbs">
        <Breadcrumb>
          <Breadcrumb.Item>
            <HomeOutlined />
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span>{i18n.translate('breadcrumbs.web-of-knowledge')}</span>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span>{i18n.translate('menu.expert-articles')}</span>
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="expertArticles__admin">
        <div className="expertArticles__title">{i18n.translate('expertArticles.title')}</div>
        <Link to={AdminRoutes.EXPERT_ARTICLES.fullPath + `/new`} key={AdminRoutes.EXPERT_ARTICLES.path + `/new`}>
          <Button
            className="button"
            type="primary"
            size="large"
            style={{ fontSize: 16, fontWeight: 700, borderRadius: 0 }}
          >
            {i18n.translate('expertArticles.addButton')}
          </Button>
        </Link>
      </div>
      {tags.tagScreens && tags.tagScreens.length ? (
        <FilterComponent aggregation={esData?.aggregation?.buckets} filterAccess onExactMatchChange={exactMatchChanged} keyword={keyword} author={author} filters={tags.tagScreens} onFilterChange={refetch} onFilterRemove={resetSearch} onKeywordChange={keywordChanged} onAuthorChange={authorChanged} />
      ) : null}
      {/**<YearDividerComponent title={''}/>*/}
      {esSearch &&
        <>
          {esData?.hits?.length ? (
            <Collapse className='noPointer' collapsible={undefined} activeKey={getDefaultActiveKeys(convertDataType(esData?.hits as ProfessionalArticleEsResponseInterface["hits"]) as ProfessionalArticleInterface[])} ghost expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 270 : 90} />}>
            {sortedArticles(convertDataType(esData?.hits as ProfessionalArticleEsResponseInterface["hits"]) as ProfessionalArticleInterface[])?.length ? 
            [...sortedArticles(convertDataType(esData?.hits as ProfessionalArticleEsResponseInterface["hits"]) as ProfessionalArticleInterface[])]
              .map((group, index: number) => (
                <Panel className='noPointer' showArrow={false} forceRender key={'article_sorted' + index} header={genHeader(group?.length && group[0] ? group[0] : undefined)}>
                  {group?.length ? (
                    [...(group as ProfessionalArticleInterface[])]
                      ?.map((article, i) => (
                        <ArticleCardComponent key={'article' + i} article={article} notes={noteData}/>
                      ))
                  ) : (
                    <div className="center">
                      <NoDataComponent />
                    </div>
                  )}
                </Panel>
              )) : null}
            </Collapse>
          ) : (
            <NoDataComponent />
          )}
        </>
      }
      {!esSearch &&
        <>
          {data.professionalArticles?.data?.length ? (
            <Collapse className='noPointer' collapsible={undefined} activeKey={getDefaultActiveKeys(data.professionalArticles?.data)} ghost expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 270 : 90} />}>
            {sortedArticles(data.professionalArticles?.data)?.length ? 
            [...sortedArticles(data.professionalArticles?.data)]
              .map((group, index: number) => (
                <Panel className='noPointer' showArrow={false} forceRender key={'article_sorted' + index} header={genHeader(group?.length && group[0] ? group[0] : undefined)}>
                  {group?.length ? (
                    [...(group as ProfessionalArticleInterface[])]
                      ?.map((article, i) => (
                        <ArticleCardComponent key={'article' + i} article={article} notes={noteData}/>
                      ))
                  ) : (
                    <div className="center">
                      <NoDataComponent />
                    </div>
                  )}
                </Panel>
              )) : null}
            </Collapse>
          ) : (
            <NoDataComponent />
          )}
        </>
      }
      {esSearch &&
        <>
          {esData?.hits?.length ? (
            <Pagination
              style={{ float: 'right', paddingTop: 16, paddingBottom: 16 }}
              onChange={onPaginationChange}
              current={page}
              pageSize={pageSize}
              pageSizeOptions={['10', '20', '50', '100']}
              locale={{ items_per_page: `${i18n.t('pagination.perPage')}` }}
              total={esData?.totalHits}
              hideOnSinglePage
              responsive
              showTotal={(total) => `${i18n.t('pagination.total')}: ${total}`}
            />
          ) : null}
        </>
      }
      {!esSearch &&
        <>
          {data.professionalArticles?.data?.length ? (
            <Pagination
              style={{ float: 'right', paddingTop: 16, paddingBottom: 16 }}
              onChange={onPaginationChange}
              current={page}
              pageSize={pageSize}
              pageSizeOptions={['10', '20', '50', '100']}
              locale={{ items_per_page: `${i18n.t('pagination.perPage')}` }}
              total={data?.professionalArticles?.meta?.totalResourceCount}
              hideOnSinglePage
              responsive
              showTotal={(total) => `${i18n.t('pagination.total')}: ${total}`}
            />
          ) : null}
        </>
      }
    </div>
  );
}

export default ExpertArticlesPageComponent;
