import { Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import {
  BasicInfoComponent,
  i18n,
  PasswordComponent,
  PrintEditionsComponent,
  SubscriptionModelsComponent
} from '../../../common';
import { useLocation } from 'react-router-dom';

function UserProfilePageComponent() {
  const location = useLocation();
  const search = location.search.split('=');
  const tabParam = search[search.length - 1];

  const [selectedTab, setSelectedTab] = useState(tabParam ? tabParam : '1');

  useEffect(() => {
    localStorage.removeItem('transferFilters');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  return (
    <div className="profile w100-h100">
      <div className="profile__title" style={{ display: 'flex', justifyContent: 'space-between' }}>
        {i18n.translate('profile.title')}
      </div>
      <div className="profile___content">
        <Tabs activeKey={selectedTab} onChange={(activeKey) => setSelectedTab(activeKey)} destroyInactiveTabPane>
          <Tabs.TabPane tab={i18n.translate('profile.tabs.basicInfo')} key="1">
            <BasicInfoComponent />
          </Tabs.TabPane>
          <Tabs.TabPane tab={i18n.translate('profile.tabs.subModels')} key="2">
            <SubscriptionModelsComponent />
          </Tabs.TabPane>
          {/** UNCOMMENT AFTER FAZE 2 */}
          {/* <Tabs.TabPane tab={i18n.translate('profile.tabs.team')} key="3">
            <TeamComponent />
          </Tabs.TabPane> */}
          <Tabs.TabPane tab={i18n.translate('profile.tabs.password')} key="4">
            <PasswordComponent />
          </Tabs.TabPane>
          <Tabs.TabPane tab={i18n.translate('profile.tabs.printEditions')} key="5">
            <PrintEditionsComponent />
          </Tabs.TabPane>
        </Tabs>
      </div>
    </div>
  );
}

export default UserProfilePageComponent;
