import { PrintEditionConstants } from './printEditions.constants';
import {
  PrintEditionInterface,
  PrintEditionsBiltenIncludedResponse,
  ReducerActionInterface,
} from '../../interfaces';

// ============ INTERFACE ============

export interface PrintEditionStateInterface {
  currentPrintEdition?: PrintEditionInterface;
  printEditions?: PrintEditionsBiltenIncludedResponse;
  error?: string;
}

// ============ INIT STATE ============

const initialState: PrintEditionStateInterface = {
  currentPrintEdition: undefined,
};

// ============ REDUCERS ============


const getAllSuccess = (state: PrintEditionStateInterface, payload: PrintEditionsBiltenIncludedResponse): PrintEditionStateInterface => {
  return {
    ...state,
    printEditions: {
      ...payload
    },
  };
};


const error = (state: PrintEditionStateInterface, payload: string) => {
  return {
    ...state,
    error: payload,
  };
};

// ============ EXPORTS ============

export const PrintEditionReducer = (state = initialState, action: ReducerActionInterface) => {
  switch (action.type) {
    case PrintEditionConstants.GET_ALL_SUCCESS:
      return getAllSuccess(state, action.payload);
    case PrintEditionConstants.ERROR:
      return error(state, action.payload);
    default:
      return state;
  }
};
