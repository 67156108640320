import { AllegationConstants } from './allegation.constants';
import { AllegationInterface, AllegationResponseInterfaceAll, AllegationResponseInterfaceSingle, ReducerActionInterface } from '../../interfaces';

// ============ INTERFACE ============

export interface AllegationStateInterface {
  revision: number;
  currentAllegation?: AllegationInterface;
  allegations?: AllegationResponseInterfaceAll;
  error?: string;
  isLoading?: boolean;
}

// ============ INIT STATE ============

const initialState: AllegationStateInterface = {
  revision: 0,
  currentAllegation: undefined,
  allegations: {
    data: [],
    meta: {
      totalResourceCount: 0
    }
  }
}

// ============ REDUCERS ============

const setLoader = (state: AllegationStateInterface, payload: boolean): AllegationStateInterface => {
  return {
    ...state,
    isLoading: payload,
  };
};

const getSingleSuccess = (state: AllegationStateInterface, payload: AllegationResponseInterfaceSingle): AllegationStateInterface => {
  return {
    ...state,
    isLoading: false,
    currentAllegation: payload.data,
  };
};

const getAllSuccess = (state: AllegationStateInterface, payload: AllegationResponseInterfaceAll): AllegationStateInterface => {
  return {
    ...state,
    isLoading: false,
    allegations: {
      data: payload.data,
      meta: payload.meta
    }
  };
};

const createSuccess = (state: AllegationStateInterface, payload: AllegationResponseInterfaceSingle): AllegationStateInterface => {
  return {
    ...state,
    revision: state.revision + 1,
    isLoading: false,
    currentAllegation: payload.data,
  };
};

const updateSuccess = (state: AllegationStateInterface, payload: AllegationResponseInterfaceSingle): AllegationStateInterface => {
  return {
    ...state,
    revision: state.revision + 1,
    isLoading: false,
    currentAllegation: payload.data,
  };
};

const clearCurrent = (state: AllegationStateInterface): AllegationStateInterface => {
  return {
    ...state,
    revision: state.revision + 1,
    isLoading: false,
    currentAllegation: undefined,
  };
};

const clearList = (state: AllegationStateInterface): AllegationStateInterface => {
  return {
    ...state,
    revision: state.revision + 1,
    isLoading: false,
    allegations: {
      data: [],
      meta: {
        totalResourceCount: 0
      }
    }
  };
};

const deleteSuccess = (state: AllegationStateInterface): AllegationStateInterface => {
  return {
    ...state,
    revision: state.revision + 1,
    isLoading: false,
    currentAllegation: undefined,
  };
};

const error = (state: AllegationStateInterface, payload: string) => {
  return {
    ...state,
    revision: state.revision + 1,
    isLoading: false,
    error: payload,
  };
};

// ============ EXPORTS ============

export const AllegationReducer = (state = initialState, action: ReducerActionInterface) => {
  switch (action.type) {
    case AllegationConstants.SET_LOADER:
      return setLoader(state, action.payload);
    case AllegationConstants.GET_SINGLE_SUCCESS:
      return getSingleSuccess(state, action.payload);
    case AllegationConstants.GET_ALL_SUCCESS:
      return getAllSuccess(state, action.payload);
    case AllegationConstants.CREATE_SUCCESS:
      return createSuccess(state, action.payload);
    case AllegationConstants.UPDATE_SUCCESS:
      return updateSuccess(state, action.payload);
    case AllegationConstants.CLEAR_CURRENT:
      return clearCurrent(state);
    case AllegationConstants.CLEAR_LIST:
      return clearList(state);
    case AllegationConstants.DELETE:
      return deleteSuccess(state);
    case AllegationConstants.ERROR:
      return error(state, action.payload);
    default:
      return state;
  }
};
