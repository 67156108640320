import { Dispatch } from 'redux';
import { notification } from 'antd';
import { SynopsisConstants } from './synopsis.constants';

import { SynopsisService } from '../../api/api.service';
import { SynopsisResponseInterfaceAll, SynopsisResponseInterfaceSingle } from '../../interfaces';
import { i18n } from '../../services';

export function getAllSynopsissAction(params?: any, filter?: any, sort?: any, pagination?: any) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: SynopsisConstants.SET_LOADER,
      payload: true
    });

    dispatch({
      type: SynopsisConstants.GET_ALL_SUCCESS,
      payload: {
        data: [],
        meta: {}
      },
    });

    return SynopsisService.getAll(params, filter, sort, pagination).subscribe(
      (response: SynopsisResponseInterfaceAll) => {
        dispatch({
          type: SynopsisConstants.GET_ALL_SUCCESS,
          payload: response,
        });
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });

        dispatch({
          type: SynopsisConstants.ERROR,
          payload: error,
        });
      }
    );
  };
}

export function getSingleSynopsisAction(id: number) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: SynopsisConstants.SET_LOADER,
      payload: true
    });

    return SynopsisService.getSingle(id).subscribe(
      (response: SynopsisResponseInterfaceSingle) => {
        dispatch({
          type: SynopsisConstants.GET_SINGLE_SUCCESS,
          payload: response,
        });
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });

        dispatch({
          type: SynopsisConstants.ERROR,
          payload: error,
        });
      }
    );
  };
}

export function createSynopsisAction(body: SynopsisResponseInterfaceSingle, showNotif: boolean = true) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: SynopsisConstants.SET_LOADER,
      payload: true
    });

    return SynopsisService.create(body).subscribe(
      (response: SynopsisResponseInterfaceSingle) => {
        if (showNotif) notification['success']({ message: i18n.translate('api.successMessage'), duration: 2 });

        dispatch({
          type: SynopsisConstants.CREATE_SUCCESS,
          payload: response,
        });
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });

        dispatch({
          type: SynopsisConstants.ERROR,
          payload: error,
        });
      }
    );
  };
}

export function updateSynopsisAction(body: SynopsisResponseInterfaceSingle, showNotif: boolean = true) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: SynopsisConstants.SET_LOADER,
      payload: true
    });

    return SynopsisService.update(body).subscribe(
      (response: SynopsisResponseInterfaceSingle) => {
        if (showNotif) notification['success']({ message: i18n.translate('api.successMessage'), duration: 2 });

        dispatch({
          type: SynopsisConstants.UPDATE_SUCCESS,
          payload: response,
        });
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });

        dispatch({
          type: SynopsisConstants.ERROR,
          payload: error,
        });
      }
    );
  };
}

export function deleteSynopsisAction(id: number) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: SynopsisConstants.SET_LOADER,
      payload: true
    });
    
    return SynopsisService.delete(id).subscribe(
      (response: any) => {
        dispatch({
          type: SynopsisConstants.DELETE
        });
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });

        dispatch({
          type: SynopsisConstants.ERROR,
          payload: error,
        });
      }
    );
  };
}

export function clearSynopsisAction() {
  return (dispatch: Dispatch) => {
    dispatch({ type: SynopsisConstants.CLEAR_CURRENT });
  };
}

export function clearSynopsisListAction() {
  return (dispatch: Dispatch) => {
    dispatch({ type: SynopsisConstants.CLEAR_LIST });
  };
}
