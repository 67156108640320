import {
  CommentOutlined,
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  ExclamationCircleOutlined
} from '@ant-design/icons';
import {
  Breadcrumb,
  Button,
  Col,
  Dropdown,
  Form,
  Input,
  Menu,
  Modal,
  notification,
  Row,
  Space,
  Typography
} from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import {
  clearNoteAction,
  clearNoteLinkAction,
  createNoteAction,
  deleteNoteAction,
  deleteNoteLinkAction,
  getAllNoteLinksAction,
  getAllNotesAction,
  getSingleNoteAction,
  i18n,
  NavigationService,
  NoteInterface,
  NoteLinkInterface,
  NoteLinkModal,
  NoteLinkResponseInterfaceSingle,
  NoteLinkService,
  NoteStateInterface,
  StoreStateInterface,
  updateNoteAction
} from '../../../../../common';
import printService from '../../../../../common/services/print.service';
import { AdminRoutes } from '../../../_router/admin.routes';

const { confirm } = Modal;

function NotesCrudPageComponent() {
  const { id } = useParams<{ id: string }>();
  const userAuth = useSelector((state: StoreStateInterface) => state.auth);

  const isNew = isNaN(parseInt(id));

  const dispatch = useDispatch();
  const noteData: NoteStateInterface = useSelector((state: StoreStateInterface) => state.notes);

  const { Text } = Typography;
  const { TextArea } = Input;

  const noteLinkData = useSelector((state: StoreStateInterface) => state.notesLinks);

  const [visible, setVisible] = useState(false);
  const [isNewLink, setisNewLink] = useState(false);

  const [selectedLinkId, setSelectedLinkId] = useState(0);
  const [selectedLink, setSelectedLink] = useState<NoteLinkResponseInterfaceSingle>();

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
    dispatch(clearNoteLinkAction());
  };

  const handleCancel = () => {
    setVisible(false);
    dispatch(clearNoteLinkAction());
  };

  const handleChange = (value: string) => {};

  useEffect(() => {
    dispatch(clearNoteAction());
    dispatch(getAllNoteLinksAction(parseInt(id)));
    if (!isNew) dispatch(getSingleNoteAction(parseInt(id)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refresh = () => {
    clearNoteAction();
    dispatch(getAllNotesAction(userAuth.user?.id));
  };

  useEffect(() => {
    clearNoteLinkAction();
    if (selectedLinkId != 0) {
      NoteLinkService.getSingle(selectedLinkId).subscribe(
        (response: NoteLinkResponseInterfaceSingle) => {
          setSelectedLink(response);
        },
        (error: Error) => {
          notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
        }
      );
    }
  }, [selectedLinkId]);

  const onFinishNoteFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const clearCurrentNote = () => {
    dispatch(clearNoteAction());
  };

  const showConfirm = () => {
    confirm({
      title: i18n.t('notes.confirmDelete'),
      icon: <ExclamationCircleOutlined />,
      content: i18n.t('common.cantUndo'),
      okText: i18n.t('genericButtons.yes'),
      cancelText: i18n.t('genericButtons.cancel'),
      onOk() {
        for (let i = 0; i <= (noteLinkData?.notesLinks?.data?.length as number); i++) {
          noteLinkData.notesLinks?.data.forEach((link) => {
            dispatch(deleteNoteLinkAction(link?.id as number));
          });
        }

        notification['success']({ message: i18n.translate('api.successDelete'), duration: 2 });
        window.scrollTo({ top: 0, behavior: 'smooth' });
        dispatch(deleteNoteAction(parseInt(id)));

        setTimeout(() => {
          NavigationService.navigate(AdminRoutes.NOTES.fullPath);
        }, 1000);
      },
    });
  };

  const showConfirmLink = (linkId: number) => {
    confirm({
      title: i18n.t('notes.confirmDeleteLink'),
      icon: <ExclamationCircleOutlined />,
      content: i18n.t('common.cantUndo'),
      okText: i18n.t('genericButtons.yes'),
      cancelText: i18n.t('genericButtons.cancel'),
      onOk() {
        dispatch(deleteNoteLinkAction(linkId));

        window.scrollTo({ top: 0, behavior: 'smooth' });

        setTimeout(() => {
          dispatch(getAllNoteLinksAction(parseInt(id)));
        }, 1000);
      },
    });
  };

  const showConfirmExternal = () => {
    confirm({
      title: i18n.t('notes.confirmDeleteExternal'),
      icon: <ExclamationCircleOutlined />,
      content: i18n.t('common.cantUndo'),
      okText: i18n.t('genericButtons.yes'),
      cancelText: i18n.t('genericButtons.cancel'),
      onOk() {
        let finishNote: any = {
          data: {
            type: 'notes',
            id: noteData.currentNote?.id,
            attributes: {
              title: noteData.currentNote?.attributes?.title,
              text: noteData.currentNote?.attributes?.text,
              type: 'note',
              typeId: null,
              breadcrumb: '--',
              number: noteData.currentNote?.attributes?.number,
              isEditable: noteData.currentNote?.attributes?.isEditable,
              active: noteData.currentNote?.attributes?.active,
              notesObject: null,
            },
            relationships: {},
          },
        };

        dispatch(updateNoteAction(finishNote));
      },
    });
  };

  const loadInitialValues = () => {
    let values: any = {
      title: noteData?.currentNote?.attributes?.title,
      content: noteData?.currentNote?.attributes?.text,
      createdDate: moment(noteData?.currentNote?.attributes?.createdDate),
      external: noteLinkData?.notesLinks?.data[0]?.attributes?.link,
    };

    return values;
  };

  const menu = (
    <Menu
      items={[
        {
          label: (
            <Button
              onClick={() => printService.printNoteToPdf(noteData?.currentNote) }
              className="cardBtn"
              type="link"
            >
              {i18n.translate(`notes.actions.download`)}
            </Button>
          ),
          key: '0',
        },
        {
          label: (
            <Button
              danger
              type="link"
              onClick={() => {
                showConfirm();
              }}
            >
              {i18n.translate(`notes.actions.delete`)}
            </Button>
          ),
          key: '1',
        },
      ]}
    />
  );

  const onFinishNote = (values: any) => {
    let finishNote: any = {
      data: {
        type: 'notes',
        id: noteData.currentNote?.id,
        attributes: {
          title: values.title,
          text: values.content,
          type: noteData.currentNote?.attributes?.type,
          typeId: noteData.currentNote?.attributes?.typeId,
          breadcrumb: noteData.currentNote?.attributes?.breadcrumb,
          number: noteData.currentNote?.attributes?.number,
          isEditable: noteData.currentNote?.attributes?.isEditable,
          active: noteData.currentNote?.attributes?.active,
        },
        relationships: {},
      },
    };

    isNew ? dispatch(createNoteAction(finishNote)) : dispatch(updateNoteAction(finishNote));

    setTimeout(() => {
      NavigationService.navigate(AdminRoutes.NOTES.fullPath + `/${id}`);
    }, 1000);
  };

  return (
    <div className="notesCrud w100-h100">
      {noteData?.currentNote?.attributes ? (
        <>
          <div className="note___breadcrumbs">
            <Breadcrumb>
              <Breadcrumb.Item>
                <CommentOutlined />
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <span>{i18n.translate('breadcrumbs.notes')}</span>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <span>{noteData?.currentNote?.attributes?.title}</span>
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>

          <Form
            layout="vertical"
            name="note"
            size="large"
            scrollToFirstError
            initialValues={noteData.currentNote && !isNew ? loadInitialValues() : { visibility: 'all' }}
            onFinish={onFinishNote}
            onFinishFailed={onFinishNoteFailed}
            autoComplete="off"
            requiredMark={false}
          >
            <Row justify="space-between" align="middle">
              <Col>
                <div className="note__title">{noteData?.currentNote?.attributes?.title}</div>
              </Col>
              <Col>
                <Space>
                  <Dropdown overlay={menu} trigger={['click']}>
                    <Button className="button" size="large" style={{ fontSize: 16, fontWeight: 700, borderRadius: 0 }}>
                      <Space>
                        {i18n.translate(`notes.noteView.actions`)}
                        <DownOutlined />
                      </Space>
                    </Button>
                  </Dropdown>
                  <Link to={AdminRoutes.NOTES.fullPath + `/${id}`} key={AdminRoutes.NOTES.fullPath + `/${id}`}>
                    <Button
                      onClick={clearCurrentNote}
                      className="button"
                      size="large"
                      style={{ fontSize: 16, fontWeight: 700, borderRadius: 0 }}
                    >
                      {i18n.translate('genericButtons.cancel')}
                    </Button>
                  </Link>
                  <Button
                    className="button"
                    type="primary"
                    size="large"
                    htmlType="submit"
                    style={{ fontSize: 16, fontWeight: 700, borderRadius: 0 }}
                  >
                    {i18n.translate(`notes.noteView.save`)}
                  </Button>
                </Space>
              </Col>
            </Row>

            <Form.Item label={<Text strong>{i18n.translate(`notes.noteView.name`)}</Text>} name="title">
              <Input placeholder={i18n.translate(`notes.noteView.name`)} />
            </Form.Item>

            <Form.Item>
              <Space direction="vertical">
                <Text strong>{i18n.translate(`notes.noteView.linkedContent`)}</Text>
                <Text type="secondary">{noteData?.currentNote?.attributes?.breadcrumb?.toUpperCase()}</Text>
                {noteData?.currentNote?.attributes?.notesObject?.title}
              </Space>
              {noteData?.currentNote?.attributes?.notesObject !== null && (
                <Button
                  className="cardBtn"
                  type="link"
                  icon={<DeleteOutlined />}
                  onClick={() => {
                    showConfirmExternal();
                  }}
                />
              )}
            </Form.Item>

            {(noteLinkData?.notesLinks?.data?.length as number) > 0 ? (
              <>
                <Form.Item
                  label={<Text strong>{i18n.translate(`notes.noteView.externalContent`)}</Text>}
                  name="external"
                  rules={[
                    {
                      type: 'url',
                      message: i18n.t(`common.validUrl`),
                    },
                  ]}
                >
                  <Col span={24}>
                    <Space direction="vertical" size="large">
                      {noteLinkData?.notesLinks?.data?.map((noteLink: NoteLinkInterface) => (
                        <Space direction='horizontal'>
                          <Button
                            style={{ whiteSpace: 'pre-wrap', height: 'auto', textAlign: 'left', overflowWrap: 'anywhere' }}
                            onClick={() => {
                              window.open(noteLink.attributes?.link, '_blank');
                            }}
                          >
                            {noteLink?.attributes?.link}
                          </Button>

                          <Button
                            className="cardBtn"
                            type="link"
                            icon={<EditOutlined />}
                            onClick={() => {
                              setSelectedLinkId(noteLink?.id as number);
                              setisNewLink(false);
                              showModal();
                            }}
                          />
                          <Button
                            className="cardBtn"
                            type="link"
                            icon={<DeleteOutlined />}
                            onClick={() => {
                              showConfirmLink(noteLink.id as number);
                            }}
                          />
                        </Space>
                      ))}
                      <Form.Item>
                        <Space direction="horizontal" size="large">
                          <Button
                            className="cardBtn"
                            type="link"
                            onClick={() => {
                              setisNewLink(true);
                              showModal();
                            }}
                          >
                            {i18n.translate(`notes.noteView.addLink`)}
                          </Button>
                        </Space>
                      </Form.Item>
                    </Space>
                  </Col>
                </Form.Item>
              </>
            ) : (
              <>
                <Form.Item label={<Text strong>{i18n.translate(`notes.noteView.externalContent`)}</Text>}>
                  <Space direction="horizontal" size="large">
                    <Button
                      className="cardBtn"
                      type="link"
                      onClick={() => {
                        setisNewLink(true);
                        showModal();
                      }}
                    >
                      {i18n.translate(`notes.noteView.addLink`)}
                    </Button>
                  </Space>
                </Form.Item>
              </>
            )}

            <Form.Item label={<Text strong>{i18n.translate(`notes.noteView.note`)}</Text>} name="content">
              <TextArea showCount placeholder={i18n.translate(`notes.noteView.note`)} rows={25} />
            </Form.Item>
          </Form>

          <NoteLinkModal
            isNew={isNewLink}
            visible={visible}
            handleOk={handleOk}
            handleCancel={handleCancel}
            handleChange={handleChange}
            noteLink={selectedLink?.data as NoteLinkInterface}
            note={noteData?.currentNote as NoteInterface}
          />
        </>
      ) : null}
    </div>
  );
}

export default NotesCrudPageComponent;
