import React, { useEffect, useState } from 'react';
import { clearRoadmapAction, clearRoadmapListAction, FilterComponent, getAllRoadmapsAction, getScreenTagsByTypeAction, i18n, NoDataComponent, RoadmapEsResponseInterface, RoadmapInterface, RoadmapService, RoadmapStateInterface, StoreStateInterface, TagScreenStateInterface, updateRoadmapAction, YearDividerComponent } from '../../../common';
import { HomeOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Breadcrumb, Button, Modal, notification } from 'antd';
import WorkflowCardComponent from '../../../common/components/cards/workflowCards/workflowCard.component';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { AdminRoutes } from '../_router/admin.routes';

const { confirm } = Modal;

function WorkflowsPageComponent() {
  const dispatch = useDispatch();
  const data: RoadmapStateInterface = useSelector((state: StoreStateInterface) => state.roadmap);
  const tags: TagScreenStateInterface = useSelector((state: StoreStateInterface) => state.tagScreen);
  
  const [filterValues, setFilterValues] = useState(undefined);

  const [esData, setEsData] = useState<RoadmapEsResponseInterface>();

  const [keyword, setKeyword] = useState('');
  //const [allOpinions, setAllOpinions] = useState(true);
  const [exactMatch, setExactMatch] = useState<boolean>(true);

  const [esSearch, setEsSearch] = useState(true);

  useEffect(() => {
    localStorage.removeItem('transferFilters');
    dispatch(clearRoadmapAction());
    dispatch(clearRoadmapListAction());
    dispatch(getScreenTagsByTypeAction('roadmap'));
    //dispatch(getAllRoadmapsAction());
    esSearchFunction(false, {from: 0, size: 9999});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const esSearchFunction = (allOpinions: boolean, pageSorted: {from: number, size: number}, keyword?: string, tagIds?: any) => {
    RoadmapService.elasticSearch(allOpinions, pageSorted, exactMatch, keyword, tagIds).subscribe(
      (response: RoadmapEsResponseInterface) => {
        setEsData(response);
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
      }
    );
  };

  const refetch = (allValues: any) => {
    //setEsSearch(true);
    setFilterValues(allValues);
    esSearchFunction(false, {from: 0, size: 9999}, keyword, allValues);
  };

  const resetSearch = () => {
    setEsData(undefined);
    setKeyword('');
    //setAllOpinions(true);
    //setEsSearch(false);
    //dispatch(getAllRoadmapsAction());
    esSearchFunction(false, {from: 0, size: 9999});
  };

  const keywordChanged = (value: string) => {
    setKeyword(value);
  };

  const exactMatchChanged = (exactMatch: boolean) => {
    setExactMatch(exactMatch);
  };

  const convertDataType = (roadmaps: RoadmapEsResponseInterface["hits"]): RoadmapInterface[] => {
    return roadmaps.map((roadmap) => {
      return {
        id: roadmap.id,
        type: roadmap.index,
        attributes: roadmap.source
      };
    })
  };

  const onPositionChangeRefresh = (workflow: RoadmapInterface, up: boolean) => {
    confirm({
      title: i18n.t('workflows.confirmPositionChange'),
      icon: <ExclamationCircleOutlined />,
      okText: i18n.t('genericButtons.yes'),
      cancelText: i18n.t('genericButtons.cancel'),
      onOk() {
        const workflowData: any = {
          data: {
            type: 'roadmap',
            id: workflow?.id,
            attributes: {
              position: workflow?.attributes?.position
            }
          }
        };
    
        let roadmapOnOldPosition = data.roadmaps?.data?.find(roadmap => (roadmap?.attributes?.position == workflow?.attributes?.position && roadmap.id != workflow.id));
        if (roadmapOnOldPosition) {
          roadmapOnOldPosition.attributes.position = up ? roadmapOnOldPosition?.attributes?.position + 1 : roadmapOnOldPosition?.attributes?.position - 1;
    
          const roadmapOnOldPositionData: any = {
            data: {
              type: 'roadmap',
              id: roadmapOnOldPosition?.id,
              attributes: {
                position: roadmapOnOldPosition?.attributes?.position
              }
            }
          };
    
          dispatch(updateRoadmapAction(roadmapOnOldPositionData));
        }
        dispatch(updateRoadmapAction(workflowData));
        dispatch(clearRoadmapListAction());
        esSearch ? esSearchFunction(false, {from: 0, size: 9999}, keyword, filterValues) : dispatch(getAllRoadmapsAction(filterValues));
      }
    });
  };
  
  return (
    <div className="workflows w100-h100">
      <div className="workflows__breadcrumbs">
        <Breadcrumb>
          <Breadcrumb.Item>
            <HomeOutlined />
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span>{i18n.translate('breadcrumbs.web-of-knowledge')}</span>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span>{i18n.translate('menu.workflows')}</span>
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="workflows__admin">
        <div className='title'>{i18n.translate('workflows.title')}</div>
        <Link to={AdminRoutes.WORKFLOWS.fullPath + `/new`} key={AdminRoutes.WORKFLOWS.path + `/new`}>
          <Button className='button' type="primary" size="large" style={{fontSize: 16, fontWeight: 700, borderRadius: 0}}>
            {i18n.translate('workflows.addButton')}
          </Button>
        </Link>
      </div>
      {tags.tagScreens && tags.tagScreens.length ? <FilterComponent aggregation={esData?.aggregation?.buckets} filterAccess onExactMatchChange={exactMatchChanged} keyword={keyword} filters={tags.tagScreens} onFilterChange={refetch} onFilterRemove={resetSearch} onKeywordChange={keywordChanged} /> : null}
      <YearDividerComponent title={' '} />
      {esSearch &&
        <>
        {esData?.hits?.length ? [...(convertDataType(esData?.hits as RoadmapEsResponseInterface["hits"])) as RoadmapInterface[]].map((roadmap, i) => (
          <WorkflowCardComponent key={'roadmap' + i} workflow={roadmap} maxLength={esData?.totalHits as number} onPositionChange={onPositionChangeRefresh}/>
        )) : <NoDataComponent />}
        </>
      }
      {!esSearch &&
        <>
          {data.roadmaps?.data?.length ? [...data?.roadmaps?.data as RoadmapInterface[]].map((roadmap, i) => (
            <WorkflowCardComponent key={'roadmap' + i} workflow={roadmap} maxLength={data.roadmaps?.meta?.totalResourceCount as number} onPositionChange={onPositionChangeRefresh}/>
          )) : <NoDataComponent />}
        </>
      }
    </div>
  );
}

export default WorkflowsPageComponent;