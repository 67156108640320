import { FaqConstants } from './faq.constants';
import {
  FaqInterface,
  FaqResponseInterfaceAll,
  FaqResponseInterfaceSingle,
  FaqSingleInterface,
  FaqSingleInterfaceResponse,
  ReducerActionInterface,
} from '../../interfaces';

// ============ INTERFACE ============

export interface FaqStateInterface {
  currentFaq?: FaqInterface;
  included?: FaqSingleInterfaceResponse['data'][];
  one?: FaqSingleInterfaceResponse;
  faqs?: FaqResponseInterfaceAll;
  error?: string;
}

// ============ INIT STATE ============

const initialState: FaqStateInterface = {
  currentFaq: undefined,
  included: [],
  faqs: {
    data: [],
    meta: {
      totalResourceCount: 0,
    },
  },
};

// ============ REDUCERS ============

const getSingleSuccess = (state: FaqStateInterface, payload: FaqResponseInterfaceSingle): FaqStateInterface => {
  return {
    ...state,
    currentFaq: payload.data,
    included: payload.included
  };
};

const getOneSuccess = (state: FaqStateInterface, payload: FaqSingleInterfaceResponse): FaqStateInterface => {
  return {
    ...state,
    one: payload,
  };
};

const getAllSuccess = (state: FaqStateInterface, payload: FaqResponseInterfaceAll): FaqStateInterface => {
  return {
    ...state,
    faqs: {
      data: payload.data,
      meta: payload.meta,
    },
  };
};

const createSuccess = (state: FaqStateInterface, payload: FaqResponseInterfaceSingle): FaqStateInterface => {
  return {
    ...state,
    currentFaq: payload.data,
  };
};

const updateSuccess = (state: FaqStateInterface, payload: FaqResponseInterfaceSingle): FaqStateInterface => {
  return {
    ...state,
    currentFaq: payload.data,
  };
};

const deleteSuccess = (state: FaqStateInterface): FaqStateInterface => {
  return {
    ...state,
    currentFaq: undefined,
  };
};

const clearCurrent = (state: FaqStateInterface): FaqStateInterface => {
  return {
    ...state,
    currentFaq: undefined,
    included: []
  };
};

const clearList = (state: FaqStateInterface): FaqStateInterface => {
  return {
    ...state,
    faqs: {
      data: [],
      meta: {
        totalResourceCount: 0,
      },
    },
  };
};

const error = (state: FaqStateInterface, payload: string) => {
  return {
    ...state,
    error: payload,
  };
};

// ============ EXPORTS ============

export const FaqReducer = (state = initialState, action: ReducerActionInterface) => {
  switch (action.type) {
    case FaqConstants.GET_SINGLE_SUCCESS:
      return getSingleSuccess(state, action.payload);
    case FaqConstants.GET_ONE_SUCCESS:
      return getOneSuccess(state, action.payload);
    case FaqConstants.GET_ALL_SUCCESS:
      return getAllSuccess(state, action.payload);
    case FaqConstants.CREATE_SUCCESS:
      return createSuccess(state, action.payload);
    case FaqConstants.UPDATE_SUCCESS:
      return updateSuccess(state, action.payload);
    case FaqConstants.DELETE:
      return deleteSuccess(state);
    case FaqConstants.CLEAR_CURRENT:
      return clearCurrent(state);
    case FaqConstants.CLEAR_LIST:
      return clearList(state);
    case FaqConstants.ERROR:
      return error(state, action.payload);
    default:
      return state;
  }
};
