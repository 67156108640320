import Icon, { FilePdfOutlined, LinkOutlined } from '@ant-design/icons';
import { Button, Drawer, Popover } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { BookmarkOutlined } from '../../../assets/icons/icons';
import { FilterNameEnumHR, UserRoleEnum } from '../../enums';
import { LicenceEnum } from '../../enums/licence.enum';
import { DecisionInterface, LicenceInterface, NoteResponseInterfaceAll, TagScreenResponseInterface, VerdictInterface } from '../../interfaces';
import { StoreStateInterface } from '../../redux';
import { i18n, ViewerService } from '../../services';
import NotePickModal from '../modals/notes/notePick/notePickModal.component';
import { JavaFormat } from '../../constants';
import { UserActivityLogService } from '../../api';
import { UserActionTypeEnum } from '../../enums/userActionType.enum';

interface Props {
  onDrawerClose: () => void;
  drawerOpen?: boolean;
  drawerTitle?: string;
  decision?: DecisionInterface['attributes'];
  verdict?: VerdictInterface['attributes'];
  data?: any;
  tags?: Array<TagScreenResponseInterface>;
  licenceData?: LicenceInterface;
  notes?: NoteResponseInterfaceAll;
  validLicences?: LicenceInterface[];
}

export default function RhPracticeDetailsDrawer({ onDrawerClose, drawerOpen, drawerTitle, decision, verdict, data, licenceData, notes, validLicences }: Props) {
  const userAuth = useSelector((state: StoreStateInterface) => state.auth);
  const tags: Array<TagScreenResponseInterface> | undefined = useSelector((state: StoreStateInterface) => state.tagScreen.tagScreens);

  const [visible, setVisible] = useState(false);

  const availableNotes = licenceData?.attributes?.notesNum as number;

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    if (!((notes?.data.length as number) == availableNotes)) {
      setVisible(true);
    }
    setVisible(false)
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const getType = (record: any) => {
    let typeName: Array<string> = [];
    const parentTag = tags?.find((tag) => tag.filter && tag.filter.name == FilterNameEnumHR.PURCHASE_TYPE);
    if (parentTag && parentTag.children?.length) {
      parentTag.children?.forEach((child) => {
        const id = record?.tagIds?.find((id: number | undefined) => id == child.tag.id);
        if (id) typeName.push(child.tag.name);
      });
    }
    return typeName.join(', ');
  };

  const getDkomRatingFilterTrue = (data: any) => {
    let typeName: Array<string> = [];
    const parentTag = tags?.find((tag) => tag.filter && (tag.filter.name == 'Ocjena DKOM-a' || tag.filter.name == 'Ocjena VUS-a'));
    if (parentTag && parentTag?.children?.length) {
      parentTag.children?.forEach((child) => {
        const id = data?.attributes?.tagIds?.find((id: number | undefined) => id == child?.tag?.id);
        if (id) typeName.push(child?.tag?.name);
      });
    }
    return typeName.join(', ');
  };

  const checkDecisionLicenceYear = () => {
    const decisionYear = getDecisionYear();

    if (userAuth.user?.roles === UserRoleEnum.ADMIN) return true;

    if (validLicences?.length && validLicences?.some(x => x.attributes?.licenceType === LicenceEnum.PREMIUM)) {
      let access = false;

      validLicences.filter(x => x.attributes?.licenceType === LicenceEnum.PREMIUM).forEach(licenceData => {
        if (decisionYear == licenceData.attributes?.year) {
          access = true;
        }
        if (licenceData.attributes?.childrenLicences?.length) {
          if (licenceData.attributes?.childrenLicences?.find((x) => x.active && x.year == decisionYear)) {
            access = true;
          }
        }
      });

      return access;
    } else {
      return false;
    }
  };

  const getDecisionYear = () => {
    let yearString = i18n.translate('common.unsorted');

    if (decision?.appealInvestmentDate) yearString = moment(decision?.appealInvestmentDate, 'DD.MM.YYYY.').format('YYYY');

    return yearString;
  };

  const checkVerdictLicenceYear = () => {
    const verdictYear = getVerdictYear();

    if (userAuth.user?.roles === UserRoleEnum.ADMIN) return true;

    if (validLicences?.length && validLicences?.some(x => x.attributes?.licenceType === LicenceEnum.PREMIUM)) {
      let access = false;

      validLicences.filter(x => x.attributes?.licenceType === LicenceEnum.PREMIUM).forEach(licenceData => {
        if (verdictYear == licenceData.attributes?.year) {
          access = true;
        }
        if (licenceData.attributes?.childrenLicences?.length) {
          if (licenceData.attributes?.childrenLicences?.find((x) => x.active && x.year == verdictYear)) {
            access = true;
          }
        }
      });

      return access;
    } else {
      return false;
    }
  };

  const getVerdictYear = () => {
    let yearString = i18n.translate('common.unsorted');

    if (verdict?.verdictDate) yearString = moment(verdict?.verdictDate, 'DD.MM.YYYY.').format('YYYY');

    return yearString;
  };

  const decisionData: Array<any> = [
    {
      title: i18n.translate('dkom.table.editedDec'),
      dataIndex: '',
      render: (text: any, record: any, index: any) => (
        <>
          {record.decisionPdfUuid ? (
            !checkDecisionLicenceYear() ? (
              <Popover
                placement="topRight"
                content={i18n.translate(`common.licence.content`)}
                title={i18n.translate(`common.licence.title`)}
              >
                <Button disabled type="link" className="cardBtn" icon={<FilePdfOutlined />} />
              </Popover>
            ) : (
              <Button onClick={() => onFileView(record.decisionPdfUuid, {type: 'decision', documentType: 'BJN uređena odluka', name: record.clazz, subject: record.subject})} type="link" icon={<FilePdfOutlined />} />
            )
          ) : (
            <Popover
              placement="topRight"
              content={i18n.translate(`common.licence.content`)}
              title={i18n.translate(`common.licence.title`)}
            >
              <Button disabled type="link" className="cardBtn" icon={<FilePdfOutlined />} />
            </Popover>
          )}
        </>
      )
    },
    {
      title: i18n.translate('dkom.table.decision'),
      dataIndex: '',
      render: (text: any, record: any, index: any) => (
        <>
          {record.dkomDecisionPdfUuid ? (
            !checkDecisionLicenceYear() ? (
              <Popover
                placement="topRight"
                content={i18n.translate(`common.licence.content`)}
                title={i18n.translate(`common.licence.title`)}
              >
                <Button disabled type="link" className="cardBtn" icon={<FilePdfOutlined />} />
              </Popover>
            ) : (
              <Button onClick={() => onFileView(record.dkomDecisionPdfUuid, {type: 'decision', documentType: 'DKOM originalna odluka', name: record.clazz, subject: record.subject})} type="link" icon={<FilePdfOutlined />} />
            )
          ) : (
            <Popover
              placement="topRight"
              content={i18n.translate(`common.licence.content`)}
              title={i18n.translate(`common.licence.title`)}
            >
              <Button type="link" className="cardBtn"  icon={<FilePdfOutlined />} />
            </Popover>
          )}
          {record.dkomDecisionLink ? (
            !checkDecisionLicenceYear() ? (
              <Popover
                placement="topRight"
                content={i18n.translate(`common.licence.content`)}
                title={i18n.translate(`common.licence.title`)}
              >
                <Button disabled type="link" icon={<LinkOutlined />} />
              </Popover>
            ) : (
              <a target="_blank" href={record.dkomDecisionLink}> <Button type="link" icon={<LinkOutlined />} /> </a>
            )
          ) : (
            <Popover
              placement="topRight"
              content={i18n.translate(`common.licence.content`)}
              title={i18n.translate(`common.licence.title`)}
            >
              <Button disabled type="link" icon={<LinkOutlined />} />
            </Popover>
          )}
        </>
      )
    },
    {
      title: i18n.translate('dkom.table.pmPdf'),
      dataIndex: '',
      render: (text: any, record: any, index: any) => (
        <>
          {record.pmPdfUuid ? (
            !checkDecisionLicenceYear() ? (
              <Popover
                placement="topRight"
                content={i18n.translate(`common.licence.content`)}
                title={i18n.translate(`common.licence.title`)}
              >
                <Button disabled type="link" className="cardBtn" icon={<FilePdfOutlined />} />
              </Popover>
            ) : (
              <Button onClick={() => onFileView(record.pmPdfUuid,{type: 'decision', documentType: 'PM odluka', name: record.clazz, subject: record.subject})} type="link" icon={<FilePdfOutlined />} />
            )
          ) : (
            <Popover
              placement="topRight"
              content={i18n.translate(`common.licence.content`)}
              title={i18n.translate(`common.licence.title`)}
            >
              <Button disabled type="link" className="cardBtn" icon={<FilePdfOutlined />} />
            </Popover>
          )}
        </>
      )
    },
    {
      title: i18n.translate('dkom.table.zznpPdf'),
      dataIndex: '',
      render: (text: any, record: any, index: any) => (
        <>
          {record.zznpPdfUuid ? (
            !checkDecisionLicenceYear() ? (
              <Popover
                placement="topRight"
                content={i18n.translate(`common.licence.content`)}
                title={i18n.translate(`common.licence.title`)}
              >
                <Button disabled type="link" className="cardBtn" icon={<FilePdfOutlined />} />
              </Popover>
            ) : (
              <Button onClick={() => onFileView(record.zznpPdfUuid, {type: 'decision', documentType: 'ZZNP odluka', name: record.clazz, subject: record.subject})} type="link" icon={<FilePdfOutlined />} />
            )
          ) : (
            <Popover
              placement="topRight"
              content={i18n.translate(`common.licence.content`)}
              title={i18n.translate(`common.licence.title`)}
            >
              <Button disabled type="link" className="cardBtn" icon={<FilePdfOutlined />} />
            </Popover>
          )}
        </>
      )
    },
    {
      title: i18n.translate('dkom.table.class'),
      dataIndex: 'clazz',
    },
    {
      title: i18n.translate('dkom.table.client'),
      dataIndex: 'client',
    },
    {
      title: i18n.translate('dkom.table.appellant'),
      dataIndex: 'appellant',
    },
    {
      title: i18n.translate('dkom.table.topic'),
      dataIndex: 'subject',
    },
    {
      title: i18n.translate('dkom.table.type'),
      dataIndex: 'type',
      render: (text: any, record: any, index: any) => <span>{getType(record) ? getType(record) : '—'}</span>,
    },
    {
      title: i18n.translate('dkom.table.dateofapp'),
      render: (text: any, record: any, index: any) => (<span>{record.appealInvestmentDate ? moment(record.appealInvestmentDate, 'DD.MM.YYYY.').format('DD.MM.YYYY.') : '—'}</span>),
    },
    {
      title: i18n.translate('dkom.table.dateofdec'),
      render: (text: any, record: any, index: any) => (<span>{record.decisionDate ? moment(record.decisionDate, 'DD.MM.YYYY.').format('DD.MM.YYYY.') : '—'}</span>),
    },
    {
      title: i18n.translate('dkom.table.dateofpubl'),
      render: (text: any, record: any, index: any) => (<span>{record.decisionPublishDate ? moment(record.decisionPublishDate, 'DD.MM.YYYY.').format('DD.MM.YYYY.') : '—'}</span>),
    },
    {
      title: i18n.translate('dkom.table.waittime'),
      dataIndex: 'decisionWaitTime',
      render: (text: any, record: any, index: any) => <span>{record.decisionWaitTime > 0 ? `${record.decisionWaitTime} ${i18n.t('dkom.table.days')}` : `—`}</span>,
    },
    {
      title: i18n.translate('dkom.table.lawFirm'),
      dataIndex: 'lawFirm',
    },
    {
      title: i18n.translate('dkom.table.signatory'),
      dataIndex: 'decisionSignatory',
    },
    {
      title: i18n.translate('dkom.table.councilMembers'),
      dataIndex: 'councilMembers',
    },
    {
      title: i18n.translate('dkom.table.vusDecision'),
      dataIndex: 'verdict',
      key: 'verdict',
      render: (text: any, record: any, index: any) => (
        <span>{record.hasVerdict ? i18n.t('dkom.table.hasVerdict') : i18n.t('dkom.table.noVerdict')}</span>
      ),
    },
  ].filter(x => (x.dataIndex != 'lawFirm' && x.dataIndex != 'decisionSignatory' && x.dataIndex != 'councilMembers') || userAuth.user?.roles === UserRoleEnum.ADMIN);

  const verdictData: Array<any> = [
    {
      title: i18n.translate('vus.table.verdict'),
      dataIndex: '',
      render: (text: any, record: any, index: any) => (
        <>
          {record.vusVerdictPdfUuid ? (
            !checkVerdictLicenceYear() ? (
              <Popover
                placement="topRight"
                content={i18n.translate(`common.licence.content`)}
                title={i18n.translate(`common.licence.title`)}
              >
                <Button disabled type="link" className="cardBtn" icon={<FilePdfOutlined />} />
              </Popover>
            ) : (
              <Button onClick={() => onFileView(record.vusVerdictPdfUuid, {type: 'verdict', documentType: 'VUS originalna presuda', name: record.verdictNum})} type="link" icon={<FilePdfOutlined />} />
            )
          ) : (
            <Popover
              placement="topRight"
              content={i18n.translate(`common.licence.content`)}
              title={i18n.translate(`common.licence.title`)}
            >
              <Button disabled type="link" className="cardBtn" icon={<FilePdfOutlined />} />
            </Popover>
          )}
          {record.vusVerdictLink ? (
            !checkVerdictLicenceYear() ? (
              <Popover
                placement="topRight"
                content={i18n.translate(`common.licence.content`)}
                title={i18n.translate(`common.licence.title`)}
              >
                <Button disabled type="link" icon={<LinkOutlined />} />
              </Popover>
            ) : (
              <a target="_blank" href={record.vusVerdictLink}> <Button type="link" icon={<LinkOutlined />} /> </a>
            )
          ) : (
            <Popover
              placement="topRight"
              content={i18n.translate(`common.licence.content`)}
              title={i18n.translate(`common.licence.title`)}
            >
              <Button disabled type="link" icon={<LinkOutlined />} />
            </Popover>
          )}
        </>
      )
    },
    {
      title: i18n.translate('dkom.table.editedDec'),
      dataIndex: '',
      render: (text: any, record: any, index: any) => (
        <>
          {record.decisionPdfUuid ? (
            !checkDecisionLicenceYear() ? (
              <Popover
                placement="topRight"
                content={i18n.translate(`common.licence.content`)}
                title={i18n.translate(`common.licence.title`)}
              >
                <Button disabled type="link" className="cardBtn" icon={<FilePdfOutlined />} />
              </Popover>
            ) : (
              <Button onClick={() => onFileView(record.decisionPdfUuid, {type: 'decision', documentType: 'BJN uređena odluka', name: record.clazz, subject: record.subject})} type="link" icon={<FilePdfOutlined />} />
            )
          ) : (
            <Popover
              placement="topRight"
              content={i18n.translate(`common.licence.content`)}
              title={i18n.translate(`common.licence.title`)}
            >
              <Button disabled type="link" className="cardBtn" icon={<FilePdfOutlined />} />
            </Popover>
          )}
        </>
      )
    },
    {
      title: i18n.translate('dkom.table.decision'),
      dataIndex: '',
      render: (text: any, record: any, index: any) => (
        <>
          {record.dkomDecisionPdfUuid ? (
           !checkDecisionLicenceYear() ? (
              <Popover
                placement="topRight"
                content={i18n.translate(`common.licence.content`)}
                title={i18n.translate(`common.licence.title`)}
              >
                <Button disabled type="link" className="cardBtn" icon={<FilePdfOutlined />} />
              </Popover>
            ) : (
              <Button onClick={() => onFileView(record.dkomDecisionPdfUuid,{type: 'decision', documentType: 'DKOM originalna odluka', name: record.clazz, subject: record.subject})} type="link" icon={<FilePdfOutlined />} />
            )
          ) : (
            <Popover
              placement="topRight"
              content={i18n.translate(`common.licence.content`)}
              title={i18n.translate(`common.licence.title`)}
            >
              <Button disabled type="link" className="cardBtn" icon={<FilePdfOutlined />} />
            </Popover>
          )}
          {record.dkomDecisionLink ? (
            !checkDecisionLicenceYear() ? (
              <Popover
                placement="topRight"
                content={i18n.translate(`common.licence.content`)}
                title={i18n.translate(`common.licence.title`)}
              >
                <Button disabled type="link" icon={<LinkOutlined />} />
              </Popover>
            ) : (
              <a target="_blank" href={record.dkomDecisionLink}> <Button type="link" icon={<LinkOutlined />} /> </a>
            )
          ) : (
            <Popover
              placement="topRight"
              content={i18n.translate(`common.licence.content`)}
              title={i18n.translate(`common.licence.title`)}
            >
              <Button disabled type="link" icon={<LinkOutlined />} />
            </Popover>
          )}
        </>
      )
    },
    {
      title: i18n.translate('dkom.table.pmPdf'),
      dataIndex: '',
      render: (text: any, record: any, index: any) => (
        <>
          {record.pmPdfUuid ? (
            !checkDecisionLicenceYear() ? (
              <Popover
                placement="topRight"
                content={i18n.translate(`common.licence.content`)}
                title={i18n.translate(`common.licence.title`)}
              >
                <Button disabled type="link" className="cardBtn" icon={<FilePdfOutlined />} />
              </Popover>
            ) : (
              <Button onClick={() => onFileView(record.pmPdfUuid, {type: 'decision', documentType: 'PM odluka', name: record.clazz, subject: record.subject})} type="link" icon={<FilePdfOutlined />} />
            )
          ) : (
            <Popover
              placement="topRight"
              content={i18n.translate(`common.licence.content`)}
              title={i18n.translate(`common.licence.title`)}
            >
              <Button disabled type="link" className="cardBtn" icon={<FilePdfOutlined />} />
            </Popover>
          )}
        </>
      )
    },
    {
      title: i18n.translate('dkom.table.zznpPdf'),
      dataIndex: '',
      render: (text: any, record: any, index: any) => (
        <>
          {record.zznpPdfUuid ? (
            !checkDecisionLicenceYear() ? (
              <Popover
                placement="topRight"
                content={i18n.translate(`common.licence.content`)}
                title={i18n.translate(`common.licence.title`)}
              >
                <Button disabled type="link" className="cardBtn" icon={<FilePdfOutlined />} />
              </Popover>
            ) : (
              <Button onClick={() => onFileView(record.zznpPdfUuid, {type: 'decision', documentType: 'ZZNP odluka', name: record.clazz, subject: record.subject})} type="link" icon={<FilePdfOutlined />} />
            )
          ) : (
            <Popover
              placement="topRight"
              content={i18n.translate(`common.licence.content`)}
              title={i18n.translate(`common.licence.title`)}
            >
              <Button disabled type="link" className="cardBtn" icon={<FilePdfOutlined />} />
            </Popover>
          )}
        </>
      )
    },
    {
      title: i18n.translate('vus.table.number'),
      dataIndex: 'verdictNum',
    },
    {
      title: i18n.translate('vus.table.class'),
      dataIndex: 'decision',
    },
    {
      title: i18n.translate('vus.table.customer'),
      dataIndex: 'client',
    },
    {
      title: i18n.translate('vus.table.appellant'),
      dataIndex: 'appellant',
    },
    {
      title: i18n.translate('vus.table.plaintiff'),
      dataIndex: 'plaintiff',
    },
    {
      title: i18n.translate('vus.table.topic'),
      dataIndex: 'subject',
    },
    {
      title: i18n.translate('vus.table.type'),
      dataIndex: 'type',
      render: (text: any, record: any, index: any) => (<span>{getType(record) ? getType(record) : '—'}</span>),
    },
    {
      title: i18n.translate('vus.table.dateOfApp'),
      render: (text: any, record: any, index: any) => (<span>{record.appealInvestmentDate ? moment(record.appealInvestmentDate, JavaFormat).format('DD.MM.YYYY.') : '—'}</span>),
    },
    {
      title: i18n.translate('vus.table.dateOfDKOMDecision'),
      render: (text: any, record: any, index: any) => (<span>{record.decisionDate ? moment(record.decisionDate, JavaFormat).format('DD.MM.YYYY.') : '—'}</span>),
    },
    {
      title: i18n.translate('vus.table.dateOfDKOMPubl'),
      render: (text: any, record: any, index: any) => (<span>{record.decisionPublishDate ? moment(record.decisionPublishDate, JavaFormat).format('DD.MM.YYYY.') : '—'}</span>),
    },
    {
      title: i18n.translate('vus.table.waitTime'),
      dataIndex: 'decisionWaitTime',
      render: (text: any, record: any, index: any) => (<span>{record.decisionWaitTime > 0 ? `${record.decisionWaitTime} ${i18n.t('dkom.table.days')}` : `—`}</span>),
    },
    {
      title: i18n.translate('vus.table.dateOfVUSVerdict'),
      render: (text: any, record: any, index: any) => (<span>{record.verdictDate ? moment(record.verdictDate, 'DD.MM.YYYY.').format('DD.MM.YYYY.') : '—'}</span>),
    },
    {
      title: i18n.translate('vus.table.VUSverdictPubl'),
      render: (text: any, record: any, index: any) => (<span>{record.verdictPublishDate ? moment(record.verdictPublishDate, 'DD.MM.YYYY.').format('DD.MM.YYYY.') : '—'}</span>),
    },
    {
      title: i18n.translate('vus.table.lawFirm'),
      dataIndex: 'lawFirm',
    },
    {
      title: i18n.translate('vus.table.judge'),
      dataIndex: 'trialChamber',
    },
  ].filter(x => (x.dataIndex != 'lawFirm' && x.dataIndex != 'trialChamber') || userAuth.user?.roles === UserRoleEnum.ADMIN);

  const getVerdictData = () => {
    return {
      id: verdict?.id,
      attributes: {
        key: verdict?.id,
        ...verdict,
        decision: decision?.clazz,
        subject: decision?.subject,
        client: decision?.client,
        appellant: decision?.appellant,
        verdictDate: verdict?.verdictDate,
        verdictPublishDate: verdict?.verdictPublishDate,
        appealInvestmentDate: decision?.appealInvestmentDate,
        decisionDate: decision?.decisionDate,
        decisionPublishDate: decision?.decisionPublishDate,
        decisionWaitTime: decision?.decisionWaitTime,
        decisionPdfUuid: decision?.decisionPdfUuid,
        dkomDecisionPdfUuid: decision?.dkomDecisionPdfUuid,
        dkomDecisionLink: decision?.dkomDecisionLink,
        pmPdfUuid: decision?.pmPdfUuid,
        zznpPdfUuid: decision?.zznpPdfUuid,
      }
    };
  };

  const checkType = (records: DecisionInterface | VerdictInterface): boolean => {
    return records.attributes?.hasOwnProperty('verdictNum');
  };

  const getSequenceString = (order: number): string => {
    switch (drawerTitle) {
      case i18n.t(`rhPracticeDrawer.dkom.titles.allegation`):
        return order + '. ' + i18n.t(`rhPracticeDrawer.dkom.singular.allegation`);
      case i18n.t(`rhPracticeDrawer.dkom.titles.response`):
        return order + '. ' + i18n.t(`rhPracticeDrawer.dkom.singular.response`);
      case i18n.t(`rhPracticeDrawer.dkom.titles.summary`):
        return '';
      case i18n.t(`rhPracticeDrawer.vus.titles.allegation`):
        return order + '. ' + i18n.t(`rhPracticeDrawer.vus.singular.allegation`);
      case i18n.t(`rhPracticeDrawer.vus.titles.response`):
        return order + '. ' + i18n.t(`rhPracticeDrawer.vus.singular.response`);
      case i18n.t(`rhPracticeDrawer.vus.titles.summary`):
        return '';
      default:
        return '';
    }
  };

  // ===== DOM LISTENERS =====

  const onFileView = (uuid: string, extra: {type: string, documentType: string, name: string, subject?: string}) => {
    if (!uuid) return;
    if (userAuth?.user?.roles !== UserRoleEnum.ADMIN) {
      let userActivityLogData: any = {
        data: {
          type: 'user_activity_log',
          attributes: {
            action: UserActionTypeEnum.FILE_OPENED,
            extra: JSON.stringify(extra)
          },
          relationships: {
            user: {
              data: {
                id: userAuth?.user?.id,
                type: 'user'
              },
            },
          },
        },
      };

      UserActivityLogService.create(userActivityLogData);
    }
    ViewerService.goToPdf(uuid);
  };

  // ===== RENDER =====

  const DataBox = (data: any) => (
    <div className="box box-1">
      <div>{data?.data?.textContent}</div>
    </div>
  );

  const DataBox2 = (data: any) => (
    <div style={{marginTop: 24}} className="box box-1">
      {data?.data?.dkomText && <div className='title'>{i18n.translate(`allegations.form.fields.dkomText`)}</div>}
      {data?.data?.dkomText && <div className='text'>{data?.data?.dkomText}</div>}
      {data?.data?.appealAllegation && <div className='title'>{i18n.translate(`appeals.form.fields.appealAllegation`)}</div>}
      {data?.data?.appealAllegation && <div className='text'>{data?.data?.appealAllegation}</div>}
      {data?.data?.clientResponse && <div className='title'>{i18n.translate(`allegations.form.fields.clientResponse`)}</div>}
      {data?.data?.clientResponse && <div className='text'>{data?.data?.clientResponse}</div>}
      {data?.data?.lawArticle && <div className='title'>{i18n.translate(`allegations.form.fields.lawArticle`)}</div>}
      {data?.data?.lawArticle && <div className='text'>{data?.data?.lawArticle}</div>}
      {data?.data?.dkomAssessment && <div className='title'>{i18n.translate(`allegations.form.fields.dkomAssessment`)}</div>}
      {data?.data?.dkomAssessment && <div className='text'>{data?.data?.dkomAssessment}</div>}
      {data?.data?.vusText && <div className='title'>{i18n.translate(`orders.form.fields.vusText`)}</div>}
      {data?.data?.vusText && <div className='text'>{data?.data?.vusText}</div>}
      {data?.data?.thirdPartyResponse && <div className='title'>{i18n.translate(`orders.form.fields.thirdPartyResponse`)}</div>}
      {data?.data?.thirdPartyResponse && <div className='text'>{data?.data?.thirdPartyResponse}</div>}
      {data?.data?.zjnArticleEtc && <div className='title'>{i18n.translate(`orders.form.fields.zjnArticleEtc`)}</div>}
      {data?.data?.zjnArticleEtc && <div className='text'>{data?.data?.zjnArticleEtc}</div>}
      {data?.data?.vusAssessment && <div className='title'>{i18n.translate(`orders.form.fields.vusAssessment`)}</div>}
      {data?.data?.vusAssessment && <div className='text'>{data?.data?.vusAssessment}</div>}
      {/**data?.verdictAppealResponse && <div className='title'>{i18n.translate(`responses.form.fields.verdictAppealResponse`)}</div>*/}
      {/**data?.verdictAppealResponse && <div className='text'>{data?.verdictAppealResponse}</div>*/}
      {data?.data?.comment && <div className='title'>{i18n.translate(`allegations.form.fields.comment`)}</div>}
      {data?.data?.comment && <div className='text'>{data?.data?.comment}</div>}
    </div>
  );

  const dkomRatingFilterTrue = getDkomRatingFilterTrue(data);

  const DetailsBox = ({ records, items }: { records: DecisionInterface | VerdictInterface | any; items: Array<any>; }) => (
    <div className="box box-2">
      {/**<div className="document">
        <div className="document__title">{i18n.translate('common.myNotes')}</div>
        <div className="document__value">
          <Button type="link" className="cardBtn" onClick={showModal} icon={<Icon component={BookmarkOutlined} />}>
            {i18n.translate(`common.addNotes`)}
          </Button>
        </div>
      </div>*/}

      {dkomRatingFilterTrue && <div className="document">
        <div className="document__title">{checkType(records) ? 'Status tužbenog navoda' : 'Status žalbenog navoda'}</div>
        <div className="document__value">{dkomRatingFilterTrue}</div>
      </div>}

      {items.map((x, index) => {
        let title = x.title;
        let value = (records as any).attributes ? (records as any).attributes[x.dataIndex] : (records as any)[x.dataIndex];

        if (x.render) {
          value = x.render(null, (records as any).attributes ? (records as any).attributes : (records as any), index);
        }

        if (value === undefined || value === null) {
          value = '—';
        }

        return (
          <div key={index} className="document">
            <div className="document__title">{title}</div>
            <div className="document__value">{value}</div>
          </div>
        );
      })}

      {data?.attributes?.explanationPage && <div className="document">
        <div className="document__title">{i18n.translate('decisions.form.fields.explanationPage')}</div>
        <div className="document__value">{data?.attributes?.explanationPage}</div>
      </div>}

    </div>
  );

  return (
    <>
    <Drawer title={drawerTitle} placement="right" onClose={onDrawerClose} visible={drawerOpen} width={'80%'} destroyOnClose>
      <div className="rh-practice-details-drawer">
        <div className="flex-element">
          {data?.map((x: any, index: number) =>
          <div key={'drawerContent' + index} style={{ marginBottom: 24 }}>
            <div className="subtitle">{getSequenceString(index + 1) + (getSequenceString(index + 1) ? ' — ' : '') + x?.titleContent}</div>

            <div className="container">
              <div style={{ width: '100%' }}>
                <DataBox data={x} />
                {drawerTitle != i18n.t(`rhPracticeDrawer.dkom.titles.summary`) && drawerTitle != i18n.t(`rhPracticeDrawer.vus.titles.summary`) ? <DataBox2 data={x} /> : null}
              </div>
            </div>
          </div>
          )}
        </div>
        <div className="flex">
          {!verdict && decision && <DetailsBox records={decision} items={decisionData} />}
          {verdict && decision && <DetailsBox records={getVerdictData()} items={verdictData} />}
        </div>
      </div>
    </Drawer>
    <NotePickModal
      isExternal={true}
      visible={visible}
      handleOk={handleOk}
      handleCancel={handleCancel}
      external={data}
      type='decision'
      typeId={data?.id}
      breadcrumb={'Praksa u RH/Odluke DKOM-a/' + data?.attributes?.titleContent}
      number={data?.id}
      isEditable={true}
      active={true}
      licenceData={licenceData as LicenceInterface}
      validLicences={validLicences}
    />
    </>
  );
}
