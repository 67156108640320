import React, { useEffect, useState } from 'react';
import { DatePicker, Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { i18n } from '../../../services';
import { ExpiredLicenceService } from '../../../api';
import moment from 'moment';
import { DisplayDateFormat, JavaFormat } from '../../../constants';
import { Link } from 'react-router-dom';
import { AdminRoutes } from '../../../../features/admin';
import localeEN from 'antd/es/date-picker/locale/en_GB';
import localeHR from 'antd/es/date-picker/locale/hr_HR';
import { useSelector } from 'react-redux';
import { StoreStateInterface } from '../../../redux';

interface DataType {
  key: number;
  id: number;
  user: string;
  organization: string;
  userId: string;
  licenceType: string;
  biltenSubscription: string;
  printedCopyNum: number;
  licenceYear: string;
  additionalYears: string;
}

interface Props { }

export default function ExpiredLicencesComponent({ }: Props) {

  const userAuth = useSelector((state: StoreStateInterface) => state.auth);

  const [data, setData] = useState<Array<DataType>>([]);

  const [expirationDate, setExpirationDate] = useState<string>(moment().startOf('day').format(JavaFormat));

  useEffect(() => {
    initData();
  }, [expirationDate]);

  function initData() {
    ExpiredLicenceService.getAllByExpirationDate(expirationDate).subscribe((resp) => {
      let d: Array<DataType> = [];

      resp?.forEach((el) => {

        const additionalYears = el.licence?.childrenLicences?.map(cl => cl.year);

        d.push({
          key: el.id!,
          id: el.id!,
          user: el.user?.name ? el.user?.name : '—',
          organization: el.user?.organizationUnit?.organization?.name ? el.user?.organizationUnit?.organization?.name : '—',
          userId: el.user?.id ? el.user?.id?.toString() : '0',
          licenceType: el.licence?.licenceType ? el.licence?.licenceType : '—',
          biltenSubscription: el.licence?.biltenSubscription ? 'Da' : 'Ne',
          printedCopyNum: el.licence?.printedEditionsNum ? el.licence?.printedEditionsNum : 0,
          licenceYear: el.licence?.year ? el.licence?.year : '—',
          additionalYears: additionalYears?.length ? additionalYears.join(',') : '—'
        });
      });

      setData(d);
    });
  }

  const columns: ColumnsType<DataType> = [
    {
      title: i18n.translate(`adminProfile.expiredLicencesTable.user`),
      dataIndex: 'user',
      key: 'user',
      render: (text, record, index) => (
        <Link
          style={{ textDecoration: 'underline', color: 'blue' }}
          to={AdminRoutes.USER_PROFILE.fullPath + `/${record.userId}`}
          key={AdminRoutes.USER_PROFILE.path + `/${record.userId}`}
        >
          {text}
        </Link>
      ),
    },
    {
      title: i18n.translate(`adminProfile.expiredLicencesTable.organization`),
      dataIndex: 'organization',
      key: 'organization',
      render: (text, record, index) => (
        <span>{text.slice(0, -2)}</span>
      ),
    },
    {
      title: i18n.translate(`adminProfile.expiredLicencesTable.licenceType`),
      dataIndex: 'licenceType',
      render: (text, record, index) => {
        return i18n.translate(`common.licenceType.${text}`);
      },
    },
    {
      title: i18n.translate(`adminProfile.expiredLicencesTable.licenceYear`),
      dataIndex: 'licenceYear',
      key: 'licenceYear',
    },
    {
      title: i18n.translate(`adminProfile.expiredLicencesTable.additionalYears`),
      dataIndex: 'additionalYears',
      key: 'additionalYears',
    },
    {
      title: i18n.translate(`adminProfile.expiredLicencesTable.biltenSubscription`),
      dataIndex: 'biltenSubscription',
      key: 'biltenSubscription',
    },
    {
      title: i18n.translate(`adminProfile.expiredLicencesTable.printedCopyNum`),
      dataIndex: 'printedCopyNum',
      key: 'printedCopyNum',
    },
  ];

  return (
    <>
      <DatePicker value={moment(expirationDate)} onChange={(date) => setExpirationDate(moment(date, DisplayDateFormat).startOf('day').format(JavaFormat))} allowClear={false} locale={userAuth.lang == 'hr' ? localeHR : localeEN} format={DisplayDateFormat} style={{ borderRadius: 0, width: 200, marginBottom: 16 }} />
      <Table
        scroll={{ x: 1500, y: 600 }}
        pagination={false}
        columns={columns}
        dataSource={data}
        locale={{ emptyText: i18n.t('common.noData'), triggerDesc: i18n.t('common.sortDesc'), triggerAsc: i18n.t('common.sortAsc'), cancelSort: i18n.t('common.cancelSort')}}
      />
    </>
  );
}
