import { Button, Form, Input, Menu, MenuProps, Modal, Space, Typography } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NoteInterface, NoteResponseInterfaceSingle } from '../../../../interfaces';
import { StoreStateInterface } from '../../../../redux';
import { getAllNotesAction, NoteStateInterface, updateNoteAction } from '../../../../redux/notes';
import { i18n } from '../../../../services';

const { Text } = Typography;

interface Props {
  isNew: boolean;
  visible: boolean;
  handleOk: any;
  handleCancel: any;
  external?: any;
  type: string;
  typeId?: number;
  breadcrumb?: string;
  number?: number;
  isEditable?: boolean;
  active?: boolean;
  onFinish?: any;
  form?: any;
  toggleVisible?: any;
}

export default function NoteModal({
  isNew,
  visible,
  handleOk,
  handleCancel,
  external,
  type,
  typeId,
  breadcrumb,
  number,
  isEditable,
  active,
  onFinish,
  form,
  toggleVisible,
}: Props) {
  const dispatch = useDispatch();

  type MenuItem = Required<MenuProps>['items'][number];
  let options: MenuItem[] = [];

  const userAuth = useSelector((state: StoreStateInterface) => state.auth);
  const notes: NoteStateInterface = useSelector((state: StoreStateInterface) => state.notes);

  const filteredNotes = notes.notes?.data.filter((n) => n.attributes.type == 'note');

  const [selectedNoteId, setSelectedNoteId] = useState(0);
  const [selectedNote, setSelectedNote] = useState<NoteResponseInterfaceSingle>();

  useEffect(() => {
    // This is just a fail safe
    // All notes will be loaded in redux within app.router.tsx / admin.router.tsx
    if (!notes || !notes.notes || notes.notes.data.length === 0) {
      if (!notes.isLoading) dispatch(getAllNotesAction(userAuth.user?.id));
    }
  }, [userAuth?.user?.id, notes?.notes?.data?.length]);

  useEffect(() => {
    if (visible) {
      form.resetFields();
    }
  }, [visible]);

  function getItem(
    label: React.ReactNode,
    key: React.Key | null,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: 'group'
  ): MenuItem {
    return {
      key,
      icon,
      children,
      label,
      type,
    } as MenuItem;
  }

  filteredNotes?.forEach((note) => {
    options.push(getItem(note.attributes.title, note.id as React.Key));
  });

  const onClick: MenuProps['onClick'] = (e) => {
    setSelectedNoteId(parseInt(e.key));
  };

  const onFinishExisting = () => {
    let newNote: NoteInterface = {
      type: 'notes',
      attributes: {
        title: selectedNote?.data?.attributes?.title as string,
        text: selectedNote?.data?.attributes?.text as string,
        typeId: typeId,
        type: type,
        breadcrumb: breadcrumb,
        number: number,
        isEditable: isEditable,
        active: active,
        notesObject: external,
      },
      relationships: {
        user: {
          data: {
            id: userAuth.user?.id,
            type: 'user',
          },
        },
      },
    };

    let newNoteResponse: NoteResponseInterfaceSingle = {
      data: newNote,
    };

    dispatch(updateNoteAction(newNoteResponse, selectedNoteId));
    setTimeout(() => {
      toggleVisible();
    }, 1000);
  };

  return (
    <div>
      {isNew ? (
        <Modal
          title={
            <Text strong className="noteModal___title">
              {i18n.translate('notes.modal.title')}
            </Text>
          }
          visible={visible}
          onOk={handleOk}
          onCancel={() => {
            handleCancel();
          }}
          className="noteModal"
          footer={[
            <Button
              key="back"
              onClick={() => {
                handleCancel();
              }}
            >
              {i18n.translate(`genericButtons.cancel`)}
            </Button>,

            <Button key="submit" form="newNoteForm" type="primary" htmlType="submit" onClick={handleOk}>
              {i18n.translate(`notes.modal.createNew`)}
            </Button>,
          ]}
          width={1000}
        >
          <Form form={form} id="newNoteForm" onFinish={onFinish} layout="vertical">
            {type == 'note' ? (
              <></>
            ) : (
              <Form.Item>
                <Space direction="vertical">
                  <Text type="secondary">{i18n.translate(`notes.modal.linked`)}</Text>
                  <Text>
                    {external?.attributes?.title ||
                      external?.attributes?.name ||
                      external?.attributes?.titleContent ||
                      external?.attributes?.verdictName ||
                      external?.title ||
                      external?.attributes?.materialTitle}
                  </Text>
                </Space>
              </Form.Item>
            )}

            <Form.Item label={i18n.translate('notes.modal.name')} name="title">
              <Input placeholder={i18n.translate('notes.modal.name')} />
            </Form.Item>

            <Form.Item label={i18n.translate('notes.modal.body')} name="text">
              <TextArea rows={4} placeholder={i18n.translate('notes.modal.body')} />
            </Form.Item>
          </Form>
        </Modal>
      ) : (
        <Modal
          title={
            <Text strong className="noteModal___title">
              {i18n.translate('notes.modal.title')}
            </Text>
          }
          visible={visible}
          onOk={handleOk}
          onCancel={() => {
            handleCancel();
          }}
          className="noteModal"
          footer={[
            <Button
              key="back"
              onClick={() => {
                handleCancel();
              }}
            >
              {i18n.translate('genericButtons.cancel')}
            </Button>,

            <Button key="submit" form="existingNoteForm" type="primary" htmlType="submit" onClick={handleOk}>
              {i18n.translate(`notes.modal.addExisting`)}
            </Button>,
          ]}
          width={1000}
        >
          <Form form={form} preserve={false} id="existingNoteForm" onFinish={onFinishExisting} layout="vertical">
            <Form.Item>
              <Space direction="vertical">
                <Text type="secondary">{i18n.translate(`notes.modal.linked`)}</Text>
                <Text>
                  {external?.attributes?.title ||
                    external?.attributes?.name ||
                    external?.attributes?.titleContent ||
                    external?.attributes?.verdictName ||
                    external?.title ||
                    external?.attributes?.materialTitle}
                </Text>
              </Space>
            </Form.Item>

            <Form.Item>
              <Menu className="scrollList" items={options} onClick={onClick} />
            </Form.Item>
          </Form>
        </Modal>
      )}
    </div>
  );
}
