import Icon, {
  CaretRightOutlined,
  CheckCircleFilled,
  EditOutlined,
  ExclamationCircleFilled,
  FilePdfOutlined,
  LinkOutlined,
} from '@ant-design/icons';
import { Button, Collapse, Divider, Popover, Typography } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { DisplayYearFormat, NotePickModal, UserActivityLogService } from '../../..';
import { BookmarkOutlined } from '../../../../assets/icons/icons';
import { AdminRoutes } from '../../../../features/admin';
import { SelectTypeEnum, TypeEnum, UserRoleEnum } from '../../../enums';
import { LicenceEnum } from '../../../enums/licence.enum';
import {
  LicenceInterface,
  NoteResponseInterfaceAll,
  RegulationInterface,
  TagScreenResponseInterface,
} from '../../../interfaces';
import { StoreStateInterface } from '../../../redux';
import { DownloadService, i18n, ViewerService } from '../../../services';
import { UserActionTypeEnum } from '../../../enums/userActionType.enum';

const { Text } = Typography;
const { Panel } = Collapse;

interface Props {
  regulations?: Array<RegulationInterface>;
  tags?: TagScreenResponseInterface[];
  licenceData?: LicenceInterface;
  notes?: NoteResponseInterfaceAll;
  validLicences?: LicenceInterface[];
}

export default function RegulationCardComponent({ regulations, tags, licenceData, notes, validLicences }: Props) {
  const userAuth = useSelector((state: StoreStateInterface) => state.auth);

  const availableNotes = licenceData?.attributes?.notesNum as number;

  const [visible, setVisible] = useState(false);
  const [selectedReg, setSelectedReg] = useState<RegulationInterface>();

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    if (!((notes?.data.length as number) == availableNotes)) {
      setVisible(true);
    }
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const onClickAction = (reg: RegulationInterface) => {
    setSelectedReg(reg);
    showModal();
  };

  const onFileDownload = (regulation: RegulationInterface) => {
    if (!regulation) return;
    if (userAuth?.user?.roles !== UserRoleEnum.ADMIN) {
      let userActivityLogData: any = {
        data: {
          type: 'user_activity_log',
          attributes: {
            action: licenceData?.attributes?.licenceType === LicenceEnum.PREMIUM ? UserActionTypeEnum.FILE_DOWNLOADED : UserActionTypeEnum.FILE_OPENED,
            extra: JSON.stringify({type: 'regulation', title: regulation.attributes?.title})
          },
          relationships: {
            user: {
              data: {
                id: userAuth?.user?.id,
                type: 'user'
              },
            },
          },
        },
      };

      UserActivityLogService.create(userActivityLogData);
    }
    if (licenceData?.attributes?.licenceType === LicenceEnum.PREMIUM || userAuth.user?.roles === UserRoleEnum.ADMIN) {
      DownloadService.download(regulation?.attributes?.pdfUuid);
    } else {
      ViewerService.goToPdf(regulation?.attributes?.pdfUuid);
    }
  };

  const getRegulationYear = (regulation: RegulationInterface) => {
    let regulationYear = '';
    const parentTag = tags?.find((tag) => tag.selectType && tag.selectType == SelectTypeEnum.YEAR);
    if (parentTag && parentTag?.children?.length) {
      parentTag.children?.forEach((child) => {
        const id = regulation?.attributes?.tagIds?.find((id) => id == child.tag.id);
        if (id) regulationYear = child.tag.name;
      });
    }
    if (regulationYear) {
      regulationYear = moment(regulationYear).format(DisplayYearFormat);
    }
    return regulationYear;
  };

  const checkLicenceYear = (regulation: RegulationInterface, link: boolean) => {
    const regulationYear = moment(getRegulationYear(regulation)).format('YYYY');

    if (userAuth.user?.roles === UserRoleEnum.ADMIN) return true;

    if (validLicences?.length && link) {
      //ODLUČENO DA NE ŠTITIMO VIŠE PO GODINAMA
      /**
      let access = false;

      validLicences.forEach((licenceData) => {
        if (regulationYear == licenceData.attributes?.year) {
          access = true;
        }
        if (licenceData.attributes?.childrenLicences?.length) {
          if (licenceData.attributes?.childrenLicences?.find((x) => x.active && x.year == regulationYear)) {
            access = true;
          }
        }
      });

      return access;
      */
      return true;
    } else if (validLicences?.length && !link) {
      let access = false;

      validLicences.forEach((licenceData) => {
        //ODLUČENO DA NE ŠTITIMO VIŠE PO GODINAMA
        /**
        if (
          regulationYear == licenceData.attributes?.year &&
          licenceData.attributes?.licenceType === LicenceEnum.PREMIUM
        ) {
          access = true;
        }
        if (
          licenceData.attributes?.childrenLicences?.length &&
          licenceData.attributes?.licenceType === LicenceEnum.PREMIUM
        ) {
          if (licenceData.attributes?.childrenLicences?.find((x) => x.active && x.year == regulationYear)) {
            access = true;
          }
        }
        */
        if (licenceData.attributes?.licenceType === LicenceEnum.PREMIUM) access = true;
      });

      return access;
    } else {
      return false;
    }
  };

  const row = (regulation: RegulationInterface, key: string) => (
    <div key={key}>
      <div className="space-between flex-row">
        {checkIfRegulationValid(regulation) ? (
          <div className="icon">
            <Popover content={i18n.translate(`regulations.valid`)}>
              <CheckCircleFilled className="check" />{' '}
            </Popover>
          </div>
        ) : (
          <></>
        )}
        {!checkIfRegulationValid(regulation) ? (
          <div className="icon">
            <Popover content={i18n.translate(`regulations.invalid`)}>
              <ExclamationCircleFilled className="exc" />{' '}
            </Popover>
          </div>
        ) : (
          <></>
        )}

        <div className="space-between" style={{ padding: '0 16px', flex: 1 }}>
          <Text>{`${regulation?.attributes?.title}, ${getRegulationYear(regulation)}`}</Text>
        </div>

        <div className="space-between" style={{ width: '132px' }}>
          <>
            {checkLicenceYear(regulation, true) ? (
              <Button
                type="link"
                className="cardBtn"
                onClick={() => onClickAction(regulation)}
                icon={<Icon component={BookmarkOutlined} />}
              />
            ) : (
              <Popover
                placement="topRight"
                content={i18n.translate(`common.licence.content`)}
                title={i18n.translate(`common.licence.title`)}
              >
                <Button disabled type="link" className="cardBtn" icon={<Icon component={BookmarkOutlined} />} />
              </Popover>
            )}
            {regulation?.attributes?.pdfUuid ? (
              !checkLicenceYear(regulation, false) ? (
                <Popover
                  placement="topRight"
                  content={i18n.translate(`common.licence.content`)}
                  title={i18n.translate(`common.licence.title`)}
                >
                  <Button disabled type="link" className="cardBtn" icon={<FilePdfOutlined />} />
                </Popover>
              ) : (
                <Button
                  onClick={() => onFileDownload(regulation)}
                  type="link"
                  className="cardBtn"
                  icon={<FilePdfOutlined />}
                />
              )
            ) : (
              <Popover
                placement="topRight"
                content={i18n.translate(`common.licence.content`)}
                title={i18n.translate(`common.licence.title`)}
              >
                <Button disabled type="link" className="cardBtn" icon={<FilePdfOutlined />} />
              </Popover>
            )}
            {regulation?.attributes?.link ? (
              !checkLicenceYear(regulation, true) ? (
                // <Popover
                //   placement="topRight"
                //   content={i18n.translate(`common.licence.content`)}
                //   title={i18n.translate(`common.licence.title`)}
                // >
                //   <Button disabled type="link" className="cardBtn" icon={<LinkOutlined />} />
                // </Popover>
                <a href={regulation?.attributes?.link} target="_blank" rel="noopener noreferrer">
                  <Button type="link" className="cardBtn" icon={<LinkOutlined />} />
                </a>
              ) : (
                <a href={regulation?.attributes?.link} target="_blank" rel="noopener noreferrer">
                  <Button type="link" className="cardBtn" icon={<LinkOutlined />} />
                </a>
              )
            ) : (
              <Popover
                placement="topRight"
                content={i18n.translate(`common.licence.content`)}
                title={i18n.translate(`common.licence.title`)}
              >
                <Button disabled type="link" className="cardBtn" icon={<LinkOutlined />} />
              </Popover>
            )}
            {userAuth.user?.roles === UserRoleEnum.ADMIN && (
              <Link
                to={AdminRoutes.REGULATIONS.path + `/${regulation?.id}`}
                key={AdminRoutes.REGULATIONS.path + `/${regulation?.id}`}
              >
                <Button type="link" className="cardBtn" icon={<EditOutlined />} />
              </Link>
            )}
          </>
        </div>
      </div>
      <Divider />
    </div>
  );

  const panel = (child: any, key: string) => (
    <Panel key={key} header={child?.tag?.name}>
      {child?.filteredRegulations?.map((regulation: any, index: number) => row(regulation, 'regulation' + index))}
    </Panel>
  );

  const groupRelations = () => {
    let typeArray: any = [];
    let allFiltered: any = [];

    tags?.forEach((tag) => {
      if (tag.type && tag.type !== TypeEnum.REGULATION_STATUS)
        typeArray.push({ name: tag.filter.name, tag: tag.type, children: tag.children });
    });

    typeArray?.forEach((typeObject: any) => {
      typeObject?.children?.forEach((child: any) => {
        let filteredRegulations: any = [];
        regulations?.forEach((regulation: any) => {
          regulation?.attributes?.tagIds?.forEach((id: number) => {
            if (child?.tag?.id == id) {
              filteredRegulations?.push(regulation);
            }
          });
        });
        child.filteredRegulations = filteredRegulations;
      });
    });

    typeArray?.forEach((typeObject: any) => {
      typeObject?.children?.forEach((child: any) => {
        if (child?.filteredRegulations?.length) {
          child?.filteredRegulations?.forEach((filteredRegulation: any) => {
            allFiltered.push(filteredRegulation);
          });
        }
      });
    });

    typeArray.push({
      name: 'Nesvrstano',
      tag: 'regulation_unsorted',
      children: [{ filteredRegulations: [], tag: { id: -1, name: 'Nesvrstano' } }],
    });

    regulations?.forEach((regulation: any) => {
      const found = allFiltered.find((filtered: any) => filtered?.id == regulation.id);
      if (!found) typeArray[3]?.children[0]?.filteredRegulations?.push(regulation);
    });

    return typeArray;
  };

  const checkForRegulations = (children: any) => {
    let found = children?.find((child: any) => child?.filteredRegulations?.length > 0);
    return found ? true : false;
  };

  const checkIfRegulationValid = (regulation: RegulationInterface) => {
    let valid = false;
    const regulationStatusFilters = tags?.find((tag) => tag.type === TypeEnum.REGULATION_STATUS);
    if (regulationStatusFilters && regulationStatusFilters?.children?.length) {
      regulation?.attributes?.tagIds?.forEach((id) => {
        if (regulationStatusFilters?.children?.length && regulationStatusFilters?.children[0]) {
          if (regulationStatusFilters?.children[0].tag.id == id) valid = true;
          if (regulationStatusFilters?.children[1].tag.id == id) valid = false;
        }
      });
    }

    return valid;
  };

  const modal = (reg: RegulationInterface) => {
    return (
      <NotePickModal
        isExternal={true}
        visible={visible}
        handleOk={handleOk}
        handleCancel={handleCancel}
        external={reg}
        type="regulation"
        typeId={reg?.id as number}
        breadcrumb={'Mreža znanja/Propisi'}
        number={reg?.id}
        isEditable={true}
        active={true}
        licenceData={licenceData as LicenceInterface}
        validLicences={validLicences}
      />
    );
  };

  return (
    <div className="regulationsCard">
      {groupRelations()?.length
        ? [...groupRelations()].map((group: any, groupIndex: number) => (
            <div key={'regulations' + groupIndex}>
              {checkForRegulations(group.children) ? <div className="title">{group.name}</div> : null}
              <Collapse
                ghost
                //defaultActiveKey={group.children?.map((child: any, childIndex: number) => {return 'child' + childIndex})}
                expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
              >
                {group.children?.map((child: any, childIndex: number) =>
                  child.filteredRegulations?.length ? panel(child, 'child' + childIndex) : null
                )}
              </Collapse>
            </div>
          ))
        : null}

      {modal(selectedReg as RegulationInterface)}
    </div>
  );
}
