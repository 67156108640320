import React, { useEffect } from 'react';
import { Button, Collapse } from 'antd';
import Icon, { LinkOutlined } from '@ant-design/icons';
import { i18n } from '../../../common';

const { Panel } = Collapse;

function InstructionsPageComponent() {
  const onChange = (key: string | string[]) => {};

  function getItems(): Array<{ header: string; text: string; link?: { prefix: string; linkText: string; url: string; }; }> {
    return i18n.translate('instructions.help') as any;
  }

  useEffect(() => {
    localStorage.removeItem('transferFilters');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="instructions w100-h100">
      <div className="title">{i18n.translate('instructions.title')}</div>

      <Collapse defaultActiveKey={[]} onChange={onChange}>
        {getItems().map((x, i) => (
          <Panel header={x.header} key={i}>
            <div style={{whiteSpace: "pre-wrap", lineHeight: '16px'}}>{x.text}</div>
            {/* {x.link && (
              <div style={{ marginTop: '16px' }}>
                {x.link.prefix}
                <Button
                  type="link"
                  onClick={() => {}}
                >{x.link.linkText}</Button>
                .
              </div>
            )} */}
          </Panel>
        ))}
      </Collapse>
      <div style={{ padding: '16px' }}></div>
    </div>
  );
}

export default InstructionsPageComponent;
