import Icon, {
  CaretDownOutlined,
  CaretUpOutlined,
  EditOutlined,
  FilePdfOutlined,
  LinkOutlined
} from '@ant-design/icons';
import { Button, Col, Divider, notification, Popover, Row, Typography } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  clearProMaterialAction,
  FileService,
  FilterNameEnumHR,
  NotePickModal,
  ProMaterialsModal,
  SelectTypeEnum,
  StoreStateInterface,
  UserRoleEnum
} from '../../..';
import { BookmarkOutlined } from '../../../../assets/icons/icons';
import { AdminRoutes } from '../../../../features/admin';
import { LicenceEnum } from '../../../enums/licence.enum';
import useWindowSize from '../../../hooks/useWindowSize';
import {
  LicenceInterface,
  NoteResponseInterfaceAll,
  ProMaterialInterface,
  TagScreenResponseInterface
} from '../../../interfaces';
import { i18n } from '../../../services';

const { Paragraph, Text, Title } = Typography;

const oneLetterSize = 6.5;
const sidebarWidth = 256;
const sidebarBreakPoint = 575;

interface Props {
  proMaterial: ProMaterialInterface;
  licenceData?: LicenceInterface;
  notes?: NoteResponseInterfaceAll;
  tags?: TagScreenResponseInterface[];
  validLicences?: LicenceInterface[];
}

export default function ProMaterialCardComponent({ proMaterial, licenceData, notes, tags, validLicences }: Props) {
  const dispatch = useDispatch();
  
  const userAuth = useSelector((state: StoreStateInterface) => state.auth);

  const [ellipsis, setEllipsis] = useState(true);
  const [visible, setVisible] = useState(false);
  const [proMaterialVisible, setProMaterialVisible] = useState(false);
  const [links, setLinks] = useState(false);

  const windowSize = useWindowSize();

  const availableNotes = licenceData?.attributes?.notesNum as number;

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    if (!((notes?.data.length as number) == availableNotes)) {
      setVisible(true);
    }
    setVisible(false)
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleProMaterialOk = () => {
    dispatch(clearProMaterialAction());
    setProMaterialVisible(false);
  };

  const handleProMaterialCancel = () => {
    dispatch(clearProMaterialAction());
    setProMaterialVisible(false);
  };

  const onMaterialPdfViewEng = () => {
    FileService.temp(proMaterial?.attributes?.pdfUuidEng as string).subscribe(
      (response: any) => {
        if (response?.url) {
          notification['success']({ message: i18n.translate('api.downloadSuccessMessage'), duration: 2 });
          let url = response?.url + '/bjn';
          window.open(url, '_blank');
        } else {
          notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
        }
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
      }
    );
  };

  const onMaterialPdfViewModal = () => {
    setLinks(false);
    setProMaterialVisible(true);
  };

  const onMaterialLinkViewModal = () => {
    setLinks(true);
    setProMaterialVisible(true);
  };

  const displayShowMoreBtn = (text?: string) => {
    if (!windowSize.width || !text) return false;
    // if (text.indexOf('\n') !== -1) return true;
    const sidebarSpace = windowSize.width > sidebarBreakPoint ? sidebarWidth : 0;
    const contentWidth = text.length * oneLetterSize;
    const paddingWidth = 25;
    const boxWidth = (windowSize.width - sidebarSpace - paddingWidth - paddingWidth) * 0.8 * 2; // x2 becouse for preview we have 2 lines
    return boxWidth < contentWidth;
  };

  const checkLicenceYear = () => {
    //const materialYear = getYear();

    if (userAuth.user?.roles === UserRoleEnum.ADMIN) return true;
    
    if (!validLicences?.some(x => x.attributes?.licenceType === LicenceEnum.PREMIUM)) return false;

    if (validLicences?.length) {
      // MIČEMO ŠTIT ZA GODINE
      /**
      let access = false;

      validLicences.forEach((licenceData) => {
        if (materialYear == licenceData.attributes?.year) {
          access = true;
        }
        if (licenceData.attributes?.childrenLicences?.length) {
          if (licenceData.attributes?.childrenLicences?.find((x) => x.active && x.year == materialYear)) {
            access = true;
          }
        }
      });

      return access;
      */

      return true;
    } else {
      return false;
    }
  };

  const checkPdfAssetsExist = () => {
    return proMaterial?.attributes?.proMaterialAssets?.some(x => x.pdfUuidHr || x.pdfUuidEng);
  };

  const checkLinkAssetsExist = () => {
    return proMaterial?.attributes?.proMaterialAssets?.some(x => x.hrLink || x.engLink);
  };

  const getYear = () => {
    const tagYearsObject = tags?.find((x) => x.selectType === SelectTypeEnum.YEAR);
    const tagYearsArray = tagYearsObject?.children;

    let yearString = i18n.translate('common.unsorted');

    proMaterial?.attributes?.tagIds?.forEach((tagId) => {
      const found = tagYearsArray?.find((x) => x.tag?.id == tagId);
      if (found) {
        yearString = moment(found.tag?.name).format('YYYY');
      }
    });

    return yearString;
  };

  const getType = () => {
    let typeName = '';
    const parentTag = tags?.find((tag) => tag.filter && tag.filter.name == FilterNameEnumHR.MATERIAL_TYPE);
    if (parentTag && parentTag.children?.length) {
      parentTag.children?.forEach((child) => {
        const id = proMaterial?.attributes?.tagIds?.find((id: number | undefined) => id == child.tag.id);
        if (id) typeName = child.tag.name;
      });
    }
    return typeName;
  };

  return (
    <div className="materialCard">
      <Row className="row">
        <Col flex={4}>
          <Text className="dateTime" type="secondary">
            {i18n.translate(`proMaterials.publisher`) + proMaterial?.attributes?.publisher}
            {getType() ? ' | ' + i18n.translate(`proMaterials.typeOfMaterial`) + getType() : ''}
          </Text>
        </Col>
        <Col flex={0}>
          {checkLicenceYear() ? (
            <Button
              type="link"
              className="cardBtn"
              onClick={() => showModal()}
              icon={<Icon component={BookmarkOutlined} />}
            />
          ) : (
            <Popover
              placement="topRight"
              content={i18n.translate(`common.licence.content`)}
              title={i18n.translate(`common.licence.title`)}
            >
              <Button disabled type="link" className="cardBtn" icon={<Icon component={BookmarkOutlined} />} />
            </Popover>
          )}
          {!checkLicenceYear() || !checkPdfAssetsExist() ? (
            <Popover
              placement="topRight"
              content={i18n.translate(`common.licence.content`)}
              title={i18n.translate(`common.licence.title`)}
            >
              <Button disabled type="link" className="cardBtn" icon={<FilePdfOutlined />} />
            </Popover>
          ) : (
            <Button type="link" className="cardBtn" onClick={onMaterialPdfViewModal} icon={<FilePdfOutlined />} />
          )}
          {!checkLicenceYear() || !checkLinkAssetsExist() ? (
            <Popover
              placement="topRight"
              content={i18n.translate(`common.licence.content`)}
              title={i18n.translate(`common.licence.title`)}
            >
              <Button disabled type="link" className="cardBtn" icon={<LinkOutlined />} />
            </Popover>
          ) : (
            <Button type="link" className="cardBtn" onClick={onMaterialLinkViewModal} icon={<LinkOutlined />} />
          )}
          {userAuth.user?.roles === UserRoleEnum.ADMIN && (
            <Link
              to={AdminRoutes.PRO_MATERIALS.path + `/${proMaterial?.id}`}
              key={AdminRoutes.PRO_MATERIALS.path + `/${proMaterial?.id}`}
            >
              <Button type="link" className="cardBtn" icon={<EditOutlined />} />
            </Link>
          )}
        </Col>
      </Row>
      <Title level={4}>{proMaterial?.attributes?.materialTitle}</Title>
      <Paragraph
        className='para'
        ellipsis={ellipsis ? { rows: 2 } : false}
        style={{ whiteSpace: ellipsis ? 'normal' : 'pre-wrap' }}
      >
        {proMaterial?.attributes?.summary}
      </Paragraph>
      <>
        {ellipsis && displayShowMoreBtn(proMaterial?.attributes?.summary) && (
          <Button
            className="cardBtn"
            type="link"
            icon={<CaretDownOutlined />}
            onClick={() => {
              setEllipsis(!ellipsis);
            }}
          >
            {i18n.translate('common.readMore')}
          </Button>
        )}
        {!ellipsis && (
          <Button
            className="cardBtn"
            type="link"
            icon={<CaretUpOutlined />}
            onClick={() => {
              setEllipsis(!ellipsis);
            }}
          >
            {i18n.translate('common.hide')}
          </Button>
        )}
      </>
      <Divider />

      <NotePickModal
        isExternal={true}
        visible={visible}
        handleOk={handleOk}
        handleCancel={handleCancel}
        external={proMaterial}
        type="pro_material"
        typeId={proMaterial?.id}
        breadcrumb={'Pravna zaštita/Praksa u EU/Stručni materijali/' + proMaterial?.attributes?.materialTitle}
        number={proMaterial?.id}
        isEditable={true}
        active={true}
        licenceData={licenceData as LicenceInterface}
        validLicences={validLicences}
      />

      {proMaterialVisible ? <ProMaterialsModal
        visible={proMaterialVisible}
        handleOk={handleProMaterialOk}
        handleCancel={handleProMaterialCancel}
        proMaterial={proMaterial}
        links={links}
        licenceData={licenceData}
      /> : null}
    </div>
  );
}
