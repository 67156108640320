import React, { useEffect, useState } from 'react';
import { HomeOutlined } from '@ant-design/icons';
import { Breadcrumb, Button, Col, notification, Row, Typography } from 'antd';
import {
  BiltenStateInterface,
  clearNoteAction,
  getSingleBiltenAction,
  i18n,
  LicenceInterface,
  LicenceResponseInterfaceSingle,
  LicenceService,
  LicenceUserResponseInterfaceAll,
  LicenceUserService,
  NoteResponseInterfaceAll,
  NoteService,
  ReviewComponent,
  StoreStateInterface,
  UserRoleEnum,
} from '../../../../common';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { LicenceEnum } from '../../../../common/enums/licence.enum';
import { AdminRoutes } from '../../_router/admin.routes';

function NewsletterViewPageComponent() {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const { Paragraph, Text } = Typography;

  const newsletter: BiltenStateInterface = useSelector((state: StoreStateInterface) => state.bilten);
  const userAuth = useSelector((state: StoreStateInterface) => state.auth);

  const [licenceData, setLicenceData] = useState<LicenceInterface>();
  const [noteData, setNoteData] = useState<NoteResponseInterfaceAll>();

  useEffect(() => {
    clearNoteAction();
    NoteService.getAll(userAuth.user?.id, false).subscribe(
      (response: NoteResponseInterfaceAll) => {
        setNoteData(response); 
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
      }
    );
  }, [userAuth?.user?.id]);

  useEffect(() => {
    localStorage.removeItem('transferFilters');
    dispatch(getSingleBiltenAction(parseInt(id)));
  }, []);

  useEffect(() => {
    LicenceUserService.getAll(undefined, `filter[user.id]=${userAuth.user?.id}`, undefined, `page[offset]=0&page[limit]=999999`).subscribe(
      (response: LicenceUserResponseInterfaceAll) => {
        if (response.data?.length && response.data[0]) {
          const licenceId = response?.data[0]?.relationships?.licence?.data?.id;
          if (licenceId) {
            LicenceService.getSingle(licenceId).subscribe(
              (response: LicenceResponseInterfaceSingle) => {
                setLicenceData(response.data);
              }
            );
          }
        }
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newsletter.currentBilten]);

  return (
    <div className="newsletterView w100-h100">
      <div className="newsletterView___breadcrumbs">
        <Breadcrumb>
          <Breadcrumb.Item>
            <HomeOutlined />
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span>{i18n.translate('breadcrumbs.newsletters')}</span>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span>{newsletter?.currentBilten?.attributes?.name}</span>
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>
      {newsletter?.currentBilten?.attributes ? (
        <>
          <Row justify="space-between" align="middle">
            <Col style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
              <div className="newsletterView__title">{newsletter?.currentBilten?.attributes?.name}</div>
              <Link to={AdminRoutes.NEWSLETTERS.fullPath + `/${id}`} key={AdminRoutes.NEWSLETTERS.fullPath + `/${id}`}>
                <Button size="large">{i18n.translate('newsletters.editButton')}</Button>
              </Link>
            </Col>
          </Row>

          <ReviewComponent
            bilten={newsletter}
            notes={noteData}
          />

          <div className="newsletterView___description">
            <Row wrap={false} gutter={150}>
              <Col span={12}>
                <Text className="newsletterView__subtitle">{i18n.translate(`newsletters.goal`)}</Text>
                <Paragraph>{newsletter?.currentBilten?.attributes?.goalText}</Paragraph>
              </Col>

              <Col span={12}>
                <Text className="newsletterView__subtitle">{i18n.translate(`newsletters.resolve`)}</Text>
                <Paragraph>{newsletter?.currentBilten?.attributes?.resolvesText}</Paragraph>
              </Col>
            </Row>
          </div>
          <div style={{ height: 1, marginTop: 120 }}></div>
        </>
      ) : null}
    </div>
  );
}

export default NewsletterViewPageComponent;
