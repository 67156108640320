import { Button, Col, DatePicker, Form, FormInstance, Input, Modal, notification, Radio, Row, Typography } from 'antd';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { DisplayDateFormat } from '../../../constants';
import { StoreStateInterface } from '../../../redux';
import { i18n } from '../../../services';
import localeEN from 'antd/es/date-picker/locale/en_GB';
import localeHR from 'antd/es/date-picker/locale/hr_HR';
import { UserService } from '../../../api';

const { Title } = Typography;
const { TextArea } = Input;

interface Props {
  visible: boolean;
  handleOk?: (form: FormInstance<any>) => void;
  handleCancel: () => void;
}

export default function UsersModal({ visible, handleOk, handleCancel }: Props) {
  const userAuth = useSelector((state: StoreStateInterface) => state.auth);
  
  const [form] = Form.useForm();
  const formType = Form.useWatch('type', form);

  const [isLoading, setIsLoading] = useState(false);

  function checkIfEmailIsFree(email: string) {
    return new Promise((resolve, reject) => {
      setIsLoading(true);
      try {
        UserService.checkEmail(email).subscribe((resp) => {
          setIsLoading(false);
          resolve(resp);
        });
      } catch (error) {
        setIsLoading(false);
        resolve(false);
      }
    });
  }

  const onFinishForm = () => {
    if (form.getFieldValue('password') != form.getFieldValue('repeatPassword')) {
      notification['error']({ message: i18n.translate('common.passwordNoMatch'), duration: 5 });
      return;
    }
    
    form.validateFields()
    .then(async (values) => {
      let isEmailOk = await checkIfEmailIsFree(values.email);
      if (!isEmailOk) {
        notification['error']({ message: i18n.translate(`register.msgs.emailTaken`), duration: 4 });
        return;
      }
      handleOk && handleOk(form);
    })
    .catch((error) => {
      console.log(error);
    });
  };

  return (
    <div className="usersModal">
      <Modal
        title={i18n.translate('profile.myTeam.newUsers')}
        visible={visible}
        onOk={() => onFinishForm()}
        onCancel={() => {
          form.resetFields();
          handleCancel();
        }}
        footer={[
          <Button key="back" onClick={() => {
            form.resetFields();
            handleCancel();
          }}>
            {i18n.translate(`genericButtons.cancel`)}
          </Button>,
          <Button loading={isLoading} key="submit" type="primary" onClick={() => onFinishForm()}>
            {i18n.translate(`genericButtons.createUser`)}
          </Button>,
        ]}
      >
        <Form form={form} layout="vertical" initialValues={{type: 1, competition: false, active: true}}>
          {/**<Form.Item name="type">
            <Radio.Group>
              <Radio value={1}>{i18n.translate('register.form.legal')}</Radio>
              <Radio value={2}>{i18n.translate('register.form.physical')}</Radio>
            </Radio.Group>
          </Form.Item>*/}

          <Form.Item name="active" label={i18n.translate(`profile.subModels.active`)}>
            <Radio.Group optionType="button">
              <Radio value={false}>{i18n.translate('common.options.no')}</Radio>
              <Radio value={true}>{i18n.translate('common.options.yes')}</Radio>
            </Radio.Group>
          </Form.Item>

          <div className="section">
            <Title className="usersModal__title" level={5}></Title>
            <Form.Item label={i18n.translate(`profile.basicInfo.name`)} name="name" rules={[{ required: true, message: i18n.translate(`register.msgs.required`) }]}>
              <Input style={{ borderRadius: 0 }} />
            </Form.Item>
            <Form.Item name="email" label="E-mail" rules={[{ type: 'email', message: i18n.translate(`register.msgs.email`) }, { required: true, message: i18n.translate(`register.msgs.required`) }]}>
              <Input />
            </Form.Item>
            <Form.Item required label={i18n.translate(`profile.password.password`)} name="password" rules={[{ required: true, message: i18n.translate(`genericFormMsg.required`) }, {
                validator: (_, value) =>
                  !value.includes(" ")
                    ? Promise.resolve()
                    : Promise.reject(new Error(i18n.t('common.noSpace')))
              }]}
            >
              <Input.Password style={{ borderRadius: 0 }} />
            </Form.Item>
            <Form.Item required label={i18n.translate(`profile.password.repeatPassword`)} name="repeatPassword" rules={[{ required: true, message: i18n.translate(`genericFormMsg.required`) }, {
                validator: (_, value) =>
                  !value.includes(" ")
                    ? Promise.resolve()
                    : Promise.reject(new Error(i18n.t('common.noSpace')))
              }]}
            >
              <Input.Password style={{ borderRadius: 0 }} />
            </Form.Item>
            <Form.Item
              label={i18n.translate(`register.form.vatNonRequired`)}
              name="companyOib"
            >
              <Input style={{ borderRadius: 0, width: 200 }} />
            </Form.Item>
            <Form.Item
              label={i18n.translate(`profile.basicInfo.contactNo`)}
              name="contactNo"
            >
              <Input style={{ borderRadius: 0, width: 200 }} />
            </Form.Item>
          </div>

          <div className="section">
            <Title className="usersModal__title" level={5}>
              {i18n.translate('profile.basicInfo.remark')}
            </Title>
            <Form.Item name="competition" label={i18n.translate(`profile.basicInfo.competitionShort`)}>
              <Radio.Group optionType="button">
                <Radio value={false}>{i18n.translate('common.options.no')}</Radio>
                <Radio value={true}>{i18n.translate('common.options.yes')}</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item name="addressUser" label={i18n.translate(`profile.subModels.deliveryAddress`)}>
              <TextArea autoSize={{ minRows: 3 }} />
            </Form.Item>
            <Form.Item name="userRemark" label={i18n.translate(`profile.basicInfo.userRemark`)}>
              <TextArea autoSize={{ minRows: 3 }} />
            </Form.Item>
            <Form.Item name="temporisRemark" label={i18n.translate(`profile.basicInfo.temporisRemark`)}>
              <TextArea autoSize={{ minRows: 3 }} />
            </Form.Item>
          </div>
        </Form>
      </Modal>
    </div>
  );
}
