import Icon, { EditOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Row, Typography } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { NotePickModal } from '../../..';
import { BookmarkOutlined } from '../../../../../assets/icons/icons';
import { AdminRoutes } from '../../../../../features/admin';
import { DisplayDateFormat } from '../../../../constants';
import { UserRoleEnum } from '../../../../enums';
import {
  AppealInterface,
  DecisionInterface,
  LicenceInterface,
  NoteResponseInterfaceAll,
  TagScreenResponseInterface,
} from '../../../../interfaces';
import { StoreStateInterface } from '../../../../redux';
import { i18n } from '../../../../services';
import DecisionsDetailsDrawer from '../../../decisionsDetailsDrawer/decisionsDetailsDrawer.component';

const { Paragraph, Text } = Typography;

interface Props {
  appeal?: AppealInterface;
  tags?: Array<TagScreenResponseInterface>;
  licenceData?: LicenceInterface;
  notes?: NoteResponseInterfaceAll;
  validLicences?: LicenceInterface[];
}

export default function DKOMAppealCardComponent({ appeal, tags, licenceData, notes, validLicences }: Props) {
  const userAuth = useSelector((state: StoreStateInterface) => state.auth);

  const [decision, setDecision] = useState<DecisionInterface['attributes']>();
  const [visible, setVisible] = useState(false);

  const availableNotes = licenceData?.attributes?.notesNum as number;

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    if (!((notes?.data.length as number) == availableNotes)) {
      setVisible(true);
    }
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  useEffect(() => {
    const id = appeal?.relationships?.decision?.data?.id
      ? appeal?.relationships?.decision?.data?.id
      : appeal?.attributes?.decision?.id;
    if (id) {
      setDecision(appeal?.attributes?.decision);
      /**
      DecisionService.getSingle(id).subscribe(
        (response: any) => {
          setDecision(response.data);
        },
        (error: Error) => {
          console.log(error);
        }
      );
      */
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appeal]);

  return (
    <div className="appealCard">
      {appeal ? (
        <>
          <Row className="row">
            <Col flex={4}>
              <Text className="dateTime" type="secondary">
                {decision?.clazz} <Divider type="vertical" />{' '}
                {moment(decision?.decisionDate).format(DisplayDateFormat)}
              </Text>
            </Col>
            <Col flex={0}>
              <Button
                type="link"
                className="cardBtn"
                onClick={showModal}
                icon={<Icon component={BookmarkOutlined} />}
              />
              {userAuth.user?.roles === UserRoleEnum.ADMIN && (
                <Link
                  to={AdminRoutes.DKOM_DECISIONS.fullPath + `/${decision?.id}`}
                  key={AdminRoutes.DKOM_DECISIONS.fullPath + `/${decision?.id}`}
                >
                  <Button type="link" className="cardBtn" icon={<EditOutlined />} />
                </Link>
              )}
            </Col>
          </Row>
          <Paragraph className='para' style={{ whiteSpace: 'pre-wrap' }}>{appeal?.attributes?.titleContent}</Paragraph>

          <DecisionsDetailsDrawer
            drawerTitle={i18n.translate('appeals.title')}
            decision={decision}
            data={appeal}
            licenceData={licenceData}
            validLicences={validLicences}
          />

          <Divider />
          <NotePickModal
            isExternal={true}
            visible={visible}
            handleOk={handleOk}
            handleCancel={handleCancel}
            external={appeal}
            type="decision_appeal_response"
            typeId={appeal?.id}
            breadcrumb={
              'Pravna zaštita/Praksa u RH/Odluke DKOM-a/Odgovori na žalbu/' + appeal?.attributes?.titleContent
            }
            number={appeal?.id}
            isEditable={true}
            active={true}
            licenceData={licenceData as LicenceInterface}
            validLicences={validLicences}
          />
        </>
      ) : null}
    </div>
  );
}
