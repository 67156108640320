import { Dispatch } from 'redux';
import { notification } from 'antd';
import { BiltenConstants } from './bilten.constants';

import { BiltenService } from '../../api/api.service';
import { BiltenResponseInterfaceAll, BiltenResponseInterfaceSingle } from '../../interfaces';
import { i18n } from '../../services';

export function getAllBiltensAction(params?: any) {
  return (dispatch: Dispatch) => {
    return BiltenService.getAll(params).subscribe(
      (response: BiltenResponseInterfaceAll) => {
        dispatch({
          type: BiltenConstants.GET_ALL_SUCCESS,
          payload: response,
        });
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });

        dispatch({
          type: BiltenConstants.ERROR,
          payload: error,
        });
      }
    );
  };
}

export function getSingleBiltenAction(id: number) {
  return (dispatch: Dispatch) => {
    return BiltenService.getSingle(id).subscribe(
      (response: BiltenResponseInterfaceSingle) => {
        dispatch({
          type: BiltenConstants.GET_SINGLE_SUCCESS,
          payload: response,
        });
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });

        dispatch({
          type: BiltenConstants.ERROR,
          payload: error,
        });
      }
    );
  };
}

export function createBiltenAction(body: BiltenResponseInterfaceSingle) {
  return (dispatch: Dispatch) => {
    return BiltenService.create(body).subscribe(
      (response: BiltenResponseInterfaceSingle) => {
        notification['success']({ message: i18n.translate('api.successMessage'), duration: 2 });

        dispatch({
          type: BiltenConstants.CREATE_SUCCESS,
          payload: response,
        });
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });

        dispatch({
          type: BiltenConstants.ERROR,
          payload: error,
        });
      }
    );
  };
}

export function updateBiltenAction(body: BiltenResponseInterfaceSingle) {
  return (dispatch: Dispatch) => {
    return BiltenService.update(body).subscribe(
      (response: BiltenResponseInterfaceSingle) => {
        notification['success']({ message: i18n.translate('api.successMessage'), duration: 2 });

        dispatch({
          type: BiltenConstants.UPDATE_SUCCESS,
          payload: response,
        });
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });

        dispatch({
          type: BiltenConstants.ERROR,
          payload: error,
        });
      }
    );
  };
}

export function deleteBiltenAction(id: number) {
  return (dispatch: Dispatch) => {
    return BiltenService.delete(id).subscribe(
      (response: any) => {
        dispatch({
          type: BiltenConstants.DELETE
        });
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });

        dispatch({
          type: BiltenConstants.ERROR,
          payload: error,
        });
      }
    );
  };
}

export function clearBiltenAction() {
  return (dispatch: Dispatch) => {
    dispatch({ type: BiltenConstants.CLEAR_CURRENT });
  };
}

export function clearBiltenListAction() {
  return (dispatch: Dispatch) => {
    dispatch({ type: BiltenConstants.CLEAR_LIST });
  };
}
