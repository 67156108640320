import React, { useEffect, useState } from 'react';
import {
  clearFaqAction,
  clearFaqListAction,
  clearNoteAction,
  FAQCardComponent,
  FaqEsResponseInterface,
  FaqInterface,
  FaqService,
  FaqStateInterface,
  FilterComponent,
  getAllFaqsAction,
  getScreenTagsByTypeAction,
  i18n,
  LicenceInterface,
  LicenceResponseInterfaceSingle,
  LicenceService,
  LicenceUserResponseInterfaceAll,
  LicenceUserService,
  NoDataComponent,
  NoteResponseInterfaceAll,
  NoteService,
  NoteStateInterface,
  StoreStateInterface,
  TagScreenStateInterface,
} from '../../../common';
import { HomeOutlined } from '@ant-design/icons';
import { Breadcrumb, Button, notification, Pagination, PaginationProps } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { AdminRoutes } from '../_router/admin.routes';
import { Link } from 'react-router-dom';

function FaqPageComponent() {
  const dispatch = useDispatch();
  const data: FaqStateInterface = useSelector((state: StoreStateInterface) => state.faq);
  const tags: TagScreenStateInterface = useSelector((state: StoreStateInterface) => state.tagScreen);
  
  const userAuth = useSelector((state: StoreStateInterface) => state.auth);

  const [filterValues, setFilterValues] = useState();
  

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [keyword, setKeyword] = useState('');
  const [esData, setEsData] = useState<FaqEsResponseInterface>();
  const [exactMatch, setExactMatch] = useState<boolean>(true);

  const [licenceData, setLicenceData] = useState<LicenceInterface>();
  const [noteData, setNoteData] = useState<NoteResponseInterfaceAll>();

  useEffect(() => {
    clearNoteAction();
    NoteService.getAll(userAuth.user?.id, false).subscribe(
      (response: NoteResponseInterfaceAll) => {
        setNoteData(response); 
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
      }
    );
  }, [userAuth?.user?.id]);

  useEffect(() => {
    LicenceUserService.getAll(undefined, `filter[user.id]=${userAuth.user?.id}`, undefined, `page[offset]=0&page[limit]=999999`).subscribe(
      (response: LicenceUserResponseInterfaceAll) => {
        if (response.data?.length && response.data[0]) {
          const licenceId = response?.data[0]?.relationships?.licence?.data?.id;
          if (licenceId) {
            LicenceService.getSingle(licenceId).subscribe(
              (response: LicenceResponseInterfaceSingle) => {
                setLicenceData(response.data);
              }
            );
          }
        }
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    localStorage.removeItem('transferFilters');
    dispatch(clearFaqAction());
    dispatch(clearFaqListAction());
    dispatch(getScreenTagsByTypeAction('frequent_questions'));
    esSearchFunction({ from: pageSize * (page - 1), size: pageSize }, keyword, {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const esSearchFunction = (
    pageSorted: { from: number; size: number },
    keyword?: string,
    tagIds?: any
  ) => {
    FaqService.elasticSearch(pageSorted, exactMatch, keyword, tagIds).subscribe(
      (response: FaqEsResponseInterface) => {
        setEsData(response);
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
      }
    );
  };

  const refetch = (allValues: any) => {
    setFilterValues(allValues);
    setPage(1);
    esSearchFunction({ from: 0, size: pageSize }, keyword, allValues);
  };

  const resetSearch = () => {
    setEsData(undefined);
    setKeyword('');
    setFilterValues(undefined);
    setPage(1);
    esSearchFunction({ from: 0, size: pageSize }, '', {});
  };

  const onPaginationChange: PaginationProps['onChange'] = (page, pageSize) => {
    setPage(page);
    setPageSize(pageSize);
    esSearchFunction({ from: pageSize * (page - 1), size: pageSize }, keyword, filterValues);
  };

  const keywordChanged = (value: string) => {
    setKeyword(value);
  };

  const exactMatchChanged = (exactMatch: boolean) => {
    setExactMatch(exactMatch);
  };

  return (
    <div className="faq w100-h100">
      <div className="faq__breadcrumbs">
        <Breadcrumb>
          <Breadcrumb.Item>
            <HomeOutlined />
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span>{i18n.translate('breadcrumbs.web-of-knowledge')}</span>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span>{i18n.translate('menu.faq')}</span>
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="faq__admin">
        <div className="title">{i18n.translate('faq.title')}</div>
        <Link to={AdminRoutes.FAQ.fullPath + `/new`} key={AdminRoutes.FAQ.path + `/new`}>
          <Button
            className="button"
            type="primary"
            size="large"
            style={{ fontSize: 16, fontWeight: 700, borderRadius: 0 }}
          >
            {i18n.translate('faq.addButton')}
          </Button>
        </Link>
      </div>
      {tags.tagScreens && tags.tagScreens.length ? <FilterComponent aggregation={esData?.aggregation?.buckets} onExactMatchChange={exactMatchChanged} onKeywordChange={keywordChanged} filterAccess filters={tags.tagScreens} onFilterChange={refetch} onFilterRemove={resetSearch} /> : null}
        <>
          {esData?.hits?.length ?
            <>
              <FAQCardComponent faqs={esData?.hits.map((el: {index: "faq_group", id: number, score: number; source: FaqInterface['attributes']}) => el.source)} tags={tags.tagScreens} />
            </>
          : null}
          {!esData?.hits?.length ?
            <NoDataComponent />
          : null}
        </>
    
        <>
          {esData?.hits?.length ? (
            <Pagination
              style={{ float: 'right', paddingTop: 16, paddingBottom: 16 }}
              onChange={onPaginationChange}
              current={page}
              pageSize={pageSize}
              pageSizeOptions={['10', '20', '50', '100']}
              locale={{ items_per_page: `${i18n.t('pagination.perPage')}` }}
              total={esData?.totalHits}
              hideOnSinglePage
              responsive
              showTotal={(total) => `${i18n.t('pagination.total')}: ${total}`}
            />
          ) : null}
        </>
    </div>
  );
}

export default FaqPageComponent;
