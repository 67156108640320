export enum VerdictConstants {
  GET_ALL_SUCCESS = 'VerdictConstants_GET_ALL_SUCCESS',
  GET_SINGLE_SUCCESS = 'VerdictConstants_GET_SINGLE_SUCCESS',
  CREATE_SUCCESS = 'VerdictConstants_CREATE_SUCCESS',
  UPDATE_SUCCESS = 'VerdictConstants_UPDATE_SUCCESS',
  CLEAR_CURRENT = 'VerdictConstants_CLEAR_CURRENT',
  CLEAR_LIST = 'VerdictConstants_CLEAR_LIST',
  DELETE = 'VerdictConstants_DELETE',
  ERROR = 'VerdictConstants_ERROR'
}
