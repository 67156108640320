import { Button, Typography, Col, Form, Input, Row, Menu, Dropdown, Space, Divider, Select, notification } from 'antd';
import React from 'react';
import { SendOutlined, DownOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { i18n, LicenceUpgradeService } from '../../../services';
import { FullUserResponseInterfaceAll, LicenceInterface } from '../../../interfaces';
import { LicenceEnum } from '../../../enums/licence.enum';
import { useDispatch, useSelector } from 'react-redux';
import { clearFullUserAction, deleteLicenceAction, getSingleFullUserAction, StoreStateInterface } from '../../../redux';
import { UserRoleEnum } from '../../../enums';
import { useLocation } from 'react-router-dom';
import { InviteService, LicenceService, LicenceUserService } from '../../../api';

const { Title, Text } = Typography;

interface Props {
  licence?: LicenceInterface;
  unitUsers?: FullUserResponseInterfaceAll;
  orgUnitId?: number | string;
}

export default function NewMemberCardComponent({ licence, unitUsers, orgUnitId }: Props) {
  const location = useLocation();
  const path = location.pathname.split('/');
  const id = parseInt(path[path.length - 1]);
  
  const dispatch = useDispatch();
  
  const userAuth = useSelector((state: StoreStateInterface) => state.auth);

  const menu = (
    <Menu
      items={[
        {
          label: (
            <Button
              type="link"
              style={{ color: 'black' }}
              onClick={() => {
                
              }}
            >
              {i18n.translate(`profile.myTeam.activateCreated`)}
            </Button>
          ),
          key: '0',
        },
        {
          label: (
            <Button 
              danger 
              type="link"
              onClick={() => {
                dispatch(deleteLicenceAction(licence?.id as number));
                dispatch(clearFullUserAction());
                dispatch(getSingleFullUserAction(id));
              }}
            >
              {i18n.translate(`profile.myTeam.delete`)}
            </Button>
          ),
          key: '1',
        },
      ].filter(x => x.key === '1' && userAuth.user?.roles === UserRoleEnum.ADMIN)}
    />
  );

  const getBasicYears = () => {
    let yearArray = [];
    if (licence?.attributes?.year && licence?.attributes?.licenceType === LicenceEnum.STANDARD) {
      yearArray.push(licence?.attributes?.year);
    }
    if (licence?.attributes?.childrenLicences?.length) {
      licence?.attributes?.childrenLicences?.forEach(child => {
        if (licence?.attributes?.licenceType === LicenceEnum.STANDARD) {
          yearArray.push(child?.year);
        }
      });
    }

    return yearArray;
  };

  const getPremiumYears = () => {
    let yearArray = [];
    if (licence?.attributes?.year && licence?.attributes?.licenceType === LicenceEnum.PREMIUM) {
      yearArray.push(licence?.attributes?.year);
    }
    if (licence?.attributes?.childrenLicences?.length) {
      licence?.attributes?.childrenLicences?.forEach(child => {
        if (licence?.attributes?.licenceType === LicenceEnum.PREMIUM) {
          yearArray.push(child?.year);
        }
      });
    }

    return yearArray;
  };

  const approveLicence = (orgUnitId: number, licence: LicenceInterface, userId: number) => {
    LicenceUpgradeService.createLicenceUsers(userId, orgUnitId, licence).then(() => {
      notification['success']({ message: i18n.translate('api.successMessage'), duration: 2 });
      dispatch(clearFullUserAction());
      dispatch(getSingleFullUserAction(id));
    });
  };

  const unapproveLicence = (orgUnitId: number, licenceId: number, userId: number) => {
    LicenceService.disapproveSpecificLicence(orgUnitId, licenceId).subscribe(
      (responseLicence: any) => {
        notification['success']({ message: i18n.translate('api.successMessage'), duration: 2 });
        dispatch(clearFullUserAction());
        dispatch(getSingleFullUserAction(id));
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
      }
    );
  };

  const releaseLicence = (licenceId: number) => {
    LicenceService.releaseLicence(licenceId).subscribe(
      (responseLicence: any) => {
        notification['success']({ message: i18n.translate('api.successMessage'), duration: 2 });
        dispatch(clearFullUserAction());
        dispatch(getSingleFullUserAction(id));
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
      }
    );
  };

  const onFinish = (values: any) => {
    if (userAuth.user?.roles === UserRoleEnum.ADMIN) {
      licence?.attributes?.licenceUsers?.length ? releaseLicence(licence?.id as number) : approveLicence(orgUnitId as number, licence as LicenceInterface, values?.user);
    }
    if (userAuth.user?.roles === UserRoleEnum.MANAGER) {
      let inviteBody = {
        data: {
          type: 'user_invitation',
          attributes: {
            email: values?.mail,
            name: values?.name,
            phoneNumber: values?.contactNo,
            adminInvitation: false
          },
          relationships: {
            organizationUnit: {
              data: {
                id: orgUnitId,
                type: 'organizationUnit'
              }
            }
          }
        }
      }

      InviteService.create(inviteBody).subscribe(
        (response: any) => {
          notification['success']({ message: i18n.translate('api.successMessage'), duration: 2 });
          dispatch(clearFullUserAction());
          dispatch(getSingleFullUserAction(id));
        },
        (error: Error) => {
          notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
        }
      );
    }
  };

  const getLicenceUser = () => {
    let name = '';

    if (licence?.attributes?.licenceUsers?.length) {
      name = licence?.attributes?.licenceUsers[0]?.user?.name;
    }

    return name ? ' — ' + name : '';
  };

  return (
    <div className="newMemberCard">
      <Form onFinish={onFinish} initialValues={{user: unitUsers?.data[0]?.id}} layout="vertical">
        <Row className="titleRow" justify="space-between">
          <Col>
            <Title className="team__title" level={5}>
              {licence?.attributes?.licenceType === LicenceEnum.STANDARD ? i18n.translate(`profile.subModels.card.basic`) + getLicenceUser() : ''}
              {licence?.attributes?.licenceType === LicenceEnum.PREMIUM ? i18n.translate(`profile.subModels.card.premium`) + getLicenceUser() : ''}
            </Title>
          </Col>

          <Col>
            <Dropdown overlay={menu} trigger={['click']}>
              <a onClick={(e) => e.preventDefault()}>
                <Button>
                  {i18n.translate(`profile.actions`)}
                  <DownOutlined />
                </Button>
              </a>
            </Dropdown>
          </Col>
        </Row>
        <Row gutter={32}>
          <Col>
            <Space direction="vertical">
              <Text type="secondary">{i18n.translate(`profile.myTeam.years`).toUpperCase()}</Text>
              {licence?.attributes?.licenceType === LicenceEnum.STANDARD ? <Text>{getBasicYears()?.sort()?.reverse()?.join(', ')}</Text> : null}
              {licence?.attributes?.licenceType === LicenceEnum.PREMIUM ? <Text>{getPremiumYears()?.sort()?.reverse()?.join(', ')}</Text> : null}
            </Space>
          </Col>
          <Col>
            <Space direction="vertical">
              <Text type="secondary">{i18n.translate(`profile.myTeam.print`).toUpperCase()}</Text>
              <Text>{i18n.translate(licence?.attributes?.biltenSubscription ? `genericButtons.yes` : `genericButtons.no`)}</Text>
            </Space>
          </Col>
        </Row>
        <Divider />
        {userAuth.user?.roles === UserRoleEnum.MANAGER ?
        <Row align="bottom">
          <Col style={{ width: '213px' }}>
            <Form.Item label={i18n.translate(`profile.basicInfo.name`)} name="name" rules={[{ required: true, message: i18n.translate(`genericFormMsg.required`) }]}>
              <Input placeholder={i18n.translate(`profile.basicInfo.name`)} />
            </Form.Item>
          </Col>
          <Col style={{ width: '315px' }}>
            <Form.Item label="E-mail" name="mail" rules={[{ required: true, message: i18n.translate(`genericFormMsg.required`) }]}>
              <Input placeholder="E-mail" />
            </Form.Item>
          </Col>
          <Col style={{ width: '155px' }}>
            <Form.Item label={i18n.translate(`profile.basicInfo.contactNo`)} name="contactNo">
              <Input placeholder={i18n.translate(`profile.basicInfo.contactNo`)} />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item>
              <Button htmlType="submit" icon={<SendOutlined />}>{i18n.translate(`profile.myTeam.invite`)}</Button>
            </Form.Item>
          </Col>
        </Row> : null}
        {userAuth.user?.roles === UserRoleEnum.ADMIN ?
        <Row align="bottom">
          <Col span={16}>
            {!licence?.attributes?.licenceUsers?.length ? <Form.Item label={i18n.translate(`profile.basicInfo.name`)} name="user" rules={[{ required: true, message: i18n.translate(`genericFormMsg.required`) }]}>
              <Select>
                {unitUsers?.data?.length &&
                unitUsers?.data?.map((user) => (
                  <Select.Option key={user.id} value={user.id}>
                    {user.attributes?.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item> : <Form.Item label={i18n.translate(`profile.basicInfo.name`)}>
              <Select disabled defaultValue={licence?.attributes?.licenceUsers[0]?.user?.id}>
                {licence?.attributes?.licenceUsers?.length &&
                licence?.attributes?.licenceUsers.map((licenceUser) => (
                  <Select.Option key={licenceUser.user?.id} value={licenceUser.user?.id}>
                    {licenceUser.user?.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>}
          </Col>
          <Col span={8}>
            <Form.Item>
              {!licence?.attributes?.licenceUsers?.length ? <Button htmlType="submit" icon={<SendOutlined />}>{i18n.translate(`profile.myTeam.activate`)}</Button> : null}
              {licence?.attributes?.licenceUsers?.length ? <Button htmlType="submit" danger icon={<CloseCircleOutlined />}>{i18n.translate(`profile.myTeam.deactivate`)}</Button> : null}
            </Form.Item>
          </Col>
        </Row> : null}
      </Form>
    </div>
  );
}
