import React, { useEffect, useState } from 'react';
import { Button, Collapse, message, notification, Table, Upload, UploadProps } from 'antd';
import { UploadOutlined, ContainerOutlined } from '@ant-design/icons';
import { i18n, store } from '../../../common';
import TextArea from 'antd/lib/input/TextArea';
import HttpFactory from '../../../common/api/abstract.api.service';

const { Panel } = Collapse;

var LOADER__COUNT = 0;

function startLoader() {
  LOADER__COUNT++;
  let el: any = document.getElementById('global-loader');
  let el2: any = document.getElementById('global-loader-wrap');
  el.style.display = 'block';
  el2.style.display = 'block';
  return true;
}

function endLoader() {
  LOADER__COUNT--;
  if (LOADER__COUNT <= 0) {
    let el: any = document.getElementById('global-loader');
    let el2: any = document.getElementById('global-loader-wrap');
    el.style.display = 'none';
    el2.style.display = 'none';
    LOADER__COUNT = 0;
  }
  return true;
}

function SettingsPageComponent() {
  useEffect(() => {
    localStorage.removeItem('transferFilters');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (key: string | string[]) => {};

  const uploadProps: UploadProps = {
    name: 'files',
    multiple: false,
    action: process.env.REACT_APP_FILE_UPLOAD,
    showUploadList: {
      showRemoveIcon: false,
    },
  };

  const afterUpload = (info: any, callback: any) => {
    if (info.file.status === 'done') {
      callback(info.file.response[0].uuid);
    } else if (info.file.status === 'error') {
      message.error('Učitavanje datoteke neuspješno');
    }
  };

  const sendToBe = (url: string, body = {}, callback?: any, token?: boolean) => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    if (token) {
      var APP_TOKEN;
      var ORGANIZATION_ID;
  
      let currentState: any = store.getState();
  
      if (currentState?.auth?.isAuthenticated) {
        APP_TOKEN = currentState.auth.accessToken;
        ORGANIZATION_ID = currentState.auth.organizationId;
      }
  
      if (APP_TOKEN) myHeaders.append('Authorization', 'Bearer ' + APP_TOKEN);
      if (ORGANIZATION_ID) myHeaders.append('x-organization-id', ORGANIZATION_ID);
    }

    fetch(url, { headers: myHeaders, method: token ? 'PUT' : 'POST', body: JSON.stringify(body) })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          message.error(data.error);
        } else {
          callback && callback(data);
          message.success('Učitavanje dovršeno');
        }
        endLoader();
      });
  }

  async function doReindex(uuid: string) {
    var data = {
      index: uuid,
      recreateIndex: true,
    };
    let resp = await HttpFactory.PUT('api/es/bulk-index', data, undefined, true).subscribe(
      (response: any) => {
        notification['success']({ message: 'Učitavanje dovršeno', duration: 2 });
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
      }
    );
    return resp;
  }

  const DKOMDowload = (props: any) => {
    const [value, setValue] = useState("");

    const [dkomVusResp, setDkomVusResp] = useState([]);

    return (
      <Panel header={'DKOM / VUS Download'} key={'1'} {...props}>
        <TextArea value={value} onChange={e => setValue(e.target.value)} rows={10} />
        <Button onClick={() => {
          const parser = new DOMParser();
          const htmlDoc = parser.parseFromString(value, 'text/html');

          const tr = htmlDoc.getElementsByTagName('tr');

          const data: Array<any> = [];

          for (let index = 2; index < tr.length; index++) {
            const element = tr[index];

            // const klasa = element.children[0].innerHTML.replace('<br>', '').trim();
            // if(klasa === 'UP/II-034-02/22-01/840') debugger

            const extractDate = (str: string): string => {
              let parts = str.split('.');
              return `${parts[2]}-${parts[1]}-${parts[0]}`;
            }

            const getDateFromLinks = (element: Element, index: number): string => {
              // 1 -> 0 
              // 2 -> 2
              // 3 -> 4
              let key = (index - 1) * 2;
              try {
                return extractDate(element.children[key].innerHTML);
              } catch (error) {
                
              }
              return "";
            }

            const getLinkFromLinks = (element: Element, index: number): string => {
              // 1 -> 0 
              // 2 -> 2
              // 3 -> 4
              let key = (index - 1) * 2;
              try {
                return element.children[key].getAttribute("href") || "";
              } catch (error) {
                
              }
              return "";
            }

            const cleanNewLineString = (str: string): string => {
              let parts = str.split(new RegExp("\n", "g"));
              parts = parts.map(x => x.trim());
              return parts.join(' ').replace(new RegExp(" , ", "g"), ', ').trim();
            };

            data.push({
              klasa: element.children[0].innerHTML.replace('<br>', '').trim(),
              narucitelj: cleanNewLineString(element.children[1].innerHTML),
              zahtjevatelj: cleanNewLineString(element.children[2].innerHTML),
              predmet: (() => {
                let str = cleanNewLineString(element.children[3].innerHTML).replace(new RegExp("&nbsp;", "g"), '').replace(new RegExp('<span class="dokumentit">DIT</span><br>', "g"), '').trim();
                return str.charAt(0).toUpperCase() + str.slice(1);
              })(),
              vrsta: element.children[4].innerHTML.trim(),
              zaprimljeno: extractDate(element.children[5].innerHTML.trim()),
              status: element.children[6].innerHTML.replace(new RegExp("<br>", "g"), '').trim(),
              uredjena_odluka_datum: "",
              uredjena_odluka: "",
              dkom_odluka_datum: getDateFromLinks(element.children[7], 1),
              dkom_odluka: getLinkFromLinks(element.children[7], 1),
              zznp_odluka_datum: getDateFromLinks(element.children[7], 2),
              zznp_odluka: getLinkFromLinks(element.children[7], 2),
              pm_odluka_datum: getDateFromLinks(element.children[7], 3),
              pm_odluka: getLinkFromLinks(element.children[7], 3),
              vus: getLinkFromLinks(element.children[8], 1),
            });
          }

          let url = `${process.env.REACT_APP_IMPORTER}/api/download-dkom-vus`;
          startLoader();
          sendToBe(url, { data }, (resp: any) => {
            setDkomVusResp(resp.data);
            console.table(resp.data);
          });

          console.table(data);

        }}  type="primary" style={{marginTop: '16px', marginBottom: '16px'}}>Učitaj</Button>

        {dkomVusResp && dkomVusResp.length !== 0 && (() => {
          const columns = [
            {
              title: 'Klasa',
              dataIndex: 'klasa',
              key: 'klasa',
            },
            {
              title: 'Status',
              dataIndex: 'isNew',
              key: 'isNew',
              render: (text: any, record:any, index: any) => {
                return (record.isNew ? <div style={{ fontWeight: 600, color: 'green' }}>NOVA</div> : <div>STARA</div>);
              }
            },
            {
              title: 'Odluka',
              dataIndex: 'dkom_decision_link',
              key: 'dkom_decision_link',
              render: (text: any, record:any, index: any) => {
                if (record.dkom_decision_link.status === 'ok') return <div>OK</div>;
                if (record.dkom_decision_link.status === 'new') return <div style={{ fontWeight: 600, color: 'green' }}>NOVI</div>;
                if (record.dkom_decision_link.status === 'changed') return <div style={{ fontWeight: 600, color: 'red' }}>IZMJENJEN</div>;
                return '-';
              }
            },
            {
              title: 'ZZNP',
              dataIndex: 'zznp_link',
              key: 'zznp_link',
              render: (text: any, record:any, index: any) => {
                if (record.zznp_link.status === 'ok') return <div>OK</div>;
                if (record.zznp_link.status === 'new') return <div style={{ fontWeight: 600, color: 'green' }}>NOVI</div>;
                if (record.zznp_link.status === 'changed') return <div style={{ fontWeight: 600, color: 'red' }}>IZMJENJEN</div>;
                return '-';
              }
            },
            {
              title: 'PM',
              dataIndex: 'pm_link',
              key: 'pm_link',
              render: (text: any, record:any, index: any) => {
                if (record.pm_link.status === 'ok') return <div>OK</div>;
                if (record.pm_link.status === 'new') return <div style={{ fontWeight: 600, color: 'green' }}>NOVI</div>;
                if (record.pm_link.status === 'changed') return <div style={{ fontWeight: 600, color: 'red' }}>IZMJENJEN</div>;
                return '-';
              }
            },
            {
              title: 'VUS',
              dataIndex: 'verdict',
              key: 'verdict',
              render: (text: any, record:any, index: any) => {
                if (record.verdict.status === 'ok') return <div>OK</div>;
                if (record.verdict.status === 'new') return <div style={{ fontWeight: 600, color: 'green' }}>NOVI</div>;
                if (record.verdict.status === 'changed') return <div style={{ fontWeight: 600, color: 'red' }}>IZMJENJEN</div>;
                return '-';
              }
            },
          ];

          return <Table rowKey={'klasa'} dataSource={dkomVusResp} columns={columns} scroll={{ x: 1500 }} />;
        })()}
      </Panel>
    );
  };

  const DKOMFilesUpload = (props: any) => {

    const [dkom, setDkom] = useState();
    const [vus, setVus] = useState();

    const loadData = () => {
      startLoader();
      sendToBe(`${process.env.REACT_APP_IMPORTER}/api/download-dkom-links`, {}, (resp: any) => setDkom(resp.data));
      sendToBe(`${process.env.REACT_APP_IMPORTER}/api/download-vus-links`, {}, (resp: any) => setVus(resp.data));
    }
    
    const columns = [
      {
        title: 'Klasa',
        dataIndex: 'class',
        key: 'class',
      },
      {
        title: 'Link',
        dataIndex: 'link',
        key: 'link',
        render: (text: any, record:any, index: any) => {
          return (<a href={text} target='_blank'>{text}</a>);
        }
      },
      {
        title: '',
        dataIndex: 'id',
        key: 'id',
        render: (text: any, record:any, index: any) => {
          return (
            <Upload {...uploadProps} onChange={(info) => afterUpload(info, (uuid: string) => {
              let urlType = record.uuid_type === 'vus_verdict_pdf_uuid' ? 'download-vus-links-update' : 'download-dkom-links-update';
              let url = `${process.env.REACT_APP_IMPORTER}/api/${urlType}`;
              sendToBe(url, { data: { ...record, uuid } });
            })}>
              <Button icon={<UploadOutlined />}>Učitaj</Button>
            </Upload>
          );
        },
      },
    ];
    
    return (
      <Panel header={'DKOM PDF učitavaje'} key={'2'} {...props}>
        <div>
          <Button onClick={loadData} icon={<ContainerOutlined />}>Dohvati zapise bez dokumenata</Button>
          
          {dkom && <>
            <h2>DKOM</h2>
            <Table dataSource={dkom} columns={columns} scroll={{ x: 1500 }} />
          </>}

          {vus && <>
            <h2>VUS</h2>
            <Table dataSource={vus} columns={columns} scroll={{ x: 1500 }} />
          </>}
        </div>
      </Panel>
    );
  };

  const DKOMFilterUpload = (props: any) => {
    return (
      <Panel header={'DKOM Filteri'} key={'2'} {...props}>
        <div>
          <Upload {...uploadProps} beforeUpload={startLoader} onChange={(info) => afterUpload(info, (uuid: string) => {
            let url = `${process.env.REACT_APP_IMPORTER}/api/filter-upload-dkom/${uuid}`;
            sendToBe(url);
          })}>
            <Button icon={<UploadOutlined />}>Učitaj DKOM Filter XLS</Button>
          </Upload>
        </div>
      </Panel>
    );
  };

  const VUSFilterUpload = (props: any) => {
    return (
      <Panel header={'VUS Filteri'} key={'3'} {...props}>
        <div>
          <Upload {...uploadProps} beforeUpload={startLoader} onChange={(info) => afterUpload(info, (uuid: string) => {
            let url = `${process.env.REACT_APP_IMPORTER}/api/filter-upload-vus/${uuid}`;
            sendToBe(url);
          })}>
            <Button icon={<UploadOutlined />}>Učitaj VUS Filter XLS</Button>
          </Upload>
        </div>
      </Panel>
    );
  };

  const FAQFilterUpload = (props: any) => {
    return (
      <Panel header={'FAQ Filteri'} key={'4'} {...props}>
      <div>
        <Upload {...uploadProps} beforeUpload={startLoader} onChange={(info) => afterUpload(info, (uuid: string) => {
          let url = `${process.env.REACT_APP_IMPORTER}/api/filter-upload-faq/${uuid}`;
          sendToBe(url);
        })}>
          <Button icon={<UploadOutlined />}>Učitaj FAQ Filter XLS</Button>
        </Upload>
      </div>
      </Panel>
    );
  };

  const Elastic = (props: any) => {
    const elasticEnum = [
      'decision',
      'verdict',
      'roadmap',
      'bilten',
      'opinion',
      'professional_article',
      'faq_group',
      'decision_appeal_allegation',
      'decision_appeal_response',
      'decision_summary',
      'verdict_summary',
      'verdict_prosecution_order',
      'verdict_response',
      'regulation',
      'court_eu',
      'pro_material',
    ];

    return (
      <Panel header={'Elastic reindex'} key={'5'} {...props}>
        <div>
          {elasticEnum.map((indexName) => (
            <Button onClick={() => {
              startLoader();
              //sendToBe(`${process.env.REACT_APP_IMPORTER}/api/reindex/${indexName}`)
              doReindex(indexName)
            }} key={indexName} type="primary" style={{marginRight: '16px', marginBottom: '16px'}}>{indexName}</Button>
          ))}
        </div>
      </Panel>
    );
  };

  const VusTransfer = (props: any) => {
    const elasticEnum = [
      'vus_to_dkom',
    ];

    return (
      <Panel header={'Sinkronizacija Vrste presude VUS-a'} key={'6'} {...props}>
        <div>
          {elasticEnum.map((indexName) => (
            <Button onClick={() => {
              startLoader();
              sendToBe(`${process.env.REACT_APP_API_URL}/api/sync/tags/${indexName}`, undefined, undefined, true)
            }} key={indexName} type="primary" style={{marginRight: '16px', marginBottom: '16px'}}>{indexName}</Button>
          ))}
        </div>
      </Panel>
    );
  };

  return (
    <div className="settings-page w100-h100">
      <div className="title">{i18n.translate('settings.title')}</div>

      <Collapse defaultActiveKey={[]} onChange={onChange}>
        <DKOMDowload />
        <DKOMFilesUpload />
        <DKOMFilterUpload />
        <VUSFilterUpload />
        <FAQFilterUpload />
        <Elastic />
        <VusTransfer />
      </Collapse>
    </div>
  );
}

export default SettingsPageComponent;
