import { ProMaterialConstants } from './proMaterials.constants';
import {
  ProMaterialAssetInterface,
  ProMaterialInterface,
  ProMaterialResponseInterfaceAll,
  ProMaterialResponseInterfaceSingle,
  ReducerActionInterface,
} from '../../interfaces';

// ============ INTERFACE ============

export interface ProMaterialStateInterface {
  currentProMaterial?: ProMaterialInterface;
  includedMaterials?: ProMaterialAssetInterface[];
  promaterials?: ProMaterialResponseInterfaceAll;
  error?: string;
}

// ============ INIT STATE ============

const initialState: ProMaterialStateInterface = {
  currentProMaterial: undefined,
  includedMaterials: [],
  promaterials: {
    data: [],
    included: [],
    meta: {
      totalResourceCount: 0,
    },
  },
};

// ============ REDUCERS ============

const getSingleSuccess = (state: ProMaterialStateInterface, payload: ProMaterialResponseInterfaceSingle): ProMaterialStateInterface => {
  return {
    ...state,
    currentProMaterial: payload.data,
    promaterials: {
      data: [payload.data],
      included: payload.included,
      meta: {
        totalResourceCount: 1,
      },
    },
  };
};

const getSingleOnly = (state: ProMaterialStateInterface, payload: ProMaterialResponseInterfaceSingle): ProMaterialStateInterface => {
  return {
    ...state,
    currentProMaterial: payload.data,
    includedMaterials: payload.included,
  };
};

const getAllSuccess = (state: ProMaterialStateInterface, payload: ProMaterialResponseInterfaceAll): ProMaterialStateInterface => {
  return {
    ...state,
    promaterials: {
      data: payload.data,
      included: payload.included,
      meta: payload.meta,
    },
  };
};

const createSuccess = (state: ProMaterialStateInterface, payload: ProMaterialResponseInterfaceSingle): ProMaterialStateInterface => {
  return {
    ...state,
    currentProMaterial: payload.data,
  };
};

const updateSuccess = (state: ProMaterialStateInterface, payload: ProMaterialResponseInterfaceSingle): ProMaterialStateInterface => {
  return {
    ...state,
    currentProMaterial: payload.data,
  };
};

const clearCurrent = (state: ProMaterialStateInterface): ProMaterialStateInterface => {
  return {
    ...state,
    currentProMaterial: undefined,
    includedMaterials: []
  };
};

const clearList = (state: ProMaterialStateInterface): ProMaterialStateInterface => {
  return {
    ...state,
    promaterials: {
      data: [],
      included: [],
      meta: {
        totalResourceCount: 0,
      },
    },
  };
};

const deleteSuccess = (state: ProMaterialStateInterface): ProMaterialStateInterface => {
  return {
    ...state,
    currentProMaterial: undefined,
  };
};

const error = (state: ProMaterialStateInterface, payload: string) => {
  return {
    ...state,
    error: payload,
  };
};

// ============ EXPORTS ============

export const ProMaterialReducer = (state = initialState, action: ReducerActionInterface) => {
  switch (action.type) {
    case ProMaterialConstants.GET_SINGLE_SUCCESS:
      return getSingleSuccess(state, action.payload);
    case ProMaterialConstants.GET_SINGLE_ONLY:
      return getSingleOnly(state, action.payload);
    case ProMaterialConstants.GET_ALL_SUCCESS:
      return getAllSuccess(state, action.payload);
    case ProMaterialConstants.CREATE_SUCCESS:
      return createSuccess(state, action.payload);
    case ProMaterialConstants.UPDATE_SUCCESS:
      return updateSuccess(state, action.payload);
    case ProMaterialConstants.CLEAR_CURRENT:
      return clearCurrent(state);
    case ProMaterialConstants.CLEAR_LIST:
      return clearList(state);
    case ProMaterialConstants.DELETE:
      return deleteSuccess(state);
    case ProMaterialConstants.ERROR:
      return error(state, action.payload);
    default:
      return state;
  }
};
