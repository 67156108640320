import Icon, {
  CaretDownOutlined,
  CaretUpOutlined,
  EditOutlined,
  FilePdfOutlined,
  LinkOutlined
} from '@ant-design/icons';
import { Button, Col, Divider, Popover, Row, Typography } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { NotePickModal } from '../..';
import { BookmarkOutlined } from '../../../../assets/icons/icons';
import { AdminRoutes } from '../../../../features/admin';
import { DisplayDateFormat } from '../../../constants';
import { TypeEnum, UserRoleEnum } from '../../../enums';
import {
  LicenceInterface,
  NoteResponseInterfaceAll,
  OpinionInterface,
  TagScreenResponseInterface
} from '../../../interfaces';
import { StoreStateInterface } from '../../../redux';
import { DownloadService, i18n } from '../../../services';
import { UserActivityLogService } from '../../../api';
import { UserActionTypeEnum } from '../../../enums/userActionType.enum';

const { Paragraph, Text, Title } = Typography;

interface Props {
  opinion?: OpinionInterface;
  tags?: TagScreenResponseInterface[];
  licenceData?: LicenceInterface;
  notes?: NoteResponseInterfaceAll;
  validLicences?: LicenceInterface[];
}

export default function InstitutionalOpinionCardComponent({ opinion, tags, licenceData, notes, validLicences }: Props) {
  const userAuth = useSelector((state: StoreStateInterface) => state.auth);

  const [ellipsis, setEllipsis] = useState(true);
  const [visible, setVisible] = useState(false);

  const availableNotes = licenceData?.attributes?.notesNum as number;

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    if (!((notes?.data.length as number) == availableNotes)) {
      setVisible(true);
    }
    setVisible(false)
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const onFileDownload = () => {
    if (!opinion) return;
    if (userAuth?.user?.roles !== UserRoleEnum.ADMIN) {
      let userActivityLogData: any = {
        data: {
          type: 'user_activity_log',
          attributes: {
            action: UserActionTypeEnum.FILE_DOWNLOADED,
            extra: JSON.stringify({type: 'opinion', title: opinion.attributes?.title})
          },
          relationships: {
            user: {
              data: {
                id: userAuth?.user?.id,
                type: 'user'
              },
            },
          },
        },
      };

      UserActivityLogService.create(userActivityLogData);
    }
    DownloadService.download(opinion.attributes?.pdfUuid);
  };

  const getInstitutionName = () => {
    let institutionName = '';
    const parentTag = tags?.find((tag) => tag.type && tag.type == TypeEnum.INSTITUTION);
    if (parentTag && parentTag.children?.length) {
      parentTag.children?.forEach((child) => {
        const id = opinion?.attributes?.tagIds?.find((id) => id == child.tag.id);
        if (id) institutionName = child.tag.name;
      });
    }
    return institutionName;
  };

  const checkLicenceYear = () => {
    const opinionYear = getYear();

    if (userAuth.user?.roles === UserRoleEnum.ADMIN) return true;

    if (validLicences?.length) {
      //ODLUČENO DA NE ŠTITIMO VIŠE PO GODINAMA
      /**
      let access = false;

      validLicences.forEach((licenceData) => {
        if (opinionYear == licenceData.attributes?.year) {
          access = true;
        }
        if (licenceData.attributes?.childrenLicences?.length) {
          if (licenceData.attributes?.childrenLicences?.find((x) => x.active && x.year == opinionYear)) {
            access = true;
          }
        }
      });

      return access;
      */
      return true;
    } else {
      return false;
    }
  };

  const getYear = () => {
    let yearString = i18n.translate('common.unsorted');

    if (opinion?.attributes?.yearTag) yearString = opinion?.attributes?.yearTag.toString();

    return yearString;
  };

  return (
    <div className="institutionalOpinionCard">
      <Row className="row">
        <Col flex={4}>
          <Text className="dateTime" type="secondary">
            {getInstitutionName() ? (
              <>
                {getInstitutionName()} {opinion?.attributes?.institutionDate ? <Divider type="vertical" /> : null}
              </>
            ) : null}
            {opinion?.attributes?.institutionDate
              ? moment(opinion?.attributes?.institutionDate).format(DisplayDateFormat)
              : ''}
          </Text>
        </Col>
        <Col flex={0}>
          <>
            {checkLicenceYear() ? (
              <Button
                type="link"
                className="cardBtn"
                onClick={() => showModal()}
                icon={<Icon component={BookmarkOutlined} />}
              />
            ) : (
              <Popover
                placement="topRight"
                content={i18n.translate(`common.licence.content`)}
                title={i18n.translate(`common.licence.title`)}
              >
                <Button disabled type="link" className="cardBtn" icon={<Icon component={BookmarkOutlined} />} />
              </Popover>
            )}
            {opinion?.attributes?.pdfUuid ? (
              !checkLicenceYear() ? (
                <Popover
                  placement="topRight"
                  content={i18n.translate(`common.licence.content`)}
                  title={i18n.translate(`common.licence.title`)}
                >
                  <Button disabled type="link" className="cardBtn" icon={<FilePdfOutlined />} />
                </Popover>
              ) : (
                <Button type="link" onClick={onFileDownload} className="cardBtn" icon={<FilePdfOutlined />} />
              )
            ) : (
              <Popover
                placement="topRight"
                content={i18n.translate(`common.licence.content`)}
                title={i18n.translate(`common.licence.title`)}
              >
                <Button disabled type="link" className="cardBtn" icon={<FilePdfOutlined />} />
              </Popover>
            )}
            {opinion?.attributes?.link ? (
              !checkLicenceYear() ? (
                <Popover
                  placement="topRight"
                  content={i18n.translate(`common.licence.content`)}
                  title={i18n.translate(`common.licence.title`)}
                >
                  <Button disabled type="link" className="cardBtn" icon={<LinkOutlined />} />
                </Popover>
              ) : (
                <a href={opinion?.attributes?.link} target="_blank" rel="noopener noreferrer">
                  <Button type="link" className="cardBtn" icon={<LinkOutlined />} />
                </a>
              )
            ) : (
              <Popover
                placement="topRight"
                content={i18n.translate(`common.licence.content`)}
                title={i18n.translate(`common.licence.title`)}
              >
                <Button disabled type="link" className="cardBtn" icon={<LinkOutlined />} />
              </Popover>
            )}
            {userAuth.user?.roles === UserRoleEnum.ADMIN && (
              <Link
                to={AdminRoutes.INSTITUTIONAL_OPINIONS.path + `/${opinion?.id}`}
                key={AdminRoutes.INSTITUTIONAL_OPINIONS.path + `/${opinion?.id}`}
              >
                <Button type="link" className="cardBtn" icon={<EditOutlined />} />
              </Link>
            )}
          </>
        </Col>
      </Row>
      <Title level={4} className={'institutionalOpinionCard___title'}>
        {opinion?.attributes?.title}
      </Title>
      <Paragraph className='para' style={{ whiteSpace: 'pre-wrap' }}>{opinion?.attributes?.summary}</Paragraph>
      {!ellipsis && (
        <Paragraph className='para' style={{ whiteSpace: 'pre-wrap' }}>{opinion?.attributes?.content}</Paragraph>
      )}
      {opinion?.attributes?.content && (
        <>
          {ellipsis && (
            <Button
              className="cardBtn"
              type="link"
              icon={<CaretDownOutlined />}
              onClick={() => {
                setEllipsis(!ellipsis);
              }}
              style={!checkLicenceYear() ? { opacity: 1 } : {}}
              disabled={!checkLicenceYear()}
            >
              {i18n.translate('common.readMore')}
            </Button>
          )}
          {!ellipsis && (
            <Button
              className="cardBtn"
              type="link"
              icon={<CaretUpOutlined />}
              onClick={() => {
                setEllipsis(!ellipsis);
              }}
            >
              {i18n.translate('common.hide')}
            </Button>
          )}
        </>
      )}
      <Divider />
      <NotePickModal
        isExternal={true}
        visible={visible}
        handleOk={handleOk}
        handleCancel={handleCancel}
        external={opinion}
        type="opinion"
        typeId={opinion?.id}
        breadcrumb={'Mreža znanja/Mišljenja institucija/' + opinion?.attributes?.title}
        number={opinion?.id}
        isEditable={true}
        active={true}
        licenceData={licenceData as LicenceInterface}
        validLicences={validLicences}
      />
    </div>
  );
}
