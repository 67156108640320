export enum PrintEditionConstants {
  GET_ALL_SUCCESS = 'PrintEditionConstants_GET_ALL_SUCCESS',
  GET_SINGLE_SUCCESS = 'PrintEditionConstants_GET_SINGLE_SUCCESS',
  GET_SINGLE_ONLY = 'PrintEditionConstants_GET_SINGLE_ONLY',
  CREATE_SUCCESS = 'PrintEditionConstants_CREATE_SUCCESS',
  UPDATE_SUCCESS = 'PrintEditionConstants_UPDATE_SUCCESS',
  CLEAR_CURRENT = 'PrintEditionConstants_CLEAR_CURRENT',
  CLEAR_LIST = 'PrintEditionConstants_CLEAR_LIST',
  DELETE = 'PrintEditionConstants_DELETE',
  ERROR = 'PrintEditionConstants_ERROR'
}
