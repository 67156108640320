import { CommentOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Breadcrumb, Button, Col, Form, Modal, notification, Popover, Row, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteNoteLinkAction,
  FullOrganizationUnitResponseInterfaceSingle,
  getAllNoteLinksAction,
  i18n,
  LicenceInterface,
  LicenceResponseInterfaceAll,
  LicenceService,
  NoteInterface,
  NoteLinkStateInterface,
  NoteModal,
  NoteResponseInterfaceSingle,
  NotesComponent,
  OrganizationUnitService,
  StoreStateInterface,
  UserActivityLogService,
  UserRoleEnum,
} from '../../../../common';
import { LicenceEnum } from '../../../../common/enums/licence.enum';
import {
  clearNoteAction,
  createNoteAction,
  deleteNoteAction,
  getAllNotesAction,
  NoteStateInterface,
} from '../../../../common/redux/notes';
import { UserActionTypeEnum } from '../../../../common/enums/userActionType.enum';
import moment from 'moment';

function NotesPageComponent() {
  const { Text } = Typography;
  const { confirm } = Modal;

  const dispatch = useDispatch();

  const userAuth = useSelector((state: StoreStateInterface) => state.auth);

  const notes: NoteStateInterface = useSelector((state: StoreStateInterface) => state.notes);

  const noteLinkData: NoteLinkStateInterface = useSelector((state: StoreStateInterface) => state.notesLinks);

  const [orgUnitInfo, setOrgUnitInfo] = useState<FullOrganizationUnitResponseInterfaceSingle>();
  const [licenceData, setLicenceData] = useState<LicenceInterface>();
  const [allValidLicences, setAllValidLicences] = useState<LicenceInterface[]>([]);
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();

  const availableNotes = allValidLicences?.length ? licenceData?.attributes?.notesNum as number : 0;

  useEffect(() => {
    localStorage.removeItem('transferFilters');
    clearNoteAction();
    dispatch(getAllNotesAction(userAuth.user?.id));
  }, []);

  const refresh = () => {
    clearNoteAction();
    dispatch(getAllNotesAction(userAuth.user?.id));
  };

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    if (!((notes?.notes?.data?.length as number) == availableNotes)) {
      setVisible(true);
      refresh();
    }
    setVisible(false);
    refresh();
  };

  const handleCancel = () => {
    setTimeout(() => {
      setVisible(false);
      refresh();
    }, 1000);
  };

  useEffect(() => {
    if (userAuth.user?.organizationUnit?.id) {
      OrganizationUnitService.getSingle(userAuth.user?.organizationUnit?.id).subscribe(
        (response: FullOrganizationUnitResponseInterfaceSingle) => {
          setOrgUnitInfo(response);
        },
        (error: Error) => {
          notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
        }
      );
    }
  }, [userAuth.user]);

  useEffect(() => {
    if (orgUnitInfo?.data?.id) {
      LicenceService.getAll(
        undefined,
        `filter[organizationUnit.id]=${orgUnitInfo?.data?.id}`,
        'sort=id',
        `page[offset]=0&page[limit]=999999`
      ).subscribe(
        (response: LicenceResponseInterfaceAll) => {
          if (response?.data?.length) {
            let validLicences: LicenceInterface[] = [];

            response?.data?.forEach(licence => {
              if (licence.attributes?.licenceUsers?.find((x) => x.user?.id == userAuth.user?.id) && !licence.relationships?.licenceParent?.data && licence.attributes?.notesNum > 0) {
                setLicenceData(licence);
                const standardLicence = response.data.find(
                  (x) => x.attributes?.licenceUsers?.find((y) => y.user?.id == userAuth.user?.id) && !x.relationships?.licenceParent?.data && x.attributes?.active && x.attributes?.licenceType === LicenceEnum.STANDARD && licence.attributes?.notesNum > 0 && moment().isBefore(moment(licence?.attributes?.to).startOf('day'))
                );
                const premiumLicence = response.data.find(
                  (x) => x.attributes?.licenceUsers?.find((y) => y.user?.id == userAuth.user?.id) && !x.relationships?.licenceParent?.data && x.attributes?.active && x.attributes?.licenceType === LicenceEnum.PREMIUM && licence.attributes?.notesNum > 0 && moment().isBefore(moment(licence?.attributes?.to).startOf('day'))
                );
                if (standardLicence && standardLicence.attributes?.notesNum > 0) setLicenceData(standardLicence);
                if (premiumLicence && premiumLicence.attributes?.notesNum > 0) setLicenceData(premiumLicence);
              }
              if (licence.attributes?.licenceUsers?.find(x => x.user?.id == userAuth.user?.id) && !licence.relationships?.licenceParent?.data && licence.attributes?.active && licence?.attributes?.licenceType !== LicenceEnum.FREE && moment().isBefore(moment(licence?.attributes?.to).startOf('day'))) {
                validLicences.push(licence);
              }
            });

            setAllValidLicences([...validLicences]);
          }
        },
        (error: Error) => {
          notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
        }
      );
    }
  }, [orgUnitInfo?.data]);

  const showConfirm = (id: number) => {
    confirm({
      title: i18n.t('notes.confirmDelete'),
      icon: <ExclamationCircleOutlined />,
      content: i18n.t('common.cantUndo'),
      okText: i18n.t('genericButtons.yes'),
      cancelText: i18n.t('genericButtons.cancel'),
      onOk() {
        dispatch(getAllNoteLinksAction(id));
        for (let i = 0; i <= (noteLinkData?.notesLinks?.data?.length as number); i++) {
          noteLinkData?.notesLinks?.data?.forEach((link) => {
            dispatch(deleteNoteLinkAction(link?.id as number));
          });
        }

        notification['success']({ message: i18n.translate('api.successDelete'), duration: 2 });
        dispatch(deleteNoteAction(id));
        
        setTimeout(() => {
          dispatch(getAllNotesAction(userAuth.user?.id));
        }, 1000);
      },
    });
  };

  const onFinish = (values: any) => {
    let newNote: NoteInterface = {
      type: 'notes',
      attributes: {
        title: values.title,
        text: values.text,
        type: 'note',
        breadcrumb: '--',
        isEditable: true,
        active: true,
      },
      relationships: {
        user: {
          data: {
            id: userAuth.user?.id,
            type: 'user',
          },
        },
      },
    };

    let newNoteResponse: NoteResponseInterfaceSingle = {
      data: newNote,
    };

    if (userAuth?.user?.roles !== UserRoleEnum.ADMIN) {
      let userActivityLogData: any = {
        data: {
          type: 'user_activity_log',
          attributes: {
            action: UserActionTypeEnum.NOTE_CREATED,
            extra: newNoteResponse.data?.attributes?.type == 'note' ? undefined : newNoteResponse.data?.attributes?.type
          },
          relationships: {
            user: {
              data: {
                id: userAuth?.user?.id,
                type: 'user'
              },
            },
          },
        },
      };

      UserActivityLogService.create(userActivityLogData);
    }

    dispatch(createNoteAction(newNoteResponse, userAuth.user?.id));
    // refresh();
    setVisible(false);
  };

  return (
    <div className="notes w100-h100">
      <div className="notes___breadcrumbs">
        <Breadcrumb>
          <Breadcrumb.Item>
            <CommentOutlined />
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span>{i18n.translate('breadcrumbs.notes')}</span>
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <Row justify="space-between">
        <Col>
          <div className="notes__title">{i18n.translate('notes.title')}</div>
        </Col>
        <Col>
          {(notes?.notes?.data?.length as number) >= availableNotes ? (
            <Popover
              placement="bottomRight"
              content={i18n.translate(`notes.modal.exceeded.content`)}
              title={i18n.translate(`notes.modal.exceeded.title`)}
            >
              <Button
                disabled
                className="button"
                type="primary"
                size="large"
                style={{ fontSize: 16, fontWeight: 700, borderRadius: 0 }}
              >
                {i18n.translate('notes.create')}
              </Button>
            </Popover>
          ) : (
            <Button
              className="button"
              type="primary"
              size="large"
              onClick={showModal}
              style={{ fontSize: 16, fontWeight: 700, borderRadius: 0 }}
            >
              {i18n.translate('notes.create')}
            </Button>
          )}
        </Col>
      </Row>
      <div className="notes___content">
        {notes?.notes?.data
          .sort((a, b) => {
            return (
              new Date(a?.attributes?.modifiedDate as string).getTime() -
              new Date(b.attributes?.modifiedDate as string).getTime()
            );
          })
          .reverse()
          .map((note, i) => (
            <NotesComponent
              key={'note' + i}
              note={note}
              licenceData={licenceData}
              validLicences={allValidLicences}
              onDelete={() => {
                showConfirm(note?.id as number);
              }}
            />
          ))}
        <Text type="secondary">
          {i18n.translate(`notes.usedNotes`)} {notes.notes?.data?.length} / {availableNotes}
        </Text>
      </div>

      <NoteModal
        isNew={true}
        visible={visible}
        handleOk={handleOk}
        handleCancel={handleCancel}
        breadcrumb={'--'}
        type="note"
        onFinish={onFinish}
        form={form}
      />
    </div>
  );
}

export default NotesPageComponent;
