import { Dispatch } from 'redux';
import { notification } from 'antd';
import { OpinionConstants } from './opinion.constants';

import { OpinionService } from '../../api/api.service';
import { OpinionResponseInterfaceAll, OpinionResponseInterfaceSingle } from '../../interfaces';
import { i18n } from '../../services';

export function getAllOpinionsAction(params?: any, filter?: any, sort?: any, pagination?: any) {
  return (dispatch: Dispatch) => {
    return OpinionService.getAll(params, filter, sort, pagination).subscribe(
      (response: OpinionResponseInterfaceAll) => {
        dispatch({
          type: OpinionConstants.GET_ALL_SUCCESS,
          payload: response,
        });
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });

        dispatch({
          type: OpinionConstants.ERROR,
          payload: error,
        });
      }
    );
  };
}

export function getSingleOpinionAction(id: number) {
  return (dispatch: Dispatch) => {
    return OpinionService.getSingle(id).subscribe(
      (response: OpinionResponseInterfaceSingle) => {
        dispatch({
          type: OpinionConstants.GET_SINGLE_SUCCESS,
          payload: response,
        });
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });

        dispatch({
          type: OpinionConstants.ERROR,
          payload: error,
        });
      }
    );
  };
}

export function createOpinionAction(body: OpinionResponseInterfaceSingle) {
  return (dispatch: Dispatch) => {
    return OpinionService.create(body).subscribe(
      (response: OpinionResponseInterfaceSingle) => {
        notification['success']({ message: i18n.translate('api.successMessage'), duration: 2 });

        dispatch({
          type: OpinionConstants.CREATE_SUCCESS,
          payload: response,
        });
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });

        dispatch({
          type: OpinionConstants.ERROR,
          payload: error,
        });
      }
    );
  };
}

export function updateOpinionAction(body: OpinionResponseInterfaceSingle) {
  return (dispatch: Dispatch) => {
    return OpinionService.update(body).subscribe(
      (response: OpinionResponseInterfaceSingle) => {
        notification['success']({ message: i18n.translate('api.successMessage'), duration: 2 });

        dispatch({
          type: OpinionConstants.UPDATE_SUCCESS,
          payload: response,
        });
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });

        dispatch({
          type: OpinionConstants.ERROR,
          payload: error,
        });
      }
    );
  };
}

export function deleteOpinionAction(id: number) {
  return (dispatch: Dispatch) => {
    return OpinionService.delete(id).subscribe(
      (response: any) => {
        dispatch({
          type: OpinionConstants.DELETE
        });
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });

        dispatch({
          type: OpinionConstants.ERROR,
          payload: error,
        });
      }
    );
  };
}

export function clearOpinionAction() {
  return (dispatch: Dispatch) => {
    dispatch({ type: OpinionConstants.CLEAR_CURRENT });
  };
}

export function clearOpinionListAction() {
  return (dispatch: Dispatch) => {
    dispatch({ type: OpinionConstants.CLEAR_LIST });
  };
}
