import { InfoCircleOutlined, MoreOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Col, Dropdown, Input, InputRef, Menu, Modal, notification, Row, Space, Table } from 'antd';
import type { ColumnsType, ColumnType } from 'antd/es/table';
import type { FilterConfirmProps } from 'antd/es/table/interface';
import moment from 'moment';
import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { forkJoin } from 'rxjs';
import { AdminRoutes } from '../../../../features/admin';
import { FullUserService, LicenceService, LicenceUserService, OrganizationService, OrganizationUnitService } from '../../../api';
import { DisplayDateFormat } from '../../../constants';
import { LicenceEnum } from '../../../enums/licence.enum';
import { SubscriptionTypeEnum } from '../../../enums/subscriptionType.enum';
import {
  FullOrganizationResponseInterfaceAll,
  FullOrganizationUnitResponseInterfaceAll,
  FullUserInterface,
  LicenceInterface,
  LicenceResponseInterfaceAll,
  LicenceUserResponseInterfaceAll
} from '../../../interfaces';
import { NavigationService, i18n } from '../../../services';
import { useDispatch } from 'react-redux';
import { getAllFullUsersAction, updateFullUserAction } from '../../../redux';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const { confirm } = Modal;

interface DataType {
  key: string | number;
  name: string;
  userEmail: string;
  userFootnote: string;
  competition: string;
  companyName: string;
  organizationUnit: string;
  subscriptionModel: string;
  payDate: string;
  dateActivated: string;
  subscriptionStatus: string;
  userStatus: string;
  userLoggedIn: boolean;
  twoFactorActive: boolean;
  print: string;
  printNum: string;
  remark: string;
  lastModified: string;
  licenceId: number | string;
  orgUnitId: number | string;
  orgId: number | string;
  licenceUserId: number | string;
}

type DataIndex = keyof DataType;

interface Props {
  users?: FullUserInterface[];
  filter?: string;
  forwardRef?: any;
}

export default function AdminTableComponent({ users, filter, forwardRef }: Props) {
  const dispatch = useDispatch();
  
  const menu = (userOrgId: number, licenceId: number, licenceUserId: number) => {
    return (
    <Menu
      items={[
        {
          label: (
            <Button
              type="link"
              style={{ color: 'black' }}
              onClick={() => {
                approveLicence(userOrgId, licenceId, licenceUserId);
              }}
            >
              {i18n.translate(`profile.approveLicence`)}
            </Button>
          ),
          key: '0',
        },
        {
          label: (
            <Button
              type="link"
              style={{ color: 'black' }}
              onClick={() => {
                unapproveLicence(userOrgId, licenceId, licenceUserId);
              }}
            >
              {i18n.translate(`profile.unapproveLicence`)}
            </Button>
          ),
          key: '1',
        }
      ]}
    />
  )};

  const [organizationData, setOrganizationData] = useState<FullOrganizationResponseInterfaceAll>();
  const [organizationUnitData, setOrganizationUnitData] = useState<FullOrganizationUnitResponseInterfaceAll>();
  const [licenceData, setLicenceData] = useState<LicenceResponseInterfaceAll>();
  const [licenceUserData, setLicenceUserData] = useState<LicenceUserResponseInterfaceAll>();
  const [selectedUsers, setSelectedUsers] = useState<Array<DataType>>();

  useImperativeHandle(forwardRef, () => ({
    approveLicences() {
      if (!!selectedUsers && selectedUsers?.length > 0) {
        approveLicencesGroup(selectedUsers)
      } else {
        notification['error']({ message: i18n.translate('profile.selectUser'), duration: 2 });
      }
    },
    unapproveLicences() {
      if (!!selectedUsers && selectedUsers?.length > 0) {
        unapproveLicencesGroup(selectedUsers)
      } else {
        notification['error']({ message: i18n.translate('profile.selectUser'), duration: 2 });
      }
    }
  }));

  useEffect(() => {
    OrganizationService.getAll(undefined, undefined, 'sort=-id', `page[offset]=0&page[limit]=999999`).subscribe(
      (response: FullOrganizationResponseInterfaceAll) => {
        setOrganizationData(response);
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    OrganizationUnitService.getAll(undefined, undefined, 'sort=-id', `page[offset]=0&page[limit]=999999`).subscribe(
      (response: FullOrganizationUnitResponseInterfaceAll) => {
        setOrganizationUnitData(response);
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let getLicenceService = (): void => {
    LicenceService.getAll(undefined, undefined, 'sort=id', `page[offset]=0&page[limit]=999999`).subscribe(
      (response: LicenceResponseInterfaceAll) => {
        setLicenceData(response);
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
      }
    );
  }

  useEffect(() => {
    getLicenceService();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    LicenceUserService.getAll(undefined, undefined, 'sort=id', `page[offset]=0&page[limit]=999999`).subscribe(
      (response: LicenceUserResponseInterfaceAll) => {
        setLicenceUserData(response);
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getFullInfo = () => {
    const fullInfo = users?.map((user) => {
      const orgUnit = organizationUnitData?.data?.find(
        (unit) => unit?.id == user?.relationships?.organizationUnit?.data?.id
      );
      const org = organizationData?.data?.find((org) => org?.id == orgUnit?.relationships?.organization?.data?.id);
      let licUsr = licenceUserData?.data?.find((licUsr) => licUsr?.relationships?.user?.data?.id == user.id);
      let lic = licenceData?.data?.find((lic) => lic?.id === licUsr?.relationships?.licence?.data?.id);
      let licWithPaymentDate = licenceData?.data?.find((lic) => lic?.id === licUsr?.relationships?.licence?.data?.id && lic.attributes?.paymentDate);
      let validLic: LicenceInterface[] = [];
      licenceData?.data?.forEach((licence) => {
        if (
          licence.relationships?.organizationUnit?.data?.id == orgUnit?.id &&
          licence.attributes?.subscriptionType !== SubscriptionTypeEnum.NESTED_SUBSCRIPTION &&
          !licence.relationships?.licenceParent?.data
        ) {
          lic = licence;
        }
        if (
          licence.relationships?.organizationUnit?.data?.id == orgUnit?.id &&
          licence.attributes?.subscriptionType !== SubscriptionTypeEnum.NESTED_SUBSCRIPTION &&
          !licence.relationships?.licenceParent?.data &&
          licence.attributes?.paymentDate
        ) {
          //licWithPaymentDate = licence;
        }
        if (
          licence.relationships?.organizationUnit?.data?.id == orgUnit?.id &&
          licence.attributes?.subscriptionType !== SubscriptionTypeEnum.NESTED_SUBSCRIPTION &&
          !licence.relationships?.licenceParent?.data &&
          licence.attributes?.active &&
          moment().isBefore(moment(licence?.attributes?.to).startOf('day'))
        ) {
          validLic.push(licence)
        }
      });
      if (lic || licWithPaymentDate) {
        const finalLicence = licWithPaymentDate ? licWithPaymentDate : lic;
        licUsr = licenceUserData?.data?.find((licUsr) => licUsr?.relationships?.licence?.data?.id == finalLicence?.id);
      }

      return {
        id: user.id,
        ...user.attributes,
        organization: org,
        organizationUnit: orgUnit,
        licence: licWithPaymentDate ? licWithPaymentDate : lic,
        licenceUser: licUsr,
        validLicences: validLic
      };
    });

    return fullInfo;
  };

  const data: DataType[] = getFullInfo()?.map((user) => {

    const getStrongestLicence = () => {
      let string = i18n.t('common.licenceType.FREE');

      if (user.validLicences?.find(x => x.attributes?.licenceType === LicenceEnum.STANDARD)) string = i18n.t('common.licenceType.STANDARD');

      if (user.validLicences?.find(x => x.attributes?.licenceType === LicenceEnum.PREMIUM)) string = i18n.t('common.licenceType.PREMIUM');

      return string;
    }

    return {
      key: user?.id,
      name: user?.name,
      userEmail: user?.email,
      userFootnote: user?.footnote,
      competition: user.competition ? 'Da' : 'Ne',
      companyName: user?.organization?.attributes?.name,
      organizationUnit: user?.organizationUnit?.attributes?.name,
      subscriptionModel: getStrongestLicence(),
      payDate: user?.licence?.attributes?.paymentDate ? moment(user?.licence?.attributes?.paymentDate).format(DisplayDateFormat) : '—', //payedDate?
      dateActivated: moment(user?.licenceUser?.attributes?.createdDate).format(DisplayDateFormat),
      subscriptionStatus: user?.licence?.attributes?.active ? i18n.translate('profile.subModels.paid') : i18n.translate('profile.subModels.notPaid'),
      userStatus: user?.licence?.attributes?.active ? 'Aktivna' : 'Neaktivna',
      userLoggedIn: user?.active ? true : false,
      twoFactorActive: user?.active2fa,
      print: user?.validLicences?.some(x => x.attributes?.biltenSubscription) ? 'Da' : 'Ne',
      printNum: user?.validLicences?.reduce((partialSum, a) => partialSum + a.attributes?.printedEditionsNum, 0).toString(),
      remark: user?.adminFootnote ? user?.adminFootnote : '—',
      lastModified: user?.modifiedDate ? moment(user?.modifiedDate).format(DisplayDateFormat) : '—',
      licenceId: user?.licence?.id,
      orgUnitId: user?.organizationUnit?.id,
      orgId: user?.organization?.id,
      licenceUserId: user?.licenceUser?.id,
    };
  }) as DataType[];

  const getLicenceName = (model: string) => {
    switch (model) {
      case i18n.t('common.licenceType.FREE'):
        return 'FREE';
      case i18n.t('common.licenceType.STANDARD'):
        return 'STANDARD';
      case i18n.t('common.licenceType.PREMIUM'):
        return 'PREMIUM';
      default:
        return 'FREE';
    }
  }

  const filterData = () => {
    return data?.filter((x) => getLicenceName(x.subscriptionModel) == filter);
  };

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef<InputRef>(null);

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
      setSelectedUsers(selectedRows)
    },
    getCheckboxProps: (record: DataType) => ({
      disabled: record.companyName === 'Disabled User', // Column configuration not to be checked
      name: record.companyName,
    }),
  };

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex
  ) => {
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
    confirm();
  };

  const handleReset = (clearFilters: () => void, confirm: (param?: FilterConfirmProps) => void) => {
    clearFilters();
    setSearchText('');
    confirm();
  };

  const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<DataType> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Pretraži...`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
          >
            {i18n.t('genericButtons.search')}
          </Button>
          <Button onClick={() => clearFilters && handleReset(clearFilters, confirm)} size="small">
            {i18n.t('genericButtons.clear')}
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex] !== undefined && record[dataIndex] !== null &&
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
  });

  function changeActiveStatus(userId: number, email: string, footnote: string) {
    let userData: any = {
      data: {
        type: 'user',
        id: userId,
        attributes: {
          active: false,
          newEmailFootnote: {
            email: email,
            footnote: footnote,
          }
        }
      },
    };

    FullUserService.update(userData).subscribe(
      (response: any) => {
        notification['success']({ message: i18n.translate('common.userLoggedOutSuccess'), duration: 2 });
        dispatch(getAllFullUsersAction(undefined, undefined, 'sort=-id', `page[offset]=0&page[limit]=999999`));
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
      }
    );
  }

  const showConfirm = (record: any) => {
    confirm({
      title: i18n.t('common.userLogout'),
      icon: <ExclamationCircleOutlined />,
      //content: i18n.t('common.cantUndo'),
      okText: i18n.t('genericButtons.yes'),
      cancelText: i18n.t('genericButtons.cancel'),
      onOk() {
        changeActiveStatus(parseInt(record.key), record.userEmail, record.userFootnote)
      }
    });
  };

  const approveLicence = (orgUnitId: number, licenceId: number, licenceUserId: number) => {
    LicenceService.approveSpecificLicence(orgUnitId, licenceId).subscribe(
      (responseLicence: any) => {
        notification['success']({ message: i18n.translate('api.successMessage'), duration: 2 });
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
      }
    );
  };

  const unapproveLicence = (orgUnitId: number, licenceId: number, licenceUserId: number) => {
    LicenceService.disapproveSpecificLicence(orgUnitId, licenceId).subscribe(
      (responseLicence: any) => {
        if (responseLicence === 'SUCCESSFUL') {
          const body = {
            data: {
              type: 'licence_user',
              id: licenceUserId,
              attributes: {
                active: false
              }
            },
          };
          LicenceUserService.update(body).subscribe(
            (response: any) => {
              notification['success']({ message: i18n.translate('api.successMessage'), duration: 2 });
              getLicenceService()
            },
            (error: Error) => {
              notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
            }
          );
        }
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
      }
    );
  };

  const approveLicencesGroup = (selected: Array<DataType>) => {
    let forkJoinData: any = {};
    for (let e of selected) {
      forkJoinData[e.key] = LicenceService.approveSpecificLicence(parseInt(e.orgUnitId.toString()), parseInt(e.licenceId.toString()))
    }

    forkJoin(forkJoinData).subscribe({
      next: (data: any) => {
        let forkJoinData2: any = {};
        for (let key in forkJoinData) {
          if (data[key] === 'SUCCESSFUL') {
            const body = {
              data: {
                type: 'licence_user',
                id: selected.find((e: DataType) => e.key === key)?.licenceUserId,
                attributes: {
                  active: true
                }
              },
            };
            forkJoinData2[key] = LicenceUserService.update(body);
          }
        }
        forkJoin(forkJoinData2).subscribe({
          next: (responseUser: any) => {
            notification['success']({ message: i18n.translate('api.successMessage'), duration: 2 });
            getLicenceService()
          },
          error: (error: Error) => {
            notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
          }
        })
      },
      error: (error: any) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
      }
    })
  };

  /**
  const approveLicencesGroup = (selected: Array<DataType>) => {
    let forkJoinData: any = {};
    for (let e of selected) {
      forkJoinData[e.key] = LicenceService.approveSpecificLicence(parseInt(e.orgUnitId.toString()), parseInt(e.licenceId.toString()))
    }

    forkJoin(forkJoinData).subscribe({
      next: (data: any) => {
        notification['success']({ message: i18n.translate('api.successMessage'), duration: 2 });
        getLicenceService()
      },
      error: (error: any) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
      }
    })
  };
  */

  const unapproveLicencesGroup = (selected: Array<DataType>) => {
    let forkJoinData: any = {};
    for (let e of selected) {
      forkJoinData[e.key] = LicenceService.disapproveSpecificLicence(parseInt(e.orgUnitId.toString()), parseInt(e.licenceId.toString()))
    }

    forkJoin(forkJoinData).subscribe({
      next: (data: any) => {
        let forkJoinData2: any = {};
        for (let key in forkJoinData) {
          if (data[key] === 'SUCCESSFUL') {
            const body = {
              data: {
                type: 'licence_user',
                id: selected.find((e: DataType) => e.key === key)?.licenceUserId,
                attributes: {
                  active: false
                }
              },
            };
            forkJoinData2[key] = LicenceUserService.update(body);
          }
        }
        forkJoin(forkJoinData2).subscribe({
          next: (responseUser: any) => {
            notification['success']({ message: i18n.translate('api.successMessage'), duration: 2 });
            getLicenceService()
          },
          error: (error: Error) => {
            notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
          }
        })
      },
      error: (error: any) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
      }
    })
  };

  const columns: ColumnsType<DataType> = [
    {
      title: i18n.translate(`profile.basicInfo.name`),
      dataIndex: 'name',
      key: 'name',
      width: '190px',
      fixed: 'left',
      ...getColumnSearchProps('name'),
      render: (text, record, index) => (
        <Link
          style={{ textDecoration: 'underline', color: 'blue' }}
          to={AdminRoutes.USER_PROFILE.fullPath + `/${record.key}`}
          key={AdminRoutes.USER_PROFILE.path + `/${record.key}`}
        >
          {text}
        </Link>
      ),
    },
    {
      title: i18n.translate(`profile.basicInfo.competitionShort`),
      dataIndex: 'competition',
      key: 'competition',
      width: '130px',
    },
    {
      title: i18n.translate(`profile.basicInfo.companyOrg`),
      dataIndex: 'companyName',
      key: 'companyName',
      width: '185px',
      ...getColumnSearchProps('companyName'),
      render: (text, record, index) => (
        // <Link
        //   style={{ textDecoration: 'underline', color: 'blue' }}
        //   to={AdminRoutes.ORGANIZATION_PROFILE.fullPath + `/${record.orgId}`}
        //   key={AdminRoutes.ORGANIZATION_PROFILE.path + `/${record.orgId}`}
        // >
          <span>{text}</span>
        //</Link>
      ),
    },
    /**
    {
      title: i18n.translate(`profile.basicInfo.groupName`),
      dataIndex: 'organizationUnit',
      key: 'organizationUnit',
      width: '185px',
      ...getColumnSearchProps('organizationUnit'),
      render: (text, record, index) => (
        // <Link
        //   style={{ textDecoration: 'underline', color: 'blue' }}
        //   to={AdminRoutes.ORGANIZATION_UNIT_PROFILE.fullPath + `/${record.orgUnitId}`}
        //   key={AdminRoutes.ORGANIZATION_UNIT_PROFILE.path + `/${record.orgUnitId}`}
        // >
          <span>{text}</span>
        //</Link>
      ),
    },
    */
    {
      title: i18n.translate(`profile.subModels.subModel`),
      dataIndex: 'subscriptionModel',
      key: 'subscriptionModel',
      width: '160px',
    },
    {
      title: i18n.translate(`profile.subModels.payDate`),
      dataIndex: 'payDate',
      key: 'payDate',
      width: '140px',
    },
    {
      title: i18n.translate(`profile.subModels.activationDate`),
      dataIndex: 'dateActivated',
      key: 'dateActivated',
      width: '200px',
    },
    {
      title: i18n.translate(`profile.subModels.subStatus`),
      dataIndex: 'subscriptionStatus',
      key: 'subscriptionStatus',
      width: '140px',
    },
    {
      title: i18n.translate(`profile.subModels.status`),
      dataIndex: 'userStatus',
      key: 'userStatus',
      width: '200px',
    },
    {
      title: i18n.translate(`profile.subModels.print`),
      dataIndex: 'print',
      key: 'print',
      width: '140px',
    },
    {
      title: i18n.translate(`profile.basicInfo.printNum`),
      dataIndex: 'printNum',
      key: 'printNum',
      width: '140px',
    },
    {
      title: i18n.translate(`profile.basicInfo.temporisRemark`),
      dataIndex: 'remark',
      key: 'remark',
      width: '200px',
      ...getColumnSearchProps('remark'),
    },
    {
      title: i18n.translate(`profile.basicInfo.lastModified`),
      dataIndex: 'lastModified',
      key: 'lastModified',
      width: '150px',
      //defaultSortOrder: 'descend',
      sorter: (a, b) => moment(a.lastModified, DisplayDateFormat).valueOf() - moment(b.lastModified, DisplayDateFormat).valueOf(),
    },
    {
      title: i18n.translate(`profile.basicInfo.loggedIn`),
      dataIndex: 'userLoggedIn',
      key: 'userLoggedIn',
      width: '100px',
      render: (text, record, index) => {
        if (record.userLoggedIn) return (<Button danger type="primary" onClick={() => showConfirm(record)}>Odjavi</Button>);
        return(<span>Ne</span>);
      },
    },
    {
      title: '2FA',
      dataIndex: 'twoFactorActive',
      key: 'twoFactorActive',
      width: '100px',
      render: (text, record, index) => {
        if (record.twoFactorActive) return (<span>Da</span>);
        return(<span>Ne</span>);
      },
    },
    {
      title: i18n.translate(`profile.actions`),
      dataIndex: 'actions',
      key: 'actions',
      width: '80px',
      render: (text, record, index) => (
        <Row>
          <Col>
            <Dropdown
              overlay={menu(
                parseInt(record.orgUnitId?.toString()),
                parseInt(record.licenceId?.toString()),
                parseInt(record.licenceUserId?.toString())
              )}
              trigger={['click']}
            >
              <a onClick={(e) => e.preventDefault()}>
                <Space>
                  <Button type="link" icon={<MoreOutlined />} />
                </Space>
              </a>
            </Dropdown>
          </Col>
          {/**<Col>
            <Button type="link" icon={<InfoCircleOutlined />} />
          </Col>*/}
        </Row>
      ),
    },
  ];
  return (
    <Table
      scroll={{ x: 1500, y: 600 }}
      rowSelection={{
        type: 'checkbox',
        ...rowSelection,
      }}
      pagination={false}
      columns={columns}
      dataSource={!filter ? data : filterData()}
      locale={{ 
        emptyText: i18n.t('common.noData'),
        triggerDesc: i18n.t('common.sortDesc'),
        triggerAsc: i18n.t('common.sortAsc'),
        cancelSort: i18n.t('common.cancelSort')
      }}
    />
  );
}
