import { FullUserConstants } from './fullUser.constants';
import { FullUserInterface, FullUserResponseInterfaceAll, FullUserResponseInterfaceSingle, ReducerActionInterface } from '../../interfaces';

// ============ INTERFACE ============

export interface FullUserStateInterface {
  currentFullUser?: FullUserInterface;
  fullUsers?: FullUserResponseInterfaceAll;
  error?: string;
}

// ============ INIT STATE ============

const initialState: FullUserStateInterface = {
  currentFullUser: undefined,
  fullUsers: {
    data: [],
    meta: {
      totalResourceCount: 0
    }
  }
}

// ============ REDUCERS ============

const getSingleSuccess = (state: FullUserStateInterface, payload: FullUserResponseInterfaceSingle): FullUserStateInterface => {
  return {
    ...state,
    currentFullUser: payload.data,
  };
};

const getAllSuccess = (state: FullUserStateInterface, payload: FullUserResponseInterfaceAll): FullUserStateInterface => {
  return {
    ...state,
    fullUsers: {
      data: payload.data,
      meta: payload.meta
    }
  };
};

const createSuccess = (state: FullUserStateInterface, payload: FullUserResponseInterfaceSingle): FullUserStateInterface => {
  return {
    ...state,
    currentFullUser: payload.data,
  };
};

const updateSuccess = (state: FullUserStateInterface, payload: FullUserResponseInterfaceSingle): FullUserStateInterface => {
  return {
    ...state,
    currentFullUser: payload.data,
  };
};

const clearCurrent = (state: FullUserStateInterface): FullUserStateInterface => {
  return {
    ...state,
    currentFullUser: undefined,
  };
};

const clearList = (state: FullUserStateInterface): FullUserStateInterface => {
  return {
    ...state,
    fullUsers: {
      data: [],
      meta: {
        totalResourceCount: 0
      }
    }
  };
};

const deleteSuccess = (state: FullUserStateInterface): FullUserStateInterface => {
  return {
    ...state,
    currentFullUser: undefined,
  };
};

const error = (state: FullUserStateInterface, payload: string) => {
  return {
    ...state,
    error: payload,
  };
};


// ============ EXPORTS ============

export const FullUserReducer = (state = initialState, action: ReducerActionInterface) => {
  switch (action.type) {
    case FullUserConstants.GET_SINGLE_SUCCESS:
      return getSingleSuccess(state, action.payload);
    case FullUserConstants.GET_ALL_SUCCESS:
      return getAllSuccess(state, action.payload);
    case FullUserConstants.CREATE_SUCCESS:
      return createSuccess(state, action.payload);
    case FullUserConstants.UPDATE_SUCCESS:
      return updateSuccess(state, action.payload);
    case FullUserConstants.CLEAR_CURRENT:
      return clearCurrent(state);
    case FullUserConstants.CLEAR_LIST:
      return clearList(state);
    case FullUserConstants.DELETE:
      return deleteSuccess(state);
    case FullUserConstants.ERROR:
      return error(state, action.payload);
    default:
      return state;
  }
};
