export enum SynopsisConstants {
  SET_LOADER = 'SynopsisConstants_SET_LOADER',
  GET_ALL_SUCCESS = 'SynopsisConstants_GET_ALL_SUCCESS',
  GET_SINGLE_SUCCESS = 'SynopsisConstants_GET_SINGLE_SUCCESS',
  CREATE_SUCCESS = 'SynopsisConstants_CREATE_SUCCESS',
  UPDATE_SUCCESS = 'SynopsisConstants_UPDATE_SUCCESS',
  CLEAR_CURRENT = 'SynopsisConstants_CLEAR_CURRENT',
  CLEAR_LIST = 'SynopsisConstants_CLEAR_LIST',
  DELETE = 'SynopsisConstants_DELETE',
  ERROR = 'SynopsisConstants_ERROR'
}
