import { OrganizationUnitConstants } from './organizationUnit.constants';
import { FullOrganizationUnitInterface, FullOrganizationUnitResponseInterfaceAll, FullOrganizationUnitResponseInterfaceSingle, ReducerActionInterface } from '../../interfaces';

// ============ INTERFACE ============

export interface FullOrganizationUnitStateInterface {
  currentOrganizationUnit?: FullOrganizationUnitInterface;
  organizationUnits?: FullOrganizationUnitResponseInterfaceAll;
  error?: string;
}

// ============ INIT STATE ============

const initialState: FullOrganizationUnitStateInterface = {
  currentOrganizationUnit: undefined,
  organizationUnits: {
    data: [],
    included: [],
    meta: {
      totalResourceCount: 0
    }
  }
}

// ============ REDUCERS ============

const getSingleSuccess = (state: FullOrganizationUnitStateInterface, payload: FullOrganizationUnitResponseInterfaceSingle): FullOrganizationUnitStateInterface => {
  return {
    ...state,
    currentOrganizationUnit: payload.data,
  };
};

const getAllSuccess = (state: FullOrganizationUnitStateInterface, payload: FullOrganizationUnitResponseInterfaceAll): FullOrganizationUnitStateInterface => {
  return {
    ...state,
    organizationUnits: {
      data: payload.data,
      included: payload.included,
      meta: payload.meta
    }
  };
};

const createSuccess = (state: FullOrganizationUnitStateInterface, payload: FullOrganizationUnitResponseInterfaceSingle): FullOrganizationUnitStateInterface => {
  return {
    ...state,
    currentOrganizationUnit: payload.data,
  };
};

const updateSuccess = (state: FullOrganizationUnitStateInterface, payload: FullOrganizationUnitResponseInterfaceSingle): FullOrganizationUnitStateInterface => {
  return {
    ...state,
    currentOrganizationUnit: payload.data,
  };
};

const clearCurrent = (state: FullOrganizationUnitStateInterface): FullOrganizationUnitStateInterface => {
  return {
    ...state,
    currentOrganizationUnit: undefined,
  };
};

const clearList = (state: FullOrganizationUnitStateInterface): FullOrganizationUnitStateInterface => {
  return {
    ...state,
    organizationUnits: {
      data: [],
      included: [],
      meta: {
        totalResourceCount: 0
      }
    }
  };
};

const deleteSuccess = (state: FullOrganizationUnitStateInterface): FullOrganizationUnitStateInterface => {
  return {
    ...state,
    currentOrganizationUnit: undefined,
  };
};

const error = (state: FullOrganizationUnitStateInterface, payload: string) => {
  return {
    ...state,
    error: payload,
  };
};

// ============ EXPORTS ============

export const OrganizationUnitReducer = (state = initialState, action: ReducerActionInterface) => {
  switch (action.type) {
    case OrganizationUnitConstants.GET_SINGLE_SUCCESS:
      return getSingleSuccess(state, action.payload);
    case OrganizationUnitConstants.GET_ALL_SUCCESS:
      return getAllSuccess(state, action.payload);
    case OrganizationUnitConstants.CREATE_SUCCESS:
      return createSuccess(state, action.payload);
    case OrganizationUnitConstants.UPDATE_SUCCESS:
      return updateSuccess(state, action.payload);
    case OrganizationUnitConstants.CLEAR_CURRENT:
      return clearCurrent(state);
    case OrganizationUnitConstants.CLEAR_LIST:
      return clearList(state);
    case OrganizationUnitConstants.DELETE:
      return deleteSuccess(state);
    case OrganizationUnitConstants.ERROR:
      return error(state, action.payload);
    default:
      return state;
  }
};
