import { NotesConstants } from './notes.constants';
import { NoteInterface, NoteResponseInterfaceSingle, NoteResponseInterfaceAll, ReducerActionInterface } from '../../interfaces';

// ============ INTERFACE ============

export interface NoteStateInterface {
  currentNote?: NoteInterface;
  notes?: NoteResponseInterfaceAll;
  error?: string;
  isLoading?: boolean;
}

// ============ INIT STATE ============

const initialState: NoteStateInterface = {
  currentNote: undefined,
  isLoading: false,
  notes: {
    data: [],
    meta: {
      totalResourceCount: 0
    }
  }
}

// ============ REDUCERS ============

const setIsLoading = (state: NoteStateInterface, payload: boolean): NoteStateInterface => {
  return {
    ...state,
    isLoading: payload
  };
};

const getSingleSuccess = (state: NoteStateInterface, payload: NoteResponseInterfaceSingle): NoteStateInterface => {
  return {
    ...state,
    currentNote: payload.data,
  };
};

const getAllSuccess = (state: NoteStateInterface, payload: NoteResponseInterfaceAll): NoteStateInterface => {
  return {
    ...state,
    isLoading: false,
    notes: {
      data: payload.data,
      meta: payload.meta
    }
  };
};

const createSuccess = (state: NoteStateInterface, payload: NoteResponseInterfaceSingle): NoteStateInterface => {
  return {
    ...state,
    currentNote: payload.data,
    notes: {
      ...state.notes!,
      data: [ ...state.notes!.data, payload.data ],
      meta: {
        totalResourceCount: state.notes!.meta.totalResourceCount + 1
      }
    }
  };
};

const updateSuccess = (state: NoteStateInterface, payload: NoteResponseInterfaceSingle): NoteStateInterface => {
  return {
    ...state,
    currentNote: payload.data,
  };
};

const clearCurrent = (state: NoteStateInterface): NoteStateInterface => {
  return {
    ...state,
    currentNote: undefined,
  };
};

const error = (state: NoteStateInterface, payload: string) => {
  return {
    ...state,
    error: payload,
  };
};

// ============ EXPORTS ============

export const NotesReducer = (state = initialState, action: ReducerActionInterface) => {
  switch (action.type) {
    case NotesConstants.SET_IS_LOADING:
      return setIsLoading(state, action.payload);
    case NotesConstants.GET_SINGLE_SUCCESS:
      return getSingleSuccess(state, action.payload);
    case NotesConstants.GET_ALL_SUCCESS:
      return getAllSuccess(state, action.payload);
    case NotesConstants.CREATE_SUCCESS:
      return createSuccess(state, action.payload);
    case NotesConstants.UPDATE_SUCCESS:
      return updateSuccess(state, action.payload);
    case NotesConstants.CLEAR_CURRENT:
      return clearCurrent(state);
    case NotesConstants.ERROR:
      return error(state, action.payload);
    default:
      return state;
  }
};
