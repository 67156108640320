import { Button, Col, Divider, Row, Typography } from 'antd';
import React from 'react';
import { ExclamationCircleFilled } from '@ant-design/icons';

const { Text, Title } = Typography;

export default function NotificationComponent(props: { read: boolean }) {
  return (
    <div className="notif">
      <Row className="mainRow">
        <Col flex={0}>{props.read ? <ExclamationCircleFilled className="icon" /> : null}</Col>
        <Col flex={4}>
          <Row>
            <Col flex={4}>
              <Title level={5}>Title text</Title>
            </Col>
            <Col flex={0}>
              <Button className="cardBtn" type="link">
                More
              </Button>
            </Col>
          </Row>
          <Row>
            <Text>Notification text notification text notification text notification text notification text</Text>
          </Row>
          <Row>
            <Text type="secondary">X days ago</Text>
          </Row>
        </Col>
      </Row>
      <Divider />
    </div>
  );
}
