import { Form, Input, Modal } from 'antd';
import React from 'react';
import { i18n } from '../../../services';

interface Props {
  visible: boolean;
  handleOk: any;
  handleCancel: any;
}

export default function Login2faModal({
  visible,
  handleOk,
  handleCancel
}: Props) {
  const [form] = Form.useForm();

  const onFinish = () => {
    form.validateFields()
    .then((values) => {
      handleOk && handleOk(values);
      form.resetFields();
    })
    .catch((error) => {
      console.log(error);
    });
  };

  return (
    <div>
      <Form form={form} onFinish={onFinish} layout="vertical">
        <Modal
          className="login2faModal"
          title={i18n.translate('login2fa.title')}
          closable={false}
          visible={visible}
          onOk={onFinish}
          onCancel={() => { form.resetFields(); handleCancel() }}
          cancelText={i18n.translate('genericButtons.cancel')}
          width={705}
        >
          <div className='mb32 white-space'>
            {i18n.translate('login2fa.text1')}
            &nbsp;
            <a style={{color: "#1210A9"}} href="mailto:aplikacija@bjn-mail.biltenjavnenabave.hr">aplikacija@bjn-mail.biltenjavnenabave.hr</a>
            {i18n.translate('login2fa.text2')}
          </div>
          <Form.Item required name="code" rules={[{ required: true, message: i18n.translate(`genericFormMsg.required`) }]}
          >
            <Input autoComplete='off' size='large' style={{ borderRadius: 0, fontWeight: 'bold', textAlign: 'center' }} />
          </Form.Item>
        </Modal>
      </Form>
    </div>
  );
}
