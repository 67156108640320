import React, { useState } from 'react';
import { i18n } from '../../../common';
import { Tabs } from 'antd';
import LogsComponent from './tabs/logs.component';
import UserStatisticsComponent from './tabs/userStatistics.component';

function StatisticsPageComponent() {
  const [selectedTab, setSelectedTab] = useState('1');
  
  return (
    <div className="statistics-page w100-h100">
      <div className="title">{i18n.translate('statistics.title')}</div>

      <div className='statistics'>
        <Tabs activeKey={selectedTab} onChange={(activeKey) => setSelectedTab(activeKey)} destroyInactiveTabPane>
          <Tabs.TabPane tab={i18n.translate('statistics.log')} key="1">
            <LogsComponent />
          </Tabs.TabPane>
          <Tabs.TabPane tab={i18n.translate('statistics.userStatistics')} key="2">
            <UserStatisticsComponent />
          </Tabs.TabPane>
        </Tabs>
      </div>
    </div>
  );
}

export default StatisticsPageComponent;
