import React from 'react';
import Icon, {
  HomeOutlined,
  ReadOutlined,
  QuestionCircleOutlined,
  BankOutlined,
  FileTextOutlined,
  AuditOutlined,
  PartitionOutlined
} from '@ant-design/icons';
import Hammer from '../../../assets/images/hammer.svg'
import Scale from '../../../assets/images/scale.svg'

const HammerIcon = () => (<img alt='' src={Hammer}/>);
const ScaleIcon = () => (<img alt='' src={Scale}/>);

export const ROOT_PATH_APP = 'app';

export const AppRoutes = {
  DASHBOARD:                      { path: 'dashboard',                      fullPath: `/${ROOT_PATH_APP}/dashboard`,              icon: <HomeOutlined />,           group: 'home', hide: false},
  NEWSLETTERS:                    { path: 'newsletters',                    fullPath: `/${ROOT_PATH_APP}/newsletters`,            icon: <ReadOutlined />,           group: 'home', hide: false},
  NEWSLETTERS_VIEW:               { path: 'newsletters/view',               fullPath: `/${ROOT_PATH_APP}/newsletters/view`,       icon: null,                           group: null,                       hide: true},
  FAQ:                            { path: 'faq',                            fullPath: `/${ROOT_PATH_APP}/faq`,                    icon: <QuestionCircleOutlined />, group: 'web-of-knowledge', hide: false},
  INSTITUTIONAL_OPINIONS:         { path: 'institutional-opinions',         fullPath: `/${ROOT_PATH_APP}/institutional-opinions`, icon: <BankOutlined />,           group: 'web-of-knowledge', hide: false},
  EXPERT_ARTICLES:                { path: 'expert-articles',                fullPath: `/${ROOT_PATH_APP}/expert-articles`,        icon: <FileTextOutlined />,       group: 'web-of-knowledge', hide: false},
  REGULATIONS:                    { path: 'regulations',                    fullPath: `/${ROOT_PATH_APP}/regulations`,            icon: <AuditOutlined />,          group: 'web-of-knowledge', hide: false},
  WORKFLOWS:                      { path: 'workflows',                      fullPath: `/${ROOT_PATH_APP}/workflows`,              icon: <PartitionOutlined />,      group: 'web-of-knowledge', hide: false},
  EU_PRACTICE:                    { path: 'eu-practice',                    fullPath: `/${ROOT_PATH_APP}/eu-practice`,            icon: <Icon component={ScaleIcon}/>,      group: 'legal-protection-eu', hide: false},
  DKOM_DECISIONS:                 { path: 'dkom-decisions',                 fullPath: `/${ROOT_PATH_APP}/dkom-decisions`,         icon: null,                       group: 'legal-protection-sub-cro',hide: false},
  VUS_DECLARATIONS:               { path: 'vus-declarations',               fullPath: `/${ROOT_PATH_APP}/vus-declarations`,       icon: null,                       group: 'legal-protection-sub-cro', hide: false},
  RH_PRACTICE:                    { path: 'rh-practice',                    fullPath: `/${ROOT_PATH_APP}/rh-practice`,            icon: <Icon component={HammerIcon}/>,      group: 'legal-protection-cro', hide: false},
  EU_COURT_DECLARATIONS:          { path: 'eu-court-declarations',          fullPath: `/${ROOT_PATH_APP}/eu-court-declarations`,  icon: null,                       group: 'legal-protection-sub-eu', hide: false},
  PRO_MATERIALS:                  { path: 'professional-materials',         fullPath: `/${ROOT_PATH_APP}/professional-materials`, icon: null,                       group: 'legal-protection-sub-eu', hide: false},
  NOTES:                          { path: 'notes',                          fullPath: `/${ROOT_PATH_APP}/notes`,                  icon: null,                       group: null,                       hide: false},
  USER_PROFILE:                   { path: 'profile',                        fullPath: `/${ROOT_PATH_APP}/profile`,                icon: null,                       group: null,                       hide: false},
  INSTRUCTIONS:                   { path: 'instructions',                   fullPath: `/${ROOT_PATH_APP}/instructions`,           icon: null,                       group: null,                       hide: false},
};
