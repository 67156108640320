import { HomeOutlined } from '@ant-design/icons';
import { Breadcrumb, Button, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  clearNoteAction,
  clearRegulationAction,
  clearRegulationListAction,
  FilterComponent,
  getAllRegulationsAction,
  getScreenTagsByTypeAction,
  i18n,
  LicenceInterface,
  LicenceResponseInterfaceSingle,
  LicenceService,
  LicenceUserResponseInterfaceAll,
  LicenceUserService,
  NoDataComponent,
  NoteResponseInterfaceAll,
  NoteService,
  RegulationCardComponent,
  RegulationEsResponseInterface,
  RegulationInterface,
  RegulationService,
  RegulationStateInterface,
  StoreStateInterface,
  TagScreenStateInterface
} from '../../../common';
import { AdminRoutes } from '../_router/admin.routes';

function RegulationsPageComponent() {
  const userAuth = useSelector((state: StoreStateInterface) => state.auth);

  const dispatch = useDispatch();
  const data: RegulationStateInterface = useSelector((state: StoreStateInterface) => state.regulation);
  const tags: TagScreenStateInterface = useSelector((state: StoreStateInterface) => state.tagScreen);

  const [esData, setEsData] = useState<RegulationEsResponseInterface>();

  const [keyword, setKeyword] = useState('');
  const [exactMatch, setExactMatch] = useState<boolean>(true);

  const [esSearch, setEsSearch] = useState(true);

  const [licenceData, setLicenceData] = useState<LicenceInterface>();
  const [noteData, setNoteData] = useState<NoteResponseInterfaceAll>();

  useEffect(() => {
    clearNoteAction();
    NoteService.getAll(userAuth.user?.id, false).subscribe(
      (response: NoteResponseInterfaceAll) => {
        setNoteData(response); 
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
      }
    );
  }, [userAuth?.user?.id]);

  useEffect(() => {
    LicenceUserService.getAll(undefined, `filter[user.id]=${userAuth.user?.id}`, undefined, `page[offset]=0&page[limit]=999999`).subscribe(
      (response: LicenceUserResponseInterfaceAll) => {
        if (response.data?.length && response.data[0]) {
          const licenceId = response?.data[0]?.relationships?.licence?.data?.id;
          if (licenceId) {
            LicenceService.getSingle(licenceId).subscribe(
              (response: LicenceResponseInterfaceSingle) => {
                setLicenceData(response.data);
              }
            );
          }
        }
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    localStorage.removeItem('transferFilters');
    dispatch(clearRegulationAction());
    dispatch(clearRegulationListAction());
    dispatch(getScreenTagsByTypeAction('regulation'));
    //dispatch(getAllRegulationsAction(undefined, undefined, undefined, `page[offset]=0&page[limit]=9999`));
    esSearchFunction(false, {from: 0, size: 9999});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const esSearchFunction = (allOpinions: boolean, pageSorted: {from: number, size: number}, keyword?: string, tagIds?: any) => {
    RegulationService.elasticSearch(allOpinions, pageSorted, exactMatch, keyword, tagIds).subscribe(
      (response: RegulationEsResponseInterface) => {
        setEsData(response);
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
      }
    );
  };

  const refetch = (allValues: any) => {
    //setEsSearch(true);
    esSearchFunction(false, {from: 0, size: 9999}, keyword, allValues);
  };

  const resetSearch = () => {
    setEsData(undefined);
    setKeyword('');
    //setAllOpinions(true);
    //setEsSearch(false);
    //dispatch(getAllRegulationsAction(undefined, undefined, undefined, `page[offset]=0&page[limit]=9999`));
    esSearchFunction(false, {from: 0, size: 9999});
  };

  const keywordChanged = (value: string) => {
    setKeyword(value);
  };

  const exactMatchChanged = (exactMatch: boolean) => {
    setExactMatch(exactMatch);
  };

  const convertDataType = (regulations: RegulationEsResponseInterface["hits"]): RegulationInterface[] => {
    return regulations.map((regulation) => {
      return {
        id: regulation.id,
        type: regulation.index,
        attributes: regulation.source
      };
    })
  };

  return (
    <div className="regulations w100-h100">
      <div className="regulations__breadcrumbs">
        <Breadcrumb>
          <Breadcrumb.Item>
            <HomeOutlined />
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span>{i18n.translate('breadcrumbs.web-of-knowledge')}</span>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span>{i18n.translate('menu.regulations')}</span>
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="regulations__admin">
        <div className="title">{i18n.translate('regulations.title')}</div>
        <Link to={AdminRoutes.REGULATIONS.fullPath + `/new`} key={AdminRoutes.REGULATIONS.path + `/new`}>
          <Button
            className="button"
            type="primary"
            size="large"
            style={{ fontSize: 16, fontWeight: 700, borderRadius: 0 }}
          >
            {i18n.translate('regulations.addButton')}
          </Button>
        </Link>
      </div>
      {tags.tagScreens && tags.tagScreens.length ? (
        <FilterComponent aggregation={esData?.aggregation?.buckets} filterAccess keyword={keyword} onExactMatchChange={exactMatchChanged} filters={tags.tagScreens} noYearLimit onFilterChange={refetch} onFilterRemove={resetSearch} onKeywordChange={keywordChanged} />
      ) : null}
      {esSearch &&
        <>
          {esData?.hits?.length ? (
            <RegulationCardComponent
              regulations={[...(convertDataType(esData?.hits as RegulationEsResponseInterface["hits"]) as RegulationInterface[])]}
              tags={tags.tagScreens}
              notes={noteData}
            />
          ) : (
            <NoDataComponent />
          )}
        </>
      }
      {!esSearch &&
        <>
          {data.regulations?.data?.length ? (
            <RegulationCardComponent
              regulations={[...(data.regulations?.data as RegulationInterface[])]}
              tags={tags.tagScreens}
              notes={noteData}
            />
          ) : (
            <NoDataComponent />
          )}
        </>
      }
    </div>
  );
}

export default RegulationsPageComponent;
