import { Row, Select, SelectProps, Typography } from 'antd';
import React from 'react';
import { i18n } from '../../../../services';
import OrganizationSubscriptionTableComponent from '../../../tables/organizations/orgSubTable/organizationTable.component';

const { Title } = Typography;

const options: SelectProps['options'] = [];
for (let i = 1999; i < 2022; i++) {
  const value = `${i}`;
  options.push({
    label: value,
    value,
  });
}

export default function OrganizationSubscriptionsModelComponent(/**props ? */) {
  return (
    <div className="orgSubModels w100-h100">
      <Row justify="center">
        <Select defaultValue="2022" style={{ width: 120 }} options={options} />
      </Row>
      <Title level={4} className="subTitle">
        {i18n.translate(`organizationInfo.title`)}
      </Title>

      <OrganizationSubscriptionTableComponent />
    </div>
  );
}
