import { SearchOutlined, InfoCircleOutlined, MoreOutlined } from '@ant-design/icons';
import { Col, Dropdown, InputRef, Menu, notification, Row, TableColumnsType, Tag } from 'antd';
import { Button, Input, Space, Table } from 'antd';
import type { ColumnsType, ColumnType } from 'antd/es/table';
import type { FilterConfirmProps } from 'antd/es/table/interface';
import menu from 'antd/lib/menu';
import moment from 'moment';
import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { AdminRoutes } from '../../../../features/admin';
import { LicenceService, LicenceUserService } from '../../../api';
import { DisplayDateFormat } from '../../../constants';
import { UserRoleEnum } from '../../../enums';
import { LicenceEnum } from '../../../enums/licence.enum';
import { ChildrenLicenceInterface, FullOrganizationUnitResponseInterfaceSingle, FullUserInterface, LicenceInterface } from '../../../interfaces';
import { clearFullUserAction, deleteLicenceAction, FullUserStateInterface, getSingleFullUserAction, StoreStateInterface } from '../../../redux';
import { i18n, NavigationService } from '../../../services';

interface Props {
  orgUnitInfo?: FullOrganizationUnitResponseInterfaceSingle;
  unitUsers?: FullUserInterface[];
  userLicences?: LicenceInterface[];
  oldUserLicences?: LicenceInterface[];
  handleApprove?: () => void;
  changeTab?: (tab: string, userId: string) => void;
}

export default function TeamTableComponent({ orgUnitInfo, unitUsers, userLicences, oldUserLicences, handleApprove, changeTab }: Props) {
  const dispatch = useDispatch();
  const userAuth = useSelector((state: StoreStateInterface) => state.auth);

  const location = useLocation();
  const path = location.pathname.split('/');
  const locationUserId = parseInt(path[path.length - 1]);

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef<InputRef>(null);

  const getUserLicences = (userId: number | string): LicenceInterface[] => {
    let foundLicences: LicenceInterface[] = [];
    userLicences?.forEach(licence => {
      if (licence.attributes?.licenceUsers?.find(x => x.user?.id == userId)) {
        foundLicences.push(licence);
      }
    });

    return foundLicences;
  };

  const managerUser = () => {
    return unitUsers?.find(x => x.attributes?.roles === UserRoleEnum.MANAGER);
  };

  const getLicenceYears = (licence: LicenceInterface) => {
    let yearArray: string[] = [];

    if (licence?.attributes?.year) {
      yearArray.push(licence?.attributes?.year);
    }
    if (licence?.attributes?.childrenLicences?.length) {
      licence?.attributes?.childrenLicences?.forEach(child => {
        yearArray.push(child?.year);
      });
    }

    return yearArray?.length ? yearArray?.sort()?.reverse()?.join(', ') : '—';
  };

  const getChildBasicYears = (childLicence: ChildrenLicenceInterface) => {
    let userLicence = childLicence;
    let yearArray = [];
    if (userLicence?.year && userLicence?.licenceType === LicenceEnum.STANDARD) {
      yearArray.push(userLicence?.year);
    }

    return yearArray;
  };

  const getChildPremiumYears = (childLicence: ChildrenLicenceInterface) => {
    let userLicence = childLicence;
    let yearArray = [];
    if (userLicence?.year && userLicence?.licenceType === LicenceEnum.PREMIUM) {
      yearArray.push(userLicence?.year);
    }

    return yearArray;
  };

  const recordExists = (licences: LicenceInterface[]): boolean => {
    const exists = licences?.length ? true : false;

    return exists;
  };

  const releaseLicence = (licenceId: number) => {
    LicenceService.releaseLicence(licenceId).subscribe(
      (responseLicence: any) => {
        notification['success']({ message: i18n.translate('api.successMessage'), duration: 2 });
        handleApprove && handleApprove();
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
      }
    );
  };

  const menu = (userOrgId: number, licenceId: number, userId: number) => (
    <Menu
      items={[
        {
          label: (
            <Button
              type="link"
              style={{ color: 'black' }}
              onClick={() => {
                releaseLicence(licenceId);
              }}
            >
              {i18n.translate(`profile.myTeam.release`)}
            </Button>
          ),
          key: '0',
        },
        {
          label: (
            <Button
              type="link"
              danger
              onClick={() => {
                dispatch(deleteLicenceAction(licenceId as number));
                handleApprove && handleApprove();
              }}
            >
              {i18n.translate(`profile.myTeam.deleteShort`)}
            </Button>
          ),
          key: '1',
        },
      ]}
    />
  );

  const approveLicence = (orgUnitId: number, licenceId: number, userId: number) => {
    LicenceService.approveSpecificLicence(orgUnitId, licenceId).subscribe(
      (responseLicence: any) => {
        notification['success']({ message: i18n.translate('api.successMessage'), duration: 2 });
        handleApprove && handleApprove();
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
      }
    );
  };

  interface DataType {
    key: string;
    user: string;
    email: string;
    contact: string;
    address: string;
    footnote: string;
    adminFootnote: string;
    competition: string;
    active: string;
  }

  interface ExpandedDataType {
    key: string;
    year: string;
    model: string;
    yearView: string;
    printSub: string;
    expirationDate: string;
    paymentDate: string;
    totalEducations: string;
    usedEducations: string;
    totalQuestions: string;
    usedQuestions: string;
    totalNotes: string;
    active: string;
  }
  
  const data: DataType[] = unitUsers?.map((user, index) => {
    return {
      key: user.id?.toString(),
      user: user?.attributes?.name,
      email: user?.attributes?.email ? user?.attributes?.email : '—',
      contact: user?.attributes?.phoneNumber ? user?.attributes?.phoneNumber : '—',
      address: user?.attributes?.address ? user?.attributes?.address : '—',
      footnote: user?.attributes?.footnote ? user?.attributes?.footnote : '—',
      adminFootnote: user?.attributes?.adminFootnote ? user?.attributes?.adminFootnote : '—',
      competition: user?.attributes?.competition ? 'Da' : 'Ne',
      active: user?.attributes?.active ? 'Da' : 'Ne',
    };
  }) as DataType[];
  
  type DataIndex = keyof DataType;

  const expandedRowRender = (userId: number | string, userLicences: LicenceInterface[]) => {
    const columns: TableColumnsType<ExpandedDataType> = [
      {
        title: 'Godina',
        dataIndex: 'year',
        //width: '25%',
      },
      {
        title: 'Model pretplate',
        dataIndex: 'model',
        //width: '15%',
        render: (text: any, record: any, index: any) => (
          <Row gutter={24}>
            <Col>
              <div onClick={() => changeTab && changeTab('2', userId as string)} style={{ textDecoration: 'underline', color: 'blue', cursor: 'pointer' }}>
                {i18n.translate(`common.licenceType.${text}`)}
              </div>
            </Col>
          </Row>
        ),
      },
      {
        title: 'Pretplatne godine',
        dataIndex: 'yearView',
        //width: '40%',
      },
      {
        title: 'Pretplata na tisak',
        dataIndex: 'printSub',
        //width: '40%',
      },
      {
        title: 'Datum isteka',
        dataIndex: 'expirationDate',
        //width: '40%',
      },
      {
        title: 'Datum plaćanja',
        dataIndex: 'paymentDate',
        //width: '40%',
      },
      {
        title: 'Ukupno predavanja',
        dataIndex: 'totalEducations',
        //width: '40%',
      },
      {
        title: 'Iskorišteno predavanja',
        dataIndex: 'usedEducations',
        //width: '40%',
      },
      {
        title: 'Ukupno pitanja',
        dataIndex: 'totalQuestions',
        //width: '40%',
      },
      {
        title: 'Iskorišteno pitanja',
        dataIndex: 'usedQuestions',
        //width: '40%',
      },
      {
        title: 'Ukupno bilješki',
        dataIndex: 'totalNotes',
        //width: '40%',
      },
      {
        title: 'Aktivan',
        dataIndex: 'active',
        //width: '40%',
      },
      {
        title: i18n.translate(`profile.actions`),
        dataIndex: 'actions',
        key: 'actions',
        width: '130px',
        render: (text, record, index) => (
          userAuth.user?.roles === UserRoleEnum.ADMIN && <Row>
            <Col>
              <Dropdown
                overlay={menu(
                  parseInt(orgUnitInfo?.data?.id?.toString() as string),
                  parseInt(record.key?.toString() as string),
                  parseInt(userId.toString())
                )}
                trigger={['click']}
              >
                <a onClick={(e) => e.preventDefault()}>
                  <Space>
                    <Button type="link" icon={<MoreOutlined />} />
                  </Space>
                </a>
              </Dropdown>
            </Col>
          </Row>
        ),
      },
    ];

    const data: ExpandedDataType[] = userLicences?.map((licence, index) => {
      return {
        key: licence?.id?.toString(),
        year: licence?.attributes?.year,
        model: licence?.attributes?.licenceType,
        yearView: getLicenceYears(licence),
        printSub: licence?.attributes?.biltenSubscription ? 'Da' : 'Ne',
        expirationDate: licence?.attributes?.to ? moment(licence?.attributes?.to).format(DisplayDateFormat) : '—',
        paymentDate: licence?.attributes?.paymentDate ? moment(licence?.attributes?.paymentDate).format(DisplayDateFormat) : '—',
        totalEducations: licence?.attributes?.educationsNum ? licence?.attributes?.educationsNum.toString() : '—',
        usedEducations: licence?.attributes?.usedEducations ? licence?.attributes?.usedEducations.toString() : '—',
        totalQuestions: licence?.attributes?.questionsNum ? licence?.attributes?.questionsNum.toString() : '—',
        usedQuestions: licence?.attributes?.usedQuestions ? licence?.attributes?.usedQuestions.toString() : '—',
        totalNotes: licence?.attributes?.notesNum ? licence?.attributes?.notesNum.toString() : '—',
        active: licence?.attributes?.active ? 'Da' : 'Ne',
      };
    }) as ExpandedDataType[];

    return <Table columns={columns} dataSource={data} pagination={false} scroll={{ x: 1500 }} />;
  };

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<DataType> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Pretraži
          </Button>
          <Button onClick={() => clearFilters && handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Resetiraj
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText((selectedKeys as string[])[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex] !== undefined && record[dataIndex] !== null &&
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
  });

  const columns: ColumnsType<DataType> = [
    {
      title: i18n.translate('profile.basicInfo.name'),
      dataIndex: 'user',
      key: 'user',
      fixed: 'left',
      //width: '150px',
      ...getColumnSearchProps('user'),
      render: (text, record, index) => (
        <Row gutter={24}>
          <Col>
            <div onClick={() => changeTab && changeTab('1', record.key)} style={{ textDecoration: 'underline', color: 'blue', cursor: 'pointer' }}>
              {text}
            </div>
          </Col>
          {/**record.key == managerUser()?.id?.toString() ? <Col>
            <Tag style={{ borderRadius: 0 }} color="green">
              {i18n.translate('profile.subModels.mainUser')}
            </Tag>
          </Col> : null*/}
        </Row>
      ),
    },
    {
      title: 'E-mail',
      dataIndex: 'email',
      key: 'email',
      //width: '250px',
      ...getColumnSearchProps('email'),
    },
    {
      title: i18n.translate('profile.basicInfo.contactNo'),
      dataIndex: 'contact',
      key: 'contact',
      //width: '215px',
    },
    {
      title: i18n.translate('profile.basicInfo.address'),
      dataIndex: 'address',
      key: 'address',
      //width: '215px',
      ...getColumnSearchProps('address'),
    },
    {
      title: i18n.translate('profile.basicInfo.userRemark'),
      dataIndex: 'footnote',
      key: 'footnote',
      //width: '215px',
    },
    {
      title: i18n.translate('profile.basicInfo.temporisRemark'),
      dataIndex: 'adminFootnote',
      key: 'adminFootnote',
      //width: '215px',
    },
    {
      title: i18n.translate('profile.basicInfo.competitionShort'),
      dataIndex: 'competition',
      key: 'competition',
      //width: '215px',
    },
    {
      title: i18n.translate('profile.basicInfo.active'),
      dataIndex: 'active',
      key: 'active',
      //width: '215px',
    },
    {/**
      title: i18n.translate(`profile.actions`),
      dataIndex: 'actions',
      key: 'actions',
      width: '130px',
      render: (text, record, index) => (
        userAuth.user?.roles === UserRoleEnum.ADMIN && <Row>
          <Col>
            <Dropdown
              overlay={menu(
                parseInt(orgUnitInfo?.data?.id?.toString() as string),
                parseInt(getUserLicence(record.key)?.id?.toString() as string),
                parseInt(record.key?.toString())
              )}
              trigger={['click']}
            >
              <a onClick={(e) => e.preventDefault()}>
                <Space>
                  <Button type="link" icon={<MoreOutlined />} />
                </Space>
              </a>
            </Dropdown>
          </Col>
        </Row>
      ),
    */},
  ];
  return <Table pagination={false} columns={columns} dataSource={data} scroll={{ x: 1500 }} expandable={{expandedRowRender: record => expandedRowRender(record.key, getUserLicences(record.key)), rowExpandable: record => recordExists(getUserLicences(parseInt(record.key)))}} />;
}
