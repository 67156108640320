import { Button, Form, Input, Modal, Space, Typography } from 'antd';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { NoteInterface, NoteLinkInterface } from '../../../../interfaces';
import {
  createNoteLinkAction,
  getAllNoteLinksAction, updateNoteLinkAction
} from '../../../../redux';
import { i18n } from '../../../../services';

const { Text } = Typography;

interface Props {
  isNew: boolean;
  visible: boolean;
  handleOk: any;
  handleCancel: any;
  handleChange: any;
  noteLink: NoteLinkInterface;
  note: NoteInterface;
}

export default function NoteLinkModal({ isNew, visible, handleOk, handleCancel, noteLink, note }: Props) {
  const { id } = useParams<{ id: string }>();

  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const onFinish = (values: any) => {
    let newNote: any = {
      data: {
        type: 'notes_links',
        attributes: {
          link: values.linkEdit,
          active: true,
        },
        relationships: {
          notes: {
            data: {
              id: note?.id,
              type: note?.type,
            },
          },
        },
      },
    };

    isNew ? dispatch(createNoteLinkAction(newNote)) : dispatch(updateNoteLinkAction(newNote, noteLink?.id));

    setTimeout(() => {
      dispatch(getAllNoteLinksAction(parseInt(id)));
    }, 1000);

    form.resetFields()
  };

  return (
    <div>
      <Modal
        className="noteLinkModal"
        title={
          <Text strong className="noteLinkModal___title">
            {i18n.translate('notes.modal.title')}
          </Text>
        }
        visible={visible}
        onOk={handleOk}
        onCancel={() => {
          form.resetFields();
          handleCancel();
        }}
        footer={[
          <Button
            key="back"
            onClick={() => {
              form.resetFields();
              handleCancel();
            }}
          >
            {i18n.translate(`genericButtons.cancel`)}
          </Button>,

          <Button key="submit" form="editNoteLink" type="primary" htmlType="submit" onClick={handleOk}>
            {i18n.translate(`genericButtons.save`)}
          </Button>,
        ]}
        width={1000}
      >
        <Space direction="vertical">
          <Form
            preserve={false}
            form={form}
            id="editNoteLink"
            onFinish={onFinish}
            layout="inline"
          >
            <Form.Item
              label={
                isNew ? (
                  <Text strong>{i18n.translate(`notes.noteView.newLink`)}</Text>
                ) : (
                  <Text strong>{i18n.translate(`notes.noteView.editLink`)}</Text>
                )
              }
              name="linkEdit"
              rules={[
                {
                  type: 'url',
                  message: i18n.t(`common.validUrl`),
                },
              ]}
            >
              <Input placeholder={i18n.translate(`notes.noteView.externalContent`)} />
            </Form.Item>
          </Form>
        </Space>
      </Modal>
    </div>
  );
}
