import { Breadcrumb, Collapse, notification, Pagination, PaginationProps, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import {
  clearNoteAction,
  CourtEsResponseInterface,
  CourtInterface,
  CourtService,
  ESFilterInterface,
  EUCourtCardComponent,
  FilterComponent,
  FullOrganizationUnitResponseInterfaceSingle,
  getScreenTagsByTypeAction,
  i18n,
  LicenceInterface,
  LicenceResponseInterfaceAll,
  LicenceResponseInterfaceSingle,
  LicenceService,
  LicenceUserResponseInterfaceAll,
  LicenceUserService,
  NoDataComponent,
  NoteResponseInterfaceAll,
  NoteService,
  OrganizationUnitService,
  StoreStateInterface,
  TagScreenStateInterface,
  YearDividerComponent,
} from '../../../../common';
import { HomeOutlined, CaretRightOutlined } from '@ant-design/icons';
import {
  clearCourtAction,
  clearCourtListAction,
  CourtStateInterface,
  getAllCourtsAction,
} from '../../../../common/redux/courtEU';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { LicenceEnum } from '../../../../common/enums/licence.enum';

const { Text } = Typography;
const { Panel } = Collapse;

function EUCourtDeclarationsPageComponent() {
  const userAuth = useSelector((state: StoreStateInterface) => state.auth);

  const dispatch = useDispatch();

  const courts: CourtStateInterface = useSelector((state: StoreStateInterface) => state.court);
  const tags: TagScreenStateInterface = useSelector((state: StoreStateInterface) => state.tagScreen);

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [filterValues, setFilterValues] = useState<ESFilterInterface | undefined>();

  const [esData, setEsData] = useState<CourtEsResponseInterface>();

  const [keyword, setKeyword] = useState('');
  //const [allOpinions, setAllOpinions] = useState(true);
  const [exactMatch, setExactMatch] = useState<boolean>(true);

  const [esSearch, setEsSearch] = useState(true);

  const [orgUnitInfo, setOrgUnitInfo] = useState<FullOrganizationUnitResponseInterfaceSingle>();
  const [licenceData, setLicenceData] = useState<LicenceInterface>();
  const [allValidLicences, setAllValidLicences] = useState<LicenceInterface[]>([]);
  const [noteData, setNoteData] = useState<NoteResponseInterfaceAll>();

  useEffect(() => {
    clearNoteAction();
    NoteService.getAll(userAuth.user?.id, false).subscribe(
      (response: NoteResponseInterfaceAll) => {
        setNoteData(response); 
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
      }
    );
  }, [userAuth?.user?.id]);


  useEffect(() => {
    if (userAuth.user?.organizationUnit?.id) {
      OrganizationUnitService.getSingle(userAuth.user?.organizationUnit?.id).subscribe(
        (response: FullOrganizationUnitResponseInterfaceSingle) => {
          setOrgUnitInfo(response);
        },
        (error: Error) => {
          notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
        }
      );
    }
  }, [userAuth.user]);

  useEffect(() => {
    if (orgUnitInfo?.data?.id) {
      LicenceService.getAll(undefined, `filter[organizationUnit.id]=${orgUnitInfo?.data?.id}`, 'sort=id', `page[offset]=0&page[limit]=999999`).subscribe(
        (response: LicenceResponseInterfaceAll) => {
          if (response?.data?.length) {
            let validLicences: LicenceInterface[] = [];

            response?.data?.forEach(licence => {
              if (licence.attributes?.licenceUsers?.find((x) => x.user?.id == userAuth.user?.id) && !licence.relationships?.licenceParent?.data && licence.attributes?.notesNum > 0) {
                setLicenceData(licence);
                const standardLicence = response.data.find(
                  (x) => x.attributes?.licenceUsers?.find((y) => y.user?.id == userAuth.user?.id) && !x.relationships?.licenceParent?.data && x.attributes?.active && x.attributes?.licenceType === LicenceEnum.STANDARD && licence.attributes?.notesNum > 0 && moment().isBefore(moment(licence?.attributes?.to).startOf('day'))
                );
                const premiumLicence = response.data.find(
                  (x) => x.attributes?.licenceUsers?.find((y) => y.user?.id == userAuth.user?.id) && !x.relationships?.licenceParent?.data && x.attributes?.active && x.attributes?.licenceType === LicenceEnum.PREMIUM && licence.attributes?.notesNum > 0 && moment().isBefore(moment(licence?.attributes?.to).startOf('day'))
                );
                if (standardLicence && standardLicence.attributes?.notesNum > 0) setLicenceData(standardLicence);
                if (premiumLicence && premiumLicence.attributes?.notesNum > 0) setLicenceData(premiumLicence);
              }
              if (licence.attributes?.licenceUsers?.find(x => x.user?.id == userAuth.user?.id) && !licence.relationships?.licenceParent?.data && licence.attributes?.active && licence?.attributes?.licenceType === LicenceEnum.PREMIUM && moment().isBefore(moment(licence?.attributes?.to).startOf('day'))) {
                validLicences.push(licence);
              }
            });

            setAllValidLicences([...validLicences]);
          }
        },
        (error: Error) => {
          notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
        }
      );
    }
  }, [orgUnitInfo?.data]);

  useEffect(() => {
    localStorage.removeItem('transferFilters');
    dispatch(clearCourtAction());
    dispatch(clearCourtListAction());
    dispatch(getScreenTagsByTypeAction('court_eu'));
    /**
    dispatch(
      getAllCourtsAction(
        undefined,
        undefined,
        'sort=-verdictDate',
        `page[offset]=${pageSize * (page - 1)}&page[limit]=${pageSize}`
      )
    );
    */
    esSearchFunction(false, { from: 0, size: pageSize });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const esSearchFunction = (
    allOpinions: boolean,
    pageSorted: { from: number; size: number },
    keyword?: string,
    tagIds?: any
  ) => {
    CourtService.elasticSearch(allOpinions, pageSorted, exactMatch, keyword, tagIds).subscribe(
      (response: CourtEsResponseInterface) => {
        setEsData(response);
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
      }
    );
  };

  const refetch = (allValues: any) => {
    if (!allValidLicences.some(x => x.attributes?.licenceType === LicenceEnum.PREMIUM)) {
      notification['warning']({ message: i18n.translate('common.licence.noFilter'), duration: 4 });
      return;
    }
    //setEsSearch(true);
    setFilterValues(allValues);
    setPage(1);
    esSearchFunction(false, { from: 0, size: pageSize }, keyword, allValues);
  };

  const resetSearch = () => {
    setEsData(undefined);
    setKeyword('');
    //setAllOpinions(true);
    //setEsSearch(false);
    setFilterValues(undefined);
    setPage(1);
    //dispatch(getAllCourtsAction(undefined, undefined, 'sort=-verdictDate', `page[offset]=0&page[limit]=${pageSize}`));
    esSearchFunction(false, { from: 0, size: pageSize });
  };

  const sortedCourts = (courts: CourtInterface[]) => {
    let courtArray = courts;

    function onlyUnique(value: any, index: any, self: string | any[]) {
      return self.indexOf(value) === index;
    }

    let yearArray: string[] = [];
    let courtGroups: (CourtInterface[] | undefined)[] = [];

    courtArray?.forEach(court => {
      yearArray.push(court.attributes?.verdictDate ? moment(court.attributes?.verdictDate).format('YYYY.') : '');
    });

    let unique = yearArray?.filter(onlyUnique);

    unique?.forEach(entry => {
      courtGroups.push(courtArray?.filter(x => moment(x.attributes?.verdictDate).format('YYYY.') == entry));
    });

    if (courtArray.find(x => !x.attributes?.verdictDate)) {
      courtGroups.push(courtArray?.filter(x => !x.attributes?.verdictDate));
    }

    return courtGroups.filter(x => x?.length as number > 0);
  };

  const genHeader = (court: CourtInterface | undefined) => {
    return (
      <span className="header">
        <Text className="extraText">
          {court?.attributes?.verdictDate ? moment(court?.attributes?.verdictDate).format('YYYY.') : i18n.translate('common.unsorted')}
        </Text>
      </span>
    );
  };

  const getDefaultActiveKeys = (courts: CourtInterface[]) => {
    let courtArray = courts;

    function onlyUnique(value: any, index: any, self: string | any[]) {
      return self.indexOf(value) === index;
    }

    let yearArray: string[] = [];
    let defaultOpenArray: string[] = [];

    courtArray?.forEach(court => {
      yearArray.push(court.attributes?.verdictDate ? moment(court.attributes?.verdictDate).format('YYYY.') : '');
    });

    let unique = yearArray?.filter(onlyUnique);

    unique?.forEach((entry, index) => {
      defaultOpenArray.push('court_sorted' + index);
    });

    if (defaultOpenArray.length === 0) defaultOpenArray.push('court_sorted' + 0);
    if (defaultOpenArray.length > 0) defaultOpenArray.push('court_sorted' + defaultOpenArray.length);

    return defaultOpenArray;
  };

  const onPaginationChange: PaginationProps['onChange'] = (page, pageSize) => {
    if (!allValidLicences.some(x => x.attributes?.licenceType === LicenceEnum.PREMIUM)) {
      notification['warning']({ message: i18n.translate('common.licence.noFilter'), duration: 4 });
      return;
    }
    setPage(page);
    setPageSize(pageSize);
    if (esSearch) {
      esSearchFunction(false, { from: pageSize * (page - 1), size: pageSize }, keyword, filterValues);
    } else {
      dispatch(
        getAllCourtsAction(
          filterValues,
          undefined,
          'sort=-verdictDate',
          `page[offset]=${pageSize * (page - 1)}&page[limit]=${pageSize}`
        )
      );
    }
  };

  const keywordChanged = (value: string) => {
    setKeyword(value);
  };

  const exactMatchChanged = (exactMatch: boolean) => {
    setExactMatch(exactMatch);
  };

  const convertDataType = (courts: CourtEsResponseInterface['hits']): CourtInterface[] => {
    return courts.map((court) => {
      return {
        id: court.id,
        type: court.index,
        attributes: court.source,
      };
    });
  };

  return (
    <div className="euCourtDeclarations w100-h100">
      <div className="euCourtDeclarations__breadcrumbs">
        <Breadcrumb>
          <Breadcrumb.Item>
            <HomeOutlined />
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span>{i18n.translate('breadcrumbs.eu-practice')}</span>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span>{i18n.translate('menu.eu-court-declarations')}</span>
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="euCourtDeclarations__title">{i18n.translate('euCourt.title')}</div>
      {tags.tagScreens && tags?.tagScreens?.length ? (
        <FilterComponent
          aggregation={esData?.aggregation?.buckets}
          filterAccess
          keyword={keyword}
          filters={tags.tagScreens}
          onFilterChange={refetch}
          onFilterRemove={resetSearch}
          onKeywordChange={keywordChanged}
          onExactMatchChange={exactMatchChanged}
        />
      ) : null}
      <YearDividerComponent title={''} />
      {esSearch && (
        <>
          {esData?.hits?.length ? (
            <Collapse className='noPointer' collapsible={undefined} activeKey={getDefaultActiveKeys(convertDataType(esData?.hits as CourtEsResponseInterface["hits"]) as CourtInterface[])} ghost expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 270 : 90} />}>
            {sortedCourts(convertDataType(esData?.hits as CourtEsResponseInterface["hits"]) as CourtInterface[])?.length ? 
            [...sortedCourts(convertDataType(esData?.hits as CourtEsResponseInterface["hits"]) as CourtInterface[])]
              .map((group, index: number) => (
                <Panel className='noPointer' showArrow={false} forceRender key={'court_sorted' + index} header={genHeader(group?.length && group[0] ? group[0] : undefined)}>
                  {group?.length ? (
                    [...(group as CourtInterface[])]
                      ?.map((court, i) => (
                        <EUCourtCardComponent licenceData={licenceData} validLicences={allValidLicences} key={'euCourt' + i} court={court} notes={noteData}/>
                      ))
                  ) : (
                    <div className="center">
                      <NoDataComponent />
                    </div>
                  )}
                </Panel>
              )) : null}
            </Collapse>
          ) : (
            <NoDataComponent />
          )}
        </>
      )}
      {!esSearch && (
        <>
          {courts.courts?.data?.length ? (
            <Collapse className='noPointer' collapsible={undefined} activeKey={getDefaultActiveKeys(courts.courts?.data)} ghost expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 270 : 90} />}>
            {sortedCourts(courts.courts?.data)?.length ? 
            [...sortedCourts(courts.courts?.data)]
              .map((group, index: number) => (
                <Panel className='noPointer' showArrow={false} forceRender key={'court_sorted' + index} header={genHeader(group?.length && group[0] ? group[0] : undefined)}>
                  {group?.length ? (
                    [...(group as CourtInterface[])]
                      ?.map((court, i) => (
                        <EUCourtCardComponent licenceData={licenceData} validLicences={allValidLicences} key={'euCourt' + i} court={court} notes={noteData}/>
                      ))
                  ) : (
                    <div className="center">
                      <NoDataComponent />
                    </div>
                  )}
                </Panel>
              )) : null}
            </Collapse>
          ) : (
            <NoDataComponent />
          )}
        </>
      )}
      {esSearch && (
        <>
          {esData?.hits?.length ? (
            <Pagination
              style={{ float: 'right', paddingTop: 16, paddingBottom: 16 }}
              onChange={onPaginationChange}
              current={page}
              pageSize={pageSize}
              pageSizeOptions={['10', '20', '50', '100']}
              locale={{ items_per_page: `${i18n.t('pagination.perPage')}` }}
              total={esData?.totalHits}
              hideOnSinglePage
              responsive
              showTotal={(total) => `${i18n.t('pagination.total')}: ${total}`}
            />
          ) : null}
        </>
      )}
      {!esSearch && (
        <>
          {courts.courts?.data?.length ? (
            <Pagination
              style={{ float: 'right', paddingTop: 16, paddingBottom: 16 }}
              onChange={onPaginationChange}
              current={page}
              pageSize={pageSize}
              pageSizeOptions={['10', '20', '50', '100']}
              locale={{ items_per_page: `${i18n.t('pagination.perPage')}` }}
              total={courts?.courts?.meta?.totalResourceCount}
              hideOnSinglePage
              responsive
              showTotal={(total) => `${i18n.t('pagination.total')}: ${total}`}
            />
          ) : null}
        </>
      )}
    </div>
  );
}

export default EUCourtDeclarationsPageComponent;
