import { OrderConstants } from './order.constants';
import { OrderInterface, OrderResponseInterfaceAll, OrderResponseInterfaceSingle, ReducerActionInterface } from '../../interfaces';

// ============ INTERFACE ============

export interface OrderStateInterface {
  revision: number;
  currentOrder?: OrderInterface;
  orders?: OrderResponseInterfaceAll;
  error?: string;
  isLoading?: boolean;
}

// ============ INIT STATE ============

const initialState: OrderStateInterface = {
  revision: 0,
  currentOrder: undefined,
  orders: {
    data: [],
    meta: {
      totalResourceCount: 0
    }
  }
}

// ============ REDUCERS ============

const setLoader = (state: OrderStateInterface, payload: boolean): OrderStateInterface => {
  return {
    ...state,
    isLoading: payload,
  };
};

const getSingleSuccess = (state: OrderStateInterface, payload: OrderResponseInterfaceSingle): OrderStateInterface => {
  return {
    ...state,
    isLoading: false,
    currentOrder: payload.data,
  };
};

const getAllSuccess = (state: OrderStateInterface, payload: OrderResponseInterfaceAll): OrderStateInterface => {
  return {
    ...state,
    isLoading: false,
    orders: {
      data: payload.data,
      meta: payload.meta
    }
  };
};

const createSuccess = (state: OrderStateInterface, payload: OrderResponseInterfaceSingle): OrderStateInterface => {
  return {
    ...state,
    revision: state.revision + 1,
    isLoading: false,
    currentOrder: payload.data,
  };
};

const updateSuccess = (state: OrderStateInterface, payload: OrderResponseInterfaceSingle): OrderStateInterface => {
  return {
    ...state,
    revision: state.revision + 1,
    isLoading: false,
    currentOrder: payload.data,
  };
};

const clearCurrent = (state: OrderStateInterface): OrderStateInterface => {
  return {
    ...state,
    revision: state.revision + 1,
    isLoading: false,
    currentOrder: undefined,
  };
};

const clearList = (state: OrderStateInterface): OrderStateInterface => {
  return {
    ...state,
    revision: state.revision + 1,
    isLoading: false,
    orders: {
      data: [],
      meta: {
        totalResourceCount: 0
      }
    }
  };
};

const deleteSuccess = (state: OrderStateInterface): OrderStateInterface => {
  return {
    ...state,
    revision: state.revision + 1,
    isLoading: false,
    currentOrder: undefined,
  };
};

const error = (state: OrderStateInterface, payload: string) => {
  return {
    ...state,
    revision: state.revision + 1,
    isLoading: false,
    error: payload,
  };
};

// ============ EXPORTS ============

export const OrderReducer = (state = initialState, action: ReducerActionInterface) => {
  switch (action.type) {
    case OrderConstants.SET_LOADER:
      return setLoader(state, action.payload);
    case OrderConstants.GET_SINGLE_SUCCESS:
      return getSingleSuccess(state, action.payload);
    case OrderConstants.GET_ALL_SUCCESS:
      return getAllSuccess(state, action.payload);
    case OrderConstants.CREATE_SUCCESS:
      return createSuccess(state, action.payload);
    case OrderConstants.UPDATE_SUCCESS:
      return updateSuccess(state, action.payload);
    case OrderConstants.CLEAR_CURRENT:
      return clearCurrent(state);
    case OrderConstants.CLEAR_LIST:
      return clearList(state);
    case OrderConstants.DELETE:
      return deleteSuccess(state);
    case OrderConstants.ERROR:
      return error(state, action.payload);
    default:
      return state;
  }
};
