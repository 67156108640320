import { Button, Col, Form, Input, notification, Row, Typography } from 'antd';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { PasswordChangeService, TwoFactorAuthenticationService } from '../../../../api';
import { logoutAction, set2faAction, StoreStateInterface } from '../../../../redux';
import { i18n } from '../../../../services';
import Login2faModal from '../../../modals/2faLogin/login2faModal.component';

const { Title, Text } = Typography;

export default function PasswordComponent(/**props ? */) {
  const dispatch = useDispatch();
  const location = useLocation();
  const path = location.pathname.split('/');
  const id = parseInt(path[path.length - 1]);

  const [login2faVisible, setLogin2faVisible] = useState<boolean>(false);

  const userAuth = useSelector((state: StoreStateInterface) => state.auth);

  const onFinish = (values: any) => {
    let body = {
      email: userAuth?.user?.email as string,
      oldPassword: values?.current,
      newPassword: values?.new
    }

    if (body.newPassword != values?.repeatNew) {
      notification['error']({ message: i18n.translate('common.passwordNoMatch'), duration: 5 });
      return;
    }

    PasswordChangeService.changePassword(body).subscribe(
      (response: any) => {
        // dispatch(clearFullUserAction());
        // dispatch(getSingleFullUserAction(id as number));
        notification['success']({ message: i18n.translate('api.successMessage'), duration: 2 });
        setTimeout(() => {
          dispatch(logoutAction());
        }, 1000);
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
      }
    );
  };

  const on2faOpen = () => {
    TwoFactorAuthenticationService.activate().subscribe(
      (response: any) => {
        setLogin2faVisible(true);
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
      }
    );
  };

  const on2faFinish = (values: any) => {
    TwoFactorAuthenticationService.confirm(values.code.replace(/\s+/g,'X')).subscribe(
      (response: any) => {
        notification['success']({ message: i18n.translate('api.2faSuccess'), duration: 2 });
        set2faAction();
        setLogin2faVisible(false);
      },
      (error: any) => {
        if (error.errorId === 37) {
          notification['error']({ message: i18n.translate('login.msgs.2faInvalidOrExpired'), duration: 3 });
          return;
        }
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
      }
    );
  };
  
  return (
    <div className="password">
      <Form onFinish={onFinish} layout="vertical">
        <Form.Item required label={i18n.translate(`profile.password.current`)} name="current" rules={[{ required: true, message: i18n.translate(`genericFormMsg.required`) }, {
            validator: (_, value) =>
              !value.includes(" ")
                ? Promise.resolve()
                : Promise.reject(new Error(i18n.t('common.noSpace')))
          }]}
        >
          <Input.Password style={{ borderRadius: 0 }} />
        </Form.Item>
        <Form.Item required label={i18n.translate(`profile.password.new`)} name="new" rules={[{ required: true, message: i18n.translate(`genericFormMsg.required`) }, {
            validator: (_, value) =>
              !value.includes(" ")
                ? Promise.resolve()
                : Promise.reject(new Error(i18n.t('common.noSpace')))
          }]}
        >
          <Input.Password style={{ borderRadius: 0 }} />
        </Form.Item>
        <Form.Item required label={i18n.translate(`profile.password.repeatNew`)} name="repeatNew" rules={[{ required: true, message: i18n.translate(`genericFormMsg.required`) }, {
            validator: (_, value) =>
              !value.includes(" ")
                ? Promise.resolve()
                : Promise.reject(new Error(i18n.t('common.noSpace')))
          }]}
        >
          <Input.Password style={{ borderRadius: 0 }} />
        </Form.Item>

        <Input.Group>
          <Row gutter={8} style={{ width: '100%', margin: 0 }}>
            {/**<Col>
              <Button>{i18n.translate(`genericButtons.cancel`)}</Button>
            </Col>*/}
            <Col>
              <Button htmlType='submit' type="primary">{i18n.translate(`genericButtons.save`)}</Button>
            </Col>
          </Row>
        </Input.Group>
      </Form>
      <div className="mt32">
        <Title level={5}>{i18n.translate('login2fa.title')}</Title>
        {userAuth?.user?.active2fa ? <Text strong>{i18n.t('profile.basicInfo.active')}</Text> : <Button type="primary" onClick={on2faOpen}>{i18n.translate(`genericButtons.activate`)}</Button>}
      </div>
      <Login2faModal
        visible={login2faVisible}
        handleOk={on2faFinish}
        handleCancel={() => setLogin2faVisible(false)}
      />
    </div>
  );
}
