import { Col, Row, Typography } from 'antd';
import { Footer } from 'antd/lib/layout/layout';
import React from 'react';
import { i18n } from '../../../services';

const { Text } = Typography;

export default function TrackerFooterComponent() {
  return (
    <Footer className="footer">
      <Col className="footCol">
        <Text type="secondary">{i18n.translate('footer.trackerFooter.model')}</Text>
        <Text strong>Model</Text>
      </Col>
      <Col className="footCol">
        <Text type="secondary">{i18n.translate('footer.trackerFooter.userNo')}</Text>
        <Text strong>2</Text>
      </Col>
      <Col className="footCol">
        <Text type="secondary">{i18n.translate('footer.trackerFooter.subNo')}</Text>
        <Text strong>2</Text>
      </Col>
      <Col className="footCol">
        <Text type="secondary">{i18n.translate('footer.trackerFooter.subYear')}</Text>
        <Text strong>2011.</Text>
      </Col>
      <Col className="footCol">
        <Text strong>{i18n.translate('footer.trackerFooter.totalPrice')}</Text>
        <Row className="footRow">
          <Text strong className="price">
            1,200
          </Text>
          <Text type="secondary">+PDV</Text>
        </Row>
        <Row className="footRow">
          <Text strong className="price">
            1,200
          </Text>
          <Text type="secondary">+PDV</Text>
        </Row>
      </Col>
    </Footer>
  );
}
