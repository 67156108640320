import { BiltenConstants } from './bilten.constants';
import { BiltenInterface, BiltenResponseInterfaceAll, BiltenResponseInterfaceSingle, ReducerActionInterface } from '../../interfaces';

// ============ INTERFACE ============

export interface BiltenStateInterface {
  currentBilten?: BiltenInterface;
  biltens?: BiltenResponseInterfaceAll;
  error?: string;
}

// ============ INIT STATE ============

const initialState: BiltenStateInterface = {
  currentBilten: undefined,
  biltens: {
    data: [],
    meta: {
      totalResourceCount: 0
    }
  }
}

// ============ REDUCERS ============

const getSingleSuccess = (state: BiltenStateInterface, payload: BiltenResponseInterfaceSingle): BiltenStateInterface => {
  return {
    ...state,
    currentBilten: payload.data,
  };
};

const getAllSuccess = (state: BiltenStateInterface, payload: BiltenResponseInterfaceAll): BiltenStateInterface => {
  return {
    ...state,
    biltens: {
      data: payload.data,
      meta: payload.meta
    }
  };
};

const createSuccess = (state: BiltenStateInterface, payload: BiltenResponseInterfaceSingle): BiltenStateInterface => {
  return {
    ...state,
    currentBilten: payload.data,
  };
};

const updateSuccess = (state: BiltenStateInterface, payload: BiltenResponseInterfaceSingle): BiltenStateInterface => {
  return {
    ...state,
    currentBilten: payload.data,
  };
};

const clearCurrent = (state: BiltenStateInterface): BiltenStateInterface => {
  return {
    ...state,
    currentBilten: undefined,
  };
};

const clearList = (state: BiltenStateInterface): BiltenStateInterface => {
  return {
    ...state,
    biltens: {
      data: [],
      meta: {
        totalResourceCount: 0
      }
    }
  };
};

const deleteSuccess = (state: BiltenStateInterface): BiltenStateInterface => {
  return {
    ...state,
    currentBilten: undefined,
  };
};

const error = (state: BiltenStateInterface, payload: string) => {
  return {
    ...state,
    error: payload,
  };
};

// ============ EXPORTS ============

export const BiltenReducer = (state = initialState, action: ReducerActionInterface) => {
  switch (action.type) {
    case BiltenConstants.GET_SINGLE_SUCCESS:
      return getSingleSuccess(state, action.payload);
    case BiltenConstants.GET_ALL_SUCCESS:
      return getAllSuccess(state, action.payload);
    case BiltenConstants.CREATE_SUCCESS:
      return createSuccess(state, action.payload);
    case BiltenConstants.UPDATE_SUCCESS:
      return updateSuccess(state, action.payload);
    case BiltenConstants.CLEAR_CURRENT:
      return clearCurrent(state);
    case BiltenConstants.CLEAR_LIST:
      return clearList(state);
    case BiltenConstants.DELETE:
      return deleteSuccess(state);
    case BiltenConstants.ERROR:
      return error(state, action.payload);
    default:
      return state;
  }
};
