import { Dispatch } from 'redux';
import { notification } from 'antd';
import { UserLogStatisticsConstants } from './userLogStatistics.constants';

import { UserLogStatisticsService } from '../../api/api.service';
import { UserLogStatisticsResponseInterfaceAll } from '../../interfaces';
import { i18n } from '../../services';

export function getAllUserLogStatisticsAction(userId?: number | string, filter?: any, sort?: any, pagination?: any) {
  return (dispatch: Dispatch) => {
    return UserLogStatisticsService.getAll(userId, filter, sort, pagination).subscribe(
      (response: UserLogStatisticsResponseInterfaceAll) => {
        dispatch({
          type: UserLogStatisticsConstants.GET_ALL_SUCCESS,
          payload: response,
        });
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });

        dispatch({
          type: UserLogStatisticsConstants.ERROR,
          payload: error,
        });
      }
    );
  };
}

export function clearUserLogStatisticsListAction() {
  return (dispatch: Dispatch) => {
    dispatch({ type: UserLogStatisticsConstants.CLEAR_LIST });
  };
}
