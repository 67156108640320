import { SearchOutlined, FilePdfOutlined, MoreOutlined, EditOutlined, LinkOutlined, BankOutlined, ExceptionOutlined, BarsOutlined } from '@ant-design/icons';
import { Col, DatePicker, Dropdown, InputRef, Menu, notification, Popover, Radio, RadioChangeEvent, Row, TablePaginationConfig, Tooltip } from 'antd';
import { Button, Input, Space, Table, Typography } from 'antd';
import type { ColumnsType, ColumnType } from 'antd/es/table';
import type { FilterConfirmProps, FilterValue, SorterResult, TableCurrentDataSource } from 'antd/es/table/interface';
import moment from 'moment';
import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { FileService, FilterNameEnumHR, StoreStateInterface, UserActivityLogService, UserRoleEnum, VerdictService } from '../../..';
import { AdminRoutes } from '../../../../features/admin';
import { DisplayDateFormat } from '../../../constants';
import { VerdictInterface, TagScreenResponseInterface, DecisionInterface, LicenceInterface, OrderInterface, ResponseInterface, SynopsisInterface } from '../../../interfaces';
import { DownloadService, i18n, ViewerService } from '../../../services';
import localeHR from 'antd/es/date-picker/locale/hr_HR';
import localeEN from 'antd/es/date-picker/locale/en_GB';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { LicenceEnum } from '../../../enums/licence.enum';
import useWindowSize from '../../../hooks/useWindowSize';
import RhPracticeDetailsDrawer from '../../rhPracticeDetailsDrawer/rhPracticeDetailsDrawer.component';
import { UserActionTypeEnum } from '../../../enums/userActionType.enum';

const { Text } = Typography;

interface DataType {
  key: number | string;
  active: boolean;
  verdictNum: string;
  plaintiff: string;
  verdictPdfUuid: string;
  vusVerdictPdfUuid: string;
  vusVerdictLink: string;
  type: string;
  verdictDate: string;
  verdictPublishDate: string;
  lawFirm: string;
  trialChamber: string;
  createdDate: string;
  modifiedDate: string;
  createdBy: number;
  modifiedBy: number;
  tag: any[];
  tagIds: Array<number>;
  decision: string;
  fullDecision: any;
  subject: string;
  client: string;
  appellant: string;
  decisionDate: string;
  appealInvestmentDate: string;
  decisionPublishDate: string;
  decisionWaitTime: number;
  decisionPdfUuid: string;
  dkomDecisionPdfUuid: string;
  dkomDecisionLink: string;
  pmPdfUuid: string;
  zznpPdfUuid: string;
  verdictProsecutionOrders: Array<OrderInterface['attributes']>;
  verdictResponses: Array<ResponseInterface['attributes']>;
  verdictSummaries: Array<SynopsisInterface['attributes']>;
}

interface Props {
  verdicts?: VerdictInterface[];
  tags?: Array<TagScreenResponseInterface>;
  onChange?: (pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>, sorter: SorterResult<any> | SorterResult<any>[], extra: TableCurrentDataSource<any>) => void;
  clearColumnFilters?: () => void;
  decisions?: DecisionInterface[];
  licenceData?: LicenceInterface;
  refreshTable?: () => void;
  validLicences?: LicenceInterface[];
}

export default function VUSTableComponent({ verdicts, tags, decisions, licenceData, refreshTable, onChange, clearColumnFilters, validLicences }: Props) {
  const getDecision = (verdict: VerdictInterface) => {
    let decision;
    if (decisions?.length) {
      decision = decisions?.find(
        (decision: DecisionInterface) => decision.id == verdict.relationships?.decision?.data?.id
      );
      if (!decision) decision = decisions?.find(
        (decision: DecisionInterface) => decision.id == verdict.attributes?.decision?.id
      );
    }
    return decision;
  };

  const getType = (record: any) => {
    let typeName = '';
    const parentTag = tags?.find((tag) => tag.filter && tag.filter.name == FilterNameEnumHR.PURCHASE_TYPE);
    if (parentTag && parentTag.children?.length) {
      parentTag.children?.forEach((child) => {
        const id = record?.tagIds?.find((id: number | undefined) => id == child.tag.id);
        if (id) typeName = child.tag.name;
      });
    }
    return typeName;
  };

  const userAuth = useSelector((state: StoreStateInterface) => state.auth);

  const windowSize = useWindowSize();

  const [filtered, setFiltered] = useState(false);

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerTitle, setDrawerTitle] = useState('');
  const [detailsData, setDetailsData] = useState();
  const [currentVerdict, setCurrentVerdict] = useState<any>();

  const data: DataType[] = verdicts?.map((verdict) => {
    return {
      key: verdict.id,
      type: getType(verdict?.attributes),
      ...verdict.attributes,
      fullDecision: verdict.attributes?.decision,
      decision: getDecision(verdict)?.attributes?.clazz,
      subject: getDecision(verdict)?.attributes?.subject,
      client: getDecision(verdict)?.attributes?.client,
      appellant: getDecision(verdict)?.attributes?.appellant,
      verdictDate: verdict?.attributes?.verdictDate ? moment(verdict?.attributes?.verdictDate).format(DisplayDateFormat) : '',
      verdictPublishDate: verdict?.attributes?.verdictPublishDate ? moment(verdict?.attributes?.verdictPublishDate).format(DisplayDateFormat) : '',
      appealInvestmentDate: getDecision(verdict)?.attributes?.appealInvestmentDate ? moment(getDecision(verdict)?.attributes?.appealInvestmentDate).format(DisplayDateFormat) : '',
      decisionDate: getDecision(verdict)?.attributes?.decisionDate ? moment(getDecision(verdict)?.attributes?.decisionDate).format(DisplayDateFormat) : '',
      decisionPublishDate: getDecision(verdict)?.attributes?.decisionPublishDate ? moment(getDecision(verdict)?.attributes?.decisionPublishDate).format(DisplayDateFormat) : '',
      decisionWaitTime: getDecision(verdict)?.attributes?.decisionWaitTime,
      decisionPdfUuid: getDecision(verdict)?.attributes?.decisionPdfUuid,
      dkomDecisionPdfUuid: getDecision(verdict)?.attributes?.dkomDecisionPdfUuid,
      dkomDecisionLink: getDecision(verdict)?.attributes?.dkomDecisionLink,
      pmPdfUuid: getDecision(verdict)?.attributes?.pmPdfUuid,
      zznpPdfUuid: getDecision(verdict)?.attributes?.zznpPdfUuid,
    };
  }) as DataType[];

  type DataIndex = keyof DataType;

  const checkLicenceYear = (record: DataType) => {
    const verdictYear = moment(record?.verdictDate, DisplayDateFormat).format('YYYY');

    if (userAuth.user?.roles === UserRoleEnum.ADMIN) return true;

    if (validLicences?.length && validLicences?.some(x => x.attributes?.licenceType === LicenceEnum.PREMIUM)) {
      let access = false;

      validLicences.filter(x => x.attributes?.licenceType === LicenceEnum.PREMIUM).forEach(licenceData => {
        if (verdictYear == licenceData.attributes?.year) {
          access = true;
        }
        if (licenceData.attributes?.childrenLicences?.length) {
          if (licenceData.attributes?.childrenLicences?.find((x) => x.active && x.year == verdictYear)) {
            access = true;
          }
        }
      });

      return access;
    } else {
      return false;
    }
  };

  const searchInput = useRef<InputRef>(null);

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex
  ) => {
    setFiltered(true);
    confirm();
  };

  const handleReset = (clearFilters: () => void, confirm: (param?: FilterConfirmProps) => void) => {
    setFiltered(false);
    clearFilters();
    confirm();
  };

  const handleClearAllFilters = () => {
    setFiltered(false);
    if (clearColumnFilters)
      clearColumnFilters()
  };

  const menu = (verdictId: number, uuid: string, active: boolean, record: any) => {
    let items = [
      userAuth.user?.roles === UserRoleEnum.ADMIN && {
        label: <a onClick={() => onVerdictActivation(verdictId, active)}>{active ? i18n.translate(`vus.table.menu.deactivate`) : i18n.translate(`vus.table.menu.activate`)}</a>,
        key: '0'
      },
      {
        label: <a onClick={() => onFileDownload(uuid, record)}>{i18n.translate(`vus.table.menu.download`)}</a>,
        key: '1'
      },
    ] as ItemType[]
    return <Menu items={items} />
  };

  const onVerdictActivation = (id: number, active: boolean) => {
    const decision = {
      data: {
        id: id,
        type: 'verdict',
        attributes: {
          active: !active
        }
      }
    }
    VerdictService.activation(decision).subscribe(
      (response: any) => {
        refreshTable && refreshTable();
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
      }
    );
  };

  const onFileDownload = (uuid: string, record: any) => {
    if (userAuth?.user?.roles !== UserRoleEnum.ADMIN) {
      let userActivityLogData: any = {
        data: {
          type: 'user_activity_log',
          attributes: {
            action: UserActionTypeEnum.FILE_OPENED,
            extra: JSON.stringify({type: 'verdict', documentType: 'VUS originalna presuda', name: record.verdictNum})
          },
          relationships: {
            user: {
              data: {
                id: userAuth?.user?.id,
                type: 'user'
              },
            },
          },
        },
      };

      UserActivityLogService.create(userActivityLogData);
    }
    FileService.temp(uuid).subscribe(
      (response: any) => {
        if (response.url) {
          notification['success']({ message: i18n.translate('api.downloadSuccessMessage'), duration: 2 });
          var link = document.createElement('a');
          link.href = response.url;
          link.download = 'file.pdf';
          link.dispatchEvent(new MouseEvent('click'));
        } else {
          notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
        }
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
      }
    );
  };

  const onFileView = (uuid: string, record: DataType, type: string, documentType: string) => {
    if (!uuid) return;
    if (userAuth?.user?.roles !== UserRoleEnum.ADMIN) {
      let userActivityLogData: any = {
        data: {
          type: 'user_activity_log',
          attributes: {
            action: checkLicenceYear(record) ? UserActionTypeEnum.FILE_DOWNLOADED : UserActionTypeEnum.FILE_OPENED,
            extra: JSON.stringify({type: type, documentType: documentType, name: type == 'decision' ? record.decision : record.verdictNum, subject: type == 'decision' ? record.subject : ''})
          },
          relationships: {
            user: {
              data: {
                id: userAuth?.user?.id,
                type: 'user'
              },
            },
          },
        },
      };

      UserActivityLogService.create(userActivityLogData);
    }
    if (checkLicenceYear(record)) {
      DownloadService.download(uuid)
    } else {
      ViewerService.goToPdf(uuid);
    }
  };

  const onEditedFileView = (uuid: string, record: DataType, type: string) => {
    if (!uuid) return;
    if (userAuth?.user?.roles !== UserRoleEnum.ADMIN) {
      let userActivityLogData: any = {
        data: {
          type: 'user_activity_log',
          attributes: {
            action: UserActionTypeEnum.FILE_DOWNLOADED,
            extra: JSON.stringify({type: type === 'decision' ? 'decision' : 'verdict', documentType: type === 'decision' ? 'BJN uređena odluka' : 'BJN uređena presuda', name: type === 'decision' ? record.decision : record.verdictNum, subject: type === 'decision' ? record.subject : ''})
          },
          relationships: {
            user: {
              data: {
                id: userAuth?.user?.id,
                type: 'user'
              },
            },
          },
        },
      };

      UserActivityLogService.create(userActivityLogData);
    }
    if (checkLicenceYear(record)) {
      DownloadService.download(uuid);
    } else {
      //ViewerService.goToPdf(uuid);
      notification['warning']({ message: i18n.translate('common.licence.content'), duration: 4 });
    }
  };

  const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<DataType> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) =>
      dataIndex != 'verdictDate' &&
        dataIndex != 'verdictPublishDate' &&
        dataIndex != 'decisionDate' &&
        dataIndex != 'appealInvestmentDate' &&
        dataIndex != 'decisionPublishDate' ? (
        <div style={{ padding: 8 }}>
          <Input
            ref={searchInput}
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys([e.target.value, selectedKeys[1]])
            }}
            onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
            style={{ marginBottom: 8, display: 'block' }}
          />
          <Radio.Group defaultValue={true} style={{marginBottom: 8}} onChange={(e) => {
            setSelectedKeys([selectedKeys[0], e.target.value]);
          }}>
            <Radio checked value={true}>{i18n.translate('common.filter.exactMatch')}</Radio>
            <Radio value={false}>{i18n.translate('common.filter.noExactMatch')}</Radio>
          </Radio.Group>
          <Space style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button
              onClick={() => clearFilters && handleReset(clearFilters, confirm)}
              size="small"
            >
              {i18n.t('genericButtons.clear')}
            </Button>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
            >
              {i18n.t('genericButtons.search')}
            </Button>
          </Space>
        </div>
      ) : (
        <div style={{ padding: 8 }}>
          <DatePicker
            locale={userAuth.lang == 'hr' ? localeHR : localeEN}
            format={DisplayDateFormat}
            onChange={(e) =>
              setSelectedKeys(moment(e).format(DisplayDateFormat) ? [moment(e).format(DisplayDateFormat)] : [])
            }
            style={{ marginBottom: 8, display: 'block' }}
            allowClear={false}
          />
          <Space style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button
              onClick={() => clearFilters && handleReset(clearFilters, confirm)}
              size="small"
            >
              {i18n.t('genericButtons.clear')}
            </Button>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
            >
              {i18n.t('genericButtons.search')}
            </Button>
          </Space>
        </div>
      ),
    filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
  });

  const getTypeFilters = () => {
    let typesArray: any[] = [];
    const parentTag = tags?.find((tag) => tag.filter && tag.filter.name == FilterNameEnumHR.PURCHASE_TYPE);
    if (parentTag && parentTag.children?.length) {
      parentTag.children?.forEach((child) => {
        typesArray.push({text: child.tag?.name, value: child.tag?.id});
      });
    }
    return typesArray;
  };

  const checkIfMobile = () => {
    if ((/Android/i.test(navigator.userAgent))) {
      return true;
    }
    if ((/iPhone/i.test(navigator.userAgent))) {
      return true;
    }
    else {
      return false;
    }
  };

  const onDrawerOpen = (detailsArray: any, title: 'allegation' | 'response' | 'summary', verdict: any) => {
    const translatedTitle = i18n.t(`rhPracticeDrawer.vus.titles.${title}`);

    setDrawerTitle(translatedTitle);
    setDetailsData(detailsArray);
    setCurrentVerdict(verdict);
    setDrawerOpen(true);
  };

  const onDrawerClose = () => {
    setDetailsData(undefined);
    setCurrentVerdict(undefined);
    setDrawerOpen(false);
  };

  //OVO NE TREBA PROVJERE - OVO JE ZA ADMINOVU TABLICA
  const columns: ColumnsType<DataType> = [
    {
      dataIndex: 'subcategories',
      key: 'subcategories',
      width: '25px',
      fixed: windowSize.width as number > 425 && !checkIfMobile() ? 'left' : false,
      render: (text, record, index) => (
        <Col>
          <Row style={{justifyContent: 'center', marginBottom: 12}}>
            {record.verdictProsecutionOrders?.length ? (
              <Tooltip
                title={i18n.translate(`rhPracticeDrawer.vus.titles.allegation`)}
              >
                <Button onClick={() => onDrawerOpen(record.verdictProsecutionOrders, 'allegation', record)} type="link" icon={<BankOutlined />} />
              </Tooltip>
            ) : (
              <Popover
                title={<span className="text-bold">{i18n.translate(`rhPracticeDrawer.vus.titles.allegation`)}</span>}
                content={i18n.translate('common.licence.noTitle')}
              >
                <Button disabled type="link" className="cardBtn" icon={<BankOutlined />} />
              </Popover>
            )}
          </Row>
          
          <Row style={{justifyContent: 'center', marginBottom: 12}}>
            {record.verdictResponses?.length ? (
              <Tooltip
                title={i18n.translate(`rhPracticeDrawer.vus.titles.response`)}
              >
                <Button onClick={() => onDrawerOpen(record.verdictResponses, 'response', record)} type="link" icon={<ExceptionOutlined />} />
              </Tooltip>
            ) : (
              <Popover
                title={<span className="text-bold">{i18n.translate(`rhPracticeDrawer.vus.titles.response`)}</span>}
                content={i18n.translate('common.licence.noTitle')}
              >
                <Button disabled type="link" className="cardBtn" icon={<ExceptionOutlined />} />
              </Popover>
            )}
          </Row>

          <Row style={{justifyContent: 'center', marginBottom: 12}}>
            {record.verdictSummaries?.length ? (
              <Tooltip
                title={i18n.translate(`rhPracticeDrawer.vus.titles.summary`)}
              >
                <Button onClick={() => onDrawerOpen(record.verdictSummaries, 'summary', record)} type="link" icon={<BarsOutlined />} />
              </Tooltip>
            ) : (
              <Popover
                title={<span className="text-bold">{i18n.translate(`rhPracticeDrawer.vus.titles.summary`)}</span>}
                content={i18n.translate('common.licence.noTitle')}
              >
                <Button disabled type="link" className="cardBtn" icon={<BarsOutlined />} />
              </Popover>
            )}
          </Row>
        </Col>
      )
    },
    {
      title: i18n.translate('vus.table.number'),
      dataIndex: 'verdictNum',
      key: 'verdictNum',
      width: '140px',
      fixed: windowSize.width as number > 425 && !checkIfMobile() ? 'left' : false,
      render: (text, record, index) => <span style={record.active ? {} : { color: 'red' }}>{text}</span>,
      ...getColumnSearchProps('verdictNum'),
    },
    {
      title: i18n.translate('vus.table.verdict'),
      key: 'verdict',
      width: '200px',      
      fixed: windowSize.width as number > 425 && !checkIfMobile() ? 'left' : false,
      render: (text, record, index) => (
        <Col>
          <Row>
            <Text className="decText">
              {i18n.translate('vus.table.editedVerdict')}:
            </Text>
            {record.verdictPdfUuid ? (
              <Button onClick={() => onFileView(record.verdictPdfUuid, record, 'verdict', 'BJN uređena presuda')} type="link" icon={<FilePdfOutlined />} />
            ) : (
              <Popover
                placement="topRight"
                content={i18n.translate(`common.licence.content`)}
                title={i18n.translate(`common.licence.title`)}
              >
              <Button
                disabled
                type="link"
                className="cardBtn"
                icon={<FilePdfOutlined />}
              />
              </Popover>
            )}
          </Row>
          <Row>
            <Text className="decText">
              {i18n.translate('vus.table.verdict')}:
            </Text>
            {record.vusVerdictPdfUuid ? (
              <Button onClick={() => onFileView(record.vusVerdictPdfUuid, record, 'verdict', 'VUS originalna presuda')} type="link" icon={<FilePdfOutlined />} />
            ) : (
              <Popover
                placement="topRight"
                content={i18n.translate(`common.licence.content`)}
                title={i18n.translate(`common.licence.title`)}
              >
              <Button
                disabled
                type="link"
                className="cardBtn"
                icon={<FilePdfOutlined />}
              />
              </Popover>
            )}
            {record.vusVerdictLink ? (
              <a target="_blank" href={record.vusVerdictLink}>
                <Button type="link" icon={<LinkOutlined />} />
              </a>
            ) : (
              <Popover
                placement="topRight"
                content={i18n.translate(`common.licence.content`)}
                title={i18n.translate(`common.licence.title`)}
              >
                <Button disabled type="link" icon={<LinkOutlined />} />
              </Popover>
            )}
          </Row>
        </Col>
      ),
    },
    {
      title: i18n.translate('vus.table.DKOMdecision'),
      key: 'DKOMdecision',
      width: '200px',
      fixed: windowSize.width as number > 425 && !checkIfMobile() ? 'left' : false,
      render: (text, record, index) => (
        <Col>
          <Row>
            <Text className="decText">
              {i18n.translate('dkom.table.editedDec')}:
            </Text>
            {record.decisionPdfUuid ? (
              <Button onClick={() => onFileView(record.decisionPdfUuid, record, 'decision', 'BJN uređena odluka')} type="link" icon={<FilePdfOutlined />} />
            ) : (
              <Popover
                placement="topRight"
                content={i18n.translate(`common.licence.content`)}
                title={i18n.translate(`common.licence.title`)}
              >
              <Button
                disabled
                type="link"
                className="cardBtn"
                icon={<FilePdfOutlined />}
              />
              </Popover>
            )}
          </Row>
          <Row>
            <Text className="decText">
              {i18n.translate('dkom.table.decision')}:
            </Text>
            {record.dkomDecisionPdfUuid ? (
              <Button
                onClick={() => onFileView(record.dkomDecisionPdfUuid, record, 'decision', 'DKOM originalna odluka')}
                type="link"
                icon={<FilePdfOutlined />}
              />
            ) : (
              <Popover
                placement="topRight"
                content={i18n.translate(`common.licence.content`)}
                title={i18n.translate(`common.licence.title`)}
              >
              <Button
                disabled
                type="link"
                className="cardBtn"
                icon={<FilePdfOutlined />}
              />
              </Popover>
            )}
            {record.dkomDecisionLink ? (
              <a target="_blank" href={record.dkomDecisionLink}>
                <Button type="link" icon={<LinkOutlined />} />
              </a>
            ) : (
              <Popover
                placement="topRight"
                content={i18n.translate(`common.licence.content`)}
                title={i18n.translate(`common.licence.title`)}
              >
              <Button disabled type="link" icon={<LinkOutlined />} />
              </Popover>
            )}
          </Row>
          {record.pmPdfUuid ? <Row>
            <Text className="decText"> {i18n.translate('dkom.table.pmPdf')}: </Text>
            {record.pmPdfUuid ? (
              <Button onClick={() => onFileView(record.pmPdfUuid, record, 'decision', 'PM odluka')} type="link" icon={<FilePdfOutlined />} />
            ) : (
              <Popover
                placement="topRight"
                content={i18n.translate(`common.licence.content`)}
                title={i18n.translate(`common.licence.title`)}
              >
                <Button disabled type="link" className="cardBtn" icon={<FilePdfOutlined />} />
              </Popover>
            )}
          </Row> : null}
          {record.zznpPdfUuid ? <Row>
            <Text className="decText"> {i18n.translate('dkom.table.zznpPdf')}: </Text>
            {record.zznpPdfUuid ? (
              <Button onClick={() => onFileView(record.zznpPdfUuid, record, 'decision', 'ZZNP odluka')} type="link" icon={<FilePdfOutlined />} />
            ) : (
              <Popover
                placement="topRight"
                content={i18n.translate(`common.licence.content`)}
                title={i18n.translate(`common.licence.title`)}
              >
                <Button disabled type="link" className="cardBtn" icon={<FilePdfOutlined />} />
              </Popover>
            )}
          </Row> : null}
        </Col>
      ),
    },
    {
      title: i18n.translate('vus.table.class'),
      dataIndex: 'decision',
      key: 'decision',
      width: '140px',
      ...getColumnSearchProps('decision'),
    },
    {
      title: i18n.translate('vus.table.customer'),
      dataIndex: 'client',
      key: 'client',
      width: '140px',
      ...getColumnSearchProps('client'),
    },
    {
      title: i18n.translate('vus.table.appellant'),
      dataIndex: 'appellant',
      key: 'appellant',
      width: '180px',
      ...getColumnSearchProps('appellant'),
    },
    {
      title: i18n.translate('vus.table.plaintiff'),
      dataIndex: 'plaintiff',
      key: 'plaintiff',
      width: '180px',
      ...getColumnSearchProps('plaintiff'),
    },
    {
      title: i18n.translate('vus.table.topic'),
      dataIndex: 'subject',
      key: 'subject',
      width: '240px',
      ...getColumnSearchProps('subject'),
    },
    {
      title: i18n.translate('vus.table.type'),
      dataIndex: 'type',
      key: 'type',
      width: '130px',
      render: (text, record, index) => <span>{text ? text : '—'}</span>,
      //...getColumnSearchProps('type'),
      filters: getTypeFilters(),
    },
    {
      title: i18n.translate('vus.table.dateOfApp'),
      dataIndex: 'appealInvestmentDate',
      key: 'appealInvestmentDate',
      width: '135px',
      //...getColumnSearchProps('appealInvestmentDate'),
    },
    {
      title: i18n.translate('vus.table.dateOfDKOMDecision'),
      dataIndex: 'decisionDate',
      key: 'decisionDate',
      width: '200px',
      //...getColumnSearchProps('decisionDate'),
    },
    {
      title: i18n.translate('vus.table.dateOfDKOMPubl'),
      dataIndex: 'decisionPublishDate',
      key: 'decisionPublishDate',
      width: '250px',
      //...getColumnSearchProps('decisionPublishDate'),
    },
    {
      title: i18n.translate('vus.table.waitTime'),
      dataIndex: 'decisionWaitTime',
      key: 'decisionWaitTime',
      width: '170px',
      sorter: (a, b) => a.decisionWaitTime - b.decisionWaitTime,
      sortDirections: ['descend', 'ascend'],
      render: (text, record, index) => <span>{record.decisionWaitTime > 0 ? `${record.decisionWaitTime} ${i18n.t('dkom.table.days')}` : ``}</span>,
    },
    {
      title: i18n.translate('vus.table.dateOfVUSVerdict'),
      dataIndex: 'verdictDate',
      key: 'verdictDate',
      width: '190px',
      //...getColumnSearchProps('verdictDate'),
    },
    {
      title: i18n.translate('vus.table.VUSverdictPubl'),
      dataIndex: 'verdictPublishDate',
      key: 'verdictPublishDate',
      width: '200px',
      //...getColumnSearchProps('verdictPublishDate'),
    },
    {
      title: i18n.translate('vus.table.lawFirm'),
      dataIndex: 'lawFirm',
      key: 'lawFirm',
      width: '185px',
      ...getColumnSearchProps('lawFirm'),
    },
    {
      title: i18n.translate('vus.table.judge'),
      dataIndex: 'trialChamber',
      key: 'trialChamber',
      width: '200px',
      ...getColumnSearchProps('trialChamber'),
    },

    {
      title: i18n.translate('vus.table.actions'),
      dataIndex: 'actions',
      key: 'actions',
      width: '130px',
      render: (text, record, index) => (
        <Row>
          <Col>
            {userAuth.user?.roles === UserRoleEnum.ADMIN && (
              <Link
                to={AdminRoutes.VUS_DECLARATIONS.fullPath + `/${record.key}`}
                key={AdminRoutes.VUS_DECLARATIONS.fullPath + `/new`}
              >
                <Button type="link" icon={<EditOutlined />} />
              </Link>
            )}
          </Col>
          <Col>
            <Dropdown overlay={menu(record.key as number, record.verdictPdfUuid, record.active, record)} trigger={['click']}>
              <a onClick={(e) => e.preventDefault()}>
                <Space>
                  <Button type="link" icon={<MoreOutlined />} />
                </Space>
              </a>
            </Dropdown>
          </Col>
        </Row>
      ),
    },
  ];

  const columnsUser: ColumnsType<DataType> = [
    {
      dataIndex: 'subcategories',
      key: 'subcategories',
      width: '25px',
      fixed: windowSize.width as number > 425 && !checkIfMobile() ? 'left' : false,
      render: (text, record, index) => (
        <Col>
          <Row style={{justifyContent: 'center', marginBottom: 12}}>
            {record.verdictProsecutionOrders?.length && validLicences?.some(x => x.attributes?.licenceType === LicenceEnum.PREMIUM) ? (
              <Tooltip
                title={i18n.translate(`rhPracticeDrawer.vus.titles.allegation`)}
              >
                <Button onClick={() => onDrawerOpen(record.verdictProsecutionOrders, 'allegation', record)} type="link" icon={<BankOutlined />} />
              </Tooltip>
            ) : (
              <Popover
                title={<span className="text-bold">{i18n.translate(`rhPracticeDrawer.vus.titles.allegation`)}</span>}
                content={i18n.translate('common.licence.content')}
              >
                <Button disabled type="link" className="cardBtn" icon={<BankOutlined />} />
              </Popover>
            )}
          </Row>
          
          <Row style={{justifyContent: 'center', marginBottom: 12}}>
            {record.verdictResponses?.length && validLicences?.some(x => x.attributes?.licenceType === LicenceEnum.PREMIUM) ? (
              <Tooltip
                title={i18n.translate(`rhPracticeDrawer.vus.titles.response`)}
              >
                <Button onClick={() => onDrawerOpen(record.verdictResponses, 'response', record)} type="link" icon={<ExceptionOutlined />} />
              </Tooltip>
            ) : (
              <Popover
                title={<span className="text-bold">{i18n.translate(`rhPracticeDrawer.vus.titles.response`)}</span>}
                content={i18n.translate('common.licence.content')}
              >
                <Button disabled type="link" className="cardBtn" icon={<ExceptionOutlined />} />
              </Popover>
            )}
          </Row>

          <Row style={{justifyContent: 'center', marginBottom: 12}}>
            {record.verdictSummaries?.length && validLicences?.some(x => x.attributes?.licenceType === LicenceEnum.PREMIUM) ? (
              <Tooltip
                title={i18n.translate(`rhPracticeDrawer.vus.titles.summary`)}
              >
                <Button onClick={() => onDrawerOpen(record.verdictSummaries, 'summary', record)} type="link" icon={<BarsOutlined />} />
              </Tooltip>
            ) : (
              <Popover
                title={<span className="text-bold">{i18n.translate(`rhPracticeDrawer.vus.titles.summary`)}</span>}
                content={i18n.translate('common.licence.content')}
              >
                <Button disabled type="link" className="cardBtn" icon={<BarsOutlined />} />
              </Popover>
            )}
          </Row>
        </Col>
      )
    },
    {
      title: i18n.translate('vus.table.number'),
      dataIndex: 'verdictNum',
      key: 'verdictNum',
      width: '140px',
      fixed: windowSize.width as number > 425 && !checkIfMobile() ? 'left' : false,
      render: (text, record, index) => <span style={record.active ? {} : { color: 'red' }}>{text}</span>,
      ...getColumnSearchProps('verdictNum'),
    },
    {
      title: i18n.translate('vus.table.verdict'),
      key: 'verdict',
      width: '200px',
      fixed: windowSize.width as number > 425 && !checkIfMobile() ? 'left' : false,
      render: (text, record, index) => (
        <Col>
          <Row>
            <Text className="decText">
              {i18n.translate('vus.table.editedVerdict')}:
            </Text>
            {record.verdictPdfUuid ? (
              <Button onClick={() => onEditedFileView(record.verdictPdfUuid, record, 'verdict')} type="link" icon={<FilePdfOutlined />} />
            ) : (
              <Popover
                placement="topRight"
                content={i18n.translate(`common.licence.content`)}
                title={i18n.translate(`common.licence.title`)}
              >
                <Button
                  disabled
                  type="link"
                  className="cardBtn"
                  icon={<FilePdfOutlined />}
                />
              </Popover>
            )}
          </Row>
          <Row>
            <Text className="decText">
              {i18n.translate('vus.table.verdict')}:
            </Text>
            {record.vusVerdictPdfUuid ? (
              <Button onClick={() => onFileView(record.vusVerdictPdfUuid, record, 'verdict', 'VUS originalna presuda')} type="link" icon={<FilePdfOutlined />} />
            ) : (
              <Popover
                placement="topRight"
                content={i18n.translate(`common.licence.content`)}
                title={i18n.translate(`common.licence.title`)}
              >
                <Button
                  disabled
                  type="link"
                  className="cardBtn"
                  icon={<FilePdfOutlined />}
                />
              </Popover>
            )}
            {record.vusVerdictLink ? (
              <a target="_blank" href={record.vusVerdictLink}>
                <Button type="link" icon={<LinkOutlined />} />
              </a>
            ) : (
              <Popover
                placement="topRight"
                content={i18n.translate(`common.licence.content`)}
                title={i18n.translate(`common.licence.title`)}
              >
                <Button disabled type="link" icon={<LinkOutlined />} />
              </Popover>
            )}
          </Row>
        </Col>
      ),
    },
    {
      title: i18n.translate('vus.table.DKOMdecision'),
      key: 'DKOMdecision',
      width: '200px',      
      fixed: windowSize.width as number > 425 && !checkIfMobile() ? 'left' : false,
      render: (text, record, index) => (
        <Col>
          <Row>
            <Text className="decText">
              {i18n.translate('dkom.table.editedDec')}:
            </Text>
            {record.decisionPdfUuid ? (
              !checkLicenceYear(record) ? (
                <Popover
                  placement="topRight"
                  content={i18n.translate(`common.licence.content`)}
                  title={i18n.translate(`common.licence.title`)}
                >
                  <Button disabled type="link" className="cardBtn" icon={<FilePdfOutlined />} />
                </Popover>
              ) : (
                <Button onClick={() => onEditedFileView(record.decisionPdfUuid, record, 'decision')} type="link" icon={<FilePdfOutlined />} />
              )
            ) : (
              <Popover
                placement="topRight"
                content={i18n.translate(`common.licence.content`)}
                title={i18n.translate(`common.licence.title`)}
              >
                <Button
                  disabled
                  type="link"
                  className="cardBtn"
                  icon={<FilePdfOutlined />}
                />
              </Popover>
            )}
          </Row>
          <Row>
            <Text className="decText">
              {i18n.translate('dkom.table.decision')}:
            </Text>
            {record.dkomDecisionPdfUuid ? (
              <Button
                onClick={() => onFileView(record.dkomDecisionPdfUuid, record, 'decision', 'DKOM originalna odluka')}
                type="link"
                icon={<FilePdfOutlined />}
              />
            ) : (
              <Popover
                placement="topRight"
                content={i18n.translate(`common.licence.content`)}
                title={i18n.translate(`common.licence.title`)}
              >
                <Button
                  disabled
                  type="link"
                  className="cardBtn"
                  icon={<FilePdfOutlined />}
                />
              </Popover>
            )}
            {record.dkomDecisionLink ? (
              <a target="_blank" href={record.dkomDecisionLink}>
                <Button type="link" icon={<LinkOutlined />} />
              </a>
            ) : (
              <Popover
                placement="topRight"
                content={i18n.translate(`common.licence.content`)}
                title={i18n.translate(`common.licence.title`)}
              >
                <Button disabled type="link" icon={<LinkOutlined />} />
              </Popover>
            )}
          </Row>
          {record.pmPdfUuid ? <Row>
            <Text className="decText"> {i18n.translate('dkom.table.pmPdf')}: </Text>
            {record.pmPdfUuid ? (
              <Button onClick={() => onFileView(record.pmPdfUuid, record, 'decision', 'PM odluka')} type="link" icon={<FilePdfOutlined />} />
            ) : (
              <Popover
                placement="topRight"
                content={i18n.translate(`common.licence.content`)}
                title={i18n.translate(`common.licence.title`)}
              >
                <Button disabled type="link" className="cardBtn" icon={<FilePdfOutlined />} />
              </Popover>
            )}
          </Row> : null}
          {record.zznpPdfUuid ? <Row>
            <Text className="decText"> {i18n.translate('dkom.table.zznpPdf')}: </Text>
            {record.zznpPdfUuid ? (
              <Button onClick={() => onFileView(record.zznpPdfUuid, record, 'decision', 'ZZNP odluka')} type="link" icon={<FilePdfOutlined />} />
            ) : (
              <Popover
                placement="topRight"
                content={i18n.translate(`common.licence.content`)}
                title={i18n.translate(`common.licence.title`)}
              >
                <Button disabled type="link" className="cardBtn" icon={<FilePdfOutlined />} />
              </Popover>
            )}
          </Row> : null}
        </Col>
      ),
    },
    {
      title: i18n.translate('vus.table.class'),
      dataIndex: 'decision',
      key: 'decision',
      width: '140px',
      ...getColumnSearchProps('decision'),
    },
    {
      title: i18n.translate('vus.table.customer'),
      dataIndex: 'client',
      key: 'client',
      width: '140px',
      ...getColumnSearchProps('client'),
    },
    {
      title: i18n.translate('vus.table.appellant'),
      dataIndex: 'appellant',
      key: 'appellant',
      width: '180px',
      ...getColumnSearchProps('appellant'),
    },
    {
      title: i18n.translate('vus.table.plaintiff'),
      dataIndex: 'plaintiff',
      key: 'plaintiff',
      width: '180px',
      ...getColumnSearchProps('plaintiff'),
    },
    {
      title: i18n.translate('vus.table.topic'),
      dataIndex: 'subject',
      key: 'subject',
      width: '240px',
      ...getColumnSearchProps('subject'),
    },
    {
      title: i18n.translate('vus.table.type'),
      dataIndex: 'type',
      key: 'type',
      width: '130px',
      render: (text, record, index) => <span>{text ? text : '—'}</span>,
      //...getColumnSearchProps('type'),
      filters: getTypeFilters(),
    },
    {
      title: i18n.translate('vus.table.dateOfApp'),
      dataIndex: 'appealInvestmentDate',
      key: 'appealInvestmentDate',
      width: '135px',
      //...getColumnSearchProps('appealInvestmentDate'),
    },
    {
      title: i18n.translate('vus.table.dateOfDKOMDecision'),
      dataIndex: 'decisionDate',
      key: 'decisionDate',
      width: '200px',
      //...getColumnSearchProps('decisionDate'),
    },
    {
      title: i18n.translate('vus.table.dateOfDKOMPubl'),
      dataIndex: 'decisionPublishDate',
      key: 'decisionPublishDate',
      width: '250px',
      //...getColumnSearchProps('decisionPublishDate'),
    },
    {
      title: i18n.translate('vus.table.waitTime'),
      dataIndex: 'decisionWaitTime',
      key: 'decisionWaitTime',
      width: '170px',
      sorter: (a, b) => a.decisionWaitTime - b.decisionWaitTime,
      sortDirections: ['descend', 'ascend'],
      render: (text, record, index) => <span>{record.decisionWaitTime > 0 ? `${record.decisionWaitTime} ${i18n.t('dkom.table.days')}` : ``}</span>,
    },
    {
      title: i18n.translate('vus.table.dateOfVUSVerdict'),
      dataIndex: 'verdictDate',
      key: 'verdictDate',
      width: '190px',
      //...getColumnSearchProps('verdictDate'),
    },
    {
      title: i18n.translate('vus.table.VUSverdictPubl'),
      dataIndex: 'verdictPublishDate',
      key: 'verdictPublishDate',
      width: '200px',
      //...getColumnSearchProps('verdictPublishDate'),
    },
  ];

  return (
    <>
      <div className="vus-table-container">
        {filtered && (
          <div className="vus-table-remove-filter">
            <Button onClick={handleClearAllFilters}>{i18n.translate(`genericButtons.clearTableFilter`)}</Button>
          </div>
        )}
        <Table
          style={{ paddingBottom: 24 }}
          scroll={{ x: 1500, y: 680 }}
          columns={userAuth.user?.roles === UserRoleEnum.ADMIN ? columns : columnsUser}
          dataSource={data}
          pagination={false}
          onChange={onChange}
          locale={{
            emptyText: i18n.t('common.noData'),
            triggerDesc: i18n.t('common.sortDesc'),
            triggerAsc: i18n.t('common.sortAsc'),
            cancelSort: i18n.t('common.cancelSort'),
            filterReset: i18n.t('common.reset'),
          }}
          className="vusTable"
        />
      </div>
      <RhPracticeDetailsDrawer drawerTitle={drawerTitle} data={detailsData} drawerOpen={drawerOpen} onDrawerClose={onDrawerClose} verdict={currentVerdict} decision={currentVerdict?.fullDecision} tags={tags} licenceData={licenceData} validLicences={validLicences} />
    </>
  );
}