export enum OrganizationConstants {
  GET_ALL_SUCCESS = 'OrganizationConstants_GET_ALL_SUCCESS',
  GET_SINGLE_SUCCESS = 'OrganizationConstants_GET_SINGLE_SUCCESS',
  CREATE_SUCCESS = 'OrganizationConstants_CREATE_SUCCESS',
  UPDATE_SUCCESS = 'OrganizationConstants_UPDATE_SUCCESS',
  CLEAR_CURRENT = 'OrganizationConstants_CLEAR_CURRENT',
  CLEAR_LIST = 'OrganizationConstants_CLEAR_LIST',
  DELETE = 'OrganizationConstants_DELETE',
  ERROR = 'OrganizationConstants_ERROR'
}
