import { Button, Col, Row, Typography, Image, Space, Spin, Popover } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { EUR_CONVERSION, LicenceInterface } from '../../..';
import { AdminRoutes } from '../../../../features/admin';
import { AppRoutes } from '../../../../features/app';
import { FileService, UserActivityLogService } from '../../../api';
import { UserRoleEnum } from '../../../enums';
import { LicenceEnum } from '../../../enums/licence.enum';
import { StoreStateInterface } from '../../../redux';
import { i18n, ViewerService } from '../../../services';
import { UserActionTypeEnum } from '../../../enums/userActionType.enum';

const { Text } = Typography;

interface Props {
  id?: number;
  title: string;
  price: number;
  dateIssued: string;
  pdf: string;
  contentPdf: string;
  frontPageUuid?: string;
  type?: string;
  frontPageUrl?: string;
  licenceData?: LicenceInterface;
  validLicences?: LicenceInterface[];
}

export default function NewsletterCardComponent({
  id,
  title,
  price,
  dateIssued,
  pdf,
  frontPageUuid,
  type,
  frontPageUrl,
  licenceData,
  validLicences
}: Props) {
  const userAuth = useSelector((state: StoreStateInterface) => state.auth);

  const [biltenImage, setBiltenImage] = useState('');
  const [imageGet, setImageGet] = useState(false);

  useEffect(() => {
    if (type === 'bilten' && !frontPageUrl) {
      //getBiltenImage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getBiltenImage = () => {
    //if (!frontPageUuid) return;
    FileService.temp(frontPageUuid as string).subscribe(
      (response: any) => {
        if (response.url) {
          let url = response.url + '/bjn';
          setBiltenImage(url);
        }
        setImageGet(true);
      },
      (error: Error) => {
        setImageGet(true);
      }
    );
  };

  const onBiltenPdfView = () => {
    if (!pdf) return;
    if (userAuth?.user?.roles !== UserRoleEnum.ADMIN) {
      let userActivityLogData: any = {
        data: {
          type: 'user_activity_log',
          attributes: {
            action: UserActionTypeEnum.FILE_OPENED,
            extra: JSON.stringify({type: 'newsletter', documentType: 'Bilten', title: title})
          },
          relationships: {
            user: {
              data: {
                id: userAuth?.user?.id,
                type: 'user'
              },
            },
          },
        },
      };

      UserActivityLogService.create(userActivityLogData);
    }
    ViewerService.goToPdf(pdf);
  };

  // const onBiltenContentPdfView = () => {
  //   if (!contentPdf) return;
  //   FileService.temp(contentPdf as string).subscribe(
  //     (response: any) => {
  //       if (response.url) {
  //         notification['success']({ message: i18n.translate('api.downloadSuccessMessage'), duration: 2 });
  //         let url = response.url + '/bjn';
  //         window.open(url, '_blank');
  //       } else {
  //         notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
  //       }
  //     },
  //     (error: Error) => {
  //       notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
  //     }
  //   );
  // };

  const currencyFormat = (price: any) => parseFloat(price).toLocaleString('hr-HR', {minimumFractionDigits:2, maximumFractionDigits:2});

  const priceEuro = () => {
    let p = price;
    if (!p) p = 0;
    return `${currencyFormat(p)} EUR (+PDV)`;
  }

  const priceHrk = () => {
    let p: any = price;
    if (!p) p = 0;
    p = p * EUR_CONVERSION;
    p = currencyFormat(p);
    if (p === '319,99') p = '320,00';
    if (p === '220,01') p = '220,00';
    return `${p} HRK (+PDV)`;
  }

  const checkLicenceYear = () => {
    const newsletterYear = getYear();

    if (userAuth.user?.roles === UserRoleEnum.ADMIN) return true;

    if (validLicences?.length) {
      let access = false;

      validLicences.forEach(licenceData => {
        if (newsletterYear == licenceData.attributes?.year) {
          access = true;
        }
        if (licenceData.attributes?.childrenLicences?.length) {
          if (licenceData.attributes?.childrenLicences?.find((x) => x.active && x.year == newsletterYear)) {
            access = true;
          }
        }
      });

      return access;
    } else {
      return false;
    }
  };

  const getYear = () => {
    let yearString = i18n.translate('common.unsorted');

    if (dateIssued) yearString = moment(dateIssued).format('YYYY').toString();

    return yearString;
  };

  const isDisabled = () => {
    return !checkLicenceYear();
  };

  return (
    <div className="newsletter">
      <Col>
        <Row>
          <Image
            style={{ maxWidth: 215, maxHeight: 304 }}
            preview={false}
            alt="BJN Naslovnica"
            fallback={require('../../../../assets/images/naslovnicaBJN2.png')}
            src={frontPageUrl}
            placeholder={<Spin style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}} />}
          />
        </Row>
        <Row wrap>
          <Space direction="vertical">
            <Text strong className="NLname">
              {title}
            </Text>
            <Text type="secondary">{i18n.translate(`newsletters.price`)}:</Text>
            <Text strong>{priceEuro()}</Text>
          </Space>
        </Row>
        <Row align="middle" justify="space-between">
          {isDisabled() ?
          <Popover
            placement="topRight"
            content={i18n.translate(`common.licence.content`)}
            title={i18n.translate(`common.licence.title`)}
          >
          <Button disabled type="primary">
            {i18n.translate(`newsletters.read`)}
          </Button>
          </Popover>
          : null}
          {!isDisabled() ?
          <Button onClick={onBiltenPdfView} type="primary">
            {i18n.translate(`newsletters.read`)}
          </Button> : null}
          <Link
            to={
              userAuth.user?.roles === UserRoleEnum.ADMIN
                ? AdminRoutes.NEWSLETTERS_VIEW.fullPath + `/${id}`
                : AppRoutes.NEWSLETTERS_VIEW.fullPath + `/${id}`
            }
          >
            <Button type="link">{i18n.translate(`newsletters.learnMore`)}</Button>
          </Link>
        </Row>
      </Col>
    </div>
  );
}
