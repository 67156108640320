import React, { useEffect, useState } from 'react';
import { HomeOutlined } from '@ant-design/icons';
import { Breadcrumb, Col, notification, Row, Typography } from 'antd';
import {
  BiltenStateInterface,
  clearNoteAction,
  FullOrganizationUnitResponseInterfaceSingle,
  getSingleBiltenAction,
  i18n,
  LicenceInterface,
  LicenceResponseInterfaceAll,
  LicenceService,
  NoteResponseInterfaceAll,
  NoteService,
  OrganizationUnitService,
  ReviewComponent,
  StoreStateInterface,
} from '../../../../common';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { LicenceEnum } from '../../../../common/enums/licence.enum';

function NewsletterViewPageComponent() {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const { Paragraph, Text } = Typography;

  const newsletter: BiltenStateInterface = useSelector((state: StoreStateInterface) => state.bilten);
  const userAuth = useSelector((state: StoreStateInterface) => state.auth);

  const [orgUnitInfo, setOrgUnitInfo] = useState<FullOrganizationUnitResponseInterfaceSingle>();
  const [licenceData, setLicenceData] = useState<LicenceInterface>();
  const [allValidLicences, setAllValidLicences] = useState<LicenceInterface[]>([]);
  const [noteData, setNoteData] = useState<NoteResponseInterfaceAll>();

  useEffect(() => {
    clearNoteAction();
    NoteService.getAll(userAuth.user?.id, false).subscribe(
      (response: NoteResponseInterfaceAll) => {
        setNoteData(response); 
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
      }
    );
  }, [userAuth?.user?.id]);

  useEffect(() => {
    localStorage.removeItem('transferFilters');
    dispatch(getSingleBiltenAction(parseInt(id)));
  }, []);

  useEffect(() => {
    if (userAuth.user?.organizationUnit?.id) {
      OrganizationUnitService.getSingle(userAuth.user?.organizationUnit?.id).subscribe(
        (response: FullOrganizationUnitResponseInterfaceSingle) => {
          setOrgUnitInfo(response);
        },
        (error: Error) => {
          notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
        }
      );
    }
  }, [userAuth.user]);

  useEffect(() => {
    if (orgUnitInfo?.data?.id) {
      LicenceService.getAll(undefined, `filter[organizationUnit.id]=${orgUnitInfo?.data?.id}`, 'sort=id', `page[offset]=0&page[limit]=999999`).subscribe(
        (response: LicenceResponseInterfaceAll) => {
          if (response?.data?.length) {
            let validLicences: LicenceInterface[] = [];

            response?.data?.forEach(licence => {
              if (licence.attributes?.licenceUsers?.find((x) => x.user?.id == userAuth.user?.id) && !licence.relationships?.licenceParent?.data && licence.attributes?.notesNum > 0) {
                setLicenceData(licence);
                const standardLicence = response.data.find(
                  (x) => x.attributes?.licenceUsers?.find((y) => y.user?.id == userAuth.user?.id) && !x.relationships?.licenceParent?.data && x.attributes?.active && x.attributes?.licenceType === LicenceEnum.STANDARD && licence.attributes?.notesNum > 0 && moment().isBefore(moment(licence?.attributes?.to).startOf('day'))
                );
                const premiumLicence = response.data.find(
                  (x) => x.attributes?.licenceUsers?.find((y) => y.user?.id == userAuth.user?.id) && !x.relationships?.licenceParent?.data && x.attributes?.active && x.attributes?.licenceType === LicenceEnum.PREMIUM && licence.attributes?.notesNum > 0 && moment().isBefore(moment(licence?.attributes?.to).startOf('day'))
                );
                if (standardLicence && standardLicence.attributes?.notesNum > 0) setLicenceData(standardLicence);
                if (premiumLicence && premiumLicence.attributes?.notesNum > 0) setLicenceData(premiumLicence);
              }
              if (licence.attributes?.licenceUsers?.find(x => x.user?.id == userAuth.user?.id) && !licence.relationships?.licenceParent?.data && licence.attributes?.active && licence?.attributes?.licenceType !== LicenceEnum.FREE && moment().isBefore(moment(licence?.attributes?.to).startOf('day'))) {
                validLicences.push(licence);
              }
            });

            setAllValidLicences([...validLicences]);
          }
        },
        (error: Error) => {
          notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
        }
      );
    }
  }, [orgUnitInfo?.data]);

  return (
    <div className="newsletterView w100-h100">
      <div className="newsletterView___breadcrumbs">
        <Breadcrumb>
          <Breadcrumb.Item>
            <HomeOutlined />
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span>{i18n.translate('breadcrumbs.newsletters')}</span>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span>{newsletter?.currentBilten?.attributes?.name}</span>
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>
      {newsletter?.currentBilten?.attributes ? (
        <>
          <Row justify="space-between" align="middle">
            <Col style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
              <div className="newsletterView__title">{newsletter?.currentBilten?.attributes?.name}</div>
            </Col>
          </Row>

          <ReviewComponent
            bilten={newsletter}
            licenceData={licenceData}
            notes={noteData}
            validLicences={allValidLicences}
          />

          <div className="newsletterView___description">
            <Row wrap={false} gutter={150}>
              <Col span={12}>
                <Text className="newsletterView__subtitle">{i18n.translate(`newsletters.goal`)}</Text>
                <Paragraph>{newsletter?.currentBilten?.attributes?.goalText}</Paragraph>
              </Col>

              <Col span={12}>
                <Text className="newsletterView__subtitle">{i18n.translate(`newsletters.resolve`)}</Text>
                <Paragraph>{newsletter?.currentBilten?.attributes?.resolvesText}</Paragraph>
              </Col>
            </Row>
          </div>
          <div style={{ height: 1, marginTop: 120 }}></div>
        </>
      ) : null}
    </div>
  );
}

export default NewsletterViewPageComponent;
