import { HomeOutlined } from '@ant-design/icons';
import { Breadcrumb, Button, notification, Pagination, PaginationProps, Tabs } from 'antd';
import { FilterValue, SorterResult, TableCurrentDataSource, TablePaginationConfig } from 'antd/lib/table/interface';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  AllegationEsResponseInterface,
  AllegationInterface,
  AllegationService,
  AllegationStateInterface,
  AppealEsResponseInterface,
  AppealInterface,
  AppealService,
  AppealStateInterface,
  clearDecisionAction,
  clearDecisionListAction, clearNoteAction, DecisionEsResponseInterface, DecisionInterface, DecisionService,
  DecisionStateInterface,
  DKOMComplaintCardComponent,
  DKOMTableComponent,
  FilterComponent,
  getAllAllegationsAction,
  getAllAppealsAction,
  getAllDecisionsAction,
  getAllSummarysAction,
  getScreenTagsByTypeAction,
  i18n, LicenceResponseInterfaceSingle,
  LicenceService,
  LicenceUserResponseInterfaceAll,
  LicenceUserService, NavigationService, NoDataComponent, NoteResponseInterfaceAll,
  NoteService,
  QueryFilter,
  SorterInterface, StoreStateInterface, SummaryEsResponseInterface, SummaryInterface, SummaryService, SummaryStateInterface, TagScreenResponseInterface, TagScreenService, TagScreenStateInterface
} from '../../../../common';
import DKOMAppealCardComponent from '../../../../common/components/cards/dkom/dkomAppealCards/dkomAppealCard.component';
import DKOMSummaryCardComponent from '../../../../common/components/cards/dkom/dkomSummaryCards/dkomSummaryCard.component';
import { AdminRoutes } from '../../_router/admin.routes';

function DKOMDecisionsPageComponent() {
  const dispatch = useDispatch();

  const userAuth = useSelector((state: StoreStateInterface) => state.auth);
  const decisions: DecisionStateInterface = useSelector((state: StoreStateInterface) => state.decision);
  const allegations: AllegationStateInterface = useSelector((state: StoreStateInterface) => state.allegation);
  const appeals: AppealStateInterface = useSelector((state: StoreStateInterface) => state.appeal);
  const summary: SummaryStateInterface = useSelector((state: StoreStateInterface) => state.summary);
  const tags: TagScreenStateInterface = useSelector((state: StoreStateInterface) => state.tagScreen);

  const [selectedTab, setSelectedTab] = useState('decision');
  const [filterValues, setFilterValues] = useState();

  const [dkomFilters, setDkomFilters] = useState<TagScreenResponseInterface[]>([]);
  const [vusFilters, setVusFilters] = useState<TagScreenResponseInterface[]>([]);

  const [esDataDecision, setEsDataDecision] = useState<DecisionEsResponseInterface>();
  const [esDataAllegation, setEsDataAllegation] = useState<AllegationEsResponseInterface>();
  const [esDataAppeal, setEsDataAppeal] = useState<AppealEsResponseInterface>();
  const [esDataSummary, setEsDataSummary] = useState<SummaryEsResponseInterface>();

  const [keyword, setKeyword] = useState('');

  const [esSearch, setEsSearch] = useState(true);

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);

  const [licenceData, setLicenceData] = useState<LicenceResponseInterfaceSingle>();
  const [noteData, setNoteData] = useState<NoteResponseInterfaceAll>();
  const [queryFilter, setQueryFilter] = useState<Array<QueryFilter>>(new Array<QueryFilter>());

  const [sortBy, setSortBy] = useState<SorterInterface | undefined>();

  const [stopRender, setStopRender] = useState(false);

  const [exactMatch, setExactMatch] = useState<boolean>(true);

  useEffect(() => {
    clearNoteAction();
    NoteService.getAll(userAuth.user?.id, false).subscribe(
      (response: NoteResponseInterfaceAll) => {
        setNoteData(response);
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
      }
    );
  }, [userAuth?.user?.id]);


  useEffect(() => {
    dispatch(clearDecisionAction());
    dispatch(clearDecisionListAction());
    dispatch(getScreenTagsByTypeAction('decision'));
    //dispatch(getAllDecisionsAction(undefined, `filter[active]=true`, 'sort=-id', `page[offset]=${pageSize * (page - 1)}&page[limit]=${pageSize}`));
    esSearchFunctionDecision(false, { from: pageSize * (page - 1), size: pageSize });

    TagScreenService.getScreenTagsByType('decision').subscribe(
      (response: Array<TagScreenResponseInterface>) => {
        setDkomFilters(response);
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
      }
    );

    TagScreenService.getScreenTagsByType('verdict').subscribe(
      (response: Array<TagScreenResponseInterface>) => {
        setVusFilters(response);
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    LicenceUserService.getAll(
      undefined,
      `filter[user.id]=${userAuth?.user?.id}`,
      undefined,
      `page[offset]=0&page[limit]=999999`
    ).subscribe((response: LicenceUserResponseInterfaceAll) => {
      if (response.data?.length && response.data[0]) {
        const licenceId = response?.data[0]?.relationships?.licence?.data?.id;
        if (licenceId) {
          LicenceService.getSingle(licenceId).subscribe((response: LicenceResponseInterfaceSingle) => {
            setLicenceData(response);
          });
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const esSearchFunctionDecision = (allOpinions: boolean, pageSorted: { from: number, size: number }, keyword?: string, tagIds?: any, queryFilter?: Array<QueryFilter>, sort?: SorterInterface) => {
    DecisionService.elasticSearch(allOpinions, pageSorted, exactMatch, keyword, queryFilter, tagIds, sort, undefined).subscribe(
      (response: DecisionEsResponseInterface) => {
        setEsDataDecision(response);
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
      }
    );
  };

  const esSearchFunctionAllegation = (allOpinions: boolean, pageSorted: { from: number, size: number }, keyword?: string, tagIds?: any) => {
    AllegationService.elasticSearch(allOpinions, pageSorted, exactMatch, keyword, tagIds).subscribe(
      (response: AllegationEsResponseInterface) => {
        setEsDataAllegation(response);
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
      }
    );
  };

  const esSearchFunctionAppeal = (allOpinions: boolean, pageSorted: { from: number, size: number }, keyword?: string, tagIds?: any) => {
    AppealService.elasticSearch(allOpinions, pageSorted, exactMatch, keyword, tagIds).subscribe(
      (response: AppealEsResponseInterface) => {
        setEsDataAppeal(response);
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
      }
    );
  };

  const esSearchFunctionSummary = (allOpinions: boolean, pageSorted: { from: number, size: number }, keyword?: string, tagIds?: any) => {
    SummaryService.elasticSearch(allOpinions, pageSorted, exactMatch, keyword, tagIds).subscribe(
      (response: SummaryEsResponseInterface) => {
        setEsDataSummary(response);
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
      }
    );
  };

  const getTitle = () => {
    let str = '';
    if (selectedTab == 'decision') {
      str = i18n.translate('decisions.title');
    }
    if (selectedTab == 'allegation') {
      str = i18n.translate('allegations.title');
    }
    if (selectedTab == 'appeal') {
      str = i18n.translate('appeals.title');
    }
    if (selectedTab == 'summary') {
      str = i18n.translate('summarys.title');
    }

    return str;
  };

  const getTotal = () => {
    if (selectedTab == 'decision') {
      return esSearch ? esDataDecision?.totalHits : decisions?.decisions?.meta?.totalResourceCount
    }
    if (selectedTab == 'allegation') {
      return esSearch ? esDataAllegation?.totalHits : allegations?.allegations?.meta?.totalResourceCount
    }
    if (selectedTab == 'appeal') {
      return esSearch ? esDataAppeal?.totalHits : appeals?.appeals?.meta?.totalResourceCount
    }
    if (selectedTab == 'summary') {
      return esSearch ? esDataSummary?.totalHits : summary?.summarys?.meta?.totalResourceCount
    }
  };

  const refetch = (allValues: any, tab?: string, columnFilter?: Array<QueryFilter>, sort?: SorterInterface, stopRerender?: boolean) => {
    if ((!columnFilter || !columnFilter?.length) && !stopRerender) {
      setStopRender(true);
      setTimeout(() => {
        setStopRender(false);
      }, 0);
    }
    //setEsSearch(true);
    setFilterValues(allValues);
    setPage(1);
    if (tab === 'decision') {
      //dispatch(getAllDecisionsAction(allValues, `filter[active]=true`, 'sort=-id', `page[offset]=0&page[limit]=${pageSize}`));
      esSearchFunctionDecision(false, { from: 0, size: pageSize }, keyword, allValues, columnFilter, sort);
    }
    if (tab === 'allegation') {
      //dispatch(getAllAllegationsAction(allValues, undefined, 'sort=-id', `page[offset]=0&page[limit]=${pageSize}`));
      esSearchFunctionAllegation(false, { from: 0, size: pageSize }, keyword, allValues);
    }
    if (tab === 'appeal') {
      //dispatch(getAllAppealsAction(allValues, undefined, 'sort=-id', `page[offset]=0&page[limit]=${pageSize}`));
      esSearchFunctionAppeal(false, { from: 0, size: pageSize }, keyword, allValues);
    }
    if (tab === 'summary') {
      //dispatch(getAllSummarysAction(allValues, undefined, 'sort=-id', `page[offset]=0&page[limit]=${pageSize}`));
      esSearchFunctionSummary(false, { from: 0, size: pageSize }, keyword, allValues);
    }
  };

  const navigate = (tab: string) => {
    setSelectedTab(tab);
    //setEsSearch(false);
    setPage(1);
    setPageSize(20);
    if (tab === 'decision') {
      setEsDataDecision(undefined);
      dispatch(getScreenTagsByTypeAction('decision'));
      //dispatch(getAllDecisionsAction(filterValues, `filter[active]=true`, 'sort=-id', `page[offset]=0&page[limit]=20`));
      esSearchFunctionDecision(false, { from: 0, size: 20 }, undefined, filterValues, undefined, undefined);
    }
    if (tab === 'allegation') {
      setEsDataAllegation(undefined);
      dispatch(getScreenTagsByTypeAction('decision_appeal_allegation'));
      //dispatch(getAllAllegationsAction(filterValues, undefined, 'sort=-id', `page[offset]=0&page[limit]=20`));
      esSearchFunctionAllegation(false, { from: 0, size: 20 }, undefined, filterValues);
    }
    if (tab === 'appeal') {
      setEsDataAppeal(undefined);
      dispatch(getScreenTagsByTypeAction('decision_appeal_response'));
      //dispatch(getAllAppealsAction(filterValues, undefined, 'sort=-id', `page[offset]=0&page[limit]=20`));
      esSearchFunctionAppeal(false, { from: 0, size: 20 }, undefined, filterValues);
    }
    if (tab === 'summary') {
      setEsDataSummary(undefined);
      dispatch(getScreenTagsByTypeAction('decision_summary'));
      //dispatch(getAllSummarysAction(filterValues, undefined, 'sort=-id', `page[offset]=0&page[limit]=20`));
      esSearchFunctionSummary(false, { from: 0, size: 20 }, undefined, filterValues);
    }
  };

  const resetSearch = (tab?: string) => {
    setStopRender(true);
    setTimeout(() => {
      setStopRender(false);
    }, 0);
    localStorage.removeItem('transferFilters');
    //setEsSearch(false);
    setPage(1);
    setKeyword('');
    setFilterValues(undefined);
    if (tab === 'decision') {
      setEsDataDecision(undefined);
      //dispatch(getAllDecisionsAction(undefined, `filter[active]=true`, 'sort=-id', `page[offset]=0&page[limit]=${pageSize}`));
      esSearchFunctionDecision(false, { from: 0, size: pageSize });
    }
    if (tab === 'allegation') {
      setEsDataAllegation(undefined);
      //dispatch(getAllAllegationsAction(undefined, undefined, 'sort=-id', `page[offset]=0&page[limit]=${pageSize}`));
      esSearchFunctionAllegation(false, { from: 0, size: pageSize });
    }
    if (tab === 'appeal') {
      setEsDataAppeal(undefined);
      //dispatch(getAllAppealsAction(undefined, undefined, 'sort=-id', `page[offset]=0&page[limit]=${pageSize}`));
      esSearchFunctionAppeal(false, { from: 0, size: pageSize });
    }
    if (tab === 'summary') {
      setEsDataSummary(undefined);
      //dispatch(getAllSummarysAction(undefined, undefined, 'sort=-id', `page[offset]=0&page[limit]=${pageSize}`));
      esSearchFunctionSummary(false, { from: 0, size: pageSize });
    }
  };

  const onPaginationChange: PaginationProps['onChange'] = (page, pageSize) => {
    setPage(page);
    setPageSize(pageSize);

    if (selectedTab === 'decision') {
      if (esSearch) {
        esSearchFunctionDecision(false, { from: pageSize * (page - 1), size: pageSize }, keyword, filterValues, queryFilter, sortBy);
      } else {
        //dispatch(getAllDecisionsAction(filterValues, `filter[active]=true`, 'sort=-id', `page[offset]=${pageSize * (page - 1)}&page[limit]=${pageSize}`));
      }
    }
    if (selectedTab === 'allegation') {
      if (esSearch) {
        esSearchFunctionAllegation(false, { from: pageSize * (page - 1), size: pageSize }, keyword, filterValues);
      } else {
        //dispatch(getAllAllegationsAction(filterValues, undefined, 'sort=-id', `page[offset]=${pageSize * (page - 1)}&page[limit]=${pageSize}`));
      }
    }
    if (selectedTab === 'appeal') {
      if (esSearch) {
        esSearchFunctionAppeal(false, { from: pageSize * (page - 1), size: pageSize }, keyword, filterValues);
      } else {
        //dispatch(getAllAppealsAction(filterValues, undefined, 'sort=-id', `page[offset]=${pageSize * (page - 1)}&page[limit]=${pageSize}`));
      }
    }
    if (selectedTab === 'summary') {
      if (esSearch) {
        esSearchFunctionSummary(false, { from: pageSize * (page - 1), size: pageSize }, keyword, filterValues);
      } else {
        //dispatch(getAllSummarysAction(filterValues, undefined, 'sort=-id', `page[offset]=${pageSize * (page - 1)}&page[limit]=${pageSize}`));
      }
    }
  };

  const keywordChanged = (value: string) => {
    setKeyword(value);
  };

  const exactMatchChanged = (exactMatch: boolean) => {
    setExactMatch(exactMatch);
  };

  const convertDataTypeDecision = (decisions: DecisionEsResponseInterface["hits"]): DecisionInterface[] => {
    return decisions?.map((decision) => {
      return {
        id: decision.id,
        type: decision.index,
        attributes: decision.source
      };
    })
  };

  const convertDataTypeAllegation = (allegations: AllegationEsResponseInterface["hits"]): AllegationInterface[] => {
    return allegations?.map((allegation) => {
      return {
        id: allegation.id,
        type: allegation.index,
        attributes: allegation.source
      };
    })
  };

  const convertDataTypeAppeal = (appeals: AppealEsResponseInterface["hits"]): AppealInterface[] => {
    return appeals?.map((appeal) => {
      return {
        id: appeal.id,
        type: appeal.index,
        attributes: appeal.source
      };
    })
  };

  const convertDataTypeSummary = (summarys: SummaryEsResponseInterface["hits"]): SummaryInterface[] => {
    return summarys?.map((summary) => {
      return {
        id: summary.id,
        type: summary.index,
        attributes: summary.source
      };
    })
  };

  const onTagsChange = (changedTags: any, allTags: any) => {
    localStorage.setItem('transferFilters', JSON.stringify(allTags));
  };

  const onChangeVus = (key: string) => {
    if (key == '2') {
      NavigationService.navigate(AdminRoutes.VUS_DECLARATIONS.fullPath);
    }
  };

  const onChangeFilter = (pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>, sorter: SorterResult<any> | SorterResult<any>[], extra: TableCurrentDataSource<any>): void => {
    let sort: SorterInterface | undefined;
    //@ts-ignore
    if (Object.keys(sorter).length > 0 && sorter.column !== undefined) {
      sort = {
        //@ts-ignore
        field: sorter.field.toString() === "verdict" ? "hasVerdict" : sorter.field.toString(),
        //@ts-ignore
        order: sorter.order === "descend" ? "Desc" : "Asc"
      }
    }
    let qf: Array<QueryFilter> = new Array<QueryFilter>();
    for (let key in filters) {
      if (filters[key] !== null && !!filters[key] && filters[key]!.length > 0) {
        if (key === 'type') {
          qf.push({ name: key, value: filters[key]! as number[]});
        } else {
          qf.push({ name: key, value: filters[key]![0].toString(), exactMatch: filters[key]![1] as boolean });
        }
      }
    }
    setQueryFilter(qf)
    setSortBy(sort)
    refetch(filterValues, 'decision', qf, sort, true)
  }

  const clearColumnFilters = () => {
    setQueryFilter([]);
    refetch(filterValues, 'decision', [])
  }

  const getAggregation = () => {
    if (selectedTab === 'decision') {
      return esDataDecision?.aggregation?.buckets;
    }
    if (selectedTab === 'allegation') {
      return esDataAllegation?.aggregation?.buckets;
    }
    if (selectedTab === 'appeal') {
      return esDataAppeal?.aggregation?.buckets;
    }
    if (selectedTab === 'summary') {
      return esDataSummary?.aggregation?.buckets;
    }
  };

  return (
    <div className="dkomDecisions w100-h100">
      <div className="dkomDecisions__breadcrumbs">
        <Breadcrumb>
          <Breadcrumb.Item>
            <HomeOutlined />
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span>{i18n.translate('breadcrumbs.rh-practice')}</span>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span>{i18n.translate('menu.dkom-decisions')}</span>
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="dkomDecisions__admin">
        <div className="title">{i18n.translate('dkom.title')}</div>
        <Link to={AdminRoutes.DKOM_DECISIONS.fullPath + `/new`} key={AdminRoutes.DKOM_DECISIONS.path + `/new`}>
          <Button
            className="button"
            type="primary"
            size="large"
            style={{ fontSize: 16, fontWeight: 700, borderRadius: 0 }}
          >
            {i18n.translate('decisions.addButton')}
          </Button>
        </Link>
      </div>
      <div className='dkomDecisions__tabs'>
        <Tabs onChange={onChangeVus} defaultActiveKey="1" size='large'>
          <Tabs.TabPane tab={i18n.translate('decisions.tabs.dkom')} key="1" />
          <Tabs.TabPane tab={i18n.translate('verdicts.tabs.vus')} key="2" />
        </Tabs>
      </div>
      {tags.tagScreens && tags.tagScreens.length && dkomFilters && dkomFilters.length && vusFilters && vusFilters.length ? (
        <FilterComponent
          aggregation={getAggregation()}
          filterAccess
          filters={tags.tagScreens}
          tab={selectedTab}
          onFilterChange={refetch}
          onTabChange={navigate}
          onFilterRemove={resetSearch}
          keyword={keyword}
          onKeywordChange={keywordChanged}
          onTagChange={onTagsChange}
          transferEnabled
          dkomFilters={dkomFilters}
          vusFilters={vusFilters}
          onExactMatchChange={exactMatchChanged}
          alternativeLayout
          hideFilter
        />
      ) : null}
      <div className="subtitle">{getTitle()}</div>
      {selectedTab == 'decision' && !stopRender ? (
        <DKOMTableComponent clearColumnFilters={clearColumnFilters} onChange={onChangeFilter} decisions={esSearch ? convertDataTypeDecision(esDataDecision?.hits as DecisionEsResponseInterface["hits"]) : decisions.decisions?.data} tags={tags.tagScreens} refreshTable={() => refetch(filterValues, 'decision')} />
      ) : null}
      {selectedTab == 'allegation' ? (
        !esSearch ? (
          allegations.allegations?.data?.length ? (
            [...(allegations.allegations?.data)].map((allegation, i) => (
              <DKOMComplaintCardComponent key={'complaint' + i} complaint={allegation} notes={noteData} />
            ))
          ) : (
            <NoDataComponent />
          )
        ) : (
          esDataAllegation?.hits?.length ? (
            [...(convertDataTypeAllegation(esDataAllegation?.hits))].map((allegation, i) => (
              <DKOMComplaintCardComponent key={'complaint' + i} complaint={allegation} notes={noteData} />
            ))
          ) : (
            <NoDataComponent />
          )
        )
      ) : null}
      {selectedTab == 'appeal' ? (
        !esSearch ? (
          appeals.appeals?.data?.length ? (
            [...(appeals.appeals?.data)].map((appeal, i) => (
              <DKOMAppealCardComponent key={'appeal' + i} appeal={appeal} notes={noteData} />
            ))
          ) : (
            <NoDataComponent />
          )
        ) : (
          esDataAppeal?.hits?.length ? (
            [...(convertDataTypeAppeal(esDataAppeal?.hits))].map((appeal, i) => (
              <DKOMAppealCardComponent key={'appeal' + i} appeal={appeal} notes={noteData} />
            ))
          ) : (
            <NoDataComponent />
          )
        )
      ) : null}
      {selectedTab == 'summary' ? (
        !esSearch ? (
          summary.summarys?.data?.length ? (
            [...(summary.summarys?.data)].map((summary, i) => (
              <DKOMSummaryCardComponent key={'summary' + i} summary={summary} notes={noteData} />
            ))
          ) : (
            <NoDataComponent />
          )
        ) : (
          esDataSummary?.hits?.length ? (
            [...(convertDataTypeSummary(esDataSummary?.hits))].map((summary, i) => (
              <DKOMSummaryCardComponent key={'summary' + i} summary={summary} notes={noteData} />
            ))
          ) : (
            <NoDataComponent />
          )
        )
      ) : null}
      <Pagination
        style={{ float: 'right', paddingTop: 16, paddingBottom: 16 }}
        onChange={onPaginationChange}
        current={page}
        pageSize={pageSize}
        pageSizeOptions={['10', '20', '50', '100']}
        locale={{ items_per_page: `${i18n.t('pagination.perPage')}` }}
        total={getTotal()}
        showSizeChanger
        responsive
        showTotal={(total) => `${i18n.t('pagination.total')}: ${total}`}
      />
    </div>
  );
}

export default DKOMDecisionsPageComponent;
