import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { BiltenInterface } from '../../../../interfaces';
import { BiltenStateInterface, StoreStateInterface, clearBiltenAction, clearBiltenListAction, getAllBiltensAction } from '../../../../redux';

import { i18n } from '../../../../services';
import { PrintEditionModal, PrintEditionsTableComponent } from '../../../index';

export default function AdminPrintEditionsComponent() {
  const location = useLocation();
  const path = location.pathname.split('/');
  const id = parseInt(path[path.length - 1]);

  const dispatch = useDispatch();
  const data: BiltenStateInterface = useSelector((state: StoreStateInterface) => state.bilten);

  useEffect(() => {
    dispatch(clearBiltenAction());
    dispatch(clearBiltenListAction());
    dispatch(getAllBiltensAction());
  }, []);

  return (
    <div className="printEditions">
      <div className="printEditions__admin">
        <div className="title">{i18n.translate('profile.printEditions.title')}</div>
        <PrintEditionModal style='button' userId={id} printEdition={undefined} biltens={data.biltens?.data as BiltenInterface[]} />
      </div>

      <PrintEditionsTableComponent userId={id} isAdminView={true} biltens={data.biltens?.data as BiltenInterface[]} />
    </div>
  );
}
