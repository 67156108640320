import { Dispatch } from 'redux';
import { notification } from 'antd';
import { RegulationConstants } from './regulation.constants';

import { RegulationService } from '../../api/api.service';
import { RegulationResponseInterfaceAll, RegulationResponseInterfaceSingle } from '../../interfaces';
import { i18n } from '../../services';

export function getAllRegulationsAction(params?: any, filter?: any, sort?: any, pagination?: any) {
  return (dispatch: Dispatch) => {
    return RegulationService.getAll(params, filter, sort, pagination).subscribe(
      (response: RegulationResponseInterfaceAll) => {
        dispatch({
          type: RegulationConstants.GET_ALL_SUCCESS,
          payload: response,
        });
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });

        dispatch({
          type: RegulationConstants.ERROR,
          payload: error,
        });
      }
    );
  };
}

export function getSingleRegulationAction(id: number) {
  return (dispatch: Dispatch) => {
    return RegulationService.getSingle(id).subscribe(
      (response: RegulationResponseInterfaceSingle) => {
        dispatch({
          type: RegulationConstants.GET_SINGLE_SUCCESS,
          payload: response,
        });
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });

        dispatch({
          type: RegulationConstants.ERROR,
          payload: error,
        });
      }
    );
  };
}

export function createRegulationAction(body: RegulationResponseInterfaceSingle) {
  return (dispatch: Dispatch) => {
    return RegulationService.create(body).subscribe(
      (response: RegulationResponseInterfaceSingle) => {
        notification['success']({ message: i18n.translate('api.successMessage'), duration: 2 });

        dispatch({
          type: RegulationConstants.CREATE_SUCCESS,
          payload: response,
        });
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });

        dispatch({
          type: RegulationConstants.ERROR,
          payload: error,
        });
      }
    );
  };
}

export function updateRegulationAction(body: RegulationResponseInterfaceSingle) {
  return (dispatch: Dispatch) => {
    return RegulationService.update(body).subscribe(
      (response: RegulationResponseInterfaceSingle) => {
        notification['success']({ message: i18n.translate('api.successMessage'), duration: 2 });

        dispatch({
          type: RegulationConstants.UPDATE_SUCCESS,
          payload: response,
        });
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });

        dispatch({
          type: RegulationConstants.ERROR,
          payload: error,
        });
      }
    );
  };
}

export function deleteRegulationAction(id: number) {
  return (dispatch: Dispatch) => {
    return RegulationService.delete(id).subscribe(
      (response: any) => {
        dispatch({
          type: RegulationConstants.DELETE
        });
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });

        dispatch({
          type: RegulationConstants.ERROR,
          payload: error,
        });
      }
    );
  };
}

export function clearRegulationAction() {
  return (dispatch: Dispatch) => {
    dispatch({ type: RegulationConstants.CLEAR_CURRENT });
  };
}

export function clearRegulationListAction() {
  return (dispatch: Dispatch) => {
    dispatch({ type: RegulationConstants.CLEAR_LIST });
  };
}
