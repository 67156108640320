import { InfoCircleOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Divider, Form, FormInstance, notification, Popconfirm, Row, Select, Space, Typography } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LicenceService, LicenceUserService, OrganizationService, OrganizationUnitService, PriceService, UpgradeRequestService } from '../../../../api';
import { DisplayDateFormat, EUR_CONVERSION, JavaFormat } from '../../../../constants';
import { UserRoleEnum } from '../../../../enums';
import { LicenceEnum } from '../../../../enums/licence.enum';
import { FullOrganizationResponseInterfaceSingle, FullOrganizationUnitResponseInterfaceSingle, LicenceInterface, LicenceResponseInterfaceAll, LicenceUserInterface, LicenceUserResponseInterfaceAll, LicenceUserResponseInterfaceSingle, PriceInterface, PriceResponseInterfaceAll } from '../../../../interfaces';
import { clearFullUserAction, getSingleFullUserAction, StoreStateInterface } from '../../../../redux';
import { i18n } from '../../../../services';
import { AdminSubscriptionTableComponent, NoDataComponent, SubscriptionCardComponent, UpgradeModal } from '../../../index';
import localeHR from 'antd/es/date-picker/locale/hr_HR';
import localeEN from 'antd/es/date-picker/locale/en_GB';
import UserUpgradeRequestComponent from '../../../tables/userUpgradeRequest/userUpgradeRequest.component';

const { Title, Text } = Typography;
const { Option } = Select;

export default function SubscriptionModelsComponent(/**props ? */) {
  const dispatch = useDispatch();
  const userAuth = useSelector((state: StoreStateInterface) => state.auth);
  const id = userAuth.user?.id;
  const user = useSelector((state: StoreStateInterface) => state.fullUser);
  const [orgUnitInfo, setOrgUnitInfo] = useState<FullOrganizationUnitResponseInterfaceSingle>();
  const [orgInfo, setOrgInfo] = useState<FullOrganizationResponseInterfaceSingle>();
  const [licenceInfo, setLicenceInfo] = useState<LicenceInterface>();
  const [oldLicencesInfo, setOldLicencesInfo] = useState<LicenceInterface[]>([]);
  const [priceInfo, setPriceInfo] = useState<PriceInterface>();
  const [userLicence, setUserLicence] = useState<LicenceUserInterface>();
  const [validLicences, setValidLicences] = useState<LicenceInterface[]>([]);

  const [visible, setVisible] = useState(false);

  const [loadedLatestLicence, setLoadedLatestLicence] = useState(false);

  const [selectedModel, setSelectedModel] = useState<string>('PREMIUM');

  useEffect(() => {
    dispatch(clearFullUserAction());
    dispatch(getSingleFullUserAction(id as number));
  }, [id]);

  useEffect(() => {
    if (user.currentFullUser?.relationships?.organizationUnit?.data?.id) {
      OrganizationUnitService.getSingle(user.currentFullUser?.relationships?.organizationUnit?.data?.id).subscribe(
        (response: FullOrganizationUnitResponseInterfaceSingle) => {
          setOrgUnitInfo(response);
        },
        (error: Error) => {
          notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
        }
      );
    }
  }, [user.currentFullUser]);

  useEffect(() => {
    if (orgUnitInfo?.data?.id) {
    let l: LicenceInterface;
      LicenceService.getAll(undefined, `filter[organizationUnit.id]=${orgUnitInfo?.data?.id}`, 'sort=id', `page[offset]=0&page[limit]=999999`).subscribe(
        (response: LicenceResponseInterfaceAll) => {
          if (response?.data?.length) {
            let oldLicences: LicenceInterface[] = [];
            let validLicences: LicenceInterface[] = [];

            response?.data?.forEach(licence => {
              if (licence.attributes?.licenceUsers?.find(x => x.user?.id == id) && !licence.relationships?.licenceParent?.data) {
                setLicenceInfo(licence);
                l = licence;
                setLoadedLatestLicence(true);
              }
              if (licence.attributes?.licenceUsers?.find(x => x.user?.id == id) && !licence.relationships?.licenceParent?.data && licence.attributes?.active && moment().isBefore(moment(licence?.attributes?.to).startOf('day'))) {
                validLicences.push(licence);
              } else {
                if (licence.attributes?.licenceUsers?.find(x => x.user?.id == id) && !licence.relationships?.licenceParent?.data) oldLicences.push(licence);
              }
            });

            setOldLicencesInfo([...oldLicences]);
            setValidLicences([...validLicences]);
          }

          LicenceUserService.getAll(undefined, undefined, 'sort=-id', `page[offset]=0&page[limit]=999999`).subscribe({
            next: (data: LicenceUserResponseInterfaceAll) => {
              let find = data?.data?.find((e: LicenceUserInterface) => e.relationships?.licence?.data?.id === l?.id);
              setUserLicence(find);
            },
            error: (error: Error) => {
              notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
            }
          })
        },
        (error: Error) => {
          setLoadedLatestLicence(true);
          notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
        }
      );
    }
  }, [orgUnitInfo?.data]);

  useEffect(() => {
    if (orgUnitInfo?.data?.id) {
      OrganizationService.getSingle(orgUnitInfo?.data?.relationships?.organization?.data?.id as number).subscribe(
        (response: FullOrganizationResponseInterfaceSingle) => {
          if (response) {
            setOrgInfo(response);
          }
        },
        (error: Error) => {
          notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
        }
      );
    }
  }, [orgUnitInfo?.data]);

  useEffect(() => {
    if (orgUnitInfo?.data?.id) {
      PriceService.getAll(orgUnitInfo?.data?.id).subscribe(
        (response: PriceResponseInterfaceAll) => {
          if (response?.data?.length && response.data[0]) {
            setPriceInfo(response?.data[0]);
          }
        },
        (error: Error) => {
          notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
        }
      );
    }
  }, [orgUnitInfo?.data]);

  const showModal = (model: string) => {
    setSelectedModel(model);
    setVisible(true);
  };

  const handleUpgradeOk = (form: FormInstance<any>, price: number, isUpgrade: boolean | undefined, isNew: boolean | undefined, isTrial: boolean | undefined) => {
    let values = form.getFieldsValue(true);

    let numberArrayYears: number[] = values?.years?.map((stringYear: string) => parseInt(stringYear));

    let latestYear = Math.max.apply(Math, numberArrayYears);
    //var index = values?.years?.indexOf(latestYear.toString());
    //if (index !== -1) {
      //values?.years?.splice(index, 1);
    //}

    let subs = '0';
    if (Array.isArray(values.subs)) subs = values.subs?.length?.toString();
    if (!Array.isArray(values.subs)) subs = values.subs;

    let extra = {
      isUpgrade: isUpgrade ? true : false,
      isNew: isNew ? true : false,
      isTrial: isTrial ? true : false,
      oldModel: licenceInfo?.attributes?.licenceType,
      oldYear: latestYear.toString(),
      oldFrom: moment(latestYear.toString()).startOf('year').format(JavaFormat),
      oldTo: moment(latestYear.toString()).add(1, 'y').set({'date': 20, 'month': 1}).format(JavaFormat),
      bothYearsSubscription: parseInt(subs) > 1,
      biltenSubscriptionYears: [values.subs].flat(),
      price: price
    }

    const handleSingleYears = () => {
      if (values?.model === LicenceEnum.PREMIUM) {
        if (values?.years?.includes(latestYear.toString()) && values?.years?.includes((latestYear - 1)?.toString())) {
          if (moment().isSame(moment().set('month', 10), 'month')) {
            if ([values.subs].flat().includes('2')) return latestYear.toString();
            if ([values.subs].flat().includes('1')) return (latestYear - 1).toString();
          }
          else return latestYear.toString();
        }
        return latestYear.toString();
      } else {
        if (values?.years?.includes(latestYear.toString()) && values?.years?.includes((latestYear - 1)?.toString())) {
          // if (parseInt(subs) == 0) return `${latestYear},${latestYear - 1}`;
          if (parseInt(subs) == 0) return latestYear.toString();
          if (moment().isSame(moment().set('month', 10), 'month')) {
            if ([values.subs].flat().includes('2')) return latestYear.toString();
            if ([values.subs].flat().includes('1')) return (latestYear - 1).toString();
          }
          else return latestYear.toString();
        }
        return latestYear.toString();
      }
    };

    let upgradeBody = {
      data: {
        type: 'upgrade_request' as 'upgrade_request',
        attributes: {
          licenceType: values?.model,
          printedCopyNum: parseInt(subs) > 1 ? 3 : parseInt(subs) * 3,
          printedCopyYears: parseInt(subs) > 1 ? `${latestYear},${latestYear - 1}` : handleSingleYears(),
          additionalYears: values?.years?.length ? values?.years?.join(',') : null,
          status: 'ZAPRIMLJENO',
          createdDate: moment().format(JavaFormat),
          extra: JSON.stringify(extra),
        },
        relationships: {
          user: {
            data: {
              id: id as number,
              type: "user" as 'user'
            }
          },
        }
      }
    }

    UpgradeRequestService.create(upgradeBody).subscribe(
      (response: any) => {
        dispatch(clearFullUserAction());
        dispatch(getSingleFullUserAction(id as number));
        notification['success']({ message: i18n.translate('api.successMessage'), duration: 2 });
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
      }
    );

    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const getBasicYears = (licence: LicenceInterface) => {
    let yearArray = [];
    if (licence?.attributes?.year && licence?.attributes?.licenceType === LicenceEnum.STANDARD) {
      yearArray.push(licence?.attributes?.year);
    }
    if (licence?.attributes?.childrenLicences?.length) {
      licence?.attributes?.childrenLicences?.forEach(child => {
        if (licence?.attributes?.licenceType === LicenceEnum.STANDARD) {
          yearArray.push(child?.year);
        }
      });
    }

    return yearArray;
  };

  const getPremiumYears = (licence: LicenceInterface) => {
    let yearArray = [];
    if (licence?.attributes?.year && licence?.attributes?.licenceType === LicenceEnum.PREMIUM) {
      yearArray.push(licence?.attributes?.year);
    }
    if (licence?.attributes?.childrenLicences?.length) {
      licence?.attributes?.childrenLicences?.forEach(child => {
        if (licence?.attributes?.licenceType === LicenceEnum.PREMIUM) {
          yearArray.push(child?.year);
        }
      });
    }

    return yearArray;
  };

  const getManagerUser = () => {
    let manager = '';
    if (licenceInfo?.attributes?.licenceUsers?.length) {
      const foundManager = licenceInfo?.attributes?.licenceUsers?.find(x => x?.user?.roles === UserRoleEnum.MANAGER);
      if (foundManager) {
        manager = foundManager.user?.name ? foundManager.user?.name : '';
      }
    }

    return manager;
  };

  const getAddress = () => {
    let address = '';
    if (licenceInfo?.attributes?.licenceUsers?.length) {
      const foundUser = licenceInfo?.attributes?.licenceUsers?.find(x => x?.user?.id == user.currentFullUser?.id);
      if (foundUser) {
        address = foundUser?.user?.organizationUnit?.organization?.streetAddress ? foundUser?.user?.organizationUnit?.organization?.streetAddress : '';
      }
    }

    return address;
  };

  const onFinishProfile = (values: any) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });

    const userObj = {
      data: {
        id: user.currentFullUser?.id,
        type: 'user',
        attributes: {
          active: !values.status
        }
      }
    }

    const licenceObj = {
      data: {
        id: licenceInfo?.id,
        type: 'licence',
        attributes: {
          to: moment(values.expDate).format(JavaFormat),
          biltenSubscription: values.printSub,
          educationsNum: values.lecturePackage
        }
      }
    }

    LicenceService.updateAny(licenceObj).subscribe(
      (response: any) => {
        notification['success']({ message: i18n.translate('api.successMessage'), duration: 2 });
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
      }
    );
  };

  const setMostPowerfulModel = (model: 'FREE' | 'STANDARD' | 'PREMIUM') => {
    let returnBoolean = false;

    if (model === 'FREE') {
      if (!validLicences?.some(x => x.attributes?.licenceType === LicenceEnum.STANDARD) && !validLicences?.some(x => x.attributes?.licenceType === LicenceEnum.PREMIUM)) {
        returnBoolean = true;
      }
    }
    if (model === 'STANDARD') {
      if (validLicences?.some(x => x.attributes?.licenceType === LicenceEnum.STANDARD) && !validLicences?.some(x => x.attributes?.licenceType === LicenceEnum.PREMIUM)) {
        returnBoolean = true;
      }
    }
    if (model === 'PREMIUM') {
      if (validLicences?.some(x => x.attributes?.licenceType === LicenceEnum.PREMIUM)) {
        returnBoolean = true;
      }
    }

    return returnBoolean;
  };

  return (
    <div className="subModels">
      {user.currentFullUser && orgUnitInfo?.data && loadedLatestLicence ? (
        <>
          <Title className="subModels__title" level={5} style={{marginTop: 16, marginBottom: 16}}>
            {i18n.translate('profile.subModels.current')}
          </Title>
          <div className="subModels___content">
            <Row style={{ display: 'flex', flexWrap: 'wrap' }} gutter={24}>
              <Col>
                <SubscriptionCardComponent
                  title={i18n.translate('profile.subModels.card.starter')}
                  btnText={i18n.translate('register.licence.subs.starter')}
                  onClick={() => showModal('FREE')}
                  active={setMostPowerfulModel('FREE')}
                  isFree={true}
                />
              </Col>
              <Col>
                <SubscriptionCardComponent
                  title={i18n.translate('profile.subModels.card.basic')}
                  btnText={i18n.translate('register.licence.subs.standard')}
                  onClick={() => showModal('STANDARD')}
                  active={setMostPowerfulModel('STANDARD')}
                />
              </Col>
              <Col>
                <SubscriptionCardComponent
                  title="Premium model"
                  btnText={i18n.translate('register.licence.subs.premium')}
                  onClick={() => showModal('PREMIUM')}
                  active={setMostPowerfulModel('PREMIUM')}
                />
              </Col>
            </Row>
          </div>

          {id ? <UserUpgradeRequestComponent userId={id} /> : null}

          <Title style={{marginTop: 16, marginBottom: 16}} className="subModels__title" level={5}>
            {i18n.translate('profile.subModels.valid')}
          </Title>
          <AdminSubscriptionTableComponent user={user} orgUnitInfo={orgUnitInfo} licenceInfo={licenceInfo} oldLicencesInfo={validLicences} />

          <Title style={{marginTop: 16, marginBottom: 16}} className="subModels__title" level={5}>
            {i18n.translate('profile.subModels.subHistory')}
          </Title>

          <AdminSubscriptionTableComponent user={user} orgUnitInfo={orgUnitInfo} licenceInfo={licenceInfo} oldLicencesInfo={oldLicencesInfo} />
        {/*<>
          <Title className="subModels__title" level={5}>
            {i18n.translate('profile.subModels.basicInfo')}
          </Title>
          <div className="subModels___content">
            <Divider />
            <Row gutter={16}>
              <Col className="titleCol" span={5}>
                <Text type="secondary">{i18n.translate('profile.subModels.holder')}</Text>
              </Col>
              <Col span={10}>
                <Text strong>
                  {orgInfo?.data?.attributes?.name ? orgInfo?.data?.attributes?.name : '—'}
                </Text>
              </Col>
            </Row>
            <Divider />
            <Row gutter={16}>
              <Col className="titleCol" span={5}>
                <Text type="secondary">{i18n.translate('profile.subModels.activationDate')}</Text>
              </Col>
              <Col span={10}>
                <Text strong>{licenceInfo?.attributes?.from ? moment(licenceInfo?.attributes?.from).format(DisplayDateFormat) : '—'}</Text>
              </Col>
            </Row>
            <Divider />
            <Row gutter={16}>
              <Col className="titleCol" span={5}>
                <Text type="secondary">{i18n.translate('profile.subModels.expirationDate')}</Text>
              </Col>
              <Col span={10}>
                <Text strong>{licenceInfo?.attributes?.to ? moment(licenceInfo?.attributes?.to).format(DisplayDateFormat) : '—'}</Text>
              </Col>
            </Row>
            <Divider />
            <Row gutter={16}>
              <Col className="titleCol" span={5}>
                <Text type="secondary">{i18n.translate('profile.subModels.subStatus')}</Text>
              </Col>
              <Col span={10}>
                <Text strong>{licenceInfo?.attributes?.active ? i18n.translate('profile.subModels.paid') : i18n.translate('profile.subModels.notPaid')}</Text>
              </Col>
            </Row>
            {!!userLicence?.attributes.createdDate &&
              <>
                <Divider />
                <Row gutter={16}>
                  <Col className="titleCol" span={5}>
                    <Text type="secondary">{i18n.translate('profile.subModels.payDate')}</Text>
                  </Col>
                  <Col span={10}>
                    <Text strong>{userLicence?.attributes.createdDate ? moment(userLicence?.attributes.createdDate).format(DisplayDateFormat) : '—'}</Text>
                  </Col>
                </Row>
              </>
            }

            <Row gutter={16}>
            <Col className="titleCol" span={5}>
              <Text type="secondary">{i18n.translate('profile.subModels.mainUser')}</Text>
            </Col>
            <Col span={10}>
              <Text strong>{getManagerUser()}</Text>
            </Col>
          </Row>
            <Divider />
            <Row gutter={16}>
            <Col className="titleCol" span={5}>
              <Text type="secondary">{i18n.translate('profile.subModels.price')}</Text>
            </Col>
            <Col span={10}>
              <Text strong>1000000000 dolar</Text>
            </Col>
          </Row>
          <Divider />
          </div>

          <Title className="subModels__title" level={5}>
            {i18n.translate('profile.subModels.overview')}
          </Title>
          <div className="subModels___content">
            <Divider />
            <Row gutter={16}>
              <Col className="titleCol" span={5}>
                <Text type="secondary">{i18n.translate('profile.subModels.basicView')}</Text>
              </Col>
              <Col span={10}>
                <Text strong>{getBasicYears()?.length ? getBasicYears().sort()?.reverse()?.join(', ') : '—'}</Text>
              </Col>
            </Row>
            <Divider />
            <Row gutter={16}>
              <Col className="titleCol" span={5}>
                <Text type="secondary">{i18n.translate('profile.subModels.premiumView')}</Text>
              </Col>
              <Col span={15}>
                <Text strong>{getPremiumYears()?.length ? getPremiumYears().sort()?.reverse()?.join(', ') : '—'}</Text>
              </Col>
              <Col span={4}>
                <Button onClick={showModal} size="small">{i18n.translate('profile.unlock.years')}</Button>
              </Col>
            </Row>
            <Divider />
          </div>

          <Title className="subModels__title" level={5}>
            {i18n.translate('profile.basicInfo.print')}
          </Title>
          <div className="subModels___content">
            <Divider />
            <Row gutter={16}>
              <Col className="titleCol" span={5}>
                <Text type="secondary">{i18n.translate('profile.subModels.subNo')}</Text>
              </Col>
              <Col span={15}>
                <Text strong>{licenceInfo?.attributes?.biltenSubscription ? i18n.t('common.options.yes') : i18n.t('common.options.no')}</Text>
              </Col>
              <Col span={4}>
                <Button onClick={showModal} size="small">{i18n.translate('profile.unlock.subs')}</Button>
              </Col>
            </Row>
            <Divider />
            <Row gutter={16}>
              <Col className="titleCol" span={5}>
                <Text type="secondary">{i18n.translate('profile.subModels.deliveryAddress')}</Text>
                <Button size="small" type="link" icon={<InfoCircleOutlined />}></Button>
              </Col>
            </Row>
            <Divider />
          </div>

          <Title className="subModels__title" level={5}>
            {i18n.translate('profile.subModels.lectures')}
          </Title>
          <div className="subModels___content">
            <Divider />
            <Row gutter={16}>
              <Col className="titleCol" span={5}>
                <Text type="secondary">{i18n.translate('profile.subModels.lectureNo')}</Text>
              </Col>
              <Col span={10}>
                <Text strong>{licenceInfo?.attributes?.educationsNum ? licenceInfo?.attributes?.educationsNum : '—'}</Text>
              </Col>
            </Row>
            <Divider />
            <Row gutter={16}>
              <Col className="titleCol" span={5}>
                <Text type="secondary">{i18n.translate('profile.subModels.lectureUsed')}</Text>
              </Col>
              <Col span={10}>
                <Text strong>{licenceInfo?.attributes?.usedEducations ? licenceInfo?.attributes?.usedEducations : '—'}</Text>
              </Col>
            </Row>
            <Divider />
          </div>

          <Title className="subModels__title" level={5}>
            {i18n.translate('profile.subModels.subHistory')}
          </Title>
          <AdminSubscriptionTableComponent user={user} orgUnitInfo={orgUnitInfo} licenceInfo={licenceInfo} oldLicencesInfo={oldLicencesInfo} />
        */}        
      </>
      ) : null}
      <UpgradeModal isUpgrade licenceInfo={licenceInfo} visible={visible} handleOk={handleUpgradeOk} handleCancel={handleCancel} selectedModel={selectedModel} />
    </div>
  );
}
