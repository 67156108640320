export const apiRoutes: any = {
  //AUTH
  register: `api/auth/register`,
  login: `api/auth/login`,
  logout: `api/auth/logout`,
  refreshToken: `api/auth/token/refresh`,
  registerCheckEmail: `api/auth/check-email`,
  resetPassword: `api/auth/reset-password`,
  latest2faCode: `api/auth/2FA/latest_2FA_code`,
  twoFactorActivation: `api/auth/2FA/request_email_2fa_activation`,
  twoFactorConfirmation: `api/auth/2FA/confirm_email_2fa`,
  twoFactorDeactivation: `api/auth/2FA/deactivate_email_2fa`,

  //BILTEN
  bilten: `api/bilten`,

  //PROFESSIONAL ARTICLE
  professionalArticle: `api/professional_article`,

  //FAQ
  faq: `api/faq_group`,

  //FAQ
  faqSingle: `api/frequent_questions`,

  //OPINION
  opinion: `api/opinion`,

  //ROADMAP
  roadmap: `api/roadmap`,

  //REGULATION
  regulation: `api/regulation`,

  //NOTES
  notes: `api/notes`,
  notesLinks: `api/notes_links`,

  //DECISION
  decision: `api/decision`,
  allegation: `api/decision_appeal_allegation`,
  appeal: `api/decision_appeal_response`,
  summary: `api/decision_summary`,

  //VERDICT
  verdict: `api/verdict`,
  order: `api/verdict_prosecution_order`,
  response: `api/verdict_response`,
  synopsis: `api/verdict_summary`,

  //TAG SCREEN
  tagScreen: `api/tag_screens/fetch`,

  //FILE
  dms: `api/dms`,
  dmsAdmin: `api/dms/admin`,

  // NEWS
  news: `${process.env.REACT_APP_URL_WP}/wp-json/wp/v2/posts`,

  // EDU
  educations: `https://www.temporis.hr/wp-json/wp/v2/education`,

  // BJN
  bjn: `${process.env.REACT_APP_URL_WP}/wp-json/eightshift-libs/v1/user-info`,

  // ORGANIZATION
  organization: `api/organization`,
  organizationUnit: `api/organization_unit`,

  // USER
  fullUser: `api/user`,

  // LICENCE
  licence: `api/licence`,
  approveLicence: `api/approve_licence`,
  disapproveLicence: `api/disapprove_licence`,
  trialLicence: `api/trial`,
  extendLicence: `api/extend_subscription`,
  releaseLicence: `api/release_licence`,

  // LICENCE USER
  licenceUser: `api/licence_user`,

  // COURT
  courtEU: `api/court_eu`,

  // PROMATERIALS
  proMaterials: `api/professional_material`,
  proMaterialAssets: `api/pro_material_assets`,

  //ES
  elasticSearch: `api/es/search`,
  
  notification: `api/notification`,

  //PRICES
  prices: `api/price`,

  //EDUCATIONS & QUESTIONS
  educationsPut: `api/educations/user`,
  questionsPut: `api/questions/user`,

  //CHANGE PASSWORD
  changePassword: `api/auth/change-password`,

  //PRINT EDITIONS
  printEditions: `api/printed_edition`,
  
  //INVITATION
  userInvitation: `api/auth/user_invitation`,

  //UPGRADE REQUEST
  upgradeRequest: `api/upgrade_request`,

  //USER ACTIVITY LOG
  userActivityLog: `api/user_activity_log`,

  //USER LOG STATISTICS
  userLogStatistics: `api/user_log_statistics`,

  //EXPIRED LICENCE
  expiredLicences: `api/expired_licences`
};
