import { SummaryConstants } from './summary.constants';
import { SummaryInterface, SummaryResponseInterfaceAll, SummaryResponseInterfaceSingle, ReducerActionInterface } from '../../interfaces';

// ============ INTERFACE ============

export interface SummaryStateInterface {
  revision: number;
  currentSummary?: SummaryInterface;
  summarys?: SummaryResponseInterfaceAll;
  error?: string;
  isLoading?: boolean;
}

// ============ INIT STATE ============

const initialState: SummaryStateInterface = {
  revision: 0,
  currentSummary: undefined,
  summarys: {
    data: [],
    meta: {
      totalResourceCount: 0
    }
  }
}

// ============ REDUCERS ============

const setLoader = (state: SummaryStateInterface, payload: boolean): SummaryStateInterface => {
  return {
    ...state,
    isLoading: payload,
  };
};

const getSingleSuccess = (state: SummaryStateInterface, payload: SummaryResponseInterfaceSingle): SummaryStateInterface => {
  return {
    ...state,
    isLoading: false,
    currentSummary: payload.data,
  };
};

const getAllSuccess = (state: SummaryStateInterface, payload: SummaryResponseInterfaceAll): SummaryStateInterface => {
  return {
    ...state,
    isLoading: false,
    summarys: {
      data: payload.data,
      meta: payload.meta
    }
  };
};

const createSuccess = (state: SummaryStateInterface, payload: SummaryResponseInterfaceSingle): SummaryStateInterface => {
  return {
    ...state,
    revision: state.revision + 1,
    isLoading: false,
    currentSummary: payload.data,
  };
};

const updateSuccess = (state: SummaryStateInterface, payload: SummaryResponseInterfaceSingle): SummaryStateInterface => {
  return {
    ...state,
    revision: state.revision + 1,
    isLoading: false,
    currentSummary: payload.data,
  };
};

const clearCurrent = (state: SummaryStateInterface): SummaryStateInterface => {
  return {
    ...state,
    revision: state.revision + 1,
    isLoading: false,
    currentSummary: undefined,
  };
};

const clearList = (state: SummaryStateInterface): SummaryStateInterface => {
  return {
    ...state,
    revision: state.revision + 1,
    isLoading: false,
    summarys: {
      data: [],
      meta: {
        totalResourceCount: 0
      }
    }
  };
};

const deleteSuccess = (state: SummaryStateInterface): SummaryStateInterface => {
  return {
    ...state,
    revision: state.revision + 1,
    isLoading: false,
    currentSummary: undefined,
  };
};

const error = (state: SummaryStateInterface, payload: string) => {
  return {
    ...state,
    revision: state.revision + 1,
    isLoading: false,
    error: payload,
  };
};

// ============ EXPORTS ============

export const SummaryReducer = (state = initialState, action: ReducerActionInterface) => {
  switch (action.type) {
    case SummaryConstants.SET_LOADER:
      return setLoader(state, action.payload);
    case SummaryConstants.GET_SINGLE_SUCCESS:
      return getSingleSuccess(state, action.payload);
    case SummaryConstants.GET_ALL_SUCCESS:
      return getAllSuccess(state, action.payload);
    case SummaryConstants.CREATE_SUCCESS:
      return createSuccess(state, action.payload);
    case SummaryConstants.UPDATE_SUCCESS:
      return updateSuccess(state, action.payload);
    case SummaryConstants.CLEAR_CURRENT:
      return clearCurrent(state);
    case SummaryConstants.CLEAR_LIST:
      return clearList(state);
    case SummaryConstants.DELETE:
      return deleteSuccess(state);
    case SummaryConstants.ERROR:
      return error(state, action.payload);
    default:
      return state;
  }
};
