import React, { useEffect, useState } from 'react';
import {
  ChildrenTagScreenResponseInterface,
  clearOpinionAction,
  createOpinionAction,
  deleteOpinionAction,
  DisplayDateFormat,
  FileFormatEnum,
  FileService,
  FilterNameEnumHR,
  getScreenTagsByTypeAction,
  getSingleOpinionAction,
  i18n,
  JavaFormat,
  NavigationService,
  OpinionStateInterface,
  SelectTypeEnum,
  StoreStateInterface,
  TagScreenResponseInterface,
  TagScreenStateInterface,
  updateOpinionAction,
} from '../../../../common';
import { InboxOutlined, DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import {
  Button,
  DatePicker,
  Divider,
  Form,
  Input,
  message,
  Modal,
  notification,
  Radio,
  Select,
  TreeSelect,
  Upload,
  UploadFile,
  UploadProps,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { AdminRoutes } from '../../_router/admin.routes';
import localeHR from 'antd/es/date-picker/locale/hr_HR';
import localeEN from 'antd/es/date-picker/locale/en_GB';
import moment from 'moment';
import { RangePickerProps } from 'antd/lib/date-picker';

const { SHOW_ALL } = TreeSelect;

const { TextArea } = Input;

const { Dragger } = Upload;

const { confirm } = Modal;

function InstitutionalOpinionsCrudPageComponent() {
  const location = useLocation();
  const path = location.pathname.split('/');
  const id = parseInt(path[path.length - 1]);
  const isNew = isNaN(id);

  const dispatch = useDispatch();
  const data: OpinionStateInterface = useSelector((state: StoreStateInterface) => state.opinion);
  const tags: TagScreenStateInterface = useSelector((state: StoreStateInterface) => state.tagScreen);
  const userAuth = useSelector((state: StoreStateInterface) => state.auth);

  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [pdfUuid, setPdfUuid] = useState('');

  useEffect(() => {
    dispatch(clearOpinionAction());
    dispatch(getScreenTagsByTypeAction('opinion'));
    if (!isNew) dispatch(getSingleOpinionAction(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isNew) {
      if (data?.currentOpinion?.attributes?.pdfUuid) {
        FileService.list(data?.currentOpinion?.attributes?.pdfUuid).subscribe(
          (response: any) => {
            setFileList([{ name: response.fileName, uid: response.uuid }]);
            setPdfUuid(response.uuid);
          },
          (error: Error) => {
            console.log(error);
          }
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.currentOpinion?.attributes]);

  const onFileDownload = () => {
    FileService.temp(pdfUuid).subscribe(
      (response: any) => {
        if (response.url) {
          notification['success']({ message: i18n.translate('api.downloadSuccessMessage'), duration: 2 });
          var link = document.createElement('a');
          link.href = response.url;
          link.download = 'file.pdf';
          link.dispatchEvent(new MouseEvent('click'));
        } else {
          notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
        }
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
      }
    );
  };

  const beforeUpload = (file: any) => {
    if (!FileFormatEnum.ALLOWED.split(',').includes(file.type)) {
      message.error(i18n.translate('genericFileUpload.formatError', { filename: file.name }));
      return false;
    } else {
      return true;
    }
  };

  const onFileListChange = (info: any) => {
    if (!FileFormatEnum.ALLOWED.split(',').includes(info.file.type)) return;

    if (info.file.status === 'done') {
      passUuid(info.file.response[0].uuid);
      message.success(i18n.translate('genericFileUpload.uploadSuccess', { filename: info.file.name }));
    } else if (info.file.status === 'error') {
      message.error(i18n.translate('genericFileUpload.uploadFailed', { filename: info.file.name }));
    }
    setFileList([info.fileList.pop()]);
  };

  const onFileRemove = () => {
    passUuid('');
    setFileList([]);
  };

  const props: UploadProps = {
    name: 'files',
    accept: FileFormatEnum.ALLOWED,
    beforeUpload: (file: any) => beforeUpload(file),
    multiple: false,
    action: process.env.REACT_APP_FILE_UPLOAD,
    onChange: (info: any) => onFileListChange(info),
    fileList: fileList,
    showUploadList: {
      showRemoveIcon: false,
    },
  };

  const checkForNested = (tag: TagScreenResponseInterface) => {
    let nested = tag.children?.find((child) => child.nestedChildren?.length && child.nestedChildren?.length > 0);
    if (nested) return true;
    else return false;
  };

  // eslint-disable-next-line arrow-body-style
  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    // Can not select year after current year or before 2016
    return (current && current > moment().endOf('year')) || current < moment('2015').endOf('year');
  };

  const onFinishOpinion = (values: any) => {

    const getTags = () => {
      let array: Array<{ id: Number; type: string }> = [];
      tags.tagScreens?.forEach((tag) => {
        const value = values[tag.filter.name as keyof any];
        if (value) {
          if (tag?.selectType === SelectTypeEnum.YEAR) {
            const childTag = tag.children?.find((child) => child.tag.name == moment(value).format('YYYY'));
            if (childTag) array.push({ id: childTag.tag.id as number, type: 'tag' });
          }
          if (tag?.selectType === SelectTypeEnum.SELECT || tag?.selectType === SelectTypeEnum.BOOLEAN) {
            const childTag = tag.children?.find((child) => child.tag.id == value);
            if (childTag) array.push({ id: childTag.tag.id as number, type: 'tag' });
          }
          if (tag?.selectType === SelectTypeEnum.MULTISELECT) {
            const value = values[tag.filter.name as keyof any];
            if (value && value.length && value.length > 0) {
              value.forEach((id: any) => {
                if (id) array.push({ id: id, type: 'tag' });
              });
            }
          }
        }
      });

      return array;
    };

    const getYear = () => {
      try {
        return values['Godina'].format('YYYY');
      } catch (error) {
        
      }
      return '';
    }

    let opinionData: any = {
      data: {
        type: 'opinion',
        id: data.currentOpinion?.id,
        attributes: {
          title: values.title,
          content: values.content,
          summary: values.summary,
          //publishedAt: moment().format(JavaFormat),
          institutionDate: values.institutionDate ? moment(values.institutionDate).format(JavaFormat) : '',
          pdfUuid: pdfUuid,
          link: values.originalLink,
          year: getYear()
        },
        relationships: {
          tags: {
            data: getTags(),
          },
        },
      },
    };
    isNew ? dispatch(createOpinionAction(opinionData)) : dispatch(updateOpinionAction(opinionData));
    window.scrollTo({ top: 0, behavior: 'smooth' });

    setTimeout(() => {
      NavigationService.navigate(AdminRoutes.INSTITUTIONAL_OPINIONS.fullPath);
    }, 1000);
  };

  const onFinishOpinionFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const setTreeData = (children: Array<ChildrenTagScreenResponseInterface>) => {
    let values = children.map((child) => ({
      title: child.tag.name,
      value: child.tag.id,
      key: child.tag.id,
      children: child.nestedChildren?.map((nestedChild) => ({
        title: nestedChild.tag.name,
        value: nestedChild.tag.id,
        key: nestedChild.tag.id,
        children: nestedChild.nestedChildren?.map((nestedLvl2Child) => ({
          title: nestedLvl2Child.tag.name,
          value: nestedLvl2Child.tag.id,
          key: nestedLvl2Child.tag.id,
        })),
      })),
    }));
    return values;
  };

  const getDisplayConditions = () => {
    if (isNew) {
      return tags.tagScreens && tags.tagScreens.length > 0;
    } else {
      return tags.tagScreens && tags.tagScreens.length > 0 && data.currentOpinion;
    }
  };

  const getFilter = (field: string) => {
    let filter;
    let tag = tags.tagScreens?.find((tag) => tag.filter.name == field);
    if (tag) {
      let array: number[] = [];
      data.currentOpinion?.attributes?.tagIds?.forEach((id) => {
        tag?.children?.forEach((child) => {
          if (id === child.tag.id) {
            if (tag?.selectType === SelectTypeEnum.YEAR) {
              filter = moment(child.tag.name);
            }
            if (tag?.selectType === SelectTypeEnum.SELECT || tag?.selectType === SelectTypeEnum.BOOLEAN) {
              filter = child.tag.id;
            }
            if (tag?.selectType === SelectTypeEnum.MULTISELECT) {
              array.push(child.tag.id);
            }
          }
        });
      });

      if (tag?.selectType === SelectTypeEnum.MULTISELECT) filter = array;
    }
    return filter;
  };

  const loadInitialValues = () => {
    let values: any = {
      title: data.currentOpinion?.attributes?.title,
      content: data.currentOpinion?.attributes?.content,
      summary: data.currentOpinion?.attributes?.summary,
      originalLink: data.currentOpinion?.attributes?.link,
      institutionDate: data.currentOpinion?.attributes?.institutionDate
        ? moment(data.currentOpinion?.attributes?.institutionDate)
        : null,
    };

    tags.tagScreens?.forEach((tag) => {
      values[tag.filter.name as keyof any] = getFilter(tag.filter.name);
    });

    return values;
  };

  const loadDefaultValues = () => {
    let values: any = {};

    tags.tagScreens?.forEach((tag) => {
      if (tag.filter.name == FilterNameEnumHR.ZJN) {
        values[tag.filter.name as keyof any] = [tag.children?.length ? tag.children[0].tag.id : []];
      }
    });

    return values;
  };

  const clearCurrentOpinion = () => {
    dispatch(clearOpinionAction());
  };

  const passUuid = (uuid: string) => {
    setPdfUuid(uuid);
  };

  const showConfirm = () => {
    confirm({
      title: i18n.t('institutionalOpinions.confirmDelete'),
      icon: <ExclamationCircleOutlined />,
      content: i18n.t('common.cantUndo'),
      okText: i18n.t('genericButtons.yes'),
      cancelText: i18n.t('genericButtons.cancel'),
      onOk() {
        dispatch(deleteOpinionAction(id));
        window.scrollTo({ top: 0, behavior: 'smooth' });

        setTimeout(() => {
          NavigationService.navigate(AdminRoutes.INSTITUTIONAL_OPINIONS.fullPath);
        }, 1000);
      },
    });
  };

  const returnComponent = (tag: TagScreenResponseInterface) => {
    let component = null;
    if (tag.selectType == SelectTypeEnum.YEAR) {
      component = (
        <DatePicker
          picker="year"
          disabledDate={disabledDate}
          locale={userAuth.lang == 'hr' ? localeHR : localeEN}
          format={'YYYY.'}
        />
      );
    }

    if (tag.selectType == SelectTypeEnum.BOOLEAN) {
      component = (
        <Radio.Group>
          <Radio.Button value={tag.children?.length ? tag.children[0].tag.id : 0}>
            {tag.children?.length ? tag.children[0].tag.name : ''}
          </Radio.Button>
          <Radio.Button value={tag.children?.length ? tag.children[1].tag.id : 1}>
            {tag.children?.length ? tag.children[1].tag.name : ''}
          </Radio.Button>
        </Radio.Group>
      );
    }

    if (tag.selectType == SelectTypeEnum.SELECT || tag.selectType == SelectTypeEnum.MULTISELECT) {
      if (checkForNested(tag)) {
        component = (
          <TreeSelect
            treeData={setTreeData(tag.children as Array<ChildrenTagScreenResponseInterface>)}
            treeCheckable
            showCheckedStrategy={SHOW_ALL}
            maxTagCount="responsive"
            placeholder={i18n.t('genericFormMsg.placeholders.select')}
          />
        );
      } else {
        component = (
          <Select
            mode={tag.selectType == SelectTypeEnum.MULTISELECT ? 'multiple' : undefined}
            maxTagCount={tag.selectType == SelectTypeEnum.MULTISELECT ? 'responsive' : undefined}
            allowClear
          >
            {tag.children?.length &&
              tag.children.map((child) => (
                <Select.Option key={child.tag.name} value={child.tag.id}>
                  {child.tag.name}
                </Select.Option>
              ))}
          </Select>
        );
      }
    }

    return component;
  };

  return (
    <div className="institutionalOpinionsCrud w100-h100">
      {getDisplayConditions() && (
        <Form
          layout="vertical"
          name="opinion"
          size="large"
          scrollToFirstError
          initialValues={data?.currentOpinion && !isNew ? loadInitialValues() : loadDefaultValues()}
          onFinish={onFinishOpinion}
          onFinishFailed={onFinishOpinionFailed}
          autoComplete="off"
          requiredMark={false}
        >
          <div className="institutionalOpinionsCrud__admin">
            <div className="title">
              {i18n.translate(isNew ? 'institutionalOpinions.crudTitleAdd' : 'institutionalOpinions.crudTitleUpdate')}
            </div>
            <div className="buttons">
              <Link to={AdminRoutes.INSTITUTIONAL_OPINIONS.fullPath} key={AdminRoutes.INSTITUTIONAL_OPINIONS.path}>
                <Button
                  onClick={clearCurrentOpinion}
                  className="button"
                  size="large"
                  style={{ fontSize: 16, fontWeight: 700, borderRadius: 0, minWidth: 193, marginRight: 16 }}
                >
                  {i18n.translate('genericButtons.cancel')}
                </Button>
              </Link>
              <Button
                className="button"
                htmlType="submit"
                type="primary"
                size="large"
                style={{ fontSize: 16, fontWeight: 700, borderRadius: 0, minWidth: 193 }}
              >
                {i18n.translate('genericButtons.save')}
              </Button>
            </div>
          </div>
          <Divider />
          <div className="institutionalOpinionsCrud__form">
            <div className="subtitle">
              <span className="text">{i18n.translate('institutionalOpinions.form.sections.info')}</span>
            </div>

            <Form.Item
              label={<span className="text-bold">{i18n.translate(`institutionalOpinions.form.fields.title`)}</span>}
              name="title"
              rules={[{ required: true, message: i18n.translate(`genericFormMsg.required`) }]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 12 }}
            >
              <Input style={{ borderRadius: 0 }} />
            </Form.Item>

            <Form.Item
              label={<span className="text-bold">{i18n.translate(`institutionalOpinions.form.fields.summary`)}</span>}
              name="summary"
              rules={[{ required: true, message: i18n.translate(`genericFormMsg.required`) }]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 12 }}
            >
              <TextArea style={{ borderRadius: 0 }} autoSize={{ minRows: 4 }} />
            </Form.Item>

            <Form.Item
              label={<span className="text-bold">{i18n.translate(`institutionalOpinions.form.fields.text`)}</span>}
              name="content"
              rules={[{ required: true, message: i18n.translate(`genericFormMsg.required`) }]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 12 }}
            >
              <TextArea style={{ borderRadius: 0 }} autoSize={{ minRows: 4 }} />
            </Form.Item>

            <Form.Item
              label={
                <span className="text-bold">{i18n.translate(`institutionalOpinions.form.fields.publishedDate`)}</span>
              }
              name="institutionDate"
            >
              {/** DISABLED DATE ISPOD AKO TREBA */}
              <DatePicker
                style={{ borderRadius: 0 }}
                locale={userAuth.lang == 'hr' ? localeHR : localeEN}
                format={DisplayDateFormat}
              />
            </Form.Item>

            <div className="subtitle">
              <span className="text">{i18n.translate('institutionalOpinions.form.sections.filters')}</span>
            </div>

            {/** OVDJE ISPOD SVI FILTERI TREBAJU BITI GENERIČKI */}

            {tags.tagScreens?.map((tag) => (
              <Form.Item
                label={<span className="text-bold">{`${tag.filter.name}`}</span>}
                name={tag.filter.name}
                key={tag.filter.name}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 8 }}
              >
                {returnComponent(tag)}
              </Form.Item>
            ))}

            {/** GENERIC FILTERI END */}

            <div className="subtitle">
              <span className="text">{i18n.translate('institutionalOpinions.form.sections.upload')}</span>
            </div>

            <Form.Item
              label={<span className="text-bold">{i18n.translate(`institutionalOpinions.form.fields.pdf`)}</span>}
              name="pdf"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 10 }}
            >
              <Dragger {...props}>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">{i18n.translate(`institutionalOpinions.form.upload.text`)}</p>
              </Dragger>
            </Form.Item>

            {fileList && fileList.length > 0 && (
              <div>
                <Button type="link" style={{ color: 'red', marginBottom: 8 }} onClick={onFileRemove}>
                  {i18n.translate(`genericButtons.delete`)}
                </Button>
                <Button type="link" style={{ marginLeft: 8, marginBottom: 8 }} onClick={onFileDownload}>
                  {i18n.translate(`genericButtons.download`)}
                </Button>
              </div>
            )}

            <Form.Item
              label={
                <span className="text-bold">{i18n.translate(`institutionalOpinions.form.fields.originalLink`)}</span>
              }
              name="originalLink"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 12 }}
            >
              <Input style={{ borderRadius: 0 }} />
            </Form.Item>
          </div>
          <div className="institutionalOpinionsCrud__bottomButtons">
            <div>
              {!isNew && (
                <Button
                  danger
                  onClick={showConfirm}
                  className="button"
                  size="large"
                  icon={<DeleteOutlined />}
                  style={{ fontSize: 16, fontWeight: 700, borderRadius: 0, minWidth: 193 }}
                >
                  {i18n.translate('genericButtons.delete')}
                </Button>
              )}
            </div>
            <div>
              <Link to={AdminRoutes.INSTITUTIONAL_OPINIONS.fullPath} key={AdminRoutes.INSTITUTIONAL_OPINIONS.path}>
                <Button
                  onClick={clearCurrentOpinion}
                  className="button"
                  size="large"
                  style={{ fontSize: 16, fontWeight: 700, borderRadius: 0, minWidth: 193, marginRight: 16 }}
                >
                  {i18n.translate('genericButtons.cancel')}
                </Button>
              </Link>
              <Button
                className="button"
                htmlType="submit"
                type="primary"
                size="large"
                style={{ fontSize: 16, fontWeight: 700, borderRadius: 0, minWidth: 193 }}
              >
                {i18n.translate('genericButtons.save')}
              </Button>
            </div>
          </div>
        </Form>
      )}
    </div>
  );
}

export default InstitutionalOpinionsCrudPageComponent;
