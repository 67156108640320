import { Dispatch } from 'redux';
import { notification } from 'antd';
import { OrganizationConstants } from './organization.constants';

import { OrganizationService } from '../../api/api.service';
import { FullOrganizationResponseInterfaceAll, FullOrganizationResponseInterfaceSingle } from '../../interfaces';
import { i18n } from '../../services';

export function getAllOrganizationsAction(params?: any, filter?: any, sort?: any, pagination?: any) {
  return (dispatch: Dispatch) => {
    return OrganizationService.getAll(params, filter, sort, pagination).subscribe(
      (response: FullOrganizationResponseInterfaceAll) => {
        dispatch({
          type: OrganizationConstants.GET_ALL_SUCCESS,
          payload: response,
        });
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });

        dispatch({
          type: OrganizationConstants.ERROR,
          payload: error,
        });
      }
    );
  };
}

export function getSingleOrganizationAction(id: number) {
  return (dispatch: Dispatch) => {
    return OrganizationService.getSingle(id).subscribe(
      (response: FullOrganizationResponseInterfaceSingle) => {
        dispatch({
          type: OrganizationConstants.GET_SINGLE_SUCCESS,
          payload: response,
        });
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });

        dispatch({
          type: OrganizationConstants.ERROR,
          payload: error,
        });
      }
    );
  };
}

export function createOrganizationAction(body: FullOrganizationResponseInterfaceSingle) {
  return (dispatch: Dispatch) => {
    return OrganizationService.create(body).subscribe(
      (response: FullOrganizationResponseInterfaceSingle) => {
        notification['success']({ message: i18n.translate('api.successMessage'), duration: 2 });

        dispatch({
          type: OrganizationConstants.CREATE_SUCCESS,
          payload: response,
        });
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });

        dispatch({
          type: OrganizationConstants.ERROR,
          payload: error,
        });
      }
    );
  };
}

export function updateOrganizationAction(body: FullOrganizationResponseInterfaceSingle, hideSuccess?: boolean) {
  return (dispatch: Dispatch) => {
    return OrganizationService.update(body).subscribe(
      (response: FullOrganizationResponseInterfaceSingle) => {
        if (!hideSuccess) notification['success']({ message: i18n.translate('api.successMessage'), duration: 2 });

        dispatch({
          type: OrganizationConstants.UPDATE_SUCCESS,
          payload: response,
        });
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });

        dispatch({
          type: OrganizationConstants.ERROR,
          payload: error,
        });
      }
    );
  };
}

export function deleteOrganizationAction(id: number) {
  return (dispatch: Dispatch) => {
    return OrganizationService.delete(id).subscribe(
      (response: any) => {
        dispatch({
          type: OrganizationConstants.DELETE
        });
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });

        dispatch({
          type: OrganizationConstants.ERROR,
          payload: error,
        });
      }
    );
  };
}

export function clearOrganizationAction() {
  return (dispatch: Dispatch) => {
    dispatch({ type: OrganizationConstants.CLEAR_CURRENT });
  };
}

export function clearOrganizationListAction() {
  return (dispatch: Dispatch) => {
    dispatch({ type: OrganizationConstants.CLEAR_LIST });
  };
}
