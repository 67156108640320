import { Modal, Space, Typography, UploadFile } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FileService, LicenceInterface, ProMaterialInterface, UserActivityLogService, UserRoleEnum } from '../../..';
import { LicenceEnum } from '../../../enums/licence.enum';
import { StoreStateInterface } from '../../../redux';
import { DownloadService, i18n, ViewerService } from '../../../services';
import { UserActionTypeEnum } from '../../../enums/userActionType.enum';

const { Text } = Typography;

interface Props {
  visible: boolean;
  handleOk: any;
  handleCancel: any;
  proMaterial?: ProMaterialInterface;
  links?: boolean;
  licenceData?: LicenceInterface;
}

export default function ProMaterialsModal({
  visible,
  handleOk,
  handleCancel,
  proMaterial,
  links,
  licenceData,
}: Props) {
  const userAuth = useSelector((state: StoreStateInterface) => state.auth);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [fileListEng, setFileListEng] = useState<UploadFile[]>([]);
  const [pdfLinkList, setPdfLinkList] = useState<string[]>([]);
  const [pdfLinkListEng, setPdfLinkListEng] = useState<string[]>([]);

  const arrayHr: UploadFile[] = [];
  const arrayEng: UploadFile[] = [];

  useEffect(() => {
    if (proMaterial?.attributes?.proMaterialAssets?.length) {
      let materialsLinkArrayEng: string[] = [];
      let materialsLinkArrayHr: string[] = [];
      proMaterial?.attributes?.proMaterialAssets?.forEach((material, index) => {
        if (material?.pdfUuidEng) {
          FileService.list(material?.pdfUuidEng).subscribe(
            (response: any) => {
              passFileListEng({ name: response.fileName, uid: response.uuid, response: { position: index } });
            },
            (error: Error) => {
              console.log(error);
            }
          );
        }
        if (material?.pdfUuidHr) {
          FileService.list(material?.pdfUuidHr).subscribe(
            (response: any) => {
              passFileList({ name: response.fileName, uid: response.uuid, response: { position: index } });
            },
            (error: Error) => {
              console.log(error);
            }
          );
        }
        if (material?.engLink) {
          materialsLinkArrayEng.push(material?.engLink);
        }
        if (material?.hrLink) {
          materialsLinkArrayHr.push(material?.hrLink);
        }
      });
      setPdfLinkList(materialsLinkArrayHr);
      setPdfLinkListEng(materialsLinkArrayEng);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [proMaterial?.attributes?.proMaterialAssets]);

  const passFileList = (file: UploadFile) => {
    arrayHr.push(file);
    const array = [...arrayHr].sort((a, b) => a.response?.position - b.response?.position);
    setFileList([...array]);
  };

  const passFileListEng = (file: UploadFile) => {
    arrayEng.push(file);
    const array = [...arrayEng].sort((a, b) => a.response?.position - b.response?.position);
    setFileListEng([...array]);
  };

  const onMaterialPdfView = (uuid: string, name: string) => {
    if (!uuid) return;
    if (userAuth?.user?.roles !== UserRoleEnum.ADMIN) {
      let userActivityLogData: any = {
        data: {
          type: 'user_activity_log',
          attributes: {
            action: licenceData?.attributes?.licenceType === LicenceEnum.PREMIUM ? UserActionTypeEnum.FILE_DOWNLOADED : UserActionTypeEnum.FILE_OPENED,
            extra: JSON.stringify({type: 'pro_material', name: name})
          },
          relationships: {
            user: {
              data: {
                id: userAuth?.user?.id,
                type: 'user'
              },
            },
          },
        },
      };

      UserActivityLogService.create(userActivityLogData);
    }
    if (licenceData?.attributes?.licenceType === LicenceEnum.PREMIUM || userAuth.user?.roles === UserRoleEnum.ADMIN) {
      DownloadService.download(uuid);
    } else {
      ViewerService.goToPdf(uuid);
    }
  };

  const getLinkName = (link: string, index: number, eng: boolean) => {
    if (link.length <= 50) {
      return (
        <a target="_blank" href={link}>
          <div
            style={{ whiteSpace: 'pre-wrap', height: 'auto', textAlign: 'left', overflowWrap: 'anywhere' }}
            className="link"
            key={`${eng ? 'engLink' : 'hrLink' + index}`}
          >{`${eng ? fileListEng[index]?.name : fileList[index]?.name}`}</div>
        </a>
      );
    } else {
      return (
        <a target="_blank" href={link}>
          <div
            style={{ whiteSpace: 'pre-wrap', height: 'auto', textAlign: 'left', overflowWrap: 'anywhere' }}
            className="link"
            key={`${eng ? 'engLink' : 'hrLink' + index}`}
            >{`${eng ? fileListEng[index]?.name : fileList[index]?.name}`}</div>
        </a>
      );
    }
  };

  return (
    <div>
      <Modal
        className="proMaterialsModal"
        title={
          <Text strong className="proMaterialsModal___title">
            {links ? i18n.translate('proMaterials.modalTitleLinks') : i18n.translate('proMaterials.modalTitlePdf')}
          </Text>
        }
        footer={null}
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        {links ? (
          <Space direction="vertical">
            <Text strong>HR</Text>
            {pdfLinkList.map((linkHr, index) => getLinkName(linkHr, index, false))}
            {!pdfLinkList?.length ? i18n.translate('common.noData') : null}
            <Text strong>EN</Text>
            {pdfLinkListEng.map((linkEn, index) => getLinkName(linkEn, index, true))}
            {!pdfLinkListEng?.length ? i18n.translate('common.noData') : null}
          </Space>
        ) : null}
        {!links ? (
          <Space direction="vertical">
            <Text strong>HR</Text>
            {fileList.map((fileHr, index) => (
              <div onClick={() => onMaterialPdfView(fileHr.uid, fileHr.name)} className="link" key={'hrPdf' + index}>
                {fileHr.name}
              </div>
            ))}
            {!fileList?.length ? i18n.translate('common.noData') : null}
            <Text strong>EN</Text>
            {fileListEng.map((fileEng, index) => (
              <div onClick={() => onMaterialPdfView(fileEng.uid, fileEng.name)} className="link" key={'engPdf' + index}>
                {fileEng.name}
              </div>
            ))}
            {!fileListEng?.length ? i18n.translate('common.noData') : null}
          </Space>
        ) : null}
      </Modal>
    </div>
  );
}
