import { OrganizationConstants } from './organization.constants';
import { FullOrganizationInterface, FullOrganizationResponseInterfaceAll, FullOrganizationResponseInterfaceSingle, ReducerActionInterface } from '../../interfaces';

// ============ INTERFACE ============

export interface FullOrganizationStateInterface {
  currentOrganization?: FullOrganizationInterface;
  organizations?: FullOrganizationResponseInterfaceAll;
  error?: string;
}

// ============ INIT STATE ============

const initialState: FullOrganizationStateInterface = {
  currentOrganization: undefined,
  organizations: {
    data: [],
    meta: {
      totalResourceCount: 0
    }
  }
}

// ============ REDUCERS ============

const getSingleSuccess = (state: FullOrganizationStateInterface, payload: FullOrganizationResponseInterfaceSingle): FullOrganizationStateInterface => {
  return {
    ...state,
    currentOrganization: payload.data,
  };
};

const getAllSuccess = (state: FullOrganizationStateInterface, payload: FullOrganizationResponseInterfaceAll): FullOrganizationStateInterface => {
  return {
    ...state,
    organizations: {
      data: payload.data,
      meta: payload.meta
    }
  };
};

const createSuccess = (state: FullOrganizationStateInterface, payload: FullOrganizationResponseInterfaceSingle): FullOrganizationStateInterface => {
  return {
    ...state,
    currentOrganization: payload.data,
  };
};

const updateSuccess = (state: FullOrganizationStateInterface, payload: FullOrganizationResponseInterfaceSingle): FullOrganizationStateInterface => {
  return {
    ...state,
    currentOrganization: payload.data,
  };
};

const clearCurrent = (state: FullOrganizationStateInterface): FullOrganizationStateInterface => {
  return {
    ...state,
    currentOrganization: undefined,
  };
};

const clearList = (state: FullOrganizationStateInterface): FullOrganizationStateInterface => {
  return {
    ...state,
    organizations: {
      data: [],
      meta: {
        totalResourceCount: 0
      }
    }
  };
};

const deleteSuccess = (state: FullOrganizationStateInterface): FullOrganizationStateInterface => {
  return {
    ...state,
    currentOrganization: undefined,
  };
};

const error = (state: FullOrganizationStateInterface, payload: string) => {
  return {
    ...state,
    error: payload,
  };
};

// ============ EXPORTS ============

export const OrganizationReducer = (state = initialState, action: ReducerActionInterface) => {
  switch (action.type) {
    case OrganizationConstants.GET_SINGLE_SUCCESS:
      return getSingleSuccess(state, action.payload);
    case OrganizationConstants.GET_ALL_SUCCESS:
      return getAllSuccess(state, action.payload);
    case OrganizationConstants.CREATE_SUCCESS:
      return createSuccess(state, action.payload);
    case OrganizationConstants.UPDATE_SUCCESS:
      return updateSuccess(state, action.payload);
    case OrganizationConstants.CLEAR_CURRENT:
      return clearCurrent(state);
    case OrganizationConstants.CLEAR_LIST:
      return clearList(state);
    case OrganizationConstants.DELETE:
      return deleteSuccess(state);
    case OrganizationConstants.ERROR:
      return error(state, action.payload);
    default:
      return state;
  }
};
