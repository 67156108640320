import React, { useEffect, useState } from 'react';
import { Button, Modal, notification, Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { i18n, LicenceUpgradeService } from '../../../services';
import { UpgradeRequestService } from '../../../api';
import moment from 'moment';
import { JavaFormat } from '../../../constants';
import { Link } from 'react-router-dom';
import { AdminRoutes } from '../../../../features/admin';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import Title from 'antd/lib/typography/Title';

const { confirm } = Modal;

interface DataType {
  key: number;
  id: number;
  user: string;
  organization: string;
  userId: string;
  organizationUnitId: string;
  licenceType: string;
  printedCopyYears: string;
  printedCopyNum: number;
  additionalYears: string;
  createdDate: string;
  status: string;
  extra?: string;
}

interface Props {
  userId?: number;
  callback?: () => void;
}

export default function AdminUpgradeRequestComponent({ userId, callback }: Props) {

  const [data, setData] = useState<Array<DataType>>([]);

  useEffect(() => {
    initData();
  }, []);

  function initData() {
    const request = userId ? UpgradeRequestService.getAllFromUser(userId) : UpgradeRequestService.getAll();

    request.subscribe((resp) => {
      let d: Array<DataType> = [];

      resp?.data?.forEach((el) => {
        let user = resp?.included?.find(x => x.type == 'user' && x.id == el.relationships?.user?.data?.id);
        let org = resp?.included?.find(x => x.type == 'organization_unit' && x.id == user?.relationships?.organizationUnit?.data?.id);
        d.push({
          key: el.id!,
          id: el.id!,
          user: user?.attributes?.name,
          organization: org?.attributes?.name,
          userId: user?.id,
          organizationUnitId: org?.id,
          licenceType: el.attributes.licenceType!,
          printedCopyYears: el.attributes.printedCopyYears ? el.attributes.printedCopyYears : '',
          printedCopyNum: el.attributes.printedCopyNum!,
          additionalYears: el.attributes.additionalYears!,
          createdDate: moment(el.attributes.createdDate).format('DD.MM.YYYY. HH:mm'),
          status: el.attributes.status!,
          extra: el.attributes?.extra
        });
      });

      setData(d);
    });
  }

  const handleUpgradeOk = (record: DataType) => {

    let extra = JSON.parse(record.extra as string);

    if (extra?.isUpgrade) {

      let numberArrayYears: number[] = record?.additionalYears?.split(',').map((stringYear: string) => parseInt(stringYear));

      let latestYear = Math.max.apply(Math, numberArrayYears);
      var index = numberArrayYears.indexOf(latestYear);
      if (index !== -1) {
        numberArrayYears.splice(index, 1);
      }

      const stringArrayYears: string[] = numberArrayYears.map((numberYear: number) => numberYear.toString());

      let licenceDetails: any = {
        from: moment(extra?.oldFrom).format(JavaFormat),
        to: moment(extra?.oldTo).format(JavaFormat),
        licenceType: record.licenceType,
        notesNum: LicenceUpgradeService.getNotesFinal(record.licenceType),
        questionsNum: LicenceUpgradeService.getConsultationsFinal(record.licenceType),
        educationsNum: LicenceUpgradeService.getLecturesFinal(record.licenceType),
        printedEditionsNum: record.printedCopyNum, // * 3,
        biltenSubscription: record.printedCopyNum > 0 ? true : false,
        price: extra.price
      };

      LicenceUpgradeService.upgradeUserLicenceRequest(parseInt(record.userId), parseInt(record.organizationUnitId), licenceDetails, extra.oldYear, stringArrayYears, record.printedCopyYears?.split(',')?.length > 1, extra.biltenSubscriptionYears).then(() => {
        notification['success']({ message: i18n.translate('api.successMessage'), duration: 2 });
        callback && callback();
      });
    }
  };

  function changeStatus(id: number, status: string, record: DataType) {
    if (status === 'OBRAĐENO') {
      handleUpgradeOk(record);
      UpgradeRequestService.update({
        data: {
          id: id,
          type: 'upgrade_request',
          attributes: {
            status
          }
        }
      }).subscribe(() => initData());
    } 
  }

  function deleteEntry(id: number) {
    UpgradeRequestService.delete(id).subscribe(() => {
      notification['success']({ message: i18n.translate('api.successDelete'), duration: 2 });
      initData();
    });
  }

  const showConfirm = (record: any) => {
    confirm({
      title: i18n.t('common.recordRemoval'),
      icon: <ExclamationCircleOutlined />,
      content: i18n.t('common.cantUndo'),
      okText: i18n.t('genericButtons.yes'),
      cancelText: i18n.t('genericButtons.cancel'),
      onOk() {
        deleteEntry(parseInt(record?.key))
      }
    });
  };

  const getPrintedCopyText = (record: any) => {
    let extra = JSON.parse(record.extra as string);
    if (record.printedCopyYears && record.printedCopyYears?.split(',')?.length > 1) {
      if (extra?.biltenSubscriptionYears?.length == 2) {
        return `${record.printedCopyNum} (${record.printedCopyYears?.split(',')[0]}), ${record.printedCopyNum} (${record.printedCopyYears?.split(',')[1]})`
      }
      if (extra?.biltenSubscriptionYears?.length == 1 && !extra?.biltenSubscriptionYears?.includes('0')) {
        return `${record.printedCopyNum} (${record.printedCopyYears?.split(',')[0]})`
      }

      return record.printedCopyNum
    } else {
      return record.printedCopyNum == 0 ? record.printedCopyNum : (record.printedCopyNum + (record.printedCopyYears ? ` (${record.printedCopyYears})` : ` (${extra?.oldYear})`));
    }
  };

  const columns: ColumnsType<DataType> = [
    {
      title: i18n.translate(`adminProfile.upgradeRequestTable.id`),
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: i18n.translate(`adminProfile.upgradeRequestTable.user`),
      dataIndex: 'user',
      key: 'user',
      render: (text, record, index) => (
        <Link
          style={{ textDecoration: 'underline', color: 'blue' }}
          to={AdminRoutes.USER_PROFILE.fullPath + `/${record.userId}`}
          key={AdminRoutes.USER_PROFILE.path + `/${record.userId}`}
        >
          {text}
        </Link>
      ),
    },
    {
      title: i18n.translate(`adminProfile.upgradeRequestTable.organization`),
      dataIndex: 'organization',
      key: 'organization',
      render: (text, record, index) => (
        <span>{text.slice(0, -2)}</span>
      ),
    },
    {
      title: i18n.translate(`adminProfile.upgradeRequestTable.licenceType`),
      dataIndex: 'licenceType',
      render: (text, record, index) => {
        return i18n.translate(`common.licenceType.${text}`);
      },
    },
    {
      title: i18n.translate(`adminProfile.upgradeRequestTable.printedCopyNum`),
      dataIndex: 'printedCopyNum',
      key: 'printedCopyNum',
      render: (text, record, index) => getPrintedCopyText(record),
    },
    {
      title: i18n.translate(`adminProfile.upgradeRequestTable.additionalYears`),
      dataIndex: 'additionalYears',
      key: 'additionalYears',
    },
    {
      title: i18n.translate(`adminProfile.upgradeRequestTable.createdDate`),
      dataIndex: 'createdDate',
      key: 'createdDate',
    },
    {
      title: i18n.translate(`adminProfile.upgradeRequestTable.status`),
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: '',
      dataIndex: '',
      key: '',
      width: '185px',
      render: (text, record, index) => {
        if (record.status === 'ZAPRIMLJENO') return (<><Button style={{marginRight: 8}} onClick={() => changeStatus(record.id, 'OBRAĐENO', record)} type="primary">Potvrdi</Button><Button danger type="primary" onClick={() => showConfirm(record)}>Obriši</Button></>);
        // return (<Button onClick={() => changeStatus(record.id, 'ZAPRIMLJENO', record)}>Poništi</Button>);
        return(<Button danger type="primary" onClick={() => showConfirm(record)}>Obriši</Button>);
      },
    }
  ];

  const columnsNoUser: ColumnsType<DataType> = [
    {
      title: i18n.translate(`adminProfile.upgradeRequestTable.id`),
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: i18n.translate(`adminProfile.upgradeRequestTable.organization`),
      dataIndex: 'organization',
      key: 'organization',
      render: (text, record, index) => (
        <span>{text.slice(0, -2)}</span>
      ),
    },
    {
      title: i18n.translate(`adminProfile.upgradeRequestTable.licenceType`),
      dataIndex: 'licenceType',
      render: (text, record, index) => {
        return i18n.translate(`common.licenceType.${text}`);
      },
    },
    {
      title: i18n.translate(`adminProfile.upgradeRequestTable.printedCopyNum`),
      dataIndex: 'printedCopyNum',
      key: 'printedCopyNum',
      render: (text, record, index) => getPrintedCopyText(record),
    },
    {
      title: i18n.translate(`adminProfile.upgradeRequestTable.additionalYears`),
      dataIndex: 'additionalYears',
      key: 'additionalYears',
    },
    {
      title: i18n.translate(`adminProfile.upgradeRequestTable.createdDate`),
      dataIndex: 'createdDate',
      key: 'createdDate',
    },
    {
      title: i18n.translate(`adminProfile.upgradeRequestTable.status`),
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: '',
      dataIndex: '',
      key: '',
      width: '185px',
      render: (text, record, index) => {
        if (record.status === 'ZAPRIMLJENO') return (<><Button style={{marginRight: 8}} onClick={() => changeStatus(record.id, 'OBRAĐENO', record)} type="primary">Potvrdi</Button><Button danger type="primary" onClick={() => showConfirm(record)}>Obriši</Button></>);
        // return (<Button onClick={() => changeStatus(record.id, 'ZAPRIMLJENO', record)}>Poništi</Button>);
        return(<Button danger type="primary" onClick={() => showConfirm(record)}>Obriši</Button>);
      },
    }
  ];

  return (
    <>
      {data?.filter(x => x.status === 'ZAPRIMLJENO')?.length || !userId ? (
      <>
        {userId ? <Title level={5} style={{ color: '#1210A9', marginTop: 16, marginBottom: 16 }}>{i18n.translate('adminProfile.tabs.upgradeRequest')}</Title> : null}
        <Table
          scroll={{ x: 1500, y: 600 }}
          pagination={false}
          columns={userId ? columnsNoUser : columns}
          dataSource={data}
          locale={{ emptyText: i18n.t('common.noData'), triggerDesc: i18n.t('common.sortDesc'), triggerAsc: i18n.t('common.sortAsc'), cancelSort: i18n.t('common.cancelSort')}}
        />
      </>) : null}
    </>
  );
}
