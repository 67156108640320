export enum OrganizationUnitConstants {
  GET_ALL_SUCCESS = 'OrganizationUnitConstants_GET_ALL_SUCCESS',
  GET_SINGLE_SUCCESS = 'OrganizationUnitConstants_GET_SINGLE_SUCCESS',
  CREATE_SUCCESS = 'OrganizationUnitConstants_CREATE_SUCCESS',
  UPDATE_SUCCESS = 'OrganizationUnitConstants_UPDATE_SUCCESS',
  CLEAR_CURRENT = 'OrganizationUnitConstants_CLEAR_CURRENT',
  CLEAR_LIST = 'OrganizationUnitConstants_CLEAR_LIST',
  DELETE = 'OrganizationUnitConstants_DELETE',
  ERROR = 'OrganizationUnitConstants_ERROR'
}
