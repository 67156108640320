import { TagScreenConstants } from './tagScreen.constants';
import { ReducerActionInterface, TagScreenResponseInterface } from '../../interfaces';

// ============ INTERFACE ============

export interface TagScreenStateInterface {
  tagScreens?: Array<TagScreenResponseInterface>;
  error?: string;
}

// ============ INIT STATE ============

const initialState: TagScreenStateInterface = {
  tagScreens: []
}

// ============ REDUCERS ============

const getScreenTagsByTypeSuccess = (state: TagScreenStateInterface, payload: Array<TagScreenResponseInterface>): TagScreenStateInterface => {
  return {
    ...state,
    tagScreens: payload,
  };
};

const clear = (state: TagScreenStateInterface): TagScreenStateInterface => {
  return {
    ...state,
    tagScreens: []
  };
};

const error = (state: TagScreenStateInterface, payload: string) => {
  return {
    ...state,
    error: payload,
  };
};

// ============ EXPORTS ============

export const TagScreenReducer = (state = initialState, action: ReducerActionInterface) => {
  switch (action.type) {
    case TagScreenConstants.GET_SCREEN_TAGS_BY_TYPE_SUCCESS:
      return getScreenTagsByTypeSuccess(state, action.payload);
    case TagScreenConstants.CLEAR:
      return clear(state);
    case TagScreenConstants.ERROR:
      return error(state, action.payload);
    default:
      return state;
  }
};
