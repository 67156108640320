import { notification } from 'antd';
import { FileService } from '../api/api.service';
import i18n from './translate.service';

async function goToPdf(uuid: string, page?: number) {
  if (!page) page = 1;
  if (uuid.length === 0) {
    notification['error']({ message: i18n.translate('common.noDocument'), duration: 2 });
    return;
  }
  let version = await encodeUuid(uuid);
  let url = `${process.env.REACT_APP_PDF_VIEWER}/?v=${version}#${page}`;
  window.open(url, '_blank');
}

async function encodeUuid(uuid: string) {
  let url = await getTempUrl(uuid);
  return btoa(`${url}`);
}

async function getTempUrl(uuid: string) {
  return new Promise((resolve, reject) => {
    FileService.temp(uuid).subscribe(
      (response: any) => {
        if (response.url) {
          let url = response.url + '/bjn';
          url = getBjnTempUrl(url);
          resolve(url);
        } else {
          notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
          reject();
        }
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
        reject();
      }
    );
  });
}

function getBjnTempUrl(url: string) {
  return url.replace('/view', '/bjn');
}

export default {
  goToPdf,
  encodeUuid,
  getBjnTempUrl,
};
