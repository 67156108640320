import React, { useEffect, useState } from 'react';
import {
  ChildrenTagScreenResponseInterface,
  clearRegulationAction,
  createRegulationAction,
  deleteRegulationAction,
  FileFormatEnum,
  FileService,
  FilterNameEnumHR,
  getScreenTagsByTypeAction,
  getSingleRegulationAction,
  i18n,
  NavigationService,
  RegulationStateInterface,
  SelectTypeEnum,
  StoreStateInterface,
  TagScreenResponseInterface,
  TagScreenStateInterface,
  TypeEnum,
  updateRegulationAction,
} from '../../../../common';
import { InboxOutlined, DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import {
  Button,
  Divider,
  Form,
  Input,
  message,
  Modal,
  notification,
  Radio,
  Select,
  TreeSelect,
  Upload,
  UploadFile,
  UploadProps,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { AdminRoutes } from '../../_router/admin.routes';
import moment from 'moment';

const { SHOW_ALL } = TreeSelect;

const { Dragger } = Upload;

const { confirm } = Modal;

function RegulationsCrudPageComponent() {
  const location = useLocation();
  const path = location.pathname.split('/');
  const id = parseInt(path[path.length - 1]);
  const isNew = isNaN(id);

  const dispatch = useDispatch();
  const data: RegulationStateInterface = useSelector((state: StoreStateInterface) => state.regulation);
  const tags: TagScreenStateInterface = useSelector((state: StoreStateInterface) => state.tagScreen);

  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [pdfUuid, setPdfUuid] = useState('');

  useEffect(() => {
    dispatch(clearRegulationAction());
    dispatch(getScreenTagsByTypeAction('regulation'));
    if (!isNew) dispatch(getSingleRegulationAction(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isNew) {
      if (data?.currentRegulation?.attributes?.pdfUuid) {
        FileService.list(data?.currentRegulation?.attributes?.pdfUuid).subscribe(
          (response: any) => {
            setFileList([{ name: response.fileName, uid: response.uuid }]);
            setPdfUuid(response.uuid);
          },
          (error: Error) => {
            console.log(error);
          }
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.currentRegulation?.attributes]);

  const onFileDownload = () => {
    FileService.temp(pdfUuid).subscribe(
      (response: any) => {
        if (response.url) {
          notification['success']({ message: i18n.translate('api.downloadSuccessMessage'), duration: 2 });
          var link = document.createElement('a');
          link.href = response.url;
          link.download = 'file.pdf';
          link.dispatchEvent(new MouseEvent('click'));
        } else {
          notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
        }
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
      }
    );
  };

  const beforeUpload = (file: any) => {
    if (!FileFormatEnum.ALLOWED.split(',').includes(file.type)) {
      message.error(i18n.translate('genericFileUpload.formatError', { filename: file.name }));
      return false;
    } else {
      return true;
    }
  };

  const onFileListChange = (info: any) => {
    if (!FileFormatEnum.ALLOWED.split(',').includes(info.file.type)) return;

    if (info.file.status === 'done') {
      passUuid(info.file.response[0].uuid);
      message.success(i18n.translate('genericFileUpload.uploadSuccess', { filename: info.file.name }));
    } else if (info.file.status === 'error') {
      message.error(i18n.translate('genericFileUpload.uploadFailed', { filename: info.file.name }));
    }
    setFileList([info.fileList.pop()]);
  };

  const onFileRemove = () => {
    passUuid('');
    setFileList([]);
  };

  const props: UploadProps = {
    name: 'files',
    accept: FileFormatEnum.ALLOWED,
    beforeUpload: (file: any) => beforeUpload(file),
    multiple: false,
    action: process.env.REACT_APP_FILE_UPLOAD,
    onChange: (info: any) => onFileListChange(info),
    fileList: fileList,
    showUploadList: {
      showRemoveIcon: false,
    },
  };

  const checkForNested = (tag: TagScreenResponseInterface) => {
    let nested = tag.children?.find((child) => child.nestedChildren?.length && child.nestedChildren?.length > 0);
    if (nested) return true;
    else return false;
  };

  const onFinishRegulation = (values: any) => {
    const getTags = () => {
      let array: Array<{ id: Number; type: string }> = [];
      tags.tagScreens?.forEach((tag) => {
        const value = values[tag.filter.name as keyof any];
        if (value) {
          if (tag?.selectType === SelectTypeEnum.SELECT || tag?.selectType === SelectTypeEnum.BOOLEAN || tag?.selectType === SelectTypeEnum.YEAR) {
            const childTag = tag.children?.find((child) => child.tag.id == value);
            if (childTag) array.push({ id: childTag.tag.id as number, type: 'tag' });
          }
          if (tag?.selectType === SelectTypeEnum.MULTISELECT) {
            const value = values[tag.filter.name as keyof any];
            if (value && value.length && value.length > 0) {
              value.forEach((id: any) => {
                if (id) array.push({ id: id, type: 'tag' });
              });
            }
          }
        }
      });

      return array;
    };

    let regulationData: any = {
      data: {
        type: 'regulation',
        id: data.currentRegulation?.id,
        attributes: {
          title: values.title,
          pdfUuid: pdfUuid,
          link: values.originalLink,
          //publishedAt: moment().format(JavaFormat),
        },
        relationships: {
          tags: {
            data: getTags(),
          },
        },
      },
    };
    isNew ? dispatch(createRegulationAction(regulationData)) : dispatch(updateRegulationAction(regulationData));
    window.scrollTo({ top: 0, behavior: 'smooth' });

    setTimeout(() => {
      NavigationService.navigate(AdminRoutes.REGULATIONS.fullPath);
    }, 1000);
  };

  const onFinishRegulationFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const setTreeData = (children: Array<ChildrenTagScreenResponseInterface>) => {
    let values = children.map((child) => ({
      title: child.tag.name,
      value: child.tag.id,
      key: child.tag.id,
      children: child.nestedChildren?.map((nestedChild) => ({
        title: nestedChild.tag.name,
        value: nestedChild.tag.id,
        key: nestedChild.tag.id,
        children: nestedChild.nestedChildren?.map((nestedLvl2Child) => ({
          title: nestedLvl2Child.tag.name,
          value: nestedLvl2Child.tag.id,
          key: nestedLvl2Child.tag.id,
        })),
      })),
    }));
    return values;
  };

  const getDisplayConditions = () => {
    if (isNew) {
      return tags.tagScreens && tags.tagScreens.length > 0;
    } else {
      return tags.tagScreens && tags.tagScreens.length > 0 && data.currentRegulation;
    }
  };

  const getFilter = (field: string) => {
    let filter;
    let tag = tags.tagScreens?.find((tag) => tag.filter.name == field);
    if (tag) {
      let array: number[] = [];
      data.currentRegulation?.attributes?.tagIds?.forEach((id) => {
        tag?.children?.forEach((child) => {
          if (id === child.tag.id) {
            if (tag?.selectType === SelectTypeEnum.SELECT || tag?.selectType === SelectTypeEnum.BOOLEAN) {
              filter = child.tag.id;
            }
            if (tag?.selectType === SelectTypeEnum.YEAR || tag?.selectType === SelectTypeEnum.MULTISELECT) {
              array.push(child.tag.id);
            }
          }
        });
      });

      if (tag?.selectType === SelectTypeEnum.MULTISELECT || tag?.selectType === SelectTypeEnum.YEAR) filter = array;
    }
    return filter;
  };

  const loadInitialValues = () => {
    let values: any = {
      title: data.currentRegulation?.attributes?.title,
      originalLink: data.currentRegulation?.attributes?.link,
    };

    tags.tagScreens?.forEach((tag) => {
      values[tag.filter.name as keyof any] = getFilter(tag.filter.name);
    });

    return values;
  };

  const loadDefaultValues = () => {
    let values: any = {};

    tags.tagScreens?.forEach((tag) => {
      if (tag.filter.name == FilterNameEnumHR.ZJN) {
        values[tag.filter.name as keyof any] = [tag.children?.length ? tag.children[0].tag.id : []];
      }
      if (tag.filter.type === TypeEnum.REGULATION_STATUS) {
        values[tag.filter.name as keyof any] = tag.children?.length ? tag.children[0].tag.id : null;
      }
    });

    return values;
  };

  const clearCurrentRegulation = () => {
    dispatch(clearRegulationAction());
  };

  const passUuid = (uuid: string) => {
    setPdfUuid(uuid);
  };

  const showConfirm = () => {
    confirm({
      title: i18n.t('regulations.confirmDelete'),
      icon: <ExclamationCircleOutlined />,
      content: i18n.t('common.cantUndo'),
      okText: i18n.t('genericButtons.yes'),
      cancelText: i18n.t('genericButtons.cancel'),
      onOk() {
        dispatch(deleteRegulationAction(id));
        window.scrollTo({ top: 0, behavior: 'smooth' });

        setTimeout(() => {
          NavigationService.navigate(AdminRoutes.REGULATIONS.fullPath);
        }, 1000);
      }
    });
  };

  const filterFromCurrentYear = (
    children: ChildrenTagScreenResponseInterface[]
  ): ChildrenTagScreenResponseInterface[] => {
    const currentYear = parseInt(moment().format('YYYY').toString());
    return [...children].filter(a => parseInt(a.tag?.name) <= currentYear).sort((a, b) => parseInt(a.tag?.name) - parseInt(b.tag?.name)).reverse();
  };

  return (
    <div className="regulationsCrud w100-h100">
      {getDisplayConditions() && (
        <Form
          layout="vertical"
          name="regulation"
          size="large"
          scrollToFirstError
          initialValues={data.currentRegulation && !isNew ? loadInitialValues() : loadDefaultValues()}
          onFinish={onFinishRegulation}
          onFinishFailed={onFinishRegulationFailed}
          autoComplete="off"
          requiredMark={false}
        >
          <div className="regulationsCrud__admin">
            <div className="title">
              {i18n.translate(isNew ? 'regulations.crudTitleAdd' : 'regulations.crudTitleUpdate')}
            </div>
            <div className="buttons">
              <Link to={AdminRoutes.REGULATIONS.fullPath} key={AdminRoutes.REGULATIONS.path}>
                <Button
                  onClick={clearCurrentRegulation}
                  className="button"
                  size="large"
                  style={{ fontSize: 16, fontWeight: 700, borderRadius: 0, minWidth: 193, marginRight: 16 }}
                >
                  {i18n.translate('genericButtons.cancel')}
                </Button>
              </Link>
              <Button
                className="button"
                htmlType="submit"
                type="primary"
                size="large"
                style={{ fontSize: 16, fontWeight: 700, borderRadius: 0, minWidth: 193 }}
              >
                {i18n.translate('genericButtons.save')}
              </Button>
            </div>
          </div>
          <Divider />
          <div className="regulationsCrud__form">
            <div className="subtitle">
              <span className="text">{i18n.translate('regulations.form.sections.info')}</span>
            </div>

            <Form.Item
              label={<span className="text-bold">{i18n.translate(`regulations.form.fields.title`)}</span>}
              name="title"
              rules={[{ required: true, message: i18n.translate(`genericFormMsg.required`) }]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 12 }}
            >
              <Input style={{ borderRadius: 0 }} />
            </Form.Item>

            <div className="subtitle">
              <span className="text">{i18n.translate('regulations.form.sections.filters')}</span>
            </div>

            {/** OVDJE ISPOD SVI FILTERI TREBAJU BITI GENERIČKI */}

            {tags.tagScreens?.map((tag) => (
              <Form.Item
                label={<span className="text-bold">{`${tag.filter.name}`}</span>}
                name={tag.filter.name}
                key={tag.filter.name}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 8 }}
              >
                {tag.selectType === SelectTypeEnum.BOOLEAN ? (
                  <Radio.Group>
                    <Radio.Button value={tag.children?.length ? tag.children[0].tag.id : 0}>
                      {tag.children?.length ? tag.children[0].tag.name : ''}
                    </Radio.Button>
                    <Radio.Button value={tag.children?.length ? tag.children[1].tag.id : 1}>
                      {tag.children?.length ? tag.children[1].tag.name : ''}
                    </Radio.Button>
                  </Radio.Group>
                ) : checkForNested(tag) && tag.selectType === SelectTypeEnum.MULTISELECT ? (
                  <TreeSelect
                    treeData={setTreeData(tag.children as Array<ChildrenTagScreenResponseInterface>)}
                    treeCheckable
                    showCheckedStrategy={SHOW_ALL}
                    maxTagCount="responsive"
                    placeholder={i18n.t('genericFormMsg.placeholders.select')}
                  />
                ) : (
                  <Select
                    mode={tag.selectType === SelectTypeEnum.MULTISELECT ? 'multiple' : undefined}
                    maxTagCount={tag.selectType === SelectTypeEnum.MULTISELECT ? 'responsive' : undefined}
                    allowClear
                  >
                    {tag.selectType === SelectTypeEnum.YEAR
                      ? tag.children?.length &&
                        filterFromCurrentYear(tag.children) &&
                        filterFromCurrentYear(tag.children)?.length &&
                        filterFromCurrentYear(tag.children).map((child) => (
                          <Select.Option key={child.tag.name} value={child.tag.id}>
                            {child.tag.name}
                          </Select.Option>
                        ))
                      : tag.children?.length &&
                        tag.children.map((child) => (
                          <Select.Option key={child.tag.name} value={child.tag.id}>
                            {child.tag.name}
                          </Select.Option>
                        ))}
                  </Select>
                )}
              </Form.Item>
            ))}

            {/** GENERIC FILTERI END */}

            <div className="subtitle">
              <span className="text">{i18n.translate('regulations.form.sections.upload')}</span>
            </div>

            <Form.Item
              label={<span className="text-bold">{i18n.translate(`regulations.form.fields.pdf`)}</span>}
              name="pdf"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 10 }}
            >
              <Dragger {...props}>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">{i18n.translate(`regulations.form.upload.text`)}</p>
              </Dragger>
            </Form.Item>

            {fileList && fileList.length > 0 && (
              <div>
                <Button type="link" style={{ color: 'red', marginBottom: 8 }} onClick={onFileRemove}>
                  {i18n.translate(`genericButtons.delete`)}
                </Button>
                <Button type="link" style={{ marginLeft: 8, marginBottom: 8 }} onClick={onFileDownload}>
                  {i18n.translate(`genericButtons.download`)}
                </Button>
              </div>
            )}

            <Form.Item
              label={<span className="text-bold">{i18n.translate(`regulations.form.fields.originalLink`)}</span>}
              name="originalLink"
              rules={[{ required: true, message: i18n.translate(`genericFormMsg.required`) }]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 12 }}
            >
              <Input style={{ borderRadius: 0 }} />
            </Form.Item>
          </div>
          <div className="regulationsCrud__bottomButtons">
            <div>
              {!isNew && (
                <Button
                  danger
                  onClick={showConfirm}
                  className="button"
                  size="large"
                  icon={<DeleteOutlined />}
                  style={{ fontSize: 16, fontWeight: 700, borderRadius: 0, minWidth: 193 }}
                >
                  {i18n.translate('genericButtons.delete')}
                </Button>
              )}
            </div>
            <div>
              <Link to={AdminRoutes.REGULATIONS.fullPath} key={AdminRoutes.REGULATIONS.path}>
                <Button
                  onClick={clearCurrentRegulation}
                  className="button"
                  size="large"
                  style={{ fontSize: 16, fontWeight: 700, borderRadius: 0, minWidth: 193, marginRight: 16 }}
                >
                  {i18n.translate('genericButtons.cancel')}
                </Button>
              </Link>
              <Button
                className="button"
                htmlType="submit"
                type="primary"
                size="large"
                style={{ fontSize: 16, fontWeight: 700, borderRadius: 0, minWidth: 193 }}
              >
                {i18n.translate('genericButtons.save')}
              </Button>
            </div>
          </div>
        </Form>
      )}
    </div>
  );
}

export default RegulationsCrudPageComponent;
