import {
  Button,
  DatePicker,
  Form,
  Input,
  message,
  Modal,
  notification,
  Radio,
  Select,
  TreeSelect,
  Upload,
  UploadProps,
} from 'antd';
import React, { useEffect, useState } from 'react';
import {
  ChildrenTagScreenResponseInterface,
  clearDecisionAction,
  createDecisionAction,
  DecisionStateInterface,
  deleteDecisionAction,
  DisplayDateFormat,
  FileFormatEnum,
  FileService,
  FilterNameEnumHR,
  getScreenTagsByTypeAction,
  i18n,
  JavaFormat,
  NavigationService,
  SelectTypeEnum,
  store,
  StoreStateInterface,
  TagScreenResponseInterface,
  TagScreenStateInterface,
  updateDecisionAction,
} from '../../../../../../../common';
import { ExclamationCircleOutlined, DeleteOutlined, InboxOutlined } from '@ant-design/icons';
import { Link, useLocation } from 'react-router-dom';
import { AdminRoutes } from '../../../../../_router/admin.routes';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import TextArea from 'antd/lib/input/TextArea';
import localeHR from 'antd/es/date-picker/locale/hr_HR';
import localeEN from 'antd/es/date-picker/locale/en_GB';

const { SHOW_ALL } = TreeSelect;

const { Dragger } = Upload;

const { confirm } = Modal;

interface Props {
  setTabAllegation?: (decisionId: number) => void;
}

function DKOMDecisionsCrudPageComponent({ setTabAllegation }: Props) {

  const dispatch = useDispatch();
  const [form] = Form.useForm<any>();
  const data: DecisionStateInterface = useSelector((state: StoreStateInterface) => state.decision);
  const tags: TagScreenStateInterface = useSelector((state: StoreStateInterface) => state.tagScreen);
  const userAuth = useSelector((state: StoreStateInterface) => state.auth);

  const [uploadDb, setUploadDb] = useState({
    dkomDecisionPdfUuid: { uuid: null, fileList: []},
    decisionPdfUuid: { uuid: null, fileList: []},
    pmPdfUuid: { uuid: null, fileList: []},
    zznpPdfUuid: { uuid: null, fileList: []}
  });

  useEffect(() => {
    dispatch(getScreenTagsByTypeAction('decision'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!getId()) return;

    let getFileDescriptor = async (uuid: string) => {
      return new Promise((resolve) => {
        FileService.list(uuid).subscribe(
          (response: any) => {
            resolve({
              uuid: response.uuid,
              fileList: [{ name: response.fileName, uid: response.uuid }]
            });
          },
          (error: Error) => {
            console.log(error);
            resolve({
              uuid: null,
              fileList: [{ name: i18n.translate('api.fileNotFound'), uid: null }]
            });
          }
        );
      });
    };

    let getData = async () => {
      if (!data.currentDecision) return;

      let uploadDbTemp = { ...uploadDb };

      for (let _key of Object.keys(uploadDb)) {
        let key = _key as keyof typeof data.currentDecision.attributes;
        let uuid = data.currentDecision?.attributes[key] as string;
  
        if (!uuid) continue;
  
        let d: any = await getFileDescriptor(uuid);
        uploadDbTemp = { ...uploadDbTemp, [key]: { ...d }  };
      }

      setUploadDb(uploadDbTemp);

      form.resetFields();
    };

    getData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.currentDecision?.attributes, tags]);

  const getId = () => {
    if (!data || !data.currentDecision) return undefined;
    return data.currentDecision.id;
  }

  const onUploadFileDownload = (_key: string) => {
    let key = _key as keyof typeof uploadDb;
    let el = uploadDb[key];

    if (!el.uuid) return;

    FileService.temp(el.uuid).subscribe(
      (response: any) => {
        if (response.url) {
          notification['success']({ message: i18n.translate('api.downloadSuccessMessage'), duration: 2 });
          var link = document.createElement('a');
          link.href = response.url;
          link.download = (el.fileList[0] as any).name;
          link.dispatchEvent(new MouseEvent('click'));
        } else {
          notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
        }
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
      }
    );
  }

  const onUploadFileRemove = (_key: string) => {
    let key = _key as keyof typeof uploadDb;
    setUploadDb({ 
      ...uploadDb, 
      [key]: {
        uuid: null,
        fileList: []
      } 
    });
  }

  const beforeUpload = (file: any) => {
    if (!FileFormatEnum.ALLOWED.split(',').includes(file.type)) {
      message.error(i18n.translate('genericFileUpload.formatError', { filename: file.name }));
      return false;
    } else {
      return true;
    }
  };

  const onFileListChange = (_key: string, info: any) => {
    let key = _key as keyof typeof uploadDb;

    if (!FileFormatEnum.ALLOWED.split(',').includes(info.file.type)) return;

    if (info.file.status === 'done') {
      setUploadDb({ 
        ...uploadDb, 
        [key]: {
          uuid: info.file.response[0].uuid,
          fileList: [info.fileList.pop()]
        } 
      });

      message.success(i18n.translate('genericFileUpload.uploadSuccess', { filename: info.file.name }));
    } else if (info.file.status === 'error') {
      message.error(i18n.translate('genericFileUpload.uploadFailed', { filename: info.file.name }));
    } else {
      setUploadDb({ 
        ...uploadDb, 
        [key]: {
          uuid: null,
          fileList: [info.fileList.pop()]
        } 
      });
    }

  };

  const getUploadProps = (_key: string) => {
    let key = _key as keyof typeof uploadDb;

    const props: UploadProps = {
      name: 'files',
      accept: FileFormatEnum.ALLOWED,
      beforeUpload: (file: any) => beforeUpload(file),
      multiple: false,
      action: process.env.REACT_APP_FILE_UPLOAD,
      onChange: (info: any) => onFileListChange(key, info),
      fileList: uploadDb[key].fileList,
      showUploadList: {
        showRemoveIcon: false,
      },
    };

    return props;
  }

  const checkForNested = (tag: TagScreenResponseInterface) => {
    let nested = tag.children?.find((child) => child.nestedChildren?.length && child.nestedChildren?.length > 0);
    if (nested) return true;
    else return false;
  };

  const onFinishDecision = (values: any) => {
    const getTags = () => {
      let array: Array<{ id: Number; type: string }> = [];
      tags.tagScreens?.forEach((tag) => {
        const value = values[tag.filter.name as keyof any];
        if (value) {
          if (tag?.selectType === SelectTypeEnum.SELECT || tag?.selectType === SelectTypeEnum.BOOLEAN || tag?.selectType === SelectTypeEnum.YEAR) {
            const childTag = tag.children?.find((child) => child.tag.id == value);
            if (childTag) array.push({ id: childTag.tag.id as number, type: 'tag' });
          }
          if (
            tag?.selectType === SelectTypeEnum.MULTISELECT ||
            tag?.selectType === SelectTypeEnum.ADVANCED
          ) {
            const value = values[tag.filter.name as keyof any];
            if (value && value.length && value.length > 0) {
              value.forEach((id: any) => {
                if (id) array.push({ id: id, type: 'tag' });
              });
            }
          }
        }
      });

      return array;
    };

    let decisionData: any = {
      data: {
        type: 'decision',
        id: data.currentDecision?.id,
        attributes: {
          clazz: values.class,
          client: values.client,
          appellant: values.appellant,
          subject: values.subject,
          appealInvestmentDate: values.appealInvestmentDate
            ? moment(values.appealInvestmentDate).format(JavaFormat)
            : null,
          decisionDate: values.decisionDate ? moment(values.decisionDate).format(JavaFormat) : null,
          decisionPublishDate: values.decisionPublishDate
            ? moment(values.decisionPublishDate).format(JavaFormat)
            : null,

          dkomDecisionLink: values.dkomDecisionLink,
          
          decisionPdfUuid: uploadDb.decisionPdfUuid.uuid,
          dkomDecisionPdfUuid: uploadDb.dkomDecisionPdfUuid.uuid,
          pmPdfUuid: uploadDb.pmPdfUuid.uuid,
          zznpPdfUuid: uploadDb.zznpPdfUuid.uuid,

          hasVerdict: values.hasVerdict,
          lawFirm: values.lawFirm,
          decisionSignatory: values.decisionSignatory,
          councilMembers: values.councilMembers,
        },
        relationships: {
          tags: {
            data: getTags(),
          },
        },
      },
    };
    
    !getId() ? dispatch(createDecisionAction(decisionData)) : dispatch(updateDecisionAction(decisionData));
    window.scrollTo({ top: 0, behavior: 'smooth' });

    setTimeout(() => {
      setTab(store.getState().decision.currentDecision?.id as number);
    }, 2000);
  };

  const onFinishDecisionFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const setTreeData = (children: Array<ChildrenTagScreenResponseInterface>) => {
    let values = children.map((child) => ({
      title: child.tag.name,
      value: child.tag.id,
      key: child.tag.id,
      children: child.nestedChildren?.map((nestedChild) => ({
        title: nestedChild.tag.name,
        value: nestedChild.tag.id,
        key: nestedChild.tag.id,
        children: nestedChild.nestedChildren?.map((nestedLvl2Child) => ({
          title: nestedLvl2Child.tag.name,
          value: nestedLvl2Child.tag.id,
          key: nestedLvl2Child.tag.id,
        })),
      })),
    }));
    return values;
  };

  // const setCascaderData = (children: Array<ChildrenTagScreenResponseInterface>) => {
  //   let values = children.map((child) => ({
  //     label: child.tag.name,
  //     value: child.tag.id,
  //     key: child.tag.id,
  //     children: child.nestedChildren?.map((nestedChild) => ({
  //       label: nestedChild.tag.name,
  //       value: nestedChild.tag.id,
  //       key: nestedChild.tag.id,
  //       children: nestedChild.nestedChildren?.map((nestedLvl2Child) => ({
  //         label: nestedLvl2Child.tag.name,
  //         value: nestedLvl2Child.tag.id,
  //         key: nestedLvl2Child.tag.id,
  //       })),
  //     })),
  //   }));
  //   return values;
  // };

  const getFilter = (field: string) => {
    let filter;
    let tag = tags.tagScreens?.find((tag) => tag.filter.name == field);
    if (tag) {
      let array: number[] = [];
      data.currentDecision?.attributes?.tagIds?.forEach((id) => {
        tag?.children?.forEach((child) => {
          if (id === child.tag.id) {
            if (tag?.selectType === SelectTypeEnum.SELECT || tag?.selectType === SelectTypeEnum.BOOLEAN) {
              filter = child.tag.id;
            }
            if (
              tag?.selectType === SelectTypeEnum.YEAR ||
              tag?.selectType === SelectTypeEnum.MULTISELECT ||
              tag?.selectType === SelectTypeEnum.ADVANCED
            ) {
              array.push(child.tag.id);
            }
          }
          if (child.nestedChildren?.length) {
            child.nestedChildren?.forEach(nestedChild => {
              if (id === nestedChild.tag.id) {
                array.push(nestedChild.tag.id);
              }
              if (nestedChild.nestedChildren?.length) {
                nestedChild.nestedChildren?.forEach(nestedLvl2Child => {
                  if (id === nestedLvl2Child.tag.id) {
                    array.push(nestedLvl2Child.tag.id);
                  }
                });
              }
            });
          }
        });
      });

      if (
        tag?.selectType === SelectTypeEnum.YEAR ||
        tag?.selectType === SelectTypeEnum.MULTISELECT ||
        tag?.selectType === SelectTypeEnum.ADVANCED
      )
        filter = array;
    }
    return filter;
  };

  const loadInitialValues = () => {
    let values: any = {
      class: data.currentDecision?.attributes?.clazz,
      client: data.currentDecision?.attributes?.client,
      appellant: data.currentDecision?.attributes?.appellant,
      subject: data.currentDecision?.attributes?.subject,
      appealInvestmentDate: data.currentDecision?.attributes?.appealInvestmentDate
        ? moment(data.currentDecision?.attributes?.appealInvestmentDate)
        : null,
      decisionDate: data.currentDecision?.attributes?.decisionDate
        ? moment(data.currentDecision?.attributes?.decisionDate)
        : null,
      decisionPublishDate: data.currentDecision?.attributes?.decisionDate
        ? moment(data.currentDecision?.attributes?.decisionPublishDate)
        : null,
      dkomDecisionLink: data.currentDecision?.attributes?.dkomDecisionLink,
      hasVerdict: data.currentDecision?.attributes?.hasVerdict,
      lawFirm: data.currentDecision?.attributes?.lawFirm,
      decisionSignatory: data.currentDecision?.attributes?.decisionSignatory,
      councilMembers: data.currentDecision?.attributes?.councilMembers,
      verdictNum: data.currentDecision?.attributes?.verdict?.verdictNum,
      verdictDate: data.currentDecision?.attributes?.verdict?.verdictDate
        ? moment(data.currentDecision?.attributes?.verdict?.verdictDate)
        : null,
    };

    tags.tagScreens?.forEach((tag) => {
      values[tag.filter.name as keyof any] = getFilter(tag.filter.name);
    });

    return values;
  };

  const loadDefaultValues = () => {

    let values: any = {
      hasVerdict: false,
    };

    tags.tagScreens?.forEach((tag) => {
      if (tag.filter.name == FilterNameEnumHR.ZJN) {
        values[tag.filter.name as keyof any] = [tag.children?.length ? tag.children[0].tag.id : []];
      }
    });

    return values;
  };

  const clearCurrentDecision = () => {
    dispatch(clearDecisionAction());
  };

  const showConfirm = () => {
    confirm({
      title: i18n.t('decisions.confirmDelete'),
      icon: <ExclamationCircleOutlined />,
      content: i18n.t('common.cantUndo'),
      okText: i18n.t('genericButtons.yes'),
      cancelText: i18n.t('genericButtons.cancel'),
      onOk() {
        let id = getId();
        if (!id) {
          alert("Error");
          return;
        }
        dispatch(deleteDecisionAction(id));
        window.scrollTo({ top: 0, behavior: 'smooth' });

        setTimeout(() => {
          NavigationService.navigate(AdminRoutes.DKOM_DECISIONS.fullPath);
        }, 1000);
      },
    });
  };

  const filterFromCurrentYear = (
    children: ChildrenTagScreenResponseInterface[]
  ): ChildrenTagScreenResponseInterface[] => {
    const currentYear = moment().format('YYYY');
    const currentYearChild = children.find((child) => child.tag.name == currentYear);
    const index = children.indexOf(currentYearChild as ChildrenTagScreenResponseInterface);
    if (index && index > 0) children.length = index + 1;
    return [...children].reverse();
  };

  const setTab = (decisionId: number) => {
    setTabAllegation && setTabAllegation(decisionId);
  };

  return (
    <div className="dkomDecisionsCrud w100-h100">
      {(
        <Form
          form={form}
          layout="vertical"
          name="decision"
          size="large"
          scrollToFirstError
          initialValues={getId() ? loadInitialValues() : loadDefaultValues()}
          onFinish={onFinishDecision}
          onFinishFailed={onFinishDecisionFailed}
          autoComplete="off"
          requiredMark={false}
        >
          <div className="dkomDecisionsCrud__form">
            <div className="subtitle">
              <span className="text">{i18n.translate('decisions.form.sections.info')}</span>
            </div>

            <Form.Item
              label={<span className="text-bold">{i18n.translate(`decisions.form.fields.class`)}</span>}
              name="class"
              rules={[{ required: true, message: i18n.translate(`genericFormMsg.required`) }]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 12 }}
            >
              <Input style={{ borderRadius: 0 }} />
            </Form.Item>

            <Form.Item
              label={<span className="text-bold">{i18n.translate(`decisions.form.fields.client`)}</span>}
              name="client"
              rules={[{ required: true, message: i18n.translate(`genericFormMsg.required`) }]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 12 }}
            >
              <Input style={{ borderRadius: 0 }} />
            </Form.Item>

            <Form.Item
              label={<span className="text-bold">{i18n.translate(`decisions.form.fields.appellant`)}</span>}
              name="appellant"
              rules={[{ required: true, message: i18n.translate(`genericFormMsg.required`) }]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 12 }}
            >
              <Input style={{ borderRadius: 0 }} />
            </Form.Item>

            <Form.Item
              label={<span className="text-bold">{i18n.translate(`decisions.form.fields.subject`)}</span>}
              name="subject"
              rules={[{ required: true, message: i18n.translate(`genericFormMsg.required`) }]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 12 }}
            >
              <TextArea style={{ borderRadius: 0 }} autoSize={{ minRows: 4 }} />
            </Form.Item>

            <Form.Item
              label={<span className="text-bold">{i18n.translate(`decisions.form.fields.appealInvestmentDate`)}</span>}
              rules={[{ required: true, message: i18n.translate(`genericFormMsg.required`) }]}
              name="appealInvestmentDate"
            >
              {/** DISABLED DATE ISPOD AKO TREBA */}
              <DatePicker
                style={{ borderRadius: 0 }}
                locale={userAuth.lang == 'hr' ? localeHR : localeEN}
                format={DisplayDateFormat}
              />
            </Form.Item>

            <Form.Item
              label={<span className="text-bold">{i18n.translate(`decisions.form.fields.decisionDate`)}</span>}
              //rules={[{ required: true, message: i18n.translate(`genericFormMsg.required`) }]}
              name="decisionDate"
            >
              {/** DISABLED DATE ISPOD AKO TREBA */}
              <DatePicker
                style={{ borderRadius: 0 }}
                locale={userAuth.lang == 'hr' ? localeHR : localeEN}
                format={DisplayDateFormat}
              />
            </Form.Item>

            <Form.Item
              label={<span className="text-bold">{i18n.translate(`decisions.form.fields.decisionPublishDate`)}</span>}
              //rules={[{ required: true, message: i18n.translate(`genericFormMsg.required`) }]}
              name="decisionPublishDate"
            >
              {/** DISABLED DATE ISPOD AKO TREBA */}
              <DatePicker
                style={{ borderRadius: 0 }}
                locale={userAuth.lang == 'hr' ? localeHR : localeEN}
                format={DisplayDateFormat}
              />
            </Form.Item>

            <div className="subtitle">
              <span className="text">{i18n.translate('decisions.form.sections.filters')}</span>
            </div>

            {/** OVDJE ISPOD SVI FILTERI TREBAJU BITI GENERIČKI */}

            {tags.tagScreens?.map((tag) => (
              <Form.Item
                label={<span className="text-bold">{`${tag.filter.name}`}</span>}
                name={tag.filter.name}
                key={tag.filter.name}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 8 }}
              >
                {tag.selectType === SelectTypeEnum.BOOLEAN ? (
                  <Radio.Group>
                    <Radio.Button value={tag.children?.length ? tag.children[0].tag.id : 0}>
                      {tag.children?.length ? tag.children[0].tag.name : ''}
                    </Radio.Button>
                    <Radio.Button value={tag.children?.length ? tag.children[1].tag.id : 1}>
                      {tag.children?.length ? tag.children[1].tag.name : ''}
                    </Radio.Button>
                  </Radio.Group>
                ) : checkForNested(tag) &&
                  (tag.selectType === SelectTypeEnum.MULTISELECT || tag.selectType === SelectTypeEnum.ADVANCED) ? (
                  tag.selectType === SelectTypeEnum.ADVANCED ? (
                    <TreeSelect
                      treeData={setTreeData(tag.children as Array<ChildrenTagScreenResponseInterface>)}
                      treeCheckable
                      showCheckedStrategy={SHOW_ALL}
                      maxTagCount="responsive"
                    />
                  ) : (
                    <TreeSelect
                      treeData={setTreeData(tag.children as Array<ChildrenTagScreenResponseInterface>)}
                      treeCheckable
                      showCheckedStrategy={SHOW_ALL}
                      maxTagCount="responsive"
                    />
                  )
                ) : (
                  <Select
                    mode={
                      tag.selectType === SelectTypeEnum.MULTISELECT || tag.selectType === SelectTypeEnum.ADVANCED
                        ? 'multiple'
                        : undefined
                    }
                    maxTagCount={
                      tag.selectType === SelectTypeEnum.MULTISELECT || tag.selectType === SelectTypeEnum.ADVANCED
                        ? 'responsive'
                        : undefined
                    }
                    allowClear
                  >
                    {tag.selectType === SelectTypeEnum.YEAR
                      ? tag.children?.length &&
                        filterFromCurrentYear(tag.children) &&
                        filterFromCurrentYear(tag.children)?.length &&
                        filterFromCurrentYear(tag.children).map((child) => (
                          <Select.Option key={child.tag.name} value={child.tag.id}>
                            {child.tag.name}
                          </Select.Option>
                        ))
                      : tag.children?.length &&
                        tag.children.map((child) => (
                          <Select.Option key={child.tag.name} value={child.tag.id}>
                            {child.tag.name}
                          </Select.Option>
                        ))}
                  </Select>
                )}
              </Form.Item>
            ))}

            {/** GENERIC FILTERI END */}

            <div className="subtitle">
              <span className="text">{i18n.translate('decisions.form.sections.vus')}</span>
            </div>

            <Form.Item
              label={<span className="text-bold">{i18n.translate(`decisions.form.fields.hasVerdict`)}</span>}
              name="hasVerdict"
              rules={[{ required: true, message: i18n.translate(`genericFormMsg.required`) }]}
            >
              <Radio.Group>
                <Radio.Button value={true}>{i18n.translate(`decisions.form.fields.options.yes`)}</Radio.Button>
                <Radio.Button value={false}>{i18n.translate(`decisions.form.fields.options.no`)}</Radio.Button>
              </Radio.Group>
            </Form.Item>

            <Form.Item
              label={<span className="text-bold">{i18n.translate(`verdicts.form.fields.verdictDate`)}</span>}
              name="verdictDate"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 12 }}
            >
              <DatePicker
                disabled
                style={{ borderRadius: 0 }}
                locale={userAuth.lang == 'hr' ? localeHR : localeEN}
                format={DisplayDateFormat}
                placeholder={undefined}
              />
            </Form.Item>

            <Form.Item
              label={<span className="text-bold">{i18n.translate(`verdicts.form.fields.verdictNum`)}</span>}
              name="verdictNum"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 12 }}
            >
              <Input disabled style={{ borderRadius: 0 }} placeholder={undefined} />
            </Form.Item>

            <div className="subtitle">
              <span className="text">{i18n.translate('decisions.form.sections.upload')}</span>
            </div>

            <Form.Item
              label={<span className="text-bold">{i18n.translate(`decisions.form.fields.dkomDecisionLink`)}</span>}
              name="dkomDecisionLink"
              //rules={[{ required: true, message: i18n.translate(`genericFormMsg.required`) }]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 12 }}
            >
              <Input style={{ borderRadius: 0 }} />
            </Form.Item>

            { /* decisionPdfUuid */ }

            <Form.Item
              label={<span className="text-bold">{i18n.translate(`decisions.form.fields.pdf`)}</span>}
              name="pdf"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 10 }}
              //rules={
                //uploadDb.decisionPdfUuid.fileList.length === 0 ? [{ required: true, message: i18n.translate(`genericFormMsg.required`) }] : []
              //}
            >
              <Dragger {...getUploadProps('decisionPdfUuid')}>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">{i18n.translate(`decisions.form.upload.text`)}</p>
              </Dragger>
            </Form.Item>

            {uploadDb.decisionPdfUuid.fileList.length > 0 && (
              <div>
                <Button type="link" style={{ color: 'red', marginBottom: 8 }} onClick={() => onUploadFileRemove('decisionPdfUuid')}>
                  {i18n.translate(`genericButtons.delete`)}
                </Button>
                <Button type="link" style={{ marginLeft: 8, marginBottom: 8 }} onClick={() => onUploadFileDownload('decisionPdfUuid')}>
                  {i18n.translate(`genericButtons.download`)}
                </Button>
              </div>
            )}

            { /* dkomDecisionPdfUuid */ }

            <Form.Item
              label={<span className="text-bold">{i18n.translate(`decisions.form.fields.pdfDkom`)}</span>}
              name="pdfDkom"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 10 }}
              //rules={
                //uploadDb.dkomDecisionPdfUuid.fileList.length === 0
                  //</div>? [{ required: true, message: i18n.translate(`genericFormMsg.required`) }]
                  //: []
              //}
            >
              <Dragger {...getUploadProps('dkomDecisionPdfUuid')}>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">{i18n.translate(`decisions.form.upload.text`)}</p>
              </Dragger>
            </Form.Item>

            {uploadDb.dkomDecisionPdfUuid.fileList.length > 0 && (
              <div>
                <Button type="link" style={{ color: 'red', marginBottom: 8 }} onClick={() => onUploadFileRemove('dkomDecisionPdfUuid')}>
                  {i18n.translate(`genericButtons.delete`)}
                </Button>
                <Button type="link" style={{ marginLeft: 8, marginBottom: 8 }} onClick={() => onUploadFileDownload('dkomDecisionPdfUuid')}>
                  {i18n.translate(`genericButtons.download`)}
                </Button>
              </div>
            )}

            { /* pmPdfUuid */ }

            <Form.Item
              label={<span className="text-bold">{i18n.translate(`decisions.form.fields.pmPdf`)}</span>}
              name="pdfDkom"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 10 }}
            >
              <Dragger {...getUploadProps('pmPdfUuid')}>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">{i18n.translate(`decisions.form.upload.text`)}</p>
              </Dragger>
            </Form.Item>

            {uploadDb.pmPdfUuid.fileList.length > 0 && (
              <div>
                <Button type="link" style={{ color: 'red', marginBottom: 8 }} onClick={() => onUploadFileRemove('pmPdfUuid')}>
                  {i18n.translate(`genericButtons.delete`)}
                </Button>
                <Button type="link" style={{ marginLeft: 8, marginBottom: 8 }} onClick={() => onUploadFileDownload('pmPdfUuid')}>
                  {i18n.translate(`genericButtons.download`)}
                </Button>
              </div>
            )}

            { /* zznpPdfUuid */ }

            <Form.Item
              label={<span className="text-bold">{i18n.translate(`decisions.form.fields.zznpPdf`)}</span>}
              name="pdfDkom"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 10 }}
            >
              <Dragger {...getUploadProps('zznpPdfUuid')}>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">{i18n.translate(`decisions.form.upload.text`)}</p>
              </Dragger>
            </Form.Item>

            {uploadDb.zznpPdfUuid.fileList.length > 0 && (
              <div>
                <Button type="link" style={{ color: 'red', marginBottom: 8 }} onClick={() => onUploadFileRemove('zznpPdfUuid')}>
                  {i18n.translate(`genericButtons.delete`)}
                </Button>
                <Button type="link" style={{ marginLeft: 8, marginBottom: 8 }} onClick={() => onUploadFileDownload('zznpPdfUuid')}>
                  {i18n.translate(`genericButtons.download`)}
                </Button>
              </div>
            )}

            <div style={{ marginTop: 16 }} className="subtitle">
              <span className="text">{i18n.translate('decisions.form.sections.admin')}</span>
            </div>

            <Form.Item
              label={<span className="text-bold">{i18n.translate(`decisions.form.fields.lawFirm`)}</span>}
              name="lawFirm"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 12 }}
            >
              <Input style={{ borderRadius: 0 }} />
            </Form.Item>

            <Form.Item
              label={<span className="text-bold">{i18n.translate(`decisions.form.fields.decisionSignatory`)}</span>}
              name="decisionSignatory"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 12 }}
            >
              <Input style={{ borderRadius: 0 }} />
            </Form.Item>

            <Form.Item
              label={<span className="text-bold">{i18n.translate(`decisions.form.fields.councilMembers`)}</span>}
              name="councilMembers"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 12 }}
            >
              <Input style={{ borderRadius: 0 }} />
            </Form.Item>
          </div>
          <div className="dkomDecisionsCrud__bottomButtons">
            <div>
              {getId() && (
                <Button
                  danger
                  onClick={showConfirm}
                  className="button"
                  size="large"
                  icon={<DeleteOutlined />}
                  style={{ fontSize: 16, fontWeight: 700, borderRadius: 0, minWidth: 193 }}
                >
                  {i18n.translate('genericButtons.delete')}
                </Button>
              )}
            </div>
            <div>
              <Link to={AdminRoutes.DKOM_DECISIONS.fullPath} key={AdminRoutes.DKOM_DECISIONS.path}>
                <Button
                  onClick={clearCurrentDecision}
                  className="button"
                  size="large"
                  style={{ fontSize: 16, fontWeight: 700, borderRadius: 0, minWidth: 193, marginRight: 16 }}
                >
                  {i18n.translate('genericButtons.cancel')}
                </Button>
              </Link>
              <Button
                className="button"
                htmlType="submit"
                type="primary"
                size="large"
                style={{ fontSize: 16, fontWeight: 700, borderRadius: 0, minWidth: 193 }}
              >
                {i18n.translate('genericButtons.save')}
              </Button>
            </div>
          </div>
        </Form>
      )}
    </div>
  );
}

export default DKOMDecisionsCrudPageComponent;
