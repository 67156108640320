import { Button, Input, InputRef, Space, Table } from 'antd';
import type { ColumnsType, ColumnType } from 'antd/es/table';
import { FilterConfirmProps } from 'antd/lib/table/interface';
import React, { useRef, useState } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { i18n } from '../../../../services';
import {
  FullOrganizationResponseInterfaceSingle,
  FullOrganizationUnitResponseInterfaceAll,
} from '../../../../interfaces';
import moment from 'moment';
import { DisplayDateFormat } from '../../../../constants';

interface DataType {
  key: string;
  name: string;
  year: string;
  users: string;
  printNo: string;
  registerDate: string;
  adminUser: string;
}

interface Props {
  units: FullOrganizationUnitResponseInterfaceAll;
}

export default function OrganizationTableComponent({ units }: Props) {
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef<InputRef>(null);

  const data: DataType[] = units?.data?.map((unit) => {
    return {
      key: unit.id,
      name: unit.attributes.name,
      year: moment(unit.attributes.createdDate).format('YYYY.'),
      users: '5',
      printNo: '5',
      registerDate: moment(unit.attributes.createdDate).format(DisplayDateFormat),
      adminUser: unit.attributes.createdBy,
    };
  }) as unknown as DataType[];

  type DataIndex = keyof DataType;

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<DataType> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => clearFilters && handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText((selectedKeys as string[])[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex] !== undefined && record[dataIndex] !== null &&
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
  });

  const columns: ColumnsType<DataType> = [
    {
      title: i18n.translate(`organizationInfo.name`),
      dataIndex: 'name',
      key: 'name',
      width: '165px',
      ...getColumnSearchProps('name'),
    },
    {
      title: i18n.translate(`organizationInfo.year`),
      dataIndex: 'year',
      key: 'year',
      width: '100px',
    },
    {
      title: i18n.translate(`organizationInfo.users`),
      dataIndex: 'users',
      key: 'users',
      width: '175px',
    },
    {
      title: i18n.translate(`organizationInfo.printNo`),
      dataIndex: 'printSubs',
      key: 'printSubs',
      width: '175px',
    },
    {
      title: i18n.translate(`organizationInfo.registerDate`),
      dataIndex: 'registerDate',
      key: 'registerDate',
      width: '200px',
    },
    {
      title: i18n.translate(`organizationInfo.adminUser`),
      dataIndex: 'adminUser',
      key: 'adminUser',
      width: '250px',
      ...getColumnSearchProps('adminUser'),
    },
  ];
  return <Table className="organizationTable" columns={columns} dataSource={data} pagination={false} scroll={{ x: 1500 }} />;
}
