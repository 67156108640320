import Icon, { CaretDownOutlined, CaretUpOutlined, EditOutlined, FilePdfOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Popover, Row, Typography } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { NotePickModal } from '../..';
import { BookmarkOutlined } from '../../../../assets/icons/icons';
import { AdminRoutes } from '../../../../features/admin';
import { DisplayDateFormat } from '../../../constants';
import { UserRoleEnum } from '../../../enums';
import { LicenceEnum } from '../../../enums/licence.enum';
import useWindowSize from '../../../hooks/useWindowSize';
import { LicenceInterface, NoteResponseInterfaceAll, ProfessionalArticleInterface } from '../../../interfaces';
import { StoreStateInterface } from '../../../redux';
import { DownloadService, i18n } from '../../../services';
import { UserActivityLogService } from '../../../api';
import { UserActionTypeEnum } from '../../../enums/userActionType.enum';

const { Paragraph, Text, Title } = Typography;

const oneLetterSize = 6.5;
const sidebarWidth = 256;
const sidebarBreakPoint = 575;

interface Props {
  article?: ProfessionalArticleInterface;
  licenceData?: LicenceInterface;
  notes?: NoteResponseInterfaceAll;
  validLicences?: LicenceInterface[];
}

export default function ArticleCardComponent({ article, licenceData, notes, validLicences }: Props) {
  const userAuth = useSelector((state: StoreStateInterface) => state.auth);

  const windowSize = useWindowSize();

  const availableNotes = licenceData?.attributes?.notesNum as number;

  const [ellipsis, setEllipsis] = useState(true);
  const [visible, setVisible] = useState(false);

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    if (!((notes?.data.length as number) == availableNotes)) {
      setVisible(true);
    }
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const onFileDownload = () => {
    if (!article) return;
    if (userAuth?.user?.roles !== UserRoleEnum.ADMIN) {
      let userActivityLogData: any = {
        data: {
          type: 'user_activity_log',
          attributes: {
            action: UserActionTypeEnum.FILE_DOWNLOADED,
            extra: JSON.stringify({type: 'article', title: article.attributes?.title})
          },
          relationships: {
            user: {
              data: {
                id: userAuth?.user?.id,
                type: 'user'
              },
            },
          },
        },
      };

      UserActivityLogService.create(userActivityLogData);
    }
    DownloadService.download(article?.attributes?.pdfUuid);
  };

  const displayShowMoreBtn = (text?: string) => {
    if (!windowSize.width || !text) return false;
    // if (text.indexOf('\n') !== -1) return true;
    const sidebarSpace = windowSize.width > sidebarBreakPoint ? sidebarWidth : 0;
    const contentWidth = text.length * oneLetterSize;
    const paddingWidth = 25;
    const boxWidth = (windowSize.width - sidebarSpace - paddingWidth - paddingWidth) * 0.8 * 2; // x2 becouse for preview we have 2 lines
    return boxWidth < contentWidth;
  };

  const checkLicenceYear = () => {
    if (userAuth.user?.roles === UserRoleEnum.ADMIN) return true;

    if (validLicences?.length) {
      return true;
    } else {
      return moment(article?.attributes?.publishedAt).isBefore(moment().subtract(6, 'months'));
    }
  };

  const checkLicenceYearBookmark = () => {
    if (userAuth.user?.roles === UserRoleEnum.ADMIN) return true;

    if (validLicences?.length) {
      return true;
    } else {
      return false;
    }
  };

  const getYear = () => {
    let yearString = i18n.translate('common.unsorted');

    if (article?.attributes?.publishedAt) yearString = moment(article?.attributes?.publishedAt).format('YYYY');

    return yearString;
  };

  return (
    <div className="articleCard">
      <Row className="row">
        <Col flex={4}>
          <Text className="dateTime" type="secondary">
            {moment(article?.attributes?.publishedAt).format(DisplayDateFormat)}
            {article?.attributes?.author && userAuth.user?.roles === UserRoleEnum.ADMIN
              ? ` | ${article?.attributes?.author}`
              : ''}
          </Text>
        </Col>
        <Col flex={0}>
          <>
            {checkLicenceYearBookmark() ? (
              <Button
                type="link"
                className="cardBtn"
                onClick={() => showModal()}
                icon={<Icon component={BookmarkOutlined} />}
              />
            ) : (
              <Popover
                placement="topRight"
                content={i18n.translate(`common.licence.content`)}
                title={i18n.translate(`common.licence.title`)}
              >
                <Button disabled type="link" className="cardBtn" icon={<Icon component={BookmarkOutlined} />} />
              </Popover>
            )}
            {article?.attributes?.pdfUuid ? (
              <Button type="link" onClick={onFileDownload} className="cardBtn" icon={<FilePdfOutlined />} />
            ) : (
              <Popover
                placement="topRight"
                content={i18n.translate(`common.licence.content`)}
                title={i18n.translate(`common.licence.title`)}
              >
                <Button disabled type="link" className="cardBtn" icon={<FilePdfOutlined />} />
              </Popover>
            )}
            {userAuth.user?.roles === UserRoleEnum.ADMIN && (
              <Link
                to={AdminRoutes.EXPERT_ARTICLES.path + `/${article?.id}`}
                key={AdminRoutes.EXPERT_ARTICLES.path + `/${article?.id}`}
              >
                <Button type="link" className="cardBtn" icon={<EditOutlined />} />
              </Link>
            )}
          </>
        </Col>
      </Row>
      <Title level={4} className={'articleCard___title'}>
        {article?.attributes?.title}
      </Title>
      <Paragraph
        className='para'
        ellipsis={ellipsis ? { rows: 2 } : false}
        style={{ whiteSpace: ellipsis ? 'normal' : 'pre-wrap' }}
      >
        {article?.attributes?.content}
      </Paragraph>
      {ellipsis && displayShowMoreBtn(article?.attributes?.content) && (
        <Button
          className="cardBtn"
          type="link"
          icon={<CaretDownOutlined />}
          onClick={() => {
            setEllipsis(!ellipsis);
          }}
        >
          {i18n.translate('common.readMore')}
        </Button>
      )}
      {!ellipsis && (
        <Button
          className="cardBtn"
          type="link"
          icon={<CaretUpOutlined />}
          onClick={() => {
            setEllipsis(!ellipsis);
          }}
        >
          {i18n.translate('common.hide')}
        </Button>
      )}
      <Divider />
      <NotePickModal
        isExternal={true}
        visible={visible}
        handleOk={handleOk}
        handleCancel={handleCancel}
        external={article}
        type="professional_article"
        typeId={article?.id}
        breadcrumb={'Mreža znanja/Stručni članci/' + article?.attributes?.title}
        number={article?.id}
        isEditable={true}
        active={true}
        licenceData={licenceData as LicenceInterface}
        validLicences={validLicences}
      />
    </div>
  );
}
