import { Dispatch } from 'redux';
import { notification } from 'antd';
import { OrderConstants } from './order.constants';

import { OrderService } from '../../api/api.service';
import { OrderResponseInterfaceAll, OrderResponseInterfaceSingle } from '../../interfaces';
import { i18n } from '../../services';

export function getAllOrdersAction(params?: any, filter?: any, sort?: any, pagination?: any) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: OrderConstants.SET_LOADER,
      payload: true
    });

    dispatch({
      type: OrderConstants.GET_ALL_SUCCESS,
      payload: {
        data: [],
        meta: {}
      },
    });

    return OrderService.getAll(params, filter, sort, pagination).subscribe(
      (response: OrderResponseInterfaceAll) => {
        dispatch({
          type: OrderConstants.GET_ALL_SUCCESS,
          payload: response,
        });
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });

        dispatch({
          type: OrderConstants.ERROR,
          payload: error,
        });
      }
    );
  };
}

export function getSingleOrderAction(id: number) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: OrderConstants.SET_LOADER,
      payload: true
    });

    return OrderService.getSingle(id).subscribe(
      (response: OrderResponseInterfaceSingle) => {
        dispatch({
          type: OrderConstants.GET_SINGLE_SUCCESS,
          payload: response,
        });
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });

        dispatch({
          type: OrderConstants.ERROR,
          payload: error,
        });
      }
    );
  };
}

export function createOrderAction(body: OrderResponseInterfaceSingle, showNotif: boolean = true) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: OrderConstants.SET_LOADER,
      payload: true
    });

    return OrderService.create(body).subscribe(
      (response: OrderResponseInterfaceSingle) => {
        if (showNotif) notification['success']({ message: i18n.translate('api.successMessage'), duration: 2 });

        dispatch({
          type: OrderConstants.CREATE_SUCCESS,
          payload: response,
        });
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });

        dispatch({
          type: OrderConstants.ERROR,
          payload: error,
        });
      }
    );
  };
}

export function updateOrderAction(body: OrderResponseInterfaceSingle, showNotif: boolean = true) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: OrderConstants.SET_LOADER,
      payload: true
    });

    return OrderService.update(body).subscribe(
      (response: OrderResponseInterfaceSingle) => {
        if (showNotif) notification['success']({ message: i18n.translate('api.successMessage'), duration: 2 });

        dispatch({
          type: OrderConstants.UPDATE_SUCCESS,
          payload: response,
        });
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });

        dispatch({
          type: OrderConstants.ERROR,
          payload: error,
        });
      }
    );
  };
}

export function deleteOrderAction(id: number) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: OrderConstants.SET_LOADER,
      payload: true
    });

    return OrderService.delete(id).subscribe(
      (response: any) => {
        dispatch({
          type: OrderConstants.DELETE
        });
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });

        dispatch({
          type: OrderConstants.ERROR,
          payload: error,
        });
      }
    );
  };
}

export function clearOrderAction() {
  return (dispatch: Dispatch) => {
    dispatch({ type: OrderConstants.CLEAR_CURRENT });
  };
}

export function clearOrderListAction() {
  return (dispatch: Dispatch) => {
    dispatch({ type: OrderConstants.CLEAR_LIST });
  };
}
