import { Button, notification, Row, Spin, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { ExternalService } from '../../../api';
import { NewsInterface } from '../../../interfaces';
import { i18n } from '../../../services';
import NewsCardComponent from '../../cards/newsCards/newsCard.component';

const { Title } = Typography;

export default function NewsComponent() {

  const [newsData, setNewsData] = useState<NewsInterface[]>([]);
  const [loading, setLoading] = useState(true);
  
  useEffect(() => {
    localStorage.removeItem('transferFilters');
    ExternalService.getNews().subscribe(
      (response: NewsInterface[]) => {
        setNewsData(response);
        setLoading(false);
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Spin spinning={loading}>
      <div className="news">
        <div className="section">
          <Title className="title" level={3}>
            {i18n.translate('dashboard.news.title')}
          </Title>

          <div className="news">
            {newsData &&
              newsData.length > 2 &&
              [...newsData]
                .slice(0, 2)
                .map((newsSingle, i) => <NewsCardComponent key={'news' + i} newsSingle={newsSingle} />)}
            <Row justify="start">
              <Button
                type="primary"
                onClick={() => {
                  window.open(process.env.REACT_APP_URL_WP + '/novosti', '_blank');
                }}
                className="cardBtn"
              >
                {i18n.translate('dashboard.news.all')}
              </Button>
            </Row>
          </div>
        </div>
      </div>
    </Spin>
  );
}
