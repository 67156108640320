import React, { useEffect } from 'react';
import { Modal } from 'antd';
import i18n from '../../services/translate.service';

interface Props {
  style?: any;
}

export default function VersionComponent({ style }: Props) {
  useEffect(() => {
    getVersion();
  }, []);

  function getVersion() {
    try {
      fetch(`${window.location.origin}/version.json?v=${new Date().getTime()}`)
        .then((response) => response.json())
        .then((data) => {
          processVersion(data.version);
        });
    } catch (error) {}
  }

  function processVersion(jsonVersion: string) {
    const isSameVersion = process.env.REACT_APP_VERSION_NO === jsonVersion;
    if (!isSameVersion) {
      showModal();
    }
  }

  const showModal = () => {
    Modal.info({
      okText: i18n.translate('common.version.btn'),
      title: i18n.translate('common.version.title'),
      content: (
        <div>
          <p>{i18n.translate('common.version.contant')}</p>
        </div>
      ),
      closable: true,
      onOk() {
        window.location.reload();
      },
    });
  };

  return (
    <div className="version-widget" style={style}>
      {process.env.REACT_APP_VERSION_NO} - {process.env.REACT_APP_VERSION_DATE}
    </div>
  );
}
