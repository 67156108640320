import { notification } from 'antd';
import { FileService } from '../api/api.service';
import i18n from './translate.service';

function download(uuid: string) {
  FileService.temp(uuid).subscribe(
    (response: any) => {
      if (response.url) {
        let url = response.url + '/bjn';
        window.open(url, '_blank');
      } else {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
      }
    },
    (error: Error) => {
      notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
    }
  );
}



export default {
  download
};
