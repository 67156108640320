import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { i18n } from '../../../services';
import { UpgradeRequestService } from '../../../api';
import moment from 'moment';
import Title from 'antd/lib/typography/Title';
import { DisplayDateFormat, JavaFormat } from '../../../constants';

interface DataType {
  key: number;
  id: number;
  user: string;
  organization: string;
  userId: string;
  organizationUnitId: string;
  licenceType: string;
  printedCopyYears: string;
  printedCopyNum: number;
  additionalYears: string;
  createdDate: string;
  status: string;
  extra?: string;
}

interface Props {
  userId: number;
}

export default function UserUpgradeRequestComponent({ userId }: Props) {

  const [data, setData] = useState<Array<DataType>>([]);

  useEffect(() => {
    initData();
  }, []);

  function initData() {
    UpgradeRequestService.getAllFromUser(userId).subscribe((resp) => {
      let d: Array<DataType> = [];

      resp?.data?.forEach((el) => {
        let user = resp?.included?.find(x => x.type == 'user' && x.id == el.relationships?.user?.data?.id);
        let org = resp?.included?.find(x => x.type == 'organization_unit' && x.id == user?.relationships?.organizationUnit?.data?.id);
        d.push({
          key: el.id!,
          id: el.id!,
          user: user?.attributes?.name,
          organization: org?.attributes?.name,
          userId: user?.id,
          organizationUnitId: org?.id,
          licenceType: el.attributes.licenceType!,
          printedCopyYears: el.attributes.printedCopyYears ? el.attributes.printedCopyYears : '',
          printedCopyNum: el.attributes.printedCopyNum!,
          additionalYears: el.attributes.additionalYears!,
          createdDate: moment(el.attributes.createdDate).format(DisplayDateFormat),
          status: el.attributes.status!,
          extra: el.attributes?.extra
        });
      });

      setData(d);
    });
  }

  const getPrintedCopyText = (record: any) => {
    let extra = JSON.parse(record.extra as string);
    if (record.printedCopyYears && record.printedCopyYears?.split(',')?.length > 1) {
      if (extra?.biltenSubscriptionYears?.length == 2) {
        return `${record.printedCopyNum} (${record.printedCopyYears?.split(',')[0]}), ${record.printedCopyNum} (${record.printedCopyYears?.split(',')[1]})`
      }
      if (extra?.biltenSubscriptionYears?.length == 1 && !extra?.biltenSubscriptionYears?.includes('0')) {
        return `${record.printedCopyNum} (${record.printedCopyYears?.split(',')[0]})`
      }

      return record.printedCopyNum
    } else {
      return record.printedCopyNum == 0 ? record.printedCopyNum : (record.printedCopyNum + (record.printedCopyYears ? ` (${record.printedCopyYears})` : ` (${extra?.oldYear})`));
    }
  };

  const columns: ColumnsType<DataType> = [
    {
      title: i18n.translate(`adminProfile.upgradeRequestTable.licenceType`),
      dataIndex: 'licenceType',
      render: (text, record, index) => {
        return i18n.translate(`common.licenceType.${text}`);
      },
    },
    {
      title: i18n.translate(`adminProfile.upgradeRequestTable.printedCopyNum`),
      dataIndex: 'printedCopyNum',
      key: 'printedCopyNum',
      render: (text, record, index) => getPrintedCopyText(record),
    },
    {
      title: i18n.translate(`adminProfile.upgradeRequestTable.additionalYears`),
      dataIndex: 'additionalYears',
      key: 'additionalYears',
    },
    {
      title: i18n.translate(`adminProfile.upgradeRequestTable.createdDate`),
      dataIndex: 'createdDate',
      key: 'createdDate',
    },
  ];
  return (
    <>
      {data?.filter(x => x.status === 'ZAPRIMLJENO')?.length ? (
      <>
        <Title level={5} style={{ color: '#1210A9', marginTop: 16, marginBottom: 16 }}>{i18n.translate('adminProfile.tabs.upgradeRequest')}</Title>
        <Table
          scroll={{ x: 1500, y: 600 }}
          pagination={false}
          columns={columns}
          dataSource={data?.filter(x => x.status === 'ZAPRIMLJENO')}
          locale={{ emptyText: i18n.t('common.noData'), triggerDesc: i18n.t('common.sortDesc'), triggerAsc: i18n.t('common.sortAsc'), cancelSort: i18n.t('common.cancelSort')}}
        />
      </>) : null}
    </>
  );
}
