import React, { useEffect, useState } from 'react';
import { ChildrenTagScreenResponseInterface, clearProfessionalArticleAction, createProfessionalArticleAction, deleteProfessionalArticleAction, DisplayDateFormat, FileFormatEnum, FileService, getScreenTagsByTypeAction, getSingleProfessionalArticleAction, i18n, JavaFormat, NavigationService, ProfessionalArticleStateInterface, SelectTypeEnum, StoreStateInterface, TagScreenResponseInterface, TagScreenStateInterface, updateProfessionalArticleAction } from '../../../../common';
import {
  InboxOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined
} from '@ant-design/icons';
import { Button, DatePicker, Divider, Form, Input, message, Modal, notification, Radio, Select, TreeSelect, Upload, UploadFile, UploadProps } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { AdminRoutes } from '../../_router/admin.routes';
import localeHR from 'antd/es/date-picker/locale/hr_HR';
import localeEN from 'antd/es/date-picker/locale/en_GB';
import moment from 'moment';
import { RangePickerProps } from 'antd/lib/date-picker';

const { SHOW_ALL } = TreeSelect;

const { TextArea } = Input;

const { Dragger } = Upload;

const { confirm } = Modal;

function ExpertArticlesCrudPageComponent() {
  const location = useLocation();
  const path = location.pathname.split('/');
  const id = parseInt(path[path.length - 1]);
  const isNew = isNaN(id);

  const dispatch = useDispatch();
  const data: ProfessionalArticleStateInterface = useSelector((state: StoreStateInterface) => state.professionalArticle);
  const tags: TagScreenStateInterface = useSelector((state: StoreStateInterface) => state.tagScreen);
  const userAuth = useSelector((state: StoreStateInterface) => state.auth);

  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [pdfUuid, setPdfUuid] = useState('');

  useEffect(() => {
    dispatch(clearProfessionalArticleAction());
    dispatch(getScreenTagsByTypeAction('professional_article'));
    if (!isNew) dispatch(getSingleProfessionalArticleAction(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isNew) {
      if (data?.currentProfessionalArticle?.attributes?.pdfUuid) {
        FileService.list(data?.currentProfessionalArticle?.attributes?.pdfUuid).subscribe(
          (response: any) => {
            setFileList([{name: response.fileName, uid: response.uuid}]);
            setPdfUuid(response.uuid);
          },
          (error: Error) => {
           console.log(error);
          }
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.currentProfessionalArticle?.attributes]);

  const onFileDownload = () => {
    FileService.temp(pdfUuid).subscribe(
      (response: any) => {
        if (response.url) {
          notification['success']({ message: i18n.translate('api.downloadSuccessMessage'), duration: 2 });
          var link = document.createElement('a');
          link.href = response.url;
          link.download = 'file.pdf';
          link.dispatchEvent(new MouseEvent('click'));
        } else {
          notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
        }
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
      }
    );
  };

  const beforeUpload = (file: any) => {
    if (!FileFormatEnum.ALLOWED.split(',').includes(file.type)) {
      message.error(i18n.translate("genericFileUpload.formatError", {filename: file.name}));
      return false;
    } else {
      return true
    }
  };

  const onFileListChange = (info: any) => {
    if (!FileFormatEnum.ALLOWED.split(',').includes(info.file.type)) return;

    if (info.file.status === 'done') {
      passUuid(info.file.response[0].uuid);
      message.success(i18n.translate("genericFileUpload.uploadSuccess", {filename: info.file.name}));
    } else if (info.file.status === 'error') {
      message.error(i18n.translate("genericFileUpload.uploadFailed", {filename: info.file.name}));
    }
    setFileList([info.fileList.pop()]);
  };

  const onFileRemove = () => {
    passUuid('');
    setFileList([]);
  };

  const props: UploadProps = {
    name: 'files',
    accept: FileFormatEnum.ALLOWED,
    beforeUpload: (file: any) => beforeUpload(file),
    multiple: false,
    action: process.env.REACT_APP_FILE_UPLOAD,
    onChange: (info: any) => onFileListChange(info),
    fileList: fileList,
    showUploadList: {
      showRemoveIcon: false
    },
  };

  const checkForNested = (tag: TagScreenResponseInterface) => {
    let nested = tag.children?.find(child => child.nestedChildren?.length && child.nestedChildren?.length > 0);
    if (nested)
      return true;
    else 
      return false;
  };

  // eslint-disable-next-line arrow-body-style
  const disabledDate: RangePickerProps['disabledDate'] = current => {
    // Can not select year after current year or before 2016
    return current && current > moment().endOf('year') || current < moment('2015').endOf('year');
  };

  const onFinishArticle = (values: any) => {

    const getTags = () => {
      let array: Array<{id: Number, type: string}> = [];
      tags.tagScreens?.forEach(tag => {
        const value = values[tag.filter.name as keyof any];
        if (value) {
          if (tag?.selectType === SelectTypeEnum.YEAR) {
            const childTag = tag.children?.find(child => child.tag.name == moment(value).format('YYYY'));
            if (childTag) array.push({ id: childTag.tag.id as number, type: 'tag' });
          }
          if (tag?.selectType === SelectTypeEnum.SELECT || tag?.selectType === SelectTypeEnum.BOOLEAN) {
            const childTag = tag.children?.find(child => child.tag.id == value);
            if (childTag) array.push({ id: childTag.tag.id as number, type: 'tag' });
          }
          if (tag?.selectType === SelectTypeEnum.MULTISELECT) {
            const value = values[tag.filter.name as keyof any];
            if (value && value.length && value.length > 0) {
              value.forEach((id: any) => {
                if (id) array.push({ id: id, type: 'tag' })
              });
            }
          }
        }
      });

      return array;
    };

    let articleData: any = {
      data: {
        type: 'professional_article',
        id: data.currentProfessionalArticle?.id,
        attributes: {
          title: values.title,
          content: values.content,
          author: values.author,
          publishedAt: values.publishedAt ? moment(values.publishedAt).format(JavaFormat) : null,
          pdfUuid: pdfUuid,
          visibility: values.visibility
        },
        relationships: {
          tags: {
            data: getTags()
          }
        }
      }
    };
    isNew ? dispatch(createProfessionalArticleAction(articleData)) : dispatch(updateProfessionalArticleAction(articleData));
    window.scrollTo({ top: 0, behavior: 'smooth' });

    setTimeout(() => {
      NavigationService.navigate(AdminRoutes.EXPERT_ARTICLES.fullPath);
    }, 1000);
  };

  const onFinishArticleFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const setTreeData = (children: Array<ChildrenTagScreenResponseInterface>) => {
    let values = children.map(child => ({
      title: child.tag.name,
      value: child.tag.id,
      key: child.tag.id,
      children: child.nestedChildren?.map(nestedChild => ({
        title: nestedChild.tag.name,
        value: nestedChild.tag.id,
        key: nestedChild.tag.id,
        children: nestedChild.nestedChildren?.map(nestedLvl2Child => ({
          title: nestedLvl2Child.tag.name,
          value: nestedLvl2Child.tag.id,
          key: nestedLvl2Child.tag.id,
        }))
      }))
    }));
    return values;
  };

  const getDisplayConditions = () => {
    if (isNew) {
      return tags.tagScreens && tags.tagScreens.length > 0;
    } else {
      return tags.tagScreens && tags.tagScreens.length > 0 && data.currentProfessionalArticle;
    }
  };

  const getFilter = (field: string) => {
    let filter;
    let tag = tags.tagScreens?.find(tag => tag.filter.name == field);
    if (tag) {
        let array: number[] = [];
        data.currentProfessionalArticle?.attributes?.tagIds?.forEach(id => {
          tag?.children?.forEach(child => {
            if (id === child.tag.id) {
              if (tag?.selectType === SelectTypeEnum.YEAR) {
                filter = moment(child.tag.name);
              }
              if (tag?.selectType === SelectTypeEnum.SELECT || tag?.selectType === SelectTypeEnum.BOOLEAN) {
                filter = child.tag.id;
              }
              if (tag?.selectType === SelectTypeEnum.MULTISELECT) {
                array.push(child.tag.id);
              }
            }
          });
        });

        if (tag?.selectType === SelectTypeEnum.MULTISELECT) filter = array;
    }
    return filter;
  };

  const loadInitialValues = () => {
    let values: any = {
      title: data.currentProfessionalArticle?.attributes?.title,
      content: data.currentProfessionalArticle?.attributes?.content,
      author: data.currentProfessionalArticle?.attributes?.author,
      publishedAt: moment(data.currentProfessionalArticle?.attributes?.publishedAt),
      visibility: data.currentProfessionalArticle?.attributes?.visibility,
    }

    tags.tagScreens?.forEach(tag => {
      values[tag.filter.name as keyof any] = getFilter(tag.filter.name);
    });

    return values;
  };

  const clearCurrentArticle = () => {
    dispatch(clearProfessionalArticleAction());
  };

  const passUuid = (uuid: string) => {
    setPdfUuid(uuid);
  };

  const showConfirm = () => {
    confirm({
      title: i18n.t('expertArticles.confirmDelete'),
      icon: <ExclamationCircleOutlined />,
      content: i18n.t('common.cantUndo'),
      okText: i18n.t('genericButtons.yes'),
      cancelText: i18n.t('genericButtons.cancel'),
      onOk() {
        dispatch(deleteProfessionalArticleAction(id));
        window.scrollTo({ top: 0, behavior: 'smooth' });

        setTimeout(() => {
          NavigationService.navigate(AdminRoutes.EXPERT_ARTICLES.fullPath);
        }, 1000);
      }
    });
  };

  const returnComponent = (tag: TagScreenResponseInterface) => {
    let component = null;
    if (tag.selectType == SelectTypeEnum.YEAR) {
      component = <DatePicker picker="year" disabledDate={disabledDate} locale={userAuth.lang == 'hr' ? localeHR : localeEN} format={'YYYY.'} />
    }

    if (tag.selectType == SelectTypeEnum.BOOLEAN) {
      component = <Radio.Group>
                    <Radio.Button value={tag.children?.length ? tag.children[0].tag.id : 0}>{tag.children?.length ? tag.children[0].tag.name : ''}</Radio.Button>
                    <Radio.Button value={tag.children?.length ? tag.children[1].tag.id : 1}>{tag.children?.length ? tag.children[1].tag.name : ''}</Radio.Button>
                  </Radio.Group>
    }

    if (tag.selectType == SelectTypeEnum.SELECT || tag.selectType == SelectTypeEnum.MULTISELECT) {
      if (checkForNested(tag)) {
        component = <TreeSelect treeData={setTreeData(tag.children as Array<ChildrenTagScreenResponseInterface>)} treeCheckable showCheckedStrategy={SHOW_ALL} maxTagCount='responsive' placeholder={i18n.t('genericFormMsg.placeholders.select')} />
      } else {
        component = <Select mode={tag.selectType == SelectTypeEnum.MULTISELECT ? "multiple" : undefined} maxTagCount={tag.selectType == SelectTypeEnum.MULTISELECT ? 'responsive' : undefined} allowClear>
                      {tag.children?.length && tag.children.map(child => (
                        <Select.Option key={child.tag.name} value={child.tag.id}>{child.tag.name}</Select.Option>
                      ))}
                    </Select>
      }
    }

    return component;
  };

  return (
    <div className="expertArticlesCrud w100-h100">
    {getDisplayConditions() &&
      <Form
        layout='vertical'
        name="article"
        size='large'
        scrollToFirstError
        initialValues={
          data?.currentProfessionalArticle && !isNew ? loadInitialValues() : { visibility: "all" }
        }
        onFinish={onFinishArticle}
        onFinishFailed={onFinishArticleFailed}
        autoComplete="off"
        requiredMark={false}
      >
        <div className="expertArticlesCrud__admin">
          <div className="title">{i18n.translate(isNew ? 'expertArticles.crudTitleAdd' : 'expertArticles.crudTitleUpdate')}</div>
          <div className="buttons">
            <Link to={AdminRoutes.EXPERT_ARTICLES.fullPath} key={AdminRoutes.EXPERT_ARTICLES.path}>
              <Button onClick={clearCurrentArticle} className='button' size="large" style={{fontSize: 16, fontWeight: 700, borderRadius: 0, minWidth: 193, marginRight: 16}}>
                {i18n.translate('genericButtons.cancel')}
              </Button>
            </Link>
            <Button className='button' htmlType="submit" type="primary" size="large" style={{fontSize: 16, fontWeight: 700, borderRadius: 0, minWidth: 193}}>
              {i18n.translate('genericButtons.save')}
            </Button>
          </div>
        </div>
        <Divider />
        <div className="expertArticlesCrud__form">
          <div className='subtitle'>
            <span className='text'>{i18n.translate('expertArticles.form.sections.info')}</span>
          </div>

          <Form.Item
            label={<span className='text-bold'>{i18n.translate(`expertArticles.form.fields.title`)}</span>}
            name="title"
            rules={[{ required: true, message: i18n.translate(`genericFormMsg.required`) }]}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 12 }}
          >
            <Input style={{borderRadius: 0}} />
          </Form.Item>

          <Form.Item
            label={<span className='text-bold'>{i18n.translate(`expertArticles.form.fields.text`)}</span>}
            name="content"
            rules={[{ required: true, message: i18n.translate(`genericFormMsg.required`) }]}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 12 }}
          >
            <TextArea style={{borderRadius: 0}} autoSize={{ minRows: 4 }} />
          </Form.Item>

          <Form.Item
            label={<span className='text-bold'>{i18n.translate(`expertArticles.form.fields.publishedDate`)}</span>}
            name="publishedAt"
          >
            {/** DISABLED DATE ISPOD AKO TREBA */}
            <DatePicker style={{borderRadius: 0}} locale={userAuth.lang == 'hr' ? localeHR : localeEN} format={DisplayDateFormat} />
          </Form.Item>

          <Form.Item
            label={<span className='text-bold'>{i18n.translate(`expertArticles.form.fields.author`)}</span>}
            name="author"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 12 }}
          >
            <Input style={{borderRadius: 0}} />
          </Form.Item>

          <div className='subtitle'>
            <span className='text'>{i18n.translate('expertArticles.form.sections.filters')}</span>
          </div>

          {/** OVDJE ISPOD SVI FILTERI TREBAJU BITI GENERIČKI */}

          {/** OSIM OVOG PRVOG */}
          <Form.Item
            label={<span className='text-bold'>{i18n.translate(`expertArticles.form.fields.visibility`)}</span>}
            name="visibility"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Radio.Group>
              <Radio.Button value="all">{i18n.t('visibility.all')}</Radio.Button>
              <Radio.Button value="subscribers">{i18n.t('visibility.subscribers')}</Radio.Button>
            </Radio.Group>
          </Form.Item>

          {tags.tagScreens?.map(tag => (
            <Form.Item
              label={<span className='text-bold'>{`${tag?.filter?.name}`}</span>}
              name={tag?.filter?.name}
              key={tag?.filter?.name}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 8 }}
            >
              {returnComponent(tag)}
            </Form.Item>
          ))}

          {/** GENERIC FILTERI END */}

          <div className='subtitle'>
            <span className='text'>{i18n.translate('expertArticles.form.sections.upload')}</span>
          </div>

          <Form.Item
            label={<span className='text-bold'>{i18n.translate(`expertArticles.form.fields.pdf`)}</span>}
            name="pdf"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 10 }}
          >
            <Dragger {...props}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">{i18n.translate(`expertArticles.form.upload.text`)}</p>
            </Dragger>
          </Form.Item>

          {fileList && fileList.length > 0 &&
          <div>
            <Button type='link' style={{color: 'red', marginBottom: 8}} onClick={onFileRemove}>{i18n.translate(`genericButtons.delete`)}</Button>
            <Button type='link' style={{marginLeft: 8, marginBottom: 8}} onClick={onFileDownload}>{i18n.translate(`genericButtons.download`)}</Button>
          </div>
          }
        </div>
        <div className="expertArticlesCrud__bottomButtons">
          <div>
            {!isNew && 
            <Button danger onClick={showConfirm} className='button' size="large" icon={<DeleteOutlined />} style={{fontSize: 16, fontWeight: 700, borderRadius: 0, minWidth: 193}}>
              {i18n.translate('genericButtons.delete')}
            </Button>}
          </div>
          <div>
            <Link to={AdminRoutes.EXPERT_ARTICLES.fullPath} key={AdminRoutes.EXPERT_ARTICLES.path}>
              <Button onClick={clearCurrentArticle} className='button' size="large" style={{fontSize: 16, fontWeight: 700, borderRadius: 0, minWidth: 193, marginRight: 16}}>
                {i18n.translate('genericButtons.cancel')}
              </Button>
            </Link>
            <Button className='button' htmlType="submit" type="primary" size="large" style={{fontSize: 16, fontWeight: 700, borderRadius: 0, minWidth: 193}}>
              {i18n.translate('genericButtons.save')}
            </Button>
          </div>
        </div>
      </Form>
    }
    </div>
  );
}

export default ExpertArticlesCrudPageComponent;
