import { Dispatch } from 'redux';
import { notification } from 'antd';
import { RoadmapConstants } from './roadmap.constants';

import { RoadmapService } from '../../api/api.service';
import { RoadmapResponseInterfaceAll, RoadmapResponseInterfaceSingle } from '../../interfaces';
import { i18n } from '../../services';

export function getAllRoadmapsAction(params?: any) {
  return (dispatch: Dispatch) => {
    return RoadmapService.getAll(params).subscribe(
      (response: RoadmapResponseInterfaceAll) => {
        dispatch({
          type: RoadmapConstants.GET_ALL_SUCCESS,
          payload: response,
        });
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });

        dispatch({
          type: RoadmapConstants.ERROR,
          payload: error,
        });
      }
    );
  };
}

export function getSingleRoadmapAction(id: number) {
  return (dispatch: Dispatch) => {
    return RoadmapService.getSingle(id).subscribe(
      (response: RoadmapResponseInterfaceSingle) => {
        dispatch({
          type: RoadmapConstants.GET_SINGLE_SUCCESS,
          payload: response,
        });
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });

        dispatch({
          type: RoadmapConstants.ERROR,
          payload: error,
        });
      }
    );
  };
}

export function createRoadmapAction(body: RoadmapResponseInterfaceSingle) {
  return (dispatch: Dispatch) => {
    return RoadmapService.create(body).subscribe(
      (response: RoadmapResponseInterfaceSingle) => {
        notification['success']({ message: i18n.translate('api.successMessage'), duration: 2 });

        dispatch({
          type: RoadmapConstants.CREATE_SUCCESS,
          payload: response,
        });
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });

        dispatch({
          type: RoadmapConstants.ERROR,
          payload: error,
        });
      }
    );
  };
}

export function updateRoadmapAction(body: RoadmapResponseInterfaceSingle) {
  return (dispatch: Dispatch) => {
    return RoadmapService.update(body).subscribe(
      (response: RoadmapResponseInterfaceSingle) => {
        notification['success']({ message: i18n.translate('api.successMessage'), duration: 2 });

        dispatch({
          type: RoadmapConstants.UPDATE_SUCCESS,
          payload: response,
        });
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });

        dispatch({
          type: RoadmapConstants.ERROR,
          payload: error,
        });
      }
    );
  };
}

export function deleteRoadmapAction(id: number) {
  return (dispatch: Dispatch) => {
    return RoadmapService.delete(id).subscribe(
      (response: any) => {
        dispatch({
          type: RoadmapConstants.DELETE
        });
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });

        dispatch({
          type: RoadmapConstants.ERROR,
          payload: error,
        });
      }
    );
  };
}

export function clearRoadmapAction() {
  return (dispatch: Dispatch) => {
    dispatch({ type: RoadmapConstants.CLEAR_CURRENT });
  };
}

export function clearRoadmapListAction() {
  return (dispatch: Dispatch) => {
    dispatch({ type: RoadmapConstants.CLEAR_LIST });
  };
}
