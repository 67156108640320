import Icon, { EditOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Row, Typography } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { NotePickModal } from '../../..';
import { BookmarkOutlined } from '../../../../../assets/icons/icons';
import { AdminRoutes } from '../../../../../features/admin';
import { VerdictService } from '../../../../api';
import { DisplayDateFormat } from '../../../../constants';
import { UserRoleEnum } from '../../../../enums';
import {
  DecisionInterface,
  LicenceInterface,
  NoteResponseInterfaceAll,
  OrderInterface,
  TagScreenResponseInterface,
  VerdictInterface
} from '../../../../interfaces';
import { StoreStateInterface } from '../../../../redux';
import { i18n } from '../../../../services';
import DecisionsDetailsDrawer from '../../../decisionsDetailsDrawer/decisionsDetailsDrawer.component';

const { Paragraph, Text } = Typography;

interface Props {
  order?: OrderInterface;
  tags?: Array<TagScreenResponseInterface>;
  licenceData?: LicenceInterface;
  notes?: NoteResponseInterfaceAll;
  validLicences?: LicenceInterface[];
}

export default function VUSComplaintCardComponent({ order, tags, licenceData, notes, validLicences }: Props) {
  const userAuth = useSelector((state: StoreStateInterface) => state.auth);

  const [verdict, setVerdict] = useState<VerdictInterface['attributes']>();
  const [decision, setDecision] = useState<DecisionInterface['attributes']>();
  const [visible, setVisible] = useState(false);

  const availableNotes = licenceData?.attributes?.notesNum as number;

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    if (!((notes?.data.length as number) == availableNotes)) {
      setVisible(true);
    }
    setVisible(false)
  };

  const handleCancel = () => {
    setVisible(false);
  };

  useEffect(() => {
    const id = order?.relationships?.verdict?.data?.id
      ? order?.relationships?.verdict?.data?.id
      : order?.attributes?.verdict?.id;
    if (id) {
      setVerdict(order?.attributes?.verdict);
      setDecision(order?.attributes?.verdict?.decision);
      /**
      VerdictService.getSingle(id).subscribe(
        (response: any) => {
          setVerdict(response.data);
          setDecision(response.included[0]);
        },
        (error: Error) => {
          console.log(error);
        }
      );
      */
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order]);

  return (
    <div className="orderCard">
      {order ? (
        <>
          <Row className="row">
            <Col flex={4}>
              <Text className="dateTime" type="secondary">
                {verdict?.verdictNum} <Divider type="vertical" />{' '}
                {moment(verdict?.verdictDate).format(DisplayDateFormat)}
              </Text>
            </Col>
            <Col flex={0}>
              <Button
                type="link"
                className="cardBtn"
                onClick={showModal}
                icon={<Icon component={BookmarkOutlined} />}
              />

              {userAuth.user?.roles === UserRoleEnum.ADMIN && (
                <Link
                  to={AdminRoutes.VUS_DECLARATIONS.fullPath + `/${verdict?.id}`}
                  key={AdminRoutes.VUS_DECLARATIONS.fullPath + `/${verdict?.id}`}
                >
                  <Button type="link" className="cardBtn" icon={<EditOutlined />} />
                </Link>
              )}
            </Col>
          </Row>
          <Paragraph className='para' style={{ whiteSpace: 'pre-wrap' }}>{order?.attributes?.titleContent}</Paragraph>

          <DecisionsDetailsDrawer
            drawerTitle={i18n.translate('responses.title')}
            decision={decision}
            verdict={verdict}
            data={order}
            tags={tags}
            licenceData={licenceData}
            validLicences={validLicences}
          />

          <Divider />
          <NotePickModal
            isExternal={true}
            visible={visible}
            handleOk={handleOk}
            handleCancel={handleCancel}
            external={order}
            type="verdict_prosecution_order"
            typeId={order?.id}
            breadcrumb={'Pravna zaštita/Praksa u RH/Presude VUS-a/Tužbeni navodi/' + order?.attributes?.titleContent}
            number={order?.id}
            isEditable={true}
            active={true}
            licenceData={licenceData as LicenceInterface}
            validLicences={validLicences}
          />
        </>
      ) : null}
    </div>
  );
}
