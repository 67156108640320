import { Button, Drawer, Table, notification } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { i18n } from '../../services';
import { UserActionTypeEnum, getUserActionLabel, translateDomainString } from '../../enums/userActionType.enum';
import { UserLogStatisticsService } from '../../api';
import { UserLicenceInterface } from '../../interfaces';

interface Props {
  tableRecord: any;
  open: boolean;
  onClose: () => void;
  startDate: any;
  endDate: any;
  title?: string;
}

export default function StatisticDetailsDrawer({ tableRecord, open, onClose, startDate, endDate, title }: Props) {

  const [data, setData] = useState<{id: number, action: string, extra: string, users: Array<UserLicenceInterface>}>();

  useEffect(() => {
    if (open) {
      getStatisticDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const getStatisticDetails = () => {
    let filter = `${`action=${tableRecord.action}`}`;
    filter += tableRecord.extra ? `&extra=${tableRecord.extra}` : `&extra=`;
    filter += `&startDateTime=${startDate}&endDateTime=${endDate}`;
    const sort = 'sort=-id';
    const pagination = `page[offset]=0&page[limit]=999999`;
    UserLogStatisticsService.getStatisticDetails(filter, undefined, sort, pagination).subscribe(
      (response: any) => {
        setData(response);
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
      }
    );
  }

  const getExtraLabel = (record: any) => {
    switch (record.action) {
      case UserActionTypeEnum.LOGIN:
        return 'Korisnik se prijavio u sustav';
      case UserActionTypeEnum.LOGOUT:
        return 'Korisnik se odjavio iz sustava';
      case UserActionTypeEnum.CATEGORY_ACCESSED:
        return `Korisnik pristupio kategoriji ${translateDomainString(record.extra)}`;
      case UserActionTypeEnum.FILTERS_USED:
        return processFiltersUsed(JSON.parse(record.extra));
      case UserActionTypeEnum.FILE_OPENED:
        return `Korisnik otvorio datoteku ${JSON.parse(record.extra).title ? JSON.parse(record.extra).title : JSON.parse(record.extra).name} (domena ${translateDomainString(JSON.parse(record.extra).type)}) ` + (JSON.parse(record.extra).documentType ? `vrste ${JSON.parse(record.extra).documentType}` : ``);
      case UserActionTypeEnum.FILE_DOWNLOADED:
        return `Korisnik preuzeo datoteku ${JSON.parse(record.extra).title ? JSON.parse(record.extra).title : JSON.parse(record.extra).name} (domena ${translateDomainString(JSON.parse(record.extra).type)}) ` + (JSON.parse(record.extra).documentType ? `vrste ${JSON.parse(record.extra).documentType}` : ``);
      case UserActionTypeEnum.NOTE_CREATED:
        return `Korisnik kreirao bilješku${record.extra && record.extra !== 'notes' ? (' za ' + translateDomainString(record.extra)) : ' bez povezanog sadržaja'}`;
      case UserActionTypeEnum.INSTITUTION_VISITED:
        return `Naziv institucije - ${JSON.parse(record.extra).organizationName}`;
      default:
        return '—';
    }
  };

  const processFiltersUsed = (filters: any) => {
    const domainString = filters[0]?.domain;

    let finalString = domainString ? `Iskorišteni filteri u domeni ${translateDomainString(domainString)}:` : 'Iskorišteni filteri:';

    filters.forEach((filter: any) => {
      finalString += `\n${filter.parentFilterUsed} - iskorišteno iz kategorije: ${filter.childFiltersNum}`;
    });

    return finalString;
  };

  // ===== RENDER =====

  const columnsUser = [
    {
      title: 'Akcija',
      dataIndex: 'action',
      key: 'action',
      show: true,
      render: (text: any, record:any, index: any) => {
        return (<span>{data?.action ? getUserActionLabel(data.action) : '—'}</span>);
      }
    },
    {
      title: 'Organizacija',
      dataIndex: 'organizationName',
      key: 'organizationName',
      show: true,
      render: (text: any, record:any, index: any) => {
        return (<span>{record.user?.organizationName ? record.user?.organizationName : '—'}</span>);
      }
    },
    {
      title: 'Korisnik',
      dataIndex: 'createdBy',
      key: 'createdBy',
      show: true,
      render: (text: any, record:any, index: any) => {
        return (<span>{record.user?.name ? record.user?.name : '—'}</span>);
      }
    },
    {
      title: 'IP Adresa',
      dataIndex: 'ipAddress',
      key: 'ipAddress',
      show: true
    },
    {
      title: 'Detalji',
      dataIndex: 'extra',
      key: 'extra',
      show: true,
      render: (text: any, record:any, index: any) => {
        return (<span>{data?.extra ? getExtraLabel(data) : '—'}</span>);
      }
    }
  ].filter(x => x.show);

  const downloadDetailsCSV = () => {
    let filter = `${`action=${tableRecord.action}`}`;
    filter += tableRecord.extra ? `&extra=${tableRecord.extra}` : `&extra=`;
    filter += `&startDateTime=${startDate}&endDateTime=${endDate}`;
    const sort = 'sort=-id';
    const pagination = `page[offset]=0&page[limit]=999999`;
    UserLogStatisticsService.getStatisticDetails(filter, undefined, sort, pagination).subscribe(
      (response: any) => {
        let attrs = ["action", "organizationName", "createdBy", "ipAddress", "extra"];
        let csvContent = "data:text/csv;charset=utf-8,";

        // Header processing

        const translateHeader = (text: string) => {
          switch (text) {
            case 'id':
              return 'ID';
            case 'action':
              return 'Akcija';
            case 'organizationName':
              return 'Organizacija';
            case 'createdBy':
              return 'Korisnik';
            case 'ipAddress':
              return 'IP adresa';
            case 'createdDate':
              return 'Datum';
            case 'extra':
              return 'Detalji';
            default:
              return '—';
          }
        }

        // Header
        let row = [...attrs].map(x => translateHeader(x)).join(";");
        csvContent += row + "\r\n";

        // Data processing

        const getUser = (userData: any) => {
          let userString = '';
    
          if (userData?.user?.name) {
            userString = userData?.user?.name;
          }
    
          return userString;
        };

        const getOrganization = (userData: any) => {
          let orgString = '';
    
          if (userData?.user?.organizationName) {
            orgString = userData?.user?.organizationName;
          }
    
          return orgString;
        };

        const getExtraLabel = (record: any) => {
          switch (tableRecord.action) {
            case UserActionTypeEnum.LOGIN:
              return 'Korisnik se prijavio u sustav';
            case UserActionTypeEnum.LOGOUT:
              return 'Korisnik se odjavio iz sustava';
            case UserActionTypeEnum.CATEGORY_ACCESSED:
              return `Korisnik pristupio kategoriji ${translateDomainString(record.extra)}`;
            case UserActionTypeEnum.FILTERS_USED:
              return processFiltersUsed(JSON.parse(record.extra));
            case UserActionTypeEnum.FILE_OPENED:
              return `Korisnik otvorio datoteku ${JSON.parse(record.extra).title ? JSON.parse(record.extra).title : JSON.parse(record.extra).name} (domena ${translateDomainString(JSON.parse(record.extra).type)}) ` + (JSON.parse(record.extra).documentType ? `vrste ${JSON.parse(record.extra).documentType}` : ``);
            case UserActionTypeEnum.FILE_DOWNLOADED:
              return `Korisnik preuzeo datoteku ${JSON.parse(record.extra).title ? JSON.parse(record.extra).title : JSON.parse(record.extra).name} (domena ${translateDomainString(JSON.parse(record.extra).type)}) ` + (JSON.parse(record.extra).documentType ? `vrste ${JSON.parse(record.extra).documentType}` : ``);
            case UserActionTypeEnum.NOTE_CREATED:
              return `Korisnik kreirao bilješku${record.extra && record.extra !== 'notes' ? (' za ' + translateDomainString(record.extra)) : ' bez povezanog sadržaja'}`;
            case UserActionTypeEnum.INSTITUTION_VISITED:
              return `Naziv institucije - ${JSON.parse(record.extra).organizationName}`;
            default:
              return '—';
          }
        };
    
        const processFiltersUsed = (filters: any) => {
          const domainString = filters[0]?.domain;
    
          let finalString = domainString ? `Iskorišteni filteri u domeni ${translateDomainString(domainString)}:` : 'Iskorišteni filteri:';
    
          filters.forEach((filter: any) => {
            finalString += ` ${filter.parentFilterUsed} - iskorišteno iz kategorije: ${filter.childFiltersNum}`;
          });
    
          return finalString;
        };

        const mapData = (key: string, user: any) => {
          switch (key) {
            case 'action':
              return getUserActionLabel(tableRecord.action);
            case 'organizationName':
              return getOrganization(user);
            case 'createdBy':
              return getUser(user);
            case 'extra':
              return getExtraLabel(tableRecord);
            case 'ipAddress':
              return user.ipAddress;
            default:
              return '—';
          }
        }
        
        // Data
        response.users?.forEach((dataRow: any) => {
          let userAttrs: any = dataRow;
          let row = [...attrs.map(key => mapData(key, userAttrs))].join(";");
          csvContent += row + "\r\n";
        });
        
        // Open download
        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", `bjn_detailed_statistic_${new Date(startDate).toISOString().split('T')[0]}-${new Date(endDate).toISOString().split('T')[0]}.csv`);
        document.body.appendChild(link);
        
        link.click();
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
      }
    );
  }

  return (
      <Drawer destroyOnClose title={`${title}: ${getExtraLabel(tableRecord)}`} placement="right" onClose={onClose} visible={open} width={'80%'}>
        <div className="statistic-details-drawer">
          <div className="container">
            <div style={{ width: '100%' }}>
              <Table
                scroll={{ x: 1500, y: 720 }}
                dataSource={data?.users}
                columns={columnsUser}
                pagination={false}
                locale={{ 
                  emptyText: i18n.t('common.noData'),
                  triggerDesc: i18n.t('common.sortDesc'),
                  triggerAsc: i18n.t('common.sortAsc'),
                  cancelSort: i18n.t('common.cancelSort'),
                  filterReset: i18n.t('common.reset'),
                }}
              />
              <Button onClick={downloadDetailsCSV} type="default" style={{ marginTop: 16 }} icon={<DownloadOutlined />}>Preuzmi CSV</Button>
            </div>
          </div>
        </div>
      </Drawer>
  );
}
