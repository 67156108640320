import { Button, Checkbox, Col, DatePicker, Form, FormInstance, Input, InputNumber, Modal, notification, Popconfirm, Radio, Row, Select, Space, Table, Typography } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { OrganizationUnitService, LicenceService, PriceService, UserService, FullUserService, LicenceUserService } from '../../../../api';
import { DisplayDateFormat, EUR_CONVERSION, JavaFormat } from '../../../../constants';
import { LicenceEnum } from '../../../../enums/licence.enum';
import { FullOrganizationUnitResponseInterfaceSingle, LicenceResponseInterfaceAll, LicenceInterface, PriceInterface, PriceResponseInterfaceAll, LicenceResponseInterfaceSingle } from '../../../../interfaces';
import { clearFullUserAction, createLicenceAction, deleteLicenceAction, getSingleFullUserAction, StoreStateInterface } from '../../../../redux';
import { i18n, LicenceUpgradeService } from '../../../../services';
import { AdminSubscriptionTableComponent, NoDataComponent, SubscriptionCardComponent, UpgradeModal } from '../../../index';
import localeHR from 'antd/es/date-picker/locale/hr_HR';
import localeEN from 'antd/es/date-picker/locale/en_GB';
import { AdminRoutes } from '../../../../../features/admin';
import { UserRoleEnum } from '../../../../enums';
import { DeleteTwoTone, ExclamationCircleOutlined } from '@ant-design/icons';
import AdminUpgradeRequestComponent from '../../../tables/adminUpgradeRequest/adminUpgradeRequest.component';

const { Title } = Typography;
const { Option } = Select;

const { confirm } = Modal;

export default function AdminSubscriptionModelsComponent(/**props ? */) {
  const location = useLocation();
  const path = location.pathname.split('/');
  const id = parseInt(path[path.length - 1]);

  const dispatch = useDispatch();
  const userAuth = useSelector((state: StoreStateInterface) => state.auth);
  const user = useSelector((state: StoreStateInterface) => state.fullUser);
  const [orgUnitInfo, setOrgUnitInfo] = useState<FullOrganizationUnitResponseInterfaceSingle>();
  const [licenceInfo, setLicenceInfo] = useState<LicenceInterface>();
  const [oldLicencesInfo, setOldLicencesInfo] = useState<LicenceInterface[]>([]);
  const [validLicences, setValidLicences] = useState<LicenceInterface[]>([]);
  const [priceInfo, setPriceInfo] = useState<PriceInterface>();

  const [selectedModel, setSelectedModel] = useState<string>('PREMIUM');
  
  const [visible, setVisible] = useState(false);

  const [form] = Form.useForm();

  useEffect(() => {
    dispatch(clearFullUserAction());
    dispatch(getSingleFullUserAction(id));
  }, [id]);

  useEffect(() => {
    if (user.currentFullUser?.relationships?.organizationUnit?.data?.id) {
      OrganizationUnitService.getSingle(user.currentFullUser?.relationships?.organizationUnit?.data?.id).subscribe(
        (response: FullOrganizationUnitResponseInterfaceSingle) => {
          setOrgUnitInfo(response);
        },
        (error: Error) => {
          notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
        }
      );
    }
  }, [user.currentFullUser]);

  useEffect(() => {
    if (orgUnitInfo?.data?.id) {
      LicenceService.getAll(undefined, `filter[organizationUnit.id]=${orgUnitInfo?.data?.id}`, 'sort=id', `page[offset]=0&page[limit]=999999`).subscribe(
        (response: LicenceResponseInterfaceAll) => {
          if (response?.data?.length) {
            let oldLicences: LicenceInterface[] = [];
            let validLicences: LicenceInterface[] = [];

            response?.data?.forEach(licence => {
              if (licence.attributes?.licenceUsers?.find(x => x.user?.id == id) && !licence.relationships?.licenceParent?.data) {
                setLicenceInfo(licence);
                form.setFieldsValue(loadInitialValues(licence));
              }
              if (licence.attributes?.licenceUsers?.find(x => x.user?.id == id) && !licence.relationships?.licenceParent?.data && licence.attributes?.active && moment().isBefore(moment(licence?.attributes?.to).startOf('day'))) {
                validLicences.push(licence);
              } else {
                if (licence.attributes?.licenceUsers?.find(x => x.user?.id == id) && !licence.relationships?.licenceParent?.data) oldLicences.push(licence);
              }
            });

            setOldLicencesInfo([...oldLicences]);
            setValidLicences([...validLicences]);
          }
        },
        (error: Error) => {
          notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
        }
      );
    }
  }, [orgUnitInfo?.data]);

  useEffect(() => {
    if (orgUnitInfo?.data?.id) {
      PriceService.getAll(orgUnitInfo?.data?.id).subscribe(
        (response: PriceResponseInterfaceAll) => {
          if (response?.data?.length && response.data[0]) {
            setPriceInfo(response?.data[0]);
          }
        },
        (error: Error) => {
          notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
        }
      );
    }
  }, [orgUnitInfo?.data]);

  const showModal = (model: string) => {
    setSelectedModel(model);
    setVisible(true);
  };

  const handleUpgradeOk = (form: FormInstance<any>, price: number, isUpgrade: boolean | undefined, isNew: boolean | undefined, isTrial: boolean | undefined) => {
    let values = form.getFieldsValue(true);

    let numberArrayYears: number[] = values?.years?.map((stringYear: string) => parseInt(stringYear));

    let latestYear = Math.max.apply(Math, numberArrayYears);
    var index = values?.years?.indexOf(latestYear.toString());
    if (index !== -1) {
      values?.years?.splice(index, 1);
    }

    let subs = '0';
    if (Array.isArray(values.subs)) subs = values.subs?.length?.toString();
    if (!Array.isArray(values.subs)) subs = values.subs;

    let licenceDetails: any = {
      from: values?.actDate ? moment(values?.actDate).format(JavaFormat) : moment(latestYear.toString()).startOf('year').format(JavaFormat),
      to: values?.expDate ? moment(values?.expDate).format(JavaFormat) : moment(latestYear.toString()).add(1, 'y').set({'date': 20, 'month': 1}).format(JavaFormat),
      licenceType: values?.model,
      notesNum: values?.notes == 'noLimit' ? 1000000 : values?.notes,
      questionsNum: values?.questions == 'noLimit' ? 1000000 : values?.questions,
      educationsNum: values?.educations == 'noLimit' ? 1000000 : values?.educations,
      printedEditionsNum: parseInt(subs) > 1 ? 3 : parseInt(subs) * 3,
      biltenSubscription: parseInt(subs) > 0 ? true : false,
      price: price
    };

    if (isUpgrade) {
      LicenceUpgradeService.upgradeUserLicence(id, orgUnitInfo?.data?.id!, licenceDetails, latestYear.toString(), values.years, parseInt(subs) > 1, [values.subs].flat(), false).then(() => {
        notification['success']({ message: i18n.translate('api.successMessage'), duration: 2 });
        setVisible(false);
        dispatch(clearFullUserAction());
        dispatch(getSingleFullUserAction(id));
      });
    }

    if (isNew) {
      LicenceUpgradeService.createUserLicence(orgUnitInfo?.data?.id!, licenceDetails, latestYear.toString(), values.years, parseInt(subs) > 1, [values.subs].flat(), false).then(() => {
        notification['success']({ message: i18n.translate('api.successMessage'), duration: 2 });
        setVisible(false);
        dispatch(clearFullUserAction());
        dispatch(getSingleFullUserAction(id));
      });
    }

  };

  const handleCancel = () => {
    setVisible(false);
  };

  const getBasicYears = (licence: LicenceInterface) => {
    let yearArray = [];
    if (licence?.attributes?.year && licence?.attributes?.licenceType === LicenceEnum.STANDARD) {
      yearArray.push(licence?.attributes?.year);
    }
    if (licence?.attributes?.childrenLicences?.length) {
      licence?.attributes?.childrenLicences?.forEach(child => {
        if (licence?.attributes?.licenceType === LicenceEnum.STANDARD) {
          yearArray.push(child?.year);
        }
      });
    }

    return yearArray;
  };

  const getPremiumYears = (licence: LicenceInterface) => {
    let yearArray = [];
    if (licence?.attributes?.year && licence?.attributes?.licenceType === LicenceEnum.PREMIUM) {
      yearArray.push(licence?.attributes?.year);
    }
    if (licence?.attributes?.childrenLicences?.length) {
      licence?.attributes?.childrenLicences?.forEach(child => {
        if (licence?.attributes?.licenceType === LicenceEnum.PREMIUM) {
          yearArray.push(child?.year);
        }
      });
    }

    return yearArray;
  };

  const getManagerUser = (licence: LicenceInterface) => {
    let manager = '';
    if (licence?.attributes?.licenceUsers?.length) {
      const foundManager = licence?.attributes?.licenceUsers?.find(x => x?.user?.roles === UserRoleEnum.MANAGER);
      if (foundManager) {
        manager = foundManager.user?.name ? foundManager.user?.name : '';
      }
    }

    return manager;
  };

  const getAddress = (licence: LicenceInterface) => {
    let address = '';
    if (licence?.attributes?.licenceUsers?.length) {
      const foundUser = licence?.attributes?.licenceUsers?.find(x => x?.user?.id == user.currentFullUser?.id);
      if (foundUser) {
        address = foundUser?.user?.organizationUnit?.organization?.streetAddress ? foundUser?.user?.organizationUnit?.organization?.streetAddress : '';
      }
    }

    return address;
  };

  const loadInitialValues = (licence: LicenceInterface) => {
    let values: any = {
      status: licence?.attributes?.active,
      actDate: licence?.attributes?.from ? moment(licence?.attributes?.from) : null,
      expDate: licence?.attributes?.to ? moment(licence?.attributes?.to) : null,
      //priceEUR: priceInfo?.attributes?.amount,
      //priceHRK: priceInfo?.attributes?.amount ? (priceInfo?.attributes?.amount * EUR_CONVERSION) : 0,
      //adaptedEUR: priceInfo?.attributes?.amount,
      //adaptedHRK: priceInfo?.attributes?.amount ? (priceInfo?.attributes?.amount * EUR_CONVERSION) : 0,
      //payDate: priceInfo?.attributes?.createdDate ? moment(priceInfo?.attributes?.createdDate) : null,
      basicYear: getBasicYears(licence),
      premiumYearView: getPremiumYears(licence),
      leadUser: getManagerUser(licence),
      printSub: licence?.attributes?.biltenSubscription,
      address: getAddress(licence),
      lecturePackage: licence?.attributes?.educationsNum,
      lecturesUsed: licence?.attributes?.usedEducations,
    };

    return values;
  };

  const onFinishProfile = (values: any) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });

    const userObj = {
      data: {
        id: user.currentFullUser?.id,
        type: 'user',
        attributes: {
          active: !values.status
        }
      }
    }

    const licenceObj = {
      data: {
        id: licenceInfo?.id,
        type: 'licence',
        attributes: {
          active: values?.status,
          to: moment(values.expDate).format(JavaFormat),
          biltenSubscription: values.printSub,
          educationsNum: values.lecturePackage
        }
      }
    }

    let lId: any = licenceObj!.data.id!;
    if (licenceObj.data.attributes.active) LicenceService.approveSpecificLicence(orgUnitInfo!.data.id!, lId).subscribe(() => {});
    else LicenceService.disapproveSpecificLicence(orgUnitInfo!.data.id!, lId).subscribe(() => {});

    LicenceService.updateAny(licenceObj).subscribe(
      (response: any) => {
        notification['success']({ message: i18n.translate('api.successMessage'), duration: 2 });
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
      }
    );
  };

  const getInputComponent = (inputType: 'select' | 'date' | 'number') => {
    switch (inputType) {
      case 'select':
        return <Select>
                <Option value={true}>Da</Option>
                <Option value={false}>Ne</Option>
               </Select>
      case 'date':
        return <DatePicker locale={userAuth.lang == 'hr' ? localeHR : localeEN} format={DisplayDateFormat} />;
      case 'number':
        return <InputNumber min={0} />;
      default:
        return <InputNumber min={0} />;
    }
  };

  const originData: Item[] = validLicences?.map((licence, index) => {

    const getViewYears = () => {
      switch (licence.attributes?.licenceType) {
        case LicenceEnum.FREE:
          return licence.attributes?.year;
        case LicenceEnum.STANDARD:
          return getBasicYears(licence)?.length ? getBasicYears(licence).sort()?.reverse()?.join(', ') : '—';
        case LicenceEnum.PREMIUM:
          return getPremiumYears(licence)?.length ? getPremiumYears(licence).sort()?.reverse()?.join(', ') : '—';
        default:
          return '—';
      }
    };

    return {
      key: licence?.id?.toString(),
      year: licence?.attributes?.year,
      model: licence?.attributes?.licenceType,
      yearView: getViewYears(),
      printSub: licence?.attributes?.biltenSubscription ? 'Da' : 'Ne',
      expirationDate: licence?.attributes?.to ? moment(licence?.attributes?.to).format(DisplayDateFormat) : '—',
      paymentDate: licence?.attributes?.paymentDate ? moment(licence?.attributes?.paymentDate).format(DisplayDateFormat) : '—',
      totalEducations: licence?.attributes?.educationsNum ? licence?.attributes?.educationsNum.toString() : '—',
      usedEducations: licence?.attributes?.usedEducations ? licence?.attributes?.usedEducations.toString() : '—',
      totalQuestions: licence?.attributes?.questionsNum ? licence?.attributes?.questionsNum.toString() : '—',
      usedQuestions: licence?.attributes?.usedQuestions ? licence?.attributes?.usedQuestions.toString() : '—',
      totalNotes: licence?.attributes?.notesNum ? licence?.attributes?.notesNum.toString() : '—',
      active: licence?.attributes?.active ? 'Da' : 'Ne',
    };
  }) as Item[];

  const [formLicence] = Form.useForm();
  const [data, setData] = useState(originData);
  const [editingKey, setEditingKey] = useState('');

  const isEditing = (record: Item) => record.key === editingKey;

  interface Item {
    key: string;
    year: string;
    model: string;
    yearView: string;
    printSub: string;
    expirationDate: string;
    paymentDate: string;
    totalEducations: string;
    usedEducations: string;
    totalQuestions: string;
    usedQuestions: string;
    totalNotes: string;
    active: string;
  }

  interface ItemHistory {
    key: string;
    year: string;
    model: string;
    yearView: string;
    printSub: string;
    expirationDate: string;
    paymentDate: string;
    totalEducations: string;
    usedEducations: string;
    totalQuestions: string;
    usedQuestions: string;
    totalNotes: string;
    active: string;
    isValid: string;
    validTo: string;
  }

  interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
    editing: boolean;
    dataIndex: string;
    title: any;
    inputType: 'select' | 'date' | 'number';
    record: Item;
    index: number;
    children: React.ReactNode;
  }
  
  const EditableCell: React.FC<EditableCellProps> = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    const inputNode = getInputComponent(inputType);
  
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{ margin: 0 }}
            rules={[
              {
                required: dataIndex == 'paymentDate' ? false : true,
                message: `Molimo unesite ${title}!`,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  const edit = (record: Partial<Item> & { key: React.Key }) => {
    formLicence.setFieldsValue({
      printSub: record.printSub == 'Da' ? true : false, 
      expirationDate: record.expirationDate != '—' ? moment(record.expirationDate, DisplayDateFormat) : moment(), 
      paymentDate: record.paymentDate != '—' ? moment(record.paymentDate, DisplayDateFormat) : null,
      active: record.active == 'Da' ? true : false,
      totalEducations: record.totalEducations != '—' ? parseInt(record.totalEducations as string) : 0,
      usedEducations: record.usedEducations != '—' ? parseInt(record.usedEducations as string) : 0,
      totalQuestions: record.totalQuestions != '—' ? parseInt(record.totalQuestions as string) : 0,
      usedQuestions: record.usedQuestions != '—' ? parseInt(record.usedQuestions as string) : 0,
      totalNotes: record.totalNotes != '—' ? parseInt(record.totalNotes as string) : 0,
    });
    setEditingKey(record.key);
  };

  const deleteEntry = (key: number) => {
    dispatch(deleteLicenceAction(key));
    dispatch(clearFullUserAction());
    dispatch(getSingleFullUserAction(id));
  };

  const showConfirm = (record: Partial<Item> & { key: React.Key }) => {
    confirm({
      title: i18n.t('common.recordRemoval'),
      icon: <ExclamationCircleOutlined />,
      content: i18n.t('common.cantUndo'),
      okText: i18n.t('genericButtons.yes'),
      cancelText: i18n.t('genericButtons.cancel'),
      onOk() {
        deleteEntry(parseInt(record?.key))
      }
    });
  };

  const cancel = () => {
    setEditingKey('');
  };

  const save = async (key: React.Key) => {
    try {
      const row = (await formLicence.validateFields()) as Item;

      const newData = [...data];
      const index = newData.findIndex(item => key === item.key);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        setData(newData);
        setEditingKey('');
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey('');
      }
      
      const licenceObj = {
        data: {
          id: key,
          type: 'licence',
          attributes: {
            active: row?.active,
            to: moment(row.expirationDate).format(JavaFormat),
            paymentDate: row.paymentDate && row.paymentDate != '—' ? moment(row.paymentDate).format(JavaFormat) : null,
            biltenSubscription: row.printSub,
            educationsNum: row.totalEducations,
            usedEducations: row.usedEducations,
            questionsNum: row.totalQuestions,
            usedQuestions: row.usedQuestions,
            notesNum: row.totalNotes
          }
        }
      }

      let lId: any = licenceObj!.data.id!;
      if (licenceObj.data.attributes.active) LicenceService.approveSpecificLicence(orgUnitInfo!.data.id!, lId).subscribe(() => {});
      else LicenceService.disapproveSpecificLicence(orgUnitInfo!.data.id!, lId).subscribe(() => {});
  
      LicenceService.updateAny(licenceObj).subscribe(
        (response: any) => {
          notification['success']({ message: i18n.translate('api.successMessage'), duration: 2 });
          dispatch(clearFullUserAction());
          dispatch(getSingleFullUserAction(id));
        },
        (error: Error) => {
          notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
        }
      );

    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  const columns = [
    {
      title: 'Godina',
      dataIndex: 'year',
      //width: '25%',
      editable: false,
    },
    {
      title: 'Model pretplate',
      dataIndex: 'model',
      //width: '15%',
      editable: false,
      render: (text: any, record: any, index: any) => {
        return i18n.translate(`common.licenceType.${text}`);
      },
    },
    {
      title: 'Pretplatne godine',
      dataIndex: 'yearView',
      //width: '40%',
      editable: false,
    },
    {
      title: 'Pretplata na tisak',
      dataIndex: 'printSub',
      //width: '40%',
      editable: true,
    },
    {
      title: 'Datum isteka',
      dataIndex: 'expirationDate',
      //width: '40%',
      editable: true,
    },
    {
      title: 'Datum plaćanja',
      dataIndex: 'paymentDate',
      //width: '40%',
      editable: true,
    },
    {
      title: 'Ukupno predavanja',
      dataIndex: 'totalEducations',
      //width: '40%',
      editable: true,
    },
    {
      title: 'Iskorišteno predavanja',
      dataIndex: 'usedEducations',
      //width: '40%',
      editable: true,
    },
    {
      title: 'Ukupno pitanja',
      dataIndex: 'totalQuestions',
      //width: '40%',
      editable: true,
    },
    {
      title: 'Iskorišteno pitanja',
      dataIndex: 'usedQuestions',
      //width: '40%',
      editable: true,
    },
    {
      title: 'Ukupno bilješki',
      dataIndex: 'totalNotes',
      //width: '40%',
      editable: true,
    },
    {
      title: 'Aktivan',
      dataIndex: 'active',
      //width: '40%',
      editable: true,
    },
    {
      title: 'Akcije',
      dataIndex: 'operation',
      render: (_: any, record: Item) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link onClick={() => save(record.key)} style={{ marginRight: 8 }}>
              Spremi
            </Typography.Link>
            <Popconfirm cancelText={'Odustani'} title="Sigurno želite odustati od uređivanja?" onConfirm={cancel}>
              <a>Odustani</a>
            </Popconfirm>
          </span>
        ) : (
          <div style={{display: 'flex', alignItems: 'center'}}>
            <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
              Uredi
            </Typography.Link>
            <Button disabled={editingKey !== ''} type="text" onClick={() => showConfirm(record)} icon={<DeleteTwoTone twoToneColor="#FF0000" />} />
          </div>
        );
      },
    },
  ];

  const mergedColumns = columns.map(col => {
    const getInputType = () => {
      switch (col.dataIndex) {
        case 'printSub':
          return 'select';
        case 'active':
          return 'select';
        case 'expirationDate':
          return 'date';
        case 'paymentDate':
          return 'date';
        default:
          return 'number';
      }
    };

    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: Item) => ({
        record,
        inputType: getInputType(),
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });








  // ------------------------------------------------- DRUGA TABLICA








  const originDataHistory: ItemHistory[] = oldLicencesInfo?.map((licence, index) => {

    const getViewYears = () => {
      switch (licence.attributes?.licenceType) {
        case LicenceEnum.FREE:
          return licence.attributes?.year;
        case LicenceEnum.STANDARD:
          return getBasicYears(licence)?.length ? getBasicYears(licence).sort()?.reverse()?.join(', ') : '—';
        case LicenceEnum.PREMIUM:
          return getPremiumYears(licence)?.length ? getPremiumYears(licence).sort()?.reverse()?.join(', ') : '—';
        default:
          return '—';
      }
    };

    return {
      key: licence?.id?.toString(),
      year: licence?.attributes?.year,
      model: licence?.attributes?.licenceType,
      yearView: getViewYears(),
      printSub: licence?.attributes?.biltenSubscription ? 'Da' : 'Ne',
      expirationDate: licence?.attributes?.to ? moment(licence?.attributes?.to).format(DisplayDateFormat) : '—',
      paymentDate: licence?.attributes?.paymentDate ? moment(licence?.attributes?.paymentDate).format(DisplayDateFormat) : '—',
      totalEducations: licence?.attributes?.educationsNum ? licence?.attributes?.educationsNum.toString() : '—',
      usedEducations: licence?.attributes?.usedEducations ? licence?.attributes?.usedEducations.toString() : '—',
      totalQuestions: licence?.attributes?.questionsNum ? licence?.attributes?.questionsNum.toString() : '—',
      usedQuestions: licence?.attributes?.usedQuestions ? licence?.attributes?.usedQuestions.toString() : '—',
      totalNotes: licence?.attributes?.notesNum ? licence?.attributes?.notesNum.toString() : '—',
      active: licence?.attributes?.active ? 'Da' : 'Ne',
      isValid: licence.attributes?.licenceUsers?.find(x => x.user?.id == id && x.valid) ? 'Da' : 'Ne',
      validTo: licence.attributes?.to
    };
  }) as ItemHistory[];

  const [formLicenceHistory] = Form.useForm();
  const [dataHistory, setDataHistory] = useState(originDataHistory);
  const [editingKeyHistory, setEditingKeyHistory] = useState('');

  const isEditingHistory = (record: ItemHistory) => record.key === editingKeyHistory;

  const editHistory = (record: Partial<ItemHistory> & { key: React.Key }) => {
    formLicenceHistory.setFieldsValue({
      printSub: record.printSub == 'Da' ? true : false, 
      expirationDate: record.expirationDate != '—' ? moment(record.expirationDate, DisplayDateFormat) : moment(), 
      paymentDate: record.paymentDate != '—' ? moment(record.paymentDate, DisplayDateFormat) : null,
      active: record.active == 'Da' ? true : false,
      totalEducations: record.totalEducations != '—' ? parseInt(record.totalEducations as string) : 0,
      usedEducations: record.usedEducations != '—' ? parseInt(record.usedEducations as string) : 0,
      totalQuestions: record.totalQuestions != '—' ? parseInt(record.totalQuestions as string) : 0,
      usedQuestions: record.usedQuestions != '—' ? parseInt(record.usedQuestions as string) : 0,
      totalNotes: record.totalNotes != '—' ? parseInt(record.totalNotes as string) : 0,
    });
    setEditingKeyHistory(record.key);
  };

  const cancelHistory = () => {
    setEditingKeyHistory('');
  };

  const saveHistory = async (key: React.Key) => {
    try {
      const row = (await formLicenceHistory.validateFields()) as ItemHistory;

      const newData = [...dataHistory];
      const index = newData.findIndex(item => key === item.key);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        setDataHistory(newData);
        setEditingKeyHistory('');
      } else {
        newData.push(row);
        setDataHistory(newData);
        setEditingKeyHistory('');
      }
      
      const licenceObj = {
        data: {
          id: key,
          type: 'licence',
          attributes: {
            active: row?.active,
            to: moment(row.expirationDate).format(JavaFormat),
            paymentDate: row.paymentDate && row.paymentDate != '—' ? moment(row.paymentDate).format(JavaFormat) : null,
            biltenSubscription: row.printSub,
            educationsNum: row.totalEducations,
            usedEducations: row.usedEducations,
            questionsNum: row.totalQuestions,
            usedQuestions: row.usedQuestions,
            notesNum: row.totalNotes
          }
        }
      }

      let lId: any = licenceObj!.data.id!;
      if (licenceObj.data.attributes.active) LicenceService.approveSpecificLicence(orgUnitInfo!.data.id!, lId).subscribe(() => {});
      else LicenceService.disapproveSpecificLicence(orgUnitInfo!.data.id!, lId).subscribe(() => {});
  
      LicenceService.updateAny(licenceObj).subscribe(
        (response: any) => {
          notification['success']({ message: i18n.translate('api.successMessage'), duration: 2 });
          dispatch(clearFullUserAction());
          dispatch(getSingleFullUserAction(id));
        },
        (error: Error) => {
          notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
        }
      );

    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  const columnsHistory = [
    {
      title: 'Godina',
      dataIndex: 'year',
      //width: '25%',
      editable: false,
    },
    {
      title: 'Model pretplate',
      dataIndex: 'model',
      //width: '15%',
      editable: false,
      render: (text: any, record: any, index: any) => {
        return i18n.translate(`common.licenceType.${text}`);
      },
    },
    {
      title: 'Pretplatne godine',
      dataIndex: 'yearView',
      //width: '40%',
      editable: false,
    },
    {
      title: 'Pretplata na tisak',
      dataIndex: 'printSub',
      //width: '40%',
      editable: true,
    },
    {
      title: 'Datum isteka',
      dataIndex: 'expirationDate',
      //width: '40%',
      editable: true,
    },
    {
      title: 'Datum plaćanja',
      dataIndex: 'paymentDate',
      //width: '40%',
      editable: true,
    },
    {
      title: 'Ukupno predavanja',
      dataIndex: 'totalEducations',
      //width: '40%',
      editable: true,
    },
    {
      title: 'Iskorišteno predavanja',
      dataIndex: 'usedEducations',
      //width: '40%',
      editable: true,
    },
    {
      title: 'Ukupno pitanja',
      dataIndex: 'totalQuestions',
      //width: '40%',
      editable: true,
    },
    {
      title: 'Iskorišteno pitanja',
      dataIndex: 'usedQuestions',
      //width: '40%',
      editable: true,
    },
    {
      title: 'Ukupno bilješki',
      dataIndex: 'totalNotes',
      //width: '40%',
      editable: true,
    },
    {
      title: 'Aktivan',
      dataIndex: 'active',
      //width: '40%',
      editable: true,
    },
    {
      title: 'Akcije',
      dataIndex: 'operation',
      render: (_: any, record: ItemHistory) => {
        //OSTAVLJAM ISPOD ZAKOMENTIRANO ZA SVAKI SLUČAJ
        //if (record.isValid == 'Ne' || moment().isAfter(moment(record.validTo))) return;
        //if (record.isValid == 'Ne') return;
        const editable = isEditingHistory(record);
        return editable ? (
          <span>
            <Typography.Link onClick={() => saveHistory(record.key)} style={{ marginRight: 8 }}>
              Spremi
            </Typography.Link>
            <Popconfirm cancelText={'Odustani'} title="Sigurno želite odustati od uređivanja?" onConfirm={cancelHistory}>
              <a>Odustani</a>
            </Popconfirm>
          </span>
        ) : (
          <div style={{display: 'flex', alignItems: 'center'}}>
            <Typography.Link disabled={editingKeyHistory !== ''} onClick={() => editHistory(record)}>
              Uredi
            </Typography.Link>
            <Button disabled={editingKeyHistory !== ''} type="text" onClick={() => showConfirm(record)} icon={<DeleteTwoTone twoToneColor="#FF0000" />} />
          </div>
        );
      },
    },
  ];

  const mergedColumnsHistory = columnsHistory.map(col => {
    const getInputType = () => {
      switch (col.dataIndex) {
        case 'printSub':
          return 'select';
        case 'active':
          return 'select';
        case 'expirationDate':
          return 'date';
        case 'paymentDate':
          return 'date';
        default:
          return 'number';
      }
    };

    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: ItemHistory) => ({
        record,
        inputType: getInputType(),
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditingHistory(record),
      }),
    };
  });

  const setMostPowerfulModel = (model: 'FREE' | 'STANDARD' | 'PREMIUM') => {
    let returnBoolean = false;

    if (model === 'FREE') {
      if (!validLicences?.some(x => x.attributes?.licenceType === LicenceEnum.STANDARD) && !validLicences?.some(x => x.attributes?.licenceType === LicenceEnum.PREMIUM)) {
        returnBoolean = true;
      }
    }
    if (model === 'STANDARD') {
      if (validLicences?.some(x => x.attributes?.licenceType === LicenceEnum.STANDARD) && !validLicences?.some(x => x.attributes?.licenceType === LicenceEnum.PREMIUM)) {
        returnBoolean = true;
      }
    }
    if (model === 'PREMIUM') {
      if (validLicences?.some(x => x.attributes?.licenceType === LicenceEnum.PREMIUM)) {
        returnBoolean = true;
      }
    }

    return returnBoolean;
  };

  const sortByYears = (data: Array<Item> | Array<ItemHistory>): any => {
    return data.sort((a, b) => parseInt(b.year) - parseInt(a.year));
  };

  return (
    <div className="subModels">
      {user.currentFullUser && orgUnitInfo?.data && licenceInfo ? (
        <>
        <Title style={{marginTop: 16, marginBottom: 16}} className="subModels__title" level={5}>
          {i18n.translate('profile.subModels.current')}
        </Title>
        <div className="subModels___content">
          <Row style={{ display: 'flex', flexWrap: 'wrap' }} gutter={24}>
            <Col>
              <SubscriptionCardComponent
                title={i18n.translate('profile.subModels.card.starter')}
                btnText={i18n.translate('register.licence.subs.starter')}
                onClick={() => showModal('FREE')}
                active={setMostPowerfulModel('FREE')}
                isFree={true}
              />
            </Col>
            <Col>
              <SubscriptionCardComponent
                title={i18n.translate('profile.subModels.card.basic')}
                btnText={i18n.translate('register.licence.subs.standard')}
                onClick={() => showModal('STANDARD')}
                active={setMostPowerfulModel('STANDARD')}
              />
            </Col>
            <Col>
              <SubscriptionCardComponent
                title="Premium model"
                btnText={i18n.translate('register.licence.subs.premium')}
                onClick={() => showModal('PREMIUM')}
                active={setMostPowerfulModel('PREMIUM')}
              />
            </Col>
          </Row>
        </div>

        <div className="subModels___content">
          <AdminUpgradeRequestComponent userId={id} callback={() => { dispatch(clearFullUserAction()); dispatch(getSingleFullUserAction(id)); }} />

          <Title style={{marginTop: 16, marginBottom: 16}} className="subModels__title" level={5}>
            {i18n.translate('profile.subModels.valid')}
          </Title>
          
          {/*<Form 
            layout="vertical"
            form={form}
            onFinish={onFinishProfile}
          >
            <Button
              className="button"
              htmlType="submit"
              type="primary"
              size="large"
              style={{ fontSize: 16, fontWeight: 700, borderRadius: 0, minWidth: 193 }}
            >
              {i18n.translate('genericButtons.save')}
            </Button>
            <Form.Item name="status" label={i18n.translate(`profile.subModels.status`)}>
              <Radio.Group>
                <Radio value={true}>{i18n.translate(`profile.subModels.activeF`)}</Radio>
                <Radio value={false}>{i18n.translate(`profile.subModels.inactiveF`)}</Radio>
              </Radio.Group>
            </Form.Item>

            <Form.Item name="subOwner" label="Subscription owner">
              <Input defaultValue="Grupic" style={{ borderRadius: 0, width: 550 }} />
            </Form.Item>

            <Form.Item name="actDate" label={i18n.translate(`profile.subModels.activationDate`)} {...config}>
              <DatePicker locale={userAuth.lang == 'hr' ? localeHR : localeEN} format={DisplayDateFormat} disabled style={{ borderRadius: 0, width: 200 }} />
            </Form.Item>
            <Form.Item name="expDate" label={i18n.translate(`profile.subModels.expirationDate`)} {...config}>
              <DatePicker locale={userAuth.lang == 'hr' ? localeHR : localeEN} format={DisplayDateFormat} style={{ borderRadius: 0, width: 200 }} />
            </Form.Item>

            <Row gutter={55}>
              <Col>
                <Form.Item name="priceHRK" label={i18n.translate(`profile.subModels.priceHRK`)}>
                  <InputNumber precision={2} disabled style={{ borderRadius: 0, width: 200 }} />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item name="priceEUR" label={i18n.translate(`profile.subModels.priceEUR`)}>
                  <InputNumber precision={2} disabled style={{ borderRadius: 0, width: 200 }} />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item name="conversion" label={i18n.translate(`dashboard.review.conversion`)}>
                  <Input
                    disabled
                    defaultValue={EUR_CONVERSION}
                    style={{ borderRadius: 0, width: 200 }}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={55}>
              <Col>
                <Form.Item name="adaptedHRK" label={i18n.translate(`profile.subModels.adaptedHRK`)}>
                  <InputNumber precision={2} style={{ borderRadius: 0, width: 200 }} />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item name="adaptedEUR" label={i18n.translate(`profile.subModels.adaptedEUR`)}>
                  <InputNumber precision={2} style={{ borderRadius: 0, width: 200 }} />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item name="conversion" label={i18n.translate(`dashboard.review.conversion`)}>
                  <Input disabled defaultValue={EUR_CONVERSION} placeholder={EUR_CONVERSION.toString()} style={{ borderRadius: 0, width: 200 }} />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item name="payDate" label={i18n.translate(`profile.subModels.payDate`)} {...config}>
              <DatePicker locale={userAuth.lang == 'hr' ? localeHR : localeEN} format={DisplayDateFormat} disabled style={{ borderRadius: 0, width: 200 }} />
            </Form.Item>
            
            <Form.Item name="organizationUnit" label={i18n.translate(`profile.subModels.orgUnit`)}>
              <Link
                style={{textDecoration: 'underline', color: 'blue'}}
                to={AdminRoutes.ORGANIZATION_UNIT_PROFILE.fullPath + `/${orgUnitInfo?.data?.id}`}
                key={AdminRoutes.ORGANIZATION_UNIT_PROFILE.fullPath + `/${orgUnitInfo?.data?.id}`}
              >
                {orgUnitInfo?.data?.attributes?.name}
              </Link>
            </Form.Item>

            <Form.Item label={i18n.translate(`profile.subModels.mainUser`)} name="leadUser">
              <Input disabled style={{ borderRadius: 0, width: 550 }} />
            </Form.Item>

            <Title className="subModels__title" level={5}>
              {i18n.translate('profile.subModels.available')}
            </Title>

            <Form.Item name="basicYearView" label={i18n.translate(`profile.subModels.basicYearView`)}>
              <Select
                mode="multiple"
                disabled
                style={{ borderRadius: 0, width: 333 }}
              >
              </Select>
            </Form.Item>
            <Form.Item name="premiumYearView" label={i18n.translate(`profile.subModels.premiumYearView`)}>
              <Select
                mode="multiple"
                disabled
                style={{ borderRadius: 0, width: 333 }}
              >
              </Select>
            </Form.Item>

            <Title className="subModels__title" level={5}>
              {i18n.translate('profile.subModels.team')}
            </Title>

            <Form.Item label={i18n.translate(`profile.subModels.userNo`)} name="userNo" rules={[{ type: 'number' }]}>
              <InputNumber placeholder="1" max={10} style={{ borderRadius: 0, width: 100 }} />
            </Form.Item>

            <Title className="subModels__title" level={5}>
              {i18n.translate('profile.subModels.print')}
            </Title>

            <Form.Item name="printSub" label={i18n.translate(`profile.subModels.print`)}>
              <Radio.Group>
                <Radio.Button value={true}>{i18n.translate('genericButtons.hasSubscription')}</Radio.Button>
                <Radio.Button value={false}>{i18n.translate('genericButtons.noSubscription')}</Radio.Button>
              </Radio.Group>
            </Form.Item>

            <Form.Item label={i18n.translate(`profile.subModels.subNo`)} name="subNo" rules={[{ type: 'number' }]}>
              <InputNumber placeholder="1" max={10} style={{ borderRadius: 0, width: 100 }} />
            </Form.Item>
            
            <Form.Item name="address" label={i18n.translate(`profile.subModels.deliveryAddress`)}>
              <Input
                disabled
                style={{ borderRadius: 0, width: 200 }}
              />
            </Form.Item>

            <Title className="subModels__title" level={5}>
              {i18n.translate('profile.subModels.lectures')}
            </Title>
            <Form.Item
              label={i18n.translate(`profile.subModels.lectureNo`)}
              name="lecturePackage"
              rules={[{ type: 'number' }]}
            >
              <InputNumber style={{ borderRadius: 0, width: 100 }} />
            </Form.Item>
            <Form.Item
              label={i18n.translate(`profile.subModels.lectureUsed`)}
              name="lecturesUsed"
              rules={[{ type: 'number' }]}
            >
              <InputNumber disabled style={{ borderRadius: 0, width: 100 }} />
            </Form.Item>
          </Form>*/}

          <Form form={formLicence} component={false}>
            <Table
              components={{
                body: {
                  cell: EditableCell,
                },
              }}
              bordered
              dataSource={sortByYears(originData)}
              columns={mergedColumns}
              rowClassName="editable-row"
              pagination={false}
              locale={{ 
                emptyText: i18n.t('common.noData'),
                triggerDesc: i18n.t('common.sortDesc'),
                triggerAsc: i18n.t('common.sortAsc'),
                cancelSort: i18n.t('common.cancelSort')
              }}
              scroll={{ x: 1500 }}
            />
          </Form>

          <Row justify="space-between">
            <Col>
              <Title style={{marginTop: 16, marginBottom: 16}} className="subModels__title" level={5}>
                {i18n.translate('profile.subModels.subHistory')}
              </Title>
            </Col>
            <Col>
              {/**<Button>Uredi pretplatu</Button>*/}
            </Col>
          </Row>

          {/**<AdminSubscriptionTableComponent user={user} orgUnitInfo={orgUnitInfo} licenceInfo={licenceInfo} oldLicencesInfo={oldLicencesInfo} />*/}

          <Form form={formLicenceHistory} component={false}>
            <Table
              components={{
                body: {
                  cell: EditableCell,
                },
              }}
              bordered
              dataSource={sortByYears(originDataHistory)}
              columns={mergedColumnsHistory}
              rowClassName="editable-row"
              pagination={false}
              locale={{ 
                emptyText: i18n.t('common.noData'),
                triggerDesc: i18n.t('common.sortDesc'),
                triggerAsc: i18n.t('common.sortAsc'),
                cancelSort: i18n.t('common.cancelSort')
              }}
              scroll={{ x: 1500 }}
            />
          </Form>
        </div>
        </>
        ) : null}
        <UpgradeModal isUpgrade licenceInfo={licenceInfo} visible={visible} handleOk={handleUpgradeOk} handleCancel={handleCancel} selectedModel={selectedModel} />
    </div>
  );
}
