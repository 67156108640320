import { Button, Divider, Row, Typography } from 'antd';
import React from 'react';
import { NoteInterface } from '../../../interfaces';
import { i18n } from '../../../services';
import { Link } from 'react-router-dom';
import { AdminRoutes } from '../../../../features/admin';
import { useSelector } from 'react-redux';
import { AppRoutes } from '../../../../features/app';
import { UserRoleEnum } from '../../../enums';
import { StoreStateInterface } from '../../../redux';

const { Title, Text } = Typography;

interface Props {
  notes?: Array<NoteInterface>;
}

export default function DashboardNotesComponent({ notes }: Props) {
  const userAuth = useSelector((state: StoreStateInterface) => state.auth);

  return (
    <div className="dashNotes">
      <div className="section">
        <Title className="title" level={3}>
          {i18n.translate('notes.title')}
        </Title>
        <Divider />

        {notes &&
          notes?.length &&
          [...notes]
            .sort((a, b) => {
              return (
                new Date(a?.attributes?.modifiedDate as string).getTime() -
                new Date(b.attributes?.modifiedDate as string).getTime()
              );
            })
            .reverse()
            .slice(0, 3)
            .map((note, i) => (
              <div key={note.id}>
                <Link
                  className='hover-efect'
                  to={
                    userAuth.user?.roles === UserRoleEnum.ADMIN
                      ? AdminRoutes.NOTES.fullPath + `/${note.id}`
                      : AppRoutes.NOTES.fullPath + `/${note.id}`
                  }
                >
                  <Text>{`${note.attributes?.title}`}</Text>
                </Link>
                <Divider />
              </div>
            ))}
        <Row justify="start">
          <Link
            to={userAuth.user?.roles === UserRoleEnum.ADMIN ? AdminRoutes.NOTES.fullPath : AppRoutes.NOTES.fullPath}
          >
            <Button type="primary" className="cardBtn">
              {i18n.translate(`dashboard.seeAll`)}
            </Button>
          </Link>
        </Row>
      </div>
    </div>
  );
}
