import React, { useEffect } from 'react';
import { Popconfirm, Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { DeleteOutlined } from '@ant-design/icons';
import { Button, Space } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';

import { deletePrintEditionAction, getAllPrintEditionsAction } from '../../../redux/printEditions';
import { i18n } from '../../../services';
import PrintEditionModal from '../../modals/printEditions/printEditionModal.component';
import { StoreStateInterface } from '../../../redux';
import { BiltenInterface, PrintEditionInterface } from '../../../interfaces';

interface DataType {
  key: number;
  year: string;
  biltenNo: string;
  quantity: string;
  subscription: string;
  payDate: string;
  sentDate: string;
  addressNote: string;
  data: PrintEditionInterface;
}

interface Props {
  userId: number;
  isAdminView: boolean;
  biltens: BiltenInterface[];
}

export default function PrintEditionsTableComponent({ userId, isAdminView, biltens }: Props) {
  const dispatch = useDispatch();
  const printEditions = useSelector((state: StoreStateInterface) => state.printEditions.printEditions);

  useEffect(() => {
    if (!userId) return;
    dispatch(getAllPrintEditionsAction(userId));
  }, [userId]);

  function removeRecord(item: { key: any }) {
    dispatch(deletePrintEditionAction(item.key, userId));
  }

  const getBJN = (print: PrintEditionInterface) => {
    if (print) {
      if (printEditions?.included.find((x) => x.id == print.relationships?.bilten?.data?.id)) {
        return `BJN ${
          printEditions?.included.find((x) => x.id == print.relationships?.bilten?.data?.id)?.attributes?.issueNum
        }`;
      }
    }
    return '';
  };

  const data: DataType[] = printEditions?.data?.map((print) => {
    return {
      key: print?.id,
      year: print?.attributes?.createdDate ? moment(print?.attributes?.createdDate).format('YYYY.') : '—',
      biltenNo: getBJN(print),
      quantity: print?.attributes?.quantity,
      subscription: print?.attributes?.subscription ? i18n.t('genericButtons.yes') : i18n.t('genericButtons.no'),
      addressNote: print?.attributes?.footnote,
      payedAt: print?.attributes?.payedAt ? moment(print?.attributes?.payedAt).format('DD.MM.YYYY.') : '—',
      shippedAt: print?.attributes?.shippedAt ? moment(print?.attributes?.shippedAt).format('DD.MM.YYYY.') : '—',
      data: print,
    };
  }) as unknown as DataType[];

  const columns: ColumnsType<DataType> = [
    {
      title: i18n.translate(`profile.printEditions.year`),
      dataIndex: 'year',
      key: 'year',
      fixed: 'left',
      width: '95px',
      rowSpan: 2,
    },
    {
      title: i18n.translate(`profile.printEditions.biltenNo`),
      dataIndex: 'biltenNo',
      key: 'biltenNo',
      //width: '145px',
    },
    {
      title: i18n.translate(`profile.printEditions.quantity`),
      dataIndex: 'quantity',
      key: 'quantity',
      //width: '110px',
    },
    {
      title: i18n.translate(`profile.printEditions.subscription`),
      dataIndex: 'subscription',
      key: 'subscription',
      //width: '135px',
    },
    {
      title: i18n.translate(`profile.printEditions.footnote`),
      dataIndex: 'addressNote',
      key: 'addressNote',
      //width: '290px',
    },
  ];

  const adminColumns: ColumnsType<DataType> = [
    {
      title: i18n.translate(`profile.printEditions.payedAt`),
      dataIndex: 'payedAt',
      key: 'payedAt',
      //width: '205px',
    },
    {
      title: i18n.translate(`profile.printEditions.shippedAt`),
      dataIndex: 'shippedAt',
      key: 'shippedAt',
      //width: '205px',
    },
    {
      title: i18n.translate(`profile.printEditions.actions`),
      dataIndex: 'actions',
      key: 'actions',
      //width: '130px',
      render: (text, record, index) => (
        <Space>
          <PrintEditionModal style="link" userId={userId!} printEdition={record.data as any} biltens={biltens} />

          <Popconfirm
            title={i18n.t('common.recordRemoval')}
            onConfirm={() => removeRecord(record as any)}
            onCancel={() => {}}
            okText={i18n.t('genericButtons.yes')}
            cancelText={i18n.t('genericButtons.no')}
            placement="left"
          >
            <Button danger type="link" icon={<DeleteOutlined />}></Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  function getColumns(): ColumnsType<DataType> {
    if (!isAdminView) return columns;
    return [...columns, ...adminColumns];
  }

  return (
    <>
      <Table
        style={{ paddingBottom: 24 }}
        columns={getColumns()}
        dataSource={data}
        locale={{
          emptyText: i18n.t('common.noData'),
          triggerDesc: i18n.t('common.sortDesc'),
          triggerAsc: i18n.t('common.sortAsc'),
          cancelSort: i18n.t('common.cancelSort'),
        }}
        pagination={false}
        scroll={{ x: 1500 }}
      />
    </>
  );
}
