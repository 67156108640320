import { Dispatch } from 'redux';
import { notification } from 'antd';
import { SummaryConstants } from './summary.constants';

import { SummaryService } from '../../api/api.service';
import { SummaryResponseInterfaceAll, SummaryResponseInterfaceSingle } from '../../interfaces';
import { i18n, NavigationService } from '../../services';
import { AdminRoutes } from '../../../features/admin';

export function getAllSummarysAction(params?: any, filter?: any, sort?: any, pagination?: any) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: SummaryConstants.SET_LOADER,
      payload: true
    });

    dispatch({
      type: SummaryConstants.GET_ALL_SUCCESS,
      payload: {
        data: [],
        meta: {}
      },
    });
    return SummaryService.getAll(params, filter, sort, pagination).subscribe(
      (response: SummaryResponseInterfaceAll) => {
        dispatch({
          type: SummaryConstants.GET_ALL_SUCCESS,
          payload: response,
        });
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });

        dispatch({
          type: SummaryConstants.ERROR,
          payload: error,
        });
      }
    );
  };
}

export function getSingleSummaryAction(id: number) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: SummaryConstants.SET_LOADER,
      payload: true
    });

    return SummaryService.getSingle(id).subscribe(
      (response: SummaryResponseInterfaceSingle) => {
        dispatch({
          type: SummaryConstants.GET_SINGLE_SUCCESS,
          payload: response,
        });
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });

        dispatch({
          type: SummaryConstants.ERROR,
          payload: error,
        });
      }
    );
  };
}

export function createSummaryAction(body: SummaryResponseInterfaceSingle, showNotif: boolean = true) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: SummaryConstants.SET_LOADER,
      payload: true
    });

    return SummaryService.create(body).subscribe(
      (response: SummaryResponseInterfaceSingle) => {
        if (showNotif) notification['success']({ message: i18n.translate('api.successMessage'), duration: 2 });

        dispatch({
          type: SummaryConstants.CREATE_SUCCESS,
          payload: response,
        });
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });

        dispatch({
          type: SummaryConstants.ERROR,
          payload: error,
        });
      }
    );
  };
}

export function updateSummaryAction(body: SummaryResponseInterfaceSingle, showNotif: boolean = true) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: SummaryConstants.SET_LOADER,
      payload: true
    });

    return SummaryService.update(body).subscribe(
      (response: SummaryResponseInterfaceSingle) => {
        if (showNotif) notification['success']({ message: i18n.translate('api.successMessage'), duration: 2 });

        dispatch({
          type: SummaryConstants.UPDATE_SUCCESS,
          payload: response,
        });
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });

        dispatch({
          type: SummaryConstants.ERROR,
          payload: error,
        });
      }
    );
  };
}

export function deleteSummaryAction(id: number) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: SummaryConstants.SET_LOADER,
      payload: true
    });

    return SummaryService.delete(id).subscribe(
      (response: any) => {
        dispatch({
          type: SummaryConstants.DELETE
        });

        NavigationService.navigate(AdminRoutes.DKOM_DECISIONS.fullPath);
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });

        dispatch({
          type: SummaryConstants.ERROR,
          payload: error,
        });
      }
    );
  };
}

export function clearSummaryAction() {
  return (dispatch: Dispatch) => {
    dispatch({ type: SummaryConstants.CLEAR_CURRENT });
  };
}

export function clearSummaryListAction() {
  return (dispatch: Dispatch) => {
    dispatch({ type: SummaryConstants.CLEAR_LIST });
  };
}
