export enum FullUserConstants {
  GET_ALL_SUCCESS = 'FullUserConstants_GET_ALL_SUCCESS',
  GET_SINGLE_SUCCESS = 'FullUserConstants_GET_SINGLE_SUCCESS',
  CREATE_SUCCESS = 'FullUserConstants_CREATE_SUCCESS',
  UPDATE_SUCCESS = 'FullUserConstants_UPDATE_SUCCESS',
  CLEAR_CURRENT = 'FullUserConstants_CLEAR_CURRENT',
  CLEAR_LIST = 'FullUserConstants_CLEAR_LIST',
  DELETE = 'FullUserConstants_DELETE',
  ERROR = 'FullUserConstants_ERROR'
}
