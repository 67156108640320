import { Dispatch } from 'redux';
import { notification } from 'antd';
import { NotesConstants } from './notes.constants';

import { NoteService } from '../../api/api.service';
import { NoteResponseInterfaceAll, NoteResponseInterfaceSingle } from '../../interfaces';
import { i18n } from '../../services';

export function getAllNotesAction(userId?: number) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: NotesConstants.SET_IS_LOADING,
      payload: true,
    });

    return NoteService.getAll(userId).subscribe(
      (response: NoteResponseInterfaceAll) => {
        dispatch({
          type: NotesConstants.GET_ALL_SUCCESS,
          payload: response,
        });
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });

        dispatch({
          type: NotesConstants.ERROR,
          payload: error,
        });
      }
    );
  };
}

export function getSingleNoteAction(id: number) {
  return (dispatch: Dispatch) => {
    return NoteService.getSingle(id).subscribe(
      (response: NoteResponseInterfaceSingle) => {
        dispatch({
          type: NotesConstants.GET_SINGLE_SUCCESS,
          payload: response,
        });
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });

        dispatch({
          type: NotesConstants.ERROR,
          payload: error,
        });
      }
    );
  };
}

export function createNoteAction(body: NoteResponseInterfaceSingle, userId?: number) {
  return (dispatch: Dispatch) => {
    return NoteService.create(body).subscribe(
      (response: NoteResponseInterfaceSingle) => {
        notification['success']({ message: i18n.translate('api.successMessage'), duration: 2 });

        if (userId) getAllNotesAction(userId);

        dispatch({
          type: NotesConstants.CREATE_SUCCESS,
          payload: response,
        });
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });

        dispatch({
          type: NotesConstants.ERROR,
          payload: error,
        });
      }
    );
  };
}

export function updateNoteAction(body: NoteResponseInterfaceSingle, id?: number) {
  return (dispatch: Dispatch) => {
    return NoteService.update(body, id).subscribe(
      (response: NoteResponseInterfaceSingle) => {
        notification['success']({ message: i18n.translate('api.successMessage'), duration: 2 });

        dispatch({
          type: NotesConstants.UPDATE_SUCCESS,
          payload: response,
        });
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });

        dispatch({
          type: NotesConstants.ERROR,
          payload: error,
        });
      }
    );
  };
}

export function deleteNoteAction(id: number) {
  return (dispatch: Dispatch) => {
    return NoteService.delete(id).subscribe(
      (response: any) => {
        dispatch({
          type: NotesConstants.DELETE,
        });
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });

        dispatch({
          type: NotesConstants.ERROR,
          payload: error,
        });
      }
    );
  };
}

export function clearNoteAction() {
  return (dispatch: Dispatch) => {
    dispatch({ type: NotesConstants.CLEAR_CURRENT });
  };
}
