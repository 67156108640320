export enum OrderConstants {
  SET_LOADER = 'OrderConstants_SET_LOADER',
  GET_ALL_SUCCESS = 'OrderConstants_GET_ALL_SUCCESS',
  GET_SINGLE_SUCCESS = 'OrderConstants_GET_SINGLE_SUCCESS',
  CREATE_SUCCESS = 'OrderConstants_CREATE_SUCCESS',
  UPDATE_SUCCESS = 'OrderConstants_UPDATE_SUCCESS',
  CLEAR_CURRENT = 'OrderConstants_CLEAR_CURRENT',
  CLEAR_LIST = 'OrderConstants_CLEAR_LIST',
  DELETE = 'OrderConstants_DELETE',
  ERROR = 'OrderConstants_ERROR'
}
