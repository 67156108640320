import { Button, Collapse, Form, FormInstance, InputNumber, Modal, notification, Radio, Space } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import {
  createAllegationAction,
  AllegationStateInterface,
  deleteAllegationAction,
  i18n,
  StoreStateInterface,
  updateAllegationAction,
  DecisionStateInterface,
  clearAllegationListAction,
  getSingleDecisionAction,
  getAllAllegationsAction,
  TagScreenStateInterface,
  getScreenTagsByTypeAction,
} from '../../../../../../../common';
import { ExclamationCircleOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Link, useLocation } from 'react-router-dom';
import { AdminRoutes } from '../../../../../_router/admin.routes';
import { useDispatch, useSelector } from 'react-redux';
import TextArea from 'antd/lib/input/TextArea';

const { confirm } = Modal;

const { Panel } = Collapse;

interface Props {
  setTabAppeal?: () => void;
}

function DKOMAllegationsCrudPageComponent({ setTabAppeal }: Props) {

  const dispatch = useDispatch();
  const [form] = Form.useForm<any>();
  const decision: DecisionStateInterface = useSelector((state: StoreStateInterface) => state.decision);
  const data: AllegationStateInterface = useSelector((state: StoreStateInterface) => state.allegation);
  const tags: TagScreenStateInterface = useSelector((state: StoreStateInterface) => state.tagScreen);

  useEffect(() => {
    dispatch(getScreenTagsByTypeAction('decision_appeal_allegation'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [decision.currentDecision, data.currentAllegation, data.revision]);

  useEffect(() => {
    form.resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, tags]);

  const loadData = () => {
    dispatch(getAllAllegationsAction(undefined, `filter[decision.id]=${decision.currentDecision!.id}`, 'sort=id'));
  }

  const onFinishAllegation = (values: any) => {
    values.allegations.forEach((value: any, index: number) => {
      let allegationTags: Array<{ id: number; type: string }> = [];
      allegationTags = decision.currentDecision?.attributes?.tagIds?.map((id) => ({
        id: id,
        type: 'tag',
      })) as Array<{ id: number; type: string }>;
      if (value?.dkomRatingFilter) allegationTags?.push({ id: value.dkomRatingFilter, type: 'tag' });
      let allegationData: any = {
        data: {
          type: 'decision_appeal_allegation',
          id: data.allegations?.data[index]?.id,
          attributes: {
            explanationPage: value?.explanationPage ? value.explanationPage : 1,
            titleContent: value?.titleContent,
            textContent: value?.textContent,
            dkomText: value?.dkomText,
            clientResponse: value?.clientResponse,
            lawArticle: value?.lawArticle,
            dkomAssessment: value?.dkomAssessment,
            comment: value?.comment,
          },
          relationships: {
            decision: {
              data: {
                id: decision.currentDecision?.id,
                type: 'decision',
              },
            },
            tags: {
              data: allegationTags,
            },
          },
        },
      };

      !allegationData?.data?.id
        ? dispatch(createAllegationAction(allegationData, values?.allegations.length == index + 1))
        : dispatch(updateAllegationAction(allegationData, values?.allegations?.length == index + 1));
    });

    window.scrollTo({ top: 0, behavior: 'smooth' });

    setTimeout(() => {
      setTab();
    }, 2000);
  };

  const onFinishAllegationFailed = (errorInfo: any) => {
    notification['error']({ message: i18n.translate('genericFormMsg.pleaseCheck'), duration: 2 });
    console.log('Failed:', errorInfo);
  };

  const loadInitialValues = () => {
    let values: any = data.allegations?.data?.map((allegation) => ({
      explanationPage: allegation?.attributes?.explanationPage,
      titleContent: allegation?.attributes?.titleContent,
      textContent: allegation?.attributes?.textContent,
      dkomText: allegation?.attributes?.dkomText,
      clientResponse: allegation?.attributes?.clientResponse,
      lawArticle: allegation?.attributes?.lawArticle,
      dkomAssessment: allegation?.attributes?.dkomAssessment,
      comment: allegation?.attributes?.comment,
      dkomRatingFilter: loadDkomRatingFilter(allegation?.id as number),
    }));

    return values;
  };

  const loadDefaultValues = () => {
    let values: any = [];

    return values;
  };

  const showConfirm = (key: number) => {
    confirm({
      title: i18n.t('allegations.confirmDelete'),
      icon: <ExclamationCircleOutlined />,
      content: i18n.t('common.cantUndo'),
      okText: i18n.t('genericButtons.yes'),
      cancelText: i18n.t('genericButtons.cancel'),
      onOk() {
        dispatch(deleteAllegationAction(data.allegations?.data[key]?.id as number));
        window.scrollTo({ top: 0, behavior: 'smooth' });
      },
    });
  };

  const clearAllegationList = () => {
    dispatch(clearAllegationListAction());
  };
  

  const addAllegation = () => {
    let allegationTags: Array<{ id: number; type: string }> = [];
    allegationTags = decision.currentDecision?.attributes?.tagIds?.map((id) => ({
      id: id,
      type: 'tag',
    })) as Array<{ id: number; type: string }>;

    let allegationData: any = {
      data: {
        type: 'decision_appeal_allegation',
        attributes: {
          explanationPage: 1,
          titleContent: '',
          textContent: '',
          dkomText: '',
          clientResponse: '',
          lawArticle: '',
          dkomAssessment: '',
          comment: '',
        },
        relationships: {
          decision: {
            data: {
              id: (decision.currentDecision?.id as number),
              type: 'decision',
            },
          },
          tags: {
            data: allegationTags,
          },
        },
      },
    };

    dispatch(createAllegationAction(allegationData));
  };

  const setTab = () => {
    setTabAppeal && setTabAppeal();
  };

  const loadDkomRatingFilter = (id: number) => {
    const tagTrue = getDkomRatingFilterTrue()?.tag?.id;
    const tagFalse = getDkomRatingFilterFalse()?.tag?.id;
    const allegation = data.allegations?.data?.find((allegation) => allegation.id == id);
    let tagFoundTrue;
    let tagFoundFalse;
    if (tagTrue && allegation) {
      if (allegation.attributes?.tagIds?.includes(tagTrue)) tagFoundTrue = tagTrue;
    }
    if (tagFalse && allegation) {
      if (allegation.attributes?.tagIds?.includes(tagFalse)) tagFoundFalse = tagFalse;
    }
    return tagFoundTrue ? tagFoundTrue : tagFoundFalse;
  };

  const getDkomRatingFilterTrue = () => {
    const tag = tags.tagScreens?.find((tag) => tag.filter.name == 'Ocjena DKOM-a');
    let child;
    if (tag && tag?.children && tag?.children?.length && tag?.children[0]) {
      child = tag?.children[0];
    }
    return child;
  };

  const getDkomRatingFilterFalse = () => {
    const tag = tags.tagScreens?.find((tag) => tag.filter.name == 'Ocjena DKOM-a');
    let child;
    if (tag && tag?.children && tag?.children?.length && tag?.children[1]) {
      child = tag?.children[1];
    }
    return child;
  };

  const genExtra = (key: number) => <DeleteOutlined onClick={() => showConfirm(key)} className="button" />;

  return (
    <div className="dkomAllegationsCrud w100-h100">
      {(
        <Form
          form={form}
          layout="vertical"
          name="allegation"
          size="large"
          scrollToFirstError
          onFinish={onFinishAllegation}
          onFinishFailed={onFinishAllegationFailed}
          autoComplete="off"
          requiredMark={false}
        >
          <div className="dkomAllegationsCrud__form">
            <Form.List
              initialValue={loadInitialValues()}
              name="allegations"
            >
              {(fields) => (
                <Collapse defaultActiveKey={'Panel0'}>
                  {fields.map(({ key, name }) => (
                    <Panel header={`${key + 1}. Žalbeni navod`} key={'Panel' + key} extra={genExtra(key)}>
                      <Space
                        key={key}
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          width: '100%',
                          marginBottom: 8,
                        }}
                        align="baseline"
                      >
                        <Form.Item
                          label={
                            <span className="text-bold">{i18n.translate(`allegations.form.fields.titleContent`)}</span>
                          }
                          name={[name, 'titleContent']}
                          rules={[{ required: true, message: i18n.translate(`genericFormMsg.required`) }]}
                          labelCol={{ span: 24 }}
                          wrapperCol={{ span: 24 }}
                        >
                          <TextArea autoSize={{ minRows: 4 }} />
                        </Form.Item>
                        <Form.Item
                          label={
                            <span className="text-bold">{i18n.translate(`allegations.form.fields.textContent`)}</span>
                          }
                          name={[name, 'textContent']}
                          rules={[{ required: true, message: i18n.translate(`genericFormMsg.required`) }]}
                          labelCol={{ span: 24 }}
                          wrapperCol={{ span: 24 }}
                        >
                          <TextArea autoSize={{ minRows: 4 }} />
                        </Form.Item>
                        <Form.Item
                          label={
                            <span className="text-bold">{i18n.translate(`allegations.form.fields.dkomText`)}</span>
                          }
                          name={[name, 'dkomText']}
                          //rules={[{ required: true, message: i18n.translate(`genericFormMsg.required`) }]}
                          labelCol={{ span: 24 }}
                          wrapperCol={{ span: 24 }}
                        >
                          <TextArea autoSize={{ minRows: 4 }} />
                        </Form.Item>
                        <Form.Item
                          label={
                            <span className="text-bold">
                              {i18n.translate(`allegations.form.fields.clientResponse`)}
                            </span>
                          }
                          name={[name, 'clientResponse']}
                          //rules={[{ required: true, message: i18n.translate(`genericFormMsg.required`) }]}
                          labelCol={{ span: 24 }}
                          wrapperCol={{ span: 24 }}
                        >
                          <TextArea autoSize={{ minRows: 4 }} />
                        </Form.Item>
                        <Form.Item
                          label={
                            <span className="text-bold">{i18n.translate(`allegations.form.fields.lawArticle`)}</span>
                          }
                          name={[name, 'lawArticle']}
                          //rules={[{ required: true, message: i18n.translate(`genericFormMsg.required`) }]}
                          labelCol={{ span: 24 }}
                          wrapperCol={{ span: 24 }}
                        >
                          <TextArea autoSize={{ minRows: 4 }} />
                        </Form.Item>
                        <Form.Item
                          label={
                            <span className="text-bold">
                              {i18n.translate(`allegations.form.fields.dkomAssessment`)}
                            </span>
                          }
                          name={[name, 'dkomAssessment']}
                          //rules={[{ required: true, message: i18n.translate(`genericFormMsg.required`) }]}
                          labelCol={{ span: 24 }}
                          wrapperCol={{ span: 24 }}
                        >
                          <TextArea autoSize={{ minRows: 4 }} />
                        </Form.Item>
                        <Form.Item
                          label={<span className="text-bold">{i18n.translate(`allegations.form.fields.comment`)}</span>}
                          name={[name, 'comment']}
                          //rules={[{ required: true, message: i18n.translate(`genericFormMsg.required`) }]}
                          labelCol={{ span: 24 }}
                          wrapperCol={{ span: 24 }}
                        >
                          <TextArea autoSize={{ minRows: 4 }} />
                        </Form.Item>
                        <Form.Item
                          label={
                            <span className="text-bold">
                              {i18n.translate(`allegations.form.fields.explanationPage`)}
                            </span>
                          }
                          name={[name, 'explanationPage']}
                          //rules={[{ required: true, message: i18n.translate(`genericFormMsg.required`) }]}
                          labelCol={{ span: 24 }}
                          wrapperCol={{ span: 12 }}
                        >
                          <InputNumber min={1} />
                        </Form.Item>

                        <div style={{ marginTop: 16 }} className="subtitle">
                          <span className="text">{i18n.translate('allegations.form.sections.filters')}</span>
                        </div>

                        <Form.Item
                          label={
                            <span className="text-bold">
                              {i18n.translate(`allegations.form.fields.dkomRatingFilter`)}
                            </span>
                          }
                          name={[name, 'dkomRatingFilter']}
                          //rules={[{ required: true, message: i18n.translate(`genericFormMsg.required`) }]}
                          labelCol={{ span: 24 }}
                          wrapperCol={{ span: 12 }}
                        >
                          <Radio.Group>
                            <Radio.Button
                              value={getDkomRatingFilterTrue()?.tag?.id ? getDkomRatingFilterTrue()?.tag?.id : 0}
                            >
                              {getDkomRatingFilterTrue()?.tag?.name ? getDkomRatingFilterTrue()?.tag?.name : ''}
                            </Radio.Button>
                            <Radio.Button
                              value={getDkomRatingFilterFalse()?.tag?.id ? getDkomRatingFilterFalse()?.tag?.id : 1}
                            >
                              {getDkomRatingFilterFalse()?.tag?.name ? getDkomRatingFilterFalse()?.tag?.name : ''}
                            </Radio.Button>
                          </Radio.Group>
                        </Form.Item>
                      </Space>
                    </Panel>
                  ))}
                </Collapse>
              )}
            </Form.List>
            <Button style={{ marginTop: 16 }} type="link" onClick={() => addAllegation()} block icon={<PlusOutlined />}>
              {i18n.translate(`allegations.crudTitleAdd`)}
            </Button>
          </div>
          <div className="dkomAllegationsCrud__bottomButtons">
            <div></div>
            <div>
              <Link to={AdminRoutes.DKOM_DECISIONS.fullPath} key={AdminRoutes.DKOM_DECISIONS.path}>
                <Button
                  onClick={clearAllegationList}
                  className="button"
                  size="large"
                  style={{ fontSize: 16, fontWeight: 700, borderRadius: 0, minWidth: 193, marginRight: 16 }}
                >
                  {i18n.translate('genericButtons.cancel')}
                </Button>
              </Link>
              <Button
                className="button"
                htmlType="submit"
                type="primary"
                size="large"
                style={{ fontSize: 16, fontWeight: 700, borderRadius: 0, minWidth: 193 }}
              >
                {i18n.translate('genericButtons.save')}
              </Button>
            </div>
          </div>
        </Form>
      )}
    </div>
  );
}

export default DKOMAllegationsCrudPageComponent;
