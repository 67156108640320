import { Button, Col, Form, Input, Modal, notification, Row, Typography } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { FullUserService, PasswordChangeService, TwoFactorAuthenticationService } from '../../../../api';
import { clearFullUserAction, FullUserConstants, getSingleFullUserAction, StoreStateInterface, updateFullUserAction } from '../../../../redux';
import { i18n } from '../../../../services';
import { FullUserResponseInterfaceSingle } from '../../../../interfaces';

const { Title, Text } = Typography;
const { confirm } = Modal;

export default function AdminPasswordComponent(/**props ? */) {
  const dispatch = useDispatch();
  const location = useLocation();
  const path = location.pathname.split('/');
  const id = parseInt(path[path.length - 1]);

  const [latest2faCode, setLatest2faCode] = useState<string>('');

  const user = useSelector((state: StoreStateInterface) => state.fullUser);

  useEffect(() => {
    dispatch(clearFullUserAction());
    dispatch(getSingleFullUserAction(id as number));
  }, [id]);

  const onFinish = (values: any) => {
    let body = {
      email: user?.currentFullUser?.attributes?.email as string,
      oldPassword: values?.current,
      newPassword: values?.new
    }

    if (body.newPassword != values?.repeatNew) {
      notification['error']({ message: i18n.translate('common.passwordNoMatch'), duration: 5 });
      return;
    }

    PasswordChangeService.changePassword(body).subscribe(
      (response: any) => {
        // dispatch(clearFullUserAction());
        // dispatch(getSingleFullUserAction(id as number));
        // localStorage.clear();
        // setTimeout(() => {
        //   window.location.reload();
        // }, 1000);
        notification['success']({ message: i18n.translate('api.successMessage'), duration: 2 });
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
      }
    );
  };

  const on2faActivation = () => {
    let userData: any = {
      data: {
        type: 'user',
        id: user.currentFullUser?.id,
        attributes: {
          active2fa: true,
          newEmailFootnote: {
            email: user.currentFullUser?.attributes?.email,
            footnote: user.currentFullUser?.attributes?.footnote,
          }
        }
      },
    };

    FullUserService.update(userData).subscribe(
      (response: FullUserResponseInterfaceSingle) => {
        notification['success']({ message: i18n.translate('api.2faSuccess'), duration: 2 });
        dispatch(clearFullUserAction());
        dispatch(getSingleFullUserAction(id as number));
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
      }
    );
  };

  const showConfirm = () => {
    confirm({
      title: i18n.t('profile.basicInfo.deactivate2fa'),
      icon: <ExclamationCircleOutlined />,
      //content: i18n.t('common.cantUndo'),
      okText: i18n.t('genericButtons.yes'),
      cancelText: i18n.t('genericButtons.cancel'),
      onOk() {
        TwoFactorAuthenticationService.deactivate(id).subscribe(
          (response: any) => {
            notification['success']({ message: i18n.translate('api.2faDeactivated'), duration: 2 });
            dispatch(clearFullUserAction());
            dispatch(getSingleFullUserAction(id as number));
          },
          (error: Error) => {
            notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
          }
        );
      },
    });
  };

  const getLatest2faCode = () => {
    TwoFactorAuthenticationService.getLatest2faCode(id).subscribe(
      (response: any) => {
        setLatest2faCode(response.code);
        if (!response.code) notification['warning']({ message: i18n.translate('api.2faWarning'), duration: 5 });
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
      }
    );
  };
  
  return (
    <div className="password">
      <Form onFinish={onFinish} layout="vertical">
        <Form.Item required label={i18n.translate(`profile.password.current`)} name="current" rules={[{ required: true, message: i18n.translate(`genericFormMsg.required`) }, {
            validator: (_, value) =>
              !value.includes(" ")
                ? Promise.resolve()
                : Promise.reject(new Error(i18n.t('common.noSpace')))
          }]}
        >
          <Input.Password style={{ borderRadius: 0 }} />
        </Form.Item>
        <Form.Item required label={i18n.translate(`profile.password.new`)} name="new" rules={[{ required: true, message: i18n.translate(`genericFormMsg.required`) }, {
            validator: (_, value) =>
              !value.includes(" ")
                ? Promise.resolve()
                : Promise.reject(new Error(i18n.t('common.noSpace')))
          }]}
        >
          <Input.Password style={{ borderRadius: 0 }} />
        </Form.Item>
        <Form.Item required label={i18n.translate(`profile.password.repeatNew`)} name="repeatNew" rules={[{ required: true, message: i18n.translate(`genericFormMsg.required`) }, {
            validator: (_, value) =>
              !value.includes(" ")
                ? Promise.resolve()
                : Promise.reject(new Error(i18n.t('common.noSpace')))
          }]}
        >
          <Input.Password style={{ borderRadius: 0 }} />
        </Form.Item>

        <Input.Group>
          <Row gutter={8} style={{ width: '100%', margin: 0 }}>
            {/**<Col>
              <Button>{i18n.translate(`genericButtons.cancel`)}</Button>
            </Col>*/}
            <Col>
              <Button htmlType='submit' type="primary">{i18n.translate(`genericButtons.save`)}</Button>
            </Col>
          </Row>
        </Input.Group>
      </Form>
      <div className="mt32">
        <Title level={5}>{i18n.translate('login2fa.title')}</Title>
        <div className="flex-row">
          {!user?.currentFullUser?.attributes?.active2fa ? <div><Button type="primary" onClick={on2faActivation}>{i18n.translate(`genericButtons.activate`)}</Button></div> : <div><Button type="primary" danger onClick={showConfirm}>{i18n.translate(`genericButtons.deactivate`)}</Button></div>}
          <div className='ml16'>{latest2faCode ? <div><Text strong>{latest2faCode}</Text><Button className="ml16" type="link" onClick={getLatest2faCode}>{i18n.translate(`genericButtons.get2faCodeAgain`)}</Button></div> : <Button type="link" onClick={getLatest2faCode}>{i18n.translate(`genericButtons.get2faCode`)}</Button>}</div>
        </div>
      </div>
    </div>
  );
}
