import React, { useState } from 'react';
import { Dispatch } from 'redux';
import { Form, Input, Button, notification } from 'antd';
import { useDispatch } from 'react-redux';
import { AuthConstants, i18n, LoginInterface, LoginResponseInterface, NavigationService, UserActivityLogService, UserRoleEnum, UserService, VersionComponent } from '../../../common';
import { Link } from 'react-router-dom';
import { AuthRoutes } from '../_router/auth.routes';
import logo from '../../../assets/images/bjn.png';
import Login2faModal from '../../../common/components/modals/2faLogin/login2faModal.component';
import { UserActionTypeEnum } from '../../../common/enums/userActionType.enum';
import { AdminRoutes } from '../../admin';
import { AppRoutes } from '../../app';

function LoginPageComponent() {
  const dispatch = useDispatch();

  const [loginAttempts, setLoginAttempts] = useState<number>(0);
  const [login2faVisible, setLogin2faVisible] = useState<boolean>(false);

  const [form] = Form.useForm();

  const tailLayout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };

  const onFinish = (values: any) => {
    if (loginAttempts === 5) {
      setLoginAttempts(loginAttempts + 1)
      notification['error']({ message: i18n.translate('login.msgs.tooManyAttempts'), duration: 5 });
      return;
    }

    if (loginAttempts === 6) {
      setLoginAttempts(loginAttempts + 1)
      setTimeout(() => {
        setLoginAttempts(0);
      }, 1.8e+6);
      notification['error']({ message: i18n.translate('login.msgs.tooManyAttempts'), duration: 5 });
      return;
    }

    if (loginAttempts === 7) {
      notification['error']({ message: i18n.translate('login.msgs.tooManyAttempts'), duration: 5 });
      return;
    }

    setLoginAttempts(loginAttempts + 1)

    let loginData: LoginInterface = {
      username: values.email,
      password: values.password,
    };
    dispatch(loginAction(loginData));
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const on2faFinish = (values: any) => {
    let loginData: LoginInterface = {
      username: form.getFieldValue('email'),
      password: form.getFieldValue('password'),
      code: values.code
    };
    dispatch(loginAction(loginData));
  };

  const loginAction = (data: LoginInterface) => {
    return (dispatch: Dispatch) => {
      return UserService.login(data).subscribe(
        (response: LoginResponseInterface) => {
          dispatch({
            type: AuthConstants.LOGIN_SUCCESS,
            payload: response,
          });
  
          const isAdmin = response?.user?.roles === UserRoleEnum.ADMIN;
  
          notification['success']({ message: i18n.translate('login.msgs.login'), duration: 2 });
  
          document.cookie = "bjn_user=" + response?.user?.email + ";domain=.onlyoneif.com;path=/";
  
          if (!isAdmin) {
            let userActivityLogData: any = {
              data: {
                type: 'user_activity_log',
                attributes: {
                  action: UserActionTypeEnum.LOGIN,
                  extra: JSON.stringify({organizationId: response.organizationId, organizationName: response.user?.organizationUnit?.organization?.name, userId: response.user?.id, userName: response.user?.name})
                },
                relationships: {
                  user: {
                    data: {
                      id: response?.user?.id,
                      type: 'user'
                    },
                  },
                },
              },
            };
  
            let userActivityLogDataInstitution: any = {
              data: {
                type: 'user_activity_log',
                attributes: {
                  action: UserActionTypeEnum.INSTITUTION_VISITED,
                  extra: JSON.stringify({organizationId: response.organizationId, organizationName: response.user?.organizationUnit?.organization?.name})
                },
                relationships: {
                  user: {
                    data: {
                      id: response?.user?.id,
                      type: 'user'
                    },
                  },
                },
              },
            };
  
            UserActivityLogService.create(userActivityLogData);
            UserActivityLogService.create(userActivityLogDataInstitution);
          }
  
          NavigationService.navigate(isAdmin ? AdminRoutes.DASHBOARD.fullPath : AppRoutes.DASHBOARD.fullPath, '?loggedIn=true');
        },
        (error: any) => {
          if (error.errorId === 16) {
            notification['error']({ message: i18n.translate('login.msgs.alreadyLoggedIn'), duration: 2 });
            return;
          }
          if (error.errorId === 20) {
            setLogin2faVisible(true);
            return;
          }
          if (error.errorId === 37) {
            notification['error']({ message: i18n.translate('login.msgs.2faInvalidOrExpired'), duration: 3 });
            return;
          }
          notification['error']({ message: i18n.translate('login.msgs.loginError'), duration: 2 });
  
          dispatch({
            type: AuthConstants.ERROR,
            payload: error,
          });
        }
      );
    };
  }

  return (
    <div className='login'>
      <div className='login__icon'>
        <img width={38} height={32} src={logo} alt="none" />
        <div className='text'>BJN</div>
      </div>
      <div className='login__title'>
        <div className="primary">
          {i18n.translate(`login.title.primary`)}
        </div>
        <div className='subtitle'>
        {i18n.translate(`login.title.subtitle`)}
        </div>
      </div>
      <div className='login__form'>
        <Form
          form={form}
          name="basic"
          layout='vertical'
          initialValues={{ remember: true, email: '', password: '' }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          requiredMark={false}
        >
          <Form.Item
            label={i18n.translate(`login.form.username`)}
            name="email"
            rules={[{ required: true, message: i18n.translate(`login.msgs.required`) }, {
              validator: (_, value) =>
                !value.includes(" ")
                  ? Promise.resolve()
                  : Promise.reject(new Error(i18n.t('common.noSpace')))
            }]}
          >
            <Input size="large" style={{borderRadius: 0}} />
          </Form.Item>

          <Form.Item
            label={i18n.translate(`login.form.password`)}
            name="password"
            rules={[{ required: true, message: i18n.translate(`login.msgs.required`) }, {
              validator: (_, value) =>
                !value.includes(" ")
                  ? Promise.resolve()
                  : Promise.reject(new Error(i18n.t('common.noSpace')))
            }]}
          >
            <Input.Password size="large" style={{borderRadius: 0}} />
          </Form.Item>

          <Link to={AuthRoutes.FORGOT_PASSWORD.fullPath} key={AuthRoutes.FORGOT_PASSWORD.path}>
            <Button type="link" block size="small" style={{marginTop: 0, marginBottom: 8, fontSize: 12, fontWeight: 400, borderRadius: 0, textAlign: 'right'}}>
              {i18n.translate(`login.btns.forgotPassword`)}
            </Button>
          </Link>

          <Form.Item {...tailLayout}>
            <Button type="primary" htmlType="submit" block size="large" style={{fontSize: 16, fontWeight: 700, borderRadius: 0}}>
              {i18n.translate(`login.btns.login`)}
            </Button>
          </Form.Item>
          {i18n.translate(`login.btns.noAccount`)}
          <Link to={AuthRoutes.REGISTER.fullPath} key={AuthRoutes.REGISTER.path}>
            <Button type="link" style={{paddingLeft: 2}}>
              <span style={{textDecoration: 'underline'}}>{i18n.translate(`login.btns.register`)}</span>
            </Button>
          </Link>
        </Form>
      </div>

      <VersionComponent style={{ position: 'fixed', bottom: '1px' }} />
      <Login2faModal
        visible={login2faVisible}
        handleOk={on2faFinish}
        handleCancel={() => setLogin2faVisible(false)}
      />
    </div>
  );
}

export default LoginPageComponent;
