import { LicenceService, LicenceUserService } from "../api/api.service";
import moment from 'moment';
import { JavaFormat } from "../constants";
import { LicenceEnum } from "../enums/licence.enum";
import { LicenceInterface } from "../interfaces";

async function upgradeUserLicence(userId: number, orgUnitId: number, licenceDetails: any, mainYear: string, nestedYears: Array<string>, bothYearsSubscription: boolean, biltenSubscriptionYears: Array<string>, teamScreen: boolean) {

  if (bothYearsSubscription) {
    for (let i = 0; i < 2; i++) {
      let mainLicence = await LicenceService.createAny({
        data: {
          type: 'licence',
          attributes: {
            ...licenceDetails,
            year: i == 0 ? mainYear : (parseInt(mainYear) - 1)?.toString(),
            subscriptionType: 'SUBSCRIPTION',
            printedEditionsNum: i == 0 ? (biltenSubscriptionYears.includes('2') ? 3 : 0) : (biltenSubscriptionYears.includes('1') ? 3 : 0),
            biltenSubscription: i == 0 ? (biltenSubscriptionYears.includes('2') ? true : false) : (biltenSubscriptionYears.includes('1') ? true : false),
            from: i == 0 ? (teamScreen ? licenceDetails.from : moment(mainYear.toString()).startOf('year').format(JavaFormat)) : moment((parseInt(mainYear) - 1)?.toString()).startOf('year').format(JavaFormat),
            to: i == 0 ? (teamScreen ? licenceDetails.to : moment(mainYear.toString()).add(1, 'y').set({'date': 20, 'month': 1}).format(JavaFormat)) : moment((parseInt(mainYear) - 1)?.toString()).add(1, 'y').set({'date': 20, 'month': 1}).format(JavaFormat),
          },
          relationships: {
            organizationUnit: {
              data: {
                id: orgUnitId,
                type: "organization_unit"
              }
            },
            licenceParent: {
              data: null
            }
          }
        }
      }).toPromise();
    
      await LicenceUserService.create({
        data: {
          type: 'licence_user',
          relationships: {
            user: {
              data: {
                id: userId,
                type: 'user',
              },
            },
            licence: {
              data: {
                id: mainLicence.data?.id,
                type: 'licence',
              },
            },
          },
        },
      }).toPromise();
      
      if (i == 0) {
        for(let nYear of nestedYears) {
          let subLicence = await LicenceService.createAny({
            data: {
              type: 'licence',
              attributes: {
                ...licenceDetails,
                year: nYear,
                subscriptionType: 'NESTED_SUBSCRIPTION',
                printedEditionsNum: 3,
                biltenSubscription: true,
              },
              relationships: {
                organizationUnit: {
                  data: {
                    id: orgUnitId,
                    type: "organization_unit"
                  }
                },
                licenceParent: {
                  data: {
                    id: mainLicence?.data?.id,
                    type: 'licence'
                  }
                }
              }
            }
          }).toPromise();
      
          await LicenceUserService.create({
            data: {
              type: 'licence_user',
              relationships: {
                user: {
                  data: {
                    id: userId,
                    type: 'user',
                  },
                },
                licence: {
                  data: {
                    id: subLicence.data?.id,
                    type: 'licence',
                  },
                },
              },
            },
          }).toPromise();
        }
      }
    
      await LicenceService.approveSpecificLicence(orgUnitId, mainLicence.data.id!).toPromise();
    }

  } else {
    let mainLicence = await LicenceService.createAny({
      data: {
        type: 'licence',
        attributes: {
          ...licenceDetails,
          year: mainYear,
          subscriptionType: 'SUBSCRIPTION',
        },
        relationships: {
          organizationUnit: {
            data: {
              id: orgUnitId,
              type: "organization_unit"
            }
          },
          licenceParent: {
            data: null
          }
        }
      }
    }).toPromise();
  
    await LicenceUserService.create({
      data: {
        type: 'licence_user',
        relationships: {
          user: {
            data: {
              id: userId,
              type: 'user',
            },
          },
          licence: {
            data: {
              id: mainLicence.data?.id,
              type: 'licence',
            },
          },
        },
      },
    }).toPromise();
    
    for(let nYear of nestedYears) {
      let subLicence = await LicenceService.createAny({
        data: {
          type: 'licence',
          attributes: {
            ...licenceDetails,
            year: nYear,
            subscriptionType: 'NESTED_SUBSCRIPTION',
          },
          relationships: {
            organizationUnit: {
              data: {
                id: orgUnitId,
                type: "organization_unit"
              }
            },
            licenceParent: {
              data: {
                id: mainLicence?.data?.id,
                type: 'licence'
              }
            }
          }
        }
      }).toPromise();
  
      await LicenceUserService.create({
        data: {
          type: 'licence_user',
          relationships: {
            user: {
              data: {
                id: userId,
                type: 'user',
              },
            },
            licence: {
              data: {
                id: subLicence.data?.id,
                type: 'licence',
              },
            },
          },
        },
      }).toPromise();
    }
  
    await LicenceService.approveSpecificLicence(orgUnitId, mainLicence.data.id!).toPromise();
  }

}

async function upgradeUserLicenceRequest(userId: number, orgUnitId: number, licenceDetails: any, mainYear: string, nestedYears: Array<string>, bothYearsSubscription: boolean, biltenSubscriptionYears: Array<string>) {

  if (bothYearsSubscription) {
    for (let i = 0; i < 2; i++) {
      let mainLicence = await LicenceService.createAny({
        data: {
          type: 'licence',
          attributes: {
            ...licenceDetails,
            year: i == 0 ? mainYear : (parseInt(mainYear) - 1)?.toString(),
            subscriptionType: 'SUBSCRIPTION',
            printedEditionsNum: i == 0 ? (biltenSubscriptionYears.includes('2') ? 3 : 0) : (biltenSubscriptionYears.includes('1') ? 3 : 0),
            biltenSubscription: i == 0 ? (biltenSubscriptionYears.includes('2') ? true : false) : (biltenSubscriptionYears.includes('1') ? true : false),
            from: i == 0 ? moment(mainYear.toString()).startOf('year').format(JavaFormat) : moment((parseInt(mainYear) - 1)?.toString()).startOf('year').format(JavaFormat),
            to: i == 0 ? moment(mainYear.toString()).add(1, 'y').set({'date': 20, 'month': 1}).format(JavaFormat) : moment((parseInt(mainYear) - 1)?.toString()).add(1, 'y').set({'date': 20, 'month': 1}).format(JavaFormat),
            active: false
          },
          relationships: {
            organizationUnit: {
              data: {
                id: orgUnitId,
                type: "organization_unit"
              }
            },
            licenceParent: {
              data: null
            }
          }
        }
      }).toPromise();
    
      await LicenceUserService.create({
        data: {
          type: 'licence_user',
          attributes: {
            active: false
          },
          relationships: {
            user: {
              data: {
                id: userId,
                type: 'user',
              },
            },
            licence: {
              data: {
                id: mainLicence.data?.id,
                type: 'licence',
              },
            },
          },
        },
      }).toPromise();
      
      if (i == 0) {
        for(let nYear of nestedYears) {
          let subLicence = await LicenceService.createAny({
            data: {
              type: 'licence',
              attributes: {
                ...licenceDetails,
                year: nYear,
                subscriptionType: 'NESTED_SUBSCRIPTION',
                printedEditionsNum: 3,
                biltenSubscription: true,
                active: false
              },
              relationships: {
                organizationUnit: {
                  data: {
                    id: orgUnitId,
                    type: "organization_unit"
                  }
                },
                licenceParent: {
                  data: {
                    id: mainLicence?.data?.id,
                    type: 'licence'
                  }
                }
              }
            }
          }).toPromise();
      
          await LicenceUserService.create({
            data: {
              type: 'licence_user',
              attributes: {
                active: false
              },
              relationships: {
                user: {
                  data: {
                    id: userId,
                    type: 'user',
                  },
                },
                licence: {
                  data: {
                    id: subLicence.data?.id,
                    type: 'licence',
                  },
                },
              },
            },
          }).toPromise();
        }
      }
    
      await LicenceService.disapproveSpecificLicence(orgUnitId, mainLicence.data.id!).toPromise();
    }
  } else {
    let mainLicence = await LicenceService.createAny({
      data: {
        type: 'licence',
        attributes: {
          ...licenceDetails,
          year: mainYear,
          subscriptionType: 'SUBSCRIPTION',
          active: false
        },
        relationships: {
          organizationUnit: {
            data: {
              id: orgUnitId,
              type: "organization_unit"
            }
          },
          licenceParent: {
            data: null
          }
        }
      }
    }).toPromise();
  
    await LicenceUserService.create({
      data: {
        type: 'licence_user',
        attributes: {
          active: false
        },
        relationships: {
          user: {
            data: {
              id: userId,
              type: 'user',
            },
          },
          licence: {
            data: {
              id: mainLicence.data?.id,
              type: 'licence',
            },
          },
        },
      },
    }).toPromise();
    
    for(let nYear of nestedYears) {
      let subLicence = await LicenceService.createAny({
        data: {
          type: 'licence',
          attributes: {
            ...licenceDetails,
            year: nYear,
            subscriptionType: 'NESTED_SUBSCRIPTION',
            active: false
          },
          relationships: {
            organizationUnit: {
              data: {
                id: orgUnitId,
                type: "organization_unit"
              }
            },
            licenceParent: {
              data: {
                id: mainLicence?.data?.id,
                type: 'licence'
              }
            }
          }
        }
      }).toPromise();
  
      await LicenceUserService.create({
        data: {
          type: 'licence_user',
          attributes: {
            active: false
          },
          relationships: {
            user: {
              data: {
                id: userId,
                type: 'user',
              },
            },
            licence: {
              data: {
                id: subLicence.data?.id,
                type: 'licence',
              },
            },
          },
        },
      }).toPromise();
    }
  
    await LicenceService.disapproveSpecificLicence(orgUnitId, mainLicence.data.id!).toPromise();
  }
}

async function createUserLicence(orgUnitId: number, licenceDetails: any, mainYear: string, nestedYears: Array<string>, bothYearsSubscription: boolean, biltenSubscriptionYears: Array<string>, teamScreen: boolean) {
  if (bothYearsSubscription) {
    for (let i = 0; i < 2; i++) {
      let mainLicence = await LicenceService.createAny({
        data: {
          type: 'licence',
          attributes: {
            ...licenceDetails,
            year: i == 0 ? mainYear : (parseInt(mainYear) - 1)?.toString(),
            subscriptionType: 'SUBSCRIPTION',
            printedEditionsNum: i == 0 ? (biltenSubscriptionYears.includes('2') ? 3 : 0) : (biltenSubscriptionYears.includes('1') ? 3 : 0),
            biltenSubscription: i == 0 ? (biltenSubscriptionYears.includes('2') ? true : false) : (biltenSubscriptionYears.includes('1') ? true : false),
            from: i == 0 ? (teamScreen ? licenceDetails.from : moment(mainYear.toString()).startOf('year').format(JavaFormat)) : moment((parseInt(mainYear) - 1)?.toString()).startOf('year').format(JavaFormat),
            to: i == 0 ? (teamScreen ? licenceDetails.to : moment(mainYear.toString()).add(1, 'y').set({'date': 20, 'month': 1}).format(JavaFormat)) : moment((parseInt(mainYear) - 1)?.toString()).add(1, 'y').set({'date': 20, 'month': 1}).format(JavaFormat),
          },
          relationships: {
            organizationUnit: {
              data: {
                id: orgUnitId,
                type: "organization_unit"
              }
            },
            licenceParent: {
              data: null
            }
          }
        }
      }).toPromise();
      
      if (i == 0) {
        for(let nYear of nestedYears) {
          let subLicence = await LicenceService.createAny({
            data: {
              type: 'licence',
              attributes: {
                ...licenceDetails,
                year: nYear,
                subscriptionType: 'NESTED_SUBSCRIPTION',
              },
              relationships: {
                organizationUnit: {
                  data: {
                    id: orgUnitId,
                    type: "organization_unit"
                  }
                },
                licenceParent: {
                  data: {
                    id: mainLicence?.data?.id,
                    type: 'licence'
                  }
                }
              }
            }
          }).toPromise();
        }
      }
    }
  } else {
    let mainLicence = await LicenceService.createAny({
      data: {
        type: 'licence',
        attributes: {
          ...licenceDetails,
          year: mainYear,
          subscriptionType: 'SUBSCRIPTION',
        },
        relationships: {
          organizationUnit: {
            data: {
              id: orgUnitId,
              type: "organization_unit"
            }
          },
          licenceParent: {
            data: null
          }
        }
      }
    }).toPromise();
    
    for(let nYear of nestedYears) {
      let subLicence = await LicenceService.createAny({
        data: {
          type: 'licence',
          attributes: {
            ...licenceDetails,
            year: nYear,
            subscriptionType: 'NESTED_SUBSCRIPTION',
            printedEditionsNum: 3,
            biltenSubscription: true,
          },
          relationships: {
            organizationUnit: {
              data: {
                id: orgUnitId,
                type: "organization_unit"
              }
            },
            licenceParent: {
              data: {
                id: mainLicence?.data?.id,
                type: 'licence'
              }
            }
          }
        }
      }).toPromise();
    }
  }
}

async function createLicenceUsers(userId: number, orgUnitId: number, mainLicence: LicenceInterface) {

  await LicenceUserService.create({
    data: {
      type: 'licence_user',
      relationships: {
        user: {
          data: {
            id: userId,
            type: 'user',
          },
        },
        licence: {
          data: {
            id: mainLicence?.id,
            type: 'licence',
          },
        },
      },
    },
  }).toPromise();

  for(let nLicence of mainLicence.attributes.childrenLicences) {
    let subLicence = await LicenceUserService.create({
      data: {
        type: 'licence_user',
        relationships: {
          user: {
            data: {
              id: userId,
              type: 'user',
            },
          },
          licence: {
            data: {
              id: nLicence?.id,
              type: 'licence',
            },
          },
        },
      },
    }).toPromise();
  }

  //OVO SAM ZAKOMENTIRAO JER TEHNIČKI RADI I BEZ TOGA, ALI NE ZNAM ZAŠTO BE BACI GREŠKU NA OVO ISPOD
  //await LicenceService.approveSpecificLicence(orgUnitId, mainLicence.id!).toPromise();
}

const getConsultationsFinal = (licenceType: any) => {
  switch (licenceType) {
    case LicenceEnum.FREE:
      return 0;
    case LicenceEnum.STANDARD:
      return 5;
    case LicenceEnum.PREMIUM:
      return 8;
    default:
      return 0;
  }
};

const getLecturesFinal = (licenceType: any) => {
  switch (licenceType) {
    case LicenceEnum.FREE:
      return 0;
    case LicenceEnum.STANDARD:
      return 3;
    case LicenceEnum.PREMIUM:
      return 6;
    default:
      return 0;
  }
};

const getNotesFinal = (licenceType: any) => {
  switch (licenceType) {
    case LicenceEnum.FREE:
      return 0;
    case LicenceEnum.STANDARD:
      return 10;
    case LicenceEnum.PREMIUM:
      return 100;
    default:
      return 0;
  }
};

export default {
  upgradeUserLicence,
  upgradeUserLicenceRequest,
  createUserLicence,
  createLicenceUsers,
  getConsultationsFinal,
  getLecturesFinal,
  getNotesFinal
};
