export enum CourtConstants {
  GET_ALL_SUCCESS = 'CourtConstants_GET_ALL_SUCCESS',
  GET_SINGLE_SUCCESS = 'CourtConstants_GET_SINGLE_SUCCESS',
  CREATE_SUCCESS = 'CourtConstants_CREATE_SUCCESS',
  UPDATE_SUCCESS = 'CourtConstants_UPDATE_SUCCESS',
  CLEAR_CURRENT = 'CourtConstants_CLEAR_CURRENT',
  CLEAR_LIST = 'CourtConstants_CLEAR_LIST',
  DELETE = 'CourtConstants_DELETE',
  ERROR = 'CourtConstants_ERROR'
}
