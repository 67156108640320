import { Typography } from 'antd';
import React from 'react';

const { Paragraph, Title } = Typography;

export default function ComplaintComponent() {
  return (
    <div className="complaint">
      <div className="section">
        <Title className="title" level={5}>
          Naslov zalbenog navoda koji može biti jako dugi niz riječi i naziv zalbenog navoda, gdje se netko zalio i onda
          izvukli zalbeni navod jer tu moze biti jako dugi naziv, zalbenog navoda koji može biti jako dugi niz riječi i
          naziv zalbenog navoda, gdje se netko zalio i onda izvukli zalbeni navod jer tu moze biti jako dugi naziv.{' '}
        </Title>
        <Paragraph>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
          magna aliqua. Libero id faucibus nisl tincidunt eget nullam. Pretium aenean pharetra magna ac placerat. In
          nulla posuere sollicitudin aliquam ultrices sagittis orci a scelerisque. Lobortis scelerisque fermentum dui
          faucibus in ornare quam viverra orci. Felis bibendum ut tristique et egestas quis ipsum. Cursus turpis massa
          tincidunt dui ut ornare lectus. Id aliquet risus feugiat in ante. Enim ut tellus elementum sagittis vitae et
          leo. Elementum integer enim neque volutpat ac. Diam donec adipiscing tristique risus nec feugiat. Diam quis
          enim lobortis scelerisque fermentum dui faucibus. Nec feugiat nisl pretium fusce. Risus in hendrerit gravida
          rutrum. Et ultrices neque ornare aenean euismod elementum. Tortor aliquam nulla facilisi cras fermentum odio
          eu. Elementum facilisis leo vel fringilla est ullamcorper. Sit amet purus gravida quis. Arcu dictum varius
          duis at consectetur. Dui vivamus arcu felis bibendum. Ultricies tristique nulla aliquet enim tortor at auctor
          urna nunc. Cras sed felis eget velit aliquet sagittis id consectetur. Sodales ut etiam sit amet. Ullamcorper a
          lacus vestibulum sed arcu non odio euismod. Id consectetur purus ut faucibus pulvinar elementum integer enim.
          Aliquet risus feugiat in ante metus dictum at. Consequat id porta nibh venenatis cras sed felis eget. Quis
          eleifend quam adipiscing vitae proin. Est ullamcorper eget nulla facilisi etiam dignissim diam quis. Non arcu
          risus quis varius. Tortor posuere ac ut consequat semper viverra nam. Tincidunt tortor aliquam nulla facilisi
          cras fermentum.
        </Paragraph>
      </div>

      <div className="titleSection">
        <Title level={4}>Odgovor naručitelja i odluka DKOM-a</Title>
      </div>
      
      <div className="section">
        <Title level={4}>DKOM originalni tekst</Title>
        <Paragraph>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
          magna aliqua. Libero id faucibus nisl tincidunt eget nullam. Pretium aenean pharetra magna ac placerat. In
          nulla posuere sollicitudin aliquam ultrices sagittis orci a scelerisque. Lobortis scelerisque fermentum dui
          faucibus in ornare quam viverra orci. Felis bibendum ut tristique et egestas quis ipsum. Cursus turpis massa
          tincidunt dui ut ornare lectus. Id aliquet risus feugiat in ante. Enim ut tellus elementum sagittis vitae et
          leo. Elementum integer enim neque volutpat ac. Diam donec adipiscing tristique risus nec feugiat. Diam quis
          enim lobortis scelerisque fermentum dui faucibus. Nec feugiat nisl pretium fusce. Risus in hendrerit gravida
          rutrum. Et ultrices neque ornare aenean euismod elementum. Tortor aliquam nulla facilisi cras fermentum odio
          eu. Elementum facilisis leo vel fringilla est ullamcorper.
        </Paragraph>

        <Title level={4}>Odgovor narucitelja</Title>
        <Paragraph>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
          magna aliqua. Libero id faucibus nisl tincidunt eget nullam. Pretium aenean pharetra magna ac placerat. In
          nulla posuere sollicitudin aliquam ultrices sagittis orci a scelerisque. Lobortis scelerisque fermentum dui
          faucibus in ornare quam viverra orci. Felis bibendum ut tristique et egestas quis ipsum. Cursus turpis massa
          tincidunt dui ut ornare lectus. Id aliquet risus feugiat in ante. Enim ut tellus elementum sagittis vitae et
          leo. Elementum integer enim neque volutpat ac. Diam donec adipiscing tristique risus nec feugiat. Diam quis
          enim lobortis scelerisque fermentum dui faucibus. Nec feugiat nisl pretium fusce. Risus in hendrerit gravida
          rutrum. Et ultrices neque ornare aenean euismod elementum. Tortor aliquam nulla facilisi cras fermentum odio
          eu. Elementum facilisis leo vel fringilla est ullamcorper. Sit amet purus gravida quis.
        </Paragraph>

        <Title level={4}>Clanak ZJN</Title>
        <Paragraph>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
          magna aliqua. Libero id faucibus nisl tincidunt eget nullam. Pretium aenean pharetra magna ac placerat. In
          nulla posuere sollicitudin aliquam ultrices sagittis orci a scelerisque. Lobortis scelerisque fermentum dui
          faucibus in ornare quam viverra orci. Felis bibendum ut tristique et egestas quis ipsum. Cursus turpis massa
          tincidunt dui ut ornare lectus.
        </Paragraph>

        <Title level={4}>Ocjena DKOM-a / Obrazlozenje</Title>
        <Paragraph>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
          magna aliqua. Libero id faucibus nisl tincidunt eget nullam. Pretium aenean pharetra magna ac placerat. In
          nulla posuere sollicitudin aliquam ultrices sagittis orci a scelerisque. Lobortis scelerisque fermentum dui
          faucibus in ornare quam viverra orci.
        </Paragraph>

        <Title level={4}>Komentar</Title>
        <Paragraph>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
          magna aliqua. Libero id faucibus nisl tincidunt eget nullam. Pretium aenean pharetra magna ac placerat. In
          nulla posuere sollicitudin aliquam ultrices sagittis orci a scelerisque. Lobortis scelerisque fermentum dui
          faucibus in ornare quam viverra orci.
        </Paragraph>
      </div>
    </div>
  );
}
