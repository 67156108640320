import { Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import { DisplayDateFormat } from '../../../../constants';
import { UserRoleEnum } from '../../../../enums';
import { LicenceEnum } from '../../../../enums/licence.enum';
import { FullOrganizationUnitResponseInterfaceSingle, LicenceInterface } from '../../../../interfaces';
import { FullUserStateInterface, StoreStateInterface } from '../../../../redux';
import { i18n } from '../../../../services';

interface Props {
  user?: FullUserStateInterface;
  orgUnitInfo?: FullOrganizationUnitResponseInterfaceSingle;
  licenceInfo?: LicenceInterface;
  oldLicencesInfo?: LicenceInterface[];
}

export default function AdminSubscriptionTableComponent({ user, orgUnitInfo, licenceInfo, oldLicencesInfo }: Props) {
  const userAuth = useSelector((state: StoreStateInterface) => state.auth);
  
  interface DataType {
    key: string;
    year: string;
    model: string;
    yearView: string;
    printSub: string;
    expirationDate: string;
    paymentDate: string;
    totalEducations: string;
    usedEducations: string;
    totalQuestions: string;
    usedQuestions: string;
    totalNotes: string;
    active: string;
  }

  const getOrganization = (licence: LicenceInterface) => {
    let organization = '';
    if (licence?.attributes?.licenceUsers?.length) {
      const foundUser = licence?.attributes?.licenceUsers?.find(x => x?.user?.id == user?.currentFullUser?.id);
      if (foundUser) {
        organization = foundUser?.user?.organizationUnit?.organization?.name ? foundUser?.user?.organizationUnit?.organization?.name : '';
      }
    }

    return organization;
  };

  const getBasicYears = (licence: LicenceInterface) => {
    let yearArray = [];
    if (licence?.attributes?.year && licence?.attributes?.licenceType === LicenceEnum.STANDARD) {
      yearArray.push(licence?.attributes?.year);
    }
    if (licence?.attributes?.childrenLicences?.length) {
      licence?.attributes?.childrenLicences?.forEach(child => {
        if (licence?.attributes?.licenceType === LicenceEnum.STANDARD) {
          yearArray.push(child?.year);
        }
      });
    }

    return yearArray;
  };

  const getPremiumYears = (licence: LicenceInterface) => {
    let yearArray = [];
    if (licence?.attributes?.year && licence?.attributes?.licenceType === LicenceEnum.PREMIUM) {
      yearArray.push(licence?.attributes?.year);
    }
    if (licence?.attributes?.childrenLicences?.length) {
      licence?.attributes?.childrenLicences?.forEach(child => {
        if (licence?.attributes?.licenceType === LicenceEnum.PREMIUM) {
          yearArray.push(child?.year);
        }
      });
    }

    return yearArray;
  };

  const data: DataType[] = oldLicencesInfo?.map((licence, index) => {

    const getViewYears = () => {
      switch (licence.attributes?.licenceType) {
        case LicenceEnum.FREE:
          return licence.attributes?.year;
        case LicenceEnum.STANDARD:
          return getBasicYears(licence)?.length ? getBasicYears(licence).sort()?.reverse()?.join(', ') : '—';
        case LicenceEnum.PREMIUM:
          return getPremiumYears(licence)?.length ? getPremiumYears(licence).sort()?.reverse()?.join(', ') : '—';
        default:
          return '—';
      }
    };

    return {
      key: licence?.id?.toString(),
      year: licence?.attributes?.year,
      model: licence?.attributes?.licenceType,
      yearView: getViewYears(),
      printSub: licence?.attributes?.biltenSubscription ? 'Da' : 'Ne',
      expirationDate: licence?.attributes?.to ? moment(licence?.attributes?.to).format(DisplayDateFormat) : '—',
      paymentDate: licence?.attributes?.paymentDate ? moment(licence?.attributes?.paymentDate).format(DisplayDateFormat) : '—',
      totalEducations: licence?.attributes?.educationsNum ? licence?.attributes?.educationsNum.toString() : '—',
      usedEducations: licence?.attributes?.usedEducations ? licence?.attributes?.usedEducations.toString() : '—',
      totalQuestions: licence?.attributes?.questionsNum ? licence?.attributes?.questionsNum.toString() : '—',
      usedQuestions: licence?.attributes?.usedQuestions ? licence?.attributes?.usedQuestions.toString() : '—',
      totalNotes: licence?.attributes?.notesNum ? licence?.attributes?.notesNum.toString() : '—',
      active: licence?.attributes?.active ? 'Da' : 'Ne'
    };
  }) as DataType[];

  const columns: ColumnsType<DataType> = [
    {
      title: i18n.translate(`adminProfile.table.year`),
      dataIndex: 'year',
      key: 'year',
      fixed: 'left',
      width: '80px',
    },
    {
      title: i18n.translate(`adminProfile.table.subModel`),
      dataIndex: 'model',
      key: 'model',
      render: (text: any, record: any, index: any) => {
        return i18n.translate(`common.licenceType.${text}`);
      },
      //width: '130px',
    },
    {
      title: 'Pretplatne godine',
      dataIndex: 'yearView',
      key: 'yearView',
      //width: '130px',
    },
    {
      title: i18n.translate(`adminProfile.table.printSub`),
      dataIndex: 'printSub',
      key: 'printSub',
      //width: '100px',
    },
    {
      title: i18n.translate(`adminProfile.table.expirationDate`),
      dataIndex: 'expirationDate',
      key: 'expirationDate',
      //width: '130px',
    },
    {
      title: 'Datum plaćanja',
      dataIndex: 'paymentDate',
      key: 'paymentDate',
      //width: '130px',
    },
    {
      title: 'Ukupno predavanja',
      dataIndex: 'totalEducations',
      //width: '40%',
    },
    {
      title: 'Iskorišteno predavanja',
      dataIndex: 'usedEducations',
      //width: '40%',
    },
    {
      title: 'Ukupno pitanja',
      dataIndex: 'totalQuestions',
      //width: '40%',
    },
    {
      title: 'Iskorišteno pitanja',
      dataIndex: 'usedQuestions',
      //width: '40%',
    },
    {
      title: 'Ukupno bilješki',
      dataIndex: 'totalNotes',
      //width: '40%',
    },
    {
      title: 'Aktivan',
      dataIndex: 'active',
      key: 'active',
      //width: '130px',
    },
  ];

  const columnsUser: ColumnsType<DataType> = [
    {
      title: i18n.translate(`adminProfile.table.year`),
      dataIndex: 'year',
      key: 'year',
      fixed: 'left',
      width: '80px',
    },
    {
      title: i18n.translate(`adminProfile.table.subModel`),
      dataIndex: 'model',
      key: 'model',
      render: (text: any, record: any, index: any) => {
        return i18n.translate(`common.licenceType.${text}`);
      },
      //width: '130px',
    },
    {
      title: 'Pretplatne godine',
      dataIndex: 'yearView',
      key: 'yearView',
      //width: '130px',
    },
    {
      title: i18n.translate(`adminProfile.table.printSub`),
      dataIndex: 'printSub',
      key: 'printSub',
      //width: '100px',
    },
    {
      title: i18n.translate(`adminProfile.table.expirationDate`),
      dataIndex: 'expirationDate',
      key: 'expirationDate',
      //width: '130px',
    },
    {
      title: 'Ukupno predavanja',
      dataIndex: 'totalEducations',
      //width: '40%',
    },
    {
      title: 'Iskorišteno predavanja',
      dataIndex: 'usedEducations',
      //width: '40%',
    },
    {
      title: 'Ukupno pitanja',
      dataIndex: 'totalQuestions',
      //width: '40%',
    },
    {
      title: 'Iskorišteno pitanja',
      dataIndex: 'usedQuestions',
      //width: '40%',
    },
    {
      title: 'Ukupno bilješki',
      dataIndex: 'totalNotes',
      //width: '40%',
    },
    {
      title: 'Aktivan',
      dataIndex: 'active',
      key: 'active',
      //width: '130px',
    },
  ];

  const sortByYears = (data: Array<DataType>): any => {
    return data.sort((a, b) => parseInt(b.year) - parseInt(a.year));
  };

  return <Table columns={userAuth.user?.roles === UserRoleEnum.ADMIN ? columns : columnsUser} dataSource={sortByYears(data)} pagination={false} scroll={{ x: 1500 }} locale={{ emptyText: i18n.t('common.noData'), triggerDesc: i18n.t('common.sortDesc'), triggerAsc: i18n.t('common.sortAsc'), cancelSort: i18n.t('common.cancelSort')}} />;
}
