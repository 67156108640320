import { Button, Col, Divider, Image, Row, Space, Spin, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { ExternalService } from '../../../api';
import { EducationInterface } from '../../../interfaces';
import { i18n } from '../../../services';
import { EducationCardComponent } from '../../index';

const { Title } = Typography;

interface Props {}

export default function EducationsComponent({}: Props) {
  const [educationData, setEducationData] = useState<EducationInterface[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    ExternalService.getEducations().subscribe(
      (response: EducationInterface[]) => {
        let now = new Date().valueOf();
        let data: Array<{ date: string; title: string; paload: EducationInterface }> = response.map((x) => ({
          date: x.acf.start_date,
          title: x.title.rendered,
          paload: x,
        }));

        data.sort((a, b) => {
          return new Date(a?.date as string).getTime() - new Date(b?.date as string).getTime();
        });

        data = data.filter((x) => new Date(x?.date as string).getTime() >= now);

        setEducationData(data.slice(0, 4).map((x) => x.paload));
        setLoading(false);
      },
      (error: Error) => {
        // notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!educationData) return <></>;

  return (
    <Spin spinning={loading}>
      <div className="educations">
        <div className="section">
          <Row justify="space-between" align="bottom">
            <Col>
              <Title className="title" level={3}>
                {i18n.translate('dashboard.educations.title')}
              </Title>
            </Col>
            <Col>
              <Image
                preview={false}
                width={150}
                alt="Temporis logo"
                src={require('../../../../assets/images/temporisLogo.png')}
              />
            </Col>
          </Row>

          <Divider />
          {educationData.map((education, i) => (
            <EducationCardComponent key={'education' + i} education={education} />
          ))}
          <Space align="center">
            <a href="https://www.temporis.hr/edukacije/" target="_blank" rel="noopener noreferrer">
              <Button type="primary" className="cardBtn">
                {i18n.translate('dashboard.educations.register')}
              </Button>
            </a>
          </Space>
        </div>
      </div>
    </Spin>
  );
}
