import { Dispatch } from 'redux';
import { notification } from 'antd';
import { UserActivityLogConstants } from './userActivityLog.constants';

import { UserActivityLogService } from '../../api/api.service';
import { UserActivityLogResponseInterfaceAll, UserActivityLogResponseInterfaceSingle } from '../../interfaces';
import { i18n } from '../../services';

export function getAllUserActivityLogsAction(userId?: number | string, filter?: any, sort?: any, pagination?: any) {
  return (dispatch: Dispatch) => {
    return UserActivityLogService.getAll(userId, filter, sort, pagination).subscribe(
      (response: UserActivityLogResponseInterfaceAll) => {
        dispatch({
          type: UserActivityLogConstants.GET_ALL_SUCCESS,
          payload: response,
        });
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });

        dispatch({
          type: UserActivityLogConstants.ERROR,
          payload: error,
        });
      }
    );
  };
}

export function getSingleUserActivityLogAction(id: number) {
  return (dispatch: Dispatch) => {
    return UserActivityLogService.getSingle(id).subscribe(
      (response: UserActivityLogResponseInterfaceSingle) => {
        dispatch({
          type: UserActivityLogConstants.GET_SINGLE_SUCCESS,
          payload: response,
        });
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });

        dispatch({
          type: UserActivityLogConstants.ERROR,
          payload: error,
        });
      }
    );
  };
}

export function createUserActivityLogAction(body: UserActivityLogResponseInterfaceSingle) {
  return (dispatch: Dispatch) => {
    return UserActivityLogService.create(body).subscribe(
      (response: UserActivityLogResponseInterfaceSingle) => {
        notification['success']({ message: i18n.translate('api.successMessage'), duration: 2 });

        dispatch({
          type: UserActivityLogConstants.CREATE_SUCCESS,
          payload: response,
        });
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });

        dispatch({
          type: UserActivityLogConstants.ERROR,
          payload: error,
        });
      }
    );
  };
}

export function updateUserActivityLogAction(body: UserActivityLogResponseInterfaceSingle, hideSuccess?: boolean) {
  return (dispatch: Dispatch) => {
    return UserActivityLogService.update(body).subscribe(
      (response: UserActivityLogResponseInterfaceSingle) => {
        if (!hideSuccess) notification['success']({ message: i18n.translate('api.successMessage'), duration: 2 });

        dispatch({
          type: UserActivityLogConstants.UPDATE_SUCCESS,
          payload: response,
        });
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });

        dispatch({
          type: UserActivityLogConstants.ERROR,
          payload: error,
        });
      }
    );
  };
}

export function deleteUserActivityLogAction(id: number) {
  return (dispatch: Dispatch) => {
    return UserActivityLogService.delete(id).subscribe(
      (response: any) => {
        dispatch({
          type: UserActivityLogConstants.DELETE
        });
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });

        dispatch({
          type: UserActivityLogConstants.ERROR,
          payload: error,
        });
      }
    );
  };
}

export function clearUserActivityLogAction() {
  return (dispatch: Dispatch) => {
    dispatch({ type: UserActivityLogConstants.CLEAR_CURRENT });
  };
}

export function clearUserActivityLogListAction() {
  return (dispatch: Dispatch) => {
    dispatch({ type: UserActivityLogConstants.CLEAR_LIST });
  };
}
