import { Divider, Space, Typography } from 'antd';
import moment from 'moment';
import React from 'react';
import { DisplayDateFormat } from '../../../constants';
import { EducationInterface } from '../../../interfaces';

const { Text } = Typography;

interface Props {
  education?: EducationInterface;
}

export default function EducationCardComponent({ education }: Props) {

  return (
    <div className="educationCard">
      <Space>
        {' '}
        <a href={education?.link} target="_blank" className='hover-efect' rel="noopener noreferrer">
          <Text className="dateText">{moment(education?.acf.start_date).format(DisplayDateFormat)}</Text>{" "}<Text strong>{education?.title.rendered}</Text>
        </a>
      </Space>
      <Divider />
    </div>
  );
}
