import { Button, Card, Col, Row, Typography } from 'antd';
import { CheckCircleFilled } from '@ant-design/icons';
import React from 'react';

const { Title } = Typography;

interface Props {
  title: string;
  btnText: string;
  active?: boolean;
  onClick: any;
  isFree?: boolean;
}

export default function SubscriptionCardComponent({ title, btnText, active, onClick, isFree }: Props) {
  return (
    <div className="subscriptionCard">
      <Card
        bordered
        hoverable
        style={{ width: 360, height: 160, borderRadius: 0 }}
        className={active ? 'active' : 'inactive'}
      >
        <Col>
          <Row>
            {' '}
            <Title level={3}>{title}</Title>
            {active ? <CheckCircleFilled className="icon" /> : null}
          </Row>
          <Row style={{ justifyContent: 'end' }}>
            {!isFree ? <Button type='primary' onClick={onClick}>
              {btnText}
            </Button> : null}
          </Row>
        </Col>
      </Card>
    </div>
  );
}
