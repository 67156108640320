import { UserLogStatisticsConstants } from './userLogStatistics.constants';
import { UserLogStatisticsResponseInterfaceAll, ReducerActionInterface } from '../../interfaces';

// ============ INTERFACE ============

export interface UserLogStatisticsStateInterface {
  userLogStatistics?: {
    data: UserLogStatisticsResponseInterfaceAll["content"],
    meta: {
      totalResourceCount: UserLogStatisticsResponseInterfaceAll["totalElements"]
    }
  };
  error?: string;
}

// ============ INIT STATE ============

const initialState: UserLogStatisticsStateInterface = {
  userLogStatistics: {
    data: [],
    meta: {
      totalResourceCount: 0
    }
  }
}

// ============ REDUCERS ============

const getAllSuccess = (state: UserLogStatisticsStateInterface, payload: UserLogStatisticsResponseInterfaceAll): UserLogStatisticsStateInterface => {
  return {
    ...state,
    userLogStatistics: {
      data: payload.content,
      meta: {
        totalResourceCount: payload.totalElements
      }
    }
  };
};

const clearList = (state: UserLogStatisticsStateInterface): UserLogStatisticsStateInterface => {
  return {
    ...state,
    userLogStatistics: {
      data: [],
      meta: {
        totalResourceCount: 0
      }
    }
  };
};

const error = (state: UserLogStatisticsStateInterface, payload: string) => {
  return {
    ...state,
    error: payload,
  };
};


// ============ EXPORTS ============

export const UserLogStatisticsReducer = (state = initialState, action: ReducerActionInterface) => {
  switch (action.type) {
    case UserLogStatisticsConstants.GET_ALL_SUCCESS:
      return getAllSuccess(state, action.payload);
    case UserLogStatisticsConstants.CLEAR_LIST:
      return clearList(state);
    case UserLogStatisticsConstants.ERROR:
      return error(state, action.payload);
    default:
      return state;
  }
};
