import { i18n } from "../services";

export enum UserActionTypeEnum {
  LOGIN = "LOGIN",
  LOGOUT = "LOGOUT",
  CATEGORY_ACCESSED = "CATEGORY_ACCESSED",
  FILTERS_USED = "FILTERS_USED",
  FILE_OPENED = "FILE_OPENED",
  FILE_DOWNLOADED = "FILE_DOWNLOADED",
  NOTE_CREATED = "NOTE_CREATED",
  INSTITUTION_VISITED = "INSTITUTION_VISITED",
}

export const getUserActionLabel = (actionType: string) => {
  switch (actionType) {
    case UserActionTypeEnum.LOGIN:
      return i18n.t('userActionTypeEnum.LOGIN');
    case UserActionTypeEnum.LOGOUT:
      return i18n.t('userActionTypeEnum.LOGOUT');
    case UserActionTypeEnum.CATEGORY_ACCESSED:
      return i18n.t('userActionTypeEnum.CATEGORY_ACCESSED');
    case UserActionTypeEnum.FILTERS_USED:
      return i18n.t('userActionTypeEnum.FILTERS_USED');
    case UserActionTypeEnum.FILE_OPENED:
      return i18n.t('userActionTypeEnum.FILE_OPENED');
    case UserActionTypeEnum.FILE_DOWNLOADED:
      return i18n.t('userActionTypeEnum.FILE_DOWNLOADED');
    case UserActionTypeEnum.NOTE_CREATED:
      return i18n.t('userActionTypeEnum.NOTE_CREATED');
    case UserActionTypeEnum.INSTITUTION_VISITED:
      return i18n.t('userActionTypeEnum.INSTITUTION_VISITED');
    default:
      return '';
  }
}

export const translateDomainString = (text: string) => {
  switch (text) {
    case 'dashboard':
      return 'Početna';
    case 'newsletters':
      return 'Bilten - svi brojevi';
    case 'faq':
      return 'Česta pitanja';
    case 'institutional-opinions':
      return 'Mišljenja institucija';
    case 'expert-articles':
      return 'Stručni članci';
    case 'regulations':
      return 'Propisi';
    case 'workflows':
      return 'Hodogrami';
    case 'dkom-decisions':
      return 'Odluke DKOM-a';
    case 'vus-declarations':
      return 'Presude VUS-a';
    case 'eu-court-declarations':
      return 'Presude Suda EU';
    case 'professional-materials':
      return 'Stručni materijali';
    case 'article':
      return 'Stručni članci';
    case 'eu_court':
      return 'Presude Suda EU';
    case 'opinion':
      return 'Mišljenja institucija';
    case 'newsletter':
      return 'Bilten - svi brojevi';
    case 'regulation':
      return 'Propisi';
    case 'roadmap':
      return 'Hodogrami';
    case 'decision':
      return 'Odluke DKOM-a';
    case 'verdict':
      return 'Presude VUS-a';
    case 'pro_material':
      return 'Stručni materijali';
    case 'court_eu':
      return 'Presude Suda EU';
    case 'verdict_summary':
      return 'Sažetci presude';
    case 'verdict_response':
      return 'Odgovori na tužbu';
    case 'verdict_prosecution_order':
      return 'Tužbeni navodi';
    case 'decision_summary':
      return 'Sažetci odluka';
    case 'decision_appeal_response':
      return 'Odgovori na žalbu';
    case 'decision_appeal_allegation':
      return 'Žalbeni navodi';
    case 'professional_article':
      return 'Stručni članci';
    case 'opinion':
      return 'Mišljenja institucija';
    case 'frequent_questions':
      return 'Česta pitanja';
    case 'bilten':
      return 'Bilten - svi brojevi';
    default:
      return '—';
  }
};