import { ProfessionalArticleConstants } from './professionalArticle.constants';
import { ProfessionalArticleInterface, ProfessionalArticleResponseInterfaceAll, ProfessionalArticleResponseInterfaceSingle, ReducerActionInterface } from '../../interfaces';

// ============ INTERFACE ============

export interface ProfessionalArticleStateInterface {
  currentProfessionalArticle?: ProfessionalArticleInterface;
  professionalArticles?: ProfessionalArticleResponseInterfaceAll;
  error?: string;
}

// ============ INIT STATE ============

const initialState: ProfessionalArticleStateInterface = {
  currentProfessionalArticle: undefined,
  professionalArticles: {
    data: [],
    meta: {
      totalResourceCount: 0
    }
  }
}

// ============ REDUCERS ============

const getSingleSuccess = (state: ProfessionalArticleStateInterface, payload: ProfessionalArticleResponseInterfaceSingle): ProfessionalArticleStateInterface => {
  return {
    ...state,
    currentProfessionalArticle: payload.data,
  };
};

const getAllSuccess = (state: ProfessionalArticleStateInterface, payload: ProfessionalArticleResponseInterfaceAll): ProfessionalArticleStateInterface => {
  return {
    ...state,
    professionalArticles: {
      data: payload.data,
      meta: payload.meta
    }
  };
};

const createSuccess = (state: ProfessionalArticleStateInterface, payload: ProfessionalArticleResponseInterfaceSingle): ProfessionalArticleStateInterface => {
  return {
    ...state,
    currentProfessionalArticle: payload.data,
  };
};

const updateSuccess = (state: ProfessionalArticleStateInterface, payload: ProfessionalArticleResponseInterfaceSingle): ProfessionalArticleStateInterface => {
  return {
    ...state,
    currentProfessionalArticle: payload.data,
  };
};

const clearCurrent = (state: ProfessionalArticleStateInterface): ProfessionalArticleStateInterface => {
  return {
    ...state,
    currentProfessionalArticle: undefined,
  };
};

const clearList = (state: ProfessionalArticleStateInterface): ProfessionalArticleStateInterface => {
  return {
    ...state,
    professionalArticles: {
      data: [],
      meta: {
        totalResourceCount: 0
      }
    }
  };
};

const deleteSuccess = (state: ProfessionalArticleStateInterface): ProfessionalArticleStateInterface => {
  return {
    ...state,
    currentProfessionalArticle: undefined,
  };
};

const error = (state: ProfessionalArticleStateInterface, payload: string) => {
  return {
    ...state,
    error: payload,
  };
};

// ============ EXPORTS ============

export const ProfessionalArticleReducer = (state = initialState, action: ReducerActionInterface) => {
  switch (action.type) {
    case ProfessionalArticleConstants.GET_SINGLE_SUCCESS:
      return getSingleSuccess(state, action.payload);
    case ProfessionalArticleConstants.GET_ALL_SUCCESS:
      return getAllSuccess(state, action.payload);
    case ProfessionalArticleConstants.CREATE_SUCCESS:
      return createSuccess(state, action.payload);
    case ProfessionalArticleConstants.UPDATE_SUCCESS:
      return updateSuccess(state, action.payload);
    case ProfessionalArticleConstants.CLEAR_CURRENT:
      return clearCurrent(state);
    case ProfessionalArticleConstants.CLEAR_LIST:
      return clearList(state);
    case ProfessionalArticleConstants.DELETE:
      return deleteSuccess(state);
    case ProfessionalArticleConstants.ERROR:
      return error(state, action.payload);
    default:
      return state;
  }
};
