import { Button, Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { clearDecisionAction, getSingleDecisionAction, i18n, NavigationService } from '../../../../../common';
import { AdminRoutes } from '../../../_router/admin.routes';
import DKOMAllegationsCrudPageComponent from './pages/allegations/dkomAllegationsCrudPage.component';
import DKOMAppealsCrudPageComponent from './pages/appeals/dkomAppealsCrudPage.component';
import DKOMDecisionsCrudPageComponent from './pages/decisions/dkomDecisionsCrudPage.component';
import DKOMSummarysCrudPageComponent from './pages/summarys/dkomSummarysCrudPage.component';

function DKOMMainCrudPageComponent() {
  const location = useLocation();

  const [selectedTab, setSelectedTab] = useState('decision');
  const [enableTabs, setEnableTabs] = useState(false);
  const [id, setId] = useState<number | undefined>();

  const dispatch = useDispatch();

  useEffect(() => {
    const path = location.pathname.split('/');
    const id = parseInt(path[path.length - 1]);
    const isNew = isNaN(id);

    if (isNew) return;

    setId(id);
    dispatch(getSingleDecisionAction(id));
    setEnableTabs(true);
  }, []);

  const clearCurrentDecision = () => {
    dispatch(clearDecisionAction());
  };

  const decisionCreated = (decisionId: number) => {
    setId(id);
    dispatch(getSingleDecisionAction(decisionId));
    setEnableTabs(true);
    setSelectedTab('allegation');
  };
  
  return (
    <div className="dkomMainCrud w100-h100">
      <div className="dkomDecisionsCrud__admin">
        <div className="title">
          {i18n.translate(!id ? 'decisions.crudTitleAdd' : 'decisions.crudTitleUpdate')}
        </div>
        <div className="buttons">
          <Link to={AdminRoutes.DKOM_DECISIONS.fullPath} key={AdminRoutes.DKOM_DECISIONS.path}>
            <Button
              onClick={clearCurrentDecision}
              className="button"
              size="large"
              style={{ fontSize: 16, fontWeight: 700, borderRadius: 0, minWidth: 193, marginRight: 16 }}
            >
              {i18n.translate('genericButtons.cancel')}
            </Button>
          </Link>
        </div>
      </div>
      <Tabs activeKey={selectedTab} onChange={(activeKey) => setSelectedTab(activeKey)} destroyInactiveTabPane>
        <Tabs.TabPane tab={i18n.translate('decisions.tabs.decision')} key="decision">
          <DKOMDecisionsCrudPageComponent setTabAllegation={decisionCreated} />
        </Tabs.TabPane>
        <Tabs.TabPane disabled={!enableTabs} tab={i18n.translate('decisions.tabs.allegation')} key="allegation">
          <DKOMAllegationsCrudPageComponent setTabAppeal={() => setSelectedTab('appeal')} />
        </Tabs.TabPane>
        <Tabs.TabPane disabled={!enableTabs} tab={i18n.translate('decisions.tabs.appeal')} key="appeal">
          <DKOMAppealsCrudPageComponent setTabSummary={() => setSelectedTab('summary')} />
        </Tabs.TabPane>
        <Tabs.TabPane disabled={!enableTabs} tab={i18n.translate('decisions.tabs.summary')} key="summary">
          <DKOMSummarysCrudPageComponent />
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
}

export default DKOMMainCrudPageComponent;
