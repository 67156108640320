import React, { useEffect, useState } from 'react';
import {
  clearRoadmapAction,
  clearRoadmapListAction,
  FilterComponent,
  FullOrganizationUnitResponseInterfaceSingle,
  getAllRoadmapsAction,
  getScreenTagsByTypeAction,
  i18n,
  LicenceInterface,
  LicenceResponseInterfaceAll,
  LicenceResponseInterfaceSingle,
  LicenceService,
  LicenceUserResponseInterfaceAll,
  LicenceUserService,
  NoDataComponent,
  OrganizationUnitService,
  RoadmapEsResponseInterface,
  RoadmapInterface,
  RoadmapService,
  RoadmapStateInterface,
  StoreStateInterface,
  TagScreenStateInterface,
} from '../../../common';
import { HomeOutlined } from '@ant-design/icons';
import { Breadcrumb, notification } from 'antd';
import WorkflowCardComponent from '../../../common/components/cards/workflowCards/workflowCard.component';
import { useDispatch, useSelector } from 'react-redux';
import { LicenceEnum } from '../../../common/enums/licence.enum';
import moment from 'moment';

function WorkflowsPageComponent() {
  const userAuth = useSelector((state: StoreStateInterface) => state.auth);

  const dispatch = useDispatch();
  const data: RoadmapStateInterface = useSelector((state: StoreStateInterface) => state.roadmap);
  const tags: TagScreenStateInterface = useSelector((state: StoreStateInterface) => state.tagScreen);

  const [filterValues, setFilterValues] = useState(undefined);

  const [esData, setEsData] = useState<RoadmapEsResponseInterface>();

  const [keyword, setKeyword] = useState('');
  //const [allOpinions, setAllOpinions] = useState(true);
  const [exactMatch, setExactMatch] = useState<boolean>(true);

  const [esSearch, setEsSearch] = useState(true);

  const [orgUnitInfo, setOrgUnitInfo] = useState<FullOrganizationUnitResponseInterfaceSingle>();
  const [licenceData, setLicenceData] = useState<LicenceInterface>();
  const [allValidLicences, setAllValidLicences] = useState<LicenceInterface[]>([]);

  useEffect(() => {
    if (userAuth.user?.organizationUnit?.id) {
      OrganizationUnitService.getSingle(userAuth.user?.organizationUnit?.id).subscribe(
        (response: FullOrganizationUnitResponseInterfaceSingle) => {
          setOrgUnitInfo(response);
        },
        (error: Error) => {
          notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
        }
      );
    }
  }, [userAuth.user]);

  useEffect(() => {
    if (orgUnitInfo?.data?.id) {
      LicenceService.getAll(undefined, `filter[organizationUnit.id]=${orgUnitInfo?.data?.id}`, 'sort=id', `page[offset]=0&page[limit]=999999`).subscribe(
        (response: LicenceResponseInterfaceAll) => {
          if (response?.data?.length) {
            let validLicences: LicenceInterface[] = [];

            response?.data?.forEach(licence => {
              if (licence.attributes?.licenceUsers?.find(x => x.user?.id == userAuth.user?.id) && !licence.relationships?.licenceParent?.data) {
                setLicenceData(licence);
              }
              if (licence.attributes?.licenceUsers?.find(x => x.user?.id == userAuth.user?.id) && !licence.relationships?.licenceParent?.data && licence.attributes?.active && licence?.attributes?.licenceType !== LicenceEnum.FREE && moment().isBefore(moment(licence?.attributes?.to).startOf('day'))) {
                validLicences.push(licence);
              }
            });

            setAllValidLicences([...validLicences]);
          }
        },
        (error: Error) => {
          notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
        }
      );
    }
  }, [orgUnitInfo?.data]);

  useEffect(() => {
    localStorage.removeItem('transferFilters');
    dispatch(clearRoadmapAction());
    dispatch(clearRoadmapListAction());
    dispatch(getScreenTagsByTypeAction('roadmap'));
    //dispatch(getAllRoadmapsAction());
    esSearchFunction(false, {from: 0, size: 9999});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const esSearchFunction = (allOpinions: boolean, pageSorted: {from: number, size: number}, keyword?: string, tagIds?: any) => {
    RoadmapService.elasticSearch(allOpinions, pageSorted, exactMatch, keyword, tagIds).subscribe(
      (response: RoadmapEsResponseInterface) => {
        setEsData(response);
      },
      (error: any) => {
        if (error.errorId == 23) {
          notification['warning']({ message: i18n.translate('common.licence.noFilter'), duration: 2 });
        }
        else {
          notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
        }
      }
    );
  };

  const refetch = (allValues: any) => {
    if (!allValidLicences.some(x => x.attributes?.licenceType === LicenceEnum.STANDARD) && !allValidLicences.some(x => x.attributes?.licenceType === LicenceEnum.PREMIUM)) {
      notification['warning']({ message: i18n.translate('common.licence.noFilter'), duration: 4 });
      return;
    }
    //setEsSearch(true);
    setFilterValues(allValues);
    esSearchFunction(false, {from: 0, size: 9999}, keyword, allValues);
  };

  const resetSearch = () => {
    setEsData(undefined);
    setKeyword('');
    //setAllOpinions(true);
    //setEsSearch(false);
    //dispatch(getAllRoadmapsAction());
    esSearchFunction(false, {from: 0, size: 9999});
  };

  const keywordChanged = (value: string) => {
    setKeyword(value);
  };

  const exactMatchChanged = (exactMatch: boolean) => {
    setExactMatch(exactMatch);
  };

  const convertDataType = (roadmaps: RoadmapEsResponseInterface["hits"]): RoadmapInterface[] => {
    return roadmaps.map((roadmap) => {
      return {
        id: roadmap.id,
        type: roadmap.index,
        attributes: roadmap.source
      };
    })
  };

  return (
    <div className="workflows w100-h100">
      <div className="workflows__breadcrumbs">
        <Breadcrumb>
          <Breadcrumb.Item>
            <HomeOutlined />
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span>{i18n.translate('breadcrumbs.web-of-knowledge')}</span>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span>{i18n.translate('menu.workflows')}</span>
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="workflows__title">{i18n.translate('workflows.title')}</div>
      {tags.tagScreens && tags.tagScreens.length ? (
        <FilterComponent aggregation={esData?.aggregation?.buckets} filterAccess onExactMatchChange={exactMatchChanged} keyword={keyword} filters={tags.tagScreens} onFilterChange={refetch} onFilterRemove={resetSearch} onKeywordChange={keywordChanged} />
      ) : null}
      {/**<YearDividerComponent title={' '} />*/}
      {esSearch &&
        <>
        {esData?.hits?.length ? [...(convertDataType(esData?.hits as RoadmapEsResponseInterface["hits"])) as RoadmapInterface[]].map((roadmap, i) => (
          <WorkflowCardComponent key={'roadmap' + i} licenceData={licenceData} validLicences={allValidLicences} workflow={roadmap} maxLength={esData?.totalHits as number} />
        )) : <NoDataComponent />}
        </>
      }
      {!esSearch &&
        <>
          {data.roadmaps?.data?.length ? [...data?.roadmaps?.data as RoadmapInterface[]].map((roadmap, i) => (
            <WorkflowCardComponent key={'roadmap' + i} licenceData={licenceData} validLicences={allValidLicences} workflow={roadmap} maxLength={data.roadmaps?.meta?.totalResourceCount as number} />
          )) : <NoDataComponent />}
        </>
      }
    </div>
  );
}

export default WorkflowsPageComponent;
