export enum LicenceConstants {
  GET_ALL_SUCCESS = 'LicenceConstants_GET_ALL_SUCCESS',
  GET_SINGLE_SUCCESS = 'LicenceConstants_GET_SINGLE_SUCCESS',
  CREATE_SUCCESS = 'LicenceConstants_CREATE_SUCCESS',
  UPDATE_SUCCESS = 'LicenceConstants_UPDATE_SUCCESS',
  CLEAR_CURRENT = 'LicenceConstants_CLEAR_CURRENT',
  CLEAR_LIST = 'LicenceConstants_CLEAR_LIST',
  DELETE = 'LicenceConstants_DELETE',
  ERROR = 'LicenceConstants_ERROR'
}
