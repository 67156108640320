import React, { useEffect, useState } from 'react';
import { Button, DatePicker, Form, InputNumber, Modal, Select, Typography } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import TextArea from 'antd/lib/input/TextArea';
import {
  BiltenInterface,
  PrintEditionInterface,
  PrintEditionResponseInterfaceSingle,
} from '../../../interfaces';
import { i18n } from '../../../services';
import { useDispatch, useSelector } from 'react-redux';
import { createPrintEditionAction, updatePrintEditionAction } from '../../../redux/printEditions';
import localeEN from 'antd/es/date-picker/locale/en_GB';
import localeHR from 'antd/es/date-picker/locale/hr_HR';
import moment from 'moment';
import { JavaFormat } from '../../../constants';
import { StoreStateInterface } from '../../../redux';

const { Text } = Typography;
const { Option } = Select;

interface Props {
  userId: number;
  printEdition?: PrintEditionInterface;
  style: 'button' | 'link';
  biltens: BiltenInterface[];
}

export default function PrintEditionModal({ userId, printEdition, style, biltens }: Props) {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [isVisible, setIsVisible] = useState(false);

  const userAuth = useSelector((state: StoreStateInterface) => state.auth);

  useEffect(() => {
    if (printEdition) return;
    form.resetFields();
  }, [printEdition]);

  const onFinish = (values: any) => {
    let newPrint: PrintEditionInterface = {
      type: 'printed_edition',
      attributes: {
        quantity: values.quantity,
        payedAt: values.payedAt ? moment(values.payedAt).format(JavaFormat) : '',
        shippedAt: values.shippedAt ? moment(values.shippedAt).format(JavaFormat) : '',
        subscription: values.subscription,
        price: 0,
        footnote: values.footnote,
      },
      relationships: {
        user: {
          data: {
            id: userId as number,
            type: 'user',
          },
        },
        bilten: {
          data: {
            id: values.bjn,
            type: 'bilten',
          },
        },
      },
    };

    let newPrintResponse: PrintEditionResponseInterfaceSingle = {
      data: newPrint,
    };

    !printEdition
      ? dispatch(createPrintEditionAction(newPrintResponse, userId))
      : dispatch(updatePrintEditionAction(newPrintResponse, printEdition.id!, userId));
  };

  function onOk() {
    form.validateFields().then((resp) => {
      onFinish(resp);
      onCancel(); // Reset stte
    });
    
  }

  function onCancel() {
    form.resetFields();
    setIsVisible(false);
  }

  function getFirstBiltenId() {
    if (!biltens || biltens?.length === 0) return null;
    return biltens.sort((a, b) => b.id! - a.id!)[0]?.id;
  }

  const loadInitialValues = () => {
    let values: any = {
      quantity: printEdition?.attributes?.quantity || 1,
      bjn: printEdition?.relationships?.bilten?.data?.id || getFirstBiltenId(),
      subscription: !!printEdition?.attributes?.subscription,
      payedAt: printEdition?.attributes?.payedAt ? moment(printEdition?.attributes?.payedAt) : null,
      shippedAt: printEdition?.attributes?.shippedAt ? moment(printEdition?.attributes?.shippedAt) : null,
      footnote: printEdition?.attributes?.footnote,
    };

    return values;
  };

  const RenderModal = () => (
    <Modal
      title={
        <Text strong className="printModal___title">
          {i18n.translate(`profile.printEditions.${!printEdition ? 'modalTitle' : 'modalTitleEdit'}`)}
        </Text>
      }
      visible={isVisible}
      onOk={onOk}
      onCancel={onCancel}
      className="printModal"
      footer={[
        <Button key="back" onClick={onCancel}>
          {i18n.translate(`genericButtons.cancel`)}
        </Button>,

        <Button key="submit" type="primary" htmlType="submit" onClick={onOk}>
          {!printEdition ? i18n.translate(`profile.printEditions.add`) : i18n.translate(`profile.printEditions.edit`)}
        </Button>,
      ]}
      width={500}
    >
      <Form form={form} initialValues={loadInitialValues()} preserve={false} onFinish={onFinish} layout="vertical">
        <Form.Item label={i18n.translate('profile.printEditions.quantity')} name="quantity">
          <InputNumber placeholder={i18n.translate('profile.printEditions.quantity')} min={1} />
        </Form.Item>

        <Form.Item label={i18n.translate('profile.printEditions.biltenNo')} name="bjn">
          <Select>
            {biltens?.sort((a, b) => b.id! - a.id!).map((bjn) => (
              <Option key={"bilten" + bjn.id} value={bjn.id}>
                BJN {bjn.attributes?.issueNum} - {bjn.attributes?.name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label={i18n.translate('profile.printEditions.subscription')} name="subscription">
          <Select>
            <Option value={true}>{i18n.translate(`genericButtons.yes`)}</Option>
            <Option value={false}>{i18n.translate(`genericButtons.no`)}</Option>
          </Select>
        </Form.Item>

        <Form.Item label={i18n.translate('profile.printEditions.payedAt')} name="payedAt">
          <DatePicker locale={userAuth.lang == 'hr' ? localeHR : localeEN} allowClear={false} placeholder={i18n.translate('profile.printEditions.payedAt')} format={'DD.MM.YYYY.'} />
        </Form.Item>

        <Form.Item label={i18n.translate('profile.printEditions.shippedAt')} name="shippedAt">
          <DatePicker locale={userAuth.lang == 'hr' ? localeHR : localeEN} allowClear={false} placeholder={i18n.translate('profile.printEditions.shippedAt')} format={'DD.MM.YYYY.'} />
        </Form.Item>

        <Form.Item label={i18n.translate('profile.printEditions.footnote')} name="footnote">
          <TextArea rows={4} placeholder={i18n.translate('profile.printEditions.footnote')} />
        </Form.Item>
      </Form>
    </Modal>
  );

  const RenderButton = () => (
    <Button
      className="button"
      type="primary"
      size="large"
      onClick={() => setIsVisible(true)}
      style={{ fontSize: 16, fontWeight: 700, borderRadius: 0 }}
    >
      {i18n.translate('profile.printEditions.add')}
    </Button>
  );

  const RenderLink = () => <Button type="link" icon={<EditOutlined />} onClick={() => setIsVisible(true)} />;

  return (
    <>
      {style === 'link' ? <RenderLink /> : <RenderButton />}
      {isVisible && <RenderModal />}
    </>
  );
}
