import { CourtConstants } from './courtEU.constants';
import {
  CourtInterface,
  CourtResponseInterfaceAll,
  CourtResponseInterfaceSingle,
  ReducerActionInterface,
} from '../../interfaces';

// ============ INTERFACE ============

export interface CourtStateInterface {
  currentCourt?: CourtInterface;
  courts?: CourtResponseInterfaceAll;
  error?: string;
}

// ============ INIT STATE ============

const initialState: CourtStateInterface = {
  currentCourt: undefined,
  courts: {
    data: [],
    meta: {
      totalResourceCount: 0,
    },
  },
};

// ============ REDUCERS ============

const getSingleSuccess = (state: CourtStateInterface, payload: CourtResponseInterfaceSingle): CourtStateInterface => {
  return {
    ...state,
    currentCourt: payload.data,
    courts: {
      data: [payload.data],
      meta: {
        totalResourceCount: 1,
      },
    },
  };
};

const getAllSuccess = (state: CourtStateInterface, payload: CourtResponseInterfaceAll): CourtStateInterface => {
  return {
    ...state,
    courts: {
      data: payload.data,
      meta: payload.meta,
    },
  };
};

const createSuccess = (state: CourtStateInterface, payload: CourtResponseInterfaceSingle): CourtStateInterface => {
  return {
    ...state,
    currentCourt: payload.data,
  };
};

const updateSuccess = (state: CourtStateInterface, payload: CourtResponseInterfaceSingle): CourtStateInterface => {
  return {
    ...state,
    currentCourt: payload.data,
  };
};

const clearCurrent = (state: CourtStateInterface): CourtStateInterface => {
  return {
    ...state,
    currentCourt: undefined,
  };
};

const clearList = (state: CourtStateInterface): CourtStateInterface => {
  return {
    ...state,
    courts: {
      data: [],
      meta: {
        totalResourceCount: 0,
      },
    },
  };
};

const deleteSuccess = (state: CourtStateInterface): CourtStateInterface => {
  return {
    ...state,
    currentCourt: undefined,
  };
};

const error = (state: CourtStateInterface, payload: string) => {
  return {
    ...state,
    error: payload,
  };
};

// ============ EXPORTS ============

export const CourtReducer = (state = initialState, action: ReducerActionInterface) => {
  switch (action.type) {
    case CourtConstants.GET_SINGLE_SUCCESS:
      return getSingleSuccess(state, action.payload);
    case CourtConstants.GET_ALL_SUCCESS:
      return getAllSuccess(state, action.payload);
    case CourtConstants.CREATE_SUCCESS:
      return createSuccess(state, action.payload);
    case CourtConstants.UPDATE_SUCCESS:
      return updateSuccess(state, action.payload);
    case CourtConstants.CLEAR_CURRENT:
      return clearCurrent(state);
    case CourtConstants.CLEAR_LIST:
      return clearList(state);
    case CourtConstants.DELETE:
      return deleteSuccess(state);
    case CourtConstants.ERROR:
      return error(state, action.payload);
    default:
      return state;
  }
};
