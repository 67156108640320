import { Empty } from 'antd';
import React from 'react';
import { i18n } from '../../services';

interface Props {
  text?: string;
}

export default function NoDataComponent({ text }: Props) {
  return (
    <div className='noData'>
      <Empty
        description={
          <span>
            {text ? text : i18n.t('common.noData')}
          </span>
        }
      >
      </Empty>
    </div>
  );
}
