export enum AllegationConstants {
  SET_LOADER = 'AllegationConstants_SET_LOADER',
  GET_ALL_SUCCESS = 'AllegationConstants_GET_ALL_SUCCESS',
  GET_SINGLE_SUCCESS = 'AllegationConstants_GET_SINGLE_SUCCESS',
  CREATE_SUCCESS = 'AllegationConstants_CREATE_SUCCESS',
  UPDATE_SUCCESS = 'AllegationConstants_UPDATE_SUCCESS',
  CLEAR_CURRENT = 'AllegationConstants_CLEAR_CURRENT',
  CLEAR_LIST = 'AllegationConstants_CLEAR_LIST',
  DELETE = 'AllegationConstants_DELETE',
  ERROR = 'AllegationConstants_ERROR'
}
