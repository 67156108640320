export enum ProMaterialConstants {
  GET_ALL_SUCCESS = 'ProMaterialConstants_GET_ALL_SUCCESS',
  GET_SINGLE_SUCCESS = 'ProMaterialConstants_GET_SINGLE_SUCCESS',
  GET_SINGLE_ONLY = 'ProMaterialConstants_GET_SINGLE_ONLY',
  CREATE_SUCCESS = 'ProMaterialConstants_CREATE_SUCCESS',
  UPDATE_SUCCESS = 'ProMaterialConstants_UPDATE_SUCCESS',
  CLEAR_CURRENT = 'ProMaterialConstants_CLEAR_CURRENT',
  CLEAR_LIST = 'ProMaterialConstants_CLEAR_LIST',
  DELETE = 'ProMaterialConstants_DELETE',
  ERROR = 'ProMaterialConstants_ERROR'
}
