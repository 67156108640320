export enum FilterNameEnumHR {
    ZJN = "Važeći ZJN",
    YEAR = "Godina",
    REGULATION_STATUS = "Status propisa",
    PURCHASE_TYPE = "Predmet nabave",
    MATERIAL_TYPE = "Vrsta"
}

export enum FilterNameEnumEN {
    ZJN = "Valid ZJN",
    YEAR = "Year",
    REGULATION_STATUS = "Regulation Status",
    PURCHASE_TYPE = "Purchase type",
    MATERIAL_TYPE = "Vrsta"
}