import { Button, Form, Modal, notification, Popconfirm, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import {
  createSummaryAction,
  SummaryStateInterface,
  deleteSummaryAction,
  i18n,
  NavigationService,
  StoreStateInterface,
  updateSummaryAction,
  DecisionStateInterface,
  clearSummaryListAction,
  getSingleDecisionAction,
  getAllSummarysAction,
  TagScreenStateInterface,
  getScreenTagsByTypeAction,
} from '../../../../../../../common';
import { ExclamationCircleOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Link, useLocation } from 'react-router-dom';
import { AdminRoutes } from '../../../../../_router/admin.routes';
import { useDispatch, useSelector } from 'react-redux';
import TextArea from 'antd/lib/input/TextArea';

const { confirm } = Modal;

function DKOMSummarysCrudPageComponent() {

  const dispatch = useDispatch();
  const [form] = Form.useForm<any>();
  const decision: DecisionStateInterface = useSelector((state: StoreStateInterface) => state.decision);
  const data: SummaryStateInterface = useSelector((state: StoreStateInterface) => state.summary);
  const tags: TagScreenStateInterface = useSelector((state: StoreStateInterface) => state.tagScreen);

  useEffect(() => {
    dispatch(getScreenTagsByTypeAction('decision_summary'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [decision.currentDecision, data.currentSummary, data.revision]);

  useEffect(() => {
    form.resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const loadData = () => {
    dispatch(getAllSummarysAction(undefined, `filter[decision.id]=${decision.currentDecision!.id}`, 'sort=id'));
  }

  const onFinishSummary = (values: any) => {
    values.summarys.forEach((value: any, index: number) => {
      let summaryTags: Array<{ id: number; type: string }> = [];
      summaryTags = decision.currentDecision?.attributes?.tagIds?.map((id) => ({
        id: id,
        type: 'tag',
      })) as Array<{ id: number; type: string }>;
      let summaryData: any = {
        data: {
          type: 'decision_summary',
          id: data.summarys?.data[index]?.id,
          attributes: {
            titleContent: value?.titleContent,
            textContent: value?.textContent,
          },
          relationships: {
            decision: {
              data: {
                id: decision.currentDecision?.id,
                type: 'decision',
              },
            },
            tags: {
              data: summaryTags,
            },
          },
        },
      };
      
      !summaryData?.data?.id
        ? dispatch(createSummaryAction(summaryData, values?.summarys.length == index + 1))
        : dispatch(updateSummaryAction(summaryData, values?.summarys?.length == index + 1));
    });

    window.scrollTo({ top: 0, behavior: 'smooth' });

    setTimeout(() => {
      NavigationService.navigate(AdminRoutes.DKOM_DECISIONS.fullPath);
    }, 2000);
  };

  const onFinishSummaryFailed = (errorInfo: any) => {
    notification['error']({ message: i18n.translate('genericFormMsg.pleaseCheck'), duration: 2 });
    console.log('Failed:', errorInfo);
  };

  const loadInitialValues = () => {
    let values: any = data.summarys?.data?.map((summary) => ({
      titleContent: summary?.attributes?.titleContent,
      textContent: summary?.attributes?.textContent,
    }));

    return values;
  };

  const loadDefaultValues = () => {
    let values: any = [];

    return values;
  };

  const showConfirm = (key: number) => {
    confirm({
      title: i18n.t('summarys.confirmDelete'),
      icon: <ExclamationCircleOutlined />,
      content: i18n.t('common.cantUndo'),
      okText: i18n.t('genericButtons.yes'),
      cancelText: i18n.t('genericButtons.cancel'),
      onOk() {
        dispatch(deleteSummaryAction(data.summarys?.data[key]?.id as number));
        window.scrollTo({ top: 0, behavior: 'smooth' });
      },
    });
  };

  const clearSummaryList = () => {
    dispatch(clearSummaryListAction());
  };

  const addSummary = () => {
    let summaryTags: Array<{ id: number; type: string }> = [];
    summaryTags = decision.currentDecision?.attributes?.tagIds?.map((id) => ({
      id: id,
      type: 'tag',
    })) as Array<{ id: number; type: string }>;
    let summaryData: any = {
      data: {
        type: 'decision_summary',
        attributes: {
          titleContent: '',
          textContent: '',
        },
        relationships: {
          decision: {
            data: {
              id: (decision.currentDecision?.id as number),
              type: 'decision',
            },
          },
          tags: {
            data: summaryTags,
          },
        },
      },
    };

    dispatch(createSummaryAction(summaryData));
  };

  const removeRecord = () => {
    dispatch(deleteSummaryAction(data.summarys?.data[0]?.id as number));
  }

  const genExtra = (key: number) => <DeleteOutlined onClick={() => showConfirm(key)} className="button" />;

  return (
    <div className="dkomSummarysCrud w100-h100">
      {(
        <Form
          form={form}
          layout="vertical"
          name="summary"
          size="large"
          scrollToFirstError
          onFinish={onFinishSummary}
          onFinishFailed={onFinishSummaryFailed}
          autoComplete="off"
          requiredMark={false}
        >
          <div className="dkomSummarysCrud__form">
            <Form.List
              initialValue={loadInitialValues()}
              name="summarys"
            >
              {(fields) => (
                <>
                  {fields.map(({ key, name }) => (
                    <Space
                      key={key}
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: '100%',
                        marginBottom: 8,
                      }}
                      align="baseline"
                    >
                      <Form.Item
                        label={<span className="text-bold">{i18n.translate(`summarys.form.fields.titleContent`)}</span>}
                        name={[name, 'titleContent']}
                        rules={[{ required: true, message: i18n.translate(`genericFormMsg.required`) }]}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                      >
                        <TextArea autoSize={{ minRows: 2 }} />
                      </Form.Item>
                      <Form.Item
                        label={<span className="text-bold">{i18n.translate(`summarys.form.fields.textContent`)}</span>}
                        name={[name, 'textContent']}
                        rules={[{ required: true, message: i18n.translate(`genericFormMsg.required`) }]}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                      >
                        <TextArea autoSize={{ minRows: 8 }} />
                      </Form.Item>
                    </Space>
                  ))}
                </>
              )}
            </Form.List>
            {data.summarys?.data?.length === 0 ? (
              <Button style={{ marginTop: 16 }} type="link" onClick={() => addSummary()} block icon={<PlusOutlined />}>
                {i18n.translate(`summarys.crudTitleAdd`)}
              </Button>
            ) : null}
          </div>
          <div className="dkomSummarysCrud__bottomButtons">
            <div>
              {data.summarys?.data?.length !== 0 ? (
                <Popconfirm
                  title={i18n.t('common.recordRemoval')}
                  onConfirm={() => removeRecord()}
                  onCancel={() => {}}
                  okText={i18n.t('genericButtons.yes')}
                  cancelText={i18n.t('genericButtons.no')}
                  placement="left"
                >
                <Button
                      danger
                      className="button"
                      size="large"
                      style={{ fontSize: 16, fontWeight: 700, borderRadius: 0, minWidth: 193, marginRight: 16 }}
                    >
                      {i18n.translate('genericButtons.delete')}
                    </Button>
                </Popconfirm>
              ) : null}
            </div>
            <div>
              <Link to={AdminRoutes.DKOM_DECISIONS.fullPath} key={AdminRoutes.DKOM_DECISIONS.path}>
                <Button
                  onClick={clearSummaryList}
                  className="button"
                  size="large"
                  style={{ fontSize: 16, fontWeight: 700, borderRadius: 0, minWidth: 193, marginRight: 16 }}
                >
                  {i18n.translate('genericButtons.cancel')}
                </Button>
              </Link>
              <Button
                className="button"
                htmlType="submit"
                type="primary"
                size="large"
                style={{ fontSize: 16, fontWeight: 700, borderRadius: 0, minWidth: 193 }}
              >
                {i18n.translate('genericButtons.save')}
              </Button>
            </div>
          </div>
        </Form>
      )}
    </div>
  );
}

export default DKOMSummarysCrudPageComponent;
