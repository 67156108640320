import React from 'react';
import Icon, {
  HomeOutlined,
  ReadOutlined,
  QuestionCircleOutlined,
  BankOutlined,
  FileTextOutlined,
  AuditOutlined,
  ApartmentOutlined,
  UserOutlined
} from '@ant-design/icons';
import Hammer from '../../../assets/images/hammer.svg'
import Scale from '../../../assets/images/scale.svg'

const HammerIcon = () => (<img alt='' src={Hammer}/>);
const ScaleIcon = () => (<img alt='' src={Scale}/>);

export const ROOT_PATH_ADMIN = 'admin';

export const AdminRoutes = {
  DASHBOARD:                      { path: 'dashboard',                      fullPath: `/${ROOT_PATH_ADMIN}/dashboard`,              icon: <HomeOutlined />,               group: 'home',                     hide: false},
  NEWSLETTERS:                    { path: 'newsletters',                    fullPath: `/${ROOT_PATH_ADMIN}/newsletters`,            icon: <ReadOutlined />,               group: 'home',                     hide: false},
  USERS:                          { path: 'adminProfile',                   fullPath: `/${ROOT_PATH_ADMIN}/adminProfile`,           icon: <UserOutlined />,               group: 'home',                     hide: false},
  NEWSLETTERS_VIEW:               { path: 'newsletters/view',               fullPath: `/${ROOT_PATH_ADMIN}/newsletters/view`,       icon: null,                           group: null,                       hide: true},
  FAQ:                            { path: 'faq',                            fullPath: `/${ROOT_PATH_ADMIN}/faq`,                    icon: <QuestionCircleOutlined />,     group: 'web-of-knowledge',         hide: false},
  INSTITUTIONAL_OPINIONS:         { path: 'institutional-opinions',         fullPath: `/${ROOT_PATH_ADMIN}/institutional-opinions`, icon: <BankOutlined />,               group: 'web-of-knowledge',         hide: false},
  EXPERT_ARTICLES:                { path: 'expert-articles',                fullPath: `/${ROOT_PATH_ADMIN}/expert-articles`,        icon: <FileTextOutlined />,           group: 'web-of-knowledge',         hide: false},
  REGULATIONS:                    { path: 'regulations',                    fullPath: `/${ROOT_PATH_ADMIN}/regulations`,            icon: <AuditOutlined />,              group: 'web-of-knowledge',         hide: false},
  WORKFLOWS:                      { path: 'workflows',                      fullPath: `/${ROOT_PATH_ADMIN}/workflows`,              icon: <ApartmentOutlined />,          group: 'web-of-knowledge',         hide: false},
  EU_PRACTICE:                    { path: 'eu-practice',                    fullPath: `/${ROOT_PATH_ADMIN}/eu-practice`,            icon: <Icon component={ScaleIcon}/>,  group: 'legal-protection-eu',      hide: false},
  DKOM_DECISIONS:                 { path: 'dkom-decisions',                 fullPath: `/${ROOT_PATH_ADMIN}/dkom-decisions`,         icon: null,                           group: 'legal-protection-sub-cro', hide: false},
  DKOM_APPEALS:                   { path: 'dkom-appeals',                   fullPath: `/${ROOT_PATH_ADMIN}/dkom-appeals`,           icon: null,                           group: 'legal-protection-sub-cro', hide: true},
  DKOM_ALLEGATIONS:               { path: 'dkom-allegations',               fullPath: `/${ROOT_PATH_ADMIN}/dkom-allegations`,       icon: null,                           group: 'legal-protection-sub-cro', hide: true},
  DKOM_SUMMARY:                   { path: 'dkom-summary',                   fullPath: `/${ROOT_PATH_ADMIN}/dkom-summary`,           icon: null,                           group: 'legal-protection-sub-cro', hide: true},
  VUS_DECLARATIONS:               { path: 'vus-declarations',               fullPath: `/${ROOT_PATH_ADMIN}/vus-declarations`,       icon: null,                           group: 'legal-protection-sub-cro', hide: false},
  VUS_ORDERS:                     { path: 'vus-orders',                     fullPath: `/${ROOT_PATH_ADMIN}/vus-orders`,             icon: null,                           group: 'legal-protection-sub-cro', hide: true},
  VUS_RESPONSES:                  { path: 'vus-responses',                  fullPath: `/${ROOT_PATH_ADMIN}/vus-responses`,          icon: null,                           group: 'legal-protection-sub-cro', hide: true},
  VUS_SUMMARY:                    { path: 'vus-summary',                    fullPath: `/${ROOT_PATH_ADMIN}/vus-summary`,            icon: null,                           group: 'legal-protection-sub-cro', hide: true},
  RH_PRACTICE:                    { path: 'rh-practice',                    fullPath: `/${ROOT_PATH_ADMIN}/rh-practice`,            icon: <Icon component={HammerIcon}/>, group: 'legal-protection-cro',     hide: false},
  EU_COURT_DECLARATIONS:          { path: 'eu-court-declarations',          fullPath: `/${ROOT_PATH_ADMIN}/eu-court-declarations`,  icon: null,                           group: 'legal-protection-sub-eu',  hide: false},
  PRO_MATERIALS:                  { path: 'professional-materials',         fullPath: `/${ROOT_PATH_ADMIN}/professional-materials`, icon: null,                           group: 'legal-protection-sub-eu',  hide: false},
  PROFILE:                        { path: 'adminProfile',                   fullPath: `/${ROOT_PATH_ADMIN}/adminProfile`,           icon: null,                           group: null,                       hide: false},
  USER_PROFILE:                   { path: 'profile',                        fullPath: `/${ROOT_PATH_ADMIN}/profile`,                icon: null,                           group: null,                       hide: false},
  NOTES:                          { path: 'notes',                          fullPath: `/${ROOT_PATH_ADMIN}/notes`,                  icon: null,                           group: null,                       hide: false},
  ORGANIZATION_PROFILE:           { path: 'organizationProfile',            fullPath: `/${ROOT_PATH_ADMIN}/organizationProfile`,    icon: null,                           group: null,                       hide: false},
  ORGANIZATION_UNIT_PROFILE:      { path: 'organizationUnitProfile',        fullPath: `/${ROOT_PATH_ADMIN}/organizationUnitProfile`,icon: null,                           group: null,                       hide: false},
  INSTRUCTIONS:                   { path: 'instructions',                   fullPath: `/${ROOT_PATH_ADMIN}/instructions`,           icon: null,                           group: null,                       hide: false},
  SETTINGS:                       { path: 'settings',                       fullPath: `/${ROOT_PATH_ADMIN}/settings`,               icon: null,                           group: null,                       hide: false},
  STATISTICS:                     { path: 'statistics',                     fullPath: `/${ROOT_PATH_ADMIN}/statistics`,             icon: null,                           group: null,                       hide: false}
};
