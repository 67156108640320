import { UserActivityLogConstants } from './userActivityLog.constants';
import { UserActivityLogInterface, UserActivityLogResponseInterfaceAll, UserActivityLogResponseInterfaceSingle, ReducerActionInterface } from '../../interfaces';

// ============ INTERFACE ============

export interface UserActivityLogStateInterface {
  currentUserActivityLog?: UserActivityLogInterface;
  userActivityLogs?: UserActivityLogResponseInterfaceAll;
  error?: string;
}

// ============ INIT STATE ============

const initialState: UserActivityLogStateInterface = {
  currentUserActivityLog: undefined,
  userActivityLogs: {
    data: [],
    included: [],
    meta: {
      totalResourceCount: 0
    }
  }
}

// ============ REDUCERS ============

const getSingleSuccess = (state: UserActivityLogStateInterface, payload: UserActivityLogResponseInterfaceSingle): UserActivityLogStateInterface => {
  return {
    ...state,
    currentUserActivityLog: payload.data,
  };
};

const getAllSuccess = (state: UserActivityLogStateInterface, payload: UserActivityLogResponseInterfaceAll): UserActivityLogStateInterface => {
  return {
    ...state,
    userActivityLogs: {
      data: payload.data,
      included: payload.included,
      meta: payload.meta
    }
  };
};

const createSuccess = (state: UserActivityLogStateInterface, payload: UserActivityLogResponseInterfaceSingle): UserActivityLogStateInterface => {
  return {
    ...state,
    currentUserActivityLog: payload.data,
  };
};

const updateSuccess = (state: UserActivityLogStateInterface, payload: UserActivityLogResponseInterfaceSingle): UserActivityLogStateInterface => {
  return {
    ...state,
    currentUserActivityLog: payload.data,
  };
};

const clearCurrent = (state: UserActivityLogStateInterface): UserActivityLogStateInterface => {
  return {
    ...state,
    currentUserActivityLog: undefined,
  };
};

const clearList = (state: UserActivityLogStateInterface): UserActivityLogStateInterface => {
  return {
    ...state,
    userActivityLogs: {
      data: [],
      included: [],
      meta: {
        totalResourceCount: 0
      }
    }
  };
};

const deleteSuccess = (state: UserActivityLogStateInterface): UserActivityLogStateInterface => {
  return {
    ...state,
    currentUserActivityLog: undefined,
  };
};

const error = (state: UserActivityLogStateInterface, payload: string) => {
  return {
    ...state,
    error: payload,
  };
};


// ============ EXPORTS ============

export const UserActivityLogReducer = (state = initialState, action: ReducerActionInterface) => {
  switch (action.type) {
    case UserActivityLogConstants.GET_SINGLE_SUCCESS:
      return getSingleSuccess(state, action.payload);
    case UserActivityLogConstants.GET_ALL_SUCCESS:
      return getAllSuccess(state, action.payload);
    case UserActivityLogConstants.CREATE_SUCCESS:
      return createSuccess(state, action.payload);
    case UserActivityLogConstants.UPDATE_SUCCESS:
      return updateSuccess(state, action.payload);
    case UserActivityLogConstants.CLEAR_CURRENT:
      return clearCurrent(state);
    case UserActivityLogConstants.CLEAR_LIST:
      return clearList(state);
    case UserActivityLogConstants.DELETE:
      return deleteSuccess(state);
    case UserActivityLogConstants.ERROR:
      return error(state, action.payload);
    default:
      return state;
  }
};
