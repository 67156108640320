import { Dispatch } from 'redux';
import { notification } from 'antd';
import { AppealConstants } from './appeal.constants';

import { AppealService } from '../../api/api.service';
import { AppealResponseInterfaceAll, AppealResponseInterfaceSingle } from '../../interfaces';
import { i18n } from '../../services';

export function getAllAppealsAction(params?: any, filter?: any, sort?: any, pagination?: any) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: AppealConstants.GET_ALL_SUCCESS,
      payload: {
        data: [],
        meta: {}
      },
    });

    return AppealService.getAll(params, filter, sort, pagination).subscribe(
      (response: AppealResponseInterfaceAll) => {
        dispatch({
          type: AppealConstants.GET_ALL_SUCCESS,
          payload: response,
        });
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });

        dispatch({
          type: AppealConstants.ERROR,
          payload: error,
        });
      }
    );
  };
}

export function getSingleAppealAction(id: number) {
  return (dispatch: Dispatch) => {
    return AppealService.getSingle(id).subscribe(
      (response: AppealResponseInterfaceSingle) => {
        dispatch({
          type: AppealConstants.GET_SINGLE_SUCCESS,
          payload: response,
        });
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });

        dispatch({
          type: AppealConstants.ERROR,
          payload: error,
        });
      }
    );
  };
}

export function createAppealAction(body: AppealResponseInterfaceSingle, showNotif: boolean = true) {
  return (dispatch: Dispatch) => {
    return AppealService.create(body).subscribe(
      (response: AppealResponseInterfaceSingle) => {
        if (showNotif) notification['success']({ message: i18n.translate('api.successMessage'), duration: 2 });

        dispatch({
          type: AppealConstants.CREATE_SUCCESS,
          payload: response,
        });
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });

        dispatch({
          type: AppealConstants.ERROR,
          payload: error,
        });
      }
    );
  };
}

export function updateAppealAction(body: AppealResponseInterfaceSingle, showNotif: boolean = true) {
  return (dispatch: Dispatch) => {
    return AppealService.update(body).subscribe(
      (response: AppealResponseInterfaceSingle) => {
        if (showNotif) notification['success']({ message: i18n.translate('api.successMessage'), duration: 2 });

        dispatch({
          type: AppealConstants.UPDATE_SUCCESS,
          payload: response,
        });
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });

        dispatch({
          type: AppealConstants.ERROR,
          payload: error,
        });
      }
    );
  };
}

export function deleteAppealAction(id: number) {
  return (dispatch: Dispatch) => {
    return AppealService.delete(id).subscribe(
      (response: any) => {
        dispatch({
          type: AppealConstants.DELETE
        });
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });

        dispatch({
          type: AppealConstants.ERROR,
          payload: error,
        });
      }
    );
  };
}

export function clearAppealAction() {
  return (dispatch: Dispatch) => {
    dispatch({ type: AppealConstants.CLEAR_CURRENT });
  };
}

export function clearAppealListAction() {
  return (dispatch: Dispatch) => {
    dispatch({ type: AppealConstants.CLEAR_LIST });
  };
}
