export enum UserActivityLogConstants {
  GET_ALL_SUCCESS = 'UserActivityLogConstants_GET_ALL_SUCCESS',
  GET_SINGLE_SUCCESS = 'UserActivityLogConstants_GET_SINGLE_SUCCESS',
  CREATE_SUCCESS = 'UserActivityLogConstants_CREATE_SUCCESS',
  UPDATE_SUCCESS = 'UserActivityLogConstants_UPDATE_SUCCESS',
  CLEAR_CURRENT = 'UserActivityLogConstants_CLEAR_CURRENT',
  CLEAR_LIST = 'UserActivityLogConstants_CLEAR_LIST',
  DELETE = 'UserActivityLogConstants_DELETE',
  ERROR = 'UserActivityLogConstants_ERROR'
}
