import { Button, Input, Popconfirm } from 'antd';
import React, { useEffect, useState } from 'react';
import { NotificationInterface } from '../../../interfaces';
import { i18n } from '../../../services';
import { EditOutlined } from '@ant-design/icons';
import { NotificationService } from '../../../api';

const { TextArea } = Input;

interface Props {
  type: 'admin' | 'user';
}

export default function NotificationsComponent({ type }: Props) {
  const [notification, setNotification] = useState<NotificationInterface>();
  
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');

  useEffect(() => {
    load();
  }, []);

  function load() {
    NotificationService.getAll(false, "filter[active]=true").subscribe((resp) => {
      if (resp?.data?.length > 0) {
        setNotification(resp?.data[0]);
      }
    });
  }

  function openEdit() {
    if (notification) {
      setTitle(notification.attributes?.title);
      setContent(notification.attributes?.content);
    } else {
      setTitle("");
      setContent("");
    }
    setIsEdit(true);
  }

  function saveEdit() {
    let n: NotificationInterface = {
      type: 'notification',
      attributes: {}
    } as any;

    if (notification) n = { ...notification };
    n.attributes.content = content;
    n.attributes.title = title;
    
    if (n.id) NotificationService.update({ data: n }).subscribe((resp) => { load(); });
    else NotificationService.create({ data: n }).subscribe((resp) => { load(); });

    setIsEdit(false);
  }

  function removeEdit() {
    if (notification) {
      let n: NotificationInterface = { ...notification } as any;
      n.attributes.active = false;
      NotificationService.update({ data: n }).subscribe((resp) => { setNotification(undefined); load(); });
    }
    setIsEdit(false);
  }

  if (type !== 'admin' && !notification) {
    return null;
  }

  if (type === 'admin' && !notification && !isEdit) {
    return (
      <div className="dashboard-notifications-new">
        <Button onClick={() => openEdit() } type="link" icon={<EditOutlined />} />
      </div>
    );
  }

  if (type === 'admin' && isEdit) {
    return (
      <div className="dashboard-notifications">
        <div className="box">
          <Input value={title} onChange={e => setTitle(e.target.value)} style={{ marginBottom: 8 }} placeholder="Naslov" />
          <TextArea value={content} onChange={e => setContent(e.target.value)} autoSize={{ minRows: 3, maxRows: 5 }} placeholder="Poruka" />
          <div className="content">
            <Button type="primary" className='mt8' onClick={() => saveEdit() }>{i18n.t('genericButtons.save')}</Button>
            <Popconfirm
              title={i18n.t('common.recordRemoval')}
              onConfirm={() => removeEdit()}
              onCancel={() => {}}
              okText={i18n.t('genericButtons.yes')}
              cancelText={i18n.t('genericButtons.no')}
              placement="left"
            >
              <Button type="link"> {i18n.t('genericButtons.delete')} </Button>
            </Popconfirm>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="dashboard-notifications">
      <div className="box">
        <div className="title">{notification?.attributes?.title}</div>
        <div className="content">
          <div>{notification?.attributes?.content}</div>
          <div style={{ textAlign: 'right'}}>
            {type === 'admin' && <Button onClick={() => openEdit() } type="link" icon={<EditOutlined />} />}
          </div>
        </div>
      </div>
    </div>
  );
}
