import { createStore, applyMiddleware, combineReducers } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { AuthReducer, RegisterReducer } from './auth';
import { FaqReducer } from './faq';
import { ProfessionalArticleReducer } from './professionalArticle';
import { BiltenReducer } from './bilten';
import { OpinionReducer } from './opinion';
import { TagScreenReducer } from './tagScreen';
import { RoadmapReducer } from './roadmap';
import { RegulationReducer } from './regulation/regulation.reducers';
import { NotesReducer } from './notes';
import { AllegationReducer } from './allegation';
import { AppealReducer } from './appeal';
import { DecisionReducer } from './decision';
import { SummaryReducer } from './summary';
import { OrganizationReducer } from './organization';
import { OrganizationUnitReducer } from './organizationUnit';
import { VerdictReducer } from './verdict';
import { OrderReducer } from './order';
import { ResponseReducer } from './response';
import { SynopsisReducer } from './synopsis';
import { CourtReducer } from './courtEU';
import { ProMaterialReducer } from './proMaterials';
import { FullUserReducer } from './fullUser';
import { LicenceReducer } from './licence';
import { LicenceUserReducer } from './licenceUser';
import { NotesLinksReducer } from './notesLinks';
import { PrintEditionReducer } from './printEditions';
import { UserActivityLogReducer } from './userActivityLog';
import { UserLogStatisticsReducer } from './userLogStatistics';

// === List of all redducers
const AllReducers = combineReducers({
  auth: AuthReducer,
  register: RegisterReducer,
  bilten: BiltenReducer,
  faq: FaqReducer,
  professionalArticle: ProfessionalArticleReducer,
  opinion: OpinionReducer,
  roadmap: RoadmapReducer,
  regulation: RegulationReducer,
  decision: DecisionReducer,
  appeal: AppealReducer,
  allegation: AllegationReducer,
  summary: SummaryReducer,
  verdict: VerdictReducer,
  order: OrderReducer,
  response: ResponseReducer,
  synopsis: SynopsisReducer,
  tagScreen: TagScreenReducer,
  notes: NotesReducer,
  organization: OrganizationReducer,
  organizationUnit: OrganizationUnitReducer,
  fullUser: FullUserReducer,
  licence: LicenceReducer,
  licenceUser: LicenceUserReducer,
  court: CourtReducer,
  proMaterial: ProMaterialReducer,
  notesLinks: NotesLinksReducer,
  printEditions: PrintEditionReducer,
  userActivityLog: UserActivityLogReducer,
  userLogStatistic: UserLogStatisticsReducer
});

export const AppReducers = (state: any, action: any): StoreStateInterface => {
  return AllReducers(state, action);
};

// === LocalSotrage persist definition
const persistConfig = {
  key: 'my-app',
  storage,
  whitelist: ['auth'],
};

const persistedReducer = persistReducer(persistConfig, AppReducers);

const initialState = {};

const middleware = [thunk];

export const store = createStore(
  persistedReducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
  //compose(applyMiddleware(...middleware))
);

export const persistor = persistStore(store);

export type StoreStateInterface = ReturnType<typeof AllReducers>;
