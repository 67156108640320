import React, { useEffect, useState } from 'react';
import {
  clearNoteAction,
  clearRegulationAction,
  clearRegulationListAction,
  FilterComponent,
  FullOrganizationUnitResponseInterfaceSingle,
  getAllRegulationsAction,
  getScreenTagsByTypeAction,
  i18n,
  LicenceInterface,
  LicenceResponseInterfaceAll,
  LicenceResponseInterfaceSingle,
  LicenceService,
  LicenceUserResponseInterfaceAll,
  LicenceUserService,
  NoDataComponent,
  NoteResponseInterfaceAll,
  NoteService,
  OrganizationUnitService,
  RegulationCardComponent,
  RegulationEsResponseInterface,
  RegulationInterface,
  RegulationService,
  RegulationStateInterface,
  StoreStateInterface,
  TagScreenStateInterface
} from '../../../common';
import { HomeOutlined } from '@ant-design/icons';
import { Breadcrumb, notification } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { LicenceEnum } from '../../../common/enums/licence.enum';
import moment from 'moment';

function RegulationsPageComponent() {
  const userAuth = useSelector((state: StoreStateInterface) => state.auth);

  const dispatch = useDispatch();
  const data: RegulationStateInterface = useSelector((state: StoreStateInterface) => state.regulation);
  const tags: TagScreenStateInterface = useSelector((state: StoreStateInterface) => state.tagScreen);

  const [esData, setEsData] = useState<RegulationEsResponseInterface>();

  const [keyword, setKeyword] = useState('');
  //const [allOpinions, setAllOpinions] = useState(true);
  const [exactMatch, setExactMatch] = useState<boolean>(true);

  const [esSearch, setEsSearch] = useState(true);

  const [orgUnitInfo, setOrgUnitInfo] = useState<FullOrganizationUnitResponseInterfaceSingle>();
  const [licenceData, setLicenceData] = useState<LicenceInterface>();
  const [allValidLicences, setAllValidLicences] = useState<LicenceInterface[]>([]);
  const [noteData, setNoteData] = useState<NoteResponseInterfaceAll>();

  useEffect(() => {
    clearNoteAction();
    NoteService.getAll(userAuth.user?.id, false).subscribe(
      (response: NoteResponseInterfaceAll) => {
        setNoteData(response); 
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
      }
    );
  }, [userAuth?.user?.id]);

  useEffect(() => {
    if (userAuth.user?.organizationUnit?.id) {
      OrganizationUnitService.getSingle(userAuth.user?.organizationUnit?.id).subscribe(
        (response: FullOrganizationUnitResponseInterfaceSingle) => {
          setOrgUnitInfo(response);
        },
        (error: Error) => {
          notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
        }
      );
    }
  }, [userAuth.user]);

  useEffect(() => {
    if (orgUnitInfo?.data?.id) {
      LicenceService.getAll(undefined, `filter[organizationUnit.id]=${orgUnitInfo?.data?.id}`, 'sort=id', `page[offset]=0&page[limit]=999999`).subscribe(
        (response: LicenceResponseInterfaceAll) => {
          if (response?.data?.length) {
            let validLicences: LicenceInterface[] = [];

            response?.data?.forEach(licence => {
              if (licence.attributes?.licenceUsers?.find((x) => x.user?.id == userAuth.user?.id) && !licence.relationships?.licenceParent?.data && licence.attributes?.notesNum > 0) {
                setLicenceData(licence);
                const standardLicence = response.data.find(
                  (x) => x.attributes?.licenceUsers?.find((y) => y.user?.id == userAuth.user?.id) && !x.relationships?.licenceParent?.data && x.attributes?.active && x.attributes?.licenceType === LicenceEnum.STANDARD && licence.attributes?.notesNum > 0 && moment().isBefore(moment(licence?.attributes?.to).startOf('day'))
                );
                const premiumLicence = response.data.find(
                  (x) => x.attributes?.licenceUsers?.find((y) => y.user?.id == userAuth.user?.id) && !x.relationships?.licenceParent?.data && x.attributes?.active && x.attributes?.licenceType === LicenceEnum.PREMIUM && licence.attributes?.notesNum > 0 && moment().isBefore(moment(licence?.attributes?.to).startOf('day'))
                );
                if (standardLicence && standardLicence.attributes?.notesNum > 0) setLicenceData(standardLicence);
                if (premiumLicence && premiumLicence.attributes?.notesNum > 0) setLicenceData(premiumLicence);
              }
              if (licence.attributes?.licenceUsers?.find(x => x.user?.id == userAuth.user?.id) && !licence.relationships?.licenceParent?.data && licence.attributes?.active && licence?.attributes?.licenceType !== LicenceEnum.FREE && moment().isBefore(moment(licence?.attributes?.to).startOf('day'))) {
                validLicences.push(licence);
              }
            });

            setAllValidLicences([...validLicences]);
          }
        },
        (error: Error) => {
          notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
        }
      );
    }
  }, [orgUnitInfo?.data]);

  useEffect(() => {
    localStorage.removeItem('transferFilters');
    dispatch(clearRegulationAction());
    dispatch(clearRegulationListAction());
    dispatch(getScreenTagsByTypeAction('regulation'));
    //dispatch(getAllRegulationsAction(undefined, undefined, undefined, `page[offset]=0&page[limit]=9999`));
    esSearchFunction(false, {from: 0, size: 9999});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const esSearchFunction = (allOpinions: boolean, pageSorted: {from: number, size: number}, keyword?: string, tagIds?: any) => {
    RegulationService.elasticSearch(allOpinions, pageSorted, exactMatch, keyword, tagIds).subscribe(
      (response: RegulationEsResponseInterface) => {
        setEsData(response);
      },
      (error: any) => {
        if (error.errorId == 23) {
          notification['warning']({ message: i18n.translate('common.licence.noFilter'), duration: 2 });
        }
        else {
          notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
        }
      }
    );
  };

  const refetch = (allValues: any) => {
    if (!allValidLicences.some(x => x.attributes?.licenceType === LicenceEnum.STANDARD) && !allValidLicences.some(x => x.attributes?.licenceType === LicenceEnum.PREMIUM)) {
      notification['warning']({ message: i18n.translate('common.licence.noFilter'), duration: 4 });
      return;
    }
    //setEsSearch(true);
    esSearchFunction(false, {from: 0, size: 9999}, keyword, allValues);
  };

  const resetSearch = () => {
    setEsData(undefined);
    setKeyword('');
    //setAllOpinions(true);
    //setEsSearch(false);
    //dispatch(getAllRegulationsAction(undefined, undefined, undefined, `page[offset]=0&page[limit]=9999`));
    esSearchFunction(false, {from: 0, size: 9999});
  };

  const keywordChanged = (value: string) => {
    setKeyword(value);
  };

  const exactMatchChanged = (exactMatch: boolean) => {
    setExactMatch(exactMatch);
  };

  const convertDataType = (regulations: RegulationEsResponseInterface["hits"]): RegulationInterface[] => {
    return regulations.map((regulation) => {
      return {
        id: regulation.id,
        type: regulation.index,
        attributes: regulation.source
      };
    })
  };

  return (
    <div className="regulations w100-h100">
      <div className="regulations__breadcrumbs">
        <Breadcrumb>
          <Breadcrumb.Item>
            <HomeOutlined />
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span>{i18n.translate('breadcrumbs.web-of-knowledge')}</span>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span>{i18n.translate('menu.regulations')}</span>
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="regulations__title">{i18n.translate('regulations.title')}</div>
      {tags.tagScreens && tags.tagScreens.length ? (
        <FilterComponent aggregation={esData?.aggregation?.buckets} filterAccess onExactMatchChange={exactMatchChanged} keyword={keyword} filters={tags.tagScreens} noYearLimit onFilterChange={refetch} onFilterRemove={resetSearch} onKeywordChange={keywordChanged} />
      ) : null}
      {esSearch &&
        <>
          {esData?.hits?.length ? (
            <RegulationCardComponent
              regulations={[...(convertDataType(esData?.hits as RegulationEsResponseInterface["hits"]) as RegulationInterface[])]}
              tags={tags.tagScreens}
              licenceData={licenceData}
              notes={noteData}
              validLicences={allValidLicences}
            />
          ) : (
            <NoDataComponent />
          )}
        </>
      }
      {!esSearch &&
        <>
          {data.regulations?.data?.length ? (
            <RegulationCardComponent
              regulations={[...(data.regulations?.data as RegulationInterface[])]}
              tags={tags.tagScreens}
              licenceData={licenceData}
              notes={noteData}
              validLicences={allValidLicences}
            />
          ) : (
            <NoDataComponent />
          )}
        </>
      }
    </div>
  );
}

export default RegulationsPageComponent;
