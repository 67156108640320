import { Button, Col, Row, Typography } from 'antd';
import moment from 'moment';
import React from 'react';
import { DisplayDateFormat } from '../../../constants';
import { NewsInterface } from '../../../interfaces';
import { i18n } from '../../../services';

const { Paragraph, Text, Title } = Typography;

interface Props {
  newsSingle?: NewsInterface;
}

export default function NewsCardComponent({ newsSingle }: Props) {
  
  function cleanText(text: string): string {
    var txt = document.createElement("textarea");
    txt.innerHTML = text;
    return txt.value.replace(/(<([^>]+)>)/gi, '');
  }

  function getTitle(): string {
    let title = newsSingle?.title?.rendered;
    if (!title) return '';
    return cleanText(title);
  }

  function getContent(): string {
    let content = newsSingle?.excerpt?.rendered;
    if (!content) return '';
    return cleanText(content);
  }

  return (
    <div className="newsCard">
      <Col flex="1 0 25%" >
        <Row>
          <Text type="secondary">{moment(newsSingle?.date).format(DisplayDateFormat)}</Text>
        </Row>
        <Row>
          <Title level={5}>{getTitle()}</Title>
        </Row>
        <Paragraph>{getContent()}</Paragraph>
        <a href={newsSingle?.link} target="_blank" className='cardBtn hover-efect' rel="noopener noreferrer">
          {i18n.translate('dashboard.news.read')}
        </a>
      </Col>
    </div>
  );
}
