import React, { useEffect, useState } from 'react';
import {
  clearNoteAction,
  clearOpinionAction,
  clearOpinionListAction,
  FilterComponent,
  FullOrganizationUnitResponseInterfaceSingle,
  getAllOpinionsAction,
  getScreenTagsByTypeAction,
  i18n,
  InstitutionalOpinionCardComponent,
  LicenceInterface,
  LicenceResponseInterfaceAll,
  LicenceResponseInterfaceSingle,
  LicenceService,
  LicenceUserResponseInterfaceAll,
  LicenceUserService,
  NoDataComponent,
  NoteResponseInterfaceAll,
  NoteService,
  OpinionEsResponseInterface,
  OpinionInterface,
  OpinionService,
  OpinionStateInterface,
  OrganizationUnitService,
  SorterInterface,
  StoreStateInterface,
  TagScreenStateInterface,
} from '../../../common';
import { CaretRightOutlined, HomeOutlined } from '@ant-design/icons';
import { Breadcrumb, Collapse, notification, Pagination, PaginationProps, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { LicenceEnum } from '../../../common/enums/licence.enum';
import moment from 'moment';

const { Text } = Typography;
const { Panel } = Collapse;

function InstitutionalOpinionsPageComponent() {
  const userAuth = useSelector((state: StoreStateInterface) => state.auth);

  const dispatch = useDispatch();
  const data: OpinionStateInterface = useSelector((state: StoreStateInterface) => state.opinion);
  const tags: TagScreenStateInterface = useSelector((state: StoreStateInterface) => state.tagScreen);

  const [filterValues, setFilterValues] = useState();
  const [esData, setEsData] = useState<OpinionEsResponseInterface>();

  const [keyword, setKeyword] = useState('');
  const [allOpinions, setAllOpinions] = useState(true);
  const [exactMatch, setExactMatch] = useState<boolean>(true);

  const [esSearch, setEsSearch] = useState(true);
  
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const [orgUnitInfo, setOrgUnitInfo] = useState<FullOrganizationUnitResponseInterfaceSingle>();
  const [licenceData, setLicenceData] = useState<LicenceInterface>();
  const [allValidLicences, setAllValidLicences] = useState<LicenceInterface[]>([]);
  const [noteData, setNoteData] = useState<NoteResponseInterfaceAll>();

  useEffect(() => {
    clearNoteAction();
    NoteService.getAll(userAuth.user?.id, false).subscribe(
      (response: NoteResponseInterfaceAll) => {
        setNoteData(response); 
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
      }
    );
  }, [userAuth?.user?.id]);

  useEffect(() => {
    if (userAuth.user?.organizationUnit?.id) {
      OrganizationUnitService.getSingle(userAuth.user?.organizationUnit?.id).subscribe(
        (response: FullOrganizationUnitResponseInterfaceSingle) => {
          setOrgUnitInfo(response);
        },
        (error: Error) => {
          notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
        }
      );
    }
  }, [userAuth.user]);

  useEffect(() => {
    if (orgUnitInfo?.data?.id) {
      LicenceService.getAll(undefined, `filter[organizationUnit.id]=${orgUnitInfo?.data?.id}`, 'sort=id', `page[offset]=0&page[limit]=999999`).subscribe(
        (response: LicenceResponseInterfaceAll) => {
          if (response?.data?.length) {
            let validLicences: LicenceInterface[] = [];

            response?.data?.forEach(licence => {
              if (licence.attributes?.licenceUsers?.find((x) => x.user?.id == userAuth.user?.id) && !licence.relationships?.licenceParent?.data && licence.attributes?.notesNum > 0) {
                setLicenceData(licence);
                const standardLicence = response.data.find(
                  (x) => x.attributes?.licenceUsers?.find((y) => y.user?.id == userAuth.user?.id) && !x.relationships?.licenceParent?.data && x.attributes?.active && x.attributes?.licenceType === LicenceEnum.STANDARD && licence.attributes?.notesNum > 0 && moment().isBefore(moment(licence?.attributes?.to).startOf('day'))
                );
                const premiumLicence = response.data.find(
                  (x) => x.attributes?.licenceUsers?.find((y) => y.user?.id == userAuth.user?.id) && !x.relationships?.licenceParent?.data && x.attributes?.active && x.attributes?.licenceType === LicenceEnum.PREMIUM && licence.attributes?.notesNum > 0 && moment().isBefore(moment(licence?.attributes?.to).startOf('day'))
                );
                if (standardLicence && standardLicence.attributes?.notesNum > 0) setLicenceData(standardLicence);
                if (premiumLicence && premiumLicence.attributes?.notesNum > 0) setLicenceData(premiumLicence);
              }
              if (licence.attributes?.licenceUsers?.find(x => x.user?.id == userAuth.user?.id) && !licence.relationships?.licenceParent?.data && licence.attributes?.active && licence?.attributes?.licenceType !== LicenceEnum.FREE && moment().isBefore(moment(licence?.attributes?.to).startOf('day'))) {
                validLicences.push(licence);
              }
            });

            setAllValidLicences([...validLicences]);
          }
        },
        (error: Error) => {
          notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
        }
      );
    }
  }, [orgUnitInfo?.data]);

  useEffect(() => {
    localStorage.removeItem('transferFilters');
    dispatch(clearOpinionAction());
    dispatch(clearOpinionListAction());
    dispatch(getScreenTagsByTypeAction('opinion'));
    /**
    dispatch(
      getAllOpinionsAction(
        undefined,
        undefined,
        'sort=-year',
        `page[offset]=${pageSize * (page - 1)}&page[limit]=${pageSize}`
      )
    );
    */
    esSearchFunction(allOpinions, { from: pageSize * (page - 1), size: pageSize }, undefined, undefined, {field: 'year', order: 'Desc'});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const esSearchFunction = (allOpinions: boolean, pageSorted: {from: number, size: number}, keyword?: string, tagIds?: any, sort?: SorterInterface) => {
    OpinionService.elasticSearch(allOpinions, pageSorted, exactMatch, keyword, tagIds/**, sort*/).subscribe(
      (response: OpinionEsResponseInterface) => {
        setEsData(response);
      },
      (error: any) => {
        if (error.errorId == 23) {
          notification['warning']({ message: i18n.translate('common.licence.noFilter'), duration: 2 });
        }
        else {
          notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
        }
      }
    );
  };

  const refetch = (allValues: any) => {
    if (!allValidLicences.some(x => x.attributes?.licenceType === LicenceEnum.STANDARD) && !allValidLicences.some(x => x.attributes?.licenceType === LicenceEnum.PREMIUM)) {
      notification['warning']({ message: i18n.translate('common.licence.noFilter'), duration: 4 });
      return;
    }
    //setEsSearch(true);
    setFilterValues(allValues);
    setPage(1);
    esSearchFunction(allOpinions, {from: 0, size: pageSize}, keyword, allValues, {field: 'year', order: 'Desc'});
  };

  const resetSearch = () => {
    setEsData(undefined);
    setKeyword('');
    setAllOpinions(true);
    //setEsSearch(false);
    setFilterValues(undefined);
    setPage(1);
    //dispatch(getAllOpinionsAction(undefined, undefined, 'sort=-year', `page[offset]=0&page[limit]=${pageSize}`));
    esSearchFunction(allOpinions, { from: 0, size: pageSize }, undefined, undefined, {field: 'year', order: 'Desc'});
  };

  const onPaginationChangeEs: PaginationProps['onChange'] = (page, pageSize) => {
    setPage(page);
    setPageSize(pageSize);
    esSearchFunction(allOpinions, { from: pageSize * (page - 1), size: pageSize }, keyword, filterValues, {field: 'year', order: 'Desc'});
  };

  const onPaginationChange: PaginationProps['onChange'] = (page, pageSize) => {
    setPage(page);
    setPageSize(pageSize);
    dispatch(
      getAllOpinionsAction(
        filterValues,
        undefined,
        'sort=-year',
        `page[offset]=${pageSize * (page - 1)}&page[limit]=${pageSize}`
      )
    );
  };

  const sortedOpinions = (opinions: OpinionInterface[]): Array<Array<OpinionInterface>> => {
    let years: any = {};

    opinions?.forEach(opinion => {
      let key = opinion.attributes?.yearTag;
      if(!key) key = 0;
      if (!years[key]) years[key] = [];
      years[key].push(opinion);
    });

    let yearAndData: any = Object.entries(years).filter((x: any) => x[1].length > 0) as any;
    yearAndData = yearAndData.sort((a: any, b: any) => (parseInt(a[0]) > parseInt(b[0]) ? -1 : 1));
    let data: Array<Array<OpinionInterface>> = yearAndData.map((x: any) => x[1]) as any;
    return data;
  };


  const genHeader = (opinion: OpinionInterface | undefined) => {
    return (
      <span className="header">
        <Text className="extraText">
          {opinion?.attributes?.yearTag ? opinion?.attributes?.yearTag + '.' : i18n.translate('common.unsorted')}
        </Text>
      </span>
    );
  };

  const getDefaultActiveKeys = (opinions: OpinionInterface[]) => {
    let years: any = {};

    opinions?.forEach(opinion => {
      let key = opinion.attributes?.yearTag;
      if(!key) key = 0;
      if (!years[key]) years[key] = [];
    });

    return Object.keys(years).map((entry, index) => 'opinion_sorted' + index);
  };

  const allOpinionsChanged = (value: boolean) => {
    setAllOpinions(value);
  };

  const keywordChanged = (value: string) => {
    setKeyword(value);
  };

  const exactMatchChanged = (exactMatch: boolean) => {
    setExactMatch(exactMatch);
  };

  const convertDataType = (opinions: OpinionEsResponseInterface["hits"]): OpinionInterface[] => {
    return opinions.map((opinion) => {
      return {
        id: opinion.id,
        type: opinion.index,
        attributes: opinion.source
      };
    })
  };

  return (
    <div className="institutionalOpinions w100-h100">
      <div className="institutionalOpinions__breadcrumbs">
        <Breadcrumb>
          <Breadcrumb.Item>
            <HomeOutlined />
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span>{i18n.translate('breadcrumbs.web-of-knowledge')}</span>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span>{i18n.translate('menu.institutional-opinions')}</span>
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="institutionalOpinions__admin">
        <div className="title">{i18n.translate('institutionalOpinions.title')}</div>
      </div>
      {tags.tagScreens && tags.tagScreens.length ? (
        <FilterComponent aggregation={esData?.aggregation?.buckets} filterAccess onExactMatchChange={exactMatchChanged} allOpinions={allOpinions} keyword={keyword} filters={tags.tagScreens} onFilterChange={refetch} onFilterRemove={resetSearch} onAllOpinionsChange={allOpinionsChanged} onKeywordChange={keywordChanged} />
      ) : null}
      {/**<YearDividerComponent title={''} />*/}
      {esSearch &&
        <>
          {esData?.hits?.length ? (
            <Collapse className='noPointer' collapsible={undefined} activeKey={getDefaultActiveKeys(convertDataType(esData?.hits as OpinionEsResponseInterface["hits"]) as OpinionInterface[])} ghost expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 270 : 90} />}>
            {sortedOpinions(convertDataType(esData?.hits as OpinionEsResponseInterface["hits"]) as OpinionInterface[])?.length ? 
            [...sortedOpinions(convertDataType(esData?.hits as OpinionEsResponseInterface["hits"]) as OpinionInterface[])]
              .map((group, index: number) => (
                <Panel className='noPointer' showArrow={false} forceRender key={'opinion_sorted' + index} header={genHeader(group?.length && group[0] ? group[0] : undefined)}>
                  {group?.length ? (
                    [...(group as OpinionInterface[])]
                      ?.map((opinion, i) => (
                        <InstitutionalOpinionCardComponent licenceData={licenceData} validLicences={allValidLicences} key={'opinion' + i} opinion={opinion} tags={tags.tagScreens} notes={noteData} />
                      ))
                  ) : (
                    <div className="center">
                      <NoDataComponent />
                    </div>
                  )}
                </Panel>
              )) : null}
            </Collapse>
          ) : (
            <NoDataComponent />
          )}
        </>
      }
      {!esSearch &&
        <>
          {data.opinions?.data?.length ? (
            <Collapse className='noPointer' collapsible={undefined} activeKey={getDefaultActiveKeys(data.opinions?.data)} ghost expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 270 : 90} />}>
            {sortedOpinions(data.opinions?.data)?.length ? 
            [...sortedOpinions(data.opinions?.data)]
              .map((group, index: number) => (
                <Panel className='noPointer' showArrow={false} forceRender key={'opinion_sorted' + index} header={genHeader(group?.length && group[0] ? group[0] : undefined)}>
                  {group?.length ? (
                    [...(group as OpinionInterface[])]
                      ?.map((opinion, i) => (
                        <InstitutionalOpinionCardComponent licenceData={licenceData} validLicences={allValidLicences} key={'opinion' + i} opinion={opinion} tags={tags.tagScreens} notes={noteData} />
                      ))
                  ) : (
                    <div className="center">
                      <NoDataComponent />
                    </div>
                  )}
                </Panel>
              )) : null}
            </Collapse>
          ) : (
            <NoDataComponent />
          )}
        </>
      }
      {esSearch &&
        <>
          {esData?.hits?.length ? (
            <Pagination
              style={{ float: 'right', paddingTop: 16, paddingBottom: 16 }}
              onChange={onPaginationChangeEs}
              current={page}
              pageSize={pageSize}
              pageSizeOptions={['10', '20', '50', '100']}
              locale={{ items_per_page: `${i18n.t('pagination.perPage')}` }}
              total={esData?.totalHits}
              hideOnSinglePage
              responsive
              showTotal={(total) => `${i18n.t('pagination.total')}: ${total}`}
            />
          ) : null}
        </>
      }
      {!esSearch &&
        <>
          {data.opinions?.data?.length ? (
            <Pagination
              style={{ float: 'right', paddingTop: 16, paddingBottom: 16 }}
              onChange={onPaginationChange}
              current={page}
              pageSize={pageSize}
              pageSizeOptions={['10', '20', '50', '100']}
              locale={{ items_per_page: `${i18n.t('pagination.perPage')}` }}
              total={data?.opinions?.meta?.totalResourceCount}
              hideOnSinglePage
              responsive
              showTotal={(total) => `${i18n.t('pagination.total')}: ${total}`}
            />
          ) : null}
        </>
      }
    </div>
  );
}

export default InstitutionalOpinionsPageComponent;
