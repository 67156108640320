import Icon, {
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  InfoCircleTwoTone,
  LockTwoTone,
  UnlockTwoTone
} from '@ant-design/icons';
import { Button, Col, Image, Popover, Row, Space, Spin, Typography } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  BiltenStateInterface,
  DisplayDateMonthFormat,
  EUR_CONVERSION,
  LicenceInterface,
  NotePickModal,
  NoteResponseInterfaceAll,
  StoreStateInterface,
  UserRoleEnum
} from '../..';
import { BookmarkOutlined } from '../../../assets/icons/icons';
import { FileService, UserActivityLogService } from '../../api';
import { i18n, ViewerService } from '../../services';
import { title } from 'process';
import { UserActionTypeEnum } from '../../enums/userActionType.enum';

const { Title, Text } = Typography;

interface Props {
  bilten: BiltenStateInterface;
  licenceData?: LicenceInterface;
  notes?: NoteResponseInterfaceAll;
  validLicences?: LicenceInterface[];
}

export default function ReviewComponent({ bilten, licenceData, notes, validLicences }: Props) {
  const [biltenImage, setBiltenImage] = useState('');
  const [imageGet, setImageGet] = useState(false);
  const [visible, setVisible] = useState(false);

  const availableNotes = licenceData?.attributes?.notesNum as number;

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    if (!((notes?.data.length as number) == availableNotes)) {
      setVisible(true);
    }
    setVisible(false)
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const data = bilten.currentBilten?.attributes?.descriptionEight;
  const hasSplit = bilten.currentBilten?.attributes?.descriptionEight?.includes('•');
  const dataSplit = bilten.currentBilten?.attributes?.descriptionEight?.split('•');
  const userAuth = useSelector((state: StoreStateInterface) => state.auth);

  useEffect(() => {
    if (bilten.currentBilten?.type === 'bilten') {
      //getBiltenImage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getBiltenImage = () => {
    FileService.temp(bilten.currentBilten?.attributes?.frontpageUuid as string).subscribe(
      (response: any) => {
        if (response.url) {
          let url = response.url + '/bjn';
          setBiltenImage(url);
        }
        setImageGet(true);
      },
      (error: Error) => {
        setImageGet(true);
      }
    );
  };

  const onBiltenPdfView = () => {
    if (!bilten.currentBilten?.attributes?.pdfUuid) return;
    if (userAuth?.user?.roles !== UserRoleEnum.ADMIN) {
      let userActivityLogData: any = {
        data: {
          type: 'user_activity_log',
          attributes: {
            action: UserActionTypeEnum.FILE_OPENED,
            extra: JSON.stringify({type: 'newsletter', documentType: 'Bilten', title: bilten.currentBilten?.attributes?.name})
          },
          relationships: {
            user: {
              data: {
                id: userAuth?.user?.id,
                type: 'user'
              },
            },
          },
        },
      };

      UserActivityLogService.create(userActivityLogData);
    }
    ViewerService.goToPdf(bilten.currentBilten?.attributes?.pdfUuid as string);
  };

  const onBiltenContentPdfView = () => {
    if (!bilten.currentBilten?.attributes?.biltenContentByUrl) return;
    if (userAuth?.user?.roles !== UserRoleEnum.ADMIN) {
      let userActivityLogData: any = {
        data: {
          type: 'user_activity_log',
          attributes: {
            action: UserActionTypeEnum.FILE_OPENED,
            extra: JSON.stringify({type: 'newsletter', documentType: 'Bilten', title: bilten.currentBilten?.attributes?.name})
          },
          relationships: {
            user: {
              data: {
                id: userAuth?.user?.id,
                type: 'user'
              },
            },
          },
        },
      };

      UserActivityLogService.create(userActivityLogData);
    }
    window.open(bilten.currentBilten?.attributes?.biltenContentByUrl, '_blank');
  };

  const currencyFormat = (price: any) =>
    parseFloat(price).toLocaleString('hr-HR', { minimumFractionDigits: 2, maximumFractionDigits: 2 });

  const priceEuro = (price: number) => {
    let p = price;
    if (!p) p = 0;
    return `${currencyFormat(p)} EUR (+PDV)`;
  };

  const priceHrk = (price: number) => {
    let p: any = price;
    if (!p) p = 0;
    p = p * EUR_CONVERSION;
    p = currencyFormat(p);
    if (p === '319,99') p = '320,00';
    if (p === '220,01') p = '220,00';
    return `${p} HRK (+PDV)`;
  };

  const checkLicenceYear = () => {
    const newsletterYear = getYear();

    if (userAuth.user?.roles === UserRoleEnum.ADMIN) return true;

    if (validLicences?.length) {
      let access = false;

      validLicences.forEach((licenceData) => {
        if (newsletterYear == licenceData.attributes?.year) {
          access = true;
        }
        if (licenceData.attributes?.childrenLicences?.length) {
          if (licenceData.attributes?.childrenLicences?.find((x) => x.active && x.year == newsletterYear)) {
            access = true;
          }
        }
      });

      return access;
    } else {
      return false;
    }
  };

  const getYear = () => {
    let yearString = i18n.translate('common.unsorted');

    if (bilten.currentBilten?.attributes?.issuedAt)
      yearString = moment(bilten.currentBilten?.attributes?.issuedAt).format('YYYY').toString();

    return yearString;
  };

  const isDisabled = () => {
    return !checkLicenceYear();
  };

  return (
    <div className="reviewContent">
      <Row className="row" justify="space-between" gutter={47} wrap={false}>
        <Col className="row___imgCol" span={10}>
          <Row justify="center">
            <Space className="title">
              {isDisabled() ? (
                <>
                  <LockTwoTone twoToneColor="red" />
                  <Text type="secondary">{i18n.translate('dashboard.review.accessLocked')}</Text>
                </>
              ) : (
                <>
                  <UnlockTwoTone twoToneColor="#52c41a" />
                  <Text type="secondary">{i18n.translate('dashboard.review.accessUnlocked')}</Text>
                </>
              )}
            </Space>
          </Row>
          <Row justify="center">
            <Image
              style={{ maxWidth: 237, maxHeight: 344 }}
              preview={false}
              alt="BJN Naslovnica"
              fallback={require('../../../assets/images/naslovnicaBJN1.png')}
              src={bilten.currentBilten?.attributes?.frontpageUrl}
              placeholder={<Spin style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} />}
            />
          </Row>
        </Col>
        <Col className="row___listCol" span={10}>
          <Title level={5}>
            {i18n.translate(`newsletters.issue`, { number: bilten.currentBilten?.attributes?.issueNum })} /{' '}
            {moment(bilten.currentBilten?.attributes?.issuedAt)
              .locale(userAuth.lang == 'hr' ? 'hr' : 'en')
              .format(DisplayDateMonthFormat)}
          </Title>
          {hasSplit ? <ul className="list">
            {dataSplit?.slice(1, data?.length).map((item, index) => (
              <li key={'review' + index}>{item}</li>
            ))}
          </ul> : data}
          <Space>
            {isDisabled() ? (
              <Popover
                placement="topRight"
                content={i18n.translate(`common.licence.content`)}
                title={i18n.translate(`common.licence.title`)}
              >
                <Button disabled type="primary">
                  {i18n.translate('common.readBilten')}
                </Button>
              </Popover>
            ) : null}

            {!isDisabled() ? (
              <Button onClick={onBiltenPdfView} type="primary">
                {i18n.translate('common.readBilten')}
              </Button>
            ) : null}

            <Button disabled={!bilten?.currentBilten?.attributes?.biltenContentByUrl} onClick={onBiltenContentPdfView}>
              {i18n.translate('dashboard.review.reviewContent')}
            </Button>
          </Space>
        </Col>
        <span className="row___divider" />
        <Col className="row___infoCol" span={10}>
          {checkLicenceYear() ? (
            <Button type="link" className="cardBtn" onClick={showModal} icon={<Icon component={BookmarkOutlined} />}>
              {i18n.translate(`common.addNotes`)}
            </Button>
          ) : (
            <Popover
              placement="topRight"
              content={i18n.translate(`common.licence.content`)}
              title={i18n.translate(`common.licence.title`)}
            >
              <Button disabled type="link" className="cardBtn" icon={<Icon component={BookmarkOutlined} />}>
                {i18n.translate(`common.addNotes`)}
              </Button>
            </Popover>
          )}
          <div className="row___info">
            <Row>
              <Text type="secondary">{i18n.translate('dashboard.review.yearlySub')}</Text>
            </Row>
            <Row style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
              <Button
                style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}
                type="text"
                icon={<InfoCircleTwoTone />}
              />
              <Text>Osnovni</Text>
            </Row>
          </div>
          <div className="row___info">
            <Row>
              <Text type="secondary">{i18n.translate('dashboard.review.editionAvailable')}</Text>
            </Row>
            <Row style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
              {bilten.currentBilten?.attributes?.availability ? (
                <Button
                  style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}
                  type="text"
                  icon={<CheckCircleTwoTone twoToneColor="#52c41a" />}
                />
              ) : (
                <Button
                  style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}
                  type="text"
                  icon={<CloseCircleTwoTone twoToneColor="red" />}
                />
              )}
              {bilten.currentBilten?.attributes?.availability ? (
                <Text>{i18n.translate('dashboard.review.available')}</Text>
              ) : (
                <Text>{i18n.translate('dashboard.review.unavailable')}</Text>
              )}
            </Row>
          </div>
          <div className="row___price">
            <Text type="secondary">{i18n.translate('dashboard.review.price')}</Text>

            <Text strong>{priceEuro(bilten?.currentBilten?.attributes?.price as number)}</Text>
          </div>

          {bilten?.currentBilten?.attributes?.availability ? (
            <Button
              type="text"
              onClick={() =>
                window.open(
                  `${process.env.REACT_APP_URL_WP}/naruci-bilten/?broj=${bilten?.currentBilten?.attributes?.issueNum}`,
                  '_blank'
                )
              }
            >
              <Text type="secondary" className="orderPrint" style={{ color: 'blue' }}>
                {i18n.translate('dashboard.review.orderPrint')}
              </Text>
            </Button>
          ) : (
            <Button disabled type="text">
              <Text type="secondary" className="orderPrint">
                {i18n.translate('dashboard.review.orderPrint')}
              </Text>
            </Button>
          )}
        </Col>
      </Row>
      <NotePickModal
        isExternal={true}
        visible={visible}
        handleOk={handleOk}
        handleCancel={handleCancel}
        external={bilten?.currentBilten}
        type="bilten"
        typeId={bilten?.currentBilten?.id}
        breadcrumb={'Bilten - svi brojevi/' + bilten?.currentBilten?.attributes?.name}
        number={bilten?.currentBilten?.attributes?.issueNum}
        isEditable={true}
        active={true}
        licenceData={licenceData as LicenceInterface}
        validLicences={validLicences}
      />
    </div>
  );
}
