import { HomeOutlined } from '@ant-design/icons';
import { Breadcrumb, notification, Pagination, PaginationProps, Tabs } from 'antd';
import { FilterValue, SorterResult, TableCurrentDataSource, TablePaginationConfig } from 'antd/lib/table/interface';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearNoteAction,
  clearVerdictAction,
  clearVerdictListAction,
  DecisionInterface,
  ESFilterInterface,
  FilterComponent,
  FullOrganizationUnitResponseInterfaceSingle, getScreenTagsByTypeAction,
  i18n,
  LicenceInterface,
  LicenceResponseInterfaceAll, LicenceService, NavigationService,
  NoDataComponent,
  NoteResponseInterfaceAll,
  NoteService,
  OrderEsResponseInterface,
  OrderInterface,
  OrderService,
  OrderStateInterface,
  OrganizationUnitService,
  QueryFilter,
  ResponseEsResponseInterface,
  ResponseInterface,
  ResponseService,
  ResponseStateInterface,
  SelectTypeEnum,
  SorterInterface,
  StoreStateInterface,
  SynopsisEsResponseInterface,
  SynopsisInterface,
  SynopsisService,
  SynopsisStateInterface,
  TagScreenResponseInterface,
  TagScreenService,
  TagScreenStateInterface,
  UserRoleEnum,
  VerdictEsResponseInterface,
  VerdictInterface,
  VerdictService,
  VerdictStateInterface,
  VUSComplaintCardComponent,
  VUSTableComponent
} from '../../../../common';
import VUSAppealCardComponent from '../../../../common/components/cards/vus/vusAppealCards/vusAppealCard.component';
import VUSSummaryCardComponent from '../../../../common/components/cards/vus/vusSummaryCards/vusSummaryCard.component';
import { LicenceEnum } from '../../../../common/enums/licence.enum';
import { AppRoutes } from '../../_router/app.routes';

function VUSDeclarationsPageComponent() {
  const userAuth = useSelector((state: StoreStateInterface) => state.auth);

  const dispatch = useDispatch();
  const verdicts: VerdictStateInterface = useSelector((state: StoreStateInterface) => state.verdict);
  const orders: OrderStateInterface = useSelector((state: StoreStateInterface) => state.order);
  const responses: ResponseStateInterface = useSelector((state: StoreStateInterface) => state.response);
  const synopsis: SynopsisStateInterface = useSelector((state: StoreStateInterface) => state.synopsis);
  const tags: TagScreenStateInterface = useSelector((state: StoreStateInterface) => state.tagScreen);

  const [selectedTab, setSelectedTab] = useState('decision');
  const [filterValues, setFilterValues] = useState<ESFilterInterface | undefined>();

  const [esDataVerdict, setEsDataVerdict] = useState<VerdictEsResponseInterface>();
  const [esDataOrder, setEsDataOrder] = useState<OrderEsResponseInterface>();
  const [esDataResponse, setEsDataResponse] = useState<ResponseEsResponseInterface>();
  const [esDataSynopsis, setEsDataSynopsis] = useState<SynopsisEsResponseInterface>();
  const [esDecisions, setEsDecisions] = useState<DecisionInterface[]>([]);

  const [dkomFilters, setDkomFilters] = useState<TagScreenResponseInterface[]>([]);
  const [vusFilters, setVusFilters] = useState<TagScreenResponseInterface[]>([]);

  const [keyword, setKeyword] = useState('');

  const [esSearch, setEsSearch] = useState(true);

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);

  const [orgUnitInfo, setOrgUnitInfo] = useState<FullOrganizationUnitResponseInterfaceSingle>();
  const [licenceData, setLicenceData] = useState<LicenceInterface>();
  const [allValidLicences, setAllValidLicences] = useState<LicenceInterface[]>([]);
  const [noteData, setNoteData] = useState<NoteResponseInterfaceAll>();

  const [sortBy, setSortBy] = useState<SorterInterface | undefined>();
  const [queryFilter, setQueryFilter] = useState<Array<QueryFilter>>(new Array<QueryFilter>());

  const [stopRender, setStopRender] = useState(false);

  const [exactMatch, setExactMatch] = useState<boolean>(true);

  useEffect(() => {
    clearNoteAction();
    NoteService.getAll(userAuth.user?.id, false).subscribe(
      (response: NoteResponseInterfaceAll) => {
        setNoteData(response);
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
      }
    );
  }, [userAuth?.user?.id]);

  useEffect(() => {
    if (userAuth.user?.organizationUnit?.id) {
      OrganizationUnitService.getSingle(userAuth.user?.organizationUnit?.id).subscribe(
        (response: FullOrganizationUnitResponseInterfaceSingle) => {
          setOrgUnitInfo(response);
        },
        (error: Error) => {
          notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
        }
      );
    }
  }, [userAuth.user]);

  useEffect(() => {
    if (orgUnitInfo?.data?.id) {
      LicenceService.getAll(undefined, `filter[organizationUnit.id]=${orgUnitInfo?.data?.id}`, 'sort=id', `page[offset]=0&page[limit]=999999`).subscribe(
        (response: LicenceResponseInterfaceAll) => {
          if (response?.data?.length) {
            let validLicences: LicenceInterface[] = [];

            response?.data?.forEach(licence => {
              if (licence.attributes?.licenceUsers?.find((x) => x.user?.id == userAuth.user?.id) && !licence.relationships?.licenceParent?.data && licence.attributes?.notesNum > 0) {
                setLicenceData(licence);
                const standardLicence = response.data.find(
                  (x) => x.attributes?.licenceUsers?.find((y) => y.user?.id == userAuth.user?.id) && !x.relationships?.licenceParent?.data && x.attributes?.active && x.attributes?.licenceType === LicenceEnum.STANDARD && licence.attributes?.notesNum > 0 && moment().isBefore(moment(licence?.attributes?.to).startOf('day'))
                );
                const premiumLicence = response.data.find(
                  (x) => x.attributes?.licenceUsers?.find((y) => y.user?.id == userAuth.user?.id) && !x.relationships?.licenceParent?.data && x.attributes?.active && x.attributes?.licenceType === LicenceEnum.PREMIUM && licence.attributes?.notesNum > 0 && moment().isBefore(moment(licence?.attributes?.to).startOf('day'))
                );
                if (standardLicence && standardLicence.attributes?.notesNum > 0) setLicenceData(standardLicence);
                if (premiumLicence && premiumLicence.attributes?.notesNum > 0) setLicenceData(premiumLicence);
              }
              if (licence.attributes?.licenceUsers?.find(x => x.user?.id == userAuth.user?.id) && !licence.relationships?.licenceParent?.data && licence.attributes?.active && licence?.attributes?.licenceType !== LicenceEnum.FREE && moment().isBefore(moment(licence?.attributes?.to).startOf('day'))) {
                validLicences.push(licence);
              }
            });

            setAllValidLicences([...validLicences]);
          }
        },
        (error: Error) => {
          notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
        }
      );
    }
  }, [orgUnitInfo?.data]);

  useEffect(() => {
    dispatch(clearVerdictAction());
    dispatch(clearVerdictListAction());
    dispatch(getScreenTagsByTypeAction('verdict'));
    /**
    dispatch(
      getAllVerdictsAction(
        undefined,
        undefined,
        'sort=-id',
        `page[offset]=${pageSize * (page - 1)}&page[limit]=${pageSize}`
      )
    );
    */
    esSearchFunctionVerdict(false, { from: pageSize * (page - 1), size: pageSize });
    // eslint-disable-next-line react-hooks/exhaustive-deps

    TagScreenService.getScreenTagsByType('decision').subscribe(
      (response: Array<TagScreenResponseInterface>) => {
        setDkomFilters(response);
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
      }
    );

    TagScreenService.getScreenTagsByType('verdict').subscribe(
      (response: Array<TagScreenResponseInterface>) => {
        setVusFilters(response);
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const prepareSupportedYears = () => {
    let supportedYears: { id: number | undefined; name: string; }[] = [];

    if (tags.tagScreens?.length) {
      tags.tagScreens?.forEach(tag => {
        if (tag.filter?.selectType === SelectTypeEnum.YEAR) {
          tag.children?.forEach(child => {
            supportedYears.push({id: child.tag?.id, name: child.tag?.name});
          });
        }
      });
    }

    return supportedYears;
  };
  const getEsDecisions = (verdicts: VerdictEsResponseInterface["hits"]): DecisionInterface[] => {
    let decisionArray: DecisionInterface[] = [];
    verdicts?.forEach(verdict => {
      if (verdict.source?.decision) decisionArray.push(
        {
          id: verdict.source?.decision?.id,
          type: 'decision',
          attributes: verdict.source?.decision
        }
      );
    });

    return decisionArray;
  };

  const esSearchFunctionVerdict = (allOpinions: boolean, pageSorted: { from: number, size: number }, keyword?: string, tagIds?: any, queryFilter?: Array<QueryFilter>, sort?: SorterInterface, supportedYears?: any) => {
    VerdictService.elasticSearch(allOpinions, pageSorted, exactMatch, keyword, queryFilter, tagIds, sort, supportedYears).subscribe(
      (response: VerdictEsResponseInterface) => {
        const decisions = getEsDecisions(response.hits);
        setEsDataVerdict(response);
        setEsDecisions(decisions);
      },
      (error: any) => {
        if (error.errorId == 23) {
          notification['warning']({ message: i18n.translate('common.licence.noFilter'), duration: 2 });
        }
        else {
          notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
        }
      }
    );
  };

  const esSearchFunctionOrder = (allOpinions: boolean, pageSorted: { from: number, size: number }, keyword?: string, tagIds?: any, supportedYears?: any) => {
    OrderService.elasticSearch(allOpinions, pageSorted, exactMatch, keyword, tagIds, supportedYears).subscribe(
      (response: OrderEsResponseInterface) => {
        setEsDataOrder(response);
      },
      (error: any) => {
        if (error.errorId == 23) {
          notification['warning']({ message: i18n.translate('common.licence.noFilter'), duration: 2 });
        }
        else {
          notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
        }
      }
    );
  };

  const esSearchFunctionResponse = (allOpinions: boolean, pageSorted: { from: number, size: number }, keyword?: string, tagIds?: any, supportedYears?: any) => {
    ResponseService.elasticSearch(allOpinions, pageSorted, exactMatch, keyword, tagIds, supportedYears).subscribe(
      (response: ResponseEsResponseInterface) => {
        setEsDataResponse(response);
      },
      (error: any) => {
        if (error.errorId == 23) {
          notification['warning']({ message: i18n.translate('common.licence.noFilter'), duration: 2 });
        }
        else {
          notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
        }
      }
    );
  };

  const esSearchFunctionSynopsis = (allOpinions: boolean, pageSorted: { from: number, size: number }, keyword?: string, tagIds?: any, supportedYears?: any) => {
    SynopsisService.elasticSearch(allOpinions, pageSorted, exactMatch, keyword, tagIds, supportedYears).subscribe(
      (response: SynopsisEsResponseInterface) => {
        setEsDataSynopsis(response);
      },
      (error: any) => {
        if (error.errorId == 23) {
          notification['warning']({ message: i18n.translate('common.licence.noFilter'), duration: 2 });
        }
        else {
          notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
        }
      }
    );
  };

  const getTitle = () => {
    let str = '';
    if (selectedTab == 'decision') {
      str = i18n.translate('verdicts.title');
    }
    if (selectedTab == 'allegation') {
      str = i18n.translate('orders.title');
    }
    if (selectedTab == 'appeal') {
      str = i18n.translate('responses.title');
    }
    if (selectedTab == 'summary') {
      str = i18n.translate('synopsiss.title');
    }

    return str;
  };

  const getTotal = () => {
    if (selectedTab == 'decision') {
      return esSearch ? esDataVerdict?.totalHits : verdicts?.verdicts?.meta?.totalResourceCount
    }
    if (selectedTab == 'allegation') {
      return esSearch ? esDataOrder?.totalHits : orders?.orders?.meta?.totalResourceCount
    }
    if (selectedTab == 'appeal') {
      return esSearch ? esDataResponse?.totalHits : responses?.responses?.meta?.totalResourceCount
    }
    if (selectedTab == 'summary') {
      return esSearch ? esDataSynopsis?.totalHits : synopsis?.synopsiss?.meta?.totalResourceCount
    }
  };

  const refetch = (allValues: any, tab?: string, columnFilter?: Array<QueryFilter>, sort?: SorterInterface, tableSearchAllowed?: boolean, stopRerender?: boolean) => {
    if (!checkLicenceYear()) {
      if (!tableSearchAllowed && allValidLicences?.length && !allValidLicences?.some(x => x.attributes?.licenceType === LicenceEnum.STANDARD) && !allValidLicences?.some(x => x.attributes?.licenceType === LicenceEnum.PREMIUM)) {
        notification['warning']({ message: i18n.translate('common.licence.noFilter'), duration: 4 });
        return;
      }
    }
    if ((!columnFilter || !columnFilter?.length) && !stopRerender) {
      setStopRender(true);
      setTimeout(() => {
        setStopRender(false);
      }, 0);
    }
    //setEsSearch(true);
    setFilterValues(allValues);
    setPage(1);
    if (tab === 'decision') {
      //dispatch(getAllVerdictsAction(allValues, undefined, 'sort=-id', `page[offset]=0&page[limit]=${pageSize}`));
      esSearchFunctionVerdict(false, { from: 0, size: pageSize }, keyword, allValues, columnFilter, sort, prepareSupportedYears());
    }
    if (tab === 'allegation') {
      //dispatch(getAllOrdersAction(allValues, undefined, 'sort=-id', `page[offset]=0&page[limit]=${pageSize}`));
      esSearchFunctionOrder(false, { from: 0, size: pageSize }, keyword, allValues, prepareSupportedYears());
    }
    if (tab === 'appeal') {
      //dispatch(getAllResponsesAction(allValues, undefined, 'sort=-id', `page[offset]=0&page[limit]=${pageSize}`));
      esSearchFunctionResponse(false, { from: 0, size: pageSize }, keyword, allValues, prepareSupportedYears());
    }
    if (tab === 'summary') {
      //dispatch(getAllSynopsissAction(allValues, undefined, 'sort=-id', `page[offset]=0&page[limit]=${pageSize}`));
      esSearchFunctionSynopsis(false, { from: 0, size: pageSize }, keyword, allValues, prepareSupportedYears());
    }
  };

  const navigate = (tab: string) => {
    setSelectedTab(tab);
    //setEsSearch(false);
    setPage(1);
    setPageSize(20);
    if (tab === 'decision') {
      setEsDataVerdict(undefined);
      dispatch(getScreenTagsByTypeAction('verdict'));
      //dispatch(getAllVerdictsAction(filterValues, undefined, 'sort=-id', `page[offset]=0&page[limit]=20`));
      esSearchFunctionVerdict(false, { from: 0, size: 20 }, undefined, filterValues, undefined, undefined);
    }
    if (tab === 'allegation') {
      setEsDataOrder(undefined);
      dispatch(getScreenTagsByTypeAction('verdict_prosecution_order'));
      //dispatch(getAllOrdersAction(filterValues, undefined, 'sort=-id', `page[offset]=0&page[limit]=20`));
      esSearchFunctionOrder(false, { from: 0, size: 20 }, undefined, filterValues, prepareSupportedYears());
    }
    if (tab === 'appeal') {
      setEsDataResponse(undefined);
      dispatch(getScreenTagsByTypeAction('verdict_response'));
      //dispatch(getAllResponsesAction(filterValues, undefined, 'sort=-id', `page[offset]=0&page[limit]=20`));
      esSearchFunctionResponse(false, { from: 0, size: 20 }, undefined, filterValues, prepareSupportedYears());
    }
    if (tab === 'summary') {
      setEsDataSynopsis(undefined);
      dispatch(getScreenTagsByTypeAction('verdict_summary'));
      //dispatch(getAllSynopsissAction(filterValues, undefined, 'sort=-id', `page[offset]=0&page[limit]=20`));
      esSearchFunctionSynopsis(false, { from: 0, size: 20 }, undefined, filterValues, prepareSupportedYears());
    }
  };

  const resetSearch = (tab?: string) => {
    setStopRender(true);
    setTimeout(() => {
      setStopRender(false);
    }, 0);
    localStorage.removeItem('transferFilters');
    //setEsSearch(false);
    setPage(1);
    setKeyword('');
    setFilterValues(undefined);
    if (tab === 'decision') {
      setEsDataVerdict(undefined);
      //dispatch(getAllVerdictsAction(undefined, undefined, 'sort=-id', `page[offset]=0&page[limit]=${pageSize}`));
      esSearchFunctionVerdict(false, { from: 0, size: pageSize });
    }
    if (tab === 'allegation') {
      setEsDataOrder(undefined);
      //dispatch(getAllOrdersAction(undefined, undefined, 'sort=-id', `page[offset]=0&page[limit]=${pageSize}`));
      esSearchFunctionOrder(false, { from: 0, size: pageSize }, undefined, undefined, prepareSupportedYears());
    }
    if (tab === 'appeal') {
      setEsDataResponse(undefined);
      //dispatch(getAllResponsesAction(undefined, undefined, 'sort=-id', `page[offset]=0&page[limit]=${pageSize}`));
      esSearchFunctionResponse(false, { from: 0, size: pageSize }, undefined, undefined, prepareSupportedYears());
    }
    if (tab === 'summary') {
      setEsDataSynopsis(undefined);
      //dispatch(getAllSynopsissAction(undefined, undefined, 'sort=-id', `page[offset]=0&page[limit]=${pageSize}`));
      esSearchFunctionSynopsis(false, { from: 0, size: pageSize }, undefined, undefined, prepareSupportedYears());
    }
  };

  const onPaginationChange: PaginationProps['onChange'] = (page, pageSize) => {
    setPage(page);
    setPageSize(pageSize);

    if (selectedTab === 'decision') {
      if (esSearch) {
        esSearchFunctionVerdict(false, { from: pageSize * (page - 1), size: pageSize }, keyword, filterValues, queryFilter, sortBy, prepareSupportedYears());
      } else {
        //dispatch(getAllVerdictsAction(filterValues, undefined, 'sort=-id', `page[offset]=${pageSize * (page - 1)}&page[limit]=${pageSize}`));
      }
    }
    if (selectedTab === 'allegation') {
      if (esSearch) {
        esSearchFunctionOrder(false, { from: pageSize * (page - 1), size: pageSize }, keyword, filterValues, prepareSupportedYears());
      } else {
        //dispatch(getAllOrdersAction(filterValues, undefined, 'sort=-id', `page[offset]=${pageSize * (page - 1)}&page[limit]=${pageSize}`));
      }
    }
    if (selectedTab === 'appeal') {
      if (esSearch) {
        esSearchFunctionResponse(false, { from: pageSize * (page - 1), size: pageSize }, keyword, filterValues, prepareSupportedYears());
      } else {
        //dispatch(getAllResponsesAction(filterValues, undefined, 'sort=-id', `page[offset]=${pageSize * (page - 1)}&page[limit]=${pageSize}`));
      }
    }
    if (selectedTab === 'summary') {
      if (esSearch) {
        esSearchFunctionSynopsis(false, { from: pageSize * (page - 1), size: pageSize }, keyword, filterValues, prepareSupportedYears());
      } else {
        //dispatch(getAllSynopsissAction(filterValues, undefined, 'sort=-id', `page[offset]=${pageSize * (page - 1)}&page[limit]=${pageSize}`));
      }
    }
  };

  const keywordChanged = (value: string) => {
    setKeyword(value);
  };

  const exactMatchChanged = (exactMatch: boolean) => {
    setExactMatch(exactMatch);
  };

  const convertDataTypeVerdict = (verdicts: VerdictEsResponseInterface["hits"]): VerdictInterface[] => {
    return verdicts?.map((verdict) => {
      return {
        id: verdict.id,
        type: verdict.index,
        attributes: verdict.source
      };
    })
  };

  const convertDataTypeOrder = (orders: OrderEsResponseInterface["hits"]): OrderInterface[] => {
    return orders?.map((order) => {
      return {
        id: order.id,
        type: order.index,
        attributes: order.source
      };
    })
  };

  const convertDataTypeResponse = (responses: ResponseEsResponseInterface["hits"]): ResponseInterface[] => {
    return responses?.map((response) => {
      return {
        id: response.id,
        type: response.index,
        attributes: response.source
      };
    })
  };

  const convertDataTypeSynopsis = (synopsiss: SynopsisEsResponseInterface["hits"]): SynopsisInterface[] => {
    return synopsiss?.map((synopsis) => {
      return {
        id: synopsis.id,
        type: synopsis.index,
        attributes: synopsis.source
      };
    })
  };

  const onTagsChange = (changedTags: any, allTags: any) => {
    localStorage.setItem('transferFilters', JSON.stringify(allTags));
  };

  const onChangeDkom = (key: string) => {
    if (key == '1') {
      NavigationService.navigate(AppRoutes.DKOM_DECISIONS.fullPath);
    }
  };

  const onChangeFilter = (pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>, sorter: SorterResult<any> | SorterResult<any>[], extra: TableCurrentDataSource<any>): void => {
    let sort: SorterInterface | undefined;
    //@ts-ignore
    if (Object.keys(sorter).length > 0 && sorter.column !== undefined) {
      sort = {
        //@ts-ignore
        field: sorter.field.toString(),
        //@ts-ignore
        order: sorter.order === "descend" ? "Desc" : "Asc"
      }
    }
    let qf: Array<QueryFilter> = new Array<QueryFilter>();
    for (let key in filters) {
      if (filters[key] !== null && !!filters[key] && filters[key]!.length > 0) {
        if (key === 'type') {
          qf.push({ name: key, value: filters[key]! as number[]});
        } else {
          qf.push({ name: key, value: filters[key]![0].toString(), exactMatch: filters[key]![1] as boolean });
        }
      }
    }
    setQueryFilter(qf)
    setSortBy(sort)
    refetch(filterValues, 'decision', qf, sort, true, true)
  }

  const clearColumnFilters = () => {
    setQueryFilter([]);
    refetch(filterValues, 'decision', [], undefined, true)
  }

  const checkLicenceYear = () => {
    if (userAuth.user?.roles === UserRoleEnum.ADMIN) return true;

    if (allValidLicences?.length && allValidLicences?.some(x => x.attributes?.licenceType === LicenceEnum.PREMIUM)) {
      return true;
    } else {
      return false;
    }
  };

  const getAggregation = () => {
    if (selectedTab === 'decision') {
      return esDataVerdict?.aggregation?.buckets;
    }
    if (selectedTab === 'allegation') {
      return esDataOrder?.aggregation?.buckets;
    }
    if (selectedTab === 'appeal') {
      return esDataResponse?.aggregation?.buckets;
    }
    if (selectedTab === 'summary') {
      return esDataSynopsis?.aggregation?.buckets;
    }
  };

  return (
    <div className="vusDeclarations w100-h100">
      <div className="vusDeclarations__breadcrumbs">
        <Breadcrumb>
          <Breadcrumb.Item>
            <HomeOutlined />
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span>{i18n.translate('breadcrumbs.rh-practice')}</span>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span>{i18n.translate('menu.vus-declarations')}</span>
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="vusDeclarations__title">{i18n.translate('vus.title')}</div>
      <div className='vusDeclarations__tabs'>
        <Tabs onChange={onChangeDkom} defaultActiveKey="2" size='large'>
          <Tabs.TabPane tab={i18n.translate('decisions.tabs.dkom')} key="1" />
          <Tabs.TabPane tab={i18n.translate('verdicts.tabs.vus')} key="2" />
        </Tabs>
      </div>
      {tags.tagScreens && tags.tagScreens.length && dkomFilters && dkomFilters.length && vusFilters && vusFilters.length ? (
        <FilterComponent
          aggregation={getAggregation()}
          filterAccess={checkLicenceYear()}
          filters={tags.tagScreens}
          tab={selectedTab}
          onFilterChange={refetch}
          onTabChange={navigate}
          onFilterRemove={resetSearch}
          keyword={keyword}
          onKeywordChange={keywordChanged}
          onTagChange={onTagsChange}
          transferEnabled
          dkomFilters={dkomFilters}
          vusFilters={vusFilters}
          onExactMatchChange={exactMatchChanged}
          alternativeLayout
        />
      ) : null}
      <div className="subtitle">{getTitle()}</div>
      {selectedTab == 'decision' && !stopRender ? (
        <VUSTableComponent
          validLicences={allValidLicences}
          verdicts={convertDataTypeVerdict(esDataVerdict?.hits as VerdictEsResponseInterface["hits"])}
          tags={tags.tagScreens}
          onChange={onChangeFilter}
          decisions={esDecisions}
          refreshTable={() => refetch(!!filterValues ? filterValues : [], 'decision')}
          clearColumnFilters={clearColumnFilters}
          licenceData={licenceData}
          
        />
      ) : null}
      {selectedTab == 'allegation' ? (
        !esSearch ? (
          orders.orders?.data?.length ? (
            [...(orders.orders?.data as OrderInterface[])].map((order, i) => (
              <VUSComplaintCardComponent validLicences={allValidLicences} licenceData={licenceData} key={'order' + i} order={order} notes={noteData} />
            ))
          ) : (
            <NoDataComponent />
          )
        ) : (
          esDataOrder?.hits?.length ? (
            [...(convertDataTypeOrder(esDataOrder?.hits))].map((order, i) => (
              <VUSComplaintCardComponent validLicences={allValidLicences} licenceData={licenceData} key={'order' + i} order={order} notes={noteData} />
            ))
          ) : (
            <NoDataComponent />
          )
        )
      ) : null}
      {selectedTab == 'appeal' ? (
        !esSearch ? (
          responses.responses?.data?.length ? (
            [...(responses.responses?.data as ResponseInterface[])].map((response, i) => (
              <VUSAppealCardComponent validLicences={allValidLicences} licenceData={licenceData} key={'response' + i} response={response} notes={noteData} />
            ))
          ) : (
            <NoDataComponent />
          )
        ) : (
          esDataResponse?.hits?.length ? (
            [...(convertDataTypeResponse(esDataResponse?.hits))].map((response, i) => (
              <VUSAppealCardComponent validLicences={allValidLicences} licenceData={licenceData} key={'response' + i} response={response} notes={noteData} />
            ))
          ) : (
            <NoDataComponent />
          )
        )
      ) : null}
      {selectedTab == 'summary' ? (
        !esSearch ? (
          synopsis.synopsiss?.data?.length ? (
            [...(synopsis.synopsiss?.data as SynopsisInterface[])].map((synopsis, i) => (
              <VUSSummaryCardComponent validLicences={allValidLicences} licenceData={licenceData} key={'synopsis' + i} synopsis={synopsis} notes={noteData} />
            ))
          ) : (
            <NoDataComponent />
          )
        ) : (
          esDataSynopsis?.hits?.length ? (
            [...(convertDataTypeSynopsis(esDataSynopsis?.hits))].map((synopsis, i) => (
              <VUSSummaryCardComponent validLicences={allValidLicences} licenceData={licenceData} key={'synopsis' + i} synopsis={synopsis} notes={noteData} />
            ))
          ) : (
            <NoDataComponent />
          )
        )
      ) : null}
      <Pagination
        style={{ float: 'right', paddingTop: 16, paddingBottom: 16 }}
        onChange={onPaginationChange}
        current={page}
        pageSize={pageSize}
        pageSizeOptions={['10', '20', '50', '100']}
        locale={{ items_per_page: `${i18n.t('pagination.perPage')}` }}
        total={getTotal()}
        showSizeChanger
        responsive
        showTotal={(total) => `${i18n.t('pagination.total')}: ${total}`}
      />
    </div>
  );
}

export default VUSDeclarationsPageComponent;
