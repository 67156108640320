import { Dispatch } from 'redux';
import { notification } from 'antd';
import { NoteLinkConstants } from './notesLinks.constants';

import { NoteLinkService } from '../../api/api.service';
import { NoteLinkResponseInterfaceAll, NoteLinkResponseInterfaceSingle } from '../../interfaces';
import { i18n } from '../../services';

export function getAllNoteLinksAction(noteId?: number) {
  return (dispatch: Dispatch) => {
    return NoteLinkService.getAll(noteId).subscribe(
      (response: NoteLinkResponseInterfaceAll) => {
        dispatch({
          type: NoteLinkConstants.GET_ALL_SUCCESS,
          payload: response,
        });
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });

        dispatch({
          type: NoteLinkConstants.ERROR,
          payload: error,
        });
      }
    );
  };
}

export function getSingleNoteLinkAction(id: number) {
  return (dispatch: Dispatch) => {
    return NoteLinkService.getSingle(id).subscribe(
      (response: NoteLinkResponseInterfaceSingle) => {
        dispatch({
          type: NoteLinkConstants.GET_SINGLE_SUCCESS,
          payload: response,
        });
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });

        dispatch({
          type: NoteLinkConstants.ERROR,
          payload: error,
        });
      }
    );
  };
}

export function createNoteLinkAction(body: NoteLinkResponseInterfaceSingle) {
  return (dispatch: Dispatch) => {
    return NoteLinkService.create(body).subscribe(
      (response: NoteLinkResponseInterfaceSingle) => {
        notification['success']({ message: i18n.translate('api.successMessage'), duration: 2 });

        dispatch({
          type: NoteLinkConstants.CREATE_SUCCESS,
          payload: response,
        });
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });

        dispatch({
          type: NoteLinkConstants.ERROR,
          payload: error,
        });
      }
    );
  };
}

export function updateNoteLinkAction(body: NoteLinkResponseInterfaceSingle, id?: number) {
  return (dispatch: Dispatch) => {
    return NoteLinkService.update(body, id).subscribe(
      (response: NoteLinkResponseInterfaceSingle) => {
        notification['success']({ message: i18n.translate('api.successMessage'), duration: 2 });

        dispatch({
          type: NoteLinkConstants.UPDATE_SUCCESS,
          payload: response,
        });
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });

        dispatch({
          type: NoteLinkConstants.ERROR,
          payload: error,
        });
      }
    );
  };
}

export function deleteNoteLinkAction(id: number) {
  return (dispatch: Dispatch) => {
    return NoteLinkService.delete(id).subscribe(
      (response: any) => {
        dispatch({
          type: NoteLinkConstants.DELETE,
        });
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });

        dispatch({
          type: NoteLinkConstants.ERROR,
          payload: error,
        });
      }
    );
  };
}

export function clearNoteLinkAction() {
  return (dispatch: Dispatch) => {
    dispatch({ type: NoteLinkConstants.CLEAR_CURRENT });
  };
}
