import { Button, Collapse, Form, InputNumber, Modal, notification, Radio, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import {
  createResponseAction,
  ResponseStateInterface,
  deleteResponseAction,
  i18n,
  StoreStateInterface,
  updateResponseAction,
  VerdictStateInterface,
  clearResponseListAction,
  getSingleVerdictAction,
  getAllResponsesAction,
  TagScreenStateInterface,
  getScreenTagsByTypeAction,
} from '../../../../../../../common';
import { ExclamationCircleOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Link, useLocation } from 'react-router-dom';
import { AdminRoutes } from '../../../../../_router/admin.routes';
import { useDispatch, useSelector } from 'react-redux';
import TextArea from 'antd/lib/input/TextArea';

const { confirm } = Modal;

const { Panel } = Collapse;

interface Props {
  setTabSynopsis?: () => void;
}

function VUSResponsesCrudPageComponent({ setTabSynopsis }: Props) {

  const dispatch = useDispatch();
  const [form] = Form.useForm<any>();
  const verdict: VerdictStateInterface = useSelector((state: StoreStateInterface) => state.verdict);
  const data: ResponseStateInterface = useSelector((state: StoreStateInterface) => state.response);
  const tags: TagScreenStateInterface = useSelector((state: StoreStateInterface) => state.tagScreen);

  useEffect(() => {
    dispatch(getScreenTagsByTypeAction('verdict_response'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verdict.currentVerdict, data.currentResponse, data.revision]);

  useEffect(() => {
    form.resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, tags]);

  const loadData = () => {
    dispatch(getAllResponsesAction(undefined, `filter[verdict.id]=${verdict.currentVerdict!.id}`, 'sort=id'));
  }

  const onFinishResponse = (values: any) => {
    values.responses.forEach((value: any, index: number) => {
      let responseTags: Array<{ id: number; type: string }> = [];
      responseTags = verdict.currentVerdict?.attributes?.tagIds?.map((id) => ({
        id: id,
        type: 'tag',
      })) as Array<{ id: number; type: string }>;
      if (value?.vusRatingFilter) responseTags?.push({ id: value.vusRatingFilter, type: 'tag' });
      let responseData: any = {
        data: {
          type: 'verdict_response',
          id: data.responses?.data[index]?.id,
          attributes: {
            explanationPage: value?.explanationPage ? value.explanationPage : 1,
            titleContent: value?.titleContent,
            textContent: value?.textContent,
            vusText: value?.vusText,
            //verdictAppealResponse: value?.verdictAppealResponse,
            vusAssessment: value?.vusAssessment,
            thirdPartyResponse: value?.thirdPartyResponse,
            zjnArticleEtc: value?.zjnArticleEtc,
            comment: value?.comment,
          },
          relationships: {
            verdict: {
              data: {
                id: verdict.currentVerdict?.id,
                type: 'verdict',
              },
            },
            tags: {
              data: responseTags,
            },
          },
        },
      };

      !responseData?.data?.id
        ? dispatch(createResponseAction(responseData, values?.responses.length == index + 1))
        : dispatch(updateResponseAction(responseData, values?.responses?.length == index + 1));
    });

    window.scrollTo({ top: 0, behavior: 'smooth' });

    setTimeout(() => {
      setTab();
    }, 2000);
  };

  const onFinishResponseFailed = (errorInfo: any) => {
    notification['error']({ message: i18n.translate('genericFormMsg.pleaseCheck'), duration: 2 });
    console.log('Failed:', errorInfo);
  };

  const loadInitialValues = () => {
    let values: any = data.responses?.data?.map((response) => ({
      explanationPage: response?.attributes?.explanationPage,
      titleContent: response?.attributes?.titleContent,
      textContent: response?.attributes?.textContent,
      vusText: response?.attributes?.vusText,
      thirdPartyResponse: response?.attributes?.thirdPartyResponse,
      //verdictAppealResponse: response?.attributes?.verdictAppealResponse,
      zjnArticleEtc: response?.attributes?.zjnArticleEtc,
      comment: response?.attributes?.comment,
      vusAssessment: response?.attributes?.vusAssessment,
      vusRatingFilter: loadVusRatingFilter(response?.id as number),
    }));

    return values;
  };

  const loadDefaultValues = () => {
    let values: any = [];

    return values;
  };

  const showConfirm = (key: number) => {
    confirm({
      title: i18n.t('responses.confirmDelete'),
      icon: <ExclamationCircleOutlined />,
      content: i18n.t('common.cantUndo'),
      okText: i18n.t('genericButtons.yes'),
      cancelText: i18n.t('genericButtons.cancel'),
      onOk() {
        dispatch(deleteResponseAction(data.responses?.data[key]?.id as number));
        window.scrollTo({ top: 0, behavior: 'smooth' });
      },
    });
  };

  const clearResponseList = () => {
    dispatch(clearResponseListAction());
  };

  const addResponse = () => {
    let responseTags: Array<{ id: number; type: string }> = [];
    responseTags = verdict.currentVerdict?.attributes?.tagIds?.map((id) => ({
      id: id,
      type: 'tag',
    })) as Array<{ id: number; type: string }>;
    let responseData: any = {
      data: {
        type: 'verdict_response',
        attributes: {
          explanationPage: 1,
          titleContent: '',
          textContent: '',
          vusText: '',
          //verdictAppealResponse: '',
          vusAssessment: '',
          thirdPartyResponse: '',
          comment: '',
          zjnArticleEtc: '',
        },
        relationships: {
          verdict: {
            data: {
              id: (verdict.currentVerdict?.id as number),
              type: 'verdict',
            },
          },
          tags: {
            data: responseTags,
          },
        },
      },
    };
    dispatch(createResponseAction(responseData));
  };

  const setTab = () => {
    setTabSynopsis && setTabSynopsis();
  };

  const loadVusRatingFilter = (id: number) => {
    const tagTrue = getVusRatingFilterTrue()?.tag?.id;
    const tagFalse = getVusRatingFilterFalse()?.tag?.id;
    const response = data.responses?.data?.find((response) => response.id == id);
    let tagFoundTrue;
    let tagFoundFalse;
    if (tagTrue && response) {
      if (response.attributes?.tagIds?.includes(tagTrue)) tagFoundTrue = tagTrue;
    }
    if (tagFalse && response) {
      if (response.attributes?.tagIds?.includes(tagFalse)) tagFoundFalse = tagFalse;
    }
    return tagFoundTrue ? tagFoundTrue : tagFoundFalse;
  };

  const getVusRatingFilterTrue = () => {
    const tag = tags.tagScreens?.find((tag) => tag.filter.name == 'Ocjena VUS-a');
    let child;
    if (tag && tag?.children && tag?.children?.length && tag?.children[0]) {
      child = tag?.children[0];
    }
    return child;
  };

  const getVusRatingFilterFalse = () => {
    const tag = tags.tagScreens?.find((tag) => tag.filter.name == 'Ocjena VUS-a');
    let child;
    if (tag && tag?.children && tag?.children?.length && tag?.children[1]) {
      child = tag?.children[1];
    }
    return child;
  };

  const genExtra = (key: number) => <DeleteOutlined onClick={() => showConfirm(key)} className="button" />;

  return (
    <div className="vusResponsesCrud w100-h100">
      {(
        <Form
          form={form}
          layout="vertical"
          name="response"
          size="large"
          scrollToFirstError
          onFinish={onFinishResponse}
          onFinishFailed={onFinishResponseFailed}
          autoComplete="off"
          requiredMark={false}
        >
          <div className="vusResponsesCrud__form">
            <Form.List
              initialValue={loadInitialValues()}
              name="responses"
            >
              {(fields) => (
                <Collapse defaultActiveKey={'Panel0'}>
                  {fields.map(({ key, name }) => (
                    <Panel header={`${key + 1}. Odgovor na tužbu`} key={'Panel' + key} extra={genExtra(key)}>
                      <Space
                        key={key}
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          width: '100%',
                          marginBottom: 8,
                        }}
                        align="baseline"
                      >
                        <Form.Item
                          label={
                            <span className="text-bold">{i18n.translate(`responses.form.fields.titleContent`)}</span>
                          }
                          name={[name, 'titleContent']}
                          rules={[{ required: true, message: i18n.translate(`genericFormMsg.required`) }]}
                          labelCol={{ span: 24 }}
                          wrapperCol={{ span: 24 }}
                        >
                          <TextArea autoSize={{ minRows: 4 }} />
                        </Form.Item>
                        <Form.Item
                          label={
                            <span className="text-bold">{i18n.translate(`responses.form.fields.textContent`)}</span>
                          }
                          name={[name, 'textContent']}
                          rules={[{ required: true, message: i18n.translate(`genericFormMsg.required`) }]}
                          labelCol={{ span: 24 }}
                          wrapperCol={{ span: 24 }}
                        >
                          <TextArea autoSize={{ minRows: 4 }} />
                        </Form.Item>
                        {/**<Form.Item
                          label={
                            <span className="text-bold">
                              {i18n.translate(`responses.form.fields.verdictAppealResponse`)}
                            </span>
                          }
                          name={[name, 'verdictAppealResponse']}
                          rules={[{ required: true, message: i18n.translate(`genericFormMsg.required`) }]}
                          labelCol={{ span: 24 }}
                          wrapperCol={{ span: 24 }}
                        >
                          <TextArea autoSize={{ minRows: 4 }} />
                        </Form.Item>*/}
                        <Form.Item
                          label={<span className="text-bold">{i18n.translate(`responses.form.fields.vusText`)}</span>}
                          name={[name, 'vusText']}
                          //rules={[{ required: true, message: i18n.translate(`genericFormMsg.required`) }]}
                          labelCol={{ span: 24 }}
                          wrapperCol={{ span: 24 }}
                        >
                          <TextArea autoSize={{ minRows: 4 }} />
                        </Form.Item>
                        <Form.Item
                          label={
                            <span className="text-bold">
                              {i18n.translate(`responses.form.fields.thirdPartyResponse`)}
                            </span>
                          }
                          name={[name, 'thirdPartyResponse']}
                          //rules={[{ required: true, message: i18n.translate(`genericFormMsg.required`) }]}
                          labelCol={{ span: 24 }}
                          wrapperCol={{ span: 24 }}
                        >
                          <TextArea autoSize={{ minRows: 4 }} />
                        </Form.Item>
                        <Form.Item
                          label={
                            <span className="text-bold">{i18n.translate(`responses.form.fields.zjnArticleEtc`)}</span>
                          }
                          name={[name, 'zjnArticleEtc']}
                          //rules={[{ required: true, message: i18n.translate(`genericFormMsg.required`) }]}
                          labelCol={{ span: 24 }}
                          wrapperCol={{ span: 24 }}
                        >
                          <TextArea autoSize={{ minRows: 4 }} />
                        </Form.Item>
                        <Form.Item
                          label={
                            <span className="text-bold">{i18n.translate(`responses.form.fields.vusAssessment`)}</span>
                          }
                          name={[name, 'vusAssessment']}
                          //rules={[{ required: true, message: i18n.translate(`genericFormMsg.required`) }]}
                          labelCol={{ span: 24 }}
                          wrapperCol={{ span: 24 }}
                        >
                          <TextArea autoSize={{ minRows: 4 }} />
                        </Form.Item>
                        <Form.Item
                          label={<span className="text-bold">{i18n.translate(`responses.form.fields.comment`)}</span>}
                          name={[name, 'comment']}
                          //rules={[{ required: true, message: i18n.translate(`genericFormMsg.required`) }]}
                          labelCol={{ span: 24 }}
                          wrapperCol={{ span: 24 }}
                        >
                          <TextArea autoSize={{ minRows: 4 }} />
                        </Form.Item>
                        <Form.Item
                          label={
                            <span className="text-bold">{i18n.translate(`responses.form.fields.explanationPage`)}</span>
                          }
                          name={[name, 'explanationPage']}
                          //rules={[{ required: true, message: i18n.translate(`genericFormMsg.required`) }]}
                          labelCol={{ span: 24 }}
                          wrapperCol={{ span: 12 }}
                        >
                          <InputNumber min={1} />
                        </Form.Item>

                        <div style={{ marginTop: 16 }} className="subtitle">
                          <span className="text">{i18n.translate('responses.form.sections.filters')}</span>
                        </div>

                        <Form.Item
                          label={
                            <span className="text-bold">{i18n.translate(`responses.form.fields.vusRatingFilter`)}</span>
                          }
                          name={[name, 'vusRatingFilter']}
                          //rules={[{ required: true, message: i18n.translate(`genericFormMsg.required`) }]}
                          labelCol={{ span: 24 }}
                          wrapperCol={{ span: 12 }}
                        >
                          <Radio.Group>
                            <Radio.Button
                              value={getVusRatingFilterTrue()?.tag?.id ? getVusRatingFilterTrue()?.tag?.id : 0}
                            >
                              {getVusRatingFilterTrue()?.tag?.name ? getVusRatingFilterTrue()?.tag?.name : ''}
                            </Radio.Button>
                            <Radio.Button
                              value={getVusRatingFilterFalse()?.tag?.id ? getVusRatingFilterFalse()?.tag?.id : 1}
                            >
                              {getVusRatingFilterFalse()?.tag?.name ? getVusRatingFilterFalse()?.tag?.name : ''}
                            </Radio.Button>
                          </Radio.Group>
                        </Form.Item>
                      </Space>
                    </Panel>
                  ))}
                </Collapse>
              )}
            </Form.List>
            <Button style={{ marginTop: 16 }} type="link" onClick={() => addResponse()} block icon={<PlusOutlined />}>
              {i18n.translate(`responses.crudTitleAdd`)}
            </Button>
          </div>
          <div className="vusResponsesCrud__bottomButtons">
            <div></div>
            <div>
              <Link to={AdminRoutes.VUS_DECLARATIONS.fullPath} key={AdminRoutes.VUS_DECLARATIONS.path}>
                <Button
                  onClick={clearResponseList}
                  className="button"
                  size="large"
                  style={{ fontSize: 16, fontWeight: 700, borderRadius: 0, minWidth: 193, marginRight: 16 }}
                >
                  {i18n.translate('genericButtons.cancel')}
                </Button>
              </Link>
              <Button
                className="button"
                htmlType="submit"
                type="primary"
                size="large"
                style={{ fontSize: 16, fontWeight: 700, borderRadius: 0, minWidth: 193 }}
              >
                {i18n.translate('genericButtons.save')}
              </Button>
            </div>
          </div>
        </Form>
      )}
    </div>
  );
}

export default VUSResponsesCrudPageComponent;
