import { HomeOutlined } from '@ant-design/icons';
import { Breadcrumb, notification, Pagination, PaginationProps } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearFaqAction,
  clearFaqListAction,
  clearNoteAction,
  FAQCardComponent,
  FaqEsResponseInterface, FaqInterface, FaqService,
  FaqStateInterface,
  FilterComponent, FullOrganizationUnitResponseInterfaceSingle, getScreenTagsByTypeAction,
  i18n,
  LicenceInterface,
  LicenceResponseInterfaceAll,
  LicenceService,
  NoDataComponent,
  NoteResponseInterfaceAll,
  NoteService, OrganizationUnitService, StoreStateInterface,
  TagScreenStateInterface
} from '../../../common';
import { LicenceEnum } from '../../../common/enums/licence.enum';

function FaqPageComponent() {
  const userAuth = useSelector((state: StoreStateInterface) => state.auth);

  const dispatch = useDispatch();
  const data: FaqStateInterface = useSelector((state: StoreStateInterface) => state.faq);
  const tags: TagScreenStateInterface = useSelector((state: StoreStateInterface) => state.tagScreen);

  const [filterValues, setFilterValues] = useState();

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [keyword, setKeyword] = useState('');
  const [esData, setEsData] = useState<FaqEsResponseInterface>();
  const [exactMatch, setExactMatch] = useState<boolean>(true);

  const [orgUnitInfo, setOrgUnitInfo] = useState<FullOrganizationUnitResponseInterfaceSingle>();
  const [licenceData, setLicenceData] = useState<LicenceInterface>();
  const [allValidLicences, setAllValidLicences] = useState<LicenceInterface[]>([]);
  const [noteData, setNoteData] = useState<NoteResponseInterfaceAll>();

  useEffect(() => {
    clearNoteAction();
    NoteService.getAll(userAuth.user?.id, false).subscribe(
      (response: NoteResponseInterfaceAll) => {
        setNoteData(response);
      },
      (error: Error) => {
        notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
      }
    );
  }, [userAuth?.user?.id]);

  useEffect(() => {
    if (userAuth.user?.organizationUnit?.id) {
      OrganizationUnitService.getSingle(userAuth.user?.organizationUnit?.id).subscribe(
        (response: FullOrganizationUnitResponseInterfaceSingle) => {
          setOrgUnitInfo(response);
        },
        (error: Error) => {
          notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
        }
      );
    }
  }, [userAuth.user]);

  useEffect(() => {
    if (orgUnitInfo?.data?.id) {
      LicenceService.getAll(undefined, `filter[organizationUnit.id]=${orgUnitInfo?.data?.id}`, 'sort=id', `page[offset]=0&page[limit]=999999`).subscribe(
        (response: LicenceResponseInterfaceAll) => {
          if (response?.data?.length) {
            let validLicences: LicenceInterface[] = [];

            response?.data?.forEach(licence => {
              if (licence.attributes?.licenceUsers?.find((x) => x.user?.id == userAuth.user?.id) && !licence.relationships?.licenceParent?.data && licence.attributes?.notesNum > 0) {
                setLicenceData(licence);
                const standardLicence = response.data.find(
                  (x) => x.attributes?.licenceUsers?.find((y) => y.user?.id == userAuth.user?.id) && !x.relationships?.licenceParent?.data && x.attributes?.active && x.attributes?.licenceType === LicenceEnum.STANDARD && licence.attributes?.notesNum > 0 && moment().isBefore(moment(licence?.attributes?.to).startOf('day'))
                );
                const premiumLicence = response.data.find(
                  (x) => x.attributes?.licenceUsers?.find((y) => y.user?.id == userAuth.user?.id) && !x.relationships?.licenceParent?.data && x.attributes?.active && x.attributes?.licenceType === LicenceEnum.PREMIUM && licence.attributes?.notesNum > 0 && moment().isBefore(moment(licence?.attributes?.to).startOf('day'))
                );
                if (standardLicence && standardLicence.attributes?.notesNum > 0) setLicenceData(standardLicence);
                if (premiumLicence && premiumLicence.attributes?.notesNum > 0) setLicenceData(premiumLicence);
              }
              if (licence.attributes?.licenceUsers?.find(x => x.user?.id == userAuth.user?.id) && !licence.relationships?.licenceParent?.data && licence.attributes?.active && licence?.attributes?.licenceType !== LicenceEnum.FREE && moment().isBefore(moment(licence?.attributes?.to).startOf('day'))) {
                validLicences.push(licence);
              }
            });

            setAllValidLicences([...validLicences]);
          }
        },
        (error: Error) => {
          notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
        }
      );
    }
  }, [orgUnitInfo?.data]);

  useEffect(() => {
    localStorage.removeItem('transferFilters');
    dispatch(clearFaqAction());
    dispatch(clearFaqListAction());
    dispatch(getScreenTagsByTypeAction('frequent_questions'));
    esSearchFunction({ from: pageSize * (page - 1), size: pageSize }, keyword, {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const esSearchFunction = (
    pageSorted: { from: number; size: number },
    keyword?: string,
    tagIds?: any
  ) => {
    FaqService.elasticSearch(pageSorted, exactMatch, keyword, tagIds).subscribe(
      (response: FaqEsResponseInterface) => {
        setEsData(response);
      },
      (error: any) => {
        if (error.errorId == 23) {
          notification['warning']({ message: i18n.translate('common.licence.noFilter'), duration: 2 });
        }
        else {
          notification['error']({ message: i18n.translate('api.errorMessage'), duration: 2 });
        }
      }
    );
  };

  const refetch = (allValues: any) => {
    if (!allValidLicences.some(x => x.attributes?.licenceType === LicenceEnum.STANDARD) && !allValidLicences.some(x => x.attributes?.licenceType === LicenceEnum.PREMIUM)) {
      notification['warning']({ message: i18n.translate('common.licence.noFilter'), duration: 4 });
      return;
    }
    setFilterValues(allValues);
    setPage(1);
    esSearchFunction({ from: 0, size: pageSize }, keyword, allValues);
  };


  const resetSearch = () => {
    setEsData(undefined);
    setKeyword('');
    setFilterValues(undefined);
    setPage(1);
    esSearchFunction({ from: 0, size: pageSize }, '', {});
  };

  const onPaginationChange: PaginationProps['onChange'] = (page, pageSize) => {
    setPage(page);
    setPageSize(pageSize);
    esSearchFunction({ from: pageSize * (page - 1), size: pageSize }, keyword, filterValues);
  };

  const keywordChanged = (value: string) => {
    setKeyword(value);
  };

  const exactMatchChanged = (exactMatch: boolean) => {
    setExactMatch(exactMatch);
  };

  return (
    <div className="faq w100-h100">
      <div className="faq__breadcrumbs">
        <Breadcrumb>
          <Breadcrumb.Item>
            <HomeOutlined />
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span>{i18n.translate('breadcrumbs.web-of-knowledge')}</span>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span>{i18n.translate('menu.faq')}</span>
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="faq__title">{i18n.translate('faq.title')}</div>
      {tags.tagScreens && tags.tagScreens.length ? <FilterComponent aggregation={esData?.aggregation?.buckets} filterAccess onExactMatchChange={exactMatchChanged} filters={tags.tagScreens} keyword={keyword} onKeywordChange={keywordChanged} onFilterChange={refetch} onFilterRemove={resetSearch} /> : null}
      <>
        {esData?.hits?.length ?
          <>
            <FAQCardComponent licenceData={licenceData} validLicences={allValidLicences} faqs={esData?.hits.map((el: { index: "faq_group", id: number, score: number; source: FaqInterface['attributes'] }) => el.source)} tags={tags.tagScreens} />
          </>
        : null}
        {!esData?.hits?.length ?
          <NoDataComponent />
        : null}
      </>
      <>
        {esData?.hits?.length ? (
          <Pagination
            style={{ float: 'right', paddingTop: 16, paddingBottom: 16 }}
            onChange={onPaginationChange}
            current={page}
            pageSize={pageSize}
            pageSizeOptions={['10', '20', '50', '100']}
            locale={{ items_per_page: `${i18n.t('pagination.perPage')}` }}
            total={esData?.totalHits}
            hideOnSinglePage
            responsive
            showTotal={(total) => `${i18n.t('pagination.total')}: ${total}`}
          />
        ) : null}
      </>
    </div>
  );
}

export default FaqPageComponent;
