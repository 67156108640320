import { JavaFormat } from "../constants";
import { i18n } from "../services";
import moment from 'moment';

export enum PeriodEnum {
  TODAY = 1,
  THIS_MONTH = 2,
  LAST_MONTH = 3,
  CUSTOM = 4
}

export interface Period {
  id: number;
  startDate: string;
  endDate: string;
}

export const Periods: Array<Period> = [
  { id: PeriodEnum.TODAY, startDate: moment().startOf('day').format(JavaFormat), endDate: moment().endOf('day').format(JavaFormat) },
  { id: PeriodEnum.THIS_MONTH, startDate: moment().startOf('month').format(JavaFormat), endDate: moment().endOf('month').format(JavaFormat) },
  { id: PeriodEnum.LAST_MONTH, startDate: moment().subtract(1, 'month').startOf('month').format(JavaFormat), endDate: moment().subtract(1, 'month').endOf('month').format(JavaFormat) },
  { id: PeriodEnum.CUSTOM, startDate: moment().startOf('day').format(JavaFormat), endDate: moment().endOf('day').format(JavaFormat) }
]

export const getPeriodLabel = (period: number) => {
  switch (period) {
    case PeriodEnum.TODAY:
      return i18n.t("common.constants.period.today");
    case PeriodEnum.THIS_MONTH:
      return i18n.t("common.constants.period.thisMonth");
    case PeriodEnum.LAST_MONTH:
      return i18n.t("common.constants.period.lastMonth");
    case PeriodEnum.CUSTOM:
      return i18n.t("common.constants.period.custom");
    default:
      return '';
  }
}

export const getPeriodStartDate = (period: number) => {
  switch (period) {
    case PeriodEnum.TODAY:
      return moment().startOf('day').format(JavaFormat);
    case PeriodEnum.THIS_MONTH:
      return moment().startOf('month').format(JavaFormat);
    case PeriodEnum.LAST_MONTH:
      return moment().subtract(1, 'month').startOf('month').format(JavaFormat);
    case PeriodEnum.CUSTOM:
      return moment().startOf('day').format(JavaFormat);
    default:
      return moment().startOf('day').format(JavaFormat);
  }
}

export const getPeriodEndDate = (period: number) => {
  switch (period) {
    case PeriodEnum.TODAY:
      return moment().endOf('day').format(JavaFormat);
    case PeriodEnum.THIS_MONTH:
      return moment().endOf('month').format(JavaFormat);
    case PeriodEnum.LAST_MONTH:
      return moment().subtract(1, 'month').endOf('month').format(JavaFormat);
    case PeriodEnum.CUSTOM:
      return moment().endOf('day').format(JavaFormat);
    default:
      return moment().endOf('day').format(JavaFormat);
  }
}