import { Collapse, Divider, Typography } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';
import React from 'react';

interface Props {
  title: string;
  children?: any;
}

const { Title } = Typography;
const { Panel } = Collapse;

export default function YearDividerComponent({ title, children }: Props) {
  return (
    <>
      {children && (
        <div className="year-divider">
          <Collapse ghost expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}>
            <Panel
              key={title}
              header={
                <>
                  <Title className="year-divider__title" level={5}>
                    {title}
                  </Title>
                </>
              }
            >
              {children}
            </Panel>
            
          </Collapse>
          
          <Divider />
        </div>
      )}
      {!children && (
        <div className="year-divider">
          <Title className="year-divider__title" level={5}>
            {title}
          </Title>
          <Divider />
        </div>
      )}
    </>
  );
}
