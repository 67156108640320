export enum NoteTypeEnum {
  ARTICLE = 'article',
  OPINION = 'opinion',
  FAQ = 'faq',
  REGULATION = 'regulation',
  PRO_MATERIAL = 'proMaterial',
  EU_COURT = 'court',
  ALLEGATION = 'allegation',
  APPEAL = 'appeal',
  SUMMARY = 'summary',
  ORDER = 'order',
  RESPONSE = 'response',
  SYNOPSIS = 'synposis',
  BILTEN = 'bilten',
}
