import { Typography } from 'antd';
import React, { useEffect }  from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { i18n } from '../../../../services';
import { BiltenStateInterface, clearBiltenAction, clearBiltenListAction, getAllBiltensAction, StoreStateInterface } from '../../../../redux';
import PrintEditionsTableComponent from '../../../tables/printEditions/printEditionsTable.component';
import { BiltenInterface } from '../../../../interfaces';

const { Title } = Typography;

export default function AdminPrintEditionsComponent() {
  const userAuth = useSelector((state: StoreStateInterface) => state.auth);

  const dispatch = useDispatch();
  const data: BiltenStateInterface = useSelector((state: StoreStateInterface) => state.bilten);

  useEffect(() => {
    dispatch(clearBiltenAction());
    dispatch(clearBiltenListAction());
    dispatch(getAllBiltensAction());
  }, []);

  return (
    <div className="printEditions">
      <Title className="printEditions__title" level={5}>
        {i18n.translate('profile.printEditions.title')}
      </Title>
      <PrintEditionsTableComponent userId={userAuth.user?.id!} isAdminView={false} biltens={data.biltens?.data as BiltenInterface[]} />
    </div>
  );
}
