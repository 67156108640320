import { Modal, Typography } from 'antd';
import React from 'react';
import { i18n } from '../../../services';
import { DisplayDateFormat } from '../../../constants';
import moment from 'moment';

const { Text } = Typography;

interface Props {
  visible: boolean;
  handleOk: any;
  date?: string;
}

export default function Force2faModal({
  visible,
  handleOk,
  date
}: Props) {
  return (
    <div>
      <Modal
        className="force2faModal"
        title={i18n.translate('force2fa.title')}
        closable={false}
        visible={visible}
        onOk={handleOk}
        cancelButtonProps={{ style: { display: 'none' } }}
        width={850}
      >
        <div className='white-space'>
          {i18n.translate('force2fa.text1')}
          &nbsp;
          <a style={{color: "#1210A9"}} href="mailto:bilten@temporis.hr">bilten@temporis.hr</a>
          &nbsp;
          {i18n.translate('force2fa.text2')}
          &nbsp;
          <a href="tel:+385 1 6431 872"><strong>+385 1 6431 872</strong></a>
          &nbsp;
          {i18n.translate('force2fa.text3')}
        </div>
      </Modal>
    </div>
  );
}
